import { getReportItems } from '../../api';
import { TrendsResponse } from './types';
import { LocationInfo, ReportItemInfo, Trend } from '../../common/types/projectsHierarchy';
import {
  calculateLocationAverageAccordingToMetrics,
  calculateValuesAverage,
  getLocationsDates,
  modifyValueData,
  populateWithMissingTimeFramesInitData,
} from './utils';
import { mapReportItemType } from '../utils';
import { PeriodsEnum } from '../../common/reportItems/types';
import { generateMapPoints } from '../utils/mappers/generateMapPoints';
import { generateMapPoiPolygons } from '../utils/mappers/generateMapPoiPolygons';
import { filterByLocationName } from './utils/filterByLocationName';
import { toFixNumber } from '../../common/lib';
import { isAbsoluteDropdownDisabled } from './card/utils';
import { getCorrectPointsIfCoordinatesAreWrong } from '../../common/maps/utils';

const convertData = ({ values, id, name, type }: TrendsResponse, locations: LocationInfo[]): ReportItemInfo<Trend> => {
  const dates = getLocationsDates(values);

  const { locationsAverage, average } = calculateValuesAverage(values);

  const data = values.map(({ location_id, location_name, data }) => {
    const calculatedLocationData = modifyValueData(data, locationsAverage, average, location_id);

    return {
      location_id,
      location_name,
      data: calculatedLocationData,
    };
  });

  const { averageByDifferentMetricsInLine, visitCount, fromMonthToMonthAverageChange } =
    calculateLocationAverageAccordingToMetrics(locationsAverage, average, data, dates);

  const mapPointsData = values.map(({ location_id, location_name }) => {
    const { locationsCountVisits } = visitCount;
    const locationCounts = locationsCountVisits.get(location_id);

    return {
      location_id,
      location_name,
      confidenceLevel: locationCounts?.confidenceLevel,
      average: {
        singleLocation: locationCounts?.singleLocation || 0,
        absoluteLocation: locationCounts?.absoluteNumber || 0,
        allLocations: locationCounts?.allLocations || 0,
      },
    };
  });

  const initialPointsData = mapPointsData.map((pointData) => ({
    ...pointData,
    average: pointData.average.allLocations ? `${toFixNumber(pointData.average.allLocations * 100, 2)}%` : '',
  }));

  const points = generateMapPoints(initialPointsData, locations);
  const poiPolygons = generateMapPoiPolygons(values, locations);
  const isAbsoluteNumbersDisabled = isAbsoluteDropdownDisabled(data, locations);

  return {
    id,
    name,
    type: mapReportItemType(type),
    data: {
      data,
      mapPointsData,
      fromMonthToMonthAverageChange,
      averageByDifferentMetricsInLine,
      dates,
      visitCount,
      isAbsoluteNumbersDisabled,
    },
    visualization: {
      points: getCorrectPointsIfCoordinatesAreWrong(points, poiPolygons),
      poiPolygons: generateMapPoiPolygons(values, locations),
    },
    settings: [],
  };
};

export const fetchTrendsData = async (
  reportItemId: string,
  locationIds: string[],
  period: PeriodsEnum = PeriodsEnum.MONTHLY,
  locations: LocationInfo[] = [],
  selectedTimeFrames: string[] | null,
) => {
  try {
    const response = await getReportItems<TrendsResponse>(reportItemId, period, locationIds, selectedTimeFrames);
    const convertedData = convertData(response, locations);

    const modifiedData = populateWithMissingTimeFramesInitData(
      selectedTimeFrames,
      period,
      filterByLocationName(convertedData.data?.data),
    );

    const filledWithMissingTimes = {
      ...convertedData,
      data: {
        ...convertedData.data,
        data: modifiedData,
      },
    } as ReportItemInfo<Trend>;

    return filledWithMissingTimes;
  } catch (error) {}
};
