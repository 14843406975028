import React from 'react';

export const RemoveAvatarIcon = () => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.49999 10.0833C1.49999 10.725 2.02499 11.25 2.66666 11.25H7.33332C7.97499 11.25 8.49999 10.725 8.49999 10.0833V3.08333H1.49999V10.0833ZM2.93499 5.93L3.75749 5.1075L4.99999 6.34417L6.23666 5.1075L7.05916 5.93L5.82249 7.16667L7.05916 8.40333L6.23666 9.22583L4.99999 7.98917L3.76332 9.22583L2.94082 8.40333L4.17749 7.16667L2.93499 5.93ZM7.04166 1.33333L6.45832 0.75H3.54166L2.95832 1.33333H0.916656V2.5H9.08332V1.33333H7.04166Z"
      fill="white"
    />
  </svg>
);
