import { TrendData, TrendModifiedData } from '../types';

const confidenceLevelThreshold = 0.65;

function isPresentRelative(is_relative_shown: boolean, confidence_level: number) {
  if (is_relative_shown) {
    return true;
  }
  if (confidence_level && confidence_level >= confidenceLevelThreshold) {
    return true;
  }

  return typeof confidence_level === 'undefined' || confidence_level === null;
}

export const modifyValueData = (
  data: TrendData[],
  locationsAverage: Map<string, number>,
  average: number,
  location_id: string,
): TrendModifiedData[] =>
  data.map(({ date, value, confidence_level, is_relative_shown }) => {
    const optionalLocationAverage: number | undefined = locationsAverage.get(location_id);
    const locationAverage: number = !optionalLocationAverage ? 1 : optionalLocationAverage;
    const isRelativePresent = isPresentRelative(is_relative_shown, confidence_level);

    return {
      date,
      allLocations: isRelativePresent ? (average === 0 ? 0 : Number(value) / average - 1) : undefined,
      singleLocation: isRelativePresent ? (locationAverage === 0 ? 0 : Number(value) / locationAverage - 1) : undefined,
      absoluteLocation: confidence_level && confidence_level >= confidenceLevelThreshold ? Number(value) : undefined,
      is_relative_shown,
      confidence_level,
    };
  });
