import { useRef, useState } from 'react';
import domToImage from 'dom-to-image-more';

interface Options {
  backgroundColor?: string;
}

export const useElementScreenshot = ({ backgroundColor }: Options) => {
  const [base64Image, setBase64Image] = useState('');
  const [isScreenshotInProgress, toggleInScreenshotInProgress] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const onMakeScreenshot = () => {
    toggleInScreenshotInProgress(true);
    if (elementRef.current) {
      setTimeout(() => {
        domToImage
          .toPng(elementRef.current, { bgcolor: backgroundColor })
          .then((dataUrl: string) => {
            if (dataUrl.includes('base64')) {
              setBase64Image(dataUrl);
            }
          })
          .catch((error: any) => {
            console.error('oops, something went wrong!', error);
          })
          .finally(() => toggleInScreenshotInProgress(false));
      }, 1000);
    }
  };

  const onClearScreenshotData = () => {
    setBase64Image('');
  };

  return { elementRef, onMakeScreenshot, base64Image, onClearScreenshotData, isScreenshotInProgress };
};
