import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import styles from './dropdown.module.scss';
import MenuContainer from './menuContainer';
import { ArrowDownIcon } from '../../icons/dropdown';
import { maxLengthOfString } from '../../utils';
import { Option } from './types';

interface Props<T> {
  options: Option<T>[];
  selectedId: string;
  onChange: (option: Option<T>) => void;
  classNames?: string;
  selectedItemClassName?: string;
  disabled?: boolean;
  maxLengthToShow?: number;
  placeholder?: string;
}

const Dropdown = <T,>({
  options,
  selectedId,
  onChange,
  classNames,
  disabled,
  selectedItemClassName,
  maxLengthToShow,
  placeholder,
}: Props<T>) => {
  const [menuOpened, toggleMenuOpened] = useState(false);

  const menuBlurHandler = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      toggleMenuOpened(false);
    }
  };

  const changeHandler = (itemId: Option<T>) => {
    onChange(itemId);
    toggleMenuOpened(false);
  };

  const toggleMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (options.length > 1 && !disabled) {
      toggleMenuOpened((prevState) => !prevState);
    }
  };

  const items: Option<T>[] = options.map((option) => ({
    id: option.id,
    label: option.label,
    disabled: option.disabled || false,
    item: option.item,
    type: option.type,
    icon: option.icon,
  }));

  const selectedItem: Option<T> = items.find((item) => item.id === selectedId) || {
    id: '',
    label: '',
    disabled: false,
    item: null,
    type: null,
    icon: null,
  };

  const filteredItems = useMemo(() => items.filter(({ id }) => id !== selectedId), [selectedId, items]);

  const selectedValue = selectedItem.label;

  const value = maxLengthToShow
    ? selectedValue
      ? maxLengthOfString(selectedValue, maxLengthToShow)
      : placeholder
    : selectedValue || placeholder;

  return (
    <div onBlur={menuBlurHandler} tabIndex={0} className={`${styles.dropdownContainer} ${classNames}`}>
      <div
        onClick={toggleMenu}
        className={classnames({
          [styles.selectedItem]: true,
          [styles.selectedItem_open]: menuOpened,
          [styles.selectedItem_closed]: !menuOpened,
          [styles.selectedItem_empty]: selectedId === '',
          [styles.disabled]: disabled,
        })}
      >
        <span className={selectedItemClassName}>{value}</span>
        {filteredItems.length > 0 && <ArrowDownIcon />}
      </div>
      <MenuContainer options={filteredItems} onChange={changeHandler} isOpened={menuOpened} />
    </div>
  );
};

Dropdown.defaultProps = {
  classNames: '',
  selectedItemClassName: '',
  disabled: false,
};

export default Dropdown;
