import React from 'react';
import styles from './styles.module.scss';
import { LinkButton } from '../../../../common/controls/buttons';

interface Props {
  isLoading?: boolean;
}

export const MessageLoadGraph: React.FC<Props> = ({ isLoading }) => (
  <div className={styles.container}>
    <div className={styles.body}>
      {isLoading ? (
        <h3 className={styles.title}>Loading data...</h3>
      ) : (
        <>
          <h3 className={styles.title}>There's not enough data to show this report.</h3>
          <h4 className={styles.subtitle}>Please try extending the timeframe you're analyzing.</h4>
          <p className={styles.content}>
            If that doesn't help, please contact us and a customer success representative will get back to you shortly
          </p>
          <LinkButton url="https://placesense.ai/contact/" text="Contact us" external filled />
        </>
      )}
    </div>
  </div>
);
