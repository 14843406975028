import React, { ChangeEvent } from 'react';
import styles from './textArea.module.scss';

interface Props {
  name: string;
  value: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  placeholder?: string;
  width?: string;
  height?: string;
}

export const TextArea = (props: Props) => {
  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <textarea
      name={props.name}
      className={styles.textArea}
      value={props.value}
      onChange={onInputChange}
      disabled={props.disabled}
      placeholder={props.placeholder || ''}
      maxLength={300}
      style={{ width: props.width || '100%', height: props.height || '180px' }}
    />
  );
};
