import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../catchmentMenu.module.scss';
import { MainContext } from '../../../../common/types/mainContext';
import { SwitchToggle } from '../../../../common/controls/toggle';
import { Button } from '../../../../common/controls/buttons';
import {
  CatchmentLocationsCountEnum,
  CatchmentTypeEnum,
  ColorSchemeEnum,
  ReportItemType,
} from '../../../../common/reportItems/types';
import { usePopupPositions } from '../../../../common/reportItems/hooks';
import { useClickOutside, useURLParams } from '../../../../common/hooks';
import { ThresholdFilter } from '../../../../common/controls';
import { trackUserAction, UserActionsEnum } from '../../../../../mixpanel';
import { Catchment, ReportItemInfo } from '../../../../common/types/projectsHierarchy';
import { getMinMax } from '../../../../common/utils/getMinMax';
import { usePreviousValue } from '../../../../common/hooks/usePrevious';

type TProps = {
  onClose: (value: boolean) => void;
  isMenuOpened: boolean;
};

const CatchmentMenu = ({ onClose, isMenuOpened }: TProps) => {
  const { t } = useTranslation();
  const {
    reportItemsDetails,
    updateReportItemsDetailsValue,
    primaryLocationId,
    selection: { selectedReport },
    catchmentLayer,
  } = useContext(MainContext);

  const previousValues = usePreviousValue({
    locationsCount: reportItemsDetails.catchmentLocationsCount,
    socioDemoMetric: reportItemsDetails.catchmentSocioDemoMetric,
    primaryLocationId,
    catchmentLayer,
  });

  const [colorScheme, setColorScheme] = useState<ColorSchemeEnum>(reportItemsDetails.colorScheme);
  const [catchmentType, setCatchmentType] = useState<CatchmentTypeEnum>(reportItemsDetails.catchmentType);
  const [threshold, setThreshold] = useState<{ min: number; max: number }>(reportItemsDetails.catchmentTreshold);
  const [shouldUpdateThreshold, setShouldUpdateThreshold] = useState<boolean>(true);
  const { onChangeParams } = useURLParams();

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.CATCHMENT,
  ) as ReportItemInfo<Catchment>;

  const minMaxValue = useMemo(() => {
    if (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE) {
      const data = reportItem?.data?.data?.find((location) => location.location_id === primaryLocationId)?.[
        catchmentType.toLowerCase() as 'home' | 'work'
      ];
      if (data) {
        return getMinMax(data, 'geo_entity_share_of_target');
      }
    }
    return undefined;
  }, [
    catchmentType,
    primaryLocationId,
    reportItem?.data?.data,
    catchmentLayer,
    reportItem?.visualization?.multiLocationPolygons,
    reportItemsDetails.catchmentLocationsCount,
    reportItemsDetails.catchmentSocioDemoMetric,
  ]);

  useEffect(() => {
    if (minMaxValue) {
      setThreshold({
        min: minMaxValue[0] || 0.001,
        max: minMaxValue[1] || 1,
      });
      if (shouldUpdateThreshold) {
        updateReportItemsDetailsValue('catchmentTreshold', {
          min: minMaxValue[0] || 0.001,
          max: minMaxValue[1] || 1,
        });
        onChangeParams('catchmentThresholdMin', minMaxValue[0] || 0.001);
        onChangeParams('catchmentThresholdMax', minMaxValue[1] || 1);
        setShouldUpdateThreshold(false);
      }
    }
  }, [minMaxValue]);

  useEffect(() => {
    // checking if threshold should be updated
    if (
      reportItemsDetails.catchmentLocationsCount !== previousValues?.locationsCount ||
      (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE &&
        reportItemsDetails.catchmentSocioDemoMetric !== previousValues.socioDemoMetric) ||
      (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE &&
        primaryLocationId !== previousValues.primaryLocationId) ||
      catchmentLayer !== previousValues.catchmentLayer
    ) {
      setShouldUpdateThreshold(true);
    }
  }, [
    reportItemsDetails.catchmentLocationsCount,
    reportItemsDetails.catchmentSocioDemoMetric,
    primaryLocationId,
    catchmentLayer,
  ]);

  useEffect(() => {
    if (reportItemsDetails.colorScheme !== colorScheme) {
      setColorScheme(reportItemsDetails.colorScheme);
    }
  }, [reportItemsDetails.colorScheme]);

  const onSave = () => {
    if (reportItemsDetails.colorScheme !== colorScheme) {
      trackUserAction(
        'Catchment color scheme changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Color scheme: ${colorScheme}`,
      );
      updateReportItemsDetailsValue('colorScheme', colorScheme);
      onChangeParams('colorScheme', colorScheme);
    }
    if (reportItemsDetails.catchmentType !== catchmentType) {
      trackUserAction(
        'Catchment source reference changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Source reference: ${catchmentType}`,
      );
      updateReportItemsDetailsValue('catchmentType', catchmentType);
      onChangeParams('catchmentType', catchmentType);
    }
    if (
      reportItemsDetails.catchmentTreshold.min !== threshold.min ||
      reportItemsDetails.catchmentTreshold.max !== threshold.max
    ) {
      trackUserAction(
        'Catchment threshold changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Min: ${threshold.min}, max: ${threshold.max}`,
      );
      updateReportItemsDetailsValue('catchmentTreshold', threshold);
      onChangeParams('catchmentThresholdMin', threshold.min);
      onChangeParams('catchmentThresholdMax', threshold.max);
    }
    onMenuClose();
  };

  const onMenuClose = () => {
    onClose(false);
  };

  const { ref } = usePopupPositions(`menu-${ReportItemType.CATCHMENT}`, onMenuClose, 145, 32, 190);
  useClickOutside(ref, onMenuClose, isMenuOpened);

  return (
    <div className={`${styles.catchmentMenu} ${styles.smaller}`} ref={ref}>
      {isMenuOpened && (
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>{t('reportItem.settings')}</h3>
          </div>
          <div className={styles.row}>
            <div className={styles.rowHeader}>{t('catchment.card.sourceReference')}</div>
            <div className={styles.content}>
              <SwitchToggle
                leftLabel={t('catchment.card.home')}
                leftValue={CatchmentTypeEnum.HOME}
                rightLabel={t('catchment.card.work')}
                rightValue={CatchmentTypeEnum.WORK}
                selectedValue={catchmentType}
                onClick={(value) => setCatchmentType(value as CatchmentTypeEnum)}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowHeader}>{t('catchment.card.mapPalette')}</div>
            <div className={styles.content}>
              <SwitchToggle
                leftLabel={t('catchment.card.color')}
                leftValue={ColorSchemeEnum.COLORFUL}
                rightLabel={t('catchment.card.monochrome')}
                rightValue={ColorSchemeEnum.MONOCHROME}
                selectedValue={colorScheme}
                onClick={(value) => setColorScheme(value as ColorSchemeEnum)}
              />
            </div>
          </div>
          <hr />
          <div className={styles.row}>
            <div className={styles.rowHeader}>{t('catchment.card.metricThreshold')}</div>
            <div className={styles.content}>
              <ThresholdFilter
                initialValue={threshold}
                minValue={minMaxValue ? minMaxValue[0] : 0}
                maxValue={minMaxValue ? minMaxValue[1] : 1}
                onChange={setThreshold}
                percentage
                unitSymbol="%"
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button onClick={() => onClose(false)}>{t('reportItem.cancel')}</Button>
            <Button layout="primary" onClick={onSave} animation>
              {t('reportItem.save')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CatchmentMenu;
