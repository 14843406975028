import React from 'react';
import { Provider } from 'react-redux';
import { buildStore, history } from '../../../store/store';
import AppRouter from '../router/router';
import 'react-datepicker/dist/react-datepicker.css';
import { ConnectedRouter } from 'connected-react-router';
import { Sprite } from '../../Icon';

function App() {
  return (
    <Provider store={buildStore()}>
      <ConnectedRouter history={history}>
        {/* place ConnectedRouter under Provider */}
        <>
          <AppRouter />
          <Sprite />
        </>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
