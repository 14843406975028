import React, { useContext } from 'react';
import { MainContext } from '../../types/mainContext';
import { ReportItemType } from '../../reportItems/types';
import { CatchmentBottomBar } from './catchment';
import { MovementBottomBar } from './movement';
import { ContentVisualizationMode } from '../../types/projectsHierarchy';
import { ExportMapContext } from '../../export/exportSettings/settings/mapContainer';

export const MapBottomBar = () => {
  const { selection, contentVisualizationMode, exportSelection } = useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const reportItem = isExportMap ? exportSelection.selectedReportItem : selection.selectedReportItem;

  const renderBottomBar = () => {
    switch (reportItem?.type) {
      case ReportItemType.CATCHMENT:
        return <CatchmentBottomBar />;
      case ReportItemType.MOVEMENT:
        return <MovementBottomBar />;
      default:
        return null;
    }
  };

  if (isExportMap || (!isExportMap && contentVisualizationMode === ContentVisualizationMode.MAP))
    return renderBottomBar();

  return <></>;
};
