import { DurationLocation } from '../../../../common/types/projectsHierarchy';
import { dateFormatter } from '../../../trends/utils';
import { toFixNumber } from '../../../../common/lib';

export const convertDataForExcel = (data: DurationLocation[]) => {
  const entities = data.map(({ data, location_name }) => {
    return data.map(({ value, category }) => {
      return {
        location: location_name,
        time_range: String(category),
        visit_proportion: `${toFixNumber(value * 100, 2)}%`,
      };
    });
  });
  return entities.reduce((acc, curr) => [...acc, ...curr], []);
};
