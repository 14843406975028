import { getPercentageChange, toFixNumber } from '../../../common/lib';
import { getOnlyValue } from './getOnlyValue';
import { LocationVisitCount } from '../types';
import { transformOnlyValues } from './transformOnlyValues';

export const getFromMonthToMonthAverage = (data: (number | undefined)[]) => {
  const recordsCount = data.length;

  const sum = data.reduce<number>((acc, curr, index) => {
    const nextValue = data[index + 1];
    if (!nextValue || !curr) return Number(acc) + 0;

    return Number(acc) + toFixNumber(getPercentageChange(Number(curr), nextValue), 2);
  }, 0);

  return sum / recordsCount;
};

export const getSingleLocationsFromMonthToMonthAverage = (entries: [string, LocationVisitCount<'array'>][]) =>
  entries.map((entry) => {
    const locationId = entry[0];
    const locationValues = entry[1].singleLocation;
    return {
      location_id: locationId,
      value: toFixNumber(getFromMonthToMonthAverage(getOnlyValue(transformOnlyValues(locationValues))), 1),
    };
  });
