import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable, { BaseTableHeaderProps } from '../../../common/baseTable';
import { TableDataFormat } from '../../duration/types';
import { TrendValue } from '../types';
import { dateFormatter } from '../utils';
import { PeriodsEnum } from '../../../common/reportItems/types';
import { toFixNumber } from '../../../common/lib';
import { useTimePeriod } from '../../../common/hooks/useTimePeriod';

interface Props {
  data: TrendValue[];
  period: PeriodsEnum;
  isPercentage: boolean;
}

const dateOptions = {
  format: {
    monthly: 'MMM YYYY',
    weekly: 'MMM DD, YYYY',
  },
};

const exportDateOptions = {
  format: {
    monthly: 'YYYY-MM',
    weekly: 'YYYY-MM-DD',
  },
};

const convertDataToTableFormat = (
  data: TrendValue[],
  isPercentage: boolean,
  period: PeriodsEnum,
  isExport: boolean,
  timeFrame: string,
) =>
  data
    ? data.map((item) => ({
        head: item.location_name,
        options: item.data
          .map((subitem) => ({
            subhead: {
              value: subitem.date,
              formatter: () => dateFormatter(String(subitem.date), period, isExport ? exportDateOptions : dateOptions),
            },
            values: isExport
              ? {
                  value: Number(subitem.value),
                  timeframe: timeFrame,
                }
              : {
                  value: isPercentage ? Number(subitem.value) * 100 : toFixNumber(Number(subitem.value), 0),
                },
          }))
          .filter((subitem) => !Number.isNaN(subitem.values.value)),
      }))
    : [];

export const Table: React.FC<Props> = ({ data, period, isPercentage }) => {
  const { t } = useTranslation();
  const timeFrameString = useTimePeriod();
  const tableData: TableDataFormat[] = useMemo(
    () => convertDataToTableFormat(data, isPercentage, period, false, timeFrameString),
    [data, period, isPercentage],
  );

  const exportTableData: TableDataFormat[] = useMemo(
    () => convertDataToTableFormat(data, isPercentage, period, true, timeFrameString),
    [data, period, isPercentage],
  );

  const tableHeaders: BaseTableHeaderProps[] = [
    { key: 'value', name: t('visitTrends.detail.footfallIndex'), unit: isPercentage ? '%' : '' },
  ];

  const exportTableHeaders: BaseTableHeaderProps[] = [
    { key: 'value', name: t('visitTrends.detail.footfallIndex'), unit: '' },
    { key: 'timeframe', name: t('reportItem.timeFrame'), unit: '' },
  ];

  return (
    <BaseTable<TableDataFormat>
      data={tableData}
      headers={tableHeaders}
      headName={t('visitTrends.detail.location')}
      subheadName={t('visitTrends.detail.timeRange')}
      exportHeaders={exportTableHeaders}
      exportData={exportTableData}
    />
  );
};
