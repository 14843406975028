import { AppActions } from '../actions/actionTypes';

export interface ReportItemTypesState {
  types?: string[];
}

const initialState: ReportItemTypesState = {
  types: undefined,
};

export default function reportItemTypesReducer(state = initialState, action: AppActions) {
  switch (action.type) {
    case 'LOAD_REPORT_ITEM_TYPES': {
      return {
        ...state,
        types: action.payload.types,
      };
    }
    default:
      return state;
  }
}
