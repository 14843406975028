import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EChartsOption } from 'echarts';
import { LocationDailySeasonality, LocationInfo } from '../../../common/types/projectsHierarchy';
import Chart, { getTooltipHeight } from '../../../common/chart';
import { WeekDays } from '../../types';
import { sortByValue } from '../../../common/utils';
import styles from './style.module.scss';
import { ChartEvents } from '../../../common/chart/interfaces';

interface Props {
  data: LocationDailySeasonality[];
  locations: { [key in string]: boolean };
  projectLocations: LocationInfo[];
  events: ChartEvents;
}

function getOptions(
  data: LocationDailySeasonality[],
  selectedValues: { [key in string]: boolean },
  projectLocations: LocationInfo[],
  t: (val: string) => string,
): EChartsOption {
  return {
    grid: {
      top: 20,
      left: 70,
      right: 35,
      bottom: 75,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      type: 'category',
      data: data[0].data.map((item) => WeekDays[item.dayOfWeekNumber]),
      axisLabel: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
      },
      axisLine: { onZero: true },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          width: 2,
          color: '#FFFFFF',
          opacity: 0.2,
        },
      },
    },
    yAxis: {
      type: 'value',
      name: t('seasonality.detail.visitProportion'),
      nameLocation: 'middle',
      axisLabel: {
        formatter: (value: number) => `${value * 100}%`,
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
      },
      nameTextStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      splitLine: {
        lineStyle: {
          opacity: 0.1,
          type: 'solid',
          width: 1,
          color: '#FFFFFF',
        },
      },
      nameGap: 50,
    },
    legend: {
      show: false,
      selected: selectedValues,
      pageIconColor: 'rgba(255, 255, 255, .25',
    },
    tooltip: {
      trigger: 'axis',
      triggerOn: 'click',
      borderColor: 'rgba(255, 255, 255, 0.3)',
      formatter: (params: any) => {
        if (Array.isArray(params)) {
          const sortedData = sortByValue(params);
          const header = `
              <div class="${styles.header}">
                <span class="${styles.title}">${t('seasonality.detail.shareOfVisitsOn')} </span>
                <span class="${styles.date}">${sortedData[0].name}</span>
              </div>`;

          let items = '';

          sortedData.forEach((item) => {
            items += `<div class="${styles.row}">
                  <span class="${styles.location}">${item.marker} ${item.seriesName}:</span>
                  <span class="${styles.value}">${(+item.value * 100).toFixed(2)}%</span>
                </div>`;
          });

          const maxItemsHeight = getTooltipHeight(params.length, 5);
          return `
            ${header}
            <div class="custom-scrollbar ${styles.items}" style="height:${maxItemsHeight}px;">
              ${items}
            </div> 
          `;
        }
        return '';
      },
      className: styles.seasonalityTooltip,
      backgroundColor: '##1F303D',
      axisPointer: {
        type: 'line',
        axis: 'x',
        lineStyle: {
          color: '#999',
        },
      },
    },
    series: data.map((item, i) => {
      const location = projectLocations.find((loc) => loc.id === item.locationId);
      return {
        id: item.locationId,
        name: item.locationName,
        type: 'bar',
        stack: `${i}`,
        color: location?.color || '#FFF',
        barGap: 0.8,
        data: [...item.data.slice(1), item.data[0]].map((el) => el.visitsShare),
        itemStyle: {
          borderRadius: [2, 2, 0, 0],
        },
        label: {
          fontFamily: 'Open Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          color: location?.color || '#FFF',
          show: true,
          position: 'top',
          formatter: (params) => `${(+params.value * 100).toFixed(1)}`,
        },
      };
    }),
  };
}

const SeasonalityDetailDailyChart: React.FC<Props> = ({ data, locations, projectLocations, events }) => {
  const { t } = useTranslation();
  const options = useMemo(
    () => getOptions(data, locations, projectLocations, t),
    [data, locations, projectLocations, t],
  );

  return (
    <div style={{ height: '270px' }}>
      <Chart option={options} events={events} />
    </div>
  );
};

export default SeasonalityDetailDailyChart;
