export const convertImageToBase64 = async (image: HTMLImageElement, width?: number, height?: number) => {
  await image.decode();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (width) canvas.width = 2550;
  if (height) canvas.height = 2000;
  if (ctx) {
    ctx.drawImage(image, 0, 0);
    return canvas.toDataURL();
  }
};
