import React from 'react';

interface Props {
  fill?: string;
}

export const CrossIcon = ({ fill = 'white' }: Props) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.66659 1.27325L8.72659 0.333252L4.99992 4.05992L1.27325 0.333252L0.333252 1.27325L4.05992 4.99992L0.333252 8.72659L1.27325 9.66659L4.99992 5.93992L8.72659 9.66659L9.66659 8.72659L5.93992 4.99992L9.66659 1.27325Z"
      fill={fill}
    />
  </svg>
);
