import React, { useContext, useEffect, useState } from 'react';
import { MainContext } from '../../../../common/types/mainContext';
import { PlusIcon } from '../../../../common/icons/sliders';
import Dropdown from '../../../../common/controls/dropdown';
import { CircleButton } from '../../../../common/controls/buttons/circleButton/circleButton';
import styles from './projectsDropdown.module.scss';
import { CreateProjectPopup } from './createProjectPopup';
import { getDateString } from '../../../../common/utils';

interface TProps {
  projectId: string;
  onChange: (projectId: string) => void;
}

type TProjectOption = {
  id: string;
  label: string;
  item: null;
  disabled: boolean;
};

export const ProjectsDropdown = ({ projectId, onChange }: TProps) => {
  const { projectsHierarchy, setProjectsHierarchy } = useContext(MainContext);

  const [projectsList, setProjectsList] = useState<TProjectOption[]>([]);
  const [isPopupOpen, togglePopup] = useState<boolean>(false);

  const onDropdownChange = (item: TProjectOption) => {
    if (item.id) {
      onChange(item.id);
    }
  };

  const onNewProjectCreated = (id: string, name: string, description: string) => {
    if (id.length > 0 && name.length > 0) {
      const today = new Date();
      setProjectsHierarchy([
        ...projectsHierarchy,
        {
          id,
          name,
          description,
          locations: [],
          locations_count: 0,
          reports: [],
          picture_url: null,
          created_date: getDateString(today),
        },
      ]);
      onChange(id);
    }
  };

  useEffect(() => {
    const list = projectsHierarchy
      .map((project) => ({ id: project.id, label: project.name, item: null, disabled: false }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setProjectsList(list);
  }, [projectsHierarchy]);

  return (
    <div className={styles.projectsDropdownContainer}>
      <Dropdown<null>
        onChange={onDropdownChange}
        selectedId={projectId}
        options={projectsList}
        classNames={styles.dropdown}
      />
      <CircleButton icon={<PlusIcon />} onClick={() => togglePopup(true)} title="Create new project" />
      <CreateProjectPopup isOpen={isPopupOpen} toggleOpen={togglePopup} onSave={onNewProjectCreated} />
    </div>
  );
};
