import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { getEditHandleStyle, getFeatureStyle } from './style';

interface EditorProps {
  polygon: GeoJSON.Feature<GeoJSON.Polygon> | undefined | null;
  setPolygon: (polygon: GeoJSON.Feature<GeoJSON.Polygon> | undefined) => void;
}

const PolylineEditor: React.FC<EditorProps> = ({ polygon, setPolygon }) => {
  const [mode, setMode] = useState<EditingMode | DrawPolygonMode>(new DrawPolygonMode());

  const editorRef = useRef<Editor>(null);

  useEffect(() => {
    if (polygon && mode.constructor !== EditingMode) setMode(new EditingMode());
    if (!polygon && editorRef.current) {
      editorRef.current.deleteFeatures(0);
      setMode(new DrawPolygonMode());
    }
  }, [polygon, setMode, editorRef]);

  const onUpdateHandler = useCallback(
    (e: any) => {
      if (e?.editType === 'addFeature') setMode(new EditingMode());
      if (e?.data[0]) setPolygon(e.data[0]);
    },
    [setMode, setPolygon],
  );

  return (
    <Editor
      ref={editorRef}
      style={{ width: '100%', height: '100%' }}
      clickRadius={12}
      mode={mode as any}
      onUpdate={onUpdateHandler}
      editHandleShape="circle"
      featureStyle={getFeatureStyle}
      editHandleStyle={getEditHandleStyle}
      featuresDraggable
      selectedFeatureIndex={0}
      features={polygon && ([polygon] as any)}
    />
  );
};

export default PolylineEditor;
