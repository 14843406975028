import moment, { DurationInputArg2, Moment } from 'moment';
import { PeriodsEnum } from '../../../common/reportItems/types';
import { TrendLocation } from '../../../common/types/projectsHierarchy';

export const getMinMaxMonth = (timeFrames: string[] | null): Moment[] => {
  if (timeFrames) {
    return [moment.utc(`${timeFrames[0]}-01`), moment.utc(`${timeFrames[timeFrames.length - 1]}-01`)];
  }
  return [];
};

export const getMinMaxWeek = (timeFrames: string[] | null): Moment[] => {
  if (timeFrames) {
    return [
      moment.utc(`${timeFrames[0]}-01`).startOf('isoWeek'),
      moment
        .utc(`${timeFrames[timeFrames.length - 1]}-01`)
        .endOf('month')
        .startOf('isoWeek'),
    ];
  }
  return [];
};

export const getAllMonthsBetweenPeriod = (minDate: Moment, maxDate: Moment): number[] =>
  getAllTimeFramesBetweenPeriod(minDate, maxDate, 'M', 1, 'month');

export const getAllWeeksBetweenPeriod = (minDate: Moment, maxDate: Moment): number[] =>
  getAllTimeFramesBetweenPeriod(minDate, maxDate, 'D', 1, 'weeks');

export const getAllTimeFramesBetweenPeriod = (
  minDate: Moment,
  maxDate: Moment,
  format: string,
  amount: number,
  unit: DurationInputArg2,
): number[] => {
  const timeValues: number[] = [];

  while (maxDate > minDate || minDate.format(format) === maxDate.format(format)) {
    timeValues.push(minDate.valueOf());
    minDate.add(amount, unit);
  }
  return timeValues;
};

export const populateWithMissingTimeFrames = (timeFrames: string[] | null, period: PeriodsEnum, data: any[]) => {
  if (timeFrames === null) {
    return;
  }
  data.forEach((location) => {
    let dates: number[] = [];
    if (PeriodsEnum.MONTHLY === period) {
      const [minDate, maxDate] = getMinMaxMonth(timeFrames);
      dates = getAllMonthsBetweenPeriod(minDate, maxDate);
    } else if (PeriodsEnum.WEEKLY === period) {
      const [minDate, maxDate] = getMinMaxWeek(timeFrames);
      dates = getAllWeeksBetweenPeriod(minDate, maxDate);
    }

    location.data = dates.map((date) => {
      const item: any | undefined = (location.data || []).find((item: any) => Number(item.date) === date);
      const updatedItem: any = {
        date,
        value: item ? item.value : '-',
        confidence_level: item && item.confidence_level ? item.confidence_level : '-',
        is_relative_shown: item && item.is_relative_shown ? item.is_relative_shown : '-',
      };
      return updatedItem;
    });
  });
};

export const populateWithMissingTimeFramesInitData = (
  timeFrames: string[] | null,
  period: PeriodsEnum,
  data: TrendLocation[] = [],
) => {
  if (timeFrames === null) {
    return data;
  }

  return data.map((location) => {
    let dates: number[] = [];
    if (PeriodsEnum.MONTHLY === period) {
      const [minDate, maxDate] = getMinMaxMonth(timeFrames);
      dates = getAllMonthsBetweenPeriod(minDate, maxDate);
    } else if (PeriodsEnum.WEEKLY === period) {
      const [minDate, maxDate] = getMinMaxWeek(timeFrames);
      dates = getAllWeeksBetweenPeriod(minDate, maxDate);
    }

    const locationData = dates.map((date) => {
      const item = location.data.find((item) => Number(item.date) === date);
      const updatedItem = {
        date,
        allLocations: item ? item.allLocations : '-',
        singleLocation: item ? item.singleLocation : '-',
        absoluteLocation: item ? item.absoluteLocation : '-',
      };
      return updatedItem;
    });

    return { ...location, data: locationData };
  });
};
