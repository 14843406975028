import { ReportItemType } from '../reportItems/types';
import { PrimaryValue } from '../../reportItems/interfaces';
import { CrossVisitationData } from '../../reportItems/crossVisitation/types';
import { TrendModifiedData } from '../../reportItems/trends/types';
import { Point, Area, IndexArea, IndexPoints, IndexMultiArea } from './visualizationObjects';
import { RetentionModifiedData } from '../../reportItems/retention/types';
import { AnalyticsData, MovementData, MovementHotspotsData } from '../../reportItems/movement/types';
import { CatchmentItemData, CatchmentItemValue } from '../../reportItems/catchment/types';
import { IReportCategory } from '../../../types/report';

type ReportItemVisualization = {
  viewport?: number[];
  points?: Point[];
  poiPolygons?: Area[];
  aoiPolygons?: Area[];
  indexPolygons?: IndexArea[];
  multiLocationPolygons?: IndexMultiArea;
  heatmap?: IndexPoints[];
  tripPoints?: IndexPoints[];
};

export type LocationInfo = {
  id: string;
  name: string;
  description: string;
  address: string;
  street: string;
  houseNumber: string;
  city: string;
  country: string;
  geometry: string;
  longitude: number | null;
  latitude: number | null;
  color?: string;
  image?: string;
};

export type ReportItemInfo<T> = {
  id: string;
  name: string;
  createdDate?: string;
  type: ReportItemType;
  data: T | null;
  visualization: ReportItemVisualization | null;
  settings: ReportItemSetting[];
};

export type VersionInfo = {
  product: string;
  frontend: string;
  catchment: string;
  movement: string;
};

export type ReportItemSetting = {
  name: string;
  value: string;
};

export type ReportInfo = {
  id: string;
  name: string;
  description: string | null;
  category: IReportCategory;
  created_date: string | null;
  start_date: string;
  end_date: string;
  viewport: number[] | null;
  locations_count: number | null;
  report_items: ReportItemInfo<ReportItems>[];
  location_ids: string[] | null;
  creator: string;
  numberOfSignals: number | null;

  report_category: string;
  status: string;
  visualization_url: string;

  recurring?: boolean;
  recurrence_frequency?: string;

  version?: VersionInfo;
};

export type ProjectInfo = {
  id: string;
  name: string;
  description: string | null;
  created_date: string | null;
  locations_count: number | null;
  picture_url: string | null;
  reports: ReportInfo[];
  locations: LocationInfo[];
};

export type ProjectsHierarchy = ProjectInfo[];

export type ProjectSelection = {
  selectedProject: ProjectInfo | null;
  selectedReport: ReportInfo | null;
  selectedReportItem: ReportItemInfo<ReportItems> | null;
};

export interface ExportProjectSelection {
  selectedReportItem: ReportItemInfo<ReportItems> | null;
}

export interface ReportLocation {
  locationId: string;
  country: string;
}

export type ReportItems =
  | Duration
  | Trend
  | Seasonality
  | CrossVisitation
  | Catchment
  | Retention
  | Movement
  | Absorption
  | Attraction;

export type Catchment = {
  data: CatchmentItemValue[];
  showGallery?: boolean;
  countries: string[] | null;
  reportLocations: ReportLocation[] | [];
  topArea?: CatchmentItemData;
  lowArea?: CatchmentItemData;
  maxRadius: number;
  isochronesRadius: number;
  catchmentLevel: number;
};

export interface AbsorptionData extends Location<AbsorptionTargetLocation> {
  average: number;
}

export interface Absorption {
  data: AbsorptionData[];
}

export type AbsorptionTargetLocation = {
  locationId: string;
  locationName: string;
  avgWeightedAbsorption: number;
  dateChanges: AbsorptionDateChange[];
};

export type AbsorptionDateChange = {
  timestamp: number;
  absorption: number;
};

export interface AttractionData extends Location<AttractionTargetLocation> {
  average: number;
}

export interface Attraction {
  data: AttractionData[];
  visitsFrom?: number;
}

export type AttractionTargetLocation = {
  targetLocationId: string;
  targetLocationName: string;
  avgWeightedAttraction: number;
  dateChanges: AttractionDateChange[];
};

export type AttractionDateChange = {
  timestamp: number;
  attraction: number;
};

export type Duration = {
  average: number;
  averageData: DurationData[];
  data: DurationLocation[];
};

export type SelectedTimeFrames = string[] | null;

export type Seasonality = {
  hourlyByWeek: LocationHourlySeasonality[];
  hourlyByDay: LocationHourlySeasonality[];
  daily: LocationDailySeasonality[];
  averageDailyAcrossLocations: DailySeasonality[];
  averageHourlyByWeekAcrossLocations: HourlySeasonality[];
  averageHourlyByDayAcrossLocations: HourlySeasonality[];
};

export interface LocationDailySeasonality {
  locationId: string;
  locationName: string;
  mostPopularDay: DailySeasonality;
  data: DailySeasonality[];
}

export interface CrossVisitation {
  crossVisitationAverage: number;
  singleLocationAverage: number;
  data: CrossVisitationLocation[];
}

export interface DailySeasonality {
  dayOfWeekNumber: number;
  visitsShare: number;
}

export interface LocationHourlySeasonality {
  locationId: string;
  locationName: string;
  mostPopularHour: HourlySeasonality;
  data: HourlySeasonality[];
  average: HourlySeasonality[];
}

export interface HourlySeasonality {
  dayOfWeekNumber: number;
  hourOfDay: number;
  visitsShare: number;
}

export interface Trend {
  visitCount: {
    allLocations: number;
    absoluteNumber: number;
    singleLocation: number;
    locationsCountVisits: Map<
      string,
      { allLocations: number; absoluteNumber: number; singleLocation: number; confidenceLevel?: number | boolean }
    >;
  };
  averageByDifferentMetricsInLine: {
    absoluteNumber: { [key: string]: number[] };
    singleLocations: { [key: string]: number[] };
  };
  mapPointsData: TrendsMapPointsData[];
  dates: number[];
  data: TrendLocation[];
  fromMonthToMonthAverageChange: {
    allLocations: number;
    absoluteNumber: number;
    singleLocations: number;
  };
  isAbsoluteNumbersDisabled: boolean;
}

export interface TrendsMapPointsData {
  location_id: string;
  location_name: string;
  confidenceLevel?: number | boolean;
  average: TrendAverage;
}

export interface TrendAverage {
  allLocations: number;
  singleLocation: number;
  absoluteLocation: number;
}

export interface Retention {
  data: RetentionLocation[];
  averageInfo: {
    returningAverage: number;
    newVisitsAverage: number;
    returningVisitsAcrossLocations: RetentionModifiedData[];
    returningVisitsLocationsAverage: Map<string, number>;
  };
}

export interface Movement {
  data: MovementLocation[];
  analyticsData?: { [key: string]: AnalyticsData | null };
  hotspotsData?: MovementHotspotsData;
  showGallery?: boolean;
  showAnalytics?: boolean;
  maxDistance: number;
  timeFrame: number;
}

export interface Location<T> {
  location_id: string;
  location_name: string;
  location_average?: number;
  data: T[];
}

export type DurationLocation = Location<DurationData>;
export type TrendLocation = Location<TrendModifiedData>;
export type CrossVisitationLocation = PrimaryValue<CrossVisitationData>;
export type RetentionLocation = Location<RetentionModifiedData>;
export type MovementLocation = Location<MovementData>;

export type DurationData = {
  category: DurationCategory;
  value: number;
};

export enum DurationCategory {
  '0-5' = '0-5 Min',
  '5-15' = '5-15 Min',
  '15-30' = '15-30 Min',
  '30-60' = '30-60 Min',
  '60-120' = '60-120 Min',
  '120+' = '120+ Min',
}

export enum ContentVisualizationMode {
  MAP = 'Map',
  VISUALIZATION = 'Visualization',
  TABLE = 'Table',
}
