import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';
import { useTooltipStartPosition } from '../../common/reportItems/hooks';
import ContentContainer from '../../common/page/pageContent/contentContainer';
import ButtonsContainer from '../../common/page/pageContent/buttonsContainer';
import Table from '../../common/page/table/table';
import TableRow from '../../common/page/table/tableRow/tableRow';
import { SidebarContext } from '../../common/sidebarMenu/sidebarContext';
import { Button } from '../../common/controls/buttons';
import { LocationsData, ReportItemsData } from '../types';
import {
  DurationIcon,
  TrendIcon,
  SeasonalityIcon,
  CrossVisitationIcon,
  CatchmentIcon,
  RetentionIcon,
  MovementIcon,
  AbsorptionIcon,
  AttractionIcon,
} from '../../common/icons/reportItems';
import styles from './stepThree.module.scss';
import { FullFilledSwitcher } from '../../common/switchers';
import { CrossVisitationMainTooltip } from '../../reportItems/crossVisitation/card/tooltips';
import { RetentionMainTooltip } from '../../reportItems/retention/card/tooltips';
import { MovementMainTooltip } from '../../reportItems/movement/card/tooltips';
import { SeasonalityMainTooltip } from '../../reportItems/seasonality/card';
import { VisitTrendsMainTooltip } from '../../reportItems/trends/card/tooltips/main';
import { DurationMainTooltip } from '../../reportItems/duration/card/tooltips';
import { CatchmentMainTooltip } from '../../reportItems/catchment/card/tooltip';
import { AbsorptionMainTooltip } from '../../reportItems/absorption/card/tooltips';
import { AttractionMainTooltip } from '../../reportItems/attraction/card/tooltips';
import { AttractionSettings } from './controls/attraction/attractionSettings';
import { AbsorptionSettings } from './controls/absorption/absorptionSettings';

interface TProps {
  data: ReportItemsData;
  setData: (data: ReportItemsData) => void;
  locationsData: LocationsData;
  navigate: (step: number) => void;
  onSave: VoidFunction;
  errorMessage: string | null;
  onCancel: VoidFunction;
  isAoi: boolean;
}

export const StepThree = ({
  data,
  setData,
  locationsData,
  navigate,
  onSave,
  errorMessage,
  onCancel,
  isAoi,
}: TProps) => {
  const { t } = useTranslation();
  const { isOpened } = useContext(SidebarContext);

  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);

  const { tooltipIconRef: crossVisitationRef, position: crossVisitationPosition } =
    useTooltipStartPosition('scrollable-table-content');
  const { tooltipIconRef: catchmentRef, position: catchmentPosition } =
    useTooltipStartPosition('scrollable-table-content');
  const { tooltipIconRef: retentionRef, position: retentionPosition } =
    useTooltipStartPosition('scrollable-table-content');
  const { tooltipIconRef: movementRef, position: movementPosition } =
    useTooltipStartPosition('scrollable-table-content');
  const { tooltipIconRef: seasonalityRef, position: seasonalityPosition } =
    useTooltipStartPosition('scrollable-table-content');
  const { tooltipIconRef: visitTrendsRef, position: visitTrendsPosition } =
    useTooltipStartPosition('scrollable-table-content');

  const { tooltipIconRef: durationRef, position: durationPosition } =
    useTooltipStartPosition('scrollable-table-content');
  const { tooltipIconRef: absorptionRef, position: absorptionPosition } =
    useTooltipStartPosition('scrollable-table-content');
  const { tooltipIconRef: attractionRef, position: attractionPosition } =
    useTooltipStartPosition('scrollable-table-content');

  const closeTooltip = () => {
    setActiveTooltip(null);
  };

  useEffect(() => {
    trackUserAction('Create report', UserActionsEnum.CREATE_REPORT, 'Step 3');
  }, []);

  return (
    <>
      <ContentContainer isOpened={isOpened} className={styles.stepThreeContainer} spacing>
        <Table title="Set up data" subtitle="Check out the report items" blueTitle={`${t('create.step')} 3`}>
          <TableRow
            className={styles.tableRow}
            classNameNameContainer={styles.nameContainer}
            name={
              <span>
                <TrendIcon />
              </span>
            }
            value={
              <div className={styles.reportItem}>
                <span className={styles.reportItemTitle}>{t('visitTrends.title')}</span>
                <span className={styles.reportItemSubtitle}>
                  {t('visitTrends.subtitle')}{' '}
                  <span
                    className={styles.readMore}
                    onClick={() => setActiveTooltip('visitTrends')}
                    ref={visitTrendsRef}
                  >
                    {t('create.readMore')}
                  </span>
                </span>
              </div>
            }
          />
          <TableRow
            className={styles.tableRow}
            classNameNameContainer={styles.nameContainer}
            name={
              <span>
                <CatchmentIcon />
              </span>
            }
            value={
              <div className={styles.reportItem}>
                <span className={styles.reportItemTitle}>{t('catchment.title')}</span>
                <span className={styles.reportItemSubtitle}>
                  {t('catchment.subtitle')}{' '}
                  <span className={styles.readMore} onClick={() => setActiveTooltip('catchment')} ref={catchmentRef}>
                    {t('create.readMore')}
                  </span>
                </span>
              </div>
            }
          />
          <TableRow
            className={styles.tableRow}
            classNameNameContainer={styles.nameContainer}
            name={
              <span>
                <SeasonalityIcon />
              </span>
            }
            value={
              <div className={styles.reportItem}>
                <span className={styles.reportItemTitle}>{t('seasonality.title')}</span>
                <span className={styles.reportItemSubtitle}>
                  {t('seasonality.subtitle')}{' '}
                  <span
                    className={styles.readMore}
                    onClick={() => setActiveTooltip('seasonality')}
                    ref={seasonalityRef}
                  >
                    {t('create.readMore')}
                  </span>
                </span>
              </div>
            }
          />
          <TableRow
            className={styles.tableRow}
            classNameNameContainer={styles.nameContainer}
            name={
              <span>
                <DurationIcon />
              </span>
            }
            value={
              <div className={styles.reportItem}>
                <span className={styles.reportItemTitle}>{t('duration.title')}</span>
                <span className={styles.reportItemSubtitle}>
                  {t('duration.subtitle')}{' '}
                  <span className={styles.readMore} onClick={() => setActiveTooltip('duration')} ref={durationRef}>
                    {t('create.readMore')}
                  </span>
                </span>
              </div>
            }
          />
          <TableRow
            className={styles.tableRow}
            classNameNameContainer={styles.nameContainer}
            name={
              <span>
                <RetentionIcon />
              </span>
            }
            value={
              <div className={styles.reportItem}>
                <span className={styles.reportItemTitle}>{t('retention.title')}</span>
                <span className={styles.reportItemSubtitle}>
                  {t('retention.subtitle')}{' '}
                  <span className={styles.readMore} onClick={() => setActiveTooltip('retention')} ref={retentionRef}>
                    {t('create.readMore')}
                  </span>
                </span>
              </div>
            }
          />
          {!isAoi && (
            <TableRow
              className={styles.tableRow}
              classNameNameContainer={styles.nameContainer}
              name={
                <span>
                  <CrossVisitationIcon />
                </span>
              }
              value={
                <div className={styles.reportItem}>
                  <span className={styles.reportItemTitle}>{t('crossVisitation.title')}</span>
                  <span className={styles.reportItemSubtitle}>
                    {t('crossVisitation.subtitle')}{' '}
                    <span
                      className={styles.readMore}
                      onClick={() => setActiveTooltip('crossVisitation')}
                      ref={crossVisitationRef}
                    >
                      {t('create.readMore')}
                    </span>
                  </span>
                </div>
              }
            />
          )}
          <TableRow
            className={styles.tableRow}
            classNameNameContainer={styles.nameContainer}
            name={
              <span>
                <MovementIcon />
              </span>
            }
            value={
              <div className={styles.reportItem}>
                <span className={styles.reportItemTitle}>{t('movement.title')}</span>
                <span className={styles.reportItemSubtitle}>
                  {t('movement.subtitle')}{' '}
                  <span className={styles.readMore} onClick={() => setActiveTooltip('movement')} ref={movementRef}>
                    {t('create.readMore')}
                  </span>
                </span>
              </div>
            }
          />
          {!isAoi && (
            <>
              <div className={styles.additionalDataPoints}>{t('create.additionalDataPoints')}</div>
              <TableRow
                className={styles.tableRow}
                classNameNameContainer={styles.nameContainer}
                name={
                  <span>
                    <AttractionIcon />
                  </span>
                }
                value={
                  <div className={styles.reportItem}>
                    <span className={styles.reportItemTitle}>
                      {t('attraction.title')}
                      {'  '}
                      <FullFilledSwitcher
                        value={data.attraction_isActive}
                        onChange={() => setData({ ...data, attraction_isActive: !data.attraction_isActive })}
                        // disabled
                      />
                    </span>
                    <span className={styles.reportItemSubtitle}>
                      {t('attraction.subtitle')}{' '}
                      <span
                        className={styles.readMore}
                        onClick={() => setActiveTooltip('attraction')}
                        ref={attractionRef}
                      >
                        {t('create.readMore')}
                      </span>
                    </span>
                    {data.attraction_isActive && (
                      <AttractionSettings data={data} locationsData={locationsData} onChange={setData} />
                    )}
                  </div>
                }
              />
              <TableRow
                className={styles.tableRow}
                classNameNameContainer={styles.nameContainer}
                name={
                  <span>
                    <AbsorptionIcon />
                  </span>
                }
                value={
                  <div className={styles.reportItem}>
                    <span className={styles.reportItemTitle}>
                      {t('absorption.title')}
                      {'  '}
                      <FullFilledSwitcher
                        value={data.absorption_isActive}
                        onChange={() => setData({ ...data, absorption_isActive: !data.absorption_isActive })}
                        // disabled
                      />
                    </span>
                    <span className={styles.reportItemSubtitle}>
                      {t('absorption.subtitle')}{' '}
                      <span
                        className={styles.readMore}
                        onClick={() => setActiveTooltip('absorption')}
                        ref={absorptionRef}
                      >
                        {t('create.readMore')}
                      </span>
                    </span>
                    {data.absorption_isActive && (
                      <AbsorptionSettings data={data} onChange={setData} locationsData={locationsData} />
                    )}
                  </div>
                }
              />
            </>
          )}
        </Table>

        <ButtonsContainer className={styles.buttonContainer}>
          <div>
            <Button onClick={() => navigate(2)}>{t('create.back')}</Button>
            <Button
              layout="primary"
              onClick={onSave}
              disabled={
                (data.attraction_isActive &&
                  (data.attraction_aois.length === 0 || data.attraction_pois.length === 0)) ||
                (data.absorption_isActive &&
                  data.absorption_areaType === 'AOI' &&
                  (data.absorption_aoi === null || data.absorption_pois.length === 0))
              }
            >
              {t('create.createReport')}
            </Button>
            {errorMessage && <div className={styles.error}>{errorMessage}</div>}
          </div>
          <Button onClick={onCancel}>{t('reportItem.cancel')}</Button>
        </ButtonsContainer>

        {/* Tooltips */}
        <CrossVisitationMainTooltip
          isOpen={activeTooltip === 'crossVisitation'}
          onClose={closeTooltip}
          position={crossVisitationPosition}
        />
        <CatchmentMainTooltip
          isOpen={activeTooltip === 'catchment'}
          onClose={closeTooltip}
          position={catchmentPosition}
        />
        <RetentionMainTooltip
          isOpen={activeTooltip === 'retention'}
          onClose={closeTooltip}
          position={retentionPosition}
        />
        <MovementMainTooltip isOpen={activeTooltip === 'movement'} onClose={closeTooltip} position={movementPosition} />
        <SeasonalityMainTooltip
          isOpen={activeTooltip === 'seasonality'}
          onClose={closeTooltip}
          position={seasonalityPosition}
        />
        <VisitTrendsMainTooltip
          isOpen={activeTooltip === 'visitTrends'}
          onClose={closeTooltip}
          position={visitTrendsPosition}
        />
        <DurationMainTooltip isOpen={activeTooltip === 'duration'} onClose={closeTooltip} position={durationPosition} />
        <AbsorptionMainTooltip
          isOpen={activeTooltip === 'absorption'}
          onClose={closeTooltip}
          position={absorptionPosition}
        />
        <AttractionMainTooltip
          isOpen={activeTooltip === 'attraction'}
          onClose={closeTooltip}
          position={attractionPosition}
        />
      </ContentContainer>
    </>
  );
};
