import React from 'react';
interface TProps {
  className: string;
}

const PlainCrossIcon = ({ className }: TProps) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.69691 6.00015L11.6484 2.04865C12.1176 1.57946 12.1176 0.821079 11.6484 0.351891C11.1792 -0.117297 10.4208 -0.117297 9.95165 0.351891L6.00015 4.30339L2.04865 0.351891C1.57946 -0.117297 0.82108 -0.117297 0.351891 0.351891C-0.117297 0.821079 -0.117297 1.57946 0.351891 2.04865L4.30339 6.00015L0.351891 9.95045C-0.117297 10.4196 -0.117297 11.178 0.351891 11.6472C0.585885 11.8824 0.893078 12 1.20027 12C1.50746 12 1.81465 11.8824 2.04865 11.6484L6.00015 7.69691L9.95165 11.6484C10.1856 11.8824 10.4928 12 10.8 12C11.1072 12 11.4144 11.8824 11.6484 11.6484C12.1176 11.1792 12.1176 10.4208 11.6484 9.95165L7.69691 6.00015Z"
      fill="currentColor"
    />
  </svg>
);

const CircleCrossIcon = ({ className }: TProps) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25ZM11.8 10.0344C11.9469 10.1813 11.9469 10.4187 11.8 10.5656L10.5625 11.8C10.4156 11.9469 10.1781 11.9469 10.0312 11.8L8 9.75L5.96562 11.8C5.81875 11.9469 5.58125 11.9469 5.43437 11.8L4.2 10.5625C4.05312 10.4156 4.05312 10.1781 4.2 10.0312L6.25 8L4.2 5.96562C4.05312 5.81875 4.05312 5.58125 4.2 5.43437L5.4375 4.19688C5.58437 4.05 5.82188 4.05 5.96875 4.19688L8 6.25L10.0344 4.2C10.1813 4.05312 10.4187 4.05312 10.5656 4.2L11.8031 5.4375C11.95 5.58437 11.95 5.82188 11.8031 5.96875L9.75 8L11.8 10.0344Z"
      fill="currentColor"
    />
  </svg>
);

type TCrossIconProps = TProps & {
  type?: 'circle' | 'plain';
};

export const CrossIcon = ({ className, type = 'plain' }: TCrossIconProps) =>
  type === 'plain' ? <PlainCrossIcon className={className} /> : <CircleCrossIcon className={className} />;
