import { LocationInfo } from '../../../common/types/projectsHierarchy';
import { Area } from '../../../common/types/visualizationObjects';
import { convertPolygonToGeoData } from '../../../common/maps/utils/convertPolygonToGeoData';

export const generateMapPoiPolygons = <T extends { location_id: string; location_name: string; group_id?: string }>(
  values: T[],
  locations: LocationInfo[],
): Area[] => {
  const polygons = values
    .map((value) => {
      const location = locations.find((loc) => loc.id === value.location_id);
      return location && location.geometry
        ? {
            location_id: value.location_id,
            name: value.location_name,
            geo: convertPolygonToGeoData(location.geometry),
            group_id: value.group_id,
          }
        : null;
    })
    .filter((item) => item && item.geo);
  return polygons as Area[];
};
