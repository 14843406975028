import React from 'react';
import { MenuItem } from '../../../common/page/menuItem/menuItem';
import { ProfilePicture } from '../../../common/page/profilePicture/profilePicture';
import { MenuItemsEnum } from './menuItemsEnum';

interface TProps {
  onClick: (item: MenuItemsEnum) => void;
  selected?: boolean;
  image: string | null;
}

export const UserProfileMenuItem = ({ onClick, selected, image }: TProps) => {
  const userName = localStorage.getItem('userName') || '';
  return (
    <MenuItem
      icon={<ProfilePicture id="menu-user-picture" image={image} width={22} height={24} />}
      id={MenuItemsEnum.USER_PROFILE}
      onClick={onClick}
      selected={!!selected}
      title={`My profile - ${userName}`}
    />
  );
};
