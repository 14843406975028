import React, { useState, useEffect } from 'react';
import styles from './locations.module.scss';
import LocationRow from './locationRow';
import { Button } from '../controls/buttons';
import { Checkbox } from '../controls/checkbox';

type SimpleLocationItem = {
  id: string;
  name: string;
  selected: boolean;
};

type TProps = {
  locations: any[];
  selectedLocations: any[];
  onChange: (locations: any[]) => void;
  onClose: () => void;
};

const LocationsList = ({ locations, selectedLocations, onChange, onClose }: TProps) => {
  const [preselected, setPreselected] = useState<SimpleLocationItem[]>([]);

  const onPreselectionChange = (id: string) => {
    const newPreselected = preselected.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setPreselected(newPreselected);
  };

  const onSelectAllClick = () => {
    if (locations?.length === preselected.filter((item) => item.selected).length) {
      const newPreselected = preselected.map((item) => ({ ...item, selected: false }));
      setPreselected(newPreselected);
    } else {
      const newPreselected = preselected.map((item) => ({ ...item, selected: true }));
      setPreselected(newPreselected);
    }
  };

  const onSave = () => {
    const newSelected =
      locations?.filter((loc) => preselected.some((item) => item.selected && item.id === loc.id)) || [];
    onChange(newSelected);
    onClose();
  };

  useEffect(() => {
    const selectionData: SimpleLocationItem[] =
      locations?.map((location) => ({
        id: location.id,
        name: location.name,
        selected: selectedLocations.some((selectedLocation) => selectedLocation.id === location.id),
      })) || [];
    setPreselected(selectionData);
  }, []);

  return (
    <div className={styles.locationsList}>
      <div className={`${styles.reportLocations} custom-scrollbar`}>
        <div className={styles.groupTitle}>
          <span>Select locations</span>
          <Checkbox
            isChecked={locations?.length === preselected.filter((item) => item.selected).length}
            disabled={!locations || locations.length === 0}
            onChange={onSelectAllClick}
            name="select-all-locations"
          />
        </div>
        {preselected.map((location) => (
          <LocationRow
            id={location.id}
            name={location.name}
            selected={location.selected}
            onClick={onPreselectionChange}
          />
        ))}
      </div>
      <div className={styles.buttons}>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          layout="primary"
          onClick={onSave}
          disabled={preselected.filter((item) => item.selected).length === 0}
          animation
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default LocationsList;
