import React from 'react';
import { IntroPageTemplate } from '../../common/templates';
import { SignIn } from '../../auth';

export const SignInPage = () => {
  return (
    <IntroPageTemplate>
      <SignIn />
    </IntroPageTemplate>
  );
};
