import React, { useState } from 'react';
import styles from './input.module.scss';
import { LockIcon } from '../icon/lock';
import { EyeIcon } from '../icon/eye';

interface TProps {
  value: string;
  onChange: (event: any) => void;
  placeholder?: string;
  error?: boolean;
}

export const PasswordInput = (props: TProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={props.error ? styles.errorInputContainer : styles.inputContainer}>
      <LockIcon className={styles.inputIcon} />
      <input
        className={styles.input}
        type={visible ? 'text' : 'password'}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder || 'Password'}
      />
      <EyeIcon onClick={() => setVisible(!visible)} />
    </div>
  );
};
