import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BackgroundMap } from '../../../../../mainPage/backgroundMap/backgroundMap';
import styles from './style.module.scss';
import { useElementScreenshot } from '../../../../hooks/useElementScreenshot';
import { MainContext } from '../../../../types/mainContext';
import { reportItems } from '../../../../reportItems/utils';
import { useStore } from 'effector-react';
import { dashboardModel } from '../../../../../dasboardLoader';
import { ReportItemType } from '../../../../reportItems/types';
import { useDebounce, useToggle } from '../../../../hooks';
import { exportSettingsModel } from '../../model';
import { backgroundMapModel } from '../../../../../mainPage/backgroundMap/model';
import { SwitchModes } from '../../../../types/visualizationObjects';

export const ExportMapContext = React.createContext({ isExportMap: false });

export const MapContainer = () => {
  const {
    selection: { selectedReport },
    updateExportProjectSelection,
    catchmentLayer,
  } = useContext(MainContext);

  const mapLoadingStatuses = useStore(backgroundMapModel.$mapLoadingStatuses);

  const reportItemTypes = useMemo(() => {
    const availableReportItems = selectedReport?.report_items.map(({ type }) => type);
    return reportItems.filter((reportItem) => availableReportItems?.includes(reportItem.type)).map(({ type }) => type);
  }, [selectedReport?.report_items]);

  const [activeReportItemType, setActiveReportItemType] = useState<ReportItemType | null>(null);
  const [activeReportItemNumber, setActiveReportItemNumber] = useState(0);
  const [idleCount, setIdleCount] = useState(0);
  const [rerenderMap, toggleRerenderMap] = useToggle(true);
  const reportItemsLoadStatuses = useStore(dashboardModel.$reportItemsLoadStatuses);
  const { base64Image, onMakeScreenshot, elementRef, onClearScreenshotData } = useElementScreenshot({});

  useEffect(() => {
    setActiveReportItemType(reportItemTypes[activeReportItemNumber]);
    const selectedReportItem = selectedReport?.report_items.find(
      ({ type }) => type === reportItemTypes[activeReportItemNumber],
    );

    if (selectedReportItem) {
      updateExportProjectSelection('selectedReportItem', selectedReportItem);
    }

    return () => {
      updateExportProjectSelection('selectedReportItem', null);
    };
  }, [reportItemTypes, activeReportItemNumber]);

  const isTripRoutes = () => {
    return activeReportItemType === ReportItemType.CATCHMENT && catchmentLayer === SwitchModes.TRIPS;
  };

  const debouncedRerenderMap = useDebounce(rerenderMap, 1000);

  const isReadyForScreenshot = useMemo(() => {
    if (activeReportItemType && debouncedRerenderMap) {
      const isSelectedLoading = reportItemsLoadStatuses[activeReportItemType];

      if (isSelectedLoading && idleCount === 1) {
        const isTripRoutesRendering = isTripRoutes();
        if (!isTripRoutesRendering) return true;
        else {
          if (mapLoadingStatuses.tripRoutes) {
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    }

    return false;
  }, [reportItemsLoadStatuses, idleCount, activeReportItemType, mapLoadingStatuses, debouncedRerenderMap]);

  useEffect(() => {
    if (isReadyForScreenshot) {
      onMakeScreenshot();
    }
  }, [isReadyForScreenshot]);

  useEffect(() => {
    if (base64Image) {
      if (activeReportItemType) {
        exportSettingsModel.updateExportData({ [activeReportItemType]: { map: base64Image } });
        toggleRerenderMap(false);
      }
    }
  }, [base64Image]);

  useEffect(() => {
    if (!debouncedRerenderMap) clearPrevAndStartNewIteration();
  }, [debouncedRerenderMap]);

  const onIdle = () => {
    setIdleCount((prevState) => prevState + 1);
  };

  const clearPrevAndStartNewIteration = () => {
    onClearScreenshotData();
    setIdleCount(0);
    toggleRerenderMap(true);
    setActiveReportItemNumber((prevState) => prevState + 1);
  };

  return (
    <ExportMapContext.Provider value={{ isExportMap: true }}>
      <div className={styles.wrapper}>
        <div className={styles.container} ref={elementRef}>
          {rerenderMap && <BackgroundMap onIdle={onIdle} />}
        </div>
      </div>
    </ExportMapContext.Provider>
  );
};
