import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import DeleteIcon from '@material-ui/icons/Delete';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { CheckCircle as CheckCircleIcon, PhotoLibrary } from '@material-ui/icons';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './reportPage.module.css';
import Page from '../../templates/page/page';
import { AppState } from '../../../reducers/reducers';
import {
  changeReportStatus,
  clearErrorMessage,
  rerunReportWithPostProcessingSettings,
  createReportItems,
  deleteReport,
  recalculateRIs,
  deleteReportItem,
  editReportItem,
  editReportItemDescription,
  loadLocations,
  loadReportItemSettingDefinitions,
  loadReportItemTypes,
  loadReports,
  updateReport,
  updateReportItemVisualizationUrl,
  generateRIThumbnails,
  editPostProcessingSettings,
  deletePostProcessingSettings,
  getAllPostProcessingSettings,
  getPostProcessingSettingsByName,
  getPostProcessingSettingsByCategory,
} from '../../../actions/actions';
import { Report, ReportItem, IReportCategory } from '../../../types/report';
import { Location } from '../../../types/location';
import Button from '../../atoms/primaryButton/button';
import CreateReportItem from '../../organisms/createReportItem/createReportItem';
import { SettingDefinitionsForItems } from '../../../types/reportItemSettingDefinition';
import { defaultReportCategoryOption } from '../createReportPage/createReportPage';

import EditReportItemModal from './editReportItemModal';
import DeleteReportItemModal from './deleteReportItemModal';
import ConfirmDeleteModal from '../../molecules/ConfirmDeleteModal/ConfirmDeleteModal';
import routes from '../../../router/routes';
import ErrorMessage from '../../organisms/errorMessage';
import CopyToClipboardButton from '../../atoms/primaryButton/copyToClipboardButton';
import { STATUS } from '../projectsPage/status';
import SuccessBox from '../../organisms/successBox/successBox';
import ConfirmRecalculateRIsModal from '../../molecules/ConfirmRecalculateRIsModal/ConfirmRecalculateRIsModal';
import Help from '../../atoms/help/help';
import {IconButton} from "@mui/material";

interface StateProps {
  reports?: Report[];
  locations?: Location[];

  settingDefinitions?: SettingDefinitionsForItems;
  reportItemTypes?: string[];
  // errorMessage: string; //For adding report item
  // reloadReportItemsInReportsPage: () => any;
}

interface DispatchProps {
  updateReportItemVisualizationUrl: Function;
  loadReports: (projectId: string) => any;
  loadLocations: (projectId: string) => any;
  deleteReport: (projectId: string, reportId: string) => any;
  recalculateRIs: (reportId: string) => any;
  updateReport: (
    reportId: string,
    name: string,
    description: string,
    visualizationURL: string,
    recurrentNotificationEmail: string,
    callback: () => void,
  ) => void;
  clearErrorMessage: () => void;
  changeReportStatus: (reportId: string, status: string) => any;
  loadReportItemSettingDefinitions: (reportCategory: string) => void; // For adding report item
  loadReportItemTypes: (reportCategory: string) => void; // For adding report item
  createReportItems: (projectId: string, report: Report) => any;
  editReportItem: (projectId: string, reportItem: ReportItem) => any;
  editReportItemDescription: (reportItem: ReportItem) => any;
  deleteReportItem: (reportItem: ReportItem) => any;
  generateRIThumbnails: (reportItemId: string) => void;
  editPostProcessingSettings: () => any; // TODO
  deletePostProcessingSettings: (name: string) => any; // TODO
  getAllPostProcessingSettings: () => any; // TODO
  getPostProcessingSettingsByName: (name: string) => any; // TODO
  getPostProcessingSettingsByCategory: (category: string) => any; // TODO
  rerunReportWithPostProcessingSettings: (settingsArr: any, report: Report) => any;
}

interface State {
  editingVisualizationUrlForReportItemId?: string;
  editedLinkUrl?: string;
  shouldLoadExternalDetails: boolean;
  fetchReportsContinuouslyInterval: NodeJS.Timeout;
  isReportCreated: boolean;
  isReportHasError: boolean;
  isDeleteReportModalVisible: boolean;
  isRecalculateRIsModalVisible: boolean;
  isInEditMode: boolean;
  reportName: string;
  reportDescription: string;
  visualizationURL?: string;
  recurrentNotificationEmail?: string;
}

type PathParams = { projectId: string; reportId: string };

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#c4b498',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

class ReportPage extends Component<StateProps & DispatchProps & RouteComponentProps<PathParams>, State> {
  constructor(props: Readonly<StateProps & DispatchProps & RouteComponentProps<PathParams>>) {
    super(props);
    this.state = {
      shouldLoadExternalDetails: true,
      fetchReportsContinuouslyInterval: setInterval(() => {
        this.props.loadReports(this.props.match.params.projectId);
      }, 50000),
      isReportCreated: false,
      isReportHasError: false,
      isDeleteReportModalVisible: false,
      isRecalculateRIsModalVisible: false,
      isInEditMode: false,
      reportName: '',
      reportDescription: '',
      recurrentNotificationEmail: '',
    };
  }

  componentDidMount(): void {
    const { projectId } = this.props.match.params;
    this.props.loadReports(projectId);
    this.props.loadLocations(projectId);

    // const fetchReportsContinuouslyInterval = setInterval(() => {
    //   this.props.loadReports(this.props.match.params.projectId);
    // }, 5000);
    // this.setState({fetchReportsContinuouslyInterval});
  }

  componentDidUpdate(
    prevProps: Readonly<StateProps & DispatchProps & RouteComponentProps<PathParams>>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (this.state.shouldLoadExternalDetails) {
      const report = this.report();
      if (report && this.props.locations) {
        this.props.loadReportItemSettingDefinitions(report.reportCategory);
        this.props.loadReportItemTypes(report.reportCategory);
        this.setState({
          shouldLoadExternalDetails: false,
          reportName: report.name,
          reportDescription: report.description,
          visualizationURL: report.visualizationURL,
          recurrentNotificationEmail: report.recurrentNotificationEmail,
          // isReportHasError: report.status === ""
        });
      }
    }
    // if(shouldReloadReport) {
    //   loadReports(this.props.match.params.projectId)
    //   shouldReloadReport = false;
    // }
  }

  componentWillUnmount(): void {
    clearInterval(this.state.fetchReportsContinuouslyInterval);
  }

  openDeleteReportModal(): void {
    this.setState({ isDeleteReportModalVisible: true });
  }

  closeDeleteReportModal(): void {
    this.setState({ isDeleteReportModalVisible: false });
  }

  openRecalculateRIsModal(): void {
    this.setState({ isRecalculateRIsModalVisible: true });
  }

  closeRecalculateRIsModal(): void {
    this.setState({ isRecalculateRIsModalVisible: false });
  }

  switchToEditMode(): void {
    this.setState({ isInEditMode: true });
  }

  switchToViewMode(): void {
    const report = this.report();
    this.setState({
      isInEditMode: false,
      reportName: (report && report.name) || '',
      reportDescription: (report && report.description) || '',
      visualizationURL: (report && report.visualizationURL) || '',
      recurrentNotificationEmail: (report && report.recurrentNotificationEmail) || '',
    });
    this.props.clearErrorMessage();
  }

  updateFormField({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prevState) => ({ ...prevState, [name]: value }));
  }

  updateReportDescription(e: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ reportDescription: e.target.value });
  }

  updateReport(): void {
    const report = this.report();
    report &&
      this.props.updateReport(
        report.id || '',
        this.state.reportName,
        this.state.reportDescription,
        this.state.visualizationURL || '',
        this.state.recurrentNotificationEmail || '',
        () => this.switchToViewMode(),
      );
  }

  isNotFFIReport(category: string): boolean {
    return category !== 'FFI';
  }

  getReportStatus(category: string): string {
    if (category === 'FFI' || category === 'EMPTY') {
      return 'Completed';
    }
    return this.state.isReportCreated ? (this.state.isReportHasError ? 'ERROR' : 'Active') : 'In Progress...';
  }

  private renderReportDetails(report: Report) {
    const { projectId } = this.props.match.params;
    if (!report.reportCategory) {
      report.reportCategory = defaultReportCategoryOption;
    }

    const isVisibleHandler = (reportId: string, currentStatus: string) => {
      const status = currentStatus.toLowerCase() !== STATUS.PRESENTABLE ? STATUS.PRESENTABLE_VALUE : STATUS.READY_VALUE;
      this.props.changeReportStatus(reportId, status);
    };

    const isDisabled = () =>
      !report.status ||
      (report.status !== STATUS.PRESENTABLE_VALUE &&
        report.status !== STATUS.READY_VALUE &&
        report.reportCategory !== IReportCategory.FFI);

    return (
      <div className={styles.reportDetails}>
        <div className={styles.detailsRow}>
          <FormControlLabel
            className={styles.isVisible}
            onClick={(e) => {
              e.preventDefault();
              if (!isDisabled()) {
                isVisibleHandler(report.id || '', report.status || '');
              }
            }}
            control={
              <Switch
                checked={report.status?.toLowerCase() === STATUS.PRESENTABLE}
                name="isVisible"
                disabled={isDisabled()}
              />
            }
            label="Is Visible"
          />
        </div>
        <div className={`${styles.detailsRow} ${styles.verticalAlign}`}>
          <label htmlFor="report-name" className={styles.detailsProperty}>
            Report name
          </label>
          {this.state.isInEditMode ? (
            <input
              id="report-name"
              className={styles.reportInputField}
              type="text"
              name="reportName"
              value={this.state.reportName}
              onChange={this.updateFormField.bind(this)}
              autoComplete="off"
            />
          ) : (
            <div className={styles.detailsValue}>{report.name}</div>
          )}
        </div>
        <div className={styles.detailsRow}>
          <label htmlFor="report-description" className={styles.detailsProperty}>
            Report description
          </label>
          {this.state.isInEditMode ? (
            <textarea
              id="report-description"
              className={styles.reportDescription}
              rows={6}
              value={this.state.reportDescription}
              onChange={(e) => this.updateReportDescription(e)}
              autoComplete="off"
            />
          ) : (
            <div className={styles.detailsValue}>{report.description}</div>
          )}
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsProperty}>Report category</div>
          <div className={styles.detailsValue}>{report.reportCategory}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsProperty}>Report id</div>
          <CopyToClipboardButton text={report.id || ''}>
            <div className={styles.detailsValue}>{report.id}</div>
          </CopyToClipboardButton>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsProperty}>Assigned POIs</div>
          <div className={styles.detailsValue}>{this.reportPlaces(report.locations)}</div>
        </div>
        <div className={styles.detailsRow}>
          <div className={styles.detailsProperty}>Created</div>
          <div className={styles.detailsValue}>{this.formatDate(report.date)}</div>
        </div>
        {this.isNotFFIReport(report.reportCategory) && (
          <div className={styles.detailsRow}>
            <div className={styles.detailsProperty}>Report period</div>
            <div className={styles.detailsValue}>
              {`${this.formatDate(report.startDay)} - ${this.formatDate(report.endDay)}`}
            </div>
          </div>
        )}
        <div className={styles.detailsRow}>
          <div className={styles.detailsProperty}>Status</div>
          <div className={styles.detailsValue}>{this.getReportStatus(report.reportCategory)}</div>
        </div>
        <div className={`${styles.detailsRow} ${styles.verticalAlign}`}>
          <label htmlFor="visualizationURL" className={styles.detailsProperty}>
            Visualization URL
          </label>
          {this.state.isInEditMode ? (
            <input
              id="visualizationURL"
              className={styles.reportInputField}
              type="url"
              name="visualizationURL"
              value={this.state.visualizationURL}
              onChange={this.updateFormField.bind(this)}
              autoComplete="off"
            />
          ) : report.visualizationURL ? (
            <a className={styles.detailsValue} href={report.visualizationURL}>
              link
            </a>
          ) : (
            <span>-</span>
          )}
        </div>
        {report.recurring && (
          <>
            <div className={`${styles.detailsRow} ${styles.verticalAlign}`}>
              <label htmlFor="recurrenceFrequency" className={styles.detailsProperty}>
                Recurrence Frequency
              </label>
              <span className={styles.detailsValue} id="recurrenceFrequency">
                {report.recurrence_frequency}
              </span>
            </div>
            <div className={`${styles.detailsRow} ${styles.verticalAlign}`}>
              <div className={`${styles.detailsProperty} ${styles.detailsPropertyTooltip}`}>
                <label htmlFor="recurrentNotificationEmail">Notification Email on Recurrent Update </label>
                <Help helpMessage="Emails separated by comma, for example test@test.com,test@email.com" />
              </div>
              {this.state.isInEditMode ? (
                <input
                  id="recurrentNotificationEmail"
                  className={styles.reportInputField}
                  type="text"
                  name="recurrentNotificationEmail"
                  value={this.state.recurrentNotificationEmail}
                  onChange={this.updateFormField.bind(this)}
                  autoComplete="off"
                />
              ) : (
                <span className={styles.detailsValue} id="recurrentNotificationEmail">
                  {report.recurrentNotificationEmail && report.recurrentNotificationEmail.trim().length > 0
                    ? report.recurrentNotificationEmail
                    : '-'}
                </span>
              )}
            </div>
          </>
        )}
        <div className={styles.reportItems}>
          <div>
            <CreateReportItem
              isEnable={this.state.isReportCreated && this.getReportStatus(report.reportCategory) !== 'ERROR'}
              projectId={projectId}
              report={report}
              settingDefinitions={this.props.settingDefinitions}
              reportItemTypes={this.props.reportItemTypes}
              createReportItems={(report: Report) => this.props.createReportItems(projectId, report)}
              reloadReportItemsInReportsPage={() => this.props.loadReports(projectId)} // loadReports
              rerunReportWithPostProcessingSettings={(postProcessingSettings: any) =>
                this.props.rerunReportWithPostProcessingSettings(postProcessingSettings, report)
              }
              locations={this.props.locations}
            />
          </div>
          <h2>Report Items</h2>
          <div className={styles.reportItemsHeaderRow}>
            <div>Item ID</div>
            <div>Name</div>
            <div>Type</div>
            <div>Created</div>
            <div>Status</div>
            <div>Options</div>
            <div>Visualization URL</div>
            <div />
          </div>
          {this.renderReportItems(report)}
        </div>
      </div>
    );
  }

  private renderReportItems(report: Report) {
    if (report) {
      return report.reportItems
        .filter((item) => !item.status?.includes('deleted'))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((reportItem) => {
          reportItem.reportId = report.id;
          if (!this.state.isReportCreated && this.isReportItemStartedRunning(reportItem)) {
            this.setState({ isReportCreated: true });
            if (reportItem.status === 'error') {
              this.setState({ isReportHasError: true });
            }
          }
          return (
            <div key={reportItem.id} className={styles.reportItemRow}>
              <CopyToClipboardButton text={reportItem.id || ''}>
                <div>{reportItem.id}</div>
              </CopyToClipboardButton>
              <div>{reportItem.name}</div>
              <div>{reportItem.reportItemType}</div>
              <div>{reportItem.date}</div>
              <div>{reportItem.status}</div>
              {this.renderOptionsButtons(report, reportItem)}
              {this.renderLinkOrEditInput(reportItem)}
              <div>{this.renderActionButtons(reportItem)}</div>
            </div>
          );
        });
    }
    return null;
  }

  private isReportItemStartedRunning(reportItem: ReportItem) {
    return reportItem && reportItem.status && !reportItem.status.includes('initiated');
  }

  private renderActionButtons(reportItem: ReportItem) {
    const { projectId } = this.props.match.params;
    const { reportId } = this.props.match.params;
    if (this.state.editingVisualizationUrlForReportItemId) {
      return (
        <div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                this.props.updateReportItemVisualizationUrl(
                  projectId,
                  reportId,
                  reportItem.id,
                  this.state.editedLinkUrl,
                );
                this.closeEditingLink();
              }}
              disabled={this.state.editedLinkUrl === undefined || this.state.editedLinkUrl === ''}
            >
              Save
            </Button>
          </div>
          <div className={styles.buttonContainer}>
            <Button secondary onClick={() => this.closeEditingLink()}>
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    return (
      <Button
        onClick={() =>
          this.setState({
            editingVisualizationUrlForReportItemId: reportItem.id,
            editedLinkUrl: reportItem.visualizationUrl,
          })
        }
      >
        Add link
      </Button>
    );
  }

  private closeEditingLink() {
    this.setState({ editingVisualizationUrlForReportItemId: undefined, editedLinkUrl: undefined });
  }

  private renderOptionsButtons(report: Report, reportItem: ReportItem) {
    const { reportItemType } = reportItem;
    return this.state.isReportCreated ? (
      <div className={styles.optionsField}>
        <div className={styles.optionsFieldInnerRow}>
          {this.renderItemInfoButton(reportItem)}
          {this.renderItemEditButton(reportItem)}
          {this.renderItemDuplicateButton(report, reportItem)}
          {reportItemType === 'movement' || reportItemType === 'catchment'
            ? this.renderGenerateThumbnailsButton(reportItem)
            : ' '}
        </div>
        <div className={styles.optionsDeleteFieldRow}>{this.renderItemDeleteButton(reportItem)}</div>
      </div>
    ) : (
      <div>In Progress...</div>
    );
  }

  private renderItemInfoButton(reportItem: ReportItem) {
    return (
      <div>
        <HtmlTooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={
            <>
              <Typography color="inherit">
                <b>{reportItem.name}</b>
              </Typography>
              <hr />
              <table>
                <tr>
                  <td>
                    <b>Description:</b>
                  </td>
                  <td>{reportItem.description}</td>
                </tr>
                <tr>
                  <td>
                    <b>Type:</b>
                  </td>
                  <td>{reportItem.reportItemType}</td>
                </tr>
                <tr>
                  <td>
                    <b>ID:</b>
                  </td>
                  <td>{reportItem.id}</td>
                </tr>
                <tr>
                  <td>
                    <b>Created:</b>
                  </td>
                  <td>{reportItem.date}</td>
                </tr>
                <tr>
                  <td>
                    <b>Status:</b>
                  </td>
                  <td>{reportItem.status}</td>
                </tr>
              </table>
              <hr />
              <p>
                <b>
                  <u>Settings:</u>
                </b>
              </p>
              <table>
                {reportItem.settings.map((setting) => (
                  <tr key={setting.name}>
                    <td>
                      <b>{setting.name}:</b>
                    </td>
                    <td>
                      <i>{setting.value}</i>
                    </td>
                  </tr>
                ))}
              </table>
            </>
          }
          arrow
        >
          <InfoIcon
            className={styles.optionIcon}
            fontSize="inherit"
            onClick={() => {
              this.infoAction('onClick');
            }}
          />
        </HtmlTooltip>
      </div>
    );
  }

  private infoAction(msg: string) {}

  private renderItemEditButton(reportItem: ReportItem) {
    const allSettingsDefinitions = this.props.settingDefinitions;
    const report = this.report();
    const category = report?.reportCategory ? report.reportCategory : defaultReportCategoryOption;
    const fullRiType = `${this.report()?.reportCategory}.${reportItem.reportItemType}`;
    const settingsDefinitionsForReportType = (this.props.settingDefinitions || {})[fullRiType];
    if (!settingsDefinitionsForReportType || settingsDefinitionsForReportType.length === 0) {
      const category = this.report()?.reportCategory;
      if (category) {
        loadReportItemSettingDefinitions(category);
      }
    }
    return (
      <EditReportItemModal
        reportItem={reportItem}
        settingDefinitions={settingsDefinitionsForReportType}
        editReportItem={(reportItem: ReportItem) =>
          this.props.editReportItem(this.props.match.params.projectId, reportItem)
        }
        saveEditedDescription={(reportItem: ReportItem) => this.props.editReportItemDescription(reportItem)}
        reloadReportItemsInReportsPage={() => this.props.loadReports(this.props.match.params.projectId)} // loadReports
      />
    );
  }

  private renderItemDuplicateButton(report: Report, reportItem: ReportItem) {
    const { projectId } = this.props.match.params;
    return (
      <div>
        <CreateReportItem
          isEnable={this.state.isReportCreated}
          projectId={projectId}
          report={report}
          settingDefinitions={this.props.settingDefinitions}
          reportItemTypes={this.props.reportItemTypes}
          createReportItems={(report: Report) => this.props.createReportItems(projectId, report)}
          reloadReportItemsInReportsPage={() => this.props.loadReports(projectId)} // loadReports
          reportItemsToDuplicate={[reportItem]}
          rerunReportWithPostProcessingSettings={(postProcessingSettings: any) =>
            this.props.rerunReportWithPostProcessingSettings(postProcessingSettings, report)
          }
          locations={this.props.locations}
        />
      </div>
    );
  }

  private renderGenerateThumbnailsButton(reportItem: ReportItem) {
    return (
      <Tooltip title="Generate Image Gallery">
        <PhotoLibrary
          fontSize="inherit"
          className={styles.optionIcon}
          onClick={() => {
            this.props.generateRIThumbnails(reportItem.id || '');
          }}
        />
      </Tooltip>
    );
  }

  private renderItemDeleteButton(reportItem: ReportItem) {
    return (
      <DeleteReportItemModal
        reportItem={reportItem}
        deleteReportItem={(reportItem: ReportItem) => this.props.deleteReportItem(reportItem)}
        reloadReportItemsInReportsPage={() => this.props.loadReports(this.props.match.params.projectId)} // loadReports
      />
    );
  }

  private renderLinkOrEditInput(reportItem: ReportItem) {
    if (this.state.editingVisualizationUrlForReportItemId === reportItem.id) {
      return (
        <input
          className={styles.input}
          value={this.state.editedLinkUrl}
          onChange={(event) => this.setState({ editedLinkUrl: event.target.value })}
        />
      );
    }
    return reportItem.visualizationUrl ? (
      <a href={reportItem.visualizationUrl} target="_blank" rel="noreferrer">
        link
      </a>
    ) : (
      <div>empty</div>
    );
  }

  render() {
    const { projectId } = this.props.match.params;
    const target = routes.reports.create.withId(projectId);
    const report = this.report();
    if (report && this.props.locations) {
      return (
        <Page title="null">
          <SuccessBox />
          <div className={styles.titleContainer}>
            {this.state.isDeleteReportModalVisible && (
              <ConfirmDeleteModal
                title={`Delete report "${report.name}"?`}
                isVisible={this.state.isDeleteReportModalVisible}
                onDeleteRedirectURL={routes.reports.list.withId(projectId)}
                onClose={() => this.closeDeleteReportModal()}
                onDelete={() => this.props.deleteReport(projectId, report.id || '')}
              />
            )}
            {this.state.isRecalculateRIsModalVisible && (
              <ConfirmRecalculateRIsModal
                title="Are you sure you would like to calculate all report items again?"
                isVisible={this.state.isRecalculateRIsModalVisible}
                onRecalculateRIsRedirectURL={routes.reports.list.withId(projectId)}
                onClose={() => this.closeRecalculateRIsModal()}
                onRecalculateRIs={() => this.props.recalculateRIs(report.id || '')}
              />
            )}
            <h1 className={styles.title} data-test="page-title">
              Report Details
            </h1>
            <div>
              {this.state.isInEditMode && (
                <>
                  <CheckCircleIcon
                    fontSize="inherit"
                    className={styles.optionSaveReportIcon}
                    onClick={() => this.updateReport()}
                  />
                  <CancelIcon
                    fontSize="inherit"
                    className={styles.optionDiscardReportChangesIcon}
                    onClick={() => this.switchToViewMode()}
                  />
                </>
              )}
              {!this.state.isInEditMode && (
                <>
                  <EditIcon
                    className={styles.optionEditIcon}
                    fontSize="inherit"
                    onClick={() => this.switchToEditMode()}
                  />
                  <NavLink
                    to={{
                      pathname: target,
                      state: { sourceReport: report },
                    }}
                    title="Duplicate Report"
                  >
                    <FileCopyIcon className={styles.duplicateReportIcon} fontSize="inherit" />
                  </NavLink>
                  <DeleteIcon
                    className={styles.optionDeleteIcon}
                    fontSize="inherit"
                    onClick={() => this.openDeleteReportModal()}
                  />
                  <IconButton
                      disabled={!(this.report()?.status === 'READY' || this.report()?.status === 'PRESENTABLE')}
                      size={"small"}
                      className={styles.optionEditIconWrapper}
                  >
                  <AgricultureIcon
                    className={styles.optionEditIcon}
                    fontSize="inherit"
                    onClick={() => this.openRecalculateRIsModal()}
                    htmlColor={!(this.report()?.status === 'READY' || this.report()?.status === 'PRESENTABLE')? "disabled" : "#4a9cb3"}
                  />
                  </IconButton>
                </>
              )}
            </div>
          </div>
          <ErrorMessage />
          {this.renderReportDetails(report)}
        </Page>
      );
    }
    return null;
  }

  private report(): Report | undefined {
    const { reportId } = this.props.match.params;
    return (this.props.reports || []).find((report) => report.id === reportId);
  }

  private reportPlaces(locationsIds: string[]): string {
    if (this.props.locations) {
      return this.props.locations
        .filter((location) => locationsIds.includes(location.id || ''))
        .map((location) => location.name)
        .join(', ');
    }
    return '';
  }

  private formatDate(date?: string) {
    return date && date.replace('-', '/').replace('-', '/');
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  reports: state.reports.entries,
  locations: state.locations.entries,

  settingDefinitions: state.reportItemSettingDefinitions.settings,
  reportItemTypes: state.reportItemTypes.types,
});

const mapDispatchToProps: DispatchProps = {
  updateReportItemVisualizationUrl,
  loadReports,
  loadLocations,
  deleteReport,
  recalculateRIs,
  updateReport,
  clearErrorMessage,
  changeReportStatus,
  loadReportItemSettingDefinitions, // For adding report item
  loadReportItemTypes, // For adding report item
  createReportItems,
  editReportItem,
  editReportItemDescription,
  deleteReportItem,
  generateRIThumbnails,
  rerunReportWithPostProcessingSettings,
  editPostProcessingSettings,
  deletePostProcessingSettings,
  getAllPostProcessingSettings,
  getPostProcessingSettingsByName,
  getPostProcessingSettingsByCategory,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportPage as any));
