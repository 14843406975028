import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipPositionCoordinates } from '../../../../../reportItems/hooks';
import { Tooltip } from '../../../../../tooltip';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  position: TooltipPositionCoordinates;
}
const LocationSetupTooltip: React.FC<Props> = ({ onClose, position, isOpen }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      onClose={onClose}
      initialPosition={position}
      isOpen={isOpen}
      height={370}
      title={t('locations.locationType.title')}
    >
      <p>{t('locations.locationType.paragraph1')}</p>
      <p>{t('locations.locationType.paragraph2')}</p>
      <p>{t('locations.locationType.paragraph3')}</p>
      <p>{t('locations.locationType.paragraph4')}</p>
      <p>{t('locations.locationType.paragraph5')}</p>
    </Tooltip>
  );
};

export default LocationSetupTooltip;
