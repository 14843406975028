import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import Page from '../../templates/page/page';
import ProjectForm from '../../organisms/ProjectForm';
import { createProject, fetchAccounts } from '../../../actions/actions';
import { AppState } from '../../../reducers/reducers';
import { Project } from '../../../types/project';
import routes from '../../../router/routes';
import { AuthState } from '../../../reducers/authReducer';

const CreateProjectPage = (): ReactElement => {
  const dispatch = useDispatch();
  const { accounts } = useSelector<AppState, AuthState>((state) => state.auth);
  const history = useHistory();

  const selectedAccounts: Option[] =
    localStorage.getItem('accountFilter') === null ? [] : JSON.parse(localStorage.getItem('accountFilter') || '');
  const selectedAccount =
    selectedAccounts.length === 1 ? selectedAccounts[0].key : localStorage.getItem('accountId') || '';

  useEffect(() => {
    dispatch(fetchAccounts());
  }, []);
  const createProjectHandler = (project: Project): void => {
    const callback = () => history.push(routes.projects.list);
    dispatch(createProject(project, callback));
  };

  return (
    <Page title="Create project">
      <ProjectForm onSubmit={createProjectHandler} accounts={accounts} selectedAccount={selectedAccount} />
    </Page>
  );
};

export default CreateProjectPage;
