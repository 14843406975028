import { createEvent } from 'effector';
import { ExportData, ExportOptions } from './interfaces';
import { ReportItemsInformation } from './interfaces/ReportItemsInformation.interface';

export const updateExportOptions = createEvent<ExportOptions>();
export const resetOptionsData = createEvent();

export const updateExportData = createEvent<ExportData>();

export const updateReportItemsInformation = createEvent<Partial<ReportItemsInformation>>();

export const updateExportedFormats = createEvent<string>();

export const updateExportedMetrics = createEvent<string[]>();

export const toggleIsLoading = createEvent<boolean>();

export const toggleIsExportMode = createEvent<boolean>();

export const toggleUsePDFPreview = createEvent<boolean>();
