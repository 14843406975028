import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Page from '../../templates/page/page';
import { AppState } from '../../../reducers/reducers';
import {
  createPoi,
  deletePois,
  loadLocations,
  resetBatchPoiUploadResults,
  createCsvPois,
} from '../../../actions/actions';
import styles from './poiManagementPage.module.css';
import { Location } from '../../../types/location';
import { AddPoiResult, DeletePoisResult } from '../../../reducers/poiManagementReducers';
import PoiList from '../../organisms/poiList/poiList';
import AddPoiForm from '../../organisms/addPoiForm/addPoiForm';
import AddPoiViaCsv from '../../organisms/addPoiViaCsv/addPoiViaCsv';

interface State {}

interface StateProps {
  locations: Location[];
  addPoiResult: AddPoiResult;
  batchAddPoiResult: AddPoiResult[];
  deletePoisResult: DeletePoisResult;
}

interface DispatchProps {
  loadLocations: (projectId: string) => any;
  createPoi: (projectId: string, pois: Location[], inBatch: boolean, onSuccess?: () => void) => any;
  deletePois: (projectId: string, poiIds: string[]) => any;
  resetBatchPoiUploadResults: Function;
  createCsvPois: (
    rowsToProcess: any[],
    projectId: string,
    createCsvPoi: (projectId: string, pois: Location[], inBatch: boolean, onSuccess?: () => void) => any,
  ) => any;
}

type PathParams = { projectId: string };

class PoiManagementPage extends Component<StateProps & DispatchProps & RouteComponentProps<PathParams>, State> {
  componentDidMount(): void {
    const { projectId } = this.props.match.params;
    this.props.loadLocations(projectId);
  }

  render() {
    const { projectId } = this.props.match.params;
    return (
      <Page title="POI Management">
        <div className={styles.container}>
          <div className={styles.poiList}>
            <h2>POI List</h2>
            {this.getDeletePOIsStatus()}
            <PoiList
              locations={this.props.locations}
              deletePois={(poiIds: string[]) => this.props.deletePois(projectId, poiIds)}
            />
          </div>
          <div className={styles.addPoiContainer}>
            <h2>Add POI</h2>
            <AddPoiForm
              createPoi={(poi: Location, onSuccess: () => void) =>
                this.props.createPoi(projectId, [poi], true, onSuccess)
              }
              resultMessage={this.props.addPoiResult}
            />
            <AddPoiViaCsv
              batchAddPoisResult={this.props.batchAddPoiResult}
              createCsvPois={(rowsToProcess: any[]) =>
                this.props.createCsvPois(rowsToProcess, projectId, this.props.createPoi)
              }
              resetBatchPoiUploadResults={this.props.resetBatchPoiUploadResults}
            />
          </div>
        </div>
      </Page>
    );
  }

  private getDeletePOIsStatus() {
    return (
      <div
        className={classNames({
          [styles.deletePoisMessage]: true,
          [styles.deletePoisMessageError]: this.props.deletePoisResult.type === 'failure',
          [styles.deletePoisMessageSuccess]: this.props.deletePoisResult.type === 'success',
        })}
      >
        {this.props.deletePoisResult.message}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  locations: state.locations.entries || [],
  addPoiResult: state.poiManagement.addPoiResult,
  batchAddPoiResult: state.poiManagement.batchAddPoisResult,
  deletePoisResult: state.poiManagement.deletePoisResult,
});

const mapDispatchToProps: DispatchProps = {
  loadLocations,
  createPoi,
  deletePois,
  resetBatchPoiUploadResults,
  createCsvPois,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PoiManagementPage as any));
