import React from 'react';
import { ObjectSignature } from '../../types/ObjectSignature.interface';

interface Props {
  style?: ObjectSignature;
  parentStyle?: ObjectSignature;
}

export const LongArrowIcon = ({ style, parentStyle }: Props) => (
  <svg width="10" height="14" viewBox="0 0 10 14" xmlns="http://www.w3.org/2000/svg" style={parentStyle}>
    <path
      style={style}
      d="M0.916748 5.24984L1.73925 6.07234L4.41675 3.40067V12.8332H5.58342V3.40067L8.26092 6.07817L9.08342 5.24984L5.00008 1.1665L0.916748 5.24984Z"
      stroke="#399A2A"
    />
  </svg>
);
