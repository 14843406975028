import React from 'react';
import styles from './contentContainer.module.scss';

interface TProps {
  children?: React.ReactNode;
  className?: string;
  isOpened?: boolean;
  spacing?: boolean;
}

const ContentContainer: React.FC<TProps> = ({ className, children, isOpened, spacing }) => (
  <div
    className={`${styles.contentContainer} ${className || ''}`}
    style={{ width: !isOpened ? `calc(100% - 57px)` : 'calc(100% - 396px)', paddingTop: spacing ? '50px' : '5px' }}
  >
    {children}
  </div>
);

export default ContentContainer;
