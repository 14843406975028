import React from 'react';

export const CalendarIcon: React.FC = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.66667 6.3335H4V7.66683H2.66667V6.3335ZM12 3.00016V12.3335C12 13.0668 11.4 13.6668 10.6667 13.6668H1.33333C0.593333 13.6668 0 13.0668 0 12.3335L0.00666666 3.00016C0.00666666 2.26683 0.593333 1.66683 1.33333 1.66683H2V0.333496H3.33333V1.66683H8.66667V0.333496H10V1.66683H10.6667C11.4 1.66683 12 2.26683 12 3.00016ZM1.33333 4.3335H10.6667V3.00016H1.33333V4.3335ZM10.6667 12.3335V5.66683H1.33333V12.3335H10.6667ZM8 7.66683H9.33333V6.3335H8V7.66683ZM5.33333 7.66683H6.66667V6.3335H5.33333V7.66683Z"
      fill="white"
    />
  </svg>
);
