import React, { useRef, useState } from 'react';
import styles from './style.module.scss';
import { SmallLogoIcon } from '../../../icons/chart';
import { MenuIcon } from '../../../icons/reportItems';
import { useClickOutside, useToggle } from '../../../hooks';
import { Menu } from './menu';
import { useTooltipStartPosition } from '../../../reportItems/hooks';
import DeleteLocationPopup from '../popup/deleteLocationPopup';

interface Props {
  name: string;
  location: string;
  id: string;
  isEditable: boolean;
  onEdit?: (id: string) => void;
  onRemove?: (id: string) => void;
  onDelete?: (id: string) => void;
  onCopy?: (id: string) => void;
}
export const LocationItem: React.FC<Props> = ({
  name,
  location,
  id,
  isEditable,
  onEdit,
  onRemove,
  onDelete,
  onCopy,
}: Props) => {
  const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);
  const [isDeleteLocationPopupActive, setIsDeleteLocationPopupActive] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [coordinates, setCoordinates] = useState<number[]>([]);
  const ref = useRef(null);
  const closeDeletePopup = () => setIsDeleteLocationPopupActive(false);

  useClickOutside(ref, closeDeletePopup, isDeleteLocationPopupActive);

  const onToggleMenuOpen = () => {
    toggleIsMenuOpen();
  };

  useClickOutside(menuRef, onToggleMenuOpen, isMenuOpen);

  const deleteHandler = () => {
    if (onDelete) {
      onDelete(id);
    }
    closeDeletePopup();
  };

  return (
    <div className={styles.item}>
      {coordinates.length > 0 && isDeleteLocationPopupActive && (
        <div style={{ position: 'absolute', right: '90px', zIndex: 10 }} ref={ref}>
          <DeleteLocationPopup onClose={closeDeletePopup} onDelete={deleteHandler} />
        </div>
      )}
      <div className={styles.wrapper}>
        <SmallLogoIcon opacity="1" />
        <div className={styles.content}>
          <p className={styles.title}>{name}</p>
          {location && <p className={styles.subtitle}>{location}</p>}
        </div>
      </div>
      {isEditable && (
        <span className={styles.menuIcon} onClick={onToggleMenuOpen}>
          <MenuIcon />
        </span>
      )}
      {isMenuOpen && (
        <div className={styles.menu} ref={menuRef}>
          <Menu
            onRemoveFromReport={onRemove ? () => onRemove(id) : undefined}
            onDelete={onDelete ? () => setIsDeleteLocationPopupActive(true) : undefined}
            onEdit={onEdit ? () => onEdit(id) : undefined}
            onCopy={onCopy ? () => onCopy(id) : undefined}
            updateCoordinates={setCoordinates}
          />
        </div>
      )}
    </div>
  );
};
