import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../../../common/types/mainContext';
import styles from './catchmentSocioDemoDropdown.module.scss';
import Dropdown from '../../../../common/controls/dropdown';
import { Option } from '../../../../common/controls/dropdown/types';
import { CatchmentSocioDemoMetricEnum } from '../../../../common/reportItems/types';
import { useURLParams } from '../../../hooks';

export const CatchmentSocioDemoDropdown = () => {
  const { t } = useTranslation();
  const { onChangeParams } = useURLParams();
  const {
    reportItemsDetails: { catchmentSocioDemoMetric },
    updateReportItemsDetailsValue,
  } = useContext(MainContext);

  const onDropdownChange = (option: Option<null>) => {
    if (option.id) {
      updateReportItemsDetailsValue('catchmentSocioDemoMetric', option.id);
      onChangeParams('catchmentSocioDemoMetric', option.id);
    }
  };

  const dropdownOptions = Object.keys(CatchmentSocioDemoMetricEnum).map((item) => ({
    id: item,
    label: t(`catchment.enum.${item}`),
    item: null,
    disabled: false,
  }));

  return (
    <div className={styles.catchmentSocioDemoDropdown}>
      <Dropdown<null> selectedId={catchmentSocioDemoMetric} options={dropdownOptions} onChange={onDropdownChange} />
    </div>
  );
};
