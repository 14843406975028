import { createStore } from 'effector';
import { getLocations } from './effects';
import { PolygonData } from '../createLocation/interfaces';
import {
  deleteLocationOnFrontend,
  removeLocationFromReport,
  saveAndClearLocations,
  resetLocations,
  saveLocations,
  updateLocation,
} from './events';
import { formatLocationAddress } from '../locationManager/utils';
import { DisplayLocation } from './interfaces';
import { createPolygonFromWKT } from '../locationManager/validation/createPolygon';

const convertLocationsToRepresentation = (location: PolygonData): DisplayLocation => ({
  id: location.id,
  label: location.name,
  value: location.name,
  location: formatLocationAddress(location.city, location.country, location.street),
  isNew: location.isNew,
  isUsed: location.isUsed,
});

export const $projectLocations = createStore<PolygonData[]>([]);

$projectLocations.on(getLocations.doneData, (_, data) => data);

export const $selectedLocations = createStore<DisplayLocation[]>([]);

export const $selectedLocationsInformation = createStore<PolygonData[]>([]);
// delete location
$selectedLocations.on(deleteLocationOnFrontend, (store, id) => store.filter((location) => location.id !== id));
$selectedLocationsInformation.on(deleteLocationOnFrontend, (locations, id) =>
  locations.filter((location) => location.id !== id),
);
$projectLocations.on(deleteLocationOnFrontend, (locations, id) => locations.filter((location) => location.id !== id));
// remove location from project
$selectedLocations.on(removeLocationFromReport, (store, id) => store.filter((location) => location.id !== id));
$selectedLocationsInformation.on(removeLocationFromReport, (locations, id) =>
  locations.filter((location) => location.id !== id),
);

$selectedLocations.on(saveLocations, (oldLocations, newLocations) => {
  const locations = newLocations.map((location) => convertLocationsToRepresentation(location));
  return [...oldLocations, ...locations];
});

$selectedLocations.on(updateLocation, (oldLocations, newLocation) =>
  oldLocations.map((location) =>
    location.id === newLocation.id ? convertLocationsToRepresentation(newLocation) : location,
  ),
);

$selectedLocationsInformation.on(saveLocations, (oldLocations, newLocations) => {
  const locations: any = newLocations.map((location) => {
    const geometry = createPolygonFromWKT(location.polygonString);
    return {
      ...location,
      polygon: {
        wktRepresentation: location.polygonString,
        points: location?.polygon?.points
          ? location.polygon.points
          : // @ts-ignore
            geometry?.geometry.coordinates?.[0]?.map((coords) => ({
              latitude: `${coords[1]}`,
              longitude: `${coords[0]}`,
            })),
      },
    };
  });
  return [...oldLocations, ...locations];
});

$selectedLocationsInformation.on(updateLocation, (oldLocations, newLocation) =>
  oldLocations.map((location) => {
    if (location.id === newLocation.id) {
      const geometry = createPolygonFromWKT(location.polygonString);
      return {
        ...location,
        polygon: {
          wktRepresentation: location.polygonString,
          points: location?.polygon?.points
            ? location.polygon.points
            : // @ts-ignore
              geometry?.geometry.coordinates?.[0]?.map((coords) => ({
                latitude: `${coords[1]}`,
                longitude: `${coords[0]}`,
              })),
        },
      };
    }
    return location;
  }),
);

$selectedLocations.on(resetLocations, () => []);
$selectedLocationsInformation.on(resetLocations, () => []);

$selectedLocations.on(saveAndClearLocations, (_, polygons) =>
  polygons.map((polygon) => convertLocationsToRepresentation(polygon)),
);

$selectedLocationsInformation.on(saveAndClearLocations, (_, polygon) => [...polygon]);
