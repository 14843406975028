import React from 'react';
import Fade from '@material-ui/core/Fade';
import { TransitionProps } from '@material-ui/core/transitions';
import Snackbar from '@material-ui/core/Snackbar';
import styles from './copyToClipboardButton.module.css';

interface Props {
  text: string;
  children?: React.ReactNode;
}

const normalizeString = (value: string): string => value || '-';

const CopyToClipboardButton = (props: Props) => {
  const [state, setState] = React.useState<{
    open: boolean;
    transition: React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>;
  }>({
    open: false,
    transition: Fade,
  });

  const handleClick =
    (transition: React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>, value: string) =>
    () => {
      navigator.clipboard.writeText(normalizeString(value));
      setState({
        open: true,
        transition,
      });
      setTimeout(() => {
        handleClose();
      }, 5000);
    };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
      transition: Fade,
    });
  };

  return (
    <>
      <button title="Copy To Clipboard" className={styles.copyToClipboard} onClick={handleClick(Fade, props.text)}>
        {props.children}
      </button>
      <Snackbar
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.transition}
        message="Copied to clipboard"
        key={state.transition.name}
      />
    </>
  );
};

export default CopyToClipboardButton;
