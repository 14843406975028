import React from 'react';
import styles from './style.module.scss';
import { ArrowIcon } from '../../icons/sliders';
import { ButtonTooltip } from '../../controls/tooltip/buttonTooltip';

interface Props {
  isActiveLeftArrow: boolean;
  isActiveRightArrow: boolean;
  onNext: VoidFunction;
  onPrev: VoidFunction;
}
export const Switcher: React.FC<Props> = ({ isActiveLeftArrow, isActiveRightArrow, onNext, onPrev }) => (
  <div className={styles.switcher}>
    <div className={styles.button} onClick={isActiveLeftArrow ? onPrev : undefined}>
      <ButtonTooltip title="Previous">
        <ArrowIcon fill={!isActiveLeftArrow ? 'rgba(255, 255, 255, .3)' : '#00B2F1'} />
      </ButtonTooltip>
    </div>

    <div className={styles.button} onClick={isActiveRightArrow ? onNext : undefined}>
      <ButtonTooltip title="Next">
        <ArrowIcon
          fill={!isActiveRightArrow ? 'rgba(255, 255, 255, .3)' : '#00B2F1'}
          style={{ transform: 'rotate(180deg)' }}
        />
      </ButtonTooltip>
    </div>
  </div>
);
