import React, { MouseEvent, MutableRefObject, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ProjectInfo } from '../../../common/types/projectsHierarchy';
import styles from '../panelContent.module.scss';
import { CalendarIcon, LocationIcon, ReportIcon } from '../../../common/icons/controlPanel';
import { maxLengthOfString } from '../../../common/utils';
import { ProjectMenu } from './projectMenu';
import { Routes } from '../../../routes';

interface TProps {
  project: ProjectInfo;
  onClick: (
    event: MouseEvent<HTMLDivElement>,
    manageLocationsRef: MutableRefObject<HTMLAnchorElement | null>,
    project: ProjectInfo,
  ) => void;
}

export const SingleProject = ({ project, onClick }: TProps) => {
  const userRole = localStorage.getItem('userRole');
  const manageLocationsRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <div
      className={styles.singleProject}
      onClick={(event) => onClick(event, manageLocationsRef, project)}
      style={{
        backgroundImage: `url(
        ${project.picture_url && project.picture_url !== '' ? project.picture_url : '/img/v2/project.png'}
      )`,
        backgroundSize: '310px',
      }}
    >
      <div className={styles.row}>
        <div className={styles.icon} />
        <div className={styles.title}>{maxLengthOfString(project.name, 30)}</div>
        {userRole === 'placenseUser' && <ProjectMenu project={project} />}
      </div>
      <div className={styles.row}>
        <div className={styles.icon} />
        <div className={styles.description}>{maxLengthOfString(project.description, 36)}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.icon}>
          <ReportIcon />
        </div>
        <div>
          {project.reports.length} {project.reports.length === 1 ? 'report' : 'reports'}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.icon}>
          <LocationIcon />
        </div>
        <div>
          {' '}
          <span>{project.locations_count || '0'} locations</span>
          {userRole === 'placenseUser' && (
            <span>
              <Link
                to={Routes.manageLocations.with(project.id)}
                className={styles.manageLocations}
                ref={manageLocationsRef}
              >
                Manage
              </Link>
            </span>
          )}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.icon}>
          <CalendarIcon />
        </div>
        <div>{project.created_date && `Created ${project.created_date}`}</div>
      </div>
    </div>
  );
};
