import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styles from './accountForm.module.css';
import Button from '../../atoms/primaryButton/button';
import { AddAccount } from '../../../types/AddAccount';
import { addAccount } from '../../../actions/actions';
import routes from '../../../router/routes';

const AccountForm = (): ReactElement => {
  const [state, setState] = useState<AddAccount>({
    name: '',
    description: '',
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const updateInputFieldHandler = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    dispatch(addAccount(state, () => history.replace(routes.accounts.list)));
  };

  return (
    <form className={styles.form} onSubmit={submitHandler}>
      <div className={styles.formRow}>
        <label htmlFor="name">Account name</label>
        <input type="text" id="name" name="name" value={state.name} onChange={updateInputFieldHandler} />
      </div>
      <div className={styles.formRow}>
        <label htmlFor="description">Description</label>
        <textarea id="description" name="description" value={state.description} onChange={updateInputFieldHandler} />
      </div>

      <div className={styles.buttonContainer}>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
};

export default AccountForm;
