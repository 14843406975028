import { RENDER_STATE } from 'react-map-gl-draw';
import { editHandleStyle as defaultEditHandleStyle } from 'react-map-gl-draw/dist-types/style';

export const getEditHandleStyle: typeof defaultEditHandleStyle = ({ feature, state }) => {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
      return {
        fill: 'rgb(255, 255, 255)',
        fillOpacity: 1,
        stroke: 'rgb(0, 178, 241)',
        strokeWidth: 8,
        strokeOpacity: 0.6,
        r: 7,
      };

    default:
      return {
        fill: 'rgb(255, 255, 255)',
        fillOpacity: 1,
        stroke: 'rgb(0, 178, 241)',
        strokeWidth: 2,
        r: 5,
      };
  }
};

export const getFeatureStyle: typeof defaultEditHandleStyle = ({ feature, index, state }) => {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      return {
        stroke: 'rgb(0, 178, 241)',
        strokeWidth: 2,
        fill: 'rgb(0, 178, 241)',
        fillOpacity: 0.2,
      };

    default:
      return {
        stroke: 'rgb(0, 178, 241)',
        strokeWidth: 2,
        fill: 'rgb(0, 178, 241)',
        fillOpacity: 0.1,
      };
  }
};
