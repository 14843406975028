import React, { useContext } from 'react';
import styles from './style.module.scss';
import ResetMapIcon from '../../icons/map/resetMap';
import { CameraIcon } from '../../icons/export';
import { MapBaseOptions } from '../mapBase/mapBase';
import { PoisLayerOffIcon, PoisLayerOnIcon } from '../../icons/map';

interface Props {
  onReset: VoidFunction;
  onMakeScreenshot: VoidFunction;
  showPois: boolean;
  togglePois: (showPois: boolean) => void;
}
export const MapActions: React.FC<Props> = ({ onReset, onMakeScreenshot, showPois, togglePois }) => {
  const { isHidden } = useContext(MapBaseOptions);

  if (isHidden) return <></>;

  return (
    <div className={styles.actions}>
      <button type="button" className={styles.actionButton} onClick={() => togglePois(!showPois)} title="Toggle POIs">
        {showPois ? <PoisLayerOnIcon /> : <PoisLayerOffIcon />}
      </button>
      <button type="button" className={styles.actionButton_last} onClick={onReset} title="Center map">
        <ResetMapIcon />
      </button>
      <button type="button" className={styles.export} onClick={onMakeScreenshot} title="Download PNG" data-popup>
        <CameraIcon dataPopup />
      </button>
    </div>
  );
};
