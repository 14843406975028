import React, { useMemo } from 'react';
import styles from './style.module.scss';
import { CountryIcon } from './countryIcon';
import { countries } from '../../utils/countries';

interface TProps {
  onChange: (lng: string) => void;
  selected: string;
}

export const LanguagesContainer = ({ selected, onChange }: TProps) => {
  const availableLanguages = useMemo(
    () => countries.filter((country) => country.code !== selected && country.translation),
    [countries, selected],
  );
  return (
    <div className={styles.languagesContainer}>
      {availableLanguages.map((lng) => (
        <button type="button" onClick={() => onChange(lng.code)}>
          <CountryIcon code={lng.code} />
          <span>{lng.language}</span>
        </button>
      ))}
    </div>
  );
};
