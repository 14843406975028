import React from 'react';
import { Chart } from '../../Chart';
import { RetentionBriefData } from '../../../../model/interfaces';
import { MapPopupData } from '../../../../../common/types/visualizationObjects';
import { toFixNumber } from '../../../../../common/lib';
import styles from './style.module.scss';

interface Props {
  data: MapPopupData;
}

export const MapTooltip: React.FC<Props> = ({ data }) => {
  const { chartData, locationReturningAverage } = data.data as RetentionBriefData;

  return (
    <>
      <div className={styles.information}>
        <p className={styles.label}>Avg. rate of returning visits</p>
        <p className={styles.value}>
          {locationReturningAverage ? toFixNumber(locationReturningAverage * 100, 2) : ''}%
        </p>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.chart}>
          <Chart data={chartData.data} isLoading={false} />
        </div>
      </div>
    </>
  );
};
