import React from 'react';

export const MarketIcon = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 4.5V6.96667H5.96667L10.4067 16.3277L8.74167 19.3493C8.54433 19.6947 8.43333 20.1017 8.43333 20.5333C8.43333 21.89 9.54333 23 10.9 23H25.7V20.5333H11.418C11.2453 20.5333 11.1097 20.3977 11.1097 20.225L11.1467 20.077L12.2567 18.0667H21.445C22.37 18.0667 23.184 17.561 23.6033 16.7963L28.0187 8.792C28.1173 8.61933 28.1667 8.40967 28.1667 8.2C28.1667 7.52167 27.6117 6.96667 26.9333 6.96667H8.69233L7.533 4.5H3.5Z"
      fill="#A67BDD"
    />
    <path
      d="M8.71289 27.3998C8.71289 26.2448 9.64739 25.2998 10.8024 25.2998C11.9574 25.2998 12.9024 26.2448 12.9024 27.3998C12.9024 28.5548 11.9574 29.4998 10.8024 29.4998C9.64739 29.4998 8.71289 28.5548 8.71289 27.3998Z"
      fill="#DFD2EF"
    />
    <path
      d="M19.2129 27.3998C19.2129 26.2448 20.1474 25.2998 21.3024 25.2998C22.4574 25.2998 23.4024 26.2448 23.4024 27.3998C23.4024 28.5548 22.4574 29.4998 21.3024 29.4998C20.1474 29.4998 19.2129 28.5548 19.2129 27.3998Z"
      fill="#DFD2EF"
    />
  </svg>
);
