import { ReportItemInfo, Catchment, LocationInfo } from '../../common/types/projectsHierarchy';
import { CatchmentData, PolygonData, CatchmentItemData } from './types';
import { CatchmentMultiArea } from '../../common/types/visualizationObjects';
import { mapReportItemType } from '../utils';
import { convertPolygonToGeoData } from '../../common/maps/utils/convertPolygonToGeoData';
import { getCenterCoordinate } from '../../common/maps/utils/getCenterCoordinate';
import { getMinMax } from '../../common/utils/getMinMax';
import { separateThousandsByComma } from '../../common/utils';
import { countries } from '../../common/utils';

type CatchmentItemDataWithLocation = CatchmentItemData & {
  locationId: string;
  locationName: string;
};

type MultilocationDataArray = {
  index: string;
  inhabitants: number;
  households: number;
  average_income: number;
  purchasing_power: number;
  locationItems: CatchmentItemDataWithLocation[];
};

export const mapResponse = (
  catchmentData: CatchmentData,
  polygonData: PolygonData,
  selectedLocations: LocationInfo[],
): ReportItemInfo<Catchment> => {
  const locationColors = new Map<string, string>();
  selectedLocations.forEach((loc) => locationColors.set(loc.id, loc.color || '#aaa'));

  const mapPolygons = (catchmentItems: MultilocationDataArray[]): CatchmentMultiArea[] =>
    catchmentItems
      .map((item) => {
        const polygon = polygonData.find((poly) => poly.index === item.index)?.polygon;
        const geo = polygon ? convertPolygonToGeoData(polygon) : null;
        const coordinates = polygon ? getCenterCoordinate(polygon) : [];
        const countryCode = item.index.substring(0, 2);
        const country = countries.find((i) => i.code === countryCode);
        return {
          location_id: item.index,
          name: `${item.locationItems[0].geo_entity_name ? `${item.locationItems[0].geo_entity_name}: ` : ''}${
            item.locationItems[0].geo_entity_code
          }`,
          centerPointLongitude: coordinates.length > 1 ? coordinates[0] : 0,
          centerPointLatitude: coordinates.length > 1 ? coordinates[1] : 0,
          inhabitants: item.inhabitants || 0,
          households: item.households || 0,
          average_income: item.average_income || 0,
          purchasing_power: item.purchasing_power || 0,
          sourceTopLocations: item.locationItems
            .map((loc) => ({
              locationId: loc.locationId,
              locationName: loc.locationName,
              value: loc.geo_entity_share_of_source,
              color: locationColors.get(loc.locationId),
            }))
            .sort((a, b) => b.value - a.value)
            .slice(0, 3),
          destinationTopLocations: item.locationItems
            .map((loc) => ({
              locationId: loc.locationId,
              locationName: loc.locationName,
              value: loc.geo_entity_share_of_target,
              color: locationColors.get(loc.locationId),
            }))
            .sort((a, b) => b.value - a.value)
            .slice(0, 3),
          scoreTopLocations: item.locationItems
            .map((loc) => ({
              locationId: loc.locationId,
              locationName: loc.locationName,
              value: loc.geo_entity_catchment_score,
              color: locationColors.get(loc.locationId),
            }))
            .sort((a, b) => b.value - a.value)
            .slice(0, 3),
          geo,
          popupData: [
            item.inhabitants ? { name: 'Inhabitants', value: separateThousandsByComma(item.inhabitants) } : null,
            item.households ? { name: 'Households', value: separateThousandsByComma(item.households) } : null,
            item.average_income
              ? {
                  name: 'Avg. income',
                  value: `${separateThousandsByComma(item.average_income.toFixed(0))} ${country?.currency || ''}`,
                }
              : null,
            item.purchasing_power
              ? {
                  name: 'Total spending',
                  value: `${separateThousandsByComma(item.purchasing_power.toFixed(0))} ${country?.currency || ''}`,
                }
              : null,
          ].filter((popupDataItem) => popupDataItem),
        };
      })
      .filter((item) => item.geo !== null) as CatchmentMultiArea[];

  const homeData = new Map<string, CatchmentItemDataWithLocation[]>();
  const workData = new Map<string, CatchmentItemDataWithLocation[]>();

  polygonData.forEach((polygon) => {
    homeData.set(polygon.index, []);
    workData.set(polygon.index, []);
  });

  catchmentData.values.forEach((catchmentValue) => {
    catchmentValue.home.forEach((homeValue) => {
      const existingItem = homeData.get(homeValue.index);
      if (existingItem) {
        homeData.set(homeValue.index, [
          ...existingItem,
          { locationId: catchmentValue.location_id, locationName: catchmentValue.location_name, ...homeValue },
        ]);
      } else {
        homeData.set(homeValue.index, [
          { locationId: catchmentValue.location_id, locationName: catchmentValue.location_name, ...homeValue },
        ]);
      }
    });
    catchmentValue.work.forEach((workValue) => {
      const existingItem = workData.get(workValue.index);
      if (existingItem) {
        workData.set(workValue.index, [
          ...existingItem,
          { locationId: catchmentValue.location_id, locationName: catchmentValue.location_name, ...workValue },
        ]);
      } else {
        workData.set(workValue.index, [
          { locationId: catchmentValue.location_id, locationName: catchmentValue.location_name, ...workValue },
        ]);
      }
    });
  });

  const homeDataArray: MultilocationDataArray[] = Array.from(homeData, ([name, value]) => ({
    index: name,
    inhabitants: value[0]?.inhabitants || 0,
    households: value[0]?.households || 0,
    average_income: value[0]?.average_income || 0,
    purchasing_power: value[0]?.purchasing_power || 0,
    locationItems: value,
  })).filter((item) => item.locationItems.length > 0);

  const workDataArray: MultilocationDataArray[] = Array.from(workData, ([name, value]) => ({
    index: name,
    inhabitants: value[0]?.inhabitants || 0,
    households: value[0]?.households || 0,
    average_income: value[0]?.average_income || 0,
    purchasing_power: value[0]?.purchasing_power || 0,
    locationItems: value,
  })).filter((item) => item.locationItems.length > 0);

  const inhabitantstHomeMinMax = getMinMax(homeDataArray, 'inhabitants');
  const inhabitantstWorkMinMax = getMinMax(workDataArray, 'inhabitants');
  const householdsHomeMinMax = getMinMax(homeDataArray, 'households');
  const householdsWorkMinMax = getMinMax(workDataArray, 'households');
  const avgIncomeHomeMinMax = getMinMax(homeDataArray, 'average_income');
  const avgIncomeWorkMinMax = getMinMax(workDataArray, 'average_income');
  const purchasingHomeMinMax = getMinMax(homeDataArray, 'purchasing_power');
  const purchasingWorkMinMax = getMinMax(workDataArray, 'purchasing_power');

  return {
    id: catchmentData.id,
    name: catchmentData.name,
    type: mapReportItemType(catchmentData.type),
    data: {
      countries: catchmentData.countries,
      reportLocations: catchmentData.reportLocations,
      data: catchmentData.values.map((item) => ({
        ...item,
        geo_entity_type: catchmentData?.values[0]?.geo_entity_type || '',
      })),
    },
    visualization: {
      multiLocationPolygons: {
        locationIds: catchmentData.values.map((value) => value.location_id),
        home: {
          inhabitantsMinRank: inhabitantstHomeMinMax[0],
          inhabitantsMaxRank: inhabitantstHomeMinMax[1],
          householdsMinRank: householdsHomeMinMax[0],
          householdsMaxRank: householdsHomeMinMax[1],
          averageIncomeMinRank: avgIncomeHomeMinMax[0],
          averageIncomeMaxRank: avgIncomeHomeMinMax[1],
          purchasingPowerMinRank: purchasingHomeMinMax[0],
          purchasingPowerMaxRank: purchasingHomeMinMax[1],

          polygons: mapPolygons(homeDataArray),
        },
        work: {
          inhabitantsMinRank: inhabitantstWorkMinMax[0],
          inhabitantsMaxRank: inhabitantstWorkMinMax[1],
          householdsMinRank: householdsWorkMinMax[0],
          householdsMaxRank: householdsWorkMinMax[1],
          averageIncomeMinRank: avgIncomeWorkMinMax[0],
          averageIncomeMaxRank: avgIncomeWorkMinMax[1],
          purchasingPowerMinRank: purchasingWorkMinMax[0],
          purchasingPowerMaxRank: purchasingWorkMinMax[0],

          polygons: mapPolygons(workDataArray),
        },
      },
    },
  };
};
