import React from 'react';
import { MapPopupData } from '../../../../../common/types/visualizationObjects';
import { AbsorptionBriefData } from '../../../../model/interfaces';
import AbsorptionCardChart from '../../AbsorptionCardChart';
import { toFixNumber } from '../../../../../common/lib';
import styles from './style.module.scss';

interface Props {
  data: MapPopupData;
}
export const MapTooltip: React.FC<Props> = ({ data }) => {
  const { visitsFromAOI } = data.data as AbsorptionBriefData;
  return (
    <>
      <div className={styles.information}>
        <p className={styles.label}>Share of visits</p>
        <p className={styles.value}>{toFixNumber(visitsFromAOI, 1)}%</p>
      </div>
      <AbsorptionCardChart visitsFromAOIPercentage={visitsFromAOI} isLoading={false} />
    </>
  );
};
