import { API_URL, CONF_API_URL } from '../api';

export const Routes = {
  dashboard: '/',
  userProfile: '/userProfile',
  changePassword: '/changePassword',
  signIn: '/sign-in',
  signUp: '/sign-up',
  signUpActivateAccount: '/sign-up/activate-account',
  signUpFinal: '/sign-up/final',
  signUpActivateAccountCongratulations: '/sign-up/activate-account/congratulations',
  signUpActivateAccountCongratulationsVerified: '/sign-up/activate-account/congratulations/verified',
  forgotPassword: '/forgot-password',
  forgotPasswordSuccessfully: '/forgot-password/success',
  createNewPassword: '/forgot-password/:token',
  createNewPasswordSuccess: '/forgot-password/password-updated',
  projects: '/projects/:id',
  reports: '/reports/:id',
  createProject: '/createProject',
  createReport: '/createReport',
  pricing: '/pricing',
  manageLocations: {
    raw: '/projects/:projectId/locations/manage',
    with: (projectId: string) => `/projects/${projectId}/locations/manage`,
  },
};

export const API_URLS = {
  FAVORITE: `favorites`,
  PROJECT: `${API_URL}/api/projects`,
  REPORT: `${API_URL}/api/report`,
  REPORT_BY_ITEM: `${API_URL}/api/reportByItem`,
  REPORTS: `${API_URL}/api/reports`,
  REPORT_ITEMS: `${API_URL}/api/report/items`,
  REPORT_ITEMS_DATA: `${API_URL}/api/reportItems`,
  SEARCH: `${API_URL}/api/find`,
  USERS: `${API_URL}/api/users`,
  CHANGE_PASSWORD: `${API_URL}/api/users/changePassword`,
  PROJECT_HIERARCHY: `${API_URL}/api/projects/hierarchy`,
  LOGIN: `/authenticate/login`,
  SIGN_UP: `/accounts/self`,
  RESET_PASSWORD: `/users/forgotPassword`,
  CREATE_NEW_PASSWORD: `/users/resetPassword`,
  USER_AVATAR: `/meta/pic`,
  COMPANY_LOGO: `/company-logo`,
};

export const CONF_API_URLS = {
  LIMITATIONS: {
    raw: `${CONF_API_URL}/api/limits/create/report/all`,
    withParams: (accountId: string | null) => `${CONF_API_URL}/api/limits/create/report/all?accountId=${accountId}`,
  },
  CATCHMENT_V2_DATE: `${CONF_API_URL}/api/settings/custom/catchment_v2`,
};
