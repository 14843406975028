import { useContext } from 'react';
import { ReportItemType } from '../../../../common/reportItems/types';
import { MainContext } from '../../../../common/types/mainContext';
import { ExportMapContext } from '../../../../common/export/exportSettings/settings/mapContainer';

export const usePoints = () => {
  const { selection, selectedLocations, absorptionSourceLocation, attractionSourceLocation, exportSelection } =
    useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const selectedReportItem = isExportMap ? exportSelection.selectedReportItem : selection.selectedReportItem;

  let groupCondition: string | undefined;
  if (selectedReportItem?.type === ReportItemType.ABSORPTION) {
    groupCondition = absorptionSourceLocation?.id;
  } else if (selectedReportItem?.type === ReportItemType.ATTRACTION) {
    groupCondition = attractionSourceLocation?.id;
  }

  const points = selectedReportItem?.visualization?.points;
  const selectedPoints = points?.filter(
    (point) =>
      selectedLocations.some((location) => location.id === point.location_id) &&
      (!groupCondition || point.group_id === groupCondition),
  );

  return selectedPoints || [];
};
