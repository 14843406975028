import React, { ChangeEvent, useRef, useState } from 'react';
import styles from './editCompanyLogoMenu.module.scss';
import { AddAvatarIcon, EditAvatarIcon, RemoveAvatarIcon } from '../../icons/user';
import { useClickOutside } from '../../hooks';

interface Props {
  onChange?: (data: ChangeEvent<HTMLInputElement>) => void;
  onRemove?: () => void;
  id: string;
}

const EditCompanyLogoMenu: React.FC<Props> = ({ onChange, id, onRemove }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const ref = useRef(null);

  const closeMenuHandler = () => setIsOpened(false);
  const toggleMenuHandler = () => setIsOpened((prevState) => !prevState);

  useClickOutside(ref, closeMenuHandler, isOpened);

  const onSelectAvatar = () => document.getElementById(id)?.click();

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        className={`${styles.icon} ${isOpened ? styles.active : ''}`}
        onClick={toggleMenuHandler}
        title="Add image"
      >
        <EditAvatarIcon />
      </button>
      {isOpened && (
        <div className={styles.menu} ref={ref}>
          <input type="file" className={styles.input} onChange={onChange} id={id} />
          <button type="button" className={styles.iconWrapper} onClick={onSelectAvatar}>
            <div className={styles.icon}>
              <AddAvatarIcon />
            </div>
            <span>Upload photo</span>
          </button>
          <button type="button" onClick={onRemove}>
            <div className={styles.icon}>
              <RemoveAvatarIcon />
            </div>
            <span>Remove photo</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default EditCompanyLogoMenu;
