import { LocationInfo } from '../../../common/types/projectsHierarchy';
import { Point } from '../../../common/types/visualizationObjects';

export const generateMapPoints = <
  T extends { location_id: string; location_name: string; average: number | string; group_id?: string },
>(
  values: T[],
  locations: LocationInfo[],
): Point[] => {
  const points = values
    .map((value) => {
      const location = locations.find((loc) => loc.id === value.location_id);
      return location && location.latitude && location.longitude
        ? {
            location_id: value.location_id,
            name: value.location_name,
            longitude: location.longitude,
            latitude: location.latitude,
            value: value.average,
            color: location.color,
            group_id: value.group_id,
          }
        : null;
    })
    .filter((item) => item);
  return points as Point[];
};
