import { CrossVisitationValue } from '../types';
import { toFixNumber } from '../../../common/lib';

export const calculateAvgBetweenSelectedLocations = (locations: CrossVisitationValue[]) => {
  let crossVisitationRecordsCount = 0;
  const isSingleLocation = locations.length === 1;

  const crossVisitationSum = locations.reduce((curr, { data }) => {
    const noFull = data.filter(({ visitors_loyalty }) => visitors_loyalty !== 1);

    const crossVisitationSum = toFixNumber(
      noFull.reduce((curr, { visitors_loyalty }) => curr + toFixNumber(visitors_loyalty, 2), 0),
      3,
    );

    crossVisitationRecordsCount += noFull.length;

    return toFixNumber(crossVisitationSum + curr, 2);
  }, 0);

  const singleLocationAverage = toFixNumber(crossVisitationSum / crossVisitationRecordsCount, 2) * 100;
  const crossVisitationAverage = 100 - singleLocationAverage;

  return {
    crossVisitationAverage: isSingleLocation ? 0 : crossVisitationAverage,
    singleLocationAverage: isSingleLocation ? 100 : singleLocationAverage,
  };
};
