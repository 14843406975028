import React, { ChangeEvent } from 'react';
import { PasswordInput } from '../passwordInput';
import styles from './style.module.scss';
import { InputTypesEnum } from '../enums';
import { WithIconInput } from '../withIcon';
import { DefaultInput } from '../default';
import Dropdown, { DropdownTypesEnum } from '../../controls/dropdown';
import { Option } from '../../controls/dropdown/types';
import { Textarea } from '../textarea';

interface Props<T> {
  id: string;
  label?: string;
  value: string;
  name: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeSelect?: (data: any) => void;
  onIconClick?: VoidFunction;
  maxLabelWidth?: string;
  columnGap?: string;
  error?: string | null;
  fieldType?: InputTypesEnum | DropdownTypesEnum;
  icon?: React.ReactNode;
  iconSide?: 'right' | 'left';
  options?: Option<T>[];
  isBorder?: boolean;
  asterisk?: boolean;
  isDisabled?: boolean;
  maxLength?: number;
}
export const Field = <T,>({
  label,
  value,
  name,
  onChange = () => {},
  onIconClick,
  id,
  maxLabelWidth = '50px',
  error,
  fieldType = InputTypesEnum.TEXT,
  icon,
  placeholder,
  columnGap = '11px',
  iconSide,
  onChangeSelect = () => {},
  options = [],
  isBorder = true,
  asterisk,
  isDisabled,
  maxLength,
}: Props<T>) => {
  const getFieldType = (type: InputTypesEnum | DropdownTypesEnum) => {
    switch (type) {
      case InputTypesEnum.PASSWORD:
        return (
          <PasswordInput
            value={value}
            onChange={onChange}
            name={name}
            id={id}
            isError={!!error}
            isBorder={isBorder}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        );
      case InputTypesEnum.WITH_ICON:
        return (
          <WithIconInput
            value={value}
            onChange={onChange}
            name={name}
            id={id}
            isError={!!error}
            icon={icon}
            placeholder={placeholder}
            iconSide={iconSide}
            onIconClick={onIconClick}
          />
        );
      case InputTypesEnum.TEXT:
        return (
          <DefaultInput
            value={value}
            onChange={onChange}
            name={name}
            id={id}
            isError={!!error}
            placeholder={placeholder}
            isBorder={isBorder}
          />
        );
      case InputTypesEnum.TEXT_AREA:
        return (
          <Textarea
            value={value}
            onChange={onChange}
            name={name}
            id={id}
            isError={!!error}
            placeholder={placeholder}
            isDisabled={isDisabled}
            maxLength={maxLength}
          />
        );
      case DropdownTypesEnum.DEFAULT:
        return <Dropdown selectedId={value} onChange={onChangeSelect} options={options} />;
      default:
        return `The input type doesn't support`;
    }
  };

  return (
    <div
      className={styles.field}
      style={{ gridTemplateColumns: label ? `${maxLabelWidth} 1fr` : '1fr', gridColumnGap: columnGap }}
    >
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label} {asterisk && <span className={styles.asterisk}>*</span>}
        </label>
      )}
      <div className={styles.input}>
        {getFieldType(fieldType)}
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </div>
  );
};
