import React from 'react';
import styles from './style.module.scss';
import { CircleStyles, ParticleOptions, Sides, StakeType } from '../../interfaces';

interface Props {
  value: string | number;
  label: string;
  color: string;
  onOpenMore: VoidFunction;
  contentSide?: Sides;
  contentType?: StakeType;
  customStyles?: CircleStyles;
  options?: ParticleOptions;
  contribution?: string | number;
}
export const Information: React.FC<Props> = ({
  value,
  label,
  color,
  contentSide = 'right',
  contentType = 'statistic',
  customStyles,
  options,
  onOpenMore,
  contribution,
}) => {
  const isStatistic = contentType === 'statistic';

  const isClickable = options?.clickable;
  return (
    <div
      className={`${styles.information} ${contentSide === 'right' ? styles.right : styles.left} ${
        !isStatistic ? styles.information : styles.statistic
      } ${isClickable ? styles.clickable : ''}`}
      style={customStyles?.item}
      onClick={isClickable ? onOpenMore : undefined}
      title={label}
    >
      <span className={`${styles.value} ${contribution ? styles.withContribution : ''}`}>
        {value} {contribution && <span className={styles.contribution}>({contribution})</span>}
      </span>
      <div className={`${styles.row} ${isStatistic ? styles.advanced : styles.information}`}>
        {isStatistic ? <span className={styles.circle} style={{ background: color }} /> : ''}
        <span className={styles.label} style={customStyles?.label}>
          {label}
        </span>
      </div>
    </div>
  );
};
