export default {
  projects: {
    list: '/projects',
    update: {
      raw: '/projects/:projectId/updateProject',
      withId: (projectId: string | unknown) => `/projects/${projectId}/updateProject`,
    },
  },
  reports: {
    list: {
      raw: '/projects/:projectId/reports',
      withId: (projectId: string | unknown) => `/projects/${projectId}/reports`,
    },
    create: {
      raw: '/projects/:projectId/createReport',
      withId: (projectId: string) => `/projects/${projectId}/createReport`,
    },
  },
  profile: {
    changePassword: '/profile/changePassword',
  },
  users: {
    list: '/users',
    add: '/users/new',
    update: {
      raw: '/users/:userId/updateUser',
      withId: (userId: string) => `/users/${userId}/updateUser`,
    },
  },
  accounts: {
    list: '/accounts',
    add: '/accounts/new',
  },
  customerPortalView: {
    view: '/customerPortalView',
  },
};
