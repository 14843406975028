import { tableauServer } from './options';

export function addTableauEmbedIframeParams(tableauItemUrl: string) {
  const {
    publicUrl,
    oldDomainWithTrustedAuth,
    domain,
    domainWithTrustedAuth,
    oldDomain: tableauServerOldDomain,
    embedParam,
    showVizHomeParam,
  } = tableauServer;
  let res = tableauItemUrl;
  const oldDomain = 'placense.com';
  const newDomain = 'placesense.ai';
  const currentUrl = window.location.origin;
  if (currentUrl?.includes(newDomain) && tableauItemUrl.includes(oldDomain)) {
    res = res.replace(oldDomain, newDomain);
  } else if (currentUrl?.includes(oldDomain) && tableauItemUrl.includes(newDomain)) {
    res = res.replace(newDomain, oldDomain);
  }

  if (!tableauItemUrl.includes(publicUrl)) {
    // if (!tableauItemUrl.includes(domainWithTrustedAuth) && !tableauItemUrl.includes(oldDomainWithTrustedAuth)) {
    //   res = tableauItemUrl.includes(domain)
    //     ? tableauItemUrl.replace(domain, `${domainWithTrustedAuth + ticket}/`)
    //     : tableauItemUrl.replace(tableauServerOldDomain, `${oldDomainWithTrustedAuth + ticket}/`);
    // }
  }

  const origUrlHadQuestionMark = tableauItemUrl.includes('?');
  if (!origUrlHadQuestionMark) {
    res += '?:';
  }
  const origUrlHadShowVizHomeParam = tableauItemUrl.includes('showVizHome=');
  if (!origUrlHadShowVizHomeParam) {
    res = res + (res.endsWith('?') || res.endsWith('?:') ? '' : '&:') + showVizHomeParam;
  }
  if (!tableauItemUrl.includes('embed=')) {
    res = `${res}&:${embedParam}`;
  }
  return res;
}
