import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import _ from 'lodash';
import Dropdown from 'react-dropdown';
import Page from '../../templates/page/page';
import { AppState } from '../../../reducers/reducers';
import {
  createReport,
  loadLocations,
  loadReportItemSettingDefinitions,
  loadReportItemTypes,
} from '../../../actions/actions';
import { ReportItemSettingDefinition, SettingDefinitionsForItems } from '../../../types/reportItemSettingDefinition';
import { Location } from '../../../types/location';
import styles from './createReportPage.module.css';
import MultiSelect from '../../atoms/multiselect/multiselect';
import { Report, ReportItem, ReportItemSetting } from '../../../types/report';
import Help from '../../atoms/help/help';
import Button from '../../atoms/primaryButton/button';
import { OPERATIONAL_DATE_FORMAT } from '../../../dates';
import { displayNameForReportItemType } from '../../../reportItemTypes';
import 'react-dropdown/style.css';
import { v4 as uuidv4 } from 'uuid';
import stringToDate from '../../../function/stringToDate';
import ErrorMessage from '../../organisms/errorMessage';
import { RecurrenceFrequencyEnum } from '../../../customerPortalv2/createReport/types';

interface HistoryLocationState {
  sourceReport: Report;
}

interface Props {
  history: History<HistoryLocationState>;
}

interface StateProps {
  settingDefinitions?: SettingDefinitionsForItems;
  locations?: Location[];
  reportItemTypes?: string[];
}

interface DispatchProps {
  loadReportItemSettingDefinitions: (reportCategory: string) => void;
  loadLocations: (projectId: string) => void;
  createReport: (projectId: string, report: Report, callback: Function) => void;
  loadReportItemTypes: (reportCategory: string) => void;
}

interface ReportItemState {
  internalId: string;
  reportItemType: string;
  name: string;
  description: string;
  settings: ReportItemSetting[];
}

interface ItemError {
  reportItemDetails: ReportItemDetails;
  errors: string[];
  errorInItemName: boolean;
}

interface GlobalErrors {
  isReportNameValid: boolean;
}

interface ReportItemDetails {
  internalId: string;
  reportItemType: string;
}

interface State {
  name: string;
  description: string;
  reportCategory: string;
  startDay: Date;
  endDay: Date;
  recurring: boolean;
  recurringTimeNumber: number;
  recurringTimeInterval: string;
  selectedPOIs: Option[];
  selectedReportItemTypes: ReportItemDetails[];
  reportItems: ReportItemState[];
  itemErrors: ItemError[];
  globalErrors: GlobalErrors;
  candidateSelectedReportTypeToAdded: string;
  duplicateReportWithDefaultValuesInfoMessage: string;
  visualizationURL: string;
}

type PathParams = { projectId: string };

const reportCategoryOptions = ['POI_DEFAULT', 'AOI_DEFAULT', 'OOH', 'FFI'];
export const defaultReportCategoryOption = reportCategoryOptions[0];

// admin options to create regular & recurring reports
const regularOrRecurringOptions = ['NO', 'YES'];
const recurringTimeFrameOptions = [
  RecurrenceFrequencyEnum.MONTHLY,
  RecurrenceFrequencyEnum.WEEKLY,
  process.env.REACT_APP_API_URL !== 'https://portal-prod-1-dot-gifted-slice-218906.ey.r.appspot.com'
    ? RecurrenceFrequencyEnum.DAILY
    : '',
];

const defaultDate = (() => {
  const date = new Date();
  date.setDate(date.getDate() - 4);
  return date;
})();

const mapSelectedLocationToLocationOption = (locations: Location[], selectedLocations: string[]): Option[] =>
  (locations || [])
    .filter(({ id }) => selectedLocations.includes(id || ''))
    .map(({ name, id }) => ({ label: name, value: id }));

const mapReportItemToReportItemDetails = (items: ReportItem[]): ReportItemDetails[] =>
  (items || []).map((item) => ({
    internalId: item.id || uuidv4(),
    reportItemType: `DEFAULT.${item.reportItemType}`,
  }));

const mapReportItemToReportItemState = (items: ReportItem[]): ReportItemState[] =>
  (items || []).map((item) => ({
    internalId: item.id || uuidv4(),
    reportItemType: item.reportItemType,
    name: item.name,
    description: item.description,
    settings: item.settings,
  }));

const getDuplicateReportDefaultFieldsValuesMessage = (sourceReport: Report): string => {
  const fields = [];
  if (Number.isNaN(Date.parse(sourceReport.startDay))) {
    fields.push('"start date"');
  }
  if (Number.isNaN(Date.parse(sourceReport.endDay))) {
    fields.push('"end date"');
  }
  return fields.length > 0 ? `Source report do not contains fields ${fields.join(', ')}. Default values was used.` : '';
};

// check if additional components need to be rendered
const isRecurring = (recurring: boolean) => recurring === true;
const isNotFFIReport = (category: string) => category !== 'FFI';
const isEmptyReportThatSupportOnlyReportLinkWithoutReportItems = (category: string) => category === 'EMPTY';

class CreateReportPage extends Component<Props & StateProps & DispatchProps & RouteComponentProps<PathParams>, State> {
  constructor(props: Readonly<Props & RouteComponentProps<PathParams> & StateProps & DispatchProps>) {
    super(props);
    this.state = {
      name: '',
      description: '',
      reportCategory: defaultReportCategoryOption,
      startDay: defaultDate,
      endDay: defaultDate,
      recurring: false,
      recurringTimeNumber: 0,
      recurringTimeInterval: '',
      selectedPOIs: [],
      selectedReportItemTypes: [],
      reportItems: [],
      itemErrors: [],
      globalErrors: { isReportNameValid: true },
      candidateSelectedReportTypeToAdded: '',
      duplicateReportWithDefaultValuesInfoMessage: '',
      visualizationURL: '',
    };
  }

  componentDidMount(): void {
    const { projectId } = this.props.match.params;
    this.props.loadReportItemSettingDefinitions(this.state.reportCategory);
    this.props.loadLocations(projectId);
    this.props.loadReportItemTypes(this.state.reportCategory);
    this.fillFieldsIfShouldDuplicatePrevReport();
  }

  componentDidUpdate(
    prevProps: Readonly<Props & StateProps & DispatchProps & RouteComponentProps<PathParams>>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (prevState.reportCategory !== this.state.reportCategory) {
      this.props.loadReportItemSettingDefinitions(this.state.reportCategory);
      this.props.loadReportItemTypes(this.state.reportCategory);
      // this.setState({selectedReportItemTypes: []});
    }
  }

  render() {
    return (
      <Page title="Create report">
        {!!this.state.duplicateReportWithDefaultValuesInfoMessage && (
          <p className={styles.duplicateReportInfoMessage}>{this.state.duplicateReportWithDefaultValuesInfoMessage}</p>
        )}
        {this.renderCreateReportForm()}
      </Page>
    );
  }

  private fillFieldsIfShouldDuplicatePrevReport() {
    const sourceReport = this.props.history.location.state?.sourceReport;
    if (sourceReport) {
      const reportItems = (sourceReport.reportItems || []).map((item) => ({ ...item, id: uuidv4() }));
      const duplicateReportWithDefaultValuesInfoMessage = getDuplicateReportDefaultFieldsValuesMessage(sourceReport);
      this.setState({
        name: `${sourceReport.name} (copy)`,
        startDay: stringToDate(sourceReport.startDay, defaultDate),
        endDay: stringToDate(sourceReport.endDay, defaultDate),
        description: sourceReport.description,
        reportCategory: sourceReport.reportCategory,
        selectedPOIs: mapSelectedLocationToLocationOption(this.props.locations || [], sourceReport.locations),
        reportItems: mapReportItemToReportItemState(reportItems),
        selectedReportItemTypes: mapReportItemToReportItemDetails(reportItems),
        duplicateReportWithDefaultValuesInfoMessage,
        visualizationURL: sourceReport.visualizationURL || '',
      });
    }
  }

  private renderCreateReportForm() {
    if (this.props.settingDefinitions && this.props.locations && this.props.reportItemTypes) {
      const dateFormat = 'yyyy-MM-dd';
      return (
        <div className={styles.formContainer}>
          {this.renderName()}
          {isNotFFIReport(this.state.reportCategory) && this.renderStartDate(dateFormat)}
          {isNotFFIReport(this.state.reportCategory) && this.renderEndDate(dateFormat)}
          {this.renderDescription()}
          {this.renderVisualizationURL()}
          {this.renderReportCategory()}
          {this.renderRegularOrRecurring()}
          {this.state.recurring && this.renderRecurrentTimeFrame()}
          {!isEmptyReportThatSupportOnlyReportLinkWithoutReportItems(this.state.reportCategory) &&
            isNotFFIReport(this.state.reportCategory) &&
            this.renderAssignedPois()}
          {!isEmptyReportThatSupportOnlyReportLinkWithoutReportItems(this.state.reportCategory) &&
            isNotFFIReport(this.state.reportCategory) &&
            this.renderAvailableReportItems()}
          <hr className={styles.delimiter} />
          {!isEmptyReportThatSupportOnlyReportLinkWithoutReportItems(this.state.reportCategory) &&
            isNotFFIReport(this.state.reportCategory) &&
            this.renderReportItems()}
          <ErrorMessage />
          {this.renderCreateButton()}
        </div>
      );
    }
    return null;
  }

  private renderCreateButton() {
    return (
      <div className={styles.createButtonContainer}>
        <Button onClick={() => this.onCreate()}>Create</Button>
      </div>
    );
  }

  private renderAvailableReportItems() {
    if (this.props.reportItemTypes) {
      const initialTypeMsg = 'Report Item...';
      if (this.props.reportItemTypes.length === 0) {
        loadReportItemTypes(this.state.reportCategory);
      }
      return (
        <div className={styles.formRow}>
          <div className={styles.fieldName}>Report items (RI)</div>
          <div className={styles.addRiContainer}>
            <Dropdown
              className={styles.addRiDropdown}
              options={[initialTypeMsg].concat(
                this.props.reportItemTypes.sort().map((fullName) => fullName.substr(fullName.indexOf('.') + 1)),
              )}
              onChange={(event) => this.onChangeAddReportItemDropdown(`${this.state.reportCategory}.${event.value}`)}
              value={
                this.state.candidateSelectedReportTypeToAdded === ''
                  ? initialTypeMsg
                  : this.state.candidateSelectedReportTypeToAdded.substr(
                      this.state.candidateSelectedReportTypeToAdded.indexOf('.') + 1,
                    )
              }
              placeholder="Select report item to add"
            />
            <div className={styles.addRiButtonContainer}>
              <Button onClick={() => this.onAddReportItemButton()}>Add</Button>
            </div>
          </div>
        </div>
      );
    }
  }

  private renderRemoveReportItem(reportItemInternalId: string) {
    return (
      <div className={styles.removeRiButtonContainer}>
        <Button onClick={() => this.onRemoveReportItemButton(reportItemInternalId)}>Remove</Button>
      </div>
    );
  }

  private renderAssignedPois() {
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>Assigned POIs</div>
        <div className={styles.valueContainer}>
          <MultiSelect
            className={`${styles.poiMultiSelect}`}
            options={this.poiOptions()}
            value={this.state.selectedPOIs}
            onChange={(selected: Option[]) => this.setState({ selectedPOIs: selected })}
          />
        </div>
      </div>
    );
  }

  private renderDescription() {
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>Report description</div>
        <div className={styles.valueContainer}>
          <textarea
            className={styles.textarea}
            value={this.state.description}
            onChange={(event) => this.setState({ description: event.target.value })}
          />
        </div>
      </div>
    );
  }

  private renderReportCategory() {
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>Report category</div>
        <div className={styles.valueContainer}>
          <Dropdown
            options={reportCategoryOptions}
            onChange={(event) => this.setState({ reportCategory: event.value })}
            value={this.state.reportCategory}
            placeholder="Select report category"
          />
        </div>
      </div>
    );
  }

  private renderEndDate(dateFormat: string) {
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>End date</div>
        <div className={styles.datePickerContainer}>
          <DatePicker
            selected={this.state.endDay}
            onChange={(date) => this.setState({ endDay: date as any })}
            dateFormat={dateFormat}
            maxDate={defaultDate}
          />
          {this.renderLastMonthsClick('last month', 1)}
          {this.renderLastMonthsClick('last year', 12)}
        </div>
      </div>
    );
  }

  private renderStartDate(dateFormat: string) {
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>Start date</div>
        <div className={styles.datePickerContainer}>
          <DatePicker
            selected={this.state.startDay}
            onChange={(date) => this.setState({ startDay: date as any })}
            dateFormat={dateFormat}
            maxDate={defaultDate}
          />
          {this.renderLastDaysClick('reset', 0)}
          {this.renderLastDaysClick('last week', 7)}
          {this.renderLastDaysClick('last 2 weeks', 14)}
        </div>
      </div>
    );
  }

  private renderName() {
    const isSettingInvalid = !this.state.globalErrors.isReportNameValid;
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>Report name</div>
        <div className={styles.valueContainer}>
          <input
            className={styles.input}
            value={this.state.name}
            onChange={(event) => this.setState({ name: event.target.value })}
          />
          {isSettingInvalid && <div className={styles.fieldErrorMessage}>Please provide a valid report name</div>}
        </div>
      </div>
    );
  }

  private renderReportItems() {
    return <>{this.state.selectedReportItemTypes.sort().map((reportItem) => this.renderReportItem(reportItem))}</>;
  }

  // renders dropdown to choose regular or recurring report
  private renderRegularOrRecurring() {
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>Automatic Updating</div>
        <div className={styles.valueContainer}>
          <Dropdown
            options={regularOrRecurringOptions}
            onChange={(event) => {
              this.setState({ recurring: event.value === 'YES' });
            }}
            placeholder="Automatic updating? (default=NO)"
          />
        </div>
      </div>
    );
  }

  // renders input field & dropdown menu if recurring is chosen
  private renderRecurrentTimeFrame() {
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>Update every...</div>
        <div className={styles.valueContainer}>
          <Dropdown
            options={recurringTimeFrameOptions}
            onChange={(event) => {
              this.setState({ recurringTimeInterval: event.value });
            }}
            placeholder="Select a timeframe"
          />
        </div>
      </div>
    );
  }

  private renderVisualizationURL() {
    return (
      <div className={styles.formRow}>
        <div className={styles.visualizationURL}>Visualization URL</div>
        <label className={styles.valueContainer}>
          <input
            className={styles.input}
            value={this.state.visualizationURL}
            onChange={(event) => this.setState({ visualizationURL: event.target.value })}
          />
        </label>
      </div>
    );
  }

  private renderReportItem(reportItem: ReportItemDetails) {
    const { reportItemType } = reportItem;
    const reportItemTitle = reportItemType.substr(reportItemType.indexOf('.') + 1);
    const reportItemState = this.reportItemState(reportItem);
    const isReportItemNameInvalid = this.state.itemErrors.find(
      (item) => item.reportItemDetails.internalId === reportItem.internalId,
    )?.errorInItemName;
    return (
      <div className={styles.reportItem} key={reportItem.internalId}>
        <div className={styles.reportItemTitleContainer}>
          <div className={styles.reportItemTitle}>
            <h2>
              {this.state.selectedReportItemTypes.indexOf(reportItem) + 1}.{' '}
              {displayNameForReportItemType(reportItemTitle)}
            </h2>
            {this.renderRemoveReportItem(reportItem.internalId)}
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.fieldName}>Item name</div>
          <div className={styles.valueContainer}>
            <input
              className={styles.input}
              value={reportItemState.name}
              onChange={(event) => {
                const name = event.target.value;
                this.updateReportItemProperty(reportItemState, 'name', name);
              }}
            />
            {isReportItemNameInvalid && (
              <div className={styles.fieldErrorMessage}>Please provide a valid report item name</div>
            )}
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.fieldName}>Item description</div>
          <div className={styles.valueContainer}>
            <textarea
              className={styles.textarea}
              value={reportItemState.description}
              onChange={(event) => {
                const description = event.target.value;
                this.updateReportItemProperty(reportItemState, 'description', description);
              }}
            />
          </div>
        </div>
        {this.renderItemSettings(reportItem)}
      </div>
    );
  }

  private updateReportItemProperty(reportItem: ReportItemDetails, propertyName: string, value: string) {
    this.setState((prevState) => {
      const newItemState = _.assign({}, this.reportItemState(reportItem, prevState), { [propertyName]: value });
      const reportItems = [
        ...prevState.reportItems.filter((item) => item.internalId !== reportItem.internalId),
        newItemState,
      ];
      return { reportItems };
    });
  }

  private generateReportItemState(
    internalIdInput: string,
    reportItemTypeInput: string,
    settingsForItem: { name: string; value: string }[],
  ): ReportItemState {
    return {
      internalId: internalIdInput != null && internalIdInput && internalIdInput !== '' ? internalIdInput : uuidv4(),
      reportItemType: reportItemTypeInput,
      name: '',
      description: '',
      settings: settingsForItem,
    };
  }

  private reportItemState(reportItemInput: ReportItemDetails, state: State = this.state): ReportItemState {
    const maybeItemState = state.reportItems.find((reportItem) => reportItem.internalId === reportItemInput.internalId);
    const { reportItemType } = reportItemInput;
    const defaultSettingsForItem = ((this.props.settingDefinitions || {})[reportItemType] || []).map(
      (settingDefinition) => ({
        name: settingDefinition.name,
        value: settingDefinition.defaultValue,
      }),
    );
    return (
      maybeItemState || this.generateReportItemState(reportItemInput.internalId, reportItemType, defaultSettingsForItem)
    );
  }

  private renderItemSettings(reportItem: ReportItemDetails) {
    const { settingDefinitions } = this.props;
    const reportItemExistInSourceForDuplication = this.state.reportItems?.find(
      (value) => value.internalId === reportItem.internalId,
    )?.reportItemType;
    const reportItemType =
      this.state.reportCategory &&
      reportItemExistInSourceForDuplication &&
      !reportItemExistInSourceForDuplication.includes('.')
        ? `${this.state.reportCategory}.${reportItemExistInSourceForDuplication}`
        : reportItem.reportItemType;
    const reportItemTitle = reportItemType.substr(reportItemType.indexOf('.') + 1);
    if (settingDefinitions && settingDefinitions[reportItemType]) {
      return (
        <div className={styles.reportItemSettings}>
          <h2>{displayNameForReportItemType(reportItemTitle)} settings</h2>
          {settingDefinitions[reportItemType].map((settingDefinition) =>
            this.renderSettingForItem(reportItem, settingDefinition),
          )}
        </div>
      );
    }
    return null;
  }

  private renderSettingForItem(reportItem: ReportItemDetails, settingDefinition: ReportItemSettingDefinition) {
    const isSettingInvalid = this.isSettingInvalid(reportItem, settingDefinition.name);
    if (settingDefinition.settingType === 'simpleText') {
      return (
        <div key={settingDefinition.name} className={styles.formRow}>
          <div className={styles.fieldName}>
            {settingDefinition.name}
            {settingDefinition.isMandatory ? '*' : ''}
            {settingDefinition.description && <Help helpMessage={settingDefinition.description} />}
          </div>
          <div className={styles.valueContainer}>
            <input
              className={classNames(styles.input, { [styles.inputInvalid]: isSettingInvalid })}
              value={this.itemSettingValue(reportItem, settingDefinition.name, settingDefinition.defaultValue)}
              onChange={(event) => {
                const { value } = event.target;
                return this.updateItemSetting(reportItem, settingDefinition, value);
              }}
            />
            {isSettingInvalid && <div className={styles.fieldErrorMessage}>This field is required</div>}
          </div>
        </div>
      );
    }
    if (settingDefinition.settingType === 'singleChoice') {
      return (
        <div key={settingDefinition.name} className={styles.formRow}>
          <div className={styles.fieldName}>
            {settingDefinition.name}
            {settingDefinition.isMandatory ? '*' : ''}
            {settingDefinition.description && <Help helpMessage={settingDefinition.description} />}
          </div>
          <div className={styles.valueContainer}>
            <select
              className={classNames(styles.select, { [styles.inputInvalid]: isSettingInvalid })}
              value={this.itemSettingValue(reportItem, settingDefinition.name, settingDefinition.defaultValue)}
              onChange={(event) => {
                const { value } = event.target;
                return this.updateItemSetting(reportItem, settingDefinition, value);
              }}
            >
              {settingDefinition.possibleValues.map((possibleValue) => (
                <option key={possibleValue}>{possibleValue}</option>
              ))}
            </select>
            {isSettingInvalid && <div className={styles.fieldErrorMessage}>This field is required</div>}
          </div>
        </div>
      );
    }
    return null;
  }

  private updateItemSetting(
    reportItem: ReportItemDetails,
    settingDefinition: ReportItemSettingDefinition,
    value: string,
  ) {
    return this.setState((prevState) => {
      const reportItemState = this.reportItemState(reportItem, prevState);
      const newItemState = _.assign({}, reportItemState, {
        settings: this.updateSettings(reportItemState.settings, settingDefinition, value),
      });
      return {
        reportItems: [
          ...prevState.reportItems.filter((item) => item.internalId !== reportItem.internalId),
          newItemState,
        ],
      };
    });
  }

  private updateSettings(
    settings: ReportItemSetting[],
    settingDefinition: ReportItemSettingDefinition,
    newValue: string,
  ) {
    if (settings.find((s) => s.name === settingDefinition.name)) {
      return settings.map((setting) => {
        if (setting.name === settingDefinition.name) {
          return { ...setting, value: newValue };
        }
        return setting;
      });
    }
    return [
      ...settings,
      {
        name: settingDefinition.name,
        value: newValue,
      },
    ];
  }

  private itemSettingValue(reportItemDetails: ReportItemDetails, settingName: string, defaultValue: string): string {
    const item = this.state.reportItems.find((reportItem) => reportItem.internalId === reportItemDetails.internalId);
    if (!item) {
      return defaultValue;
    }
    const maybeSetting = item.settings.find((setting) => setting.name === settingName);
    return maybeSetting ? maybeSetting.value : defaultValue;
  }

  private poiOptions() {
    return (this.props.locations || []).map((location) => ({ label: location.name, value: location.id }));
  }

  private onChangeAddReportItemDropdown(value: string) {
    this.setState({ candidateSelectedReportTypeToAdded: value });
  }

  private onAddReportItemButton() {
    const selectedType = this.state.candidateSelectedReportTypeToAdded;
    if (selectedType !== null && selectedType !== '' && this.props.reportItemTypes?.includes(selectedType)) {
      this.setState({
        selectedReportItemTypes: this.state.selectedReportItemTypes.concat(
          this.generateReportItemState('', selectedType, []),
        ),
      });
    }
    this.setState({ candidateSelectedReportTypeToAdded: '' });
  }

  private onRemoveReportItemButton(reportItemInternalId: string) {
    const elementToRemove = this.state.selectedReportItemTypes.find(
      (value) => value.internalId === reportItemInternalId,
    );
    if (elementToRemove) {
      this.setState({ selectedReportItemTypes: _.without(this.state.selectedReportItemTypes, elementToRemove) });
    }
  }

  private onCreate() {
    const { projectId } = this.props.match.params;
    const { reportCategory } = this.state;
    if (
      this.isFormValid() &&
      (this.state.selectedReportItemTypes.length > 0 || (reportCategory && reportCategory === 'FFI'))
    ) {
      const reportItems: ReportItem[] = this.state.selectedReportItemTypes.map((reportItemType) =>
        this.reportItemState(reportItemType),
      );
      this.props.createReport(
        projectId,
        {
          name: this.state.name,
          description: this.state.description,
          recurring: this.state.recurring,
          recurrence_frequency: this.state.recurringTimeInterval,
          reportCategory: this.state.reportCategory,
          startDay: moment(this.state.startDay).format(OPERATIONAL_DATE_FORMAT),
          endDay: moment(this.state.endDay).format(OPERATIONAL_DATE_FORMAT),
          locations: isNotFFIReport(this.state.reportCategory) ? this.state.selectedPOIs.map((poi) => poi.value) : [],
          reportItems: isNotFFIReport(this.state.reportCategory) ? reportItems : [],
          visualizationURL: this.state.visualizationURL,
        },
        () => this.props.history.push(`/projects/${projectId}/reportIsBeingGenerated`),
      );
    }
  }

  private renderLastDaysClick(msg: string, numOfDays: number) {
    return (
      <a className={styles.datesPickerButtonsContainer} href="#" onClick={() => this.onLastDaysUpdate(numOfDays)}>
        {msg}
      </a>
    );
  }

  private renderLastMonthsClick(msg: string, numOfDays: number) {
    return (
      <a className={styles.datesPickerButtonsContainer} href="#" onClick={() => this.onLastMonthsUpdate(numOfDays)}>
        {msg}
      </a>
    );
  }

  private onLastDaysUpdate(numOfDays: number) {
    this.setState({ startDay: new Date(new Date().setDate(new Date().getDate() - numOfDays - 4)) });
    this.setState({ endDay: new Date(new Date().setDate(new Date().getDate() - 4)) });
  }

  private onLastMonthsUpdate(numOfMonths: number) {
    const date = new Date();
    const month = new Date().getMonth();
    const prevMonth = date.setMonth(month - numOfMonths);

    this.setState({ startDay: new Date(new Date(prevMonth).setDate(new Date(prevMonth).getDate() - 4)) });
    this.setState({ endDay: new Date(new Date().setDate(new Date().getDate() - 4)) });
  }

  private isFormValid() {
    if (this.state.reportCategory && this.state.reportCategory === 'FFI') {
      return true;
    }
    const { settingDefinitions } = this.props;
    if (settingDefinitions) {
      let itemErrors: ItemError[] = _.keys(settingDefinitions).map((supportedReportItemType) => {
        const selectedItemErrors: ItemError[] = this.state.selectedReportItemTypes
          .filter((reportItemDetails) => reportItemDetails.reportItemType === supportedReportItemType)
          .map((selectedItemDetails) => {
            const definitions = settingDefinitions[supportedReportItemType] || [];
            const errorsForItem: string[] = definitions
              .map((definition) => {
                const settingValue = this.itemSettingValue(
                  selectedItemDetails,
                  definition.name,
                  definition.defaultValue,
                );
                const isEmpty = !settingValue || !settingValue.length;
                if (definition.isMandatory && isEmpty) {
                  return definition.name;
                }
                return '';
              })
              .filter((error) => !!error);
            return {
              reportItemDetails: selectedItemDetails,
              errors: errorsForItem,
              errorInItemName: this.isReportItemNameInvalid(selectedItemDetails),
            };
          });
        return selectedItemErrors;
      })[0];
      if (!itemErrors) {
        itemErrors = [];
      }
      this.calcErrorReportItemNameWithoutSettingDefinitions(itemErrors);
      this.setState({ itemErrors });
      return (
        itemErrors?.flatMap((itemError) => itemError.errors).length === 0 &&
        this.validateReportFields() &&
        !itemErrors.find((itemError) => itemError.errorInItemName == true)
      );
    }
    // In case there are no global definitions at all, for any report type
    const itemErrors: ItemError[] = [];
    this.calcErrorReportItemNameWithoutSettingDefinitions(itemErrors);
    this.setState({ itemErrors });

    return false;
  }

  private validateReportFields() {
    return this.validateReportName();
  }

  private validateReportName() {
    const value = this.state.name;
    const res = value && value.length > 0;
    this.setState({ globalErrors: { isReportNameValid: !!res } });
    return res;
  }

  private isSettingInvalid(reportItemDetails: ReportItemDetails, settingName: string) {
    const item = this.state.itemErrors.find(
      (item) => item.reportItemDetails.internalId === reportItemDetails.internalId,
    ) || { errors: [] };
    return item.errors.find((erroneousSettingName) => erroneousSettingName === settingName);
  }

  private calcErrorReportItemNameWithoutSettingDefinitions(itemErrors: ItemError[]) {
    const errorsInItemsNames = this.state.selectedReportItemTypes.filter((item) => this.isReportItemNameInvalid(item));
    errorsInItemsNames.map((item) => {
      const itemAlreadyHasErrors = itemErrors?.find((element) => element.reportItemDetails === item);
      if (itemAlreadyHasErrors) {
        itemAlreadyHasErrors.errorInItemName = true;
      } else {
        itemErrors.push({ reportItemDetails: item, errors: [], errorInItemName: true });
      }
    });
  }

  private isReportItemNameInvalid(reportItemDetails: ReportItemDetails) {
    const reportItemName = this.state.reportItems.find(
      (item) => item.internalId === reportItemDetails.internalId,
    )?.name;
    return !reportItemName || !this.validateReportItemName(reportItemName);
  }

  private validateReportItemName(value: string) {
    return value && value.length > 0;
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  settingDefinitions: state.reportItemSettingDefinitions.settings,
  locations: state.locations.entries,
  reportItemTypes: state.reportItemTypes.types, // ?.map(reportItemFullType => reportItemFullType.substr(reportItemFullType.indexOf(".") + 1)).sort(),
});

const mapDispatchToProps: DispatchProps = {
  loadReportItemSettingDefinitions,
  loadLocations,
  createReport,
  loadReportItemTypes,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateReportPage));
