import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable, { BaseTableHeaderProps } from '../../../common/baseTable';
import { useTimePeriod } from '../../../common/hooks/useTimePeriod';
import { LocationHourlySeasonality } from '../../../common/types/projectsHierarchy';
import { TableDataFormat } from '../types';
import {numberToWeekday} from "../../../../function/numberToWeekday";

interface Props {
  data: LocationHourlySeasonality[];
}

const SeasonalityDetailTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const timeFrameString = useTimePeriod();

  const tableHeaders: BaseTableHeaderProps[] = [
    { key: 'hour', name: t('seasonality.detail.hourOfDay'), unit: '' },
    { key: 'value', name: t('seasonality.detail.visitsShare'), unit: '%' },
  ];

  const exportTableHeaders: BaseTableHeaderProps[] = [
    { key: 'hour', name: t('seasonality.detail.hourOfDay'), unit: '' },
    { key: 'value', name: t('seasonality.detail.visitsShare'), unit: '' },
    { key: 'timeframe', name: t('reportItem.timeFrame'), unit: '' },
  ];

  const tableData: TableDataFormat[] = useMemo(
    () =>
      data
        ? data.map((item) => ({
            head: item.locationName,
            options: item.data.map((subitem) => ({
              subhead: {
                value: subitem.dayOfWeekNumber.toString(),
                formatter: () => t(numberToWeekday(subitem.dayOfWeekNumber)).toString(),
              },
              values: {
                hour: subitem.hourOfDay,
                value: subitem.visitsShare * 100,
              },
            })),
          }))
        : [],
    [data],
  );

  const exportTableData: TableDataFormat[] = useMemo(
    () =>
      data
        ? data.map((item) => ({
            head: item.locationName,
            options: item.data.map((subitem) => ({
              subhead: {
                value: subitem.dayOfWeekNumber.toString(),
                formatter: () => t(numberToWeekday(subitem.dayOfWeekNumber)).toString(),
              },
              values: {
                hour: subitem.hourOfDay,
                value: subitem.visitsShare,
                timeframe: timeFrameString,
              },
            })),
          }))
        : [],
    [data],
  );

  return (
    <BaseTable<TableDataFormat>
      data={tableData}
      headers={tableHeaders}
      headName={t('location.location')}
      subheadName={t('seasonality.detail.dayOfWeek')}
      exportHeaders={exportTableHeaders}
      exportData={exportTableData}
    />
  );
};

export default SeasonalityDetailTable;
