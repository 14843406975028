import React from 'react';

export const AddAvatarIcon = () => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.25 3.5H10.4008L9.33333 2.33333H5.83333V3.5H8.82L9.8875 4.66666H12.25V11.6667H2.91667V6.41666H1.75V11.6667C1.75 12.3083 2.275 12.8333 2.91667 12.8333H12.25C12.8917 12.8333 13.4167 12.3083 13.4167 11.6667V4.66666C13.4167 4.025 12.8917 3.5 12.25 3.5ZM4.66667 8.16666C4.66667 9.77666 5.97333 11.0833 7.58333 11.0833C9.19333 11.0833 10.5 9.77666 10.5 8.16666C10.5 6.55666 9.19333 5.25 7.58333 5.25C5.97333 5.25 4.66667 6.55666 4.66667 8.16666ZM7.58333 6.41666C8.54583 6.41666 9.33333 7.20416 9.33333 8.16666C9.33333 9.12916 8.54583 9.91666 7.58333 9.91666C6.62083 9.91666 5.83333 9.12916 5.83333 8.16666C5.83333 7.20416 6.62083 6.41666 7.58333 6.41666ZM2.91667 3.5H4.66667V2.33333H2.91667V0.583328H1.75V2.33333H0V3.5H1.75V5.25H2.91667V3.5Z"
      fill="white"
    />
  </svg>
);
