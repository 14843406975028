import { createEffect } from 'effector';
import { FiltersOption, ParamsFilters } from '../interfaces';
import {
  getSocialDemographicCharacteristic,
  getSocialDemographicsFilters,
} from '../../../../../api/reportItems/catchment';
import { CatchmentTypeEnum } from '../../../../../common/reportItems/types';

export const getFilters = createEffect(
  async ({ reportItemId, paramsFilters }: { reportItemId: string; paramsFilters: ParamsFilters }) => {
    return {
      data: await getSocialDemographicsFilters<FiltersOption[]>(reportItemId),
      paramsFilters,
    };
  },
);

export const getData = createEffect(
  ({
    reportItemId,
    countryCode,
    catchmentType,
    locationsIds,
    timeFrames,
    minThreshold,
    maxThreshold,
    metric,
    category,
    subcategory,
  }: {
    reportItemId: string;
    countryCode: string;
    catchmentType: CatchmentTypeEnum;
    locationsIds: string[] | null;
    timeFrames: string[] | null;
    minThreshold: number;
    maxThreshold: number;
    metric: string;
    category: string;
    subcategory: string;
  }) =>
    getSocialDemographicCharacteristic(
      reportItemId,
      countryCode,
      catchmentType,
      locationsIds,
      timeFrames,
      minThreshold,
      maxThreshold,
      metric,
      category,
      subcategory,
    ),
);
