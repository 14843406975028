import React, { ReactNode, useState } from 'react';
import styles from './reportItemDetail.module.scss';
import Empty from './empty';
import { Toggle } from '../../controls/toggle';
import { ReportItemType, ReportItemVisualizationMode } from '../types';

import { ChartIcon, GraphIcon, InformationIcon } from '../../icons/reportItems';
import { ExportButtons } from './exports';
import { Tooltip } from '../../tooltip';

interface Props {
  title: string;
  subtitle: string;
  children: ReactNode;
  noData?: boolean;
  mode?: ReportItemVisualizationMode | null;
  setMode?: (value: ReportItemVisualizationMode) => void;
  disabledToggle?: boolean;
  onMakeScreenshot?: VoidFunction;
  base64Image?: string;
  type?: ReportItemType;
  hideToggle?: boolean;
  tooltipComponent?: ReactNode;
}

const ReportItemDetail: React.FC<Props> = ({
  title,
  subtitle,
  noData,
  children,
  disabledToggle,
  mode,
  setMode,
  onMakeScreenshot,
  type,
  hideToggle,
  tooltipComponent,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  return (
    <>
      <div className={styles.toggle}>
        {mode && !hideToggle && (
          <Toggle<ReportItemVisualizationMode>
            leftValue={ReportItemVisualizationMode.CHART}
            LeftIcon={GraphIcon}
            rightValue={ReportItemVisualizationMode.Graph}
            RightIcon={ChartIcon}
            selectedValue={mode}
            onClick={setMode}
            disabled={disabledToggle}
            withOptionFrame
          />
        )}
      </div>
      <div className={`custom-scrollbar ${styles.container}`}>
        <div className={styles.headingContainer}>
          <div>
            <h1>{title}</h1>
            <h2>
              {subtitle}
              {tooltipComponent && (
                <span className={styles.tooltipIcon} onClick={() => setTooltipOpen(true)}>
                  <InformationIcon />
                </span>
              )}
            </h2>
          </div>
          <div>{onMakeScreenshot && type && <ExportButtons onMakeScreenshot={onMakeScreenshot} type={type} />}</div>
        </div>

        <div className={styles.visualizationModeContainer}>
          <>
            {noData && <Empty />}
            {!noData && children}
          </>
        </div>
      </div>
      {tooltipOpen && tooltipComponent && (
        <Tooltip
          onClose={() => setTooltipOpen(false)}
          title="Profile characteristics"
          isOpen={tooltipOpen}
          height={500}
        >
          {tooltipComponent}
        </Tooltip>
      )}
    </>
  );
};

export default ReportItemDetail;
