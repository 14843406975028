import { API_URLS } from '../../../routes';
import { toDataURL } from '../../../common/utils';
import { API_URL } from '../../../../api';
import { commonRequestHeaders } from '../../../commonRequestHeaders';

export const getCompanyLogo = async (accountId: string) => {
  const url = API_URLS.COMPANY_LOGO;

  return toDataURL(`${API_URL}/api/accounts/${accountId}${url}`, commonRequestHeaders());
};
