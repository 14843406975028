import React from 'react';
import YearMonthPicker from './YearMonthPicker';
import styles from './yearMonthIntervalPicker.module.scss';

interface Props {
  startDate: Date;
  endDate: Date;
  onChangeStartDate: (startDate: Date) => void;
  onChangeEndDate: (endDate: Date) => void;
  minDate: Date;
  maxDate: Date;
  disabledFrom?: boolean;
  disabledTo?: boolean;
}

const compareDates = (a: Date, b: Date) => {
  if (a.getFullYear() < b.getFullYear() || (a.getFullYear() === b.getFullYear() && a.getMonth() < b.getMonth())) {
    return 1;
  }
  if (a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth()) {
    return 0;
  }
  return -1;
};

const YearMonthIntervalPicker: React.FC<Props> = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  minDate,
  maxDate,
  disabledFrom,
  disabledTo,
}) => {
  const changeStartDateHandler = (amount: number) => {
    const newDate = new Date(startDate);
    newDate.setMonth(newDate.getMonth() + amount);
    onChangeStartDate(newDate);
  };

  const changeEndDateHandler = (amount: number) => {
    const newDate = new Date(endDate);
    newDate.setMonth(newDate.getMonth() + 1 + amount, 0);
    onChangeEndDate(newDate);
  };

  return (
    <div className={styles.container}>
      <YearMonthPicker
        label="From"
        date={startDate}
        onChange={changeStartDateHandler}
        isIncreaseEnabled={compareDates(startDate, maxDate) === 1 && compareDates(startDate, endDate) === 1}
        isDecreaseEnabled={compareDates(startDate, minDate) === -1}
        disabled={disabledFrom}
      />
      <YearMonthPicker
        label="to"
        date={endDate}
        onChange={changeEndDateHandler}
        isIncreaseEnabled={compareDates(endDate, maxDate) === 1}
        isDecreaseEnabled={compareDates(endDate, minDate) === -1 && compareDates(endDate, startDate) === -1}
        disabled={disabledTo}
      />
    </div>
  );
};

export default YearMonthIntervalPicker;
