import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReportItemCard from '../../../common/reportItems/reportItemCard';
import {
  LocationTypeEnum,
  MovementPeriodEnum,
  MovementVisitorTypeEnum,
  ReportItemType,
} from '../../../common/reportItems/types';
import { periodDays } from '../utils';
import styles from './style.module.scss';
import { fetchMovementData, getAnalytics, getHotspots } from '../services';
import { MainContext } from '../../../common/types/mainContext';
import { ContentVisualizationMode, Movement, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { useToggle, useURLParams } from '../../../common/hooks';
import { Menu } from './menu';

import { MovementMainTooltip } from './tooltips';
import { isValidDateOfReport } from '../../utils';
import { MovementHotspotsData } from '../types';
import { dashboardModel } from '../../../dasboardLoader';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { useTooltipStartPosition } from '../../../common/reportItems/hooks';
import { SwitchModes } from '../../../common/types/visualizationObjects';
import { MovementPeriod } from './movementPeriod';
import { SelectItem } from '../../../common/types/SelectItem.interface';
import { REQUEST_DELAY } from '../../types';
import { IReportCategory } from '../../../../types/report';

export const MovementCard = () => {
  const { t } = useTranslation();
  const {
    primaryLocationId,
    selection: { selectedReport, selectedReportItem },
    selectedTimeFrames,
    updateReportItemOfSelectedReport,
    updateDashboardLoader,
    reportItemsDetails: {
      movementPeriod,
      locationType,
      movementType,
      dataLevel,
      movementTreshold,
      hotspotOverlay,
      colorScheme,
    },
    updateReportItemsDetailsValue,
    setMovementMode,
    setContentVisualizationMode,
    contentVisualizationMode,
  } = useContext(MainContext);

  const [isOpenedMenu, toggleOpenedMenu] = useToggle(false);
  const [isLoading, setIsLoading] = useToggle(false);
  const [fetched, setFetched] = useToggle(true);
  const [isTooltipOpen, toggleTooltipOpen] = useToggle(false);
  const { tooltipIconRef, position } = useTooltipStartPosition();
  const { isParsingDone, params, onChangeParams } = useURLParams();

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.MOVEMENT,
  ) as ReportItemInfo<Movement>;

  const updateLoader = (visible: boolean, label?: string, progress?: number) => {
    updateDashboardLoader(ReportItemType.MOVEMENT, visible, label, progress);
  };
  const showGalleryAndAnalytics = useMemo(() => {
    const dateToUse = reportItem?.createdDate || selectedReport?.created_date;
    return dateToUse ? isValidDateOfReport(dateToUse) : false;
  }, [reportItem]);

  useEffect(() => {
    if (
      selectedReportItem?.type === ReportItemType.MOVEMENT &&
      ContentVisualizationMode.TABLE === contentVisualizationMode
    ) {
      setContentVisualizationMode(ContentVisualizationMode.VISUALIZATION);
    }
  }, [selectedReportItem]);

  useEffect(() => {
    if (isParsingDone) {
      (async function () {
        if (reportItem) {
          if (primaryLocationId) {
            setFetched(false);
            setIsLoading(true);
            if (selectedReportItem?.type === ReportItemType.MOVEMENT) {
              dashboardModel.toggleDashboardLoading(true);
            }
            dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.MOVEMENT, value: false });
            if (selectedReportItem?.type !== ReportItemType.MOVEMENT) {
              await new Promise((resolve) => setTimeout(resolve, REQUEST_DELAY));
            }
            let response;
            let hotspots: MovementHotspotsData | undefined;
            let spider;

            if (showGalleryAndAnalytics) {
              [response, hotspots, spider] = await Promise.all([
                fetchMovementData(
                  reportItem.id,
                  primaryLocationId,
                  selectedTimeFrames,
                  updateLoader,
                  selectedReport?.category,
                ),
                getHotspots(reportItem.id, primaryLocationId, selectedTimeFrames),
                getAnalytics(reportItem.id, primaryLocationId, selectedTimeFrames),
              ]);
            } else {
              response = await fetchMovementData(
                reportItem.id,
                primaryLocationId,
                selectedTimeFrames,
                updateLoader,
                selectedReport?.category,
              );
            }

            if (response?.data) {
              const updatedMovementData = {
                ...response,
                data: {
                  ...response.data,
                  hotspotsData: hotspots,
                  analyticsData: { [primaryLocationId]: spider },
                  showGallery: showGalleryAndAnalytics,
                  showAnalytics: showGalleryAndAnalytics,
                },
                settings: [],
              };
              updateReportItemOfSelectedReport(updatedMovementData);
            } else if (selectedTimeFrames?.length === 0) {
              updateReportItemOfSelectedReport({
                id: reportItem.id,
                name: reportItem.name,
                type: reportItem.type,
                data: null,
                visualization: null,
                settings: [],
              });
              setMovementMode(SwitchModes.HEATMAP);
            }
            setFetched(true);
            setIsLoading(false);
            if (selectedReportItem?.type === ReportItemType.MOVEMENT) {
              dashboardModel.toggleDashboardLoading(false);
            }
            dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.MOVEMENT, value: true });
          }
        }
      })();
    }
  }, [primaryLocationId, selectedTimeFrames, isParsingDone]);

  const onChangeActivePeriodDay = (item: SelectItem) => {
    trackUserAction(
      'Movement visitation period changed',
      UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
      `Period: ${item.value}`,
    );
    updateReportItemsDetailsValue('movementPeriod', item.value);
    onChangeParams('movementPeriod', item.value);
  };

  const hotspotsLocations = useMemo(() => {
    if (movementPeriod === MovementPeriodEnum.BEFORE) {
      if (locationType === LocationTypeEnum.POIS) {
        return reportItem?.data?.hotspotsData?.before?.locations.pois;
      }
      return reportItem?.data?.hotspotsData?.before?.locations.buildings;
    }
    if (movementPeriod === MovementPeriodEnum.AFTER) {
      if (locationType === LocationTypeEnum.POIS) {
        return reportItem?.data?.hotspotsData?.after?.locations.pois;
      }
      return reportItem?.data?.hotspotsData?.after?.locations.buildings;
    }
    if (movementPeriod === MovementPeriodEnum.DURING && selectedReport?.category === IReportCategory.AOI_DEFAULT) {
      if (locationType === LocationTypeEnum.POIS) {
        return reportItem?.data?.hotspotsData?.during?.locations.pois;
      }
      return reportItem?.data?.hotspotsData?.during?.locations.buildings;
    }
    return null;
  }, [movementPeriod, locationType, reportItem?.data?.hotspotsData]);

  const topArea = useMemo(
    () =>
      hotspotsLocations?.length
        ? hotspotsLocations?.reduce((res, i) => (res.visits_share > i.visits_share ? res : i), { visits_share: 0.001 })
        : undefined,
    [hotspotsLocations],
  );

  const lowArea = useMemo(
    () => hotspotsLocations?.reduce((res, i) => (res.visits_share < i.visits_share ? res : i), { visits_share: 0 }),
    [hotspotsLocations],
  );

  const onTooltipClose = () => {
    toggleTooltipOpen(false);
  };

  useEffect(() => {
    if (isParsingDone) {
      const { movementThresholdMin, movementThresholdMax } = params;
      if (!(movementThresholdMin && movementThresholdMax)) {
        if (topArea && lowArea) {
          const { visits_share: max } = topArea;
          const { visits_share: min } = lowArea;
          updateReportItemsDetailsValue('movementTreshold', { min, max });
        }
      }
    }
  }, [topArea, lowArea, isParsingDone]);

  useEffect(() => {
    if (isParsingDone) {
      if (selectedReportItem?.type === ReportItemType.MOVEMENT) {
        const {
          dataLevel: dataLevelParam,
          locationType: locationTypeParam,
          movementThresholdMin,
          movementThresholdMax,
          colorScheme: colorSchemeParam,
          hotspotOverlay: hotspotOverlayParam,
          movementPeriod: movementPeriodParam,
        } = params;
        if (!dataLevelParam) {
          onChangeParams('dataLevel', dataLevel);
        }
        if (!locationTypeParam) {
          onChangeParams('locationType', locationType);
        }
        if (!(movementThresholdMin && movementThresholdMax)) {
          const { min, max } = movementTreshold;
          onChangeParams('movementThresholdMin', min);
          onChangeParams('movementThresholdMax', max);
        }
        if (!colorSchemeParam) {
          onChangeParams('colorScheme', colorScheme);
        }
        if (!hotspotOverlayParam) {
          onChangeParams('hotspotOverlay', hotspotOverlay);
        }
        if (!movementPeriodParam) {
          onChangeParams('movementPeriod', movementPeriod);
        }
      }
    }
  }, [isParsingDone, selectedReportItem?.type]);

  const noData = useMemo(() => fetched && (!reportItem || !reportItem.data), [reportItem, fetched, isLoading]);
  const availableTypes = selectedReport?.report_items?.map((value) => value.type);

  return !availableTypes?.includes(ReportItemType.MOVEMENT) ? (
    <div />
  ) : (
    <ReportItemCard
      reportItemType={ReportItemType.MOVEMENT}
      reportItem={reportItem}
      isLoading={isLoading}
      noData={noData}
      onHeaderMenuClick={toggleOpenedMenu}
      isHeaderMenuOpened={isOpenedMenu}
      onOpenTooltip={toggleTooltipOpen}
      tooltipRef={tooltipIconRef}
    >
      <>
        <MovementMainTooltip onClose={onTooltipClose} isOpen={isTooltipOpen} position={position} />
        <Menu
          onClose={toggleOpenedMenu}
          onOpenTooltip={toggleTooltipOpen}
          isOpenedMenu={isOpenedMenu}
          maxVisitShare={topArea?.visits_share}
          minVisitShare={lowArea?.visits_share}
          hasHotspots={showGalleryAndAnalytics}
        />
        <div className={styles.options}>
          {movementType === MovementVisitorTypeEnum.VISITORS &&
            selectedReport?.category === IReportCategory.POI_DEFAULT && (
              <MovementPeriod
                items={periodDays.map((item) => ({
                  value: item.value,
                  label: `movement.enum.${item.value}`,
                }))}
                activeItem={movementPeriod}
                onChange={onChangeActivePeriodDay}
                isLoading={isLoading}
              />
            )}
        </div>
      </>
    </ReportItemCard>
  );
};
