import React from 'react';
import {
  CSVImportValidationError,
  PolygonSizeValidationError,
  ValidationError,
  ValidationErrorType,
} from './validationErrorType';
import MaximumNumberOfLocationsErrorMessage from './MaximumNumberOfLocationsErrorMessage';
import PolygonTooLargeErrorMessage from './PolygonTooLargeErrorMessage';
import MaximumNumberOfClustersErrorMessage from './MaximumNumberOfClustersErrorMessage';
import CSVImportErrorMessage from './CSVImportErrorMessage';

interface Props {
  error: ValidationError;
  onClose: VoidFunction;
  isOpen: boolean;
}

const ValidationErrorMessage = ({ error, onClose, isOpen }: Props) => (
  <>
    {error.type === ValidationErrorType.NUMBER_OF_LOCATIONS && (
      <MaximumNumberOfLocationsErrorMessage onClose={onClose} isOpen={isOpen} />
    )}
    {error.type === ValidationErrorType.POLYGON_SIZE && (
      <PolygonTooLargeErrorMessage
        onClose={onClose}
        isOpen={isOpen}
        size={(error as PolygonSizeValidationError).size}
      />
    )}
    {error.type === ValidationErrorType.NUMBER_OF_CLUSTERS && (
      <MaximumNumberOfClustersErrorMessage onClose={onClose} isOpen={isOpen} />
    )}
    {error.type === ValidationErrorType.CSV && (
      <CSVImportErrorMessage onClose={onClose} isOpen={isOpen} errors={(error as CSVImportValidationError).errors} />
    )}
  </>
);

export default ValidationErrorMessage;
