import {
  $exportOptions,
  $exportData,
  $advancedReportItemsInformation,
  $exportedFormats,
  $exportedMetrics,
  $isLoading,
  $isExportMode,
  $usePDFPreview,
} from './stores';
import {
  updateExportOptions,
  resetOptionsData,
  updateExportData,
  updateReportItemsInformation,
  updateExportedFormats,
  updateExportedMetrics,
  toggleIsLoading,
  toggleIsExportMode,
  toggleUsePDFPreview,
} from './events';

export const exportSettingsModel = {
  $exportOptions,
  resetOptionsData,
  updateExportOptions,
  $exportData,
  updateExportData,
  $advancedReportItemsInformation,
  updateReportItemsInformation,
  $exportedFormats,
  updateExportedFormats,
  $exportedMetrics,
  updateExportedMetrics,
  $isLoading,
  toggleIsLoading,
  $isExportMode,
  toggleIsExportMode,
  $usePDFPreview,
  toggleUsePDFPreview,
};
