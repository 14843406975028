import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipPositionCoordinates } from '../../../../common/reportItems/hooks';
import { Tooltip } from '../../../../common/tooltip';

interface Props {
  onClose: VoidFunction;
  position?: TooltipPositionCoordinates;
  isOpen: boolean;
}

export const CatchmentMainTooltip: React.FC<Props> = ({ onClose, isOpen, position }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      onClose={onClose}
      title={t('reportItem.howItWorks')}
      isOpen={isOpen}
      initialPosition={position}
      height={300}
    >
      <>
        <p>
          {t('catchment.tooltip.paragraph1')}
          <br />
          {t('catchment.tooltip.paragraph2')}
        </p>
        <p>{t('catchment.tooltip.paragraph3')}</p>
      </>
    </Tooltip>
  );
};
