import React, { FormEvent, useEffect, useRef, useState } from 'react';
import styles from './style.module.scss';
import { splitCoordinatesArrayToPairs, splitCoordinatesByPolygons } from '../../../../polylineTool';
import { createPolygonFromWKT } from '../../../locationManager/validation/createPolygon';

const isWKTFormat = (polygon: string): boolean => {
  const normalizedPolygon = polygon.trim().toUpperCase();
  return (
    normalizedPolygon.toUpperCase().startsWith('POLYGON') || normalizedPolygon.toUpperCase().startsWith('MULTIPOLYGON')
  );
};

interface Props {
  polygon: string;
  onChange?: (value: string) => void;
}
export const PolygonField: React.FC<Props> = ({ polygon, onChange }) => {
  const [coordinates, setCoordinates] = useState<number[][][]>([[]]);
  const storedCoordinates = useRef<number[][][]>([]);
  const [isHidden, toggleIsHidden] = useState(false);

  const parsePolygonString = (polygon: string) => {
    const parsedCoordinates = polygon.split('\n');
    const coordinates = splitCoordinatesByPolygons(parsedCoordinates);
    const result = coordinates.map((coordinate) => splitCoordinatesArrayToPairs(coordinate.split(',')));
    return result;
  };

  useEffect(() => {
    if (polygon) {
      const result = parsePolygonString(polygon);
      storedCoordinates.current = result;
      setCoordinates(result);
    } else {
      storedCoordinates.current = [];
      setCoordinates([[]]);
    }
  }, [polygon]);

  const onChangeValue = (e: FormEvent<HTMLDivElement>) => (coordinateIdx: number) => {
    const value = e.currentTarget.innerText;

    if (coordinateIdx === coordinates.length - 1) {
      const dividedValue = value.split(',\n');
      const lastValueElement = dividedValue[dividedValue.length - 1];
      if (lastValueElement !== '\n' && lastValueElement.includes('\n')) {
        toggleIsHidden(true);
        storedCoordinates.current = [...storedCoordinates.current, []];
        setCoordinates((prevState) => [...prevState, []]);
        return;
      }
    }
    let allCoordinatesInString = '';

    if (isWKTFormat(value)) {
      // @ts-ignore
      const rawArray: number[][][] = createPolygonFromWKT(value).geometry.coordinates;
      if (rawArray?.length <= 1) {
        // @ts-ignore
        storedCoordinates.current[coordinateIdx] = rawArray[0];
        const unActiveCoordinates = storedCoordinates.current.filter((_, idx) => idx !== coordinateIdx);
        const parsedUnActiveCoordinates = unActiveCoordinates.reduce((acc, curr) => {
          const coordinate = curr.reduce(
            (allCoordinates, currCoordinates, idx) =>
              `${allCoordinates + currCoordinates.join(',\n')}${idx !== curr.length - 1 ? ',\n' : ''}`,
            '',
          );
          return acc ? `${acc}\n\n${coordinate}` : coordinate;
        }, '');

        allCoordinatesInString = `${parsedUnActiveCoordinates ? `${parsedUnActiveCoordinates}\n\n` : ''}${rawArray
          .flat(3)
          .join(', ')}`;
      } else {
        let beginIndex = storedCoordinates.current.length - 1;
        rawArray.forEach((item, index) => {
          beginIndex += 1;
          storedCoordinates.current[beginIndex] = rawArray[index];
        });

        const unActiveCoordinates = storedCoordinates.current.filter((_, idx) => idx !== coordinateIdx);
        const parsedUnActiveCoordinates = unActiveCoordinates.reduce((acc, curr) => {
          const coordinate = curr.reduce(
            (allCoordinates, currCoordinates, idx) =>
              `${allCoordinates + currCoordinates.join(',\n')}${idx !== curr.length - 1 ? ',\n' : ''}`,
            '',
          );
          return acc ? `${acc}\n\n${coordinate}` : coordinate;
        }, '');

        allCoordinatesInString = `${parsedUnActiveCoordinates ? `${parsedUnActiveCoordinates}\n\n` : ''}${rawArray
          .map((item) => item.flat(3).join(', '))
          .join('\n\n')}`;
      }
    } else {
      const result = parsePolygonString(value);
      storedCoordinates.current[coordinateIdx] = result[0];
      const unActiveCoordinates = storedCoordinates.current.filter((_, idx) => idx !== coordinateIdx);
      const parsedUnActiveCoordinates = unActiveCoordinates.reduce((acc, curr) => {
        const coordinate = curr.reduce(
          (allCoordinates, currCoordinates, idx) =>
            `${allCoordinates + currCoordinates.join(',\n')}${idx !== curr.length - 1 ? ',\n' : ''}`,
          '',
        );
        return acc ? `${acc}\n\n${coordinate}` : coordinate;
      }, '');

      allCoordinatesInString = `${parsedUnActiveCoordinates ? `${parsedUnActiveCoordinates}\n\n` : ''}${value}`;
    }

    if (onChange) {
      onChange(allCoordinatesInString);
    }
  };

  useEffect(() => {
    if (isHidden) {
      toggleIsHidden(false);
    } else {
      const element = document.getElementById(`coordinates-${coordinates.length - 1}`);

      if (element) {
        element.focus();
      }
    }
  }, [isHidden]);

  return (
    <div className={styles.textarea}>
      {coordinates.map((data, idx) => {
        const concatData = data.reduce(
          (acc, curr, idx) => `${acc + curr.join(',\n')}${idx !== data.length - 1 ? ',' : ''}\n`,
          '',
        );

        return (
          <div className={styles.polygon} key={`polygonField-${idx}`}>
            <p className={styles.title}>Polygon {idx + 1}</p>
            <div
              className={`coordinates ${styles.coordinates}`}
              contentEditable
              onInput={(e) => onChangeValue(e)(idx)}
              suppressContentEditableWarning
              id={`coordinates-${idx}`}
            >
              {concatData}
            </div>
          </div>
        );
      })}
    </div>
  );
};
