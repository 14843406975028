import { getDemographicData } from './effects';
import { $categories, $subCategories, $retails, $locationInformation } from './stores';

export * from './interfaces';

export const catchmentModel = {
  getDemographicData,
  $categories,
  $subCategories,
  $retails,
  $locationInformation,
};
