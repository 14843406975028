import moment, { Moment } from 'moment';
import { AppActions } from '../actions/actionTypes';
import { SettingDefinitionsForItems } from '../types/reportItemSettingDefinition';
import { dateInThePast } from '../time/timeUtils';

export interface ReportItemSettingDefinitionsState {
  settings?: SettingDefinitionsForItems;
  savedAt: Moment;
}

const initialState: ReportItemSettingDefinitionsState = {
  settings: undefined,
  savedAt: dateInThePast,
};

export default function reportItemSettingDefinitionsReducer(state = initialState, action: AppActions) {
  switch (action.type) {
    case 'LOAD_REPORT_ITEM_SETTINGS_SUCCESS':
      return {
        ...state,
        settings: action.payload.settings,
      };
    case 'EDIT_REPORT_ITEM_SETTINGS_SUCCESS': {
      return {
        ...state,
        savedAt: moment(),
      };
    }
    default:
      return state;
  }
}
