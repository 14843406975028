import React from 'react';

export const ResidentIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3337 5.33268C21.3337 3.85268 22.5203 2.66602 24.0003 2.66602C25.4803 2.66602 26.667 3.85268 26.667 5.33268C26.667 6.81268 25.4803 7.99935 24.0003 7.99935C22.5203 7.99935 21.3337 6.81268 21.3337 5.33268ZM26.667 29.3327V21.3327H30.0003L26.6137 11.1593C26.2403 10.066 25.227 9.33268 24.0803 9.33268H23.9203C22.7737 9.33268 21.747 10.066 21.387 11.1593L20.2403 14.5993C21.6803 15.3993 22.667 16.906 22.667 18.666V29.3327H26.667ZM7.33366 7.99935C8.81366 7.99935 10.0003 6.81268 10.0003 5.33268C10.0003 3.85268 8.81366 2.66602 7.33366 2.66602C5.85366 2.66602 4.66699 3.85268 4.66699 5.33268C4.66699 6.81268 5.85366 7.99935 7.33366 7.99935ZM10.0003 29.3327V19.9993H12.0003V11.9993C12.0003 10.5327 10.8003 9.33268 9.33366 9.33268H5.33366C3.86699 9.33268 2.66699 10.5327 2.66699 11.9993V19.9993H4.66699V29.3327H10.0003Z"
      fill="#DBAA85"
    />
    <path
      d="M18.8333 13.5C18.8333 14.6067 17.94 15.5 16.8333 15.5C15.7267 15.5 14.8333 14.6067 14.8333 13.5C14.8333 12.3933 15.7267 11.5 16.8333 11.5C17.94 11.5 18.8333 12.3933 18.8333 13.5Z"
      fill="#EADED5"
    />
    <path
      d="M18.8333 24.1667V29.5H14.8333V24.1667H13.5V18.8333C13.5 17.74 14.4067 16.8333 15.5 16.8333H18.1667C19.26 16.8333 20.1667 17.74 20.1667 18.8333V24.1667H18.8333Z"
      fill="#EADED5"
    />
  </svg>
);
