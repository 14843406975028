export const getPointsColorScheme = (): string[] => {
  const colors = [
    '#e67474',
    '#fea820',
    '#64b6f6',
    '#9be19e',
    '#ffced3',
    '#bb68c9',

    '#e63632',
    '#ef6c00',
    '#1889e6',
    '#baddf9',
    '#ffedb5',
    '#ce93d8',

    '#fe3d81',
    '#ffd201',
    '#3fa5f4',
    '#8cc449',
    '#ef9b9b',
    '#ef6192',

    '#ee514e',
    '#fa8c00',
    '#80d2f7',
    '#bce0b4',
    '#fefe00',
    '#f8bcd1',

    '#21a79a',
    '#ffcd81',
    '#40a044',
    '#dece35',
    '#fef076',
    '#fdb64b',
  ];
  return colors;
};
