import { createStore } from 'effector';
import { ReportItemType } from '../../../reportItems/types';
import { ExportData, ExportOptions } from './interfaces';
import {
  resetOptionsData,
  updateExportData,
  updateExportedFormats,
  updateExportedMetrics,
  updateExportOptions,
  updateReportItemsInformation,
  toggleIsLoading,
  toggleIsExportMode,
  toggleUsePDFPreview,
} from './events';

import { ReportItemsInformation } from './interfaces/ReportItemsInformation.interface';

export const $exportOptions = createStore<ExportOptions>(
  Object.values(ReportItemType).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        map: false,
        chart: false,
        table: false,
      },
    }),
    {},
  ),
);

$exportOptions.on(updateExportOptions, (store, data) => ({ ...store, ...data }));

$exportOptions.reset(resetOptionsData);

export const $exportData = createStore<ExportData>(
  Object.values(ReportItemType).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        map: null,
        chart: null,
        table: null,
      },
    }),
    {},
  ),
);

export const $advancedReportItemsInformation = createStore<ReportItemsInformation>({
  catchment: {
    topArea: null,
    visitsShare: null,
    distanceFromLocation: null,
    drivingTime: null,
    aerialDistance: null,
  },
  duration: {
    averageTime: null,
  },
  seasonality: {
    popularDay: null,
    popularHour: null,
  },
  retention: {
    returningAverage: null,
  },
  crossVisitation: {
    singleLocationAverage: null,
  },
  absorption: { visitFrom: null },
  attraction: { visitFrom: null },
});

$advancedReportItemsInformation.on(updateReportItemsInformation, (store, data) => {
  return { ...store, ...data };
});

$exportData.reset(resetOptionsData);

$exportData.on(updateExportData, (store, data) => {
  const [[key, object]] = Object.entries(data);
  return { ...store, [key]: { ...store[key as ReportItemType], ...object } };
});

export const $exportedFormats = createStore<string>('');

$exportedFormats.on(updateExportedFormats, (_, data) => data);

export const $exportedMetrics = createStore<string[]>([]);

$exportedMetrics.on(updateExportedMetrics, (_, data) => data);

export const $isLoading = createStore(false);

$isLoading.on(toggleIsLoading, (_, data) => data);

export const $isExportMode = createStore(false);

$isExportMode.on(toggleIsExportMode, (_, data) => data);

export const $usePDFPreview = createStore(false);

$usePDFPreview.on(toggleUsePDFPreview, (_, data) => data);
