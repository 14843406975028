import React, { RefObject } from 'react';
import styles from './table.module.scss';

interface TProps {
  title: string;
  subtitle?: string;
  blueTitle?: string;
  children?: React.ReactNode;
  className?: string;
  ref?: RefObject<HTMLDivElement>;
}

const Table = (props: TProps) => (
  <div
    className={`${styles.table} ${props.className ? props.className : ''}`}
    id="scrollable-table-content"
    ref={props.ref}
  >
    {props.blueTitle && <div className={styles.blueTitle}>{props.blueTitle}</div>}
    <div className={styles.title}>{props.title}</div>
    {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
    <div className={styles.tableContent}>{props.children}</div>
  </div>
);

export default Table;
