import React, { ChangeEvent } from 'react';
import styles from './style.module.scss';
import CheckIcon from '../../icons/controls/check';

interface Props {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isChecked: boolean;
  id?: string;
  name: string;
}
export const Checkbox: React.FC<Props> = ({ onChange, disabled, isChecked, id, name }) => (
  <label htmlFor={name} className={`${styles.label} ${isChecked ? styles.checked : ''} `}>
    <span className={styles.icon}>
      <CheckIcon />
    </span>
    <input
      id={id}
      name={name}
      type="checkbox"
      checked={isChecked}
      disabled={disabled}
      onChange={onChange || ((e) => {})}
      className={styles.input}
    />
  </label>
);
