import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseModal } from '../../baseModal';
import { Form } from './form';
import { Credentials } from './interfaces';
import { createNewPassword } from '../../../api';
import { Routes } from '../../../routes';

interface Props {
  token: string;
}
export const CreateNewPassword: React.FC<Props> = ({ token }) => {
  const history = useHistory();
  const [error, setError] = useState('');

  const onSubmit = async (data: Credentials) => {
    try {
      await createNewPassword({ ...data, token });
      history.replace(Routes.createNewPasswordSuccess);
    } catch (error) {
      const { data } = error.response;
      setError(data.reason);
    }
  };

  return (
    <BaseModal
      subtitle="Password must be at least 8 characters long and include letters, digits and symbols."
      title="New password"
    >
      <Form error={error} onSubmit={onSubmit} />
    </BaseModal>
  );
};
