import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseModal } from '../../baseModal';
import { Form } from './form';
import { sentResetPasswordEmail } from '../../../api';
import { Credentials } from './interfaces';
import { Routes } from '../../../routes';

interface Props {}
export const ResetPassword: React.FC<Props> = () => {
  const history = useHistory();
  const [error, setError] = useState('');

  const onSubmit = async (data: Credentials) => {
    try {
      await sentResetPasswordEmail(data);
      history.push(Routes.forgotPasswordSuccessfully, { email: data.email });
    } catch (error) {
      const { response } = error;
      if (response.status === 404) {
        setError(response.data.reason);
      }
    }
  };

  return (
    <BaseModal
      subtitle={"Fill in your email below and we'll send you instructions on how to reset your password."}
      title="Reset password"
    >
      <Form error={error} onSubmit={onSubmit} />
    </BaseModal>
  );
};
