import React from 'react';

interface Props {
  onClick?: (event: React.MouseEvent) => void;
}

export const ArrowDownIcon = (props: Props) => (
  <svg onClick={props.onClick} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.47 0.589844L6.88 5.16984L2.29 0.589844L0.880005 1.99984L6.88 7.99984L12.88 1.99984L11.47 0.589844Z"
      fill="currentColor"
    />
  </svg>
);

ArrowDownIcon.defaultProps = {
  onClick: () => {
    /* empty */
  },
};
