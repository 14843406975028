import React, { useEffect, useRef, useState } from 'react';
import { BBox } from '@turf/helpers';
import MapGL, { Marker, WebMercatorViewport } from 'react-map-gl';
import LocationAddedIcon from '@material-ui/icons/WhereToVote';
import { MapViewPort } from '../../../../customerPortalv2/common/maps/mapBase/mapBase';
import { MAP_STYLE_SHOTS, MAP_TOKEN } from '../../../../customerPortalv2/common/maps/mapBase/map.config';
import s from './MapBase.module.css';

interface MapBaseProps {
  initialBbox?: BBox | number[];
  marker?: number[];
}

const MapBase: React.FC<MapBaseProps> = ({ initialBbox, children, marker: markerFromProps }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [viewport, setViewport] = useState<MapViewPort>({
    latitude: 0,
    longitude: 0,
    zoom: 10,
  });
  const [marker, setMarker] = useState<number[]>();

  useEffect(() => {
    setMarker(markerFromProps);
  }, [markerFromProps]);

  useEffect(() => {
    const initMapPosition = async () => {
      if (rootRef.current && initialBbox) {
        const [minLng, minLat, maxLng, maxLat] = initialBbox;

        const vp = new WebMercatorViewport({
          width: rootRef.current.offsetWidth,
          height: rootRef.current.offsetHeight,
        });
        const { longitude, latitude, zoom } = vp.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: 20,
          },
        );
        setViewport({
          latitude,
          longitude,
          zoom,
        });
      } else if (rootRef.current && initialBbox === undefined) {
        setViewport({
          longitude: 9,
          latitude: 50,
          zoom: 4,
        });
      }
    };
    initMapPosition();
    window.addEventListener('resize', initMapPosition);
    return () => window.removeEventListener('resize', initMapPosition);
  }, [rootRef, initialBbox, setViewport]);

  return (
    <div ref={rootRef} className={s.root}>
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle={MAP_STYLE_SHOTS}
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAP_TOKEN}
      >
        {marker && marker.length > 0 && (
          <Marker longitude={marker[0]} latitude={marker[1]}>
            <LocationAddedIcon style={{ color: '#ff0000' }} />
          </Marker>
        )}
        {children}
      </MapGL>
    </div>
  );
};

export default MapBase;
