import React from 'react';

interface Props {
  isActive: boolean;
}
export const SortByIcon = ({ isActive }: Props) => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 12L8.33337 9.33333H10.3334V2.66667H8.33337L11 0L13.6667 2.66667H11.6667V9.33333H13.6667L11 12ZM0.333374 10.6667V9.33333H7.00004V10.6667H0.333374ZM0.333374 6.66667V5.33333H5.00004V6.66667H0.333374ZM0.333374 2.66667V1.33333H3.00004V2.66667H0.333374Z"
      fill="white"
    />
  </svg>
);
