import { toFixNumber } from '../../../common/lib';
import { RetentionLocation } from '../../../common/types/projectsHierarchy';
import { calculateLocationAverageByValues } from '../../../common/utils';
import { RetentionModifiedData } from '../types';

export const calculateRetentionReturningVisits = (values: RetentionLocation[]) => {
  let totalRecords = 0;
  const returningVisitsLocationsAverage = new Map<string, number>();
  let returningAverage = 0;
  let newVisitsAverage = 0;

  values.forEach(({ data, location_id }) => {
    totalRecords += data.length;
    const locationSum = calculateLocationAverageByValues(data.map(({ value }) => Number(value)));
    const locationNewVisitsSum = calculateLocationAverageByValues(
      data.map(({ value }) => (100 - Number(value) * 100) / 100),
    );
    newVisitsAverage += toFixNumber(locationNewVisitsSum, 3);
    returningVisitsLocationsAverage.set(location_id, toFixNumber(locationSum / data.length, 2));
    returningAverage += toFixNumber(locationSum, 2);
  });

  returningAverage /= totalRecords;
  newVisitsAverage /= totalRecords;

  // cross-locations average calculation
  const avgByRow: RetentionModifiedData[] = [];
  if (values && values.length) {
    values[0].data.forEach((header, headerIndex) => {
      const sum = values.reduce(
        (acc, valueItem) => (valueItem.data[headerIndex] ? acc + (valueItem.data[headerIndex].value as number) : acc),
        0,
      );
      avgByRow.push({
        date: header.date,
        value: sum / values.length || 0,
      });
    });
  }

  return {
    returningAverage,
    newVisitsAverage,
    returningVisitsAcrossLocations: (avgByRow || []) as RetentionModifiedData[],
    returningVisitsLocationsAverage,
  };
};
