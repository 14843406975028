import { createStore } from 'effector';
import { userSignUp } from './effects';
import { errorOccurred } from './events';

export const $isSignUpSuccessful = createStore<boolean>(false);

export const $userData = createStore<{ name: string; email: string }>({ name: '', email: '' });

export const $error = createStore('');

$isSignUpSuccessful.on(userSignUp.doneData, (_, data) => !!data);

$userData.on(userSignUp.doneData, (_, data) => data);

$error.on(errorOccurred, (_, data) => data);
