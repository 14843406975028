import ColorScale from 'color-scales';
import Color from 'color-scales/lib/Color';
import {
  COLOR_START,
  COLOR_END,
  COLOR_LEVELS,
  COLOR_MIDDLE,
  MONO_COLOR_START,
  MONO_COLOR_MIDDLE,
  MONO_COLOR_END,
} from './color.config';

const getBasicColor = (value: number, isMonochrome = false) => {
  const colorStart = isMonochrome ? MONO_COLOR_START : COLOR_START;
  const colorMiddle = isMonochrome ? MONO_COLOR_MIDDLE : COLOR_MIDDLE;
  const colorEnd = isMonochrome ? MONO_COLOR_END : COLOR_END;
  const gradient1 = new ColorScale(0, Math.floor(COLOR_LEVELS / 2), [colorStart, colorMiddle], 1);
  const gradient2 = new ColorScale(Math.ceil(COLOR_LEVELS / 2), COLOR_LEVELS - 1, [colorMiddle, colorEnd], 1);

  return value > Math.floor(COLOR_LEVELS / 2) ? gradient2.getColor(value) : gradient1.getColor(value);
};

/**
 * Create get color by value
 * @param value - index from 0 to COLOR_LEVELS
 * @param isMonochrome
 * @return  color
 */
export const getGradientColor = (value: number, isMonochrome = false) =>
  getBasicColor(value, isMonochrome).toHexString();

/**
 * Return color index from 0 to COLOR_LEVELS
 * @param value - current value
 * @param min - min value in range
 * @param max - max value in range
 */
export const getColorIndex = (value: number, min: number, max: number) => {
  if (min === max) return COLOR_LEVELS - 1;
  const index = Math.round(((value - min) / (max - min)) * COLOR_LEVELS);
  return index < 0 ? 0 : index > COLOR_LEVELS - 1 ? COLOR_LEVELS - 1 : index;
};

/**
 * Create color scale array in format [color1, ...., colorN] from COLOR_LEVELS
 * @return [color1, ...., colorN]
 */
export const getColorScale = (isMonochrome: boolean): Color[] => {
  const scale = [];
  for (let i = 1; i <= COLOR_LEVELS; i++) {
    scale.push(getBasicColor(i, isMonochrome));
  }
  return scale;
};

/**
 * Create color scale array in format [value1, color1, ...., valueN, colorN] from COLOR_LEVELS
 * @return [value1, color1, ...., valueN, colorN]
 */
export const getColorScaleWithValue = (isMonochrome: boolean) => {
  const scale = [];
  for (let i = 1; i <= COLOR_LEVELS; i++) {
    scale.push(i / COLOR_LEVELS);
    scale.push(getGradientColor(i, isMonochrome));
  }
  return scale;
};
