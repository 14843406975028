import React, { ChangeEvent } from 'react';
import styles from './companyLogo.module.scss';
import EditCompanyLogoMenu from './EditCompanyLogoMenu';

interface Props {
  image: string | null;
  companyName?: string;
  onChange?: (data: ChangeEvent<HTMLInputElement>) => void;
  onRemove?: () => void;
  id: string;
}

export const CompanyLogo = ({ image, companyName, onChange, onRemove, id }: Props) => (
  <label className={`${styles.wrapper} ${styles.space}`} htmlFor={id}>
    {image && image !== '' && (
      <div className={styles.big}>
        <img className={styles.image} src={image} alt={companyName} />
      </div>
    )}
    <EditCompanyLogoMenu id={id} onChange={onChange} onRemove={onRemove} />
  </label>
);
