import React from 'react';
import styles from './style.module.scss';

interface Props {
  isActive: boolean;
  onClick: (value: number) => void;
  value: number;
}
export const Dot: React.FC<Props> = ({ isActive, onClick, value }) => (
  <div className={`${styles.dot} ${isActive ? styles.active : ''}`} onClick={() => onClick(value)} />
);
