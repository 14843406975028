import React from 'react';
import styles from './menu.module.scss';
import { Option } from './types';

interface Props<T> {
  options: Option<T>[];
  onChange: (value: Option<T>) => void;
  isOpened: boolean;
}

const MenuContainer = <T,>({ options, onChange, isOpened }: Props<T>) => (
  <div className={`${styles.container}`}>
    <div className={`${styles.menu} ${isOpened ? styles.open : styles.close}`}>
      {options.map((item, index) => (
        <div
          key={item.id}
          onClick={(event) => {
            event.stopPropagation();
            if (!item.disabled) {
              onChange(item);
            }
          }}
          className={`${styles.item} ${styles.num} ${item.disabled ? styles.disabled : ''}`}
          style={{ animationDelay: `${(isOpened ? index + 1 : options.length - (index + 1)) * 60}ms` }}
        >
          {item.icon && <img className={styles.image} src={item.icon} alt="icon" />}
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  </div>
);

export default MenuContainer;
