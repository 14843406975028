import React from 'react';

export const PointerIcon = () => (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00233 1.39368e-05C11.2723 1.39368e-05 15.0023 2.46001 15.0023 7.15001C15.0023 10.27 12.6723 13.72 8.00233 17.5C3.33233 13.72 1.00233 10.27 1.00233 7.15001C0.978217 6.21713 1.14168 5.28891 1.48303 4.42039C1.82438 3.55188 2.33667 2.76075 2.9895 2.09392C3.64233 1.4271 4.42242 0.898164 5.28351 0.538479C6.1446 0.178794 7.06915 -0.00431416 8.00233 1.39368e-05ZM13.0023 7.15001C13.0023 4.05001 10.7023 2.00001 8.00233 2.00001C5.30233 2.00001 3.00233 4.06001 3.00233 7.15001C3.00233 9.28001 4.62233 11.89 8.00233 14.88C11.3823 11.89 13.0023 9.28001 13.0023 7.15001ZM8.00233 8.75001C7.5382 8.75001 7.09308 8.56564 6.76489 8.23745C6.4367 7.90926 6.25233 7.46414 6.25233 7.00001C6.25233 6.53588 6.4367 6.09077 6.76489 5.76258C7.09308 5.43439 7.5382 5.25001 8.00233 5.25001C8.46646 5.25001 8.91158 5.43439 9.23976 5.76258C9.56795 6.09077 9.75233 6.53588 9.75233 7.00001C9.75233 7.46414 9.56795 7.90926 9.23976 8.23745C8.91158 8.56564 8.46646 8.75001 8.00233 8.75001Z"
      fill="white"
    />
    <path
      d="M16 18C16 20.2 12.4 22 8 22C3.6 22 0 20.2 0 18C0 16.7 1.2 15.6 3.1 14.8L3.7 15.7C2.7 16.2 2 16.8 2 17.5C2 18.9 4.7 20 8 20C11.3 20 14 18.9 14 17.5C14 16.8 13.3 16.2 12.2 15.7L12.8 14.8C14.8 15.6 16 16.7 16 18Z"
      fill="white"
    />
  </svg>
);

export default PointerIcon;
