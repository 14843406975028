import React, { ChangeEvent } from 'react';
import styles from './profilePicture.module.scss';
import EditProfilePictureMenu from './EditProfilePictureMenu';

interface Props {
  image: string | null;
  name?: string;
  size?: 'normal' | 'big';
  onChange?: (data: ChangeEvent<HTMLInputElement>) => void;
  onRemove?: () => void;
  id: string;
  width?: number;
  height?: number;
  useUploadImage?: boolean;
  useNameAsTitle?: boolean;
  isDefaultAvatar?: boolean;
}

export const ProfilePicture = ({
  size,
  image,
  name,
  onChange,
  onRemove,
  id,
  width = 102,
  height = 120,
  useUploadImage,
  isDefaultAvatar,
}: Props) => {
  const initials =
    (name || '')
      .match(/(\b\S)?/g)
      ?.join('')
      .toUpperCase() || '';

  const classAccordingToSize = size === 'big' ? styles.big : styles.small;
  return (
    <label className={`${styles.wrapper} ${useUploadImage ? styles.space : ''}`} htmlFor={id}>
      <div className={classAccordingToSize} style={{ width: `${width}px`, height: `${height}px` }}>
        {image ? (
          <img
            className={styles.image}
            src={image}
            alt="Profile"
            style={{ width: `${width}px`, height: `${height}px` }}
          />
        ) : (
          <span className={`${styles.initials} ${classAccordingToSize}`}>{initials.slice(0, 1)}</span>
        )}
      </div>
      {useUploadImage && (
        <EditProfilePictureMenu id={id} onChange={onChange} onRemove={onRemove} isDefaultAvatar={isDefaultAvatar} />
      )}
    </label>
  );
};
