import React from 'react';

interface Props {
  isActive?: boolean;
}

export const CheckIcon = ({ isActive }: Props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3.5" width="18" height="18" rx="9" fill="#00B2F1" />
    <path
      d="M15.825 8.5L17 9.68333L13.6667 13.0167L10.3333 16.35L7 13.025L8.18333 11.85L10.3333 13.9917L15.825 8.5Z"
      fill="#1F303D"
    />
  </svg>
);
