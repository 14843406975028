import { Feature, Polygon } from '@turf/turf';
import * as turf from '@turf/turf';
import { PolygonData } from '../../createLocation/interfaces';
import createPolygon from './createPolygon';

export default (newPolygon: Feature<Polygon>, locations: PolygonData[], maximumNumberOfClusters: number) => {
  const centroids: number[][] = locations
    .map((location) => {
      const existingPolygon = createPolygon(location);
      if (existingPolygon) {
        return turf.centroid(existingPolygon).geometry.coordinates;
      }
      return [];
    })
    .filter((item) => item.length === 2);
  centroids.push(turf.centroid(newPolygon).geometry.coordinates);
  const normalizedCoordinates = centroids.map((coords) => [coords[0].toFixed(1), coords[1].toFixed(1)]);
  const clusters: string[][] = [];
  normalizedCoordinates.forEach((coords) => {
    if (!clusters.find((cluster) => cluster[0] === coords[0] && cluster[1] === coords[1])) {
      clusters.push(coords);
    }
  });
  return clusters.length > maximumNumberOfClusters;
};

export const isMaxNumberOfClustersReachedForLocations = (locations: PolygonData[], maximumNumberOfClusters: number) => {
  const centroids: number[][] = locations
    .map((location) => {
      const existingPolygon = createPolygon(location);
      if (existingPolygon) {
        return turf.centroid(existingPolygon).geometry.coordinates;
      }
      return [];
    })
    .filter((item) => item.length === 2);
  const normalizedCoordinates = centroids.map((coords) => [coords[0].toFixed(1), coords[1].toFixed(1)]);
  const clusters: string[][] = [];
  normalizedCoordinates.forEach((coords) => {
    if (!clusters.find((cluster) => cluster[0] === coords[0] && cluster[1] === coords[1])) {
      clusters.push(coords);
    }
  });
  return clusters.length > maximumNumberOfClusters;
};
