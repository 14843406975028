import React, { Component } from 'react';
import Modal from 'react-modal';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialUiButton from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import styles from './deleteReportItemModal.module.css';
import Button from '../../atoms/primaryButton/button';
import { ReportItem } from '../../../types/report';

interface Props {
  reportItem: ReportItem;
  deleteReportItem: (reportItem: ReportItem) => any;
  reloadReportItemsInReportsPage: () => any;
}

interface State {
  isModalOpen: boolean;
}

export default class DeleteReportItemModal extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    return (
      <>
        {this.renderModal()}
        <Tooltip title="Remove Report Item">
          <DeleteIcon
            className={styles.deleteOptionIcon}
            fontSize="inherit"
            onClick={() => {
              this.openModal();
            }}
          />
        </Tooltip>
      </>
    );
  }

  private renderModal() {
    return (
      <Modal
        isOpen={this.state.isModalOpen}
        onRequestClose={() => this.closeModal()}
        ariaHideApp={false}
        className={styles.modal}
      >
        <div className={styles.modalContent}>
          <h2 className={styles.title}>
            Delete item <b>"{this.props.reportItem.name}"</b> ?
          </h2>
          {this.renderButtons()}
        </div>
      </Modal>
    );
  }

  private renderButtons() {
    return (
      <div className={styles.buttons}>
        <div id={styles.deleteButton} className={styles.deleteButton}>
          <MaterialUiButton
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => {
              this.props.deleteReportItem(this.props.reportItem);
              this.closeModal();
              this.reloadReports();
            }}
          >
            Delete
          </MaterialUiButton>
        </div>
        <div className={styles.cancelButton}>
          <Button
            secondary
            onClick={() => {
              this.closeModal();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  private openModal() {
    this.setState({ isModalOpen: true });
  }

  private closeModal() {
    this.setState({ isModalOpen: false });
  }

  private reloadReports() {
    this.props.reloadReportItemsInReportsPage();
    setTimeout(this.props.reloadReportItemsInReportsPage(), 20);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 80);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 200);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 200);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 300);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 1000);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 1000);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 10000);
  }
}
