import React, { useEffect, useState } from 'react';
import { AnalyticItemInformation, AnalyticsData } from '../../types';
import { Switcher } from './switcher';
import styles from './style.module.scss';
import { formatDataForColumnDisplaying } from './utils';
import { DataLevelEnum, LocationTypeEnum } from '../../../../common/reportItems/types';
import { SingleColumnChart } from '../../../../common/chart/spider/singleColumn';

interface Props {
  data?: AnalyticsData | null;
  dataLevel: DataLevelEnum;
  locationType: LocationTypeEnum;
}

export const MovementSingleColumnChart: React.FC<Props> = ({ data, dataLevel, locationType }) => {
  const [columnsData, setColumnsData] = useState<{
    during: AnalyticItemInformation<'percentage'>[] | null;
  }>({ during: null });
  const [includeOptions, toggleIncludeOptions] = useState({ home: false, work: false });

  useEffect(() => {
    if (data && data.during) {
      const { during } = data;
      const { home, work } = includeOptions;

      if (home && work) {
        const duringColumn = formatDataForColumnDisplaying(during, locationType, dataLevel, true, true);
        setColumnsData({ during: duringColumn });
        return;
      }
      if (home) {
        const duringColumn = formatDataForColumnDisplaying(during, locationType, dataLevel, false, true);
        setColumnsData({ during: duringColumn });
        return;
      }
      if (work) {
        const duringColumn = formatDataForColumnDisplaying(during, locationType, dataLevel, true, false);

        setColumnsData({ during: duringColumn });
        return;
      }

      const duringColumn = formatDataForColumnDisplaying(during, locationType, dataLevel, false, false);

      setColumnsData({ during: duringColumn });
    }
  }, [data, includeOptions, dataLevel, locationType]);

  const onChangeOptions = (type: 'work' | 'home') => () => {
    toggleIncludeOptions((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  const { during } = columnsData;
  const height = (29 + 26) * ((during?.length || 1) <= 4 ? 5 : during?.length || 1);
  return (
    <div>
      {during && (
        <div className={styles.display} style={{ height: `${height}px` }}>
          <div className={styles.singleColumnSwitcher}>
            <Switcher data={includeOptions} onChange={onChangeOptions} />
          </div>
          <SingleColumnChart during={during} />
        </div>
      )}
    </div>
  );
};
