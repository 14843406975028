import { ReportItemType } from '../types';

// The order of report items in the ccc panel are:
//
// Visit trends (footfall)
// Catchment
// Popular day & hour (seasonality)
// Visit duration
// Retention rate
// Movement
// Cross-visitation (loyalty)
// Visit frequency
// Conversion (absorption/attraction)

export const reportItems = [
  { id: ReportItemType.FOOTFALL, name: 'Visit trends', type: ReportItemType.FOOTFALL },
  { id: ReportItemType.CATCHMENT, name: 'Catchment', type: ReportItemType.CATCHMENT },
  { id: ReportItemType.SEASONALITY, name: 'Seasonality', type: ReportItemType.SEASONALITY },
  { id: ReportItemType.DURATION, name: 'Duration', type: ReportItemType.DURATION },
  { id: ReportItemType.RETENTION, name: 'Retention', type: ReportItemType.RETENTION },
  { id: ReportItemType.MOVEMENT, name: 'Movement', type: ReportItemType.MOVEMENT },
  { id: ReportItemType.CROSS_VISITATION, name: 'Cross-visitation', type: ReportItemType.CROSS_VISITATION },
  { id: ReportItemType.ABSORPTION, name: 'Absorption', type: ReportItemType.ABSORPTION },
  { id: ReportItemType.ATTRACTION, name: 'Attraction', type: ReportItemType.ATTRACTION },
];
