import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './createProjectPopup.module.scss';
import { createProject } from '../../../../api/projects/create';
import { Popup } from '../../../../common/popup';
import { TextInput } from '../../../../common/controls/input/textInput';
import { TextArea } from '../../../../common/controls/input/textArea';
import { Button } from '../../../../common/controls/buttons';

interface TProps {
  isOpen: boolean;
  toggleOpen: (val: boolean) => void;
  onSave: (projectId: string, name: string, desc: string) => void;
}

export const CreateProjectPopup = ({ onSave, isOpen, toggleOpen }: TProps) => {
  const { t } = useTranslation();
  const [projectName, setProjectName] = useState<string>('');
  const [projectDesc, setProjectDesc] = useState<string>('');
  const [isError, setError] = useState<boolean>(false);

  const onProjectSave = async () => {
    if (projectName.length > 0) {
      try {
        const res = await createProject(projectName, projectDesc);
        if (res?.length > 0) {
          onSave(res, projectName, projectDesc);
          toggleOpen(false);
        } else {
          setError(true);
        }
      } catch (error) {
        console.error(error);
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setProjectName('');
      setProjectDesc('');
    }
  }, [isOpen]);

  return (
    <Popup isOpen={isOpen} onClose={() => toggleOpen(false)} height={460}>
      <div className={styles.title}>{t('create.projectTitle')}</div>
      <div className={styles.subtitle}>{t('create.projectSubtitle')}</div>
      <div className={styles.formRow}>
        <div className={styles.left}>{t('create.name')}</div>
        <div className={styles.right}>
          <TextInput name="projectName" value={projectName} onChange={setProjectName} />
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.left}>{t('create.description')}</div>
        <div className={styles.right}>
          <TextArea name="projectDescription" value={projectDesc} onChange={setProjectDesc} />
        </div>
      </div>
      {isError && <div className={styles.error}>Unexpected error in project creation process</div>}
      <div className={styles.buttons}>
        <Button onClick={() => toggleOpen(false)}>Cancel</Button>
        <Button layout="primary" onClick={onProjectSave}>
          Save
        </Button>
      </div>
    </Popup>
  );
};
