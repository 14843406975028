import React, { useContext, useMemo } from 'react';
import { MainContext } from '../../types/mainContext';
import moment, { Moment } from 'moment';
import styles from '../Menu/yearMonthLabel.module.scss';
import { TimeFrames } from '../types';

const LABEL_FORMAT = 'MMMM, YYYY';

interface Props {
  timeFrames: TimeFrames;
  onlyString: boolean;
}
export const useTimeFrameFormattedLabel = ({ timeFrames, onlyString }: Partial<Props>) => {
  const { selectedTimeFrames } = useContext(MainContext);

  const data = useMemo(() => {
    if (selectedTimeFrames) return selectedTimeFrames;
    if (timeFrames) return Object.keys(timeFrames).filter((timeFrame) => timeFrames[timeFrame]);
    return [];
  }, [selectedTimeFrames, timeFrames]);

  const isContinuous = (min: Moment, max: Moment, data: string[]): boolean => {
    let isContinuous = true;
    let currentDate = moment(min);
    while (currentDate.isSameOrBefore(max)) {
      if (!data.includes(currentDate.format('YYYY-MM'))) {
        isContinuous = false;
        break;
      }
      currentDate = currentDate.add(1, 'month');
    }
    return isContinuous;
  };

  const label = useMemo(() => {
    if (data === null || data.length === 0) {
      return 'N/A';
    }

    if (data.length === 1) {
      return moment(data[0]).format(LABEL_FORMAT);
    }

    const moments = data.map((item) => moment(item));
    const min = moment.min(moments);
    const max = moment.max(moments);

    if (isContinuous(min, max, data)) {
      return `${min.format(LABEL_FORMAT)} - ${max.format(LABEL_FORMAT)}`;
    }

    const minFormat = min.format(LABEL_FORMAT);
    const maxFormat = max.format(LABEL_FORMAT);

    if (onlyString) {
      return `${minFormat} - / ... / - ${maxFormat}`;
    }

    return (
      <div>
        <span>{min.format(LABEL_FORMAT)} - </span>
        <span className={styles.timePeriodLabel}>/ ... /</span>
        <span> - {max.format(LABEL_FORMAT)}</span>
      </div>
    );
  }, [data]);

  return { label };
};
