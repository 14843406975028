import { ProjectsHierarchy } from '../../common/types/projectsHierarchy';
import { API_URLS } from '../../routes';
import { request } from '../request';

export const getProjectsHierarchy = async () => {
  const accountId = localStorage.getItem('accountId');
  const url = `${API_URLS.PROJECT_HIERARCHY}?accountId=${accountId}`;

  return request<{ projects: ProjectsHierarchy }>({
    url,
    // onDownloadProgress,
    headers: { 'Content-Type': 'application/json' },
    maxContentLength: 2000,
  });
};
