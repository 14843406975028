import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './sidebarMenu.module.scss';
import { MenuContext } from './menuContext';
import { MenuItemsEnum, ProjectsMenuItem, UserProfileMenuItem } from './menuItems';
import { LanguageSelector } from './languageSelector/languageSelector';
import { UserProfilePanel } from '../../userProfile/userProfilePanel';
import { ProjectsPanel } from '../../projects/projectsPanel';
import { MessagesPanel } from '../../messages/messagesPanel';
import { CreateReportPanel } from '../../createReport/createReportPanel';
import { ControlPanel } from '../../common/page/controlPanel';
import { MainContext } from '../../common/types/mainContext';
import { Routes } from '../../routes';
import { useImageUploader } from '../hooks';
import { DefaultUserAvatarIcon } from '../icons/user';
import { uploadAvatar, removeAvatar } from '../../api/user';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';
import { SidebarContext } from './sidebarContext';
import { removeCompanyLogo, uploadCompanyLogo } from '../../api/account';
import { usePreviousValue } from '../../common/hooks/usePrevious';

export const SidebarMenu = () => {
  const { isSelectionDataLoaded, user, createReportStep } = useContext(MainContext);
  const { isOpened: isOpen, onToggleIsOpenedSidebar: onToggle, menuTabs } = useContext(SidebarContext);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);

  const isCreateReport = pathname.toLowerCase().includes('createreport');
  const previousIsCreateReport = usePreviousValue(isCreateReport);

  useEffect(() => {
    if (isCreateReport) {
      setSelectedMenuItem(MenuItemsEnum.CREATE_REPORT);
      onToggle(true);
    } else if (!isCreateReport && previousIsCreateReport) {
      setSelectedMenuItem(MenuItemsEnum.PROJECTS);
      onToggle(true);
    }
  }, [isCreateReport]);

  useEffect(() => {
    if (menuTabs.reports || menuTabs.projects) {
      setSelectedMenuItem(MenuItemsEnum.PROJECTS);
      onToggle(true);
    }
  }, [menuTabs]);

  const onMenuItemClick = (item: string) => {
    const isReports = pathname.includes('reports');
    const isProjects = pathname.includes('projects');
    const isDashboard = pathname === Routes.dashboard;

    if (item !== MenuItemsEnum.PROJECTS) {
      if (isReports || isProjects || isDashboard) {
        localStorage.setItem('prevUrl', `${pathname}${search}`);
      }
      switch (item) {
        case MenuItemsEnum.USER_PROFILE:
          history.push(Routes.userProfile);
          break;
        case MenuItemsEnum.CREATE_REPORT:
          history.push(Routes.createReport);
          break;
        default:
          history.replace('/');
      }
    } else if (!isReports && !isProjects) {
      const prevUrl = localStorage.getItem('prevUrl');
      if (prevUrl) {
        history.replace(prevUrl);
      } else {
        history.replace('/');
      }
    }

    if (item === selectedMenuItem) {
      onToggle();
    } else {
      onToggle(true);
      setSelectedMenuItem(item);
    }
  };

  useEffect(() => {
    trackUserAction(
      `Control panel ${isOpen ? 'opened' : 'closed'}`,
      UserActionsEnum.TOGGLE_CONTROL_PANEL,
      isOpen ? `Menu item clicked: ${selectedMenuItem}` : '',
    );
  }, [isOpen]);

  const onClose = () => {
    onToggle(false);
  };

  const updateAvatar = async (file: File) => {
    if (user) {
      try {
        await uploadAvatar(user.userId, file);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const updateCompanyLogo = async (file: File) => {
    if (user) {
      try {
        await uploadCompanyLogo(user.accountId, file);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const { url, handler, errors, resetAvatar } = useImageUploader(
    updateAvatar,
    user.avatar ? user.avatar : DefaultUserAvatarIcon,
    () => trackUserAction('User profile picture updated', UserActionsEnum.CHANGE_PROFILE_IMAGE),
  );

  const deleteAvatar = () => {
    removeAvatar(user.userId)
      .then(() => resetAvatar())
      .catch((e) => console.log(e));
    trackUserAction('User profile picture deleted', UserActionsEnum.CHANGE_PROFILE_IMAGE);
  };

  const {
    url: companyLogoUrl,
    handler: companyLogoHandler,
    errors: companyLogoErrors,
    resetAvatar: resetCompanyLogoAvatar,
  } = useImageUploader(updateCompanyLogo, user.companyLogo ? user.companyLogo : '', () =>
    trackUserAction('Company logo updated', UserActionsEnum.CHANGE_COMPANY_LOGO),
  );

  const deleteCompanyLogo = () => {
    removeCompanyLogo(user.accountId)
      .then(() => resetCompanyLogoAvatar())
      .catch((e) => console.log(e));
    trackUserAction('Company logo deleted', UserActionsEnum.CHANGE_COMPANY_LOGO);
  };

  const isDefaultIcon = url === DefaultUserAvatarIcon;

  return (
    <MenuContext.Provider value={{ closeMenu: onClose }}>
      <div className={styles.wrapper}>
        <div className={styles.sidebarMenu}>
          <UserProfileMenuItem
            onClick={onMenuItemClick}
            selected={selectedMenuItem === MenuItemsEnum.USER_PROFILE}
            image={isDefaultIcon ? '' : url}
          />
          <ProjectsMenuItem onClick={onMenuItemClick} selected={selectedMenuItem === MenuItemsEnum.PROJECTS} />
          <LanguageSelector />

          {/* TODO: Unmark when we'll work on the create version: */}
          {/* <MessagesMenuItem onClick={onMenuItemClick} selected={selectedMenuItem === MenuItemsEnum.MESSAGES} /> */}
        </div>
        <ControlPanel isOpen={isOpen}>
          {selectedMenuItem === MenuItemsEnum.USER_PROFILE && (
            <UserProfilePanel
              onChangeUserImage={handler}
              onRemoveUserImage={deleteAvatar}
              image={url}
              isDefaultAvatar={isDefaultIcon}
              error={errors.format || errors.maxSize || companyLogoErrors.format || companyLogoErrors.maxSize}
              onChangeCompanyLogo={companyLogoHandler}
              onRemoveCompanyLogo={deleteCompanyLogo}
              companyLogoImage={companyLogoUrl}
            />
          )}
          {selectedMenuItem === MenuItemsEnum.PROJECTS && isSelectionDataLoaded && <ProjectsPanel />}
          {selectedMenuItem === MenuItemsEnum.MESSAGES && <MessagesPanel />}
          {selectedMenuItem === MenuItemsEnum.CREATE_REPORT && <CreateReportPanel step={createReportStep} />}
        </ControlPanel>
      </div>
    </MenuContext.Provider>
  );
};
