import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { trackSuccessfulLogout } from '../../mixpanel';
import { HorizontalLine, LinkWithIcon, PanelTitle } from '../common/page/controlPanel';
import { UserProfileIcon } from '../common/icons/controlPanel/userProfile';
import { ChangePasswordIcon } from '../common/icons/controlPanel/changePassword';
import { SignOutIcon } from '../common/icons/controlPanel/signOut';
import styles from './userProfilePanel.module.scss';
import { ProfilePicture } from '../common/page/profilePicture/profilePicture';
import { CompanyLogo } from '../common/page/companyLogo/companyLogo';

const logout = function logout(userName: string): void {
  trackSuccessfulLogout(userName);
  localStorage.clear();
  window.location.reload();
};

interface Props {
  image: string | null;
  onChangeUserImage: (data: ChangeEvent<HTMLInputElement>) => void;
  onRemoveUserImage: () => void;
  companyLogoImage: string | null;
  onChangeCompanyLogo: (data: ChangeEvent<HTMLInputElement>) => void;
  onRemoveCompanyLogo: () => void;
  isDefaultAvatar?: boolean;
  error?: string;
}

export const UserProfilePanel = ({
  onChangeUserImage,
  onRemoveUserImage,
  image,
  error,
  isDefaultAvatar,
  companyLogoImage,
  onChangeCompanyLogo,
  onRemoveCompanyLogo,
}: Props) => {
  const userName = localStorage.getItem('userName') || '';
  const roleTitle = localStorage.getItem('roleTitle') || '';
  const companyName = localStorage.getItem('companyName') || '';

  return (
    <>
      <div className={styles.container}>
        <div>
          <PanelTitle title="My profile" backButton />
          <div className={styles.titles}>
            <div className={styles.profilePicture}>
              <ProfilePicture
                name={userName}
                size="big"
                id="user-picture"
                image={image}
                onChange={onChangeUserImage}
                onRemove={onRemoveUserImage}
                useUploadImage
                isDefaultAvatar={isDefaultAvatar}
              />
            </div>
            {error && <div className={styles.error}>{error}</div>}
            <div className={styles.userName}>{userName}</div>
            <div className={styles.titleRole}>{roleTitle}</div>
          </div>
          <LinkWithIcon title="My profile" icon={<UserProfileIcon />} to="/userProfile" />
          <LinkWithIcon title="Change password" icon={<ChangePasswordIcon />} to="/changePassword" />
          <HorizontalLine />
          <Link to="/" title="Log Out" onClick={() => logout(userName)}>
            <LinkWithIcon title="Sign out" icon={<SignOutIcon />} to="/" />
          </Link>
        </div>
        <div className={styles.company}>
          {companyName && (
            <div
              className={styles.userName}
              style={{ marginBottom: `${companyLogoImage == null || companyLogoImage === '' ? '25px' : '10px'}` }}
            >
              {companyName}
            </div>
          )}
          <CompanyLogo
            image={companyLogoImage}
            id="company-logo"
            companyName={companyName}
            onChange={onChangeCompanyLogo}
            onRemove={onRemoveCompanyLogo}
          />
        </div>
      </div>
    </>
  );
};
