import React, { useContext, useEffect, useState } from 'react';
import { checkIsUserAuthenticated } from '../../auth/utils';
import { SignInPage } from '../signIn';
import { MainContext } from '../../common/types/mainContext';
import { LoadingApplicationPage } from '../loadingApplication';
import { getProjectsHierarchy } from '../../api';
import { getCenterCoordinate } from '../../common/maps/utils/getCenterCoordinate';
import { decodeUserCredentials } from '../../auth/signIn/utils';
import { PagesWithSidebar } from '../withSidebar';
import { useLoadProgress } from '../../common/hooks';
import { getPointsColorScheme } from '../../common/maps/utils/getPointsColorScheme';
import { getAvatar } from '../../api/user';
import { User } from '../../common/types/User';
import { INTERCOM_APP_ID, runIntercomBot } from '../../common/utils/intercom';
import { getCompanyLogo } from '../../api/account';

interface Props {}
export const GeneralPage: React.FC<Props> = () => {
  const { user, projectsHierarchy, setProjectsHierarchy, saveUser, changeApplicationLoadProgress } =
    useContext(MainContext);
  const [isAuthed, setIsAuthed] = useState(checkIsUserAuthenticated());
  const [isProjectsLoaded, toggleLoadedProjects] = useState(checkIsUserAuthenticated());
  const { isCompleted } = useLoadProgress(changeApplicationLoadProgress);
  const [projectHierarchyFetched, setProjectsHierarchyFetched] = useState<boolean>(false);

  const colors = getPointsColorScheme();

  const fetchProjectHierarchy = async () => {
    try {
      toggleLoadedProjects(false);
      const { projects } = await getProjectsHierarchy();
      if (projects) {
        projects.forEach((project) => {
          project.locations.forEach((location, index) => {
            location.color = colors[index % colors.length];
            if (location.geometry && (!location.latitude || !location.longitude)) {
              try {
                const coords = getCenterCoordinate(location.geometry);
                location.longitude = coords[0];
                location.latitude = coords[1];
              } catch (error) {
                console.error(error, { location });
              }
            }
          });
        });
        setProjectsHierarchy(projects);
        setProjectsHierarchyFetched(true);
      }
    } catch (error) {
      console.log("Can't get projects");
    }
    toggleLoadedProjects(true);
  };

  const fetchUserAvatar = async (user: User) => {
    try {
      const avatar = (await getAvatar(user.userId)) as string;

      if (user) saveUser({ ...user, avatar: avatar || '' });
    } catch (error) {
      console.error(error);
    }
  };
  const fetchCompanyLogo = async (user: User) => {
    try {
      const companyLogo = (await getCompanyLogo(user.accountId)) as string;
      if (user) saveUser({ ...user, companyLogo: companyLogo || '' });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (user.userId && projectsHierarchy.length === 0) {
      fetchProjectHierarchy();
    }
  }, [user]);

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      const { user } = decodeUserCredentials(token);
      fetchUserAvatar(user);
      fetchCompanyLogo(user);
      saveUser(user);
      runIntercomBot();
      // @ts-ignore
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        api_base: 'https://api-iam.intercom.io',
        name: user.userName || '',
        email: user.userEmail || '',
        user_id: user.userId || '',
        account_id: user.accountId,
        vertical_padding: 90,
      });
    }
  }, []);

  if (!isAuthed && !user.userId) return <SignInPage />;

  if (!(projectHierarchyFetched && isCompleted)) return <LoadingApplicationPage />;

  return <PagesWithSidebar />;
};
