import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tooltip } from '../../../common/tooltip';
import { TooltipPositionCoordinates } from '../../../common/reportItems/hooks';
import {
  Absorption,
  Attraction,
  Catchment,
  LocationInfo,
  Movement,
  ReportItemInfo,
  ReportItemSetting,
} from '../../../common/types/projectsHierarchy';
import styles from './style.module.scss';
import { ReportItemType } from '../../../common/reportItems/types';
import { separateThousandsByComma } from '../../../common/utils';
import { MainContext } from '../../../common/types/mainContext';
import { generatePDF } from './generatePDF';

const createdFormat = 'D MMMM, YYYY';
const dateRangeFormat = 'MMM, YYYY';
export type ReportMetadata = {
  name: string;
  created: string | undefined;
  createdBy: string | undefined;
  dateRange: string | undefined;
  residentsExclusion: string | undefined;
  totalSignalsAnalyzed: string | undefined;
  footfall: boolean;
  duration: boolean;
  seasonality: boolean;
  retention: boolean;
  crossVisitation: boolean;
  catchment: {
    level: string;
    maxRadius: string;
  } | null;
  movement: {
    radius: string | undefined;
    timeframe: string | undefined;
  } | null;
  attraction: {
    pois: string[] | null;
    aois: string[] | null;
    linkAois: string | null;
    linkPois: string | null;
  } | null;
  absorption: {
    pois: string[] | null;
    aois: string[] | null;
    linkPois: string | null;
    radius: string | null;
  } | null;
};
const catchmentLevels = new Map<number, string>();
catchmentLevels.set(1, 'High (by neighborhood, 50km radius)');
catchmentLevels.set(2, 'Mid-high (by large city/municipality, 70km radius)');
catchmentLevels.set(3, 'Medium (by municipality, 100km radius)');
catchmentLevels.set(4, 'Mid-low (by region/district, 300km radius)');
catchmentLevels.set(5, 'Low (by state/province, 1000km radius)');

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  position: TooltipPositionCoordinates;
}
const ReportTooltip: React.FC<Props> = ({ onClose, position, isOpen }) => {
  const { t } = useTranslation();
  const {
    selection: { selectedProject, selectedReport: report },
    user: { companyLogo },
  } = useContext(MainContext);

  // @ts-ignore

  const metadata: ReportMetadata = useMemo(() => {
    const reportLocations: LocationInfo[] =
      selectedProject?.locations.filter((location) => report?.location_ids?.some((id) => id === location.id)) || [];

    const catchment =
      (report?.report_items?.find(({ type }) => ReportItemType.CATCHMENT === type) as ReportItemInfo<Catchment>) ||
      null;
    const movement =
      (report?.report_items.find(({ type }) => ReportItemType.MOVEMENT === type) as ReportItemInfo<Movement>) || null;

    const getLocation = (locationName: 'aois' | 'pois', settings: ReportItemSetting[]): string[] => {
      const items: string[] = settings.find(({ name }) => name === locationName)?.value.split(';') || [];
      if (reportLocations) {
        // @ts-ignore
        return (
          items.map((id) => reportLocations.find((location) => location.id === id)?.name).filter((name) => name) || []
        );
      }
      return [];
    };

    const attraction = (() => {
      const item =
        (report?.report_items?.find(({ type }) => ReportItemType.ATTRACTION === type) as ReportItemInfo<Attraction>) ||
        null;

      if (item) {
        const { settings } = item;
        if (settings && settings.length > 0) {
          return {
            pois: getLocation('pois', settings),
            aois: getLocation('aois', settings),
            linkPois: settings.find(({ name }) => name === 'Link POIs')?.value || null,
            linkAois: settings.find(({ name }) => name === 'Link AOIs')?.value || null,
          };
        }
        return null;
      }
      return null;
    })();

    const absorption = (() => {
      const item =
        (report?.report_items?.find(({ type }) => ReportItemType.ABSORPTION === type) as ReportItemInfo<Absorption>) ||
        null;

      if (item) {
        const { settings } = item;
        if (settings && settings.length > 0) {
          return {
            pois: getLocation('pois', settings),
            aois: getLocation('aois', settings),
            linkPois: settings.find(({ name }) => name === 'Link POIs')?.value || null,
            radius: 150,
            // settings.find(({ name, value }) => name === 'radius' && value != null && value.trim().length > 0) || null,
          };
        }
        return null;
      }
      return null;
    })();

    return {
      name: report?.name,
      created: moment(report?.created_date).format(createdFormat),
      createdBy: report?.creator,
      dateRange: `${moment(report?.start_date).format(dateRangeFormat)} - ${moment(report?.end_date).format(
        dateRangeFormat,
      )}`,
      residentsExclusion: t('reportItem.off'),
      totalSignalsAnalyzed: separateThousandsByComma(report?.numberOfSignals),
      footfall: report?.report_items.some((item) => item.type === ReportItemType.FOOTFALL) || false,
      duration: report?.report_items.some((item) => item.type === ReportItemType.DURATION) || false,
      seasonality: report?.report_items.some((item) => item.type === ReportItemType.SEASONALITY) || false,
      crossVisitation: report?.report_items.some((item) => item.type === ReportItemType.CROSS_VISITATION) || false,
      retention: report?.report_items.some((item) => item.type === ReportItemType.RETENTION) || false,
      catchment: catchment
        ? {
            level: catchment?.data?.catchmentLevel ? catchmentLevels.get(catchment.data.catchmentLevel) : '-',
            maxRadius: catchment?.data?.maxRadius ? `${catchment.data.maxRadius} ${t('reportItem.km')}` : '-',
          }
        : null,
      movement: movement
        ? {
            radius: movement?.data?.maxDistance
              ? `${movement?.data?.maxDistance.toFixed(0)} ${t('reportItem.meters')}`
              : '-',
            timeframe: movement?.data?.timeFrame ? `${movement?.data?.timeFrame} ${t('reportItem.hours')}` : '-',
          }
        : null,
      attraction: attraction || null,
      absorption: absorption || null,
    };
  }, [report]);

  const exportPDF = () => {
    generatePDF(metadata, companyLogo, t);
  };

  return (
    <Tooltip
      onClose={onClose}
      initialPosition={position}
      isOpen={isOpen}
      height={400}
      title={t('report.tooltip.title')}
      onDownload={exportPDF}
    >
      <table className={styles.reportTable}>
        <tbody>
          <tr>
            <th>{t('report.tooltip.reportName')}:</th>
            <td>{metadata.name}</td>
          </tr>
          <tr>
            <th>{t('report.tooltip.dateCreated')}:</th>
            <td>{metadata.created}</td>
          </tr>
          <tr>
            <th>{t('report.tooltip.createdBy')}:</th>
            <td>{metadata.createdBy}</td>
          </tr>
          <tr>
            <th>{t('report.tooltip.dateRange')}:</th>
            <td>{metadata.dateRange}</td>
          </tr>
          {/* <tr> */}
          {/*   <th>{t('report.tooltip.residentsExclusion')}:</th> */}
          {/*   <td>{metadata.residentsExclusion}</td> */}
          {/* </tr> */}
          <tr>
            <th>{t('report.tooltip.totalSignalsAnalyzed')}:</th>
            <td>{metadata.totalSignalsAnalyzed}</td>
          </tr>
        </tbody>
      </table>
      <h3>{t('report.tooltip.reportParameters')}</h3>
      {metadata.footfall && (
        <div className={styles.parametersBlock}>
          <h4>{t('reportItem.enum.FOOTFALL')}:</h4>
          <ul>
            <li>{t('report.tooltip.paragraph1')}</li>
          </ul>
        </div>
      )}
      {metadata.catchment && (
        <div className={styles.parametersBlock}>
          <h4>{t('reportItem.enum.CATCHMENT')}:</h4>
          <table>
            <tbody>
              <tr>
                <th>- {t('report.tooltip.catchmentLevel')}:</th>
                <td>{metadata.catchment.level}</td>
              </tr>
              <tr>
                <th>- {t('report.tooltip.maxRadius')}:</th>
                <td>{metadata.catchment.maxRadius}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {metadata.duration && (
        <div className={styles.parametersBlock}>
          <h4>{t('reportItem.enum.DURATION')}:</h4>
          <ul>
            <li>{t('report.tooltip.durationDesc')}</li>
          </ul>
        </div>
      )}
      {metadata.seasonality && (
        <div className={styles.parametersBlock}>
          <h4>{t('reportItem.enum.SEASONALITY')}:</h4>
          <ul>
            <li>{t('report.tooltip.paragraph1')}</li>
          </ul>
        </div>
      )}
      {metadata.retention && (
        <div className={styles.parametersBlock}>
          <h4>{t('reportItem.enum.RETENTION')}:</h4>
          <ul>
            <li>{t('report.tooltip.paragraph1')}</li>
          </ul>
        </div>
      )}
      {metadata.crossVisitation && (
        <div className={styles.parametersBlock}>
          <h4>{t('reportItem.enum.LOYALTY')}:</h4>
          <ul>
            <li>{t('report.tooltip.paragraph1')}</li>
          </ul>
        </div>
      )}
      {metadata.movement && (
        <div className={styles.parametersBlock}>
          <h4>{t('reportItem.enum.MOVEMENT')}:</h4>
          <table>
            <tbody>
              <tr>
                <th>- {t('report.tooltip.radius')}:</th>
                <td>{metadata.movement?.radius}</td>
              </tr>
              <tr>
                <th>- {t('report.tooltip.timeFrame')}:</th>
                <td>{metadata.movement?.timeframe}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {metadata.attraction && (
        <div className={styles.parametersBlock}>
          <h4>{t('reportItem.enum.ATTRACTION')}:</h4>
          <table>
            <tbody>
              <tr>
                <th>- {t('report.tooltip.pois')}:</th>
                <td>
                  {' '}
                  {metadata.attraction.pois && metadata.attraction.pois.length > 0 ? (
                    <ul>
                      {metadata.attraction.pois.map((poi) => (
                        <li key={poi}>{poi}</li>
                      ))}
                    </ul>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
              <tr>
                <th>- {t('report.tooltip.linkPois')}:</th>
                <td className={styles.capitalize}>
                  {metadata.attraction.linkPois ? metadata.attraction.linkPois : '-'}
                </td>
              </tr>
              <tr>
                <th>- {t('report.tooltip.aois')}:</th>
                <td>
                  {metadata.attraction.aois && metadata.attraction.aois.length > 0 ? (
                    <ul>
                      {metadata.attraction.aois.map((aoi) => (
                        <li key={aoi}>{aoi}</li>
                      ))}
                    </ul>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
              <tr>
                <th>- {t('report.tooltip.linkAOIs')}:</th>
                <td className={styles.capitalize}>
                  {metadata.attraction.linkAois ? metadata.attraction.linkAois : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {metadata.absorption && (
        <div className={styles.parametersBlock}>
          <h4>{t('reportItem.enum.ABSORPTION')}:</h4>
          <table>
            <tbody>
              <tr>
                <th>- {t('report.tooltip.pois')}:</th>
                <td>
                  {metadata.absorption.pois && metadata.absorption.pois.length > 0 ? (
                    <ul>
                      {metadata.absorption.pois.map((poi) => (
                        <li key={poi}>{poi}</li>
                      ))}
                    </ul>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
              <tr>
                <th>- {t('report.tooltip.linkPOIs')}:</th>
                <td className={styles.capitalize}>
                  {metadata.absorption.linkPois ? metadata.absorption.linkPois : '-'}
                </td>
              </tr>
              {metadata.absorption.radius && (
                <tr>
                  <th>- {t('report.tooltip.areaType')}:</th>
                  <td>{t('report.tooltip.radius')}</td>
                </tr>
              )}
              {metadata.absorption.radius && (
                <tr>
                  <th>- {t('report.tooltip.radius')}:</th>
                  <td>{`${metadata.absorption.radius} ${t('reportItem.meters')}`}</td>
                </tr>
              )}
              {!metadata.absorption.radius && (
                <tr>
                  <th>- {t('report.tooltip.aois')}:</th>
                  <td>
                    {metadata.absorption.aois && metadata.absorption.aois.length > 0 ? (
                      <ul>
                        {metadata.absorption.aois.map((aoi) => (
                          <li key={aoi}>{aoi}</li>
                        ))}
                      </ul>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </Tooltip>
  );
};

export default ReportTooltip;
