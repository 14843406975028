import React from 'react';
import { IntroPageTemplate } from '../../common/templates';
import { ResetPassword } from '../../auth';
import { RedirectAuthedUser } from '../../processes/RedirectAuthedUser';

export const ForgotPasswordPage = () => (
  <RedirectAuthedUser>
    <IntroPageTemplate>
      <ResetPassword />
    </IntroPageTemplate>
  </RedirectAuthedUser>
);
