import { Position } from '@turf/helpers/dist/js/lib/geojson';

export const getPolygonsByPoints = <T extends { geo: GeoJSON.Feature<GeoJSON.Geometry> }>(polygons: T[]) => {
  let result: GeoJSON.Feature<GeoJSON.Geometry>[] = [];

  polygons.forEach((area) => {
    const { geometry } = area.geo;
    if ('coordinates' in geometry) {
      const { coordinates } = geometry;
      if (Array.isArray(coordinates)) {
        if (geometry.type === 'Polygon') {
          const newCoordinates = (coordinates[0] as Position[]).map((point) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [point[0], point[1]],
            },
          })) as GeoJSON.Feature<GeoJSON.Geometry>[];
          result = [...result, ...newCoordinates];
        } else if (geometry.type === 'MultiPolygon') {
          (coordinates as Position[][][]).forEach((position) =>
            position.forEach((subPosition) => {
              subPosition.forEach((point) => {
                const data = {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [point[0], point[1]],
                  },
                } as GeoJSON.Feature<GeoJSON.Geometry>;
                result.push(data);
              });
            }),
          );
        }
      }
    }
  });

  return result;
};
