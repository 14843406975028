export function displayNameForReportItemType(reportItemType: string) {
  return capitalizeFirstLetter(
    reportItemType
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toLowerCase())
      .toLowerCase(),
  );
}

function capitalizeFirstLetter(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}
