import { CatchmentMetricTypeEnum, CatchmentSocioDemoMetricEnum } from '../../../../common/reportItems/types';

export const getValueFieldName = (metric: CatchmentMetricTypeEnum) => {
  switch (metric) {
    case CatchmentMetricTypeEnum.DESTINATION:
      return 'destinationValue';
    case CatchmentMetricTypeEnum.SCORE:
      return 'scoreValue';
    case CatchmentMetricTypeEnum.SOURCE:
      return 'sourceValue';
    default:
      return 'sourceValue';
  }
};

export const getSocioDemoFieldName = (metric: CatchmentSocioDemoMetricEnum) => {
  switch (metric) {
    case CatchmentSocioDemoMetricEnum.INHABITANTS:
      return 'inhabitants';
    case CatchmentSocioDemoMetricEnum.HOUSEHOLDS:
      return 'households';
    case CatchmentSocioDemoMetricEnum.HOUSEHOLD_INCOME:
      return 'average_income';
    case CatchmentSocioDemoMetricEnum.RETAIL_SPENDING:
      return 'purchasing_power';
    default:
      return 'inhabitants';
  }
};

export const getTopLocationsFieldName = (metric: CatchmentMetricTypeEnum) => {
  switch (metric) {
    case CatchmentMetricTypeEnum.DESTINATION:
      return 'destinationTopLocations';
    case CatchmentMetricTypeEnum.SCORE:
      return 'scoreTopLocations';
    case CatchmentMetricTypeEnum.SOURCE:
      return 'sourceTopLocations';
    default:
      return 'sourceTopLocations';
  }
};
