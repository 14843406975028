import { WebMercatorViewport } from 'react-map-gl';
import { FitBoundsOptions } from '@math.gl/web-mercator/src/fit-bounds';

export const getFitBounds = (
  width: number,
  height: number,
  minLng: number,
  minLat: number,
  maxLng: number,
  maxLat: number,
  options?: Omit<FitBoundsOptions, 'width' | 'height' | 'bounds'>,
) => {
  const vp = new WebMercatorViewport({
    width,
    height,
  });

  const { longitude, latitude, zoom } = vp.fitBounds(
    [
      [minLng, minLat],
      [maxLng, maxLat],
    ],
    options,
  );

  return { longitude, latitude, zoom };
};
