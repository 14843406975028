import React from 'react';
import styles from './style.module.scss';
import { SmallLogoIcon } from '../../../icons/chart';

interface Props {
  label: string;
  value: string;
  id: string;
  onClick: (id: string) => void;
}

export const Item: React.FC<Props> = ({ label, id, value, onClick }) => (
  <div className={styles.item} onClick={() => onClick(id)}>
    <SmallLogoIcon opacity={'1'} />
    <p>{label}</p>
  </div>
);
