import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BaseModal } from '../baseModal';
import { Form } from './form';
import { Credentials } from './interfaces';
import { login } from '../../api';
import { MainContext } from '../../common/types/mainContext';
import { Routes } from '../../routes';
import { Information } from './information';
import { processUserDataAfterLogin } from '../utils';

export const SignIn = () => {
  const history = useHistory();
  const location = useLocation();
  const { saveUser } = useContext(MainContext);
  const [error, setError] = useState('');

  const onSubmit = async (data: Credentials) => {
    const { pathname, search } = location;
    const savedURL = `${pathname}${search}`;
    try {
      const { token } = await login(data);
      const { user } = processUserDataAfterLogin(token);

      history.replace(savedURL || Routes.dashboard);
      saveUser(user);
      setError('');
    } catch (error) {
      const { data } = error.response;
      setError(data.reason);
    }
  };

  return (
    <BaseModal subtitle="Enter email and password to login." title="Login" information={<Information />}>
      <Form onSubmit={onSubmit} error={error} />
    </BaseModal>
  );
};
