import React, { useMemo, useRef } from 'react';
import { Button } from '../controls/buttons';
import styles from './style.module.scss';
import { LocationInfo } from '../types/projectsHierarchy';
import { useClickOutside, useWindowSize } from '../hooks';

interface Props {
  locations: LocationInfo[];
  primaryLocationId: string | null;
  onClose: VoidFunction;
  onSelectLocation: (id: string) => void;
}

const BREAK_POINT_HEIGHT = 795;
const FULL_SCREEN_CONTENT_HEIGHT = 281;
const FULL_SCREEN_WRAPPER_HEIGHT = 386;
export const LocationGallery: React.FC<Props> = ({ locations, primaryLocationId, onClose, onSelectLocation }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { height } = useWindowSize();

  const wrapperHeight = useMemo(() => {
    if (height) {
      if (height <= BREAK_POINT_HEIGHT) {
        return height - 450;
      }
      return FULL_SCREEN_WRAPPER_HEIGHT;
    }
    return FULL_SCREEN_WRAPPER_HEIGHT;
  }, [height]);

  const contentHeight = useMemo(() => {
    if (height) {
      if (height <= BREAK_POINT_HEIGHT) {
        return height - 560;
      }
      return FULL_SCREEN_CONTENT_HEIGHT;
    }
    return FULL_SCREEN_CONTENT_HEIGHT;
  }, [height]);

  useClickOutside(componentRef, onClose, true);
  return (
    <div className={styles.wrapper} ref={componentRef} style={{ height: `${wrapperHeight}px` }}>
      <h2 className={styles.title}>Location gallery</h2>
      <div
        className={`custom-scrollbar ${styles.content}`}
        style={{ gridTemplateRows: `repeat(${Math.ceil(locations.length / 4)}, 90px)`, height: `${contentHeight}px` }}
      >
        {locations.map(({ image, name, id }) => (
          <div
            className={`${styles.location} ${id === primaryLocationId ? styles.active : ''}`}
            onClick={() => onSelectLocation(id)}
            title={name}
          >
            <img src={image} alt="" />
            <p>{name}</p>
          </div>
        ))}
      </div>
      <div className={styles.actions}>
        <Button onClick={onClose}>Close</Button>
      </div>
    </div>
  );
};
