import React, { useContext, useEffect } from 'react';
import { trackUserAction, UserActionsEnum } from '../../mixpanel';
import ContentContainer from '../common/page/pageContent/contentContainer';
import Table from '../common/page/table/table';
import TableRow from '../common/page/table/tableRow/tableRow';
import { SidebarContext } from '../common/sidebarMenu/sidebarContext';
import { MainContext } from '../common/types/mainContext';

function getValue(key: string): string {
  return localStorage.getItem(key) || 'N/A';
}

const UserProfilePage = () => {
  const { toggleIsSelectionDataLoaded } = useContext(MainContext);
  const { isOpened } = useContext(SidebarContext);
  const email = getValue('userEmail');

  useEffect(() => {
    toggleIsSelectionDataLoaded(true);
    trackUserAction('User profile shown', UserActionsEnum.USER_INFO);
  }, []);

  return (
    <>
      <ContentContainer isOpened={isOpened}>
        <Table title="My profile" subtitle="Basic info you use on PlaceSence services">
          <TableRow name="User name" value={getValue('userName')} />
          <TableRow name="Company" value={getValue('companyName')} />
          <TableRow name="Role" value={getValue('roleTitle')} />
          <TableRow name="Email" value={email ? <a href={`mailto:${email}`}>{email}</a> : 'N/A'} />
          <TableRow name="Phone" value={getValue('phone')} />
        </Table>
      </ContentContainer>
    </>
  );
};
export default UserProfilePage;
