import React from 'react';

export const DurationIcon: React.FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.82671 4.17301C9.04671 3.39301 8.02671 2.99967 7.00004 2.99967V6.99967L4.17337 9.82634C5.73337 11.3863 8.26671 11.3863 9.83337 9.82634C11.3934 8.26634 11.3934 5.73301 9.82671 4.17301ZM7.00004 0.333008C3.32004 0.333008 0.333374 3.31967 0.333374 6.99967C0.333374 10.6797 3.32004 13.6663 7.00004 13.6663C10.68 13.6663 13.6667 10.6797 13.6667 6.99967C13.6667 3.31967 10.68 0.333008 7.00004 0.333008ZM7.00004 12.333C4.05337 12.333 1.66671 9.94634 1.66671 6.99967C1.66671 4.05301 4.05337 1.66634 7.00004 1.66634C9.94671 1.66634 12.3334 4.05301 12.3334 6.99967C12.3334 9.94634 9.94671 12.333 7.00004 12.333Z"
      fill="white"
    />
  </svg>
);
