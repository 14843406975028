import React from 'react';
import { LocationsData } from '../../types';
import ManageLocations from '../../../common/manageLocations';
import { IReportCategory } from '../../../../types/report';

interface TProps {
  data: LocationsData;
  setData: (data: LocationsData) => void;
  navigate: (step: number) => void;
  projectId: string;
  onCancel: VoidFunction;
  reportCategory: IReportCategory;
}

export const StepTwo = ({ projectId, navigate, setData, data, onCancel, reportCategory }: TProps) => (
    <ManageLocations
        data={data}
        setData={setData}
        projectId={projectId}
        navigate={navigate}
        onCancel={onCancel}
        reportCategory={reportCategory}
    />
);
