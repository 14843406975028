import React, { useContext, useMemo } from 'react';
import { getGroupCondition } from '../utils';
import { PoiPolygons } from '../PoiPolygons';
import { MainContext } from '../../../../common/types/mainContext';
import { ExportMapContext } from '../../../../common/export/exportSettings/settings/mapContainer';
import { ReportItemType } from '../../../../common/reportItems/types';
import { AoiPolygons } from '../AoiPolygons';

export const usePoiPolygons = () => {
  const { selection, attractionSourceLocation, absorptionSourceLocation, selectedLocations, exportSelection } =
    useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const selectedReportItem = isExportMap ? exportSelection.selectedReportItem : selection.selectedReportItem;

  const polygons = useMemo(() => {
    const reportItem = selectedReportItem;
    const attractionReportItem = selection.selectedReport?.report_items.find(
      ({ type }) => type === ReportItemType.ATTRACTION,
    );

    const poiPolygons = reportItem?.visualization?.poiPolygons;
    const aoiPolygons = attractionReportItem?.visualization?.aoiPolygons;

    const groupCondition = getGroupCondition(
      reportItem?.type,
      absorptionSourceLocation?.id,
      attractionSourceLocation?.id,
    );

    const polygons = poiPolygons?.map((poi) => {
      const aoiPolygon = aoiPolygons?.find((aoi) => aoi.location_id === poi.location_id);
      if (aoiPolygon) return { ...aoiPolygon, isAOI: true };
      return poi;
    });

    return polygons?.filter(
      (polygon) =>
        selectedLocations.some((location) => location.id === polygon.location_id) &&
        (!groupCondition || polygon.group_id === groupCondition),
    );
  }, [selectedReportItem, attractionSourceLocation, absorptionSourceLocation, selectedLocations]);

  const renderPolygons = useMemo(() => {
    const aois = polygons?.filter((polygon) => 'isAOI' in polygon && polygon.isAOI);
    const pois = polygons?.filter((polygon) => ('isAOI' in polygon ? !polygon.isAOI : true));
    return (
      <>
        <PoiPolygons polygons={pois} />
        <AoiPolygons polygons={aois} />
      </>
    );
  }, [polygons]);

  return { polygons, renderPolygons };
};
