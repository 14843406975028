import React from 'react';
import styles from './button.module.css';

interface TProps {
  text: string;
  disabled?: boolean;
}

export const SubmitButton = (props: TProps) => (
  <button className={styles.submitButton} type="submit" disabled={!!props.disabled}>
    {props.text}
  </button>
);
