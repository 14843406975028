import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportItemInfo, Seasonality } from '../../../common/types/projectsHierarchy';
import { MainContext } from '../../../common/types/mainContext';
import ReportItemCard from '../../../common/reportItems/reportItemCard';
import { ReportItemType } from '../../../common/reportItems/types';
import { getSeasonalityData } from '../services';
import SeasonalityCardMostPopularDay from './seasonalityCardMostPopularDay';
import SeasonalityCardChart from './SeasonalityCardChart';
import { mostPopularHourMock } from '../mockData';
import { SeasonalityMainTooltip } from './tooltips';
import { useToggle } from '../../../common/hooks';
import { dashboardModel } from '../../../dasboardLoader';
import { useTooltipStartPosition } from '../../../common/reportItems/hooks';
import { exportSettingsModel } from '../../../common/export';
import { WeekDays } from '../../types';
import { reportItemDetailsModel } from '../../model';

const SeasonalityCard = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetched, setFetched] = useState<boolean>(true);
  const [isTooltipOpen, toggleTooltipOpen] = useToggle(false);
  const { tooltipIconRef, position } = useTooltipStartPosition();

  const {
    selection: { selectedReport, selectedProject, selectedReportItem },
    updateReportItemOfSelectedReport,
    selectedTimeFrames,
    selectedLocationsIds,
    primaryLocationId,
  } = useContext(MainContext);

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.SEASONALITY,
  ) as ReportItemInfo<Seasonality>;

  const noData = useMemo(
    () => (fetched && (!reportItem || !reportItem.data)) || selectedLocationsIds.length <= 0,
    [reportItem, selectedTimeFrames, fetched, isLoading, selectedLocationsIds],
  );

  const hourlyByWeekData = reportItem?.data?.hourlyByWeek.find((location) => location.locationId === primaryLocationId);
  const dailyData = reportItem?.data?.daily.find((location) => location.locationId === primaryLocationId);

  const getPopularHourString = () => {
    if (hourlyByWeekData) {
      const hour = hourlyByWeekData.mostPopularHour.hourOfDay;
      const from = hour > 12 ? hour - 12 : hour;
      const to = hour >= 12 ? hour - 12 + 1 : hour + 1;
      const period = hour >= 12 ? 'pm' : 'am';
      return `${from}-${to} ${period}`;
    }
    return '';
  };

  const getPopularDayString = () => {
    if (dailyData) {
      return WeekDays[dailyData.mostPopularDay.dayOfWeekNumber - 1];
    }
    return '';
  };

  useEffect(() => {
    if (hourlyByWeekData && dailyData) {
      exportSettingsModel.updateReportItemsInformation({
        seasonality: { popularHour: getPopularHourString(), popularDay: getPopularDayString() },
      });
      reportItemDetailsModel.reportItemsBriefDataChanged({
        [ReportItemType.SEASONALITY]: {
          popularDay: getPopularDayString(),
          popularHour: getPopularHourString(),
          chartData: {
            dayOfWeek: dailyData.mostPopularDay.dayOfWeekNumber,
            mostPopularHour: hourlyByWeekData.mostPopularHour,
            average: hourlyByWeekData.average,
          },
        },
      });
    }
  }, [hourlyByWeekData, dailyData]);

  const onTooltipClose = () => {
    toggleTooltipOpen(false);
  };

  const availableTypes = selectedReport?.report_items?.map((value) => value.type);

  useEffect(() => {
    if (reportItem && !isLoading) {
      dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.SEASONALITY, value: false });
      getSeasonalityData(
        reportItem.id,
        selectedTimeFrames,
        selectedLocationsIds,
        selectedProject,
        updateReportItemOfSelectedReport,
        setIsLoading,
        setFetched,
        selectedReportItem,
      );
      dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.SEASONALITY, value: true });
    }
  }, [JSON.stringify(reportItem), selectedTimeFrames, selectedLocationsIds]);

  return !availableTypes?.includes(ReportItemType.SEASONALITY) ? (
    <div />
  ) : (
    <ReportItemCard
      reportItemType={ReportItemType.SEASONALITY}
      values={[
        {
          name: t('seasonality.card.popularDay'),
          value: getPopularDayString(),
        },
        {
          name: t('seasonality.card.popularHour'),
          value: getPopularHourString(),
        },
      ]}
      reportItem={reportItem}
      isLoading={isLoading}
      noData={noData}
      onOpenTooltip={toggleTooltipOpen}
      tooltipRef={tooltipIconRef}
    >
      <div>
        <SeasonalityMainTooltip onClose={onTooltipClose} isOpen={isTooltipOpen} position={position} />
        <SeasonalityCardMostPopularDay
          dayOfWeek={dailyData?.mostPopularDay?.dayOfWeekNumber || 1}
          isLoading={isLoading}
        />
        <SeasonalityCardChart
          data={(hourlyByWeekData && hourlyByWeekData.average) || []}
          mostPopularHour={(hourlyByWeekData && hourlyByWeekData.mostPopularHour) || mostPopularHourMock}
          isLoading={isLoading}
        />
      </div>
    </ReportItemCard>
  );
};

export default SeasonalityCard;
