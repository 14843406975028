import React from 'react';

interface Props {
  fill: string;
}
export const CaretRightIcon: React.FC<Props> = ({ fill }) => (
  <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.741797 1.09166C0.416797 1.41666 0.416797 1.94166 0.741797 2.26666L3.97513 5.5L0.741797 8.73333C0.416797 9.05833 0.416797 9.58333 0.741797 9.90833C1.0668 10.2333 1.5918 10.2333 1.9168 9.90833L5.7418 6.08333C6.0668 5.75833 6.0668 5.23333 5.7418 4.90833L1.9168 1.08333C1.60013 0.766665 1.0668 0.766665 0.741797 1.09166Z"
      fill={fill}
    />
  </svg>
);
