import React, { useContext } from 'react';
import { MainContext } from '../../../common/types/mainContext';
import { SwitchModes } from '../../../common/types/visualizationObjects';
import { CatchmentHeatmap } from '../heatmaps';
import Trips from '../trips/Trips';
import { PostalCodePolygons } from '../../backgroundMap/polygons/postalCodePolygons';
import { CenterPoint } from '../../backgroundMap/points/centerPoint';
import SocioDemoBarChartPoints from '../points/socioDemoBarChartPoints';
import { IsochronePolygons } from '../polygons/isochronePolygons';
import { CatchmentLocationsCountEnum } from '../../../common/reportItems/types';
import { MultiLocationPostalCodePolygons } from '../polygons/multiLocationPostalCodePolygons';
import { MultiCenterPoint } from '../points/multiCenterPoint';
import WinnersBarChartPoints from '../points/winnersBarChartPoints';

interface TProps {
  showPois: boolean;
}

const CatchmentSwitch = ({ showPois }: TProps) => {
  const {
    catchmentLayer,
    reportItemsDetails: { catchmentDataOverlay, catchmentLocationsCount },
  } = useContext(MainContext);

  return (
    <div>
      <div>
        {catchmentLayer === SwitchModes.POSTAL_CODES &&
          catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE && (
            <PostalCodePolygons ignoreShadow outlineColor={'#1f3045'} />
          )}
        {catchmentLayer === SwitchModes.POSTAL_CODES &&
          catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE && (
            <MultiLocationPostalCodePolygons ignoreShadow outlineColor={'#1f3045'} />
          )}
        {catchmentLayer === SwitchModes.HEATMAP && <CatchmentHeatmap />}
        {catchmentLayer === SwitchModes.TRIPS && <Trips />}
        {catchmentLayer === SwitchModes.ISOCHRONES && <IsochronePolygons ignoreShadow outlineColor={'#1f3045'} />}
      </div>
      {catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE &&
        (catchmentLayer === SwitchModes.POSTAL_CODES || catchmentLayer === SwitchModes.ISOCHRONES) &&
        catchmentDataOverlay && <SocioDemoBarChartPoints />}
      {catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE &&
        catchmentLayer === SwitchModes.POSTAL_CODES &&
        catchmentDataOverlay && <WinnersBarChartPoints />}
      {catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE && <CenterPoint showName={showPois} />}
      {catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE && <MultiCenterPoint showName={showPois} />}
    </div>
  );
};

export default CatchmentSwitch;
