import React, { ChangeEvent, useRef } from 'react';
import { CrossIcon, SearchIcon } from '../../icons/controls';
import styles from './style.module.scss';

interface Props {
  searchString: string;
  onChange: (val: string) => void;
}

export const Search = ({ searchString, onChange }: Props) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const onChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    onChange(value);
  };

  const onClearSearch = () => {
    onChange('');
  };

  return (
    <div className={styles.wrapper} ref={componentRef}>
      <div className={styles.field}>
        <input
          type="text"
          value={searchString}
          placeholder="Search existing locations"
          onChange={onChangeSearchValue}
        />
        {searchString ? (
          <span className={styles.cross} onClick={onClearSearch}>
            <CrossIcon />
          </span>
        ) : (
          <SearchIcon />
        )}
      </div>
    </div>
  );
};
