import { FiltersOption } from '../../interfaces';

export const parseFiltersAccordingToInitialFilters = (
  countryCode: string,
  metricValue: string | null,
  categoryValue: string | null,
  filters: FiltersOption[],
) => {
  const filter = filters.find(({ country }) => country === countryCode);
  if (filter) {
    const { metrics } = filter;
    const metric = metricValue ? metrics.find(({ value }) => value === metricValue) : filter.metrics?.[0];

    const category =
      metric && metricValue
        ? metric?.categories?.find(({ value }) => value === categoryValue)
        : metric?.categories?.[0];

    const categories = metric?.categories || [];

    const subcategories = category ? category?.subcategories : categories?.[0]?.subcategories || [];

    return { metric: metrics, category: categories, subcategories };
  }
  return { metric: [], category: [], subcategories: [] };
};
