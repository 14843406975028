export const getCompanyLogo = async (companyLogo: string, width: number, height: number, radius: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  const img = new Image();
  img.src = companyLogo;
  img.onload = function () {
    if (ctx != null) {
      ctx.arc(width - radius, height - radius, radius, 0, 2 * Math.PI);
      ctx.clip();
      ctx.drawImage(img, 0, 0, width, height);
    }
  };
  try {
    await img.decode();
  } catch {
    console.log('Error in company logo decoding process');
    return;
  }

  return canvas.toDataURL();
};
