import React, { useMemo } from 'react';
import moment, { Moment } from 'moment';
import YearPicker from './YearPicker';
import styles from './yearIntervalPicker.module.scss';

interface Props {
  reportStartDate: Moment;
  reportEndDate: Moment;
  startDate: Moment;
  endDate: Moment;
  onChangeStartDate: (beginYear: Moment) => void;
  onChangeEndDate: (endYear: Moment) => void;
  minYear: number;
  maxYear: number;
}

const YearIntervalPicker: React.FC<Props> = ({
  reportStartDate,
  reportEndDate,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  minYear,
  maxYear,
}) => {
  const startYear = useMemo(() => Number.parseInt(startDate.format('YYYY'), 10), [startDate]);
  const endYear = useMemo(() => Number.parseInt(endDate.format('YYYY'), 10), [endDate]);

  const changeYearHandler = (date: Moment, startMonth: string, endMonth: string, amount: number) => {
    let month = date.format('MM');
    const newYear = date.year() + amount;
    if (amount > 0 && newYear === reportEndDate.year()) {
      month = startMonth;
    } else if (amount < 0 && newYear === reportStartDate.year()) {
      month = endMonth;
    }
    return moment(`${date.year() + amount}-${month}-${date.format('DD')}`);
  };

  const changeStartYearHandler = (amount: number) => {
    const newDate = changeYearHandler(startDate, '01', reportStartDate.format('MM'), amount);
    onChangeStartDate(newDate);
  };

  const changeEndYearHandler = (amount: number) => {
    const newDate = changeYearHandler(endDate, reportEndDate.format('MM'), '12', amount);
    onChangeEndDate(newDate);
  };

  return (
    <div className={styles.container}>
      <YearPicker
        label="From"
        date={startDate}
        onChange={changeStartYearHandler}
        isIncreaseEnabled={startYear < maxYear && startYear < endYear}
        isDecreaseEnabled={startYear > minYear}
      />
      <YearPicker
        label="to"
        date={endDate}
        onChange={changeEndYearHandler}
        isIncreaseEnabled={endYear < maxYear}
        isDecreaseEnabled={endYear > minYear && endYear > startYear}
      />
    </div>
  );
};

export default YearIntervalPicker;
