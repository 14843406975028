import React from 'react';

export const UncollapseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5.33333V0H6.66667L8.86 2.19333L2.19333 8.86L0 6.66667V12H5.33333L3.14 9.80667L9.80667 3.14L12 5.33333Z"
      fill="white"
    />
  </svg>
);
