import { useMemo, useState, useEffect, useRef } from 'react';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';

const FIRST_INDEX = 1;
/*
  items - array of items to display
  total - count of items
  portionSize - count of items to show once
  scrollSize - scroll count of items changing current portion number
  dotDisplayedPortion - count of dots for once view
  displayNextStepSlide - include the one more slide in current portion or not
*/
export const useSlider = <T = unknown>(
  items: T[],
  total: number,
  portionSize: number,
  scrollSize: number,
  dotDisplayedPortion: number,
  displayNextStepSlide?: boolean,
) => {
  const [current, setCurrent] = useState(FIRST_INDEX);
  const prevCurrent = useRef<number>(FIRST_INDEX);
  const prevPortion = useRef<number[]>([]);

  useEffect(() => {
    if (Math.ceil(total / portionSize) < current) {
      setCurrent(FIRST_INDEX);
    }
  }, [total]);

  const onChangeCurrent = (value: number) => {
    trackUserAction('Gallery button clicked', UserActionsEnum.GALLERY_CLICK, 'Dots');
    setCurrent(value);
  };

  const onNextSlide = () => {
    trackUserAction('Gallery button clicked', UserActionsEnum.GALLERY_CLICK, 'Next slide');
    setCurrent((prevState) => prevState + 1);
  };

  const onPrevSlide = () => {
    trackUserAction('Gallery button clicked', UserActionsEnum.GALLERY_CLICK, 'Previous slide');
    setCurrent((prevState) => prevState - 1);
  };

  const isFirst = useMemo(() => current === FIRST_INDEX, [current]);

  const portionsCount = useMemo(() => {
    let count = 0;
    if (displayNextStepSlide) {
      count = total < portionSize ? 1 : Math.ceil(total / (portionSize - 1));
    } else {
      count = Math.ceil(total / portionSize);
    }

    return new Array(count).fill('').map((_, index) => index + 1);
  }, [total, portionSize, isFirst]);

  const dotsPortion = useMemo(() => {
    if (current < dotDisplayedPortion) {
      const INIT_ELEMENT_INDEX = 0;

      if (prevPortion.current.length === 0) {
        prevCurrent.current = current;
        return portionsCount.slice(INIT_ELEMENT_INDEX, dotDisplayedPortion);
      } else {
        const indexOfCurrent = prevPortion.current.indexOf(current);
        const IS_FIRST_ELEMENT = indexOfCurrent === 0;
        if (prevPortion.current.includes(current)) {
          const NEXT_START = current - 2;
          const NEXT_END = current - dotDisplayedPortion - 1;
          const currentPortion = portionsCount.slice(
            NEXT_START >= INIT_ELEMENT_INDEX ? NEXT_START : INIT_ELEMENT_INDEX,
            NEXT_START >= INIT_ELEMENT_INDEX ? NEXT_END : dotDisplayedPortion,
          );

          prevPortion.current = IS_FIRST_ELEMENT ? currentPortion : prevPortion.current;

          if (current === 1) prevPortion.current = [];
          return IS_FIRST_ELEMENT ? currentPortion : prevPortion.current;
        }
        prevCurrent.current = current;
      }
    } else {
      if (prevCurrent.current <= current) {
        const IS_FIRST_ELEMENT = current === 1;
        const NEXT_WHEN_FIRST = current + 2;
        const NEXT_WHEN_NOT_FIRST = current + 1;

        const PREV_WHEN_NOT_FIRST =
          current -
          dotDisplayedPortion +
          (current >= dotDisplayedPortion && current !== portionsCount[portionsCount.length - 1] ? 1 : 0);
        const PREV_WHEN_FIRST = current - 1;
        const currentPortion = portionsCount.slice(
          IS_FIRST_ELEMENT ? PREV_WHEN_FIRST : PREV_WHEN_NOT_FIRST,
          IS_FIRST_ELEMENT ? NEXT_WHEN_FIRST : NEXT_WHEN_NOT_FIRST,
        );

        prevPortion.current = currentPortion;
        prevCurrent.current = current;
        return currentPortion;
      } else {
        const indexOfCurrent = prevPortion.current.indexOf(current);
        const IS_FIRST_ELEMENT = indexOfCurrent === 0;

        if (prevPortion.current.includes(current)) {
          const NEXT_START = current - 2;
          const NEXT_END = prevCurrent.current - 1;
          const currentPortion = portionsCount.slice(NEXT_START, NEXT_END);

          prevPortion.current = IS_FIRST_ELEMENT ? currentPortion : prevPortion.current;
          return IS_FIRST_ELEMENT ? currentPortion : prevPortion.current;
        }
        prevCurrent.current = current;
      }
    }
  }, [portionsCount, current, dotDisplayedPortion]);

  const isLast = useMemo(
    () => (portionsCount.length === 0 ? true : current === portionsCount[portionsCount.length - 1]),
    [current, portionsCount],
  );

  const currentItemsPortion = useMemo(() => {
    const isFirst = current === FIRST_INDEX;

    const defaultTo = current * scrollSize;
    const defaultFrom = (current - 1) * scrollSize;

    const withNextSlideTo = current * scrollSize - 1;
    const withNextSlideFrom = (current - 1) * (scrollSize - 1);

    const to = !displayNextStepSlide ? defaultTo : !isFirst ? withNextSlideTo : defaultTo;
    const from = !displayNextStepSlide ? defaultFrom : !isFirst ? withNextSlideFrom : defaultFrom;

    return items.slice(from, to);
  }, [items, current, scrollSize]);

  return {
    current,
    onChangeCurrent,
    isFirst,
    isLast,
    portionsCount: dotsPortion,
    currentItemsPortion,
    onNextSlide,
    onPrevSlide,
  };
};
