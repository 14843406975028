import React from 'react';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import MultiSelect from '../../../atoms/multiselect/multiselect';
import styles from './postprocessingMultiSelects.module.scss'

type TProps = {
  onChange: (items: any) => void;
  options: Option[];
  value: Option[];
};

const MultiSelectEditableSearchDisabled: React.FC<TProps> = ({ onChange, options, value }: TProps) => (
  <div className={styles.valueContainer}>
    <MultiSelect
      disableSearch
      options={options}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default MultiSelectEditableSearchDisabled;
