import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportItemDetail from '../../../common/reportItems/reportItemDetail';
import { MainContext } from '../../../common/types/mainContext';
import { PeriodsEnum, ReportItemType } from '../../../common/reportItems/types';
import {
  ContentVisualizationMode,
  ReportItemInfo,
  Retention,
  RetentionLocation,
} from '../../../common/types/projectsHierarchy';
import { RetentionChart } from './Chart';
import { fetchRetentionData } from '../service';
import { Table } from './Table';
import { useElementScreenshot } from '../../../common/hooks/useElementScreenshot';
import { ChartEvents } from '../../../common/chart/interfaces';
import { ChartEventsEnum } from '../../../common/chart/enums';
import { useStore } from 'effector-react/effector-react.cjs';
import { exportSettingsModel } from '../../../common/export';
import { Header } from './Header';
import { Option } from '../../../common/controls/dropdown/types';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { convertDataForExcel } from './utils/convertDataForExcel';
import { RecurrenceFrequencyEnum } from '../../../createReport/types';
import { RecurringReportTooltip } from '../../../common/reportItems/reportItemDetail/recurringReportTooltip/recurringReportTooltip';
import moment from 'moment';

interface Props {
  useFixedScreenshotSize?: boolean;
}
export const RetentionDetail = ({ useFixedScreenshotSize }: Props) => {
  const { t } = useTranslation();
  const {
    selection: { selectedReport },
    selectedLocationsIds,
    selectedLocations,
    selectedTimeFrames,
    updateReportItemsDetailsItems,
    updateReportItemsDetailsValue,
    reportItemsDetails: { items, retentionPeriod, showAverageValuesInChart },
    contentVisualizationMode,
  } = useContext(MainContext);
  const isExportMode = useStore(exportSettingsModel.$isExportMode);

  const reportItem = items?.find((i) => i.type === ReportItemType.RETENTION) as ReportItemInfo<Retention>;

  const [period, setPeriod] = useState(PeriodsEnum.MONTHLY);
  const [modifiedData, setModifiedData] = useState<null | RetentionLocation[]>(null);
  const { onMakeScreenshot, base64Image, elementRef } = useElementScreenshot({
    backgroundColor: `rgba(37, 55, 76, 1)`,
  });
  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const isChartReady = useRef<boolean>(false);

  useEffect(() => {
    const data = reportItem?.data;
    if (data?.data) {
      let chartData = [...data.data];

      if (chartData.length > 1 && showAverageValuesInChart) {
        chartData = [
          {
            location_id: 'average',
            location_name: 'Average',
            data: data?.averageInfo.returningVisitsAcrossLocations || [],
          },
          ...chartData,
        ];
      }
      setModifiedData(chartData);
    }
  }, [reportItem?.data?.data]);

  const onChartReady = () => {
    if (!isChartReady.current) {
      isChartReady.current = true;
      if (exportOptions.RETENTION?.chart && reportItem.data?.data) {
        onMakeScreenshot();
      }
    }
  };

  const chartEvents: ChartEvents = useMemo(
    () => ({
      [ChartEventsEnum.FINISHED]: onChartReady,
    }),
    [reportItem?.data?.data],
  );

  const renderData = (data?: RetentionLocation[] | null) => {
    const chartData = [...(data || [])];
    if (!chartData) return null;

    if (contentVisualizationMode === ContentVisualizationMode.VISUALIZATION || isExportMode) {
      return (
        <RetentionChart
          data={chartData}
          period={retentionPeriod}
          projectLocations={selectedLocations}
          events={chartEvents}
          isScreenshotMode={isExportMode}
          useFixedScreenshotSize={useFixedScreenshotSize}
        />
      );
    } else {
      return <Table data={data || []} period={retentionPeriod} />;
    }
  };

  useEffect(() => {
    if (!reportItem?.data?.data) {
      exportSettingsModel.updateExportData({ [ReportItemType.RETENTION]: { chart: '', table: '' } });
    }
  }, [reportItem?.data]);

  useEffect(() => {
    if (base64Image) {
      exportSettingsModel.updateExportData({ [ReportItemType.RETENTION]: { chart: base64Image } });
    }
  }, [base64Image]);

  useEffect(() => {
    if (reportItem?.data?.data && exportOptions.RETENTION?.table) {
      const data = convertDataForExcel(reportItem.data.data, period);
      exportSettingsModel.updateExportData({ [ReportItemType.RETENTION]: { table: data } });
    }
  }, [exportOptions.RETENTION]);

  const onChangeTimeType = async (value: Option<PeriodsEnum>) => {
    trackUserAction(
      'Retention rate time period changed',
      UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
      `Time period: ${value.label}`,
    );
    updateReportItemsDetailsValue('retentionPeriod', value.id);
    // setPeriod(value.id as PeriodsEnum);
    if (reportItem) {
      const response = await fetchRetentionData(
        reportItem.id,
        selectedLocationsIds,
        value.id as PeriodsEnum,
        selectedLocations,
        selectedTimeFrames,
      );

      if (response) {
        updateReportItemsDetailsItems?.('retention', [response]);
      }
    }
  };

  const minimizedData = useMemo(
    () => renderData(modifiedData),
    [contentVisualizationMode, modifiedData, showAverageValuesInChart, isExportMode],
  );

  const lastUpdated = moment(selectedReport?.end_date);

  return (
    <div ref={elementRef} style={{ width: isExportMode ? 'auto' : '100%', height: isExportMode ? 'auto' : '100%' }} >
      <ReportItemDetail
        type={ReportItemType.RETENTION}
        title={t('retention.title')}
        subtitle={t('retention.detail.showingTheShare')}
        onMakeScreenshot={onMakeScreenshot}
      >
        {/* Commented until we get backend for this */}
        <Header
          activeTimeType={retentionPeriod}
          onChangeTimeType={onChangeTimeType}
          visualizationMode={contentVisualizationMode}
        />
        {selectedReport?.recurring &&
          !(
            selectedReport.recurrence_frequency === RecurrenceFrequencyEnum.MONTHLY && period === PeriodsEnum.MONTHLY
          ) && (
            <RecurringReportTooltip
              year={lastUpdated.format('YYYY')}
              month={lastUpdated.format('MMM')}
              day={lastUpdated.format('DD')}
            />
          )}
        {reportItem?.data?.data && minimizedData}
      </ReportItemDetail>
    </div>
  );
};
