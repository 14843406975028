import React from 'react';
import { IntroPageTemplate } from '../../common/templates';
import { SignUp } from '../../auth';

export const SignUpPage = () => {
  return (
    <IntroPageTemplate>
      <SignUp />
    </IntroPageTemplate>
  );
};
