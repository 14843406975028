import React from 'react';

interface Props {
  coordinates: {
    halfSize: number;
    center: number;
    topHeight: number;
    radius: number;
    yPosition: number;
    bottomHeight: number;
    translateY: number;
  };
  index: number;
  side: 'left' | 'right';
}
export const Line: React.FC<Props> = ({ coordinates, index, side }) => {
  const { halfSize, topHeight, radius, yPosition, bottomHeight, center, translateY } = coordinates;
  const start = side === 'left' ? bottomHeight : topHeight + radius;
  const end = side === 'left' ? topHeight + radius : bottomHeight;

  return (
    <g style={{ transform: `translateY(${translateY}px)` }}>
      <defs>
        <linearGradient id="gradient-left">
          {' '}
          <stop offset="15%" stopColor="#374659" />
          <stop offset="70%" stopColor="white" />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="gradient-right">
          <stop offset="30%" stopColor="white" />
          <stop offset="85%" stopColor="#374659" />
        </linearGradient>
      </defs>
      <path
        d={`M${start},${center} C${halfSize + (side === 'left' ? 20 : 0)},${halfSize} ${
          halfSize + (side === 'left' ? 25 : 0)
        },${yPosition} ${end},${yPosition}`}
        stroke={`url(#gradient-${side})`}
        strokeWidth={2}
      />
    </g>
  );
};
