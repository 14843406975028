import { request } from '../request';
import { API_URLS } from '../../routes';

export const editReport = (id: string, name: string, description: string, visualizationURL: string) => {
  const url = `${API_URLS.REPORT}/${id}`;
  return request<Response>({
    url,
    method: 'PUT',
    data: {
      name,
      description,
      visualizationURL,
    },
  });
};
