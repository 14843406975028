import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { BBox } from '@turf/turf';
import s from './Windows.module.css';
import Bar from '../Bar/Bar';
import MapBase from '../MapBase/MapBase';
import PolylineEditor from '../PolylineEditor/PolylineEditor';

interface Props {
  onClose: () => void;
  onSavePolygon: (coordinates: string) => void;
}

const changeHistory = _.debounce((polygon, setHistory) => {
  setHistory((preHistory: any) => [polygon, ...preHistory]);
}, 100);

const Window: React.FC<Props> = ({ onClose, onSavePolygon }) => {
  const [polygon, setPolygon] = useState<undefined | GeoJSON.Feature<GeoJSON.Polygon> | null>(undefined);
  const [history, setHistory] = useState<GeoJSON.Feature<GeoJSON.Polygon>[]>([]);
  const [selectedPlaceBBox, setSelectedPlaceBBox] = useState<BBox | undefined>(undefined);
  const [marker, setMarker] = useState<number[]>();

  const onChangePolygon = useCallback(
    (polygon: undefined | GeoJSON.Feature<GeoJSON.Polygon> | null) => {
      setPolygon(polygon);
      if (polygon) {
        changeHistory(polygon, setHistory);
      }
    },
    [setPolygon, setHistory],
  );

  const onUndo = useCallback(() => {
    const [undo, ...currentHistory] = history;
    setPolygon(undo || undefined);
    setHistory(currentHistory || []);
  }, [setPolygon, setHistory, history]);

  const onReset = useCallback(() => {
    setPolygon(undefined);
    setHistory([]);
  }, [setPolygon]);

  const onSavePolygonHandler = useCallback(() => {
    if (polygon) {
      const data = polygon.geometry.coordinates[0].map((item) => item.join(',')).join('\n');
      onSavePolygon(data);
    }
  }, [polygon]);

  const onSelectPlace = useCallback((place: any) => {
    const bounds = place.geometry.viewport;
    setMarker([place.geometry.location.lng, place.geometry.location.lat]);
    setSelectedPlaceBBox([bounds.southwest.lng, bounds.southwest.lat, bounds.northeast.lng, bounds.northeast.lat]);
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.window}>
        <div className={s.map}>
          <MapBase initialBbox={selectedPlaceBBox} marker={marker}>
            <PolylineEditor polygon={polygon} setPolygon={onChangePolygon} />
          </MapBase>
        </div>
        <div className={s.sidebar}>
          <Bar
            data={polygon}
            onUndo={onUndo}
            onReset={onReset}
            onClose={onClose}
            onSavePolygon={onSavePolygonHandler}
            onSelectPlace={onSelectPlace}
          />
        </div>
      </div>
    </div>
  );
};

export default Window;
