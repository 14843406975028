import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../../../../common/types/mainContext';
import Dropdown from '../../../../../common/controls/dropdown';
import styles from './style.module.scss';
import { Option } from '../../../../../common/controls/dropdown/types';
import { CrossVisitationChartValues } from '../../../../../common/reportItems/types';

export const ChartValuesDropdown: React.FC = () => {
  const { t } = useTranslation();
  const {
    reportItemsDetails: { crossVisitationChartValues },
    updateReportItemsDetailsValue,
  } = useContext(MainContext);

  const onChange = (value: Option<null>) => {
    updateReportItemsDetailsValue('crossVisitationChartValues', value.id);
  };

  const dropdownValues = useMemo(
    () =>
      Object.values(CrossVisitationChartValues).map((item) => ({
        id: item,
        label: t(`crossVisitation.enum.${item}`),
        item: null,
        disabled: false,
      })),
    [CrossVisitationChartValues],
  );

  return (
    <div className={styles.chartValuesDropdown}>
      <Dropdown<null> onChange={onChange} options={dropdownValues} selectedId={crossVisitationChartValues} />
    </div>
  );
};
