import React from 'react';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import MultiSelect from 'react-multi-select-component';

interface Props {
  options: Option[];
  value: Option[];
  className?: string;
  disableSearch?: boolean;
}

const MultiselectForDisplay = (props: Props) => {
  const customSearch = (options: Option[], searchValue: string) => {
    if (!searchValue) {
      return options;
    }
    return options.filter((option) => option.label && option.label.toLowerCase().includes(searchValue.toLowerCase()));
  };

  return (
    <MultiSelect
      disableSearch={props.disableSearch?props.disableSearch:false}
      className={props.className}
      options={props.options}
      value={props.value}
      labelledBy="Selected"
      filterOptions={customSearch}
      onChange={() => {}}
      ClearSelectedIcon={null}
    />
  );
};

export default MultiselectForDisplay;
