import * as turf from '@turf/turf';
import { Feature, MultiPolygon, Polygon } from '@turf/turf';
import { splitCoordinatesArrayToPairs, splitCoordinatesByPolygons } from '../../../polylineTool';
import { PolygonData } from '../../createLocation/interfaces';
import { convertPolygonToGeoData } from '../../../maps/utils/convertPolygonToGeoData';

export default (polygon: PolygonData): Feature<Polygon> | undefined => {
  const { polygonString } = polygon;
  const parsedPolygon = splitCoordinatesByPolygons(polygonString.split('\n'));
  const coords = splitCoordinatesArrayToPairs(parsedPolygon[0].split(','));
  try {
    return turf.polygon([coords]);
  } catch (e) {
    return undefined;
  }
};

export const createPolygonFromWKT = (polygon: string): Feature<Polygon | MultiPolygon> => {
  const geometry = convertPolygonToGeoData(polygon);
  try {
    return turf.polygon(geometry?.geometry?.coordinates);
  } catch (e) {
    return turf.multiPolygon(geometry?.geometry?.coordinates);
  }
};
