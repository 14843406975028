import React, { useContext, useEffect } from 'react';
import { MainContext } from '../../../types/mainContext';
import { LocationsSlidingBar } from '../../../sliders';
import { MapBottomBarContext } from '../context';
import { getLocationsImages } from '../utils';
import { getRequestModeFromType, getTypeFromMapMode } from './utils';
import { CatchmentMapScale } from '../../../../mainPage/backgroundMap/polygons/catchmentMapScale';
import { Catchment, ReportItemInfo } from '../../../types/projectsHierarchy';
import { CatchmentMapTypesEnum } from './enums';
import { IndexPolygonsMapScaleBar } from './IndexPolygonsMapScaleBar';
import { MapBaseOptions } from '../../mapBase/mapBase';

const DefaultImages = {
  [CatchmentMapTypesEnum.TRIPS]: '/img/v2/catchment_trip_route_blur.jpg',
  [CatchmentMapTypesEnum.HEAT_MAP]: '/img/v2/catchment_heatmap_blur.jpg',
  [CatchmentMapTypesEnum.POSTAL_CODES]: '/img/v2/catchment_area_blur.jpg',
  [CatchmentMapTypesEnum.ISOCHRONES]: '/img/v2/catchment_area_blur.jpg',
};

export const CatchmentBottomBar = () => {
  const {
    selectedLocations,
    selection: { selectedReportItem },
    catchmentLayer,
    reportItemsDetails: { catchmentType },
  } = useContext(MainContext);

  const { catchment, onChangeCatchment, onChangeCatchmentViewOptions } = useContext(MapBottomBarContext);
  const { isHidden } = useContext(MapBaseOptions);

  const typeAccordingMapMode = getTypeFromMapMode(catchmentLayer);
  const isAlreadyDataAccordingToMapMode = catchment.data[typeAccordingMapMode];
  const mode = getRequestModeFromType(catchmentType, typeAccordingMapMode);

  useEffect(() => {
    if (selectedReportItem?.id && isAlreadyDataAccordingToMapMode) {
      (async function () {
        const data = await getLocationsImages(
          selectedReportItem.id,
          selectedLocations,
          mode,
          DefaultImages[typeAccordingMapMode],
        );

        onChangeCatchment(typeAccordingMapMode, data);
      })();
    }
  }, [selectedLocations, catchmentLayer, catchmentType]);

  useEffect(() => {
    if (selectedReportItem?.id && !isAlreadyDataAccordingToMapMode) {
      (async function () {
        const data = await getLocationsImages(
          selectedReportItem.id,
          selectedLocations,
          mode,
          DefaultImages[typeAccordingMapMode],
        );

        onChangeCatchment(typeAccordingMapMode, data);
      })();
    }
  }, [selectedLocations, catchmentLayer, catchmentType]);

  const data = catchment.data[typeAccordingMapMode];

  const { collapsed, expanded } = catchment.view;

  const reportItem = selectedReportItem as ReportItemInfo<Catchment>;

  if (isHidden) return <IndexPolygonsMapScaleBar />;

  return data && data.length > 0 && reportItem?.data?.showGallery ? (
    <LocationsSlidingBar
      items={data}
      shareBlock={<CatchmentMapScale />}
      isExpanded={expanded}
      isCollapsed={collapsed}
      onChangeViewOptions={onChangeCatchmentViewOptions}
    />
  ) : (
    <></>
  );
};
