import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EChartsOption } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { OrdinalRawValue } from 'echarts/types/src/util/types';
import EChart from '../../../../../common/chart';
import { LocationData, MetricType } from '../interfaces';
import styles from './style.module.scss';
import { generateTooltip } from '../tooltip';
import { maxLengthOfString, separateThousandsByComma } from '../../../../../common/utils';
import { useWindowSize } from '../../../../../common/hooks';

interface Props {
  data: LocationData[];
  isRelativeMetric: boolean;
  yAxisLabel: string;
  metricType: MetricType | null;
  currency: string;
}

function getChartOptions(
  data: LocationData[],
  isRelativeMetric: boolean,
  metricType: MetricType | null,
  yAxisLabel: string,
  barWidth: number,
  currency: string,
): EChartsOption {
  return {
    grid: {
      top: 20,
      left: 100,
      right: 30,
      bottom: 155,
    },
    xAxis: {
      data: data?.map(({ name }) => name),
      scale: true,
      type: 'category',
      axisLabel: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
        width: 168, // fixed number of pixels
        rotate: 45,
        interval: 0,
        formatter: (data: OrdinalRawValue) => String(maxLengthOfString(String(data), 27)),
      },
    },
    tooltip: {
      formatter: (data: CallbackDataParams[] | CallbackDataParams) => {
        if (Array.isArray(data)) {
          return generateTooltip(data, isRelativeMetric, metricType === 'CURRENCY' ? currency : null);
        }
        return '';
      },
      trigger: 'axis',
      borderColor: 'rgba(255, 255, 255, 0.3)',
      backgroundColor: 'transparent',
      shadowColor: 'transparent',
      className: styles.tooltip,
      textStyle: {
        color: 'white',
      },
    },
    yAxis: {
      type: 'value',
      name: yAxisLabel,
      nameLocation: 'middle',
      max: ({ max }) => (max > 100 && max < 101 ? 100 : max),
      axisLabel: {
        formatter: (value: number) =>
          value !== 0
            ? `${metricType === 'CURRENCY' ? currency : ''}${
                isRelativeMetric ? value : separateThousandsByComma(value)
              }${isRelativeMetric ? '%' : ''}`
            : '0',
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
      },
      nameTextStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      splitLine: {
        lineStyle: {
          opacity: 0.1,
          type: 'solid',
          width: 1,
          color: '#FFFFFF',
        },
      },
      nameGap: 85,
    },
    legend: {
      show: data?.[0]?.data?.length > 1,
      align: 'auto',
      itemGap: 25,
      bottom: 0,
      icon: 'circle',
      type: 'scroll',
      textStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      formatter: (value) => value.replace('<CURRENCY>', currency),
      pageTextStyle: {
        color: 'white',
      },
      pageIconColor: 'rgba(255, 255, 255, .25',
    },
    series: data?.[0]?.data?.map(({ label }, index) => ({
      id: label,
      name: label,
      type: 'bar',
      barGap: '50%',
      barWidth,
      stack: 'x',
      data: data.map((location) => {
        const value = location.data.find((item) => item.label === label);
        return value?.value || 0;
      }),
      label: {
        show: barWidth >= 33,
        position: 'inside',
        color: 'black',
        fontSize: 10,
        formatter: (param: any) =>
          isRelativeMetric
            ? `${param.value}%`
            : `${metricType === 'CURRENCY' ? currency : ''}${separateThousandsByComma(param.value)}`,
      },
      itemStyle: {
        borderRadius: [2, 2, 0, 0],
      },
    })),
  };
}

const DEFAULT_BAR_WIDTH = 50;
const DEFAULT_WIDTH_CHART = 900;

export const Chart: React.FC<Props> = ({ data, isRelativeMetric, yAxisLabel, metricType, currency }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  const [barWidth, setBarWidth] = useState(DEFAULT_BAR_WIDTH);
  const [chartWidth, setChartWidth] = useState(DEFAULT_WIDTH_CHART);

  useEffect(() => {
    if (chartRef.current) {
      const dataCount = data.length;
      const chart = chartRef.current;
      const chartWidth = chart.clientWidth;
      const resultWidth = dataCount * DEFAULT_BAR_WIDTH;
      if (resultWidth > chartWidth) {
        const newBarWidth = Math.floor(chartWidth / dataCount);
        setBarWidth(newBarWidth - 7);
      } else {
        setBarWidth(DEFAULT_BAR_WIDTH);
      }
    }
  }, [data]);

  useEffect(() => {
    if (chartRef.current && width) {
      const dataCount = data.length;
      const BAR_SPACE_WIDTH = 20;
      const DEFAULT_DATA_LENGTH = 10;
      const CHART_WIDTH = width - 500;
      if (dataCount <= DEFAULT_DATA_LENGTH) {
        setChartWidth(DEFAULT_WIDTH_CHART);
        return;
      }

      const additionalColumns = dataCount - DEFAULT_DATA_LENGTH;
      const additionalWidth = DEFAULT_WIDTH_CHART + additionalColumns * (DEFAULT_BAR_WIDTH + BAR_SPACE_WIDTH);

      setChartWidth(additionalWidth > CHART_WIDTH ? CHART_WIDTH : additionalWidth);
    }
  }, [data, width]);

  const options = useMemo(
    () => getChartOptions(data, isRelativeMetric, metricType, yAxisLabel, barWidth, currency),
    [data, isRelativeMetric, yAxisLabel, barWidth, metricType, currency],
  );

  return (
    <div className={styles.chartWrapper}>
      <div style={{ height: '400px', width: `${chartWidth}px` }} ref={chartRef}>
        <EChart option={{ ...options }} />
      </div>
    </div>
  );
};
