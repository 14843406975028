import React from 'react';

interface Props {
  fill?: string;
  opacity?: string;
}
export const SmallLogoIcon = ({ opacity = '0.6', fill = 'white' }: Props) => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity={opacity}
      d="M4.97842 7.02847L5.9041 7.93482L8.99152 10.9686L7.75643 12.1811L3.78274 8.27801C3.42458 7.96775 3.13601 7.58767 2.93529 7.16191C2.73457 6.73615 2.62607 6.27402 2.61674 5.80485C2.61674 4.94784 2.96309 4.12593 3.57954 3.51994C4.196 2.91394 5.03213 2.5735 5.904 2.5735C6.77577 2.5735 7.6119 2.91394 8.22845 3.51994C8.84491 4.12593 9.19125 4.94784 9.19125 5.80485C9.18192 6.27405 9.07342 6.7362 8.8727 7.16198C8.67188 7.58775 8.38321 7.96781 8.02495 8.27801L7.75554 8.54258L6.52273 7.32957L6.82987 7.02847C7.1914 6.71644 7.41287 6.27666 7.44612 5.80485C7.44066 5.40658 7.27588 5.02644 6.98741 4.74672C6.69894 4.46698 6.31 4.31015 5.90479 4.31015C5.49968 4.31015 5.11075 4.46698 4.82228 4.74672C4.5338 5.02644 4.36902 5.40658 4.36356 5.80485C4.39671 6.27654 4.61798 6.71627 4.97922 7.02847H4.97842ZM3.18565 8.90251L5.90529 11.5733L7.13958 12.7864L5.90469 14L1.99086 10.1526C1.09338 9.37149 0.458958 8.34042 0.171895 7.1964C-0.115188 6.05238 -0.0413718 4.84958 0.383544 3.74778C0.80848 2.64599 1.5643 1.69737 2.55083 1.02795C3.53725 0.358528 4.70762 0 5.90628 0C7.10494 0 8.27521 0.358528 9.26173 1.02795C10.2482 1.69737 11.0041 2.64599 11.4289 3.74778C11.8539 4.84958 11.9277 6.05238 11.6406 7.1964C11.3535 8.34042 10.7191 9.37149 9.8217 10.1526L9.60808 10.3623L8.37467 9.14981L8.62543 8.90364C9.07312 8.51546 9.43277 8.03907 9.68104 7.50541C9.92931 6.97175 10.0605 6.39274 10.0664 5.80596C10.0574 4.72721 9.61513 3.69567 8.83587 2.936C8.05672 2.17635 7.00369 1.75012 5.90628 1.75012C4.80878 1.75012 3.75584 2.17635 2.97659 2.936C2.19744 3.69567 1.7551 4.72721 1.74606 5.80596C1.75182 6.39239 1.88286 6.97108 2.13073 7.50453C2.3786 8.03798 2.73775 8.51427 3.18485 8.90251H3.18565Z"
      fill={fill}
    />
  </svg>
);
