import jwt_decode from 'jwt-decode';
import { AppActions } from '../actions/actionTypes';
import { Account } from '../types/Account';
import { FETCH_ACCOUNTS_SUCCESS, FETCH_USER_ROLES_SUCCESS, FETCH_USERS_SUCCESS } from '../actions/actions';
import { User } from '../types/User';
import UserRole, { userRoleTranslationMap } from '../types/UserRole';

export interface AuthState {
  token: string | null;
  userId: string | null;
  userRole: string | null;
  accountId: string | null;
  accountType: string | null;
  accounts: Account[];
  users: User[];
  userRoles: UserRole[];
}

const initialState: AuthState = {
  token: null,
  userId: null,
  userRole: null,
  accountId: null,
  accountType: null,
  accounts: [],
  users: [],
  userRoles: [],
};

const compareStringValues = (itemA: string, itemB: string): number => (itemA ? itemA.localeCompare(itemB) : 0);

export default function authReducer(state = initialState, action: AppActions) {
  switch (action.type) {
    case 'LOGIN_SUCCESS': {
      const updateDateTime = new Date();
      const expireDateTime = new Date(updateDateTime.setDate(updateDateTime.getDate() + 30));
      const { token } = action.payload;
      const decodedToken: {
        userId: string;
        userRole: string;
        accountId: string;
        accountType: string;
        userName: string;
        userEmail: string;
        companyName?: string;
        roleTitle?: string;
        phone?: string;
      } = jwt_decode(token);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('jwt', token);
      localStorage.setItem('accountId', decodedToken.accountId);
      localStorage.setItem('accountType', decodedToken.accountType || '');
      localStorage.setItem('userRole', decodedToken.userRole);
      localStorage.setItem('userName', decodedToken.userName);
      localStorage.setItem('userEmail', decodedToken.userEmail);
      localStorage.setItem('userId', decodedToken.userId);

      localStorage.setItem('companyName', decodedToken.companyName ? decodedToken.companyName : '');
      localStorage.setItem('roleTitle', decodedToken.roleTitle ? decodedToken.roleTitle : '');
      localStorage.setItem('phone', decodedToken.phone ? decodedToken.phone : '');
      const expireTimeStamp =
        Math.floor(expireDateTime.getTime() / 1000) || Math.floor(updateDateTime.getTime() / 1000);
      localStorage.setItem('expireTimeStamp', expireTimeStamp.toString());

      return {
        ...state,
        token,
        userId: decodedToken.userId,
        userRole: decodedToken.userRole,
        accountId: decodedToken.accountId,
        expireTimeStamp,
      };
    }
    case FETCH_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accounts: action.payload.accounts,
      };
    }
    case FETCH_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.users.sort(
          (userA, userB) =>
            compareStringValues(userA.accountName, userB.accountName) ||
            compareStringValues(userA.firstName, userB.firstName) ||
            compareStringValues(userA.lastName, userB.lastName) ||
            compareStringValues(userA.email, userB.email),
        ),
      };
    }
    case FETCH_USER_ROLES_SUCCESS: {
      const entries = Object.entries(Object.fromEntries(userRoleTranslationMap));
      return {
        ...state,
        userRoles: action.payload.userRoles.map((_, index) => entries[index][0]),
      };
    }
    default:
      return state;
  }
}
