import mixpanel from 'mixpanel-browser';
import { UserActionsEnum, EnvironmentsEnum } from './userActionsEnum';

type TrackProperties = { [key in any]: any };

const getEnvironment = () => {
  const currentUrl = window.location.hostname;
  if (currentUrl.startsWith('portal.place')) return EnvironmentsEnum.PRODUCTION;
  if (currentUrl.startsWith('qa.place')) return EnvironmentsEnum.QA;
  if (currentUrl.startsWith('dev.place')) return EnvironmentsEnum.DEV;
  if (currentUrl.startsWith('localhost')) return EnvironmentsEnum.LOCAL;
  return EnvironmentsEnum.UNKNOWN;
};

export const track = (message: string, properties?: TrackProperties) => {
  mixpanel.track(message, {
    action: UserActionsEnum.ACTION_NOT_SET,
    actionDetail: '',
    ...properties,
  });
};

export const trackUserAction = (
  message: string,
  action: UserActionsEnum,
  actionDetail?: string,
  properties?: TrackProperties,
) => {
  const environment = getEnvironment();
  if (environment === EnvironmentsEnum.PRODUCTION) {
    mixpanel.track(message, {
      action,
      actionDetail,
      environment,
      ...properties,
    });
  }
};

export const trackSuccessfulLogin = (userId: string, email: string, name: string) => {
  mixpanel.identify(email);
  mixpanel.people.set({
    User_id: userId,
    $email: email,
    $name: name,
  });
  mixpanel.register({ Email: email, UserId: userId, Name: name });

  trackUserAction(`User '${email}' has successfully logged in`, UserActionsEnum.LOGIN, '');
};

export const trackSuccessfulLogout = (userName: string) => {
  trackUserAction(`User '${userName}' has logged out`, UserActionsEnum.LOGOUT, '');
  mixpanel.reset();
};
