import { request } from '../../request';
import { API_URLS } from '../../../routes';
import { CatchmentTypeEnum } from '../../../common/reportItems/types';
import { Data } from '../../../reportItems/catchment/detail/CharacteristicOfAudience/interfaces';

export const getSocialDemographicCharacteristic = (
  reportItemId: string,
  countryCode: string,
  catchmentType: CatchmentTypeEnum,
  locationsIds: string[] | null,
  timeFrames: string[] | null,
  minThreshold: number,
  maxThreshold: number,
  metric: string,
  category: string,
  subcategory: string,
) => {
  const url = `${API_URLS.REPORT_ITEMS_DATA}/social-demographics/${reportItemId}/${countryCode}/${catchmentType}`;

  return request<Data>({
    url,
    method: 'GET',
    params: {
      locations: locationsIds?.join(','),
      timeFrames,
      minThreshold,
      maxThreshold,
      metric,
      ...(category ? { category } : {}),
      ...(subcategory ? { subcategory } : {}),
    },
  });
};

export const getSocialDemographicsFilters = <T>(reportItemId: string): Promise<T> => {
  const url = `${API_URLS.REPORT_ITEMS_DATA}/social-demographics/${reportItemId}/filter/options`;

  return request({ url, method: 'GET' });
};
