import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportInfo } from '../../../common/types/projectsHierarchy';
import styles from '../panelContent.module.scss';
import { CalendarIcon, LocationIcon, ReportIcon } from '../../../common/icons/controlPanel';
import { maxLengthOfString } from '../../../common/utils/stringUtils';
import { ReportMenu } from './reportMenu';

interface TProps {
  report: ReportInfo;
  onClick: (report: ReportInfo) => void;
}

export const SingleReport = ({ report, onClick }: TProps) => {
  const { t } = useTranslation();
  const userRole = localStorage.getItem('userRole');
  return (
    <div
      className={styles.singleReport}
      onClick={() => onClick(report)}
      style={{
        backgroundImage: 'url(/img/v2/project.png)',
        backgroundSize: '310px',
      }}
    >
      <div className={styles.row}>
        <div className={styles.icon} />
        <div className={styles.title}>{report.name}</div>
        {userRole === 'placenseUser' && <ReportMenu report={report} />}
      </div>
      <div className={styles.row}>
        <div className={styles.icon} />
        <div className={styles.description}>{maxLengthOfString(report.description, 36)}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.icon}>
          <LocationIcon />
        </div>
        <div>{report.locations_count || '0'} locations</div>
      </div>
      <div className={styles.row}>
        <div className={styles.icon}>
          <CalendarIcon />
        </div>
        <div>{report.created_date && `Created ${report.created_date}`}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.icon}>
          <ReportIcon />
        </div>
        <div>{report.category && `Report type: ${t(`create.enum.${report.category}`)}`}</div>
      </div>
    </div>
  );
};
