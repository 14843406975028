import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportInfo } from '../../../common/types/projectsHierarchy';
import { EditReportPopup } from './popup/editReportPopup';
import { ThreeDotsMenu } from '../../../common/threeDotsMenu';
import { DeleteReportPopup } from './popup/deleteReportPopup';

interface TProps {
  report: ReportInfo;
}

export const ReportMenu = ({ report }: TProps) => {
  const { t } = useTranslation();
  const [isEditPopupOpen, toggleEditPopup] = useState<boolean>(false);
  const [isDeletePopupOpen, toggleDeletePopup] = useState<boolean>(false);

  const onItemClick = (item: string) => {
    if (item === 'Edit') {
      toggleEditPopup(true);
    }
    if (item === 'Delete') {
      toggleDeletePopup(true);
    }
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ThreeDotsMenu
        title="Settings"
        options={['Edit', /*'Duplicate',*/ 'Delete']}
        onChange={onItemClick}
        id={report.id}
      />
      <EditReportPopup isOpen={isEditPopupOpen} report={report} toggleOpen={toggleEditPopup} />
      <DeleteReportPopup isOpen={isDeletePopupOpen} report={report} toggleOpen={toggleDeletePopup} />
    </div>
  );
};
