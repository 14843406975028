import * as turf from '@turf/turf';

/**
 * Get timestamps array from distance in meters
 * @param path - array gep points
 * @return timestamps array
 */
export const getTimestampsFromDistance = (path: [number, number][]): number[] =>
  path.reduce((res, point, i, path) => {
    if (i === 0) return [0];

    const from = turf.point(path[i - 1]);
    const to = turf.point(path[i]);
    const distance = turf.distance(from, to, { units: 'meters' });
    return [...res, res[i - 1] + distance];
  }, [] as number[]);
