import classnames from 'classnames';
import PlaceIcon from '@material-ui/icons/Place';
import PlacesAutocomplete from 'react-places-autocomplete';
import React, { Fragment, useState } from 'react';
import LocationIcon from '@material-ui/icons/MyLocation';
import Geocode from 'react-geocode';
import styles from './LocationSearch.module.scss';
import Constants from '../../constants';

interface Props {
  onSelectPlace: (place: any) => void;
}

const LocationSearch: React.FC<Props> = ({ onSelectPlace }) => {
  const [place, setPlace] = useState<string>('');
  const [coordinates, setCoordinates] = useState<string>('');

  const handleSelectPlace = (place: any) => {
    setPlace(place);
    Geocode.setApiKey(Constants.GOOGLE_KEY);
    Geocode.fromAddress(place)
      .then((response) => {
        onSelectPlace(response.results[0]);
        setCoordinates('');
      })
      .catch((e) => {
        console.log(e);
        setPlace('');
        setCoordinates('');
      });
  };

  const handleAddByCoordinates = () => {
    Geocode.setApiKey(Constants.GOOGLE_KEY);
    Geocode.fromLatLng(coordinates.split(',')[0], coordinates.split(',')[1])
      .then((response) => {
        onSelectPlace(response.results[0]);
        setPlace('');
      })
      .catch((e) => {
        console.log(e);
        setCoordinates('');
        setPlace('');
      });
  };

  return (
    <>
      <div className={styles.createSidebarTitle}>
        <h4>Search for any location</h4>
      </div>
      <PlacesAutocomplete value={place} onChange={setPlace} onSelect={handleSelectPlace}>
        {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
          <label className={styles.createSidebarSearchField}>
            <input
              type="text"
              {...getInputProps()}
              placeholder="Empire State Building, New York"
              className={classnames(styles.createSidebarSearchFieldValue, {
                [styles.createSidebarSearchFieldValueHasValue]: place.length > 0,
              })}
            />
            <PlaceIcon className={styles.createSidebarSearchFieldLabel} />
            {suggestions.length > 0 && (
              <div className={styles.createSidebarSearchFieldOptions}>
                {suggestions.map((suggestion: any, index: any) => (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion)}
                    className={styles.createSidebarSearchFieldOptionsItem}
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            )}
          </label>
        )}
      </PlacesAutocomplete>
      <label className={styles.createSidebarSearchField}>
        <input
          type="text"
          onChange={(e) => setCoordinates(e.target.value)}
          onKeyPress={(e) => (e.charCode === 13 || e.keyCode === 13) && handleAddByCoordinates()}
          placeholder="40.7484405, -73.985664"
          value={coordinates}
          className={classnames(styles.createSidebarSearchFieldValue, {
            [styles.createSidebarSearchFieldValueHasValue]: coordinates.length > 0,
          })}
        />
        <LocationIcon className={styles.createSidebarSearchFieldLabel} />
      </label>
    </>
  );
};

export default LocationSearch;
