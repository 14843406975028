import React from 'react';

export const MovementIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.8 9.73346C3.97821 9.73346 4.93334 8.77834 4.93334 7.60013C4.93334 6.42192 3.97821 5.4668 2.8 5.4668C1.6218 5.4668 0.666672 6.42192 0.666672 7.60013C0.666672 8.77834 1.6218 9.73346 2.8 9.73346Z"
      fill="white"
    />
    <path
      d="M7.86666 11.3337C8.60304 11.3337 9.19999 10.7367 9.19999 10.0003C9.19999 9.26395 8.60304 8.66699 7.86666 8.66699C7.13028 8.66699 6.53333 9.26395 6.53333 10.0003C6.53333 10.7367 7.13028 11.3337 7.86666 11.3337Z"
      fill="white"
    />
    <path
      d="M8.13333 7.06699C9.90065 7.06699 11.3333 5.6343 11.3333 3.86699C11.3333 2.09968 9.90065 0.666992 8.13333 0.666992C6.36602 0.666992 4.93333 2.09968 4.93333 3.86699C4.93333 5.6343 6.36602 7.06699 8.13333 7.06699Z"
      fill="white"
    />
  </svg>
);
