import React from 'react';

export const UserProfileIcon = () => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.7 4C7.7 5.328 6.628 6.4 5.3 6.4C3.972 6.4 2.9 5.328 2.9 4C2.9 2.672 3.972 1.6 5.3 1.6C6.628 1.6 7.7 2.672 7.7 4ZM10.1 12.8H0.5V11.2C0.5 9.432 2.652 8 5.3 8C7.948 8 10.1 9.432 10.1 11.2V12.8ZM4.5 4C4.5 4.44 4.86 4.8 5.3 4.8C5.74 4.8 6.1 4.44 6.1 4C6.1 3.56 5.74 3.2 5.3 3.2C4.86 3.2 4.5 3.56 4.5 4ZM2.1 11.2H8.5C8.5 10.32 7.068 9.6 5.3 9.6C3.532 9.6 2.1 10.32 2.1 11.2ZM16.5 6.4V8H9.3V6.4H16.5ZM16.5 3.2V4.8H9.3V3.2H16.5ZM16.5 0V1.6H9.3V0H16.5Z"
      fill="white"
    />
  </svg>
);

export default UserProfileIcon;
