import React, { useMemo, useRef } from 'react';
import { useClickOutside, useToggle } from '../hooks';
import { ArrowIcon } from '../icons/reportItems';
import { SelectItem } from '../types/SelectItem.interface';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  items: SelectItem[];
  activeValue: string;
  onChange: (value: SelectItem) => void;
  customActiveItemClassName?: string;
  customSelectClassName?: string;
  customActiveSelectClassName?: string;
}
export const Select = ({
  items,
  activeValue,
  onChange,
  customActiveItemClassName,
  customActiveSelectClassName,
  customSelectClassName,
}: Props) => {
  const [isOpened, toggleIsOpened] = useToggle();
  const ref = useRef<HTMLDivElement>(null);

  const filteredItems = useMemo(() => items.filter((item) => item.value !== activeValue), [activeValue, items]);
  const activeItemValue = useMemo(() => items.find((item) => item.value === activeValue), [activeValue, items]);
  const hasItems = items.length > 0;

  const closeList = () => {
    toggleIsOpened(false);
  };

  const onChangeActiveItem = (value: SelectItem) => {
    onChange(value);
    closeList();
  };

  const { t } = useTranslation();

  useClickOutside(ref, closeList, isOpened);
  return (
    <div className={`${styles.wrapper} ${isOpened ? styles.active : ''}`} ref={ref}>
      <div className={`${styles.select} ${customSelectClassName || ''} ${isOpened ? customActiveSelectClassName : ''}`}>
        <div
          className={`${styles.activeItem} ${isOpened ? customActiveItemClassName : ''}`}
          onClick={hasItems ? () => toggleIsOpened() : undefined}
        >
          <span>{t(`${activeItemValue?.label}`)}</span>
          <ArrowIcon style={{ transform: `rotate(${isOpened ? '-180deg' : 0})` }} />
        </div>
        {items.length > 0 && (
          <div className={`${styles.list} ${isOpened ? styles.open : styles.close}`}>
            {filteredItems.map((item, index) => (
              <div
                className={`${styles.item} ${item.disabled ? styles.disabled : ''}`}
                key={item.value}
                onClick={() => (!item.disabled ? onChangeActiveItem(item) : null)}
                style={{ animationDelay: `${(isOpened ? index + 1 : filteredItems.length - (index + 1)) * 60}ms` }}
              >
                {t(item.label)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
