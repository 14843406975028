import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';
import { InformationIcon } from '../../common/icons/reportItems';
import { Button } from '../../common/controls/buttons';
import { Popup } from '../../common/popup';
import { PlaceSenseLogoIcon } from '../../common/icons/placenseLogo';
import styles from './stepFour.module.scss';

export const StepFour = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(true);

  const onProceedClick = () => {
    const prevUrl = localStorage.getItem('prevUrl');
    if (prevUrl) {
      history.push(prevUrl);
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    trackUserAction('Create report', UserActionsEnum.CREATE_REPORT, 'Step 4 - Popup');
  }, []);

  return (
    <Popup isOpen={isOpen} onClose={onProceedClick}>
      <div className={styles.popupContent}>
        <div className={styles.title}>{t('create.stepFour.almostReady')}</div>
        <div className={styles.subtitle}>{t('create.stepFour.thanksForCreating')}</div>
        <div className={styles.logo}>
          <PlaceSenseLogoIcon />
          <br />
          PlaceSense
        </div>
        <div className={styles.paragraph}>{t('create.stepFour.paragraph')}</div>
        <div className={styles.infoBlock}>
          <InformationIcon /> {t('create.stepFour.infobox')}
        </div>
        <div className={styles.buttons}>
          <Button onClick={onProceedClick}>{t('create.stepFour.proceed')}</Button>
        </div>
      </div>
    </Popup>
  );
};
