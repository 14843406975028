import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ReCaptchaV2 from 'react-google-recaptcha';
import { AppState } from '../../../../reducers/reducers';
import styles from './forgotPasswordPage.module.scss';
import { forgotPassword } from '../../../../actions/actions';
import { Page, Title, Message, ErrorMessage, EmailInput, SubmitButton, LinkButton } from '../common';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const errorMessage = useSelector<AppState, string | null>((state) => state.errorMessage.message);

  const forgotPasswordHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sessionStorage.setItem('forgotPasswordEmail', email);
    dispatch(forgotPassword(email, captchaToken || '', () => history.replace('/forgotPassword/thankYou')));
  };

  return (
    <Page>
      <form name="forgotPasswordForm" onSubmit={(e) => forgotPasswordHandler(e)}>
        <Title>Reset password</Title>
        <Message>Fill in your email below and we'll send you instructions on how to reset your password.</Message>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <EmailInput
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          error={!!(errorMessage && errorMessage !== '')}
        />
        <div className={styles.captcha}>
          <ReCaptchaV2
            sitekey="6Lek9iUdAAAAALhWqeKgMA_OnBFzdZPWGHbBV6S2"
            onChange={setCaptchaToken}
            onExpired={() => {
              setCaptchaToken(null);
            }}
          />
        </div>
        <SubmitButton text="Reset password" disabled={email === '' || !captchaToken || captchaToken === ''} />
        <LinkButton url="/" text="Login to a different account" />
      </form>
    </Page>
  );
};

export default ForgotPasswordPage;
