import { CrossVisitationData } from '../types';
import { PrimaryValue } from '../../interfaces';
import { toFixNumber } from '../../../common/lib';

export const getAllLocationsFromNestedLocations = (
  data: PrimaryValue<CrossVisitationData>[] = [],
  primaryLocationId: string,
) => {
  const primaryLocation = data.filter(({ primary_location_id }) => primary_location_id === primaryLocationId)[0];

  if (!primaryLocation) return null;

  const parsedSecondaryLocations = primaryLocation.data.map(
    ({ secondary_location_name, secondary_location_id, visitors_loyalty }) => ({
      location_id: secondary_location_id,
      location_name: secondary_location_name,
      value: `${toFixNumber(visitors_loyalty * 100, 2)}%`,
    }),
  );

  return [
    {
      location_id: primaryLocation.primary_location_id,
      location_name: primaryLocation.primary_location_name,
      value: '',
    },
    ...parsedSecondaryLocations,
  ];
};
