import React from 'react';

export const LogoIcon = () => (
  <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.4435 16.0651L13.4953 18.1367L20.3384 25.0711L17.6009 27.8425L8.79338 18.9212C7.99954 18.212 7.35993 17.3432 6.91505 16.3701C6.47017 15.3969 6.22968 14.3406 6.209 13.2682C6.209 11.3094 6.97666 9.4307 8.343 8.04557C9.70933 6.66044 11.5626 5.88228 13.495 5.88228C15.4273 5.88228 17.2805 6.66044 18.6471 8.04557C20.0134 9.4307 20.7811 11.3094 20.7811 13.2682C20.7604 14.3407 20.5199 15.397 20.075 16.3702C19.6299 17.3434 18.9901 18.2121 18.196 18.9212L17.5989 19.5259L14.8664 16.7533L15.5472 16.0651C16.3485 15.3519 16.8394 14.3466 16.9131 13.2682C16.901 12.3579 16.5357 11.489 15.8964 10.8496C15.257 10.2102 14.3949 9.85176 13.4968 9.85176C12.5989 9.85176 11.7368 10.2102 11.0975 10.8496C10.4581 11.489 10.0928 12.3579 10.0807 13.2682C10.1542 14.3464 10.6446 15.3515 11.4453 16.0651H11.4435ZM7.46995 20.3486L13.4979 26.4533L16.2337 29.2261L13.4966 32L4.82176 23.2059C2.83254 21.4205 1.42638 19.0638 0.790115 16.4489C0.153811 13.834 0.31742 11.0847 1.25923 8.56636C2.20107 6.04797 3.87632 3.87971 6.0629 2.3496C8.24926 0.819494 10.8433 0 13.5001 0C16.1569 0 18.7507 0.819494 20.9373 2.3496C23.1236 3.87971 24.7991 6.04797 25.7408 8.56636C26.6827 11.0847 26.8462 13.834 26.2099 16.4489C25.5736 19.0638 24.1674 21.4205 22.1784 23.2059L21.7049 23.6853L18.9712 20.9138L19.5269 20.3512C20.5192 19.4639 21.3164 18.375 21.8667 17.1552C22.4169 15.9354 22.7078 14.612 22.7208 13.2708C22.7008 10.805 21.7206 8.44724 19.9934 6.71086C18.2664 4.97451 15.9324 4.00026 13.5001 4.00026C11.0675 4.00026 8.73375 4.97451 7.00658 6.71086C5.27962 8.44724 4.29921 10.805 4.27918 13.2708C4.29195 14.6112 4.58238 15.9339 5.13177 17.1532C5.68117 18.3725 6.47721 19.4612 7.46819 20.3486H7.46995Z"
      fill="#00B2F1"
    />
  </svg>
);
