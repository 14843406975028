import { $isLoading, $reportItemsLoadStatuses, $areAllReportItemsLoaded } from './stores';
import { toggleDashboardLoading, toggleReportItemsLoadStatus } from './events';

export const dashboardModel = {
  $isLoading,
  $reportItemsLoadStatuses,
  $areAllReportItemsLoaded,
  toggleDashboardLoading,
  toggleReportItemsLoadStatus,
};
