import { applyMiddleware, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { AppActions } from '../actions/actionTypes';
import createRootReducer, { AppState } from '../reducers/reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const history = createBrowserHistory();

export function buildStore(preloadedState?: any): Store<AppState, AppActions> {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const rootReducer = createRootReducer(history);
  // @ts-ignore
  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
      ),
    ),
    // composeEnhancers(
    //     applyMiddleware(
    //         routerMiddleware(history),
    //         thunk)
    //     // routerMiddleware(history), // for dispatching history actions
    //     // applyMiddleware(thunk)
    // )
  );

  // // const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  // const composeEnhancer: typeof compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  // const store = createStore(
  //     createRootReducer(history),
  //     preloadedState,
  //     composeEnhancer(
  //         applyMiddleware(
  //             routerMiddleware(history),
  //         ),
  //     ),
  // );
  // return store
}

// export function buildStore(preloadedState?: any) {
//   const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
//   const store = createStore(
//       createRootReducer(history),
//       preloadedState,
//       composeEnhancer(
//           applyMiddleware(
//               routerMiddleware(history),
//           ),
//       ),
//   )
//
//   // // Hot reloading
//   // if (module.hot) {
//   //   // Enable Webpack hot module replacement for reducers
//   //   module.hot.accept('./reducers', () => {
//   //     store.replaceReducer(createRootReducer(history));
//   //   });
//   // }
//
//   return store
// }
