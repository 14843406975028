import { API_URLS } from '../../routes';
import { request } from '../../api';
import { CatchmentData, PolygonData } from './types';
import { mapResponse } from './multiLocationMapper';
import { Catchment, LocationInfo, ReportItemInfo, ReportItems } from '../../common/types/projectsHierarchy';
import { isValidDateOfReport } from '../utils';

export const fetchCatchmentMultiData = async (
  reportItemId: string,
  locations: string[],
  timeFrames: string[] | null,
) => {
  const timeFramesQueryParam = timeFrames !== null ? `timeFrames=${timeFrames.join(',')}` : '';
  const locationsQueryParam = `locations=${locations.join(',')}`;
  const queryParams = [locationsQueryParam, timeFramesQueryParam].join('&');
  const url = `${API_URLS.REPORT_ITEMS_DATA}/${reportItemId}/multi/data?${queryParams}`;
  return request<CatchmentData>({ url });
};

export const fetchPolygons = async (indexes: Set<string>, reportItemId: string) => {
  const url = `${API_URLS.REPORT_ITEMS_DATA}/${reportItemId}/data/indexes`;
  return request<PolygonData>({ url, method: 'POST', data: Array.from(indexes) });
};

export const getCatchmentMultiData = async (
  reportItemId: string,
  selectedLocations: LocationInfo[],
  reportItemData: ReportItemInfo<Catchment>,
  updateReportItemOfSelectedReport: (item: ReportItemInfo<ReportItems>, forPrimaryLocationId: string | null) => void,
  timesFrames: string[] | null,
  updateLoader: (visible: boolean, label?: string, progress?: number) => void,
) => {
  try {
    if (reportItemId && selectedLocations.length) {
      const locationsIds = selectedLocations.map((item) => item.id);
      const catchmentMultiResponse = await fetchCatchmentMultiData(reportItemId, locationsIds, timesFrames);
      updateLoader(true, 'Processing catchment data...', 30);
      const catchmentData = catchmentMultiResponse;
      if (catchmentData?.values.length > 0) {
        const indexes = new Set<string>();
        catchmentData.values.forEach((value) => {
          value.home.forEach((i) => indexes.add(i.index));
          value.work.forEach((i) => indexes.add(i.index));
        });

        updateLoader(true, 'Fetching catchment polygons...', 60);
        const polygonsResponse = await fetchPolygons(indexes, reportItemId);
        const data = mapResponse(catchmentData, polygonsResponse, selectedLocations);
        const dateToUse = catchmentMultiResponse.created_date;
        const showGallery = dateToUse ? isValidDateOfReport(dateToUse) : false;
        const mergedData =
          reportItemData.data && reportItemData.visualization
            ? ({
                ...data,
                data: {
                  reportLocations: catchmentMultiResponse.reportLocations,
                  countries: catchmentMultiResponse.countries,
                  catchmentLevel: catchmentMultiResponse.catchmentLevel,
                  showGallery,
                  data: data.data?.data,
                },
                visualization: {
                  ...reportItemData.visualization,
                  multiLocationPolygons: data.visualization?.multiLocationPolygons,
                },
              } as ReportItemInfo<Catchment>)
            : data;

        updateReportItemOfSelectedReport(mergedData, null);
      } else if (timesFrames?.length === 0) {
        if (catchmentData) {
          updateReportItemOfSelectedReport(
            {
              ...catchmentData,
              data: null,
              visualization: null,
              settings: [],
            } as ReportItemInfo<Catchment>,
            null,
          );
        }
      }
    }
  } catch (error) {}
};
