import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CrossVisitationChartValues } from '../../../../../common/reportItems/types';
import styles from './style.module.scss';

interface Props {
  data: {
    value: string;
    primaryLocation: string;
    secondaryLocation: string;
    distance: string;
    ratio: string;
    chartVal: string;
  };
}
export const Tooltip = forwardRef<HTMLDivElement, Props>(
  ({ data: { value, secondaryLocation, primaryLocation, distance, ratio, chartVal } }, ref) => {
    const { t } = useTranslation();
    return (
      <div ref={ref} className={styles.tooltip}>
        {chartVal !== CrossVisitationChartValues.VISIT_RATIO && (
          <div>
            {`${value} ${t('crossVisitation.detail.of')} ${primaryLocation} ${t('crossVisitation.detail.visitorsAlsoVisit')} ${secondaryLocation}`}
          </div>
        )}
        {chartVal !== CrossVisitationChartValues.CROSS_VISITATION && (
          <div>{`${t('crossVisitation.detail.forEvery')} ${primaryLocation} ${t(
            'crossVisitation.detail.thereAre',
          )} ${ratio} ${t('crossVisitation.detail.visitsTo')} ${secondaryLocation}`}</div>
        )}
        {distance && distance !== '' && (
          <div>
            {t('crossVisitation.detail.distanceBetweenLocations')}: {distance} km
          </div>
        )}
      </div>
    );
  },
);
