import React from 'react';
import { Marker } from 'react-map-gl';
import { separateThousandsByComma } from '../../utils';
import BarChart from './barChart';
import styles from './multiBarPin.module.scss';
import { ChartValue } from './chartPinTypes';

interface ChartPinProps {
  longitude: number;
  latitude: number;
  chartValues: ChartValue[];
  name?: string;
  onClick?: () => void;
  maxValue?: number;
  unitLabel?: string;
}

const IDENTIFIER_NAME = 'chartPin';
const MultiBarPin: React.FC<ChartPinProps> = ({
  latitude,
  longitude,
  chartValues,
  onClick,
  name,
  maxValue,
  unitLabel,
}) => (
  <Marker longitude={longitude} latitude={latitude}>
    <div className={styles.root} onClick={onClick} data-identifier={IDENTIFIER_NAME}>
      <div className={styles.pin} data-identifier={IDENTIFIER_NAME}>
        <BarChart
          data={chartValues}
          name={name || 'Unnamed area'}
          percentage
          maxValue={maxValue}
          barWidth={8}
          unitLabel={unitLabel}
        />
      </div>
      {/* <div className={styles.numericValue}>{chartValues[0]?.name}</div> */}
    </div>
  </Marker>
);

export default React.memo(MultiBarPin);
