import React from 'react';

export const CatchmentTripsIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.52 6.44016L3.57333 7.38683C3.12 6.92683 2.68 6.3335 2.38 5.42683L3.67333 5.10016C3.88667 5.6935 4.18667 6.10016 4.52 6.44016ZM5.33333 3.00016L2.66667 0.333496L0 3.00016H2.01333C2.02667 3.54016 2.06667 4.02683 2.14 4.44683L3.43333 4.12016C3.38667 3.80016 3.35333 3.42016 3.34667 3.00016H5.33333ZM12 3.00016L9.33333 0.333496L6.66667 3.00016H8.66C8.59333 5.4535 7.80667 6.16683 6.96667 6.92016C6.63333 7.2135 6.29333 7.5335 6 7.9535C5.77333 7.62683 5.51333 7.36683 5.24667 7.12683L4.30667 8.06683C4.92667 8.6335 5.33333 9.0935 5.33333 10.3335V13.6668H6.66667V10.3335C6.66667 8.98683 7.14 8.56016 7.86 7.9135C8.78 7.08683 9.91333 6.06016 9.99333 3.00016H12Z"
      fill="white"
    />
  </svg>
);
