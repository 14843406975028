import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Catchment, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { MainContext } from '../../../common/types/mainContext';
import ReportItemCard from '../../../common/reportItems/reportItemCard';
import { CatchmentLocationsCountEnum, ReportItemType } from '../../../common/reportItems/types';
import { getCatchmentData } from '../services';
import { useToggle, useURLParams } from '../../../common/hooks';
import { maxLengthOfString } from '../../../common/utils';
import { CatchmentMainTooltip } from './tooltip';
import { dashboardModel } from '../../../dasboardLoader';
import { exportSettingsModel } from '../../../common/export';
import { useTooltipStartPosition } from '../../../common/reportItems/hooks';
import { LocationsCountDropdown } from './controls/locationsCountDropdown';
import { getCatchmentMultiData } from '../multiLocationServices';
import { REQUEST_DELAY, REQUEST_SHORT_DELAY } from '../../types';
import { FRONTEND_BASE_VERSION } from '../../../common/utils/versions/const';

const CatchmentCard = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReport, selectedReportItem },
    reportItemsDetails: {
      catchmentTreshold,
      catchmentType,
      colorScheme,
      catchmentDataOverlay,
      catchmentMetricType,
      catchmentSocioDemoMetric,
      catchmentLocationsCount,
    },
    primaryLocationId,
    selectedTimeFrames,
    selectedLocations,
    updateReportItemOfSelectedReport,
    updateDashboardLoader,
    updateReportItemsDetailsValue,
  } = useContext(MainContext);
  const [isMenuOpened, toggleMenu] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useToggle(false);
  const [fetched, setFetched] = useToggle(true);
  const [isTooltipOpen, toggleTooltipOpen] = useToggle(false);
  const { tooltipIconRef, position } = useTooltipStartPosition();
  const { isParsingDone, params, onChangeParams } = useURLParams();

  const catchmentVersion = selectedReport?.version?.catchment || FRONTEND_BASE_VERSION;

  // eslint-disable-next-line
  const CatchmentMenu = require(`./${catchmentVersion}/catchmentMenu`).default;

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.CATCHMENT,
  ) as ReportItemInfo<Catchment>;

  const topArea = useMemo(
    () =>
      reportItem?.data?.data
        ?.find((location) => location.location_id === primaryLocationId)
        ?.home.reduce((res, i) => (res.geo_entity_share_of_target > i.geo_entity_share_of_target ? res : i)),
    [reportItem?.data?.data, primaryLocationId],
  );

  const updateLoader = (visible: boolean, label?: string, progress?: number) => {
    updateDashboardLoader(ReportItemType.CATCHMENT, visible, label, progress);
  };

  useEffect(() => {
    if (isParsingDone && topArea) {
      exportSettingsModel.updateReportItemsInformation({
        catchment: {
          distanceFromLocation: topArea.driving_distance,
          visitsShare: topArea.geo_entity_share_of_target,
          topArea: topArea.geo_entity_name,
          drivingTime: topArea.driving_time,
          aerialDistance: topArea.aerial_distance,
          header: t(`catchment.enum.${catchmentMetricType}`),
        },
      });
    }
  }, [topArea, isParsingDone, catchmentMetricType]);

  // fetch data for Single location catchment
  useEffect(() => {
    if (isParsingDone) {
      (async function () {
        if (
          reportItem &&
          primaryLocationId &&
          !isLoading &&
          catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE
        ) {
          setFetched(false);
          setIsLoading(true);
          if (selectedReportItem?.type === ReportItemType.CATCHMENT) {
            dashboardModel.toggleDashboardLoading(true);
          }
          dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.CATCHMENT, value: false });
          if (selectedReportItem?.type !== ReportItemType.CATCHMENT) {
            const delay = selectedReportItem?.type === ReportItemType.MOVEMENT ? REQUEST_DELAY : REQUEST_SHORT_DELAY;
            await new Promise((resolve) => setTimeout(resolve, delay));
          }
          await getCatchmentData(
            reportItem.id,
            primaryLocationId,
            reportItem,
            updateReportItemOfSelectedReport,
            updateReportItemsDetailsValue,
            selectedTimeFrames,
            updateLoader,
            selectedReport?.created_date,
          );
          setFetched(true);
          setIsLoading(false);
          dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.CATCHMENT, value: true });
          if (selectedReportItem?.type === ReportItemType.CATCHMENT) {
            dashboardModel.toggleDashboardLoading(false);
          }
        }
      })();
    }
  }, [primaryLocationId, selectedTimeFrames, isParsingDone, catchmentLocationsCount]);

  // fetch data for Multi location catchment
  useEffect(() => {
    if (isParsingDone) {
      (async function () {
        if (
          reportItem &&
          selectedLocations &&
          !isLoading &&
          catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE
        ) {
          setFetched(false);
          setIsLoading(true);
          if (selectedReportItem?.type === ReportItemType.CATCHMENT) {
            dashboardModel.toggleDashboardLoading(true);
          }
          dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.CATCHMENT, value: false });
          if (selectedReportItem?.type !== ReportItemType.CATCHMENT) {
            await new Promise((resolve) => setTimeout(resolve, REQUEST_DELAY));
          }
          await getCatchmentMultiData(
            reportItem.id,
            selectedLocations,
            reportItem,
            updateReportItemOfSelectedReport,
            selectedTimeFrames,
            updateLoader,
          );
          setFetched(true);
          setIsLoading(false);
          dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.CATCHMENT, value: true });
          if (selectedReportItem?.type === ReportItemType.CATCHMENT) {
            dashboardModel.toggleDashboardLoading(false);
          }
        }
      })();
    }
  }, [selectedTimeFrames, selectedLocations, isParsingDone, catchmentLocationsCount]);

  useEffect(() => {
    if (isParsingDone) {
      if (selectedReportItem?.type === ReportItemType.CATCHMENT) {
        const {
          catchmentType: catchmentTypeParam,
          catchmentThresholdMin,
          catchmentThresholdMax,
          colorScheme: colorSchemeParam,
          catchmentLocationsCount: catchmentLocationsCountParam,
          catchmentDataOverlay: catchmentDataOverlayParam,
          catchmentMetricType: catchmentMetricTypeParam,
          catchmentSocioDemoMetric: catchmentSocioDemoMetricParam,
        } = params;
        if (!catchmentTypeParam) {
          onChangeParams('catchmentType', catchmentType);
        }
        if (!(catchmentThresholdMin && catchmentThresholdMax)) {
          const { min, max } = catchmentTreshold;
          onChangeParams('catchmentThresholdMin', min);
          onChangeParams('catchmentThresholdMax', max);
        }
        if (!colorSchemeParam) {
          onChangeParams('colorScheme', colorScheme);
        }
        if (!catchmentLocationsCountParam) {
          onChangeParams('catchmentLocationsCount', catchmentLocationsCount);
        }
        if (!catchmentDataOverlayParam) {
          onChangeParams('catchmentDataOverlay', catchmentDataOverlay ? 'YES' : 'NO');
        }
        if (!catchmentMetricTypeParam) {
          onChangeParams('catchmentMetricType', catchmentMetricType);
        }
        if (!catchmentSocioDemoMetricParam) {
          onChangeParams('catchmentSocioDemoMetric', catchmentSocioDemoMetric);
        }
      }
    }
  }, [isParsingDone, selectedReportItem?.type]);

  const onCloseTooltip = () => {
    toggleTooltipOpen(false);
  };

  const noData = useMemo(() => fetched && (!reportItem || !reportItem.data), [reportItem, fetched, isLoading]);
  const availableTypes = selectedReport?.report_items?.map((value) => value.type);

  return !availableTypes?.includes(ReportItemType.CATCHMENT) ? (
    <div />
  ) : (
    <ReportItemCard
      reportItemType={ReportItemType.CATCHMENT}
      values={[
        { name: t('catchment.card.topArea'), value: `${maxLengthOfString(topArea?.geo_entity_name || '', 20)}` },
        {
          name: t('catchment.card.visitsShare'),
          value: `${((topArea?.geo_entity_share_of_target || 0) * 100).toFixed(2)}`,
        },
        ...(topArea?.driving_time
          ? [{ name: t('catchment.card.drivingTime'), value: `${topArea?.driving_time || ''} ${t('reportItem.min')}` }]
          : []),
        ...(topArea?.aerial_distance
          ? [
              {
                name: t('catchment.card.distanceFromLocation'),
                value: `${topArea?.aerial_distance.toFixed(2) || ''} ${t('reportItem.km')}`,
              },
            ]
          : []),
      ]}
      reportItem={reportItem}
      isLoading={isLoading}
      noData={noData}
      onHeaderMenuClick={() => {
        toggleMenu((isOpened) => !isOpened);
      }}
      isHeaderMenuOpened={isMenuOpened}
      onOpenTooltip={toggleTooltipOpen}
      tooltipRef={tooltipIconRef}
      additionalInformation={
        catchmentVersion !== FRONTEND_BASE_VERSION ? <LocationsCountDropdown isLoading={isLoading} /> : null
      }
    >
      <>
        <CatchmentMainTooltip onClose={onCloseTooltip} position={position} isOpen={isTooltipOpen} />
        <CatchmentMenu onClose={() => toggleMenu(false)} isMenuOpened={isMenuOpened} />
      </>
    </ReportItemCard>
  );
};

export default CatchmentCard;
