import React from 'react';

interface Props {
  onClick?: () => void;
}

export const PlusIcon = (props: Props) => (
  <svg
    onClick={props.onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#00B2F1"
    />
    <path d="M13.4 5H10.6V10.6H5V13.4H10.6V19H13.4V13.4H19V10.6H13.4V5Z" fill="white" />
  </svg>
);
