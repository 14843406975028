import { rewind } from '@turf/turf';
import { MAP_TOKEN } from '../mapBase/map.config';
import { MAPBOX_API_URL } from '../../../../api';

export const generateMapboxPictureURL = (
  latitude: number | null,
  longitude: number | null,
  zoom: number,
  geometry: any,
) => {
  const data: any = {
    type: 'FeatureCollection',
    features: [geometry],
  };

  const pictureWidth = '300';
  const pictureHeight = '200';
  const mapTheme = 'placense/cl370cezm00d314p6dnfly1cu';
  const mapType = 'static';
  const geoParam = `geojson(${JSON.stringify(rewind(data))})`;
  const tokenParam = `access_token=${MAP_TOKEN}`;

  return `${MAPBOX_API_URL}/${mapTheme}/${mapType}/${geoParam}/${longitude},${latitude},${zoom}/${pictureWidth}x${pictureHeight}?${tokenParam}`;
};
