import { generateLocationMapImageStyles } from './generateLocationMapImageStyles';
import { generateMapboxPictureURL } from '../../maps/utils';
import { LocationInfo } from '../../types/projectsHierarchy';
import { getPolygonsByPoints } from '../../../mainPage/backgroundMap/polygons/utils';
import { getFitBoundsFromGeo } from '../../maps/mapBase/utils/getFitBoundsFromGeo';

export const addImageUrlToLocations = (locations: LocationInfo[], width = 300, height = 200) => {
  const locationsWithMapStyles = generateLocationMapImageStyles(locations);
  return locationsWithMapStyles.map((location) => {
    const { polygons } = location;

    if ('geometry' in polygons) {
      const geo = getPolygonsByPoints([{ geo: polygons }]);

      const { zoom, longitude, latitude } = getFitBoundsFromGeo(width, height, geo);

      return {
        ...location,
        image: generateMapboxPictureURL(latitude, longitude, zoom, polygons),
      };
    }

    return location;
  });
};
