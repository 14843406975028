import React, { ReactElement, useEffect, useState } from 'react';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Page from '../../templates/page/page';
import styles from './usersPage.module.css';
import routes from '../../../router/routes';
import { AppState } from '../../../reducers/reducers';
import { AuthState } from '../../../reducers/authReducer';
import { fetchUsers } from '../../../actions/actions';
import { userRoleTranslationMap } from '../../../types/UserRole';
import ErrorMessage from '../../organisms/errorMessage';
import AccountFilter from '../../molecules/dropdowns/accountFilter/accountFilter';
import EditUserModal from '../../organisms/EditUserModal';

const normalizeString = (value: string): string => (!value || value.trim() === '' ? '-' : value);

const DATE_FORMAT = 'YYYY/MM/DD';

const UsersPage = (): ReactElement => {
  const defaultSelectedAccounts: Option[] =
    localStorage.getItem('accountFilter') === null ? [] : JSON.parse(localStorage.getItem('accountFilter') || '');

  const [selectedAccounts, setSelectedAccounts] = useState<Option[]>(defaultSelectedAccounts);
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState<boolean>(false);
  const [editedUserId, setEditedUserId] = useState<string>('');
  const { users } = useSelector<AppState, AuthState>((state) => state.auth);
  const history = useHistory();
  const addUserHandler = () => history.push(routes.users.add);
  const editUserHandler = (userId: string) => {
    history.push({ pathname: routes.users.update.withId(userId), state: { userId } });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers(selectedAccounts.map((account) => account.value)));
  }, [JSON.stringify(selectedAccounts)]);

  const openEditUserModal = (e: any, userId: string) => {
    e.preventDefault();
    setEditedUserId(userId);
    setIsEditUserModalVisible(true);
  };

  const closeEditUserModal = (): void => {
    setEditedUserId('');
    setIsEditUserModalVisible(false);
  };

  return (
    <Page title="Users" onAddUser={addUserHandler}>
      <ErrorMessage />
      {isEditUserModalVisible && (
        <EditUserModal
          onClose={closeEditUserModal}
          onSave={closeEditUserModal}
          userId={editedUserId}
          isVisible={isEditUserModalVisible}
        />
      )}
      <div className={styles.header}>
        <AccountFilter onChange={setSelectedAccounts} />
      </div>
      <div>
        {!!users && users.length > 0 && (
          <table className={styles.usersTable}>
            <thead>
              <tr>
                <th>User name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th>Created</th>
                <th>Account</th>
              </tr>
            </thead>
            <tbody>
              {users
                .sort((a, b) =>
                  (a.accountName + a.email).toLowerCase().localeCompare((b.accountName + b.email).toLowerCase()),
                )
                .map((user) => (
                  <tr key={user.userId} onClick={(e) => editUserHandler(user.userId)}>
                    <td>{normalizeString(`${user.firstName || ''} ${user.lastName || ''}`)}</td>
                    <td>{normalizeString(user.email)}</td>
                    <td>{user.role ? userRoleTranslationMap.get(user.role) : '-'}</td>
                    <td>{normalizeString(user.status)}</td>
                    <td>{user.created && `${moment(user.created).format(DATE_FORMAT)}`}</td>
                    <td>{normalizeString(user.accountName)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </Page>
  );
};

export default UsersPage;
