import { userSignUp } from './effects';
import { errorOccurred } from './events';
import { $isSignUpSuccessful, $userData, $error } from './stores';

export const signUpModel = {
  userSignUp,
  $isSignUpSuccessful,
  $userData,
  $error,
  errorOccurred,
};
