import { LocationHourlySeasonality } from '../../../../common/types/projectsHierarchy';
import { toFixNumber } from '../../../../common/lib';
import {numberToWeekday} from "../../../../../function/numberToWeekday";

/** Returns reduced seasonality data for .xlsx file generated by general export feature **/
export const convertDataForExcel = (data: LocationHourlySeasonality[]) => {
  const entities = data.map(({ data, locationName }) => {
    return data.map(({ hourOfDay, visitsShare, dayOfWeekNumber }) => {
      return {
        location: locationName,
        hour_day: String(hourOfDay),
        week_day: numberToWeekday(dayOfWeekNumber),
        visits_share: `${toFixNumber(visitsShare * 100, 2)}%`,
      };
    });
  });
  return entities.reduce((acc, curr) => [...acc, ...curr], []);
};
