import React, { useContext } from 'react';
import { MainContext } from '../../../common/types/mainContext';
import { SwitchModes } from '../../../common/types/visualizationObjects';
import { DeckHeatmap } from '../heatmaps/movement/deckHeatmap';
import { CenterPoint } from '../../backgroundMap/points/centerPoint';
import { HotspotPolygons } from '../polygons/HotspotPolygons';
import { OverlayHotspotsEnum } from '../../../common/reportItems/types';
import { IReportCategory } from '../../../../types/report';
import { MovementHexagons } from '../polygons/MovementHexagons';

const MovementSwitch = () => {
  const {
    movementMode,
    selection: { selectedReport },
    reportItemsDetails: { hotspotOverlay },
  } = useContext(MainContext);

  return (
    <>
      <CenterPoint showName />
      {movementMode === SwitchModes.HOTSPOTS && (
        <>
          {hotspotOverlay === OverlayHotspotsEnum.YES &&
            (selectedReport?.category === IReportCategory.AOI_DEFAULT ? <MovementHexagons /> : <DeckHeatmap />)}
          <HotspotPolygons />
        </>
      )}
      {movementMode === SwitchModes.HEATMAP &&
        (selectedReport?.category === IReportCategory.AOI_DEFAULT ? <MovementHexagons /> : <DeckHeatmap />)}
    </>
  );
};

export default MovementSwitch;
