import React, { useMemo } from 'react';
import { EChartsOption } from 'echarts';
import { TEXT_STYLE } from '../../../common/chart/configuration';
import { Chart } from '../../../common/chart/chart';
import styles from './singleBarPin.module.scss';
import { ChartValue } from './chartPinTypes';

function getBarChartOptions(
  data: ChartValue[],
  name: string,
  percentage: boolean, // if true, values are multiplied by 100
  unitLabel: string,
  maxValue?: number,
  barWidth?: number,
): EChartsOption {
  return {
    grid: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      type: 'category',
      data: data.map((i) => i.name),
      axisLabel: {
        show: false,
      },
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
    },
    yAxis: {
      type: 'value',
      nameLocation: 'middle',
      axisLabel: {
        show: false,
      },
      splitLine: { show: false },
      splitArea: { show: false },
      nameTextStyle: {
        ...TEXT_STYLE,
      },
      min: 0,
      max: maxValue || 'dataMax',
    },
    tooltip: {
      appendToBody: true,
      borderColor: 'rgba(255, 255, 255, 0.3)',
      className: styles.barChartTooltip,
      backgroundColor: '##1F303D',
      textStyle: {
        color: '#FFFFFF',
      },
      formatter(params: any) {
        const displayedValue = percentage ? (+params.value).toFixed(2) : (+params.value).toFixed(0);
        let toolTipHtml = `<span>${name}</span> <span>${params.name}: </span>`;
        toolTipHtml += `<b>${displayedValue}${unitLabel}</b>`;
        return toolTipHtml;
      },
    },
    series: {
      type: 'bar',
      itemStyle: {
        borderRadius: [3, 3, 0, 0],
      },
      emphasis: {
        itemStyle: {
          color: '#00B2F1',
        },
      },
      barWidth: barWidth || undefined,
      data: data.map((item) => ({
        value: percentage ? item.value * 100 : item.value,
        itemStyle: {
          color: item.color || '#aaa',
        },
      })),
    },
  };
}

interface Props {
  name: string;
  data: ChartValue[];
  maxValue?: number;
  percentage?: boolean;
  unitLabel?: string;
  barWidth?: number;
}

const BarChart: React.FC<Props> = ({ data, name, maxValue, percentage = false, unitLabel = '', barWidth }) => {
  const options = useMemo(
    () => getBarChartOptions(data, name, percentage, unitLabel, maxValue, barWidth),
    [data, name, maxValue, percentage],
  );
  return (
    <div className="chart-container" style={{ height: '65px' }}>
      <Chart option={{ ...options }} />
    </div>
  );
};

export default BarChart;
