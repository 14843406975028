import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import Dropdown from 'react-dropdown';
import Page from '../../templates/page/page';
import { AppState } from '../../../reducers/reducers';
import { SettingDefinitionsForItems } from '../../../types/reportItemSettingDefinition';
import {
  editReportItemSettingDefinitions,
  loadReportItemSettingDefinitions,
  loadReportItemTypes,
} from '../../../actions/actions';
import EditReportItemSettingDefinitionsForm from '../../organisms/reportItemSettingDefinitionsForm/EditReportItemSettingDefinitionsForm';
import { displayNameForReportItemType } from '../../../reportItemTypes';
import styles from './reportItemSettingDefinitionsPage.module.css';

interface StateProps {
  settings?: SettingDefinitionsForItems;
  savedAt: Moment;
  reportItemTypes?: string[];
}

interface DispatchProps {
  loadReportItemSettingDefinitions: (reportCategory: string) => void;
  editReportItemSettingDefinitions: (reportCategory: string, settings: SettingDefinitionsForItems) => void;
  loadReportItemTypes: (reportCategory: string) => void;
}

interface State {
  reportCategory: string;
}

const reportCategoryOptions = ['POI_DEFAULT', 'AOI_DEFAULT', 'OOH'];
const defaultReportCategoryOption = reportCategoryOptions[0];

class ReportItemSettingDefinitionsPage extends Component<StateProps & DispatchProps, State> {
  constructor(props: Readonly<StateProps & DispatchProps>) {
    super(props);
    this.state = {
      reportCategory: defaultReportCategoryOption,
    };
  }

  componentDidMount(): void {
    this.props.loadReportItemSettingDefinitions(this.state.reportCategory);
    this.props.loadReportItemTypes(this.state.reportCategory);
  }

  componentDidUpdate(prevProps: Readonly<StateProps & DispatchProps>, prevState: Readonly<State>, snapshot?: any) {
    if (prevState.reportCategory !== this.state.reportCategory) {
      this.props.loadReportItemTypes(this.state.reportCategory);
      this.props.loadReportItemSettingDefinitions(this.state.reportCategory);
    }
  }

  render() {
    return (
      <Page title="Report item settings">
        {this.renderReportCategory()}
        <hr className={styles.delimiter} />
        {this.renderReportItemSettingGroups()}
      </Page>
    );
  }

  private renderReportCategory() {
    return (
      <div className={styles.formContainer}>
        <div className={styles.categoryRow}>
          <div className={styles.fieldName}>Report Category:</div>
          <div className={styles.valueContainer}>
            <Dropdown
              options={reportCategoryOptions}
              onChange={(event) => this.setState({ reportCategory: event.value })}
              value={defaultReportCategoryOption}
              placeholder="Select report category"
            />
          </div>
        </div>
      </div>
    );
  }

  private renderReportItemSettingGroups() {
    if (this.props.settings && this.props.reportItemTypes) {
      return this.props.reportItemTypes.map((reportItemType) => {
        const settings = (this.props.settings || {})[reportItemType] || [];
        const settingsForOtherItems = (this.props.reportItemTypes || [])
          .filter((type) => type !== reportItemType)
          .map((type) => ({ [`${type}`]: (this.props.settings || {})[type] }));
        const settingsForOtherItemsAsSingleObject = Object.assign({}, ...settingsForOtherItems);
        const reportItemTitle = reportItemType.substr(reportItemType.indexOf('.') + 1);
        return (
          <div key={reportItemType}>
            <h2>{displayNameForReportItemType(reportItemTitle)}</h2>
            <EditReportItemSettingDefinitionsForm
              reportCategory={this.state.reportCategory}
              initialSettings={settings.sort()}
              savedAt={this.props.savedAt}
              onSave={(newSettings) =>
                this.props.editReportItemSettingDefinitions(this.state.reportCategory, {
                  ...settingsForOtherItemsAsSingleObject,
                  [`${reportItemType}`]: newSettings,
                })
              }
            />
          </div>
        );
      });
    }
    return null;
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  settings: state.reportItemSettingDefinitions.settings,
  savedAt: state.reportItemSettingDefinitions.savedAt,
  reportItemTypes: state.reportItemTypes.types?.sort(),
});

const mapDispatchToProps: DispatchProps = {
  loadReportItemSettingDefinitions,
  editReportItemSettingDefinitions,
  loadReportItemTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportItemSettingDefinitionsPage);
