import React, { useRef } from 'react';
import styles from './style.module.scss';
import { useClickOutside } from '../hooks';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  initialPosition?: any;
  height?: number;
  style?: any;
}
export const Popup: React.FC<Props> = ({ children, onClose, isOpen, initialPosition, height, style }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useClickOutside(elementRef, onClose, isOpen);
  return (
    <div className={`${styles.wrapper} ${isOpen ? styles.open : styles.close}`} style={style}>
      <div className={`${styles.background}`} />
      <div
        className={styles.position}
        ref={elementRef}
        style={{
          left: isOpen ? `50%` : `${initialPosition?.left}px`,
          top: isOpen ? `50%` : `${initialPosition?.top}px`,
          height: isOpen ? `${height || 450}px` : `0px`,
        }}
      >
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
