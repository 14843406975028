import React, { useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useStore } from 'effector-react';
import styles from './yearMonthPicker.module.scss';
import Menu from './Menu';
import { MainContext } from '../types/mainContext';
import { TimeFrames } from './types';
import { SelectedTimeFrames } from '../types/projectsHierarchy';
import YearMonthLabel from './Menu/YearMonthLabel';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';
import { dashboardModel } from '../../dasboardLoader';
import { Skeleton } from '../skeleton';
import { getYearsGap } from './utils';
import { useURLParams } from '../hooks';

const YearMonthPicker = () => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [reportStartDate, setReportStartDate] = useState<Moment | null>();
  const [reportEndDate, setReportEndDate] = useState<Moment | null>();
  const [timeFrames, setTimeFrames] = useState<TimeFrames>({});
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(null);
  const isGlobalLoading = useStore(dashboardModel.$isLoading);
  const { onChangeParams } = useURLParams();
  const {
    selection: { selectedReport },
    selectedTimeFrames,
    setSelectedTimeFrames,
  } = useContext(MainContext);

  useEffect(() => {
    if (selectedReport) {
      const { start_date, end_date } = selectedReport;
      const { timeFrames, startDate, endDate } = getYearsGap(start_date, end_date);

      if (selectedTimeFrames) {
        Object.keys(timeFrames).map((timeFrame) =>
          Object.assign(timeFrames, { [timeFrame]: selectedTimeFrames.includes(timeFrame) }),
        );
      }
      if (!selectedStartDate || selectedStartDate.diff(startDate, 'minutes') !== 0) {
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
      }
      setReportStartDate(startDate);
      setReportEndDate(endDate);

      setTimeFrames(timeFrames);
    }
  }, [selectedReport]);

  const getSelectedTimeFrames = (timeFrames: TimeFrames): SelectedTimeFrames => {
    if (selectedReport && reportStartDate && reportEndDate) {
      const reportBeginYear = Number.parseInt(reportStartDate.format('YYYY'), 10);
      const reportEndYear = Number.parseInt(reportEndDate.format('YYYY'), 10);
      const selectedStartYear = selectedStartDate && Number.parseInt(selectedStartDate.format('YYYY'), 10);
      const selectedEndYear = selectedEndDate && Number.parseInt(selectedEndDate.format('YYYY'), 10);
      const allChecked = Object.keys(timeFrames).every(
        (value) => timeFrames[value] && selectedStartYear === reportBeginYear && selectedEndYear === reportEndYear,
      );
      if (allChecked) {
        return null;
      }
      return Object.keys(timeFrames).filter((key) => {
        const year = Number.parseInt(key.substr(0, 4), 10);
        return (
          timeFrames[key] &&
          selectedStartYear &&
          year >= selectedStartYear &&
          selectedEndYear &&
          year <= selectedEndYear
        );
      });
    }
    return null;
  };

  const onSetTimeFrameParams = (timeFrames: string[]) => {
    onChangeParams('timeFrames', timeFrames.join(','));
  };

  const onChangeTimeFrames = (selectedTimeFrame: string[] | null, timeFrames: TimeFrames) => {
    setTimeFrames(timeFrames);
    setSelectedTimeFrames(selectedTimeFrame);
    trackUserAction('Time filter changed', UserActionsEnum.TIME_PICKER_CHANGE, JSON.stringify(timeFrames));
  };

  const saveHandler = (timeFrames: TimeFrames) => {
    const selectedTimeFrame = getSelectedTimeFrames(timeFrames);
    onChangeTimeFrames(selectedTimeFrame, timeFrames);
    if (selectedTimeFrame) {
      onSetTimeFrameParams(selectedTimeFrame);
    }
  };

  const toggleMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsMenuOpened((prevState) => !prevState);
  };

  const closeHandler = () => {
    setIsMenuOpened((prevState) => {
      if (prevState && reportStartDate && reportEndDate) {
        setSelectedStartDate(reportStartDate);
        setSelectedEndDate(reportEndDate);
      }
      return !prevState;
    });
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      if (reportStartDate && reportEndDate) {
        setSelectedStartDate(reportStartDate);
        setSelectedEndDate(reportEndDate);
      }
      setIsMenuOpened(false);
    }
  };

  return (
    <div onBlur={onBlurHandler} className={styles.container} tabIndex={0}>
      {isGlobalLoading ? (
        <Skeleton />
      ) : (
        <YearMonthLabel timeFrames={timeFrames} isMenuOpened={isMenuOpened} onToggleMenu={toggleMenu} />
      )}
      {isMenuOpened && reportStartDate && reportEndDate && selectedStartDate && selectedEndDate && (
        <Menu
          reportStartDate={reportStartDate}
          reportEndDate={reportEndDate}
          onClose={closeHandler}
          onSave={saveHandler}
          timeFrames={timeFrames}
          startDate={selectedStartDate}
          setStartDate={setSelectedStartDate}
          endDate={selectedEndDate}
          setEndDate={setSelectedEndDate}
          minYear={Number.parseInt(moment(reportStartDate).format('YYYY'), 10)}
          maxYear={Number.parseInt(moment(reportEndDate).format('YYYY'), 10)}
          lastUpdated={
            selectedReport?.end_date && selectedReport?.recurring ? moment(selectedReport.end_date) : undefined
          }
        />
      )}
    </div>
  );
};

export default YearMonthPicker;
