import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportItemInfo, Duration } from '../../../common/types/projectsHierarchy';
import { MainContext } from '../../../common/types/mainContext';
import ReportItemCard from '../../../common/reportItems/reportItemCard';
import { ReportItemType } from '../../../common/reportItems/types';
import DurationCardChart from './durationCardChart';
import { getDurationData } from '../services';
import { DurationMainTooltip } from './tooltips';
import { useToggle } from '../../../common/hooks';
import { dashboardModel } from '../../../dasboardLoader';
import { exportSettingsModel } from '../../../common/export';
import { useTooltipStartPosition } from '../../../common/reportItems/hooks';
import { reportItemDetailsModel } from '../../model';

const DurationCard = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetched, setFetched] = useState<boolean>(true);
  const [isTooltipOpen, toggleTooltipOpen] = useToggle(false);

  const {
    selection: { selectedProject, selectedReport, selectedReportItem },
    updateReportItemOfSelectedReport,
    selectedTimeFrames,
    selectedLocationsIds,
    primaryLocationId,
  } = useContext(MainContext);

  const { tooltipIconRef, position } = useTooltipStartPosition();

  const reportItem = useMemo(
    () => selectedReport?.report_items?.find((i) => i.type === ReportItemType.DURATION) as ReportItemInfo<Duration>,
    [selectedReport],
  );

  const durationLocation = useMemo(
    () => reportItem?.data?.data.find((location) => location.location_id === primaryLocationId),
    [reportItem, primaryLocationId],
  );

  const noData = useMemo(
    () =>
      (fetched && (!reportItem || !reportItem.data || reportItem.data.data.length === 0)) ||
      selectedLocationsIds.length <= 0,
    [reportItem, selectedTimeFrames, fetched, isLoading, selectedLocationsIds],
  );

  const onTooltipClose = () => {
    toggleTooltipOpen(false);
  };

  useEffect(() => {
    if (reportItem && !isLoading) {
      dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.DURATION, value: false });
      getDurationData(
        reportItem.id,
        selectedTimeFrames,
        selectedLocationsIds,
        selectedProject,
        updateReportItemOfSelectedReport,
        setFetched,
        setIsLoading,
        selectedReportItem,
      );
      dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.DURATION, value: true });
    }
  }, [JSON.stringify(reportItem), selectedTimeFrames, selectedLocationsIds]);

  useEffect(() => {
    if (durationLocation?.location_average) {
      exportSettingsModel.updateReportItemsInformation({
        duration: { averageTime: durationLocation?.location_average },
      });
      reportItemDetailsModel.reportItemsBriefDataChanged({
        [ReportItemType.DURATION]: {
          chartData: { data: durationLocation.data },
          avgDuration: durationLocation.location_average,
        },
      });
    }
  }, [durationLocation]);

  return reportItem ? (
    <ReportItemCard
      reportItemType={ReportItemType.DURATION}
      values={[
        {
          name: t('duration.card.avgTimeOnLocation'),
          value: `${durationLocation?.location_average?.toFixed(0)} ${t('reportItem.min')}`,
        },
      ]}
      reportItem={reportItem}
      isLoading={isLoading}
      noData={noData}
      onOpenTooltip={toggleTooltipOpen}
      tooltipRef={tooltipIconRef}
    >
      <>
        <DurationMainTooltip onClose={onTooltipClose} isOpen={isTooltipOpen} position={position} />
        <DurationCardChart
          data={durationLocation?.data || []}
          average={durationLocation?.location_average || 0}
          isLoading={isLoading}
        />
      </>
    </ReportItemCard>
  ) : null;
};

export default DurationCard;
