import { CircleData } from '../../interfaces';

export const getControllerColor = (data: CircleData[], index: number) => {
  const { length } = data;
  if (length === 1) {
    return '';
  }

  if (length === 2) {
    if (index === 1 || index === 2) {
      return data[0].color;
    }

    if (index === 3 || index === 4) {
      return data[1].color;
    }
  }

  if (length === 3) {
    if (index === 1 || index === 2) {
      return data[0].color;
    }

    if (index === 3) {
      return data[1].color;
    }

    if (index === 4) {
      return data[2].color;
    }
  }
};
