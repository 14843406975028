import React, { useContext, useMemo } from 'react';
import Dropdown from '../../../common/controls/dropdown';
import { MainContext } from '../../../common/types/mainContext';
import { LocationInfo } from '../../../common/types/projectsHierarchy';
import { Option } from '../../../common/controls/dropdown/types';
import styles from './sourceLocationDropdown.module.scss';
import { Skeleton } from '../../../common/skeleton';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';

interface Props {
  locations: LocationInfo[];
  isLoading: boolean;
  title: string;
}

const SourceLocationDropdown: React.FC<Props> = ({ locations, isLoading, title }) => {
  const { attractionSourceLocation, setAttractionSourceLocation } = useContext(MainContext);
  const selectedId = attractionSourceLocation ? attractionSourceLocation.id : '';
  const options: Option<LocationInfo>[] = useMemo(
    () =>
      locations.map((location) => ({
        id: location.id,
        label: location.name,
        item: location,
        disabled: false,
      })),
    [locations],
  );

  const changeHandler = (option: Option<LocationInfo>) => {
    trackUserAction(
      'Attraction source location changed',
      UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
      `Source location name: ${option.item?.name}, ID: ${option.item?.id}`,
    );
    setAttractionSourceLocation(option.item);
  };

  return (
    <div className={styles.container}>
      {isLoading ? <Skeleton className={styles.labelSkeleton} /> : <label className={styles.label}>{title}</label>}
      {isLoading ? (
        <Skeleton className={styles.dropdownSkeleton} />
      ) : (
        <Dropdown<LocationInfo>
          onChange={changeHandler}
          selectedId={selectedId}
          options={options}
          classNames={styles.sourceLocationDropdown}
          selectedItemClassName={styles.selectedItemClassName}
          maxLengthToShow={16}
        />
      )}
    </div>
  );
};

export default SourceLocationDropdown;
