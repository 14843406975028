import { ProjectInfo, ReportInfo } from '../../../../common/types/projectsHierarchy';

export const getProjectLocations = (project: ProjectInfo, report: ReportInfo) => {
  const reportLocations = project?.locations.filter((location) =>
    report.location_ids?.some((loc_id) => loc_id === location.id),
  );

  const selectedLocations = reportLocations || [];

  if (reportLocations && reportLocations.length > 0) {
    return { id: reportLocations[0].id, selectedLocations };
  }

  return { selectedLocations };
};
