import { Moment } from 'moment';

export const getYearsBetweenDates = (startDate: Moment, endDate: Moment): number[] => {
  const endYear = endDate.year();
  let currentYear = startDate.year();
  const years = [];
  while (currentYear <= endYear) {
    years.push(currentYear);
    currentYear += 1;
  }
  return years;
};
