import React from 'react';
import { IntroPageTemplate } from '../../common/templates';
import {SelfSignUpFinal} from "../../auth/signUp/selfSignUpFinal";

interface Props {}
export const SelfSignUpFinalPage: React.FC<Props> = () => {
  return (
    <IntroPageTemplate>
      <SelfSignUpFinal />
    </IntroPageTemplate>
  );
};
