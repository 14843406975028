import React from 'react';
import styles from './style.module.scss';
import { CopyIcon, PencilIcon, TrashIcon } from '../../../icons/tools';

interface Props {
  onEdit: VoidFunction;
  onTrash: VoidFunction;
  number?: number;
}
export const EditTools: React.FC<Props> = ({ onEdit, onTrash, number }) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.number}>{number}</span>
      <div className={styles.tools}>
        <span onClick={onEdit}>
          <PencilIcon />
        </span>
        {/* <span> */}
        {/*   <CopyIcon /> */}
        {/* </span>{' '} */}
        <span onClick={onTrash}>
          <TrashIcon />
        </span>
      </div>
    </div>
  );
};
