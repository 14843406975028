import { getNormalizedDataByScope } from '../../utils';
import { TrendScopesEnum } from '../../enum';
import { TrendValue } from '../../types';
import { LocationInfo, TrendLocation } from '../../../../common/types/projectsHierarchy';

const isDataAvailable = (data: TrendLocation[] = []) => {
  const result = data
    .map((item) => {
      const isAllDisabled = item.data.every((item) => item.absoluteLocation === undefined);
      return isAllDisabled;
    })
    .filter((item) => item);

  return result.length !== data.length;
};

export const isAbsoluteDropdownDisabled = (data: TrendLocation[] = [], locations: LocationInfo[]) => {
  const missing: string[] = [];

  getNormalizedDataByScope(TrendScopesEnum.ABSOLUTE_NUMBER, data).forEach((location: TrendValue) => {
    const isLocationWithoutAbsoluteValues = location.data.map((item) => item.value).every((item) => item === '-');
    if (isLocationWithoutAbsoluteValues) {
      missing.push(location.location_name);
    }
  });

  return !isDataAvailable(data) || (missing.length > 0 && missing.length === locations.length);
};
