import { request } from '../request';
import { API_URLS } from '../../routes';

export const editProject = (id: string, name: string, description: string) => {
  const url = `${API_URLS.PROJECT}/${id}/meta?name=${name}&description=${description}`;
  return request<Response>({
    url,
    method: 'PUT',
  });
};
