import { replaceValueToNA } from '../replaceValueToNA';
import { DrivingIcon } from '../../../../../../../common/icons/charts';
import { StakeType } from '../../../../../../../common/chart/demographic/interfaces';

export const getDriving = (value: number | null) => ({
  id: 4,
  icon: DrivingIcon,
  type: 'information' as StakeType,
  styles: { item: { left: '-245px' } },
  data: [
    {
      id: 1,
      color: '#DD7BAA',
      title: 'avgDrivingToLocation',
      value: value ? `${value} min` : replaceValueToNA(value),
      stake: 100,
      styles: { item: { textAlign: 'right', width: '237px' } },
    },
  ],
});
