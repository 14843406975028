import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CatchmentHeatmapIcon, CatchmentPostalCodesIcon, CatchmentTripsIcon } from '../../icons/reportItems';
import styles from './selectReportItemPanel.module.scss';
import { MainContext } from '../../types/mainContext';
import { ContentVisualizationMode, Movement, ReportItemInfo, ReportItems } from '../../types/projectsHierarchy';
import Dropdown from '../../controls/dropdown';
import { Option } from '../../controls/dropdown/types';
import { SwitchModes } from '../../types/visualizationObjects';

import { CatchmentLocationsCountEnum, ReportItemType } from '../../reportItems/types';
import { reportItems } from '../../reportItems/utils';
import { CircleButton } from '../../controls/buttons/circleButton/circleButton';
import { VisualizationModeToggle } from './visualizationModeToggle/visualizationModeToggle';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { useToggle, useURLParams } from '../../hooks';
import { SidebarContext } from '../../sidebarMenu/sidebarContext';
import { MapBaseOptions } from '../mapBase/mapBase';
import { PointerIcon } from '../../icons/menuItems';
import { CatchmentSocioDemoDropdown } from './dropdowns/catchmentSocioDemoDropdown';
import { FRONTEND_BASE_VERSION } from '../../utils/versions/const';

export const SelectReportItemPanel = () => {
  const {
    contentVisualizationMode,
    setContentVisualizationMode,
    selection,
    updateSelectedReportItem,
    catchmentLayer,
    setCatchmentLayer,
    movementMode,
    setMovementMode,
    reportItemsDetails: { selectedCountry, catchmentLocationsCount, catchmentDataOverlay },
  } = useContext(MainContext);
  const { isOpened } = useContext(SidebarContext);
  const { isHidden } = useContext(MapBaseOptions);
  const [selectedReportItemId, setSelectedReportItemId] = useState<ReportItemType>(ReportItemType.FOOTFALL);
  const [hideVisualizationMode, toggleHideVisualizationMode] = useToggle(false);
  const { onChangeParams, onRemoveParam, isParsingDone, params } = useURLParams();
  const { t } = useTranslation();

  const catchmentVersion = selection.selectedReport?.version?.catchment || FRONTEND_BASE_VERSION;

  useEffect(() => {
    if (selection.selectedReportItem?.type) {
      const isSelectedCatchment = selection.selectedReportItem.type === ReportItemType.CATCHMENT;
      const isSelectedMovement = selection.selectedReportItem.type === ReportItemType.MOVEMENT;

      if (isSelectedCatchment) {
        onChangeCatchmentSwitchMode(SwitchModes.POSTAL_CODES);
        return;
      }

      if (isSelectedMovement) {
        onChangeCatchmentSwitchMode(SwitchModes.HEATMAP);
      }
    }

    return () => {
      onRemoveParam('switchMode');
    };
  }, [selection.selectedReportItem?.type]);

  useEffect(() => {
    if (selection.selectedReportItem?.data && selectedCountry) {
      toggleHideVisualizationMode(false);
    }
  }, [selection.selectedReportItem?.data, selectedCountry]);

  useEffect(() => {
    if (isParsingDone) {
      const isSelectedCatchment = params.reportItemType === ReportItemType.CATCHMENT;
      if (params.contentVisualizationMode) {
        onChangeVisualizationMode(params.contentVisualizationMode);
      } else {
        onChangeParams('contentVisualizationMode', contentVisualizationMode);
      }
      if (params.switchMode) {
        if (isSelectedCatchment) {
          onChangeCatchmentSwitchMode(params.switchMode);
        } else {
          onChangeMovementSwitchMode(params.switchMode);
        }
      }
    }
  }, [isParsingDone]);

  useEffect(() => {
    if (selection.selectedReportItem) {
      setSelectedReportItemId(selection.selectedReportItem.type);
    }
  }, [selection]);

  const options = (): Option<ReportItemInfo<ReportItems>>[] => {
    if (selection?.selectedReport) {
      const availableReportItems = selection?.selectedReport?.report_items.map((reportItem) => reportItem.type);
      return reportItems
        .filter((reportItem) => availableReportItems?.includes(reportItem.type))
        .map((item) => ({
          id: item.type,
          type: item.type,
          label: t(`reportItem.enum.${item.type}`),
          disabled: false,
          item,
        })) as Option<ReportItemInfo<ReportItems>>[];
    }
    return [];
  };

  const selectReportItemHandler = (item: Option<ReportItemInfo<ReportItems>>) => {
    if (item.type) {
      setSelectedReportItemId(item.type);
    }

    const reportItem = selection.selectedReport?.report_items.find((report) => report.type === item?.type);
    updateSelectedReportItem(reportItem || item.item);
  };

  const onChangeVisualizationMode = (mode: ContentVisualizationMode) => {
    trackUserAction('Visualization mode changed', UserActionsEnum.VISUALIZATION_MODE_CHANGED, mode);
    setContentVisualizationMode(mode);
  };

  const onContentVisualizationModeChange = (mode: ContentVisualizationMode) => {
    onChangeVisualizationMode(mode);
    onChangeParams('contentVisualizationMode', mode);
  };

  const onCatchmentLayerChange = (mode: SwitchModes) => {
    trackUserAction('Catchment map mode changed', UserActionsEnum.VISUALIZATION_SUB_MODE_CHANGED, mode);
    setCatchmentLayer(mode);
  };

  const onChangeCatchmentSwitchMode = (mode: SwitchModes) => {
    onCatchmentLayerChange(mode);
    onChangeParams('switchMode', mode);
  };

  const onMovementLayerChange = (mode: SwitchModes) => {
    trackUserAction('Movement map mode changed', UserActionsEnum.VISUALIZATION_SUB_MODE_CHANGED, mode);
    setMovementMode(mode);
  };

  const onChangeMovementSwitchMode = (mode: SwitchModes) => {
    onMovementLayerChange(mode);
    onChangeParams('switchMode', mode);
  };

  const showHotspots: boolean = useMemo(() => {
    if (selection?.selectedReportItem?.type === ReportItemType.MOVEMENT) {
      const reportItemData = selection?.selectedReportItem as ReportItemInfo<Movement>;
      if (reportItemData.data?.hotspotsData) {
        return true;
      }
    }
    return false;
  }, [selection.selectedReportItem]);

  if (isHidden) return <></>;

  return (
    <div className={`${styles.wrapper}  ${isOpened ? styles.open : ''}`}>
      <div className={`${styles.container}`}>
        <div className={styles.dropdownBlock}>
          <Dropdown
            options={options()}
            selectedId={selectedReportItemId}
            onChange={selectReportItemHandler}
            classNames={styles.dropdown}
          />
          <VisualizationModeToggle
            selectedValue={contentVisualizationMode}
            onClick={onContentVisualizationModeChange}
            reportItemType={selection?.selectedReportItem?.type}
            hideVisualizationMode={hideVisualizationMode}
          />
        </div>
        {/* ====================== CATCHMENT SWITCH ========================= */}
        {selection?.selectedReportItem?.type === ReportItemType.CATCHMENT &&
          contentVisualizationMode === ContentVisualizationMode.MAP && (
            <>
              <div className={styles.catchmentButtons}>
                <CircleButton
                  onClick={() => onChangeCatchmentSwitchMode(SwitchModes.POSTAL_CODES)}
                  active={catchmentLayer === SwitchModes.POSTAL_CODES}
                  icon={<CatchmentPostalCodesIcon />}
                  title="Catchment areas"
                  hasShadow
                />
                <CircleButton
                  onClick={() => onChangeCatchmentSwitchMode(SwitchModes.HEATMAP)}
                  active={catchmentLayer === SwitchModes.HEATMAP}
                  icon={<CatchmentHeatmapIcon />}
                  title="Heatmap"
                  hasShadow
                  disabled={catchmentLocationsCount !== CatchmentLocationsCountEnum.SINGLE}
                />
                <CircleButton
                  onClick={() => onChangeCatchmentSwitchMode(SwitchModes.TRIPS)}
                  active={catchmentLayer === SwitchModes.TRIPS}
                  icon={<CatchmentTripsIcon />}
                  title="Trip routes"
                  hasShadow
                  disabled={catchmentLocationsCount !== CatchmentLocationsCountEnum.SINGLE}
                />
                {catchmentVersion !== FRONTEND_BASE_VERSION && (
                  <CircleButton
                    onClick={() => onChangeCatchmentSwitchMode(SwitchModes.ISOCHRONES)}
                    active={catchmentLayer === SwitchModes.ISOCHRONES}
                    icon={<PointerIcon />}
                    title="Radius"
                    hasShadow
                    className={styles.smallerIcon}
                    disabled={catchmentLocationsCount !== CatchmentLocationsCountEnum.SINGLE}
                  />
                )}
              </div>
              <div className={styles.catchmentDropdowns}>
                {((catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE && catchmentDataOverlay) ||
                  catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE) &&
                  catchmentVersion !== FRONTEND_BASE_VERSION &&
                  (catchmentLayer === SwitchModes.POSTAL_CODES || catchmentLayer === SwitchModes.ISOCHRONES) && (
                    <CatchmentSocioDemoDropdown />
                  )}
              </div>
            </>
          )}
        {/* ====================== MOVEMENT SWITCH ========================= */}
        {selection?.selectedReportItem?.type === ReportItemType.MOVEMENT &&
          contentVisualizationMode === ContentVisualizationMode.MAP && (
            <div className={styles.catchmentButtons}>
              {showHotspots && (
                <CircleButton
                  onClick={() => onChangeMovementSwitchMode(SwitchModes.HOTSPOTS)}
                  active={movementMode === SwitchModes.HOTSPOTS}
                  icon={<CatchmentPostalCodesIcon />}
                  title="Hotspots"
                  hasShadow
                />
              )}
              <CircleButton
                onClick={() => onChangeMovementSwitchMode(SwitchModes.HEATMAP)}
                active={movementMode === SwitchModes.HEATMAP}
                icon={<CatchmentHeatmapIcon />}
                title="Heatmap"
                hasShadow
              />
            </div>
          )}
      </div>
    </div>
  );
};
