import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { BaseTableProps, DataTypeExtended } from './types';
import useBaseTable from './useBaseTable';
import s from './baseTable.module.scss';
import Icon from '../../../components/Icon';
import { createCSVData } from './utils';
import { MainContext } from '../types/mainContext';

const BaseTable = <T extends DataTypeExtended>({
  data,
  headers,
  headName,
  subheadName,
  showTotal,
  showGrandTotal,
  exportHeaders,
  exportData,
  subHeadAsString,
}: BaseTableProps<T>) => {
  const {
    data: { items, total, order },
    handlers: { changeOrder },
  } = useBaseTable(data);
  const { updateReportItemsDetailsValue } = useContext(MainContext);

  const {
    data: { items: exportItems },
  } = useBaseTable(exportData);

  useEffect(() => {
    const csv = createCSVData(
      exportItems,
      headName,
      subheadName,
      exportHeaders,
      total,
      !!showTotal,
      !!showGrandTotal,
      subHeadAsString,
    );
    updateReportItemsDetailsValue('csv', csv);
  }, [exportItems]);

  return (
    <div className={s.root}>
      <table className={s.table}>
        <thead className={s.header}>
          <tr>
            <td className={s.headerCell}>
              <span className={s.headerContent}>
                {headName}
                {/* <button className={s.sortButton}>
                  <Icon iconId="ui_filter" className={s.sortButton__icon} />
                </button> */}
              </span>
            </td>
            <td className={s.headerCell}>
              <span className={s.headerContent}>
                {subheadName}
                <button onClick={() => changeOrder('head')} className={s.sortButton}>
                  <Icon
                    iconId="ui_sort"
                    className={classNames(s.sortButton__icon, {
                      [s.sortButton__icon_asc]: order && order.key === 'head' && order.direction === 'asc',
                      [s.sortButton__icon_decs]: order && order.key === 'head' && order.direction === 'desc',
                    })}
                  />
                </button>
              </span>
            </td>
            {headers.map((header) => (
              <td key={header.key} className={s.headerCell}>
                <span className={s.headerContent}>
                  {header.name}
                  <button onClick={() => changeOrder(header.key as string)} className={s.sortButton}>
                    <Icon
                      iconId="ui_sort"
                      className={classNames(s.sortButton__icon, {
                        [s.sortButton__icon_asc]: order && order.key === header.key && order.direction === 'asc',
                        [s.sortButton__icon_decs]: order && order.key === header.key && order.direction === 'desc',
                      })}
                    />
                  </button>
                </span>
              </td>
            ))}
          </tr>
        </thead>
        <tbody className={s.tableBody}>
          {items.map((row) => [
            ...row.options.map((option, index) => (
              <tr className={s.item} key={index}>
                {index === 0 && (
                  <td rowSpan={showTotal ? row.options.length + 1 : row.options.length} className={s.itemName}>
                    {row.head}
                  </td>
                )}
                <td className={s.itemOption}>{option.subhead.formatter()}</td>
                {headers.map((header, index) => (
                  <td className={s.itemOptionValue} key={index}>
                    {typeof option.values[header.key as string] === 'number'
                      ? Math.round((option.values[header.key as string] as number) * 100) / 100
                      : option.values[header.key as string]}{' '}
                    {header.unit || ''}
                  </td>
                ))}
              </tr>
            )),
            showTotal && (
              <tr className={s.itemTotal}>
                <td className={s.itemTotalCell}>Total</td>
                {headers.map((header) => (
                  <td className={s.itemTotalValue}>
                    {header.showTotal
                      ? `${Math.round(row.total[header.key as string] * 100) / 100} ${header.unit || ''}`
                      : ''}
                  </td>
                ))}
              </tr>
            ),
          ])}
        </tbody>
        {showGrandTotal && (
          <tfoot className={s.footer}>
            <tr>
              <td colSpan={2} className={s.footerCell}>
                Grand Total
              </td>
              {headers.map((header) => (
                <td className={s.footerValue}>
                  {header.showTotal
                    ? `${Math.round(total[header.key as string] * 100) / 100} ${header.unit || ''}`
                    : ''}
                </td>
              ))}
            </tr>
          </tfoot>
        )}
      </table>{' '}
    </div>
  );
};

export default BaseTable;
