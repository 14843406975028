import { API_URLS } from '../../routes';
import { ReportData, ReportItemsData, reportItemsDefaultSettings } from '../../createReport/types';
import { request } from '../request';
import { IReportCategory } from '../../../types/report';

const formatDate = (d: Date): string => {
  const month = d.getMonth() > 8 ? `${d.getMonth() + 1}` : `0${d.getMonth() + 1}`;
  const day = d.getDate() > 9 ? `${d.getDate()}` : `0${d.getDate()}`;
  return `${d.getFullYear()}-${month}-${day}`;
};

export const createReport = (report: ReportData, locations: string[], reportItemsData: ReportItemsData) => {
  const url = `${API_URLS.PROJECT}/${report.projectId}/reports`;

  const reportItems = reportItemsDefaultSettings
    .map((ri) => {
      if (ri.reportItemType.includes('catchment')) {
        return { ...ri, settings: [...ri.settings, { name: 'Catchment Geo Level', value: report.areaType }] };
      }
      if (ri.reportItemType.includes('movement')) {
        return {
          ...ri,
          settings: [{ name: 'User Type', value: report.residenceExclusion ? 'visitors' : 'all_users' }],
        };
      }
      return ri;
    })
    .filter((ri) => {
      if (report.category === 'AOI_DEFAULT' && ri.reportItemType.includes('loyalty')) {
        return false;
      }
      return true;
    });
  // attraction
  if (reportItemsData.attraction_isActive && report.category === IReportCategory.POI_DEFAULT) {
    reportItems.push({
      name: 'attraction',
      description: '',
      reportItemType: 'POI_DEFAULT.attraction',
      settings: [
        {
          name: 'aois',
          value: reportItemsData.attraction_aois.map((item) => item.id).join(';'),
        },
        {
          name: 'Link POIs',
          value: reportItemsData.attraction_linkPois ? 'yes' : 'no',
        },
        {
          name: 'Link AOIs',
          value: reportItemsData.attraction_linkAois ? 'yes' : 'no',
        },
        {
          name: 'Activity Level',
          value: 'medium',
        },
        {
          name: 'pois',
          value: reportItemsData.attraction_pois.map((item) => item.id).join(';'),
        },
      ],
    });
  }
  // absorption
  if (reportItemsData.absorption_isActive && report.category === IReportCategory.POI_DEFAULT) {
    reportItems.push({
      name: 'absorption',
      description: '',
      reportItemType: 'POI_DEFAULT.absorption',
      settings:
        reportItemsData.absorption_areaType === 'RADIUS'
          ? [
              {
                name: 'radius',
                value: reportItemsData.absorption_radius.toString(),
              },
              {
                name: 'Activity Level',
                value: 'medium',
              },
            ]
          : [
              {
                name: 'aois',
                value: reportItemsData.absorption_aoi?.id.toString() || '',
              },
              {
                name: 'pois',
                value: reportItemsData.absorption_pois.map((item) => item.id).join(';'),
              },
              {
                name: 'Link POIs',
                value: reportItemsData.absorption_linkPois ? 'yes' : 'no',
              },
              {
                name: 'Activity Level',
                value: 'medium',
              },
            ],
    });
  }

  return request<string>({
    url,
    method: 'POST',
    data: {
      name: report.name,
      description: report.description,
      reportCategory: report.category,
      startDay: report.startDate ? formatDate(report.startDate) : '',
      endDay: report.endDate ? formatDate(report.endDate) : '',
      recurring: report.recurrence,
      recurrenceFrequency: report.recurrenceFrequency,
      locations,
      reportItems,
      visualizationURL: '',
    },
  });
};
