import React from 'react';
import { CollapseIcon, MinusIcon, PlusIcon, UncollapseIcon } from '../../../icons/sliders';
import styles from './style.module.scss';

interface Props {
  onCollapse: () => void;
  onExpand: () => void;
  isExpanded: boolean;
  isCollapsed: boolean;
  disabledCollapseButton: boolean;
  hideCollapse: boolean;
}
export const CollapseOptions = ({
  onCollapse,
  onExpand,
  isExpanded,
  isCollapsed,
  disabledCollapseButton,
  hideCollapse,
}: Props) => (
  <div className={styles.wrapper}>
    {!hideCollapse ? (
      <div onClick={!disabledCollapseButton ? onCollapse : undefined} className={styles.icon} title="Maximize">
        {isCollapsed ? <CollapseIcon /> : <UncollapseIcon />}
      </div>
    ) : (
      <span />
    )}
    <div onClick={onExpand} className={styles.icon} title={isExpanded ? 'Minimize' : 'Expand'}>
      {isExpanded ? <MinusIcon /> : <PlusIcon />}
    </div>
  </div>
);
