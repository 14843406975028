import React from 'react';
// import Info from "../Info/Info";
import s from './Bar.module.css';
import LocationSearch from '../LocationSearch/LocationSearch';
import Button from '../../../atoms/primaryButton/button';

interface BarProps {
  data: GeoJSON.Feature<GeoJSON.Polygon> | undefined | null;
  onUndo: () => void;
  onReset: () => void;
  onClose: () => void;
  onSavePolygon: () => void;
  onSelectPlace: (place: any) => void;
}

const Bar: React.FC<BarProps> = ({ data, onUndo, onReset, onClose, onSavePolygon, onSelectPlace }) => (
  <div className={s.root}>
    <div className={s.container}>
      <div className={s.buttonsBox}>
        <LocationSearch onSelectPlace={onSelectPlace} />
        <div className={s.editPolygonBtnContainer}>
          <Button onClick={onReset} disabled={!data}>
            Reset
          </Button>
          <Button onClick={onUndo} disabled={!data}>
            Undo
          </Button>
        </div>
        {/* <Info data={data} /> */}
      </div>
      <div className={s.actionBtnContainer}>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={onSavePolygon} disabled={!data}>
          Save Polygon
        </Button>
      </div>
    </div>
  </div>
);

export default Bar;
