import moment from 'moment';
import { PeriodsEnum } from '../../../common/reportItems/types';

export const dateFormatter = (period: PeriodsEnum, value: string, dateFormat = 'MMM, YY') => {
  const date = moment(Number(value));
  if (PeriodsEnum.QUARTERLY === period) {
    return `Q${date.quarter()} ${date.format('YY')}`;
  }
  return date.format(dateFormat);
};
