import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import * as _ from 'lodash';
import { ReportItemSettingDefinition, ReportItemSettingType } from '../../../types/reportItemSettingDefinition';
import styles from './EditReportItemSettingDefinitionsForm.module.css';
import EditReportItemSettingDefinitionsFormEntry from '../../molecules/reportItemSettingDefinitionsFormEntry/EditReportItemSettingDefinitionsFormEntry';
import Button from '../../atoms/primaryButton/button';

interface Props {
  reportCategory: string;
  initialSettings: ReportItemSettingDefinition[];
  onSave: (settings: ReportItemSettingDefinition[]) => void;
  savedAt: Moment;
}

interface State {
  reportCategory: string;
  settings: ReportItemSettingDefinition[];
  updatedAt: Moment;
  savedSettings?: ReportItemSettingDefinition[];
}

export default class EditReportItemSettingDefinitionsForm extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      reportCategory: props.reportCategory,
      settings: props.initialSettings,
      updatedAt: moment(),
    };
  }

  componentDidUpdate(prevProps: Readonly<Props & Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.reportCategory !== prevProps.reportCategory) {
      this.setState({ reportCategory: this.props.reportCategory });
    }
    if (this.props.initialSettings !== prevProps.initialSettings) {
      this.setState({ settings: this.props.initialSettings });
    }
  }

  render() {
    return (
      <div className={styles.container}>
        {this.state.settings.map((setting, index) => (
          <EditReportItemSettingDefinitionsFormEntry
            key={index}
            setting={setting}
            onChange={(newSetting) => this.updateSetting(newSetting, index)}
            onDelete={() => this.removeSetting(index)}
          />
        ))}
        {this.renderAddSettings()}
        {this.renderSaveRow()}
      </div>
    );
  }

  private renderSaveRow() {
    return (
      <div className={styles.saveSection}>
        <Button
          onClick={() => {
            this.setState((state) => ({ savedSettings: state.settings }));
            return this.props.onSave(this.state.settings);
          }}
        >
          Save
        </Button>
        {this.shouldRenderSavedMessage() && <div className={styles.savedMessage}>Saved!</div>}
      </div>
    );
  }

  private shouldRenderSavedMessage() {
    return this.props.savedAt.isAfter(this.state.updatedAt) && _.isEqual(this.state.settings, this.state.savedSettings);
  }

  private renderAddSettings() {
    return (
      <div className={styles.addEntryButtons}>
        <button
          data-test="add-simple-text-setting"
          className={styles.actionButton}
          onClick={() => this.addSetting('simpleText')}
        >
          Add simple text setting
        </button>
        <button
          data-test="add-single-choice"
          className={styles.actionButton}
          onClick={() => this.addSetting('singleChoice')}
        >
          Add single choice setting
        </button>
      </div>
    );
  }

  private updateSetting(newSetting: ReportItemSettingDefinition, index: number) {
    this.setState((prevState) => ({
      updatedAt: moment(),
      settings: prevState.settings.map((oldSetting, currentIndex) => {
        if (index === currentIndex) {
          return newSetting;
        }
        return oldSetting;
      }),
    }));
  }

  private removeSetting(index: number) {
    this.setState((prevState) => ({
      updatedAt: moment(),
      settings: prevState.settings.filter((oldSetting, currentIndex) => currentIndex !== index),
    }));
  }

  private addSetting(settingType: ReportItemSettingType) {
    this.setState((prevState) => ({
      updatedAt: moment(),
      settings: [
        ...prevState.settings,
        {
          settingType,
          isMandatory: false,
          description: '',
          possibleValues: [],
          defaultValue: '',
          name: '',
        },
      ],
    }));
  }
}
