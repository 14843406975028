import React from 'react';

export const RefreshIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.76243 1.7625C2.84993 0.675 4.34243 0 5.99993 0C9.31493 0 11.9924 2.685 11.9924 6C11.9924 9.315 9.31493 12 5.99993 12C3.20243 12 0.869933 10.0875 0.202433 7.5H1.76243C2.37743 9.2475 4.04243 10.5 5.99993 10.5C8.48243 10.5 10.4999 8.4825 10.4999 6C10.4999 3.5175 8.48243 1.5 5.99993 1.5C4.75493 1.5 3.64493 2.0175 2.83493 2.835L5.24993 5.25H-6.77109e-05V0L1.76243 1.7625Z"
        fill="white"
      />
    </svg>
  );
};
