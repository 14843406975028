import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../organisms/UserForm/userForm.module.css';
import { ffiPlanTranslationMap } from '../../../../types/FfiPlan';
import { AppState } from '../../../../reducers/reducers';
import { ProjectsState } from '../../../../reducers/projectsReducer';

interface Props {
  label: string;
  name: string;
  value: string;
  handler: any;
  includes: string;
  ffiPlans: {};
}

const FfiPlans = (props: Props) => {
  const { ffiPlans } = useSelector<AppState, ProjectsState>((state) => state.projects);
  return (
    <label htmlFor={`plan-${props.name}`} className={styles.formRowFfi}>
      {props.label}
      <select name={props.name} id={`plan-${props.name}`} value={props.value} onChange={props.handler}>
        <option key="empty" value="">
          No plan selected
        </option>
        {ffiPlans.sort().map(
          (plan) =>
            plan.includes(props.includes) && (
              <option key={plan} value={plan}>
                {ffiPlanTranslationMap.get(plan)}
              </option>
            ),
        )}
      </select>
    </label>
  );
};

export default FfiPlans;
