import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { BaseModal } from '../../baseModal';
import styles from './style.module.scss';
import { Routes } from '../../../routes';

export const ActivateAccountModal = () => {
  const { state } = useLocation<{ email: string; name: string }>();

  if (!state?.email || !state?.name) return <Redirect to={Routes.signUp} />;

  return (
    <BaseModal subtitle={`Welcome ${state.name},`} title="Activate your account" subtitleFirst>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          We emailed a confirmation link to
          <br />
          <span className={styles.highlight}>
            <a href={`mailto:${state.email}`}>{state.email}</a>
          </span>
          <br />
          Click the link to activate your account
        </div>
        <div className={styles.footer}>
          If you don't see an email from us, try checking your spam folder, or{' '}
          <span className={styles.highlight}>
            <a href="mailto:cs@placesense.ai">contact us</a>
          </span>{' '}
          to activate your account manually
        </div>
      </div>
    </BaseModal>
  );
};
