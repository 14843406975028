import React from 'react';
import styles from './style.module.scss';
import { MapPopupData } from '../../../../../common/types/visualizationObjects';
import { CrossVisitationBriefData, RetentionBriefData } from '../../../../model/interfaces';
import { toFixNumber } from '../../../../../common/lib';

interface Props {
  data: MapPopupData;
}
export const MapTooltip: React.FC<Props> = ({ data }) => {
  const { shareOfVisits, visitRatio, distanceFromLocation } = data.data as CrossVisitationBriefData;
  return (
    <div className={styles.information}>
      <div className={styles.row}>
        <p className={styles.label}>Share of visits</p>
        <p className={styles.value}>{shareOfVisits ? toFixNumber(shareOfVisits * 100, 2) : ''}%</p>
      </div>{' '}
      <div className={styles.row}>
        <p className={styles.label}>Visit ration</p>
        <p className={styles.value}>{visitRatio}</p>
      </div>{' '}
      <div className={styles.row}>
        <p className={styles.label}>Distance from location</p>
        <p className={styles.value}>{distanceFromLocation}km</p>
      </div>
    </div>
  );
};
