import React from 'react';
import { SuccessCreatedNewPassword } from '../../auth';
import { IntroPageTemplate } from '../../common/templates';
import { RedirectAuthedUser } from '../../processes';

export const NewPasswordCreatedSuccessfullyPage = () => (
  <RedirectAuthedUser>
    <IntroPageTemplate>
      <SuccessCreatedNewPassword />
    </IntroPageTemplate>
  </RedirectAuthedUser>
);
