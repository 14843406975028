import React, { ChangeEvent } from 'react';
import styles from './textInput.module.scss';

interface Props {
  name: string;
  value: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
}

export const TextInput = (props: Props) => {
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <input
      name={props.name}
      className={styles.textInput}
      type={props.type || 'text'}
      value={props.value}
      onChange={onInputChange}
      disabled={props.disabled}
      placeholder={props.placeholder || ''}
    />
  );
};
