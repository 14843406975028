import { ReportItemType } from '../../../reportItems/types';
import { MapHandlerTypesEnum } from '../enums';

export const getClickHandlerTypeByReportItemType = (type?: ReportItemType) => {
  switch (type) {
    case ReportItemType.FOOTFALL:
      return MapHandlerTypesEnum.POLYGON;
    case ReportItemType.CATCHMENT:
      return MapHandlerTypesEnum.CODE;
    case ReportItemType.SEASONALITY:
      return MapHandlerTypesEnum.POLYGON;
    case ReportItemType.DURATION:
      return MapHandlerTypesEnum.POLYGON;
    case ReportItemType.RETENTION:
      return MapHandlerTypesEnum.POLYGON;
    case ReportItemType.MOVEMENT:
      return MapHandlerTypesEnum.CODE;
    case ReportItemType.CROSS_VISITATION:
      return MapHandlerTypesEnum.POLYGON;
    case ReportItemType.ABSORPTION:
      return MapHandlerTypesEnum.POLYGON;
    case ReportItemType.ATTRACTION:
      return MapHandlerTypesEnum.POLYGON;
    default:
      return MapHandlerTypesEnum.NO_HANDLER;
  }
};
