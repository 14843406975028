import React, {useContext, useEffect, useState} from 'react';
import {CSVIcon, ImageIcon, PDFIcon, PowerPointIcon} from '../../../icons/reportItems';
import {exportSettingsModel} from '../../../export';
import {ReportItemType} from '../../types';
import styles from './style.module.scss';
import {ButtonTooltip} from '../../../controls/tooltip/buttonTooltip';
import {MainContext} from '../../../types/mainContext';
import {trackUserAction, UserActionsEnum} from '../../../../../mixpanel';
import {ContentVisualizationMode} from '../../../types/projectsHierarchy';
import moment from 'moment';

interface Props {
  onMakeScreenshot: VoidFunction;
  type: ReportItemType;
}
export const ExportButtons: React.FC<Props> = ({ onMakeScreenshot, type }) => {
  const [fileName, setFileName] = useState<string>('table-data.csv');

  const onExport = (format: 'png' | 'pdf' | 'pptx') => {
    trackUserAction('Export buttons', UserActionsEnum.REPORT_EXPORT, `Report item type: ${type}; Format: ${format}`);
    exportSettingsModel.toggleIsLoading(true);
    exportSettingsModel.toggleIsExportMode(true);
    exportSettingsModel.updateExportOptions({ [type]: { table: false, chart: true, map: false } });
    exportSettingsModel.updateExportedFormats(format);
    exportSettingsModel.updateExportedMetrics(['chart']);
    exportSettingsModel.toggleUsePDFPreview(false);
    onMakeScreenshot();
  };

  const {
    reportItemsDetails: { csv },
    selection: { selectedReport, selectedReportItem },
    contentVisualizationMode,
  } = useContext(MainContext);

  useEffect(() => {
    const date = moment().format('yyyy-MM-DD_HH:mm:ss');
    setFileName(`${selectedReport?.name}_${selectedReportItem?.name}_${date}.csv`);
  }, [selectedReport, selectedReportItem, moment.now()]);

  return (
    <div className={styles.items}>
      {/* Add an image and tje power point exports once we're ready to implement them */}
      {contentVisualizationMode === ContentVisualizationMode.VISUALIZATION && (
        <>
          <ButtonTooltip title="Export PNG">
            <span className={styles.icon} onClick={() => onExport('png')}>
              <ImageIcon />
            </span>
          </ButtonTooltip>
          <ButtonTooltip title="Export PowerPoint">
            <span className={styles.icon} onClick={() => onExport('pptx')}>
              <PowerPointIcon />
            </span>
          </ButtonTooltip>{' '}
          <ButtonTooltip title="Export PDF">
            <span className={styles.icon} onClick={() => onExport('pdf')}>
              <PDFIcon />
            </span>
          </ButtonTooltip>
        </>
      )}
      {contentVisualizationMode === ContentVisualizationMode.TABLE && (
        <ButtonTooltip title="Export CSV">
          <a href={`data:text/csv;charset=utf-8,${csv}`} className={styles.icon} download={fileName}>
            <CSVIcon />
          </a>
        </ButtonTooltip>
      )}
    </div>
  );
};
