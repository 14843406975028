export const styleCorrections = (index: number) => {
  switch (index) {
    case 1:
      return { top: 23.5, left: 15.5 };
    case 2:
      return { top: 21.5, left: 54.5 };
    case 3:
      return { top: 21.5, left: -54.5 };
    case 4:
      return { top: 23.5, left: -15.5 };
    case 6:
      return { top: -20.5, left: -11.5 };
    case 7:
      return { top: -14.5, left: -33 };
    case 8:
      return { top: -14.5, left: 33 };
    case 9:
      return { top: -20.5, left: 11.5 };
    default:
      return { top: 0, left: 0 };
  }
};
