export const DEFAULT_PALETTE = [
  '#d87c7c',
  '#919e8b',
  '#d7ab82',
  '#6e7074',
  '#61a0a8',
  '#efa18d',
  '#787464',
  '#cc7e63',
  '#724e58',
  '#4b565b',
  '#7b7a97',
];

export const TEXT_STYLE = {
  fontFamily: 'Open Sans, sans-serif',
  color: '#1a1b1e',
  fontWeight: 600,
};
