import React from 'react';
import styles from './linkWithIcon.module.scss';
import { NavLink } from 'react-router-dom';

interface TProps {
  icon?: React.ReactElement;
  title: string;
  to: string;
  selected?: boolean;
}

export const LinkWithIcon = (props: TProps) => (
    <NavLink to={props.to} className={`${styles.linkWithIcon} ${props.selected ? styles.selected : ''}`}>
      {props.icon && <div className={styles.icon}>{props.icon}</div>}
      <div className={styles.title}>{props.title}</div>
    </NavLink>
);
