import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../common/tooltip';
import { TooltipPositionCoordinates } from '../../../../../common/reportItems/hooks';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  position?: TooltipPositionCoordinates;
}

export const RetentionMainTooltip: React.FC<Props> = ({ onClose, isOpen, position }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      onClose={onClose}
      title={t('reportItem.howItWorks')}
      isOpen={isOpen}
      initialPosition={position}
      height={335}
    >
      <div>
        <p>{t('retention.tooltip.paragraph1')}</p>
        {/* <p>{t('retention.tooltip.paragraph2')}</p>
        <p>{t('retention.tooltip.paragraph3')}</p>
        <p>
          <i>
            {t('retention.tooltip.exampleTitle')}
            <br />
            {t('retention.tooltip.exampleParagraph1')}
          </i>
        </p> */}
      </div>
    </Tooltip>
  );
};
