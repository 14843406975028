import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../style.module.scss';
import { LocationsData, ReportItemsData } from '../../../types';
import { FullFilledSwitcher } from '../../../../common/switchers';
import { Slider } from '../../../../common/controls';
import LocationsDropdown from '../../../../common/locationsDropdown/locationsDropdown';
import Dropdown from '../../../../common/controls/dropdown';
import { PolygonData } from '../../../../common/manageLocations/createLocation/interfaces';
import { Option } from '../../../../common/controls/dropdown/types';
import { SwitchToggle } from '../../../../common/controls/toggle';
import { ButtonTooltip } from '../../../../common/controls/tooltip/buttonTooltip';
import { InformationIcon } from '../../../../common/icons/reportItems';

interface TProps {
  data: ReportItemsData;
  onChange: (data: ReportItemsData) => void;
  locationsData: LocationsData;
}

export const AbsorptionSettings = ({ data, onChange, locationsData }: TProps) => {
  const { t } = useTranslation();

  const onDropdownChange = (option: Option<PolygonData>) => {
    if (option.id) {
      onChange({ ...data, absorption_aoi: option.item });
    }
  };

  const dropdownOptions = locationsData.locations.map((loc) => ({
    id: loc.id.toString(),
    label: loc.name,
    item: loc,
    disabled: false,
  }));

  const tooltipIcon = (title: string) => (
    <ButtonTooltip title={title}>
      <span>
        <InformationIcon />
      </span>
    </ButtonTooltip>
  );

  return (
    <div className={styles.settingsBox}>
      <div className={styles.row}>
        <div className={styles.header}>
          {t('create.stepThree.areaType')}
          {tooltipIcon(t('create.stepThree.areaTypeTooltip'))}
        </div>
        <div className={styles.component}>
          <SwitchToggle
            leftLabelSize="25px"
            leftLabel="AOI"
            leftValue="AOI"
            rightLabel="Radius"
            rightValue="RADIUS"
            selectedValue={data.absorption_areaType}
            onClick={(value) => onChange({ ...data, absorption_areaType: value })}
          />
        </div>
      </div>
      {data.absorption_areaType === 'RADIUS' && (
        <div className={styles.row}>
          <div className={styles.header}>
            {t('create.stepThree.radius')}
            {tooltipIcon(t('create.stepThree.radiusTooltip'))}
          </div>
          <div className={styles.component}>
            <Slider
              initialValue={data.absorption_radius}
              minValue={0}
              maxValue={500}
              onChange={(value) => onChange({ ...data, absorption_radius: value })}
            />
          </div>
        </div>
      )}
      {data.absorption_areaType === 'AOI' && (
        <>
          <div className={styles.row}>
            <div className={styles.header}>
              {t('create.stepThree.selectLocationsToAnalyze')}
              {tooltipIcon(t('create.stepThree.selectAbsorptionPOIsTooltip'))}
            </div>
            <div className={styles.component}>
              <LocationsDropdown
                locations={locationsData.locations}
                selectedLocations={data.absorption_pois}
                onChange={(pois) => onChange({ ...data, absorption_pois: pois })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.header}>
              {t('create.stepThree.linkLocations')}
              {tooltipIcon(t('create.stepThree.linkPOIsTooltip'))}
            </div>
            <div className={styles.component}>
              <FullFilledSwitcher
                value={data.absorption_linkPois}
                onChange={() => onChange({ ...data, absorption_linkPois: !data.absorption_linkPois })}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.header}>
              {t('create.stepThree.selectAOI')}
              {tooltipIcon(t('create.stepThree.selectAOITooltip'))}
            </div>
            <div className={styles.component}>
              <Dropdown<PolygonData>
                selectedId={data.absorption_aoi?.id.toString() || ''}
                options={dropdownOptions}
                onChange={onDropdownChange}
                placeholder="Select AOI"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
