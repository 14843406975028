import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import styles from './page.module.css';
import PlacenseLogoWhite from '../../atoms/placenseLogoWhite/placenseLogoWhite';
import Navigation from '../../molecules/navigation/navigation';
import { AppState } from '../../../reducers/reducers';
import { Report } from '../../../types/report';
import { Account } from '../../../types/Account';
import { PlusIcon } from './plus';

interface Props {
  title: string;
  children?: any;
  onAddUser?: () => void;
}

interface StateProps {
  reports?: Report[];
  accounts: Account[];
}

type PathParams = { projectId: string };

class Page extends Component<Props & StateProps & RouteComponentProps<PathParams>> {
  render() {
    const shouldRenderTitle: '' | boolean = this.props.title && this.props.title !== 'null';
    return (
      <div className={styles.container}>
        <div className={styles.sideBar}>
          <PlacenseLogoWhite />
          <Navigation />
        </div>
        <div className={styles.content}>
          <div className={styles.topBar}>
            <div>Account</div>
            {this.renderProject()}
          </div>
          <div className={styles.page}>
            {this.renderTitle(shouldRenderTitle)}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  private renderTitle(shouldRender: '' | boolean) {
    if (!shouldRender) {
      return;
    }
    return (
      <div className={styles.pageTitleContainer}>
        <h1 className={styles.pageTitle} data-test="page-title">
          {this.props.title}
        </h1>
        {this.props.onAddUser && <PlusIcon onClick={this.props.onAddUser} />}
      </div>
    );
  }

  private renderProject() {
    const { projectId } = this.props.match.params;
    if (projectId && this.props.reports) {
      return (
        <h3>
          Project: {(this.props.reports.find((report) => report.project_id === projectId) || { name: '' }).project_name}
        </h3>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  reports: state.reports.entries,
  accounts: state.auth.accounts,
});

export default withRouter(connect(mapStateToProps)(Page));
