import React, { useRef, useState, useEffect } from 'react';
import { useClickOutside } from '../hooks';
import styles from './style.module.scss';
import { ButtonTooltip } from '../controls/tooltip/buttonTooltip';
import { MenuIcon } from '../icons/reportItems';

interface Props {
  id?: string;
  title: string;
  options: string[];
  onChange: (option: string) => void;
}

export const ThreeDotsMenu = ({ id, title, options, onChange }: Props) => {
  const [isOpen, toggleOpen] = useState<boolean>(false);
  const [menuPosition, setMenuPosition] = useState<number[]>([0, 0]);
  const componentRef = useRef(null);

  const setPopupCoordinates = (rect: DOMRect) => {
    setMenuPosition([rect.left - 20, rect.top + 26]);
  };

  const onClick = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    if (isOpen) {
      toggleOpen(false);
    } else {
      setPopupCoordinates(rect);
      toggleOpen(true);
    }
  };

  const onCloseList = () => {
    toggleOpen(false);
  };

  useClickOutside(componentRef, onCloseList, isOpen);

  const onOptionClick = (option: string) => {
    onChange(option);
    onCloseList();
  };

  useEffect(() => {
    const scrollingPanel = document.getElementById('side-panel-scroll');
    if (scrollingPanel) {
      scrollingPanel.addEventListener('scroll', (e) => {
        const iconButton = document.getElementById(`threeDotsMenu-${id}`);
        const iconButtonRect = iconButton?.getBoundingClientRect();
        if (iconButtonRect) {
          setPopupCoordinates(iconButtonRect);
        }
      });
    }
  }, []);

  return (
    <div className={styles.wrapper} ref={componentRef}>
      <ButtonTooltip title={title} placement="top">
        <button
          type="button"
          className={`${styles.icon} ${isOpen ? styles.active : ''}`}
          onClick={onClick}
          id={`threeDotsMenu-${id}`}
        >
          <MenuIcon />
        </button>
      </ButtonTooltip>
      {isOpen && (
        <div className={styles.container} style={{ left: `${menuPosition[0]}px`, top: `${menuPosition[1]}px` }}>
          <div className={styles.list}>
            {options.map((option) => (
              <div className={styles.item} onClick={(e) => onOptionClick(option)} key={`menuitem-${option}`}>
                <span>{option}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
