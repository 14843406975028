import { createStore } from 'effector';
import { reportItemsBriefDataChanged, toggleReportItemDetailsLoading } from './events';
import { ReportItemType } from '../../common/reportItems/types';
import { ReportItemsBriefData } from './interfaces';

export const $isLoading = createStore(false);

export const $reportItemsBriefData = createStore<ReportItemsBriefData>({
  [ReportItemType.FOOTFALL]: {
    avgTraffic: '',
    chartData: {
      data: [],
      dates: [],
      isPercentage: false,
      isAbsoluteNumbersDisabled: false,
    },
  },
  [ReportItemType.CATCHMENT]: {
    topArea: '',
    shareOfVisits: '',
    distanceFromLocation: '',
    drivingTime: '',
  },
  [ReportItemType.SEASONALITY]: {
    popularDay: '',
    popularHour: '',
    chartData: {
      dayOfWeek: null,
      average: [],
      mostPopularHour: null,
    },
  },
  [ReportItemType.DURATION]: {
    avgDuration: null,
    chartData: {
      data: [],
    },
  },
  [ReportItemType.CROSS_VISITATION]: {
    shareOfVisits: null,
    visitRatio: '',
    distanceFromLocation: null,
  },
  [ReportItemType.RETENTION]: {
    locationReturningAverage: null,
    chartData: {
      data: [],
    },
  },
  [ReportItemType.MOVEMENT]: null,
  [ReportItemType.ABSORPTION]: {
    visitsFromAOI: null,
  },
  [ReportItemType.ATTRACTION]: {
    visitsFromAOI: null,
  },
});

$isLoading.on(toggleReportItemDetailsLoading, (_, data) => data);

$reportItemsBriefData.on(reportItemsBriefDataChanged, (store, data) => ({ ...store, ...data }));
