import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../../common/select';
import { SelectItem } from '../../../../common/types/SelectItem.interface';
import { TrendScopesEnum } from '../../enum';
import styles from './style.module.scss';
import { Skeleton } from '../../../../common/skeleton';

interface Props {
  activeItem: TrendScopesEnum;
  items: SelectItem[];
  onChangeMetricType: (value: SelectItem) => void;
  isLoading: boolean;
}

export const MetricType = ({ activeItem, items, onChangeMetricType, isLoading }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Skeleton className={styles.labelSkeleton} />
      ) : (
        <span className={styles.label}>{t('visitTrends.card.metricType')}</span>
      )}
      {isLoading ? (
        <Skeleton className={styles.selectSkeleton} />
      ) : (
        <Select
          activeValue={activeItem}
          items={items}
          onChange={onChangeMetricType}
          customActiveItemClassName={styles.activeItem}
          customSelectClassName={styles.select}
          customActiveSelectClassName={styles.activeSelect}
        />
      )}
    </div>
  );
};
