import React, {ReactElement, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import styles from './editUserForm.module.css';
import Button from '../../atoms/primaryButton/button';
import {AccountStatus} from '../../../types/AccountStatus';
import {AddUser} from '../../../types/AddUser';
import UserRole, {userRoleTranslationMap} from '../../../types/UserRole';
import {User} from '../../../types/User';
import UpdatePasswordModal from '../UpdatePasswordModal';
import {fetchAccounts, fetchFfiPlans, fetchUser, fetchUserRoles, updateUser} from '../../../actions/actions';
import routes from '../../../router/routes';
import {AppState} from '../../../reducers/reducers';
import {ProjectsState} from '../../../reducers/projectsReducer';
import {AuthState} from '../../../reducers/authReducer';
import FfiPlans from '../../molecules/dropdowns/ffiPlans/FfiPlans';
import UserStatus from '../../../types/UserStatus';
import _ from "lodash";
import ReactTooltip from "react-tooltip";

interface Props {
  userId: string;
}

const mapFfiPlans = (allPlans: string[], selectedPlans: string[]): { [id: string]: boolean } => {
  const result: { [id: string]: boolean } = {};
  allPlans.forEach((plan) => (result[plan] = !!selectedPlans && selectedPlans.includes(plan)));
  return result;
};

const UserForm = ({ userId }: Props): ReactElement => {
  const history = useHistory();
  const { ffiPlans } = useSelector<AppState, ProjectsState>((state) => state.projects);
  const [state, setState] = useState<AddUser>({
    accountId: '',
    userId: '',
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    phone: '',
    title: '',
    ffiPlans: {},
    status: '',
    notifyUser: false,
  });
  const [ffi, setFfi] = useState({
    NL: '',
    DE: '',
  });
  const [userFfiPlans, setUserFfiPlans] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { accounts, userRoles } = useSelector<AppState, AuthState>((state) => state.auth);
  const [isUpdatePasswordModalVisible, setIsUpdatePasswordModalVisible] = useState<boolean>(false);
  const loggedUserId = localStorage.getItem('userId');
  const [savedUserAccountId, setSavedUserAccountId] = useState<string>('')
  const [savedUserStatus, setSavedUserStatus] = useState<string>('') // used to retain the user status present in the DBs

  const userFetchedSuccessHandler = (user: User) => {
    setUserFfiPlans(user.ffiPlans);
    setState((prevState) => ({
      ...prevState,
      ...user,
      ffiPlans: mapFfiPlans(ffiPlans, user.ffiPlans),
    }));
    setSavedUserAccountId(user.accountId?user.accountId:state.accountId)
    setSavedUserStatus(user.status)
  };

  useEffect(() => {
    dispatch(fetchUser(userId, userFetchedSuccessHandler));
  }, [userId]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ffiPlans: mapFfiPlans(ffiPlans, userFfiPlans),
    }));
    setFfi({
      NL: state.ffiPlans.FFI_NL_PROFESSIONAL
        ? 'FFI_NL_PROFESSIONAL'
        : state.ffiPlans.FFI_NL_ESSENTIAL
        ? 'FFI_NL_ESSENTIAL'
        : '',
      DE: state.ffiPlans.FFI_DE_PROFESSIONAL
        ? 'FFI_DE_PROFESSIONAL'
        : state.ffiPlans.FFI_DE_ESSENTIAL
        ? 'FFI_DE_ESSENTIAL'
        : '',
    });
  }, [JSON.stringify(userFfiPlans), state.userId]);

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchFfiPlans());
    dispatch(fetchUserRoles());
  }, []);


  const updateInputFieldHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateRoleFieldHandler = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    setState((prevState) => ({
      ...prevState,
      role: value,
    }));
  };

  const updateStatusFieldHandler = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    setState((prevState) => ({
      ...prevState,
      status: value,
    }));
  };

  const updateNotifyUser = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => ({
      ...prevState,
      notifyUser: !prevState.notifyUser,
    }));
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    dispatch(updateUser(state, () => {
      history.replace(routes.users.list);
      setSavedUserStatus(state.status);
      setSavedUserAccountId(state.accountId)
    }));
  };

  const updateAccountFieldHandler = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    setState((prevState) => ({
      ...prevState,
      accountId: value,
    }));
  };

  const cancelEdit = () => {
    history.replace(routes.users.list);
  };

  const openModalUpdatePassword = () => {
    setIsUpdatePasswordModalVisible(true);
  };

  const closeModalUpdatePassword = (): void => {
    setIsUpdatePasswordModalVisible(false);
  };

  const isAccountFieldEditable = ():boolean => {
    fetchUser(userId,userFetchedSuccessHandler);
    if(savedUserStatus === UserStatus.DISABLED){
      return true;
    } else
      return false;
  }

  const accountFieldToolTip = () => {
    if(!isAccountFieldEditable()) {
      return "You have to disable the user before changing his account association"
    }
  }

  const updateFfiPlansHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    if (name === 'NL') {
      setState((prevState) => ({
        ...prevState,
        ffiPlans: {
          ...state.ffiPlans,
          FFI_NL_PROFESSIONAL: false,
          FFI_NL_ESSENTIAL: false,
          [value]: true,
        },
      }));
    }
    if (name === 'DE') {
      setState((prevState) => ({
        ...prevState,
        ffiPlans: {
          ...state.ffiPlans,
          FFI_DE_PROFESSIONAL: false,
          FFI_DE_ESSENTIAL: false,
          [value]: true,
        },
      }));
    }
    setFfi((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <form className={styles.form} onSubmit={submitHandler}>
      <div className={styles.topForm}>
        <div>
          {isUpdatePasswordModalVisible && (
            <UpdatePasswordModal
              isVisible={isUpdatePasswordModalVisible}
              onClose={closeModalUpdatePassword}
              email={state.email}
            />
          )}
          <label htmlFor="account" className={styles.formRow}>
            Account
            <ReactTooltip id="account-select">{accountFieldToolTip()}</ReactTooltip>
            <div data-for="account-select" data-tip="">
            <select disabled={!isAccountFieldEditable()} name="account" id="account" onChange={updateAccountFieldHandler} value={isAccountFieldEditable() ? state.accountId : savedUserAccountId}>
              {accounts
                .sort((a, b)=>a.name.localeCompare(b.name))
                .filter(({ status }) => status === AccountStatus.ACTIVE)
                .map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
            </select>
            </div>
          </label>
        </div>
        <div>
          <label htmlFor="firstName" className={styles.formRow}>
            First name
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={state.firstName}
              onChange={updateInputFieldHandler}
            />
          </label>
        </div>
        <div>
          <label htmlFor="lastName" className={styles.formRow}>
            Last name
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={state.lastName}
              onChange={updateInputFieldHandler}
            />
          </label>
        </div>
        <div>
          <label htmlFor="email" className={styles.formRow}>
            Email
            <input
              type="text"
              id="email"
              name="email"
              value={state.email}
              onChange={updateInputFieldHandler}
              autoComplete="off"
            />
          </label>
        </div>
        <div>
          <label htmlFor="phone" className={styles.formRow}>
            Phone
            <input type="text" id="phone" name="phone" value={state.phone} onChange={updateInputFieldHandler} />
          </label>
        </div>
        <div>
          <label htmlFor="title" className={styles.formRow}>
            Title
            <input type="text" id="title" name="title" value={state.title} onChange={updateInputFieldHandler} />
          </label>
        </div>
        <div>
          <label htmlFor="role" className={styles.formRow}>
            Role
            <select
              name="role"
              id="role"
              value={state.role}
              onChange={updateRoleFieldHandler}
              disabled={userId === loggedUserId}
            >
              {userRoles.map((role) => (
                <option key={role} value={role}>
                  {userRoleTranslationMap.get(role)}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div>
          <label htmlFor="status" className={styles.formRow}>
            Status
            <select name="status" id="status" value={state.status} onChange={updateStatusFieldHandler}>
              <option key={UserStatus.ACTIVE} value={UserStatus.ACTIVE}>
                {UserStatus.ACTIVE}
              </option>
              <option key={UserStatus.DISABLED} value={UserStatus.DISABLED}>
                {UserStatus.DISABLED}
              </option>
            </select>
          </label>
        </div>
        <div>
          <label htmlFor="password" className={styles.formRow}>
            Password
            <input
              type="password"
              id="password"
              name="password"
              value={state.password}
              onChange={updateInputFieldHandler}
              disabled
            />
          </label>
        </div>
        <Button type="button" onClick={openModalUpdatePassword} className={styles.updatePassword}>
          Update password
        </Button>
      </div>

      {state.ffiPlans && Object.keys(state.ffiPlans).length > 0 && (
        <>
          <div className={`${styles.formRow} ${styles.ffiHeading}`}>
            <h3>
              <u>FFI plans:</u>
            </h3>
          </div>
          <ul>
            <li>
              <FfiPlans
                label="FFI for Netherlands"
                name="NL"
                value={ffi.NL}
                handler={updateFfiPlansHandler}
                includes="FFI_NL"
                ffiPlans={state.ffiPlans}
              />
            </li>
            <li>
              <FfiPlans
                label="FFI for Germany"
                name="DE"
                value={ffi.DE}
                handler={updateFfiPlansHandler}
                includes="FFI_DE"
                ffiPlans={state.ffiPlans}
              />
            </li>
          </ul>
        </>
      )}
      <hr />
      <div className={styles.formTop}>
        <div className={styles.formRow}>
          <label htmlFor="notifyUser">
            <input type="checkbox" name="notifyUser" checked={state.notifyUser} onChange={updateNotifyUser} />
            Notify user
          </label>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={cancelEdit} secondary>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

export default UserForm;
