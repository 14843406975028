import { createStore } from 'effector';
import { toggleDashboardLoading, toggleReportItemsLoadStatus } from './events';
import { ReportItemType } from '../../common/reportItems/types';

export const $isLoading = createStore(false);

export const $reportItemsLoadStatuses = createStore({
  [ReportItemType.MOVEMENT]: true,
  [ReportItemType.CATCHMENT]: true,
  [ReportItemType.ATTRACTION]: true,
  [ReportItemType.RETENTION]: true,
  [ReportItemType.ABSORPTION]: true,
  [ReportItemType.SEASONALITY]: true,
  [ReportItemType.FOOTFALL]: true,
  [ReportItemType.DURATION]: true,
  [ReportItemType.CROSS_VISITATION]: true,
});

export const $areAllReportItemsLoaded = $reportItemsLoadStatuses.map(
  (item) =>
    item.ABSORPTION &&
    item.ATTRACTION &&
    item.CATCHMENT &&
    item.DURATION &&
    item.FOOTFALL &&
    item.LOYALTY &&
    item.RETENTION &&
    item.SEASONALITY,
);

$isLoading.on(toggleDashboardLoading, (_, data) => data);

$reportItemsLoadStatuses.on(toggleReportItemsLoadStatus, (store, { type, value }) => ({ ...store, [type]: value }));
