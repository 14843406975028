import React from 'react';
import { Source, Layer } from 'react-map-gl';
import { HeatmapPaint } from 'mapbox-gl';
import { getColorScaleWithValue } from '../../../common/maps/utils/getGradientUtil';
import { ReportItemType } from '../../reportItems/types';

export interface HeatmapProps {
  id: string;
  data: any;
  min: number;
  max: number;
  valueName: string;
  isMonochrome: boolean;
  reportItemType?: ReportItemType;
}

const Heatmap: React.FC<HeatmapProps> = ({ id, data, min, max, valueName, isMonochrome, reportItemType }) => {
  const paint = {
    'heatmap-weight': ['interpolate', ['linear'], ['get', valueName], min, 0.1, max, 1],
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 5, 12, 8, 15, 6],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(33,102,172,0)',
      ...getColorScaleWithValue(isMonochrome),
    ],
    'heatmap-radius':
      reportItemType === ReportItemType.MOVEMENT
        ? ['interpolate', ['linear'], ['zoom'], 4, 3, 11, 25, 14, 40, 16, 50]
        : ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20, 12, 50],
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0.75, 9, 0.5, 12, 0.3],
  };

  return (
    <Source id={id} type="geojson" data={data}>
      <Layer type="heatmap" paint={paint as HeatmapPaint} id={`${id}_layer`} />
    </Source>
  );
};

export default Heatmap;
