import React, { useMemo, useRef } from 'react';
import { MapRef } from 'react-map-gl';
import { SimpleMap } from '../../../../../common/maps/mapBase';
import { Area, Point } from '../../../../../common/types/visualizationObjects';
import { getViewport } from '../../../../../mainPage/backgroundMap/utils';
import styles from './style.module.scss';
import { CenterPoint } from '../../../../../mainPage/backgroundMap/points/centerPoint';
import { getPolygonsByPoints } from '../../../../../mainPage/backgroundMap/polygons/utils';
import { CircleChartPolygons } from '../../../../../mainPage/backgroundMap/polygons/circleChartPolygons';

interface Props {
  points: Point[];
  postalCodesPoints: Area[];
  onLoad?: VoidFunction;
}
export const LocationVisualizationMap: React.FC<Props> = ({ postalCodesPoints, points, onLoad }) => {
  const mapRef = useRef<MapRef>(null);
  const geo = useMemo(
    () =>
      points.map((point) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
      })) as GeoJSON.Feature<GeoJSON.Geometry>[],
    [points],
  );

  const postalCodesGeo = useMemo(() => getPolygonsByPoints(postalCodesPoints), [postalCodesPoints]);

  const calculatedViewPort = useMemo(() => {
    if (postalCodesGeo?.length && geo.length) {
      const isSmall = geo.length <= 2;
      const data = isSmall ? postalCodesGeo : geo;
      const width = !isSmall ? 230 : 100;
      const height = !isSmall ? 600 : 100;
      return getViewport(data, width, height, 10);
    }

    return null;
  }, [postalCodesGeo, geo]);

  return (
    <div className={`remove-map-logo ${styles.map}`}>
      <SimpleMap initialViewport={calculatedViewPort || null} ref={mapRef} onLoad={onLoad}>
        <>
          <CircleChartPolygons ignoreShadow useCatchmentPostalCodes id="-test" outlineColor="#1f3045" />
          <CenterPoint pointSize="big" />
        </>
      </SimpleMap>
    </div>
  );
};
