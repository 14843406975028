import React from 'react';
import { useTranslation } from 'react-i18next';
import { Center } from './center';
import { Column } from './column';
import styles from './style.module.scss';
import { Lines } from './lines';
import { ItemInformation } from './ItemInformation';

interface Props {
  before: ItemInformation[];
  after: ItemInformation[];
  locationImage: string;
  locationName?: string;
}
export const SpiderChart = ({ before, after, locationImage, locationName = '' }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.chart}>
      <Column items={before} connectionSide="after" title={t('movement.detail.topSourceLocations')} />
      <Lines items={before} side="left" />
      <div className={styles.center}>
        <Center image={locationImage} title={locationName} />
      </div>
      <Lines items={after} side="right" />
      <Column items={after} connectionSide="before" title={t('movement.detail.topDestinationLocations')} />
    </div>
  );
};
