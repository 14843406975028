import { CatchmentMapTypesEnum } from '../enums';
import { CatchmentTypeEnum } from '../../../../reportItems/types';

const typeToRequestMode = {
  [`${CatchmentTypeEnum.HOME}_${CatchmentMapTypesEnum.POSTAL_CODES}`]: 'HOME_POLYGON',
  [`${CatchmentTypeEnum.HOME}_${CatchmentMapTypesEnum.HEAT_MAP}`]: 'HOME_HEATMAP',
  [`${CatchmentTypeEnum.HOME}_${CatchmentMapTypesEnum.TRIPS}`]: 'HOME_TRIP_ROUTES',
  [`${CatchmentTypeEnum.HOME}_${CatchmentMapTypesEnum.ISOCHRONES}`]: 'HOME_POLYGON',
  [`${CatchmentTypeEnum.WORK}_${CatchmentMapTypesEnum.POSTAL_CODES}`]: 'WORK_POLYGON',
  [`${CatchmentTypeEnum.WORK}_${CatchmentMapTypesEnum.HEAT_MAP}`]: 'WORK_HEATMAP',
  [`${CatchmentTypeEnum.WORK}_${CatchmentMapTypesEnum.TRIPS}`]: 'WORK_TRIP_ROUTES',
  [`${CatchmentTypeEnum.WORK}_${CatchmentMapTypesEnum.ISOCHRONES}`]: 'WORK_POLYGON',
};

export const getRequestModeFromType = (type: CatchmentTypeEnum, item: CatchmentMapTypesEnum) =>
  typeToRequestMode[`${type}_${item}`];
