import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Field is required'),
  newPassword: yup.string().required('Field is required'),
  reTypedNewPassword: yup
    .string()
    .required('Field is required')
    .test('passwords-match', 'Passwords must match', function (value) {
      if (!this.parent.newPassword) return true;
      if (!value) return true;
      return this.parent.newPassword === value;
    }),
});
