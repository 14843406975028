import { API_URLS } from '../../../routes';
import { request } from '../../request';

export const getAnalyticsData = <T>(reportItemId: string, primaryLocation: string, timeFrames?:string[]|null): Promise<T> => {
  const BASE_URL = API_URLS.REPORT_ITEMS_DATA;

  const timeFramesQueryParam = timeFrames ? `timeFrames=${timeFrames.join(',')}` : '';

  const url = `${BASE_URL}/${reportItemId}/data/movement/spider/${primaryLocation}?${timeFramesQueryParam}`;
  return request({ url });
};
