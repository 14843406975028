import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './style.module.scss';
import { Routes } from '../../../routes';

export const Information = () => (
  <div className={styles.information}>
    <span className={styles.question}>Don't have an account yet?</span>
    <NavLink to={Routes.signUp}>Get started</NavLink>
  </div>
);
