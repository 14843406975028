import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../../../common/types/mainContext';
import styles from './popup.module.scss';
import { removeReport } from '../../../../api/report/remove';
import { Popup } from '../../../../common/popup';
import { Button } from '../../../../common/controls/buttons';
import { ReportInfo } from '../../../../common/types/projectsHierarchy';
import { InformationIcon } from '../../../../common/icons/reportItems';

interface TProps {
  report: ReportInfo;
  isOpen: boolean;
  toggleOpen: (val: boolean) => void;
}

export const DeleteReportPopup = ({ report, isOpen, toggleOpen }: TProps) => {
  const { t } = useTranslation();
  const { projectsHierarchy, setProjectsHierarchy } = useContext(MainContext);

  const [isError, setError] = useState<boolean>(false);

  const onReportDelete = async () => {
    try {
      const res = await removeReport(report.id);
      const hierarchy = projectsHierarchy.map((item) =>
        item.reports.some((r) => r.id === report.id)
          ? {
              ...item,
              reports: item.reports.filter((r) => r.id !== report.id),
            }
          : item,
      );
      setProjectsHierarchy(hierarchy);
      toggleOpen(false);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={() => toggleOpen(false)} height={350}>
      <div className={styles.title}>{t('delete.reportTitle')}</div>
      <div className={styles.subtitle}>{t('delete.reportSubtitle')}</div>
      <div className={styles.warning}>
        <InformationIcon /> <span>{t('delete.reportWarning')}</span>
      </div>
      <div className={styles.subtitle}>{t('delete.paragraph2')}</div>
      <div style={{ height: '50px' }} />
      {isError && <div className={styles.error}>Unexpected error during deleting of the project</div>}
      <div className={styles.buttons}>
        <Button onClick={() => toggleOpen(false)}>{t('reportItem.cancel')}</Button>

        <Button layout="primary" onClick={onReportDelete}>
          {t('reportItem.delete')}
        </Button>
      </div>
    </Popup>
  );
};
