import React from 'react';
import styles from './tableRow.module.scss';
import { InformationIcon } from '../../../icons/reportItems';

interface TProps {
  name: React.ReactNode;
  value?: React.ReactNode;
  required?: boolean;
  hideBorder?: boolean;
  className?: string;
  classNameNameContainer?: string;
  tooltip?: React.ReactNode;
  tooltipTitle?: string;
  openTooltip?: VoidFunction;
  tooltipRef?: any;
}

const TableRow = (props: TProps) => (
  <div className={`${styles.tableRow} ${props.hideBorder ? styles.noneBorder : ''} ${props.className || ''}`}>
    {props.tooltip}
    <div className={`${styles.nameContainer} ${props.classNameNameContainer}`}>
      <div className={styles.nameField}>{props.name}</div>
      {props.tooltip && (
        <span className={styles.tooltipIconContainer} ref={props.tooltipRef}>
          <span className={styles.tooltipIcon} onClick={props.openTooltip} title={props.tooltipTitle}>
            <InformationIcon />
          </span>
        </span>
      )}
    </div>
    <div className={styles.valueField}>{props.value}</div>
  </div>
);

export default TableRow;
