import React, { useMemo } from 'react';
import { EChartsOption } from 'echarts';
import { TEXT_STYLE } from '../../../common/chart/configuration';
import { Chart } from '../../../common/chart/chart';
import styles from './durationCardChart.module.scss';
import { DurationCategory, DurationData } from '../../../common/types/projectsHierarchy';
import { mapValueToCategory } from '../mapper';
import { Skeleton } from '../../../common/skeleton';

function getDurationChartOptions(data: DurationData[], averageCategory: DurationCategory): EChartsOption {
  return {
    grid: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 20,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      type: 'category',
      data: Object.keys(DurationCategory),
      axisLabel: {
        color: '#FFFFFF',
        interval: 0,
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        opacity: 0.6,
      },
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
    },
    yAxis: {
      type: 'value',
      nameLocation: 'middle',
      splitNumber: 2,
      axisLabel: {
        show: false,
      },
      nameTextStyle: {
        ...TEXT_STYLE,
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.07)',
        },
      },
    },
    tooltip: {
      appendToBody: true,
      borderColor: 'rgba(255, 255, 255, 0.3)',
      className: styles.durationTooltip,
      backgroundColor: '##1F303D',
      textStyle: {
        color: '#FFFFFF',
      },
      formatter(params: any) {
        const displayedValue = (+params.value * 100).toFixed(2);
        let toolTipHtml = `<span>Duration</span> <span>${params.name}: </span>`;
        toolTipHtml += `<b>${displayedValue}%</b>`;
        return toolTipHtml;
      },
    },
    series: {
      type: 'bar',
      color: 'rgba(255, 255, 255, 0.6)',
      itemStyle: {
        borderRadius: [3, 3, 0, 0],
      },
      emphasis: {
        itemStyle: {
          color: '#00B2F1',
        },
      },
      data: data.map((el) => {
        if (el.category === averageCategory) {
          return {
            value: el.value,
            itemStyle: {
              color: '#00B2F1',
            },
          };
        }
        return el.value;
      }),
    },
  };
}

interface Props {
  data: DurationData[];
  average: number;
  isLoading: boolean;
}

const DurationCardChart: React.FC<Props> = ({ data, average, isLoading }) => {
  const options = useMemo(() => {
    const averageCategory = mapValueToCategory(average);
    return getDurationChartOptions(data, averageCategory);
  }, [data, average]);

  return (
    <div className="chart-container" style={{ height: '60px' }}>
      {isLoading ? <Skeleton className={styles.skeleton} /> : <Chart option={{ ...options }} />}
    </div>
  );
};

export default DurationCardChart;
