import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CatchmentItemData, CatchmentItemValue } from '../types';
import BaseTable, { BaseTableHeaderProps } from '../../../common/baseTable';
import { CatchmentMetricTypeEnum, CatchmentTypeEnum } from '../../../common/reportItems/types';
import { useTimePeriod } from '../../../common/hooks/useTimePeriod';
import { MainContext } from '../../../common/types/mainContext';
import {separateThousandsByComma} from "../../../common/utils";

interface TableDataFormat {
  head: string;
  options: {
    subhead: {
      value: string | number;
      formatter: () => string | number;
    };
    values: {
      inhabitants: number | string;
      aerial_distance: string | number;
      visits_share: number;
    };
  }[];
}

interface Props {
  data: CatchmentItemValue;
  source: CatchmentTypeEnum;
}

const CatchmentDetailTable: React.FC<Props> = ({ data, source }) => {
  const { t } = useTranslation();
  const timeFrameString = useTimePeriod();
  const {
    reportItemsDetails: { catchmentMetricType },
  } = useContext(MainContext);

  const tableHeaders: BaseTableHeaderProps[] = useMemo(() => {
    const headers = [];
    headers.push({key: 'inhabitants', name: t('catchment.detail.numberOfInhabitants'), unit: ''})
    headers.push({ key: 'aerial_distance', name: t('catchment.detail.aerialDistance'), unit: 'km' });
    if (CatchmentMetricTypeEnum.SCORE === catchmentMetricType) {
      headers.push({ key: 'visits_share', name: t(`catchment.enum.${catchmentMetricType}`), unit: '' });
    } else if (CatchmentMetricTypeEnum.SOURCE === catchmentMetricType) {
      headers.push({ key: 'visits_share', name: t(`catchment.enum.${catchmentMetricType}`), unit: '%' });
    } else if (CatchmentMetricTypeEnum.DESTINATION === catchmentMetricType) {
      headers.push({ key: 'visits_share', name: t(`catchment.enum.${catchmentMetricType}`), unit: '%' });
    }
    return headers;
  }, [catchmentMetricType]);

  const exportTableHeaders: BaseTableHeaderProps[] = useMemo(
    () => [
      { key: 'inhabitants', name: `${t('catchment.detail.numberOfInhabitants')}`, unit: '' },
      { key: 'aerial_distance', name: `${t('catchment.detail.aerialDistance')} (${t('reportItem.km')})`, unit: '' },
      { key: 'visits_share', name: t(`catchment.enum.${catchmentMetricType}`), unit: '' },
      { key: 'timeframe', name: t('reportItem.timeFrame'), unit: '' },
    ],
    [catchmentMetricType],
  );

  const normalizeValue = (value: string) => {
    if (value) {
      const normalizedValue = value.replaceAll(' ', '');
      return Number.isNaN(Number(normalizedValue)) ? value : 'n/a';
    }
    return value;
  };

  const getTableDataValue = (data: CatchmentItemData, isPercentage = false) => {
    if (CatchmentMetricTypeEnum.SCORE === catchmentMetricType) {
      return data.geo_entity_catchment_score !== undefined && data.geo_entity_catchment_score !== null
        ? data.geo_entity_catchment_score * (isPercentage ? 100 : 1)
        : 0;
    }
    if (CatchmentMetricTypeEnum.SOURCE === catchmentMetricType) {
      return data.geo_entity_share_of_source !== undefined && data.geo_entity_share_of_source !== null
        ? data.geo_entity_share_of_source * (isPercentage ? 100 : 1)
        : 0;
    }
    if (CatchmentMetricTypeEnum.DESTINATION === catchmentMetricType) {
      return data.geo_entity_share_of_target !== undefined && data.geo_entity_share_of_target !== null
        ? data.geo_entity_share_of_target * (isPercentage ? 100 : 1)
        : 0;
    }
    return 0;
  };

  const tableData: TableDataFormat[] = useMemo(() => {
    const catchmentValues = source === CatchmentTypeEnum.HOME ? data.home : data.work;
    return catchmentValues.map((item) => ({
      head: data.location_name,
      options: [
        {
          subhead: {
            value: `${item.geo_entity_name ? `${item.geo_entity_name}: ` : ''}${item.geo_entity_code}`,
            formatter: () =>
              normalizeValue(`${item.geo_entity_name ? `${item.geo_entity_name}: ` : ''}${item.geo_entity_code}`),
          },
          values: {
            inhabitants: item.inhabitants ? separateThousandsByComma(item.inhabitants) : '-',
            aerial_distance: item.aerial_distance > 0 ? item.aerial_distance : '-',
            visits_share: getTableDataValue(item, true),
          },
        },
      ],
    }));
  }, [data, source, catchmentMetricType]);

  const exportTableData: TableDataFormat[] = useMemo(() => {
    const catchmentValues = source === CatchmentTypeEnum.HOME ? data.home : data.work;
    return catchmentValues.map((item) => ({
      head: data.location_name,
      options: [
        {
          subhead: {
            value: `${item.geo_entity_name ? `${item.geo_entity_name}: ` : ''}${item.geo_entity_code}`,
            formatter: () =>
              normalizeValue(`${item.geo_entity_name ? `${item.geo_entity_name}: ` : ''}${item.geo_entity_code}`),
          },
          values: {
            inhabitants: item.inhabitants ? separateThousandsByComma(item.inhabitants) : '',
            aerial_distance: item.aerial_distance > 0 ? `${item.aerial_distance}` : '',
            visits_share: getTableDataValue(item),
            timeframe: timeFrameString,
          },
        },
      ],
    }));
  }, [data, source, catchmentMetricType]);

  return (
    <BaseTable<TableDataFormat>
      data={tableData}
      headers={tableHeaders}
      headName={t('location.primaryLocation')}
      subheadName={t('catchment.geoEntityName')}
      exportHeaders={exportTableHeaders}
      exportData={exportTableData}
      subHeadAsString={false}
    />
  );
};

export default CatchmentDetailTable;
