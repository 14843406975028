import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { EChartsOption } from 'echarts';
import Chart, { getTooltipHeight } from '../../../common/chart';
import { AbsorptionTargetLocation, LocationInfo } from '../../../common/types/projectsHierarchy';
import styles from './style.module.scss';
import { dateFormatter } from '../lib/dateFormatter';
import { sortByValue } from '../../../common/utils';
import { useGraphHeight } from '../../../common/reportItems/hooks';
import { PeriodsEnum } from '../../../common/reportItems/types';
import { ChartEvents } from '../../../common/chart/interfaces';
import { DefaultLegend } from '../../../common/chart/legends';
import { useDoubleClick } from '../../../common/chart/hooks';

interface Props {
  data: AbsorptionTargetLocation[];
  projectLocations: LocationInfo[];
  events?: ChartEvents;
  isScreenshotMode?: boolean;
  useFixedScreenshotSize?: boolean;
}

function getOptions(
  data: AbsorptionTargetLocation[],
  projectLocations: LocationInfo[],
  t: (val: string) => string,
  selectedLocations: Record<string, boolean>,
  isScreenshotMode?: boolean,
): EChartsOption {
  return {
    grid: {
      top: 20,
      left: 70,
      right: 35,
      bottom: 100,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      type: 'category',
      data: data[0].dateChanges.map((el) => el.timestamp),
      axisLabel: {
        formatter: (value: number) => dateFormatter(String(value)),
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        rotate: 90,
        opacity: 0.6,
        margin: 12,
      },
      axisLine: {
        onZero: true,
        lineStyle: {
          type: 'solid',
          opacity: 0.1,
          color: '#ffffff',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          width: 1,
          color: '#FFFFFF',
          opacity: 0.1,
        },
      },
    },
    yAxis: {
      type: 'value',
      name: t('absorption.detail.percentOfVisitsToPoi'),
      nameLocation: 'middle',
      axisLabel: {
        formatter: (value: number) => `${+(value * 100).toFixed(1)}%`,
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
      },
      axisLine: {
        lineStyle: {
          type: 'solid',
          opacity: 0.1,
          color: '#ffffff',
        },
      },
      nameTextStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      splitLine: {
        lineStyle: {
          opacity: 0.1,
          type: 'solid',
          width: 1,
          color: '#FFFFFF',
        },
      },
      nameGap: 50,
    },
    legend: {
      selected: selectedLocations,
      show: !isScreenshotMode,
      align: 'auto',
      itemGap: 25,
      bottom: 0,
      icon: 'circle',
      type: 'scroll',
      textStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
    },
    tooltip: {
      borderColor: 'rgba(255, 255, 255, 0.3)',
      appendToBody: true,
      trigger: 'axis',
      triggerOn: 'click',
      formatter: (params: any) => {
        if (Array.isArray(params)) {
          const sortedData = sortByValue(params);
          const header = `
              <div class="${styles.header}">
                <span class="${styles.title}">${t('absorption.detail.percentOfVisitsToPoi')}</span><br />
                <span class="${styles.date}">${dateFormatter(sortedData[0].name, 'MMMM YYYY')}</span>
              </div>`;

          let items = '';

          sortedData.forEach((item) => {
            items += `<div class="${styles.row}">
                  <span class="${styles.location}">${item.marker} ${item.seriesName}</span>
                  <span class="${styles.value}">${(+item.value * 100).toFixed(2)}%</span>
                </div>`;
          });

          const maxItemsHeight = getTooltipHeight(params.length, 5);

          return `
            ${header}
            <div class="custom-scrollbar ${styles.items}" style="height:${maxItemsHeight}px;">
            ${items}
            </div>
          `;
        }
        return '';
      },
      className: styles.seasonalityTooltip,
      backgroundColor: 'rgba(31,48,61, 0.6)',
      axisPointer: {
        type: 'line',
        axis: 'x',
        lineStyle: {
          color: '#999',
        },
      },
    },
    series: data.map((options, i) => {
      const location = projectLocations.find((loc) => loc.id === options.locationId);
      return {
        type: 'line',
        smooth: true,
        symbol: 'none',
        stack: `${i}`,
        color: location?.color || '#FFF',
        name: options.locationName,
        data: options.dateChanges.map((item) => item.absorption),
        lineStyle:
          options.locationId === 'average'
            ? {
                type: 'dotted',
                width: 4,
              }
            : undefined,
      };
    }),
  };
}

const AbsorptionDetailChart: React.FC<Props> = ({
  data,
  projectLocations,
  events,
  isScreenshotMode,
  useFixedScreenshotSize,
}) => {
  const { t } = useTranslation();
  const { chartRef, onChange, selectedLocations } = useDoubleClick();
  const options = useMemo(
    () => getOptions(data, projectLocations, t, selectedLocations, isScreenshotMode),
    [data, isScreenshotMode, selectedLocations],
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { graphHeight } = useGraphHeight(wrapperRef, PeriodsEnum.MONTHLY, data.length);

  const legendItems = useMemo(() => {
    return data?.map(({ locationId, locationName }) => {
      const fullLocationInformation = projectLocations.find(({ id }) => id === locationId);
      return {
        id: locationId,
        label: locationName,
        color: fullLocationInformation ? fullLocationInformation?.color || '' : 'grey',
      };
    });
  }, [data]);

  return (
    <div>
      <div style={{ height: useFixedScreenshotSize ? '300px' : graphHeight }} ref={wrapperRef}>
        <Chart option={{ ...options }} events={{ legendselectchanged: onChange, ...events }} ref={chartRef} />
      </div>
      {isScreenshotMode && (
        <div className={styles.legend}>
          <DefaultLegend data={legendItems} />
        </div>
      )}
    </div>
  );
};

export default AbsorptionDetailChart;
