import { createEffect } from 'effector';
import { Credentials } from '../interfaces';
import { signUp } from '../../../../api';
import { errorOccurred } from './events';

export const userSignUp = createEffect(async (data: Credentials) => {
  try {
    const { company, name, role, email, phone, password } = data;
    await signUp({ company, name, role, email, phone, password });
    return { email, name };
  } catch (error) {
    const data = error.response.data;
    errorOccurred(data.reason);
  }
});
