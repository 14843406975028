import { PeriodsEnum } from '../../common/reportItems/types';
import { LocationInfo, ReportItemInfo, Retention } from '../../common/types/projectsHierarchy';
import { RetentionResponse } from './types';
import { filterByLocationName, mapReportItemType } from '../utils';
import { populateWithMissingTimeFrames } from './utils';
import { calculateRetentionReturningVisits } from './utils/calculateRetentionReturningVisits';
import { generateMapPoints } from '../utils/mappers/generateMapPoints';
import { generateMapPoiPolygons } from '../utils/mappers/generateMapPoiPolygons';
import { toFixNumber } from '../../common/lib';
import { getReportItems } from '../../api';
import { getCorrectPointsIfCoordinatesAreWrong } from '../../common/maps/utils';

const convertData = ({ values, id, name, type }: RetentionResponse, locations: LocationInfo[]) => {
  const average = calculateRetentionReturningVisits(values);

  const mapPointsData = values.map(({ location_id, location_name }) => {
    const currentLocationAverage = average.returningVisitsLocationsAverage.get(location_id);

    return {
      location_id,
      location_name,
      average: currentLocationAverage || 0,
    };
  });

  const initialPointsData = mapPointsData.map((pointData) => ({
    ...pointData,
    average: `${toFixNumber(pointData.average * 100, 2)}%`,
  }));

  const points = generateMapPoints(initialPointsData, locations);
  const poiPolygons = generateMapPoiPolygons(values, locations);

  return {
    id,
    name,
    data: {
      averageInfo: average,
      data: values,
    },
    type: mapReportItemType(type),
    visualization: {
      points: getCorrectPointsIfCoordinatesAreWrong(points, poiPolygons),
      poiPolygons,
    },
  };
};
export const fetchRetentionData = async (
  reportItemId: string,
  locationIds: string[],
  period: PeriodsEnum = PeriodsEnum.MONTHLY,
  locations: LocationInfo[] = [],
  selectedTimeFrames: string[] | null,
) => {
  try {
    const response = await getReportItems<RetentionResponse>(reportItemId, period, locationIds, selectedTimeFrames);
    const convertedData = convertData(response, locations);
    const modifiedData = populateWithMissingTimeFrames(
      selectedTimeFrames,
      period,
      filterByLocationName(convertedData.data.data),
    );
    const filledWithMissingTimes = {
      ...convertedData,
      data: {
        ...convertedData.data,
        data: modifiedData,
      },
    } as ReportItemInfo<Retention>;

    return filledWithMissingTimes;
  } catch (error) {}
};
