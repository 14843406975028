import React from 'react';

export const MapIcon: React.FC = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1667 0L12.06 0.02L8.5 1.4L4.5 0L0.74 1.26667C0.6 1.31333 0.5 1.43333 0.5 1.58667V11.6667C0.5 11.8533 0.646667 12 0.833333 12L0.94 11.98L4.5 10.6L8.5 12L12.26 10.7333C12.4 10.6867 12.5 10.5667 12.5 10.4133V0.333333C12.5 0.146667 12.3533 0 12.1667 0ZM5.16667 1.64667L7.83333 2.58V10.3533L5.16667 9.42V1.64667ZM1.83333 2.30667L3.83333 1.63333V9.43333L1.83333 10.2067V2.30667ZM11.1667 9.69333L9.16667 10.3667V2.57333L11.1667 1.8V9.69333Z"
      fill="white"
    />
  </svg>
);

export default MapIcon;
