import React, { useContext, useMemo } from 'react';
import Heatmap from '../../../common/maps/heatmap/heatmap';
import { CatchmentTypeEnum, ColorSchemeEnum, ReportItemType } from '../../../common/reportItems/types';
import { MainContext } from '../../../common/types/mainContext';

export const CatchmentHeatmap = () => {
  const { selection, primaryLocationId, reportItemsDetails } = useContext(MainContext);
  const heatmap = useMemo(() => {
    const locationPolygons = primaryLocationId
      ? selection.selectedReportItem?.visualization?.heatmap?.find((item) => item.location_id === primaryLocationId) ||
        null
      : null;
    if (reportItemsDetails.catchmentType === CatchmentTypeEnum.HOME) {
      return locationPolygons?.home
        ? {
            ...locationPolygons.home,
            points: locationPolygons.home.points.filter(
              (item) =>
                (item.value || 0) <= reportItemsDetails.catchmentTreshold.max &&
                (item.value || 0) >= reportItemsDetails.catchmentTreshold.min,
            ),
          }
        : null;
    }
    if (reportItemsDetails.catchmentType === CatchmentTypeEnum.WORK) {
      return locationPolygons?.work
        ? {
            ...locationPolygons.work,
            points: locationPolygons.work.points.filter(
              (item) =>
                (item.value || 0) <= reportItemsDetails.catchmentTreshold.max &&
                (item.value || 0) >= reportItemsDetails.catchmentTreshold.min,
            ),
          }
        : null;
    }
    return null;
  }, [
    primaryLocationId,
    reportItemsDetails.catchmentType,
    reportItemsDetails.catchmentTreshold,
    selection.selectedReportItem,
  ]);

  const featureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: heatmap?.points.map((point) => ({
        type: 'Feature',
        properties: point,
        geometry: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
      })),
    }),
    [heatmap],
  );

  return (
    heatmap && (
      <Heatmap
        id="heatmap_home"
        data={featureCollection}
        min={heatmap.minValue}
        max={heatmap.maxValue}
        valueName="rank"
        isMonochrome={reportItemsDetails.colorScheme === ColorSchemeEnum.MONOCHROME}
        reportItemType={ReportItemType.CATCHMENT}
      />
    )
  );
};
