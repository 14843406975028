import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../common/tooltip';
import { TooltipPositionCoordinates } from '../../../../../common/reportItems/hooks';

interface Props {
  onClose: VoidFunction;
  position?: TooltipPositionCoordinates;
  isOpen: boolean;
}

export const AbsorptionMainTooltip: React.FC<Props> = ({ onClose, position, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      onClose={onClose}
      title={t('reportItem.howItWorks')}
      initialPosition={position}
      isOpen={isOpen}
      height={300}
    >
      <>
        <p>
          {t('absorption.tooltip.paragraph1')}
          <br />
          {t('absorption.tooltip.paragraph2')}
        </p>
        <p>
          {t('absorption.tooltip.exampleTitle')}
          <br />
          <i>{t('absorption.tooltip.exampleParagraph1')}</i>
        </p>
      </>
    </Tooltip>
  );
};
