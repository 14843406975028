import React, { useContext } from 'react';
import styles from './locations.module.scss';
import { MainContext } from '../../../common/types/mainContext';
import LocationsDropdown from './locationsDropdown/locationsDropdown';
import { LocationsMiniSlidingBar } from '../../../common/sliders';
import { countries } from '../../../common/utils';

interface Props {
  showShadow: boolean;
}
const LocationsCard = ({ showShadow }: Props) => {
  const { selectedLocations, primaryLocationId } = useContext(MainContext);
  const topLocation = selectedLocations.find((location) => location.id === primaryLocationId) || null;
  const country = countries.find(({ code }) => code === topLocation?.country?.toLowerCase());

  return (
    <div className={`${styles.locationsCard} ${showShadow ? styles.shadow : ''}`}>
      <LocationsDropdown />
      {topLocation && (
        <div className={styles.address}>
          <div>{`${topLocation.street} ${topLocation.houseNumber}`}</div>
          <div>{`${topLocation.city} ${country?.label || ''}`}</div>
        </div>
      )}
      <LocationsMiniSlidingBar />
    </div>
  );
};

export default LocationsCard;
