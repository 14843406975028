import React from 'react';
import styles from './loginPageContainer.module.scss';
import PlacenseLogoBlue from '../../../../atoms/placenseLogoBlue/placenseLogoBlue';

interface TProps {
  children: React.ReactNode;
}

export const Page = ({ children }: TProps) => (
  <div className={styles.container} style={{ backgroundImage: 'url(/img/common/login-backgroud.jpg)' }}>
    <div className={styles.loginBox}>
      <div className={styles.logo}>
        <PlacenseLogoBlue />
      </div>
      <div className={styles.companyName}>PlaceSense</div>
      {children}
    </div>
  </div>
);
