import { TrendValue } from '../types';
import { toFixNumber } from '../../../common/lib';
import { calculateLocationAverageByValues } from '../../../common/utils';
import { getOnlyValue } from './getOnlyValue';

export const calculateValuesAverage = (values: TrendValue[]) => {
  let totalRecords = 0;
  const locationsAverage = new Map<string, number>();
  let average = 0;
  values.forEach(({ data, location_id }) => {
    totalRecords += data.length;
    const locationAverage = calculateLocationAverageByValues(getOnlyValue(data));
    locationsAverage.set(location_id, toFixNumber(locationAverage / data.length, 3));
    average += toFixNumber(locationAverage, 3);
  });

  average /= totalRecords;

  return {
    average: toFixNumber(average, 3),
    locationsAverage,
  };
};
