import { Area, Point } from '../../types/visualizationObjects';
import { isPointInPolygon } from './isPointInPolygon';
import { getPolygonsByPoints } from '../../../mainPage/backgroundMap/polygons/utils';
import { PointDistance } from '../../../mainPage/backgroundMap/trips/interfaces';
import { getDistanceBetweenTwoPoints } from './getDistanceBetweenTwoPoints';
import { getClosestPoint } from '../../../mainPage/backgroundMap/aoiWithRoads/utils';

export const getCorrectPointsIfCoordinatesAreWrong = (points: Point[], polygons: Area[]) =>
  points.map((entity) => {
    const { location_id: pointLocationId } = entity;
    const polygon = polygons.find(({ location_id }) => pointLocationId === location_id);

    if (polygon) {
      const { longitude, latitude } = entity;
      const point = [longitude, latitude] as [number, number];
      const isLocationInPolygon = isPointInPolygon(point)(polygon);

      if (!isLocationInPolygon) {
        const geo = getPolygonsByPoints([{ geo: polygon.geo }]);

        const pointsDistances: PointDistance[] = [];
        geo.forEach((polygon) => {
          const { geometry } = polygon;
          if (geometry.type === 'Point') {
            const { coordinates } = geometry;
            const distance = getDistanceBetweenTwoPoints(point, coordinates);
            pointsDistances.push({ distance, to: point, from: coordinates });
          }
        });
        const {
          from: [longitude, latitude],
        } = getClosestPoint(pointsDistances);
        if (longitude && latitude) {
          return { ...entity, longitude, latitude };
        }
        return entity;
      }
      return entity;
    }
    return entity;
  });
