import jwt_decode from 'jwt-decode';
import { DecodedCredentials } from '../interfaces';

export const decodeUserCredentials = (token: string) => {
  const {
    userId,
    companyName,
    userRole,
    title,
    phone,
    userEmail,
    userName,
    accountId,
    accountType,
  }: DecodedCredentials = jwt_decode(token);

  const user = {
    accountId,
    accountType,
    userRole,
    userName,
    userEmail,
    userId,
    avatar: '',
    companyLogo: '',
  };

  return { userId, companyName, userRole, title, phone, userEmail, userName, accountId, accountType, user };
};
