import React, { useRef } from 'react';
import { CheckIcon, SortByIcon } from '../icons/dropdown';
import { useClickOutside, useToggle } from '../hooks';
import styles from './style.module.scss';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';
import { ButtonTooltip } from '../controls/tooltip/buttonTooltip';

interface Props {
  options: string[];
  activeOption: string;
  onChange: (option: string) => void;
  title?: string;
}

export const SortBy = ({ options, activeOption, onChange, title }: Props) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const componentRef = useRef(null);
  const onClick = () => {
    toggleOpen();
  };

  const onCloseList = () => {
    toggleOpen(false);
  };

  useClickOutside(componentRef, onCloseList, isOpen);

  const onSortOptionClick = (option: string) => {
    trackUserAction('Left panel sorting changed', UserActionsEnum.SORTING_CHANGE, option);
    onChange(option);
    onCloseList();
  };

  return (
    <div className={styles.wrapper} ref={componentRef}>
      <ButtonTooltip title={title}>
        <div className={`${styles.icon} ${isOpen ? styles.active : ''}`} onClick={onClick}>
          <SortByIcon isActive={isOpen} />
        </div>
      </ButtonTooltip>
      {isOpen && (
        <div className={styles.container}>
          <p className={styles.title}>Sort by</p>
          <div className={styles.list}>
            {options.map((option) => (
              <div className={styles.item} onClick={() => onSortOptionClick(option)}>
                <span>{option}</span>
                {option === activeOption && <CheckIcon />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
