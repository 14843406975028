import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { Popup } from '../../../../tooltips';

interface Props {
  onClose: () => void;
  onDelete: () => void;
}

const DeleteLocationPopup = ({ onClose, onDelete }: Props) => {
  const { t } = useTranslation();

  return (
    <Popup onNo={onClose} onYes={onDelete} withActions>
      <div className={styles.container}>
        <h3>{t('edit.deleteLocation.title')}</h3>
        <p>{t('edit.deleteLocation.content')}</p>
      </div>
    </Popup>
  );
};

export default DeleteLocationPopup;
