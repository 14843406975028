import { createEffect } from 'effector';
import { createProjectLocations, getProjectLocations, removeLocations } from '../../../api';
import { convertPolygonsToPoints, generatePolygonString, splitCoordinatesByPolygons } from '../../polylineTool';
import { PolygonData } from '../createLocation/interfaces';
import { editLocation as editLocationAction } from '../../../api/projects/locations/edit';

export const getLocations = createEffect(async (projectId: string) => {
  try {
    const locations = await getProjectLocations(projectId);
    return locations.map((location) => ({
      ...location,
      polygonString: location.polygon?.points
        ?.map((coords) => [coords.longitude, coords.latitude])
        .flat(2)
        .join(',\n'),
    }));
  } catch (error) {
    return [];
  }
});

export const deleteLocationOnBackend = createEffect(
  async ({
    projectId,
    locationsIds,
    onSuccess,
  }: {
    projectId: string;
    locationsIds: string[];
    onSuccess: () => void;
  }) => {
    try {
      await removeLocations(projectId, locationsIds);
      onSuccess();
      return true;
    } catch (error) {
      return false;
    }
  },
);

export const createLocation = createEffect(
  async ({
    projectId,
    data,
    onSuccess,
  }: {
    projectId: string;
    data: Omit<PolygonData, 'id'>;
    onSuccess: () => void;
  }) => {
    try {
      const { polygonString, ...rest } = data;
      const parsedPolygon = splitCoordinatesByPolygons(polygonString ? polygonString.split('\n') : []);
      const isMultiple = parsedPolygon.length > 1;

      const payload = {
        ...rest,
        address: '',
        houseNumber: '',
        polygon: {
          points: !isMultiple ? convertPolygonsToPoints(parsedPolygon)[0] : undefined,
          wktRepresentation: isMultiple ? generatePolygonString(parsedPolygon) : undefined,
        },
      };
      await createProjectLocations(projectId, [payload]);
      onSuccess();
      return true;
    } catch (error) {
      return false;
    }
  },
);

export const createLocations = createEffect(
  async ({ projectId, data, onSuccess }: { projectId: string; data: PolygonData[]; onSuccess: () => void }) => {
    try {
      const payload = data.map((location) => {
        const { polygonString, ...rest } = location;

        return {
          ...rest,
          houseNumber: '',
          polygon: {
            points: undefined,
            wktRepresentation: polygonString,
          },
        };
      });

      await createProjectLocations(projectId, payload);
      onSuccess();
      return true;
    } catch (error) {
      return false;
    }
  },
);

export const editLocation = createEffect(
  async ({
    projectId,
    locationId,
    name,
    description,
    onSuccess,
  }: {
    projectId: string;
    locationId: string;
    name: string;
    description: string;
    onSuccess: () => void;
  }) => {
    try {
      const data = { name, description };
      await editLocationAction(projectId, locationId, data);
      onSuccess();
    } catch (error) {
      console.log(error);
    }
  },
);
