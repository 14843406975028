import React from 'react';
import styles from './empty.module.scss';
import { LinkButton } from '../../controls/buttons';

const Empty: React.FC = () => (
  <div className={styles.noDataContainer}>
    <div className={styles.noDataBody}>
      <>
        <h3>There's not enough data to show this report.</h3>
        <h4>Please try extending the timeframe you're analyzing.</h4>
        <p>
          If that doesn't help, please contact us and a customer success representative will get back to you shortly
        </p>
        <LinkButton url="https://placesense.ai/contact/" text="Contact us" external filled />
      </>
    </div>
  </div>
);

Empty.defaultProps = {
  isLoading: false,
};

export default Empty;
