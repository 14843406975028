import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import ReportItemDetail from '../../../common/reportItems/reportItemDetail';
import { ContentVisualizationMode, ReportItemInfo, Seasonality } from '../../../common/types/projectsHierarchy';
import { ReportItemType } from '../../../common/reportItems/types';
import SeasonalityDetailTable from './SeasonalityDetailTable';
import SeasonalityDetailChart from './SeasonalityDetailChart';
import { MainContext } from '../../../common/types/mainContext';
import { exportSettingsModel } from '../../../common/export';
import { useElementScreenshot } from '../../../common/hooks/useElementScreenshot';
import { ShowAverageToggle } from '../../../common/reportItems/reportItemDetail/showAverageToggle/showAverageToggle';
import { convertDataForExcel } from './utils';
import { ShowHoursByToggle } from '../../../common/reportItems/reportItemDetail/showHoursByToggle/ShowHoursByToggle';

interface Props {
  useFixedScreenshotSize?: boolean;
}
export const SeasonalityDetail = ({ useFixedScreenshotSize }: Props) => {
  const { t } = useTranslation();

  const {
    selection: { selectedProject, selectedReport },
    selectedLocationsIds,
    contentVisualizationMode,
    reportItemsDetails: { showAverageValuesInChart },
  } = useContext(MainContext);

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.SEASONALITY,
  ) as ReportItemInfo<Seasonality>;

  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const isChartsReady = useRef({ daily: false, hourly: false });
  const isExportMode = useStore(exportSettingsModel.$isExportMode);
  const [modifiedData, setModifiedData] = useState<Seasonality | null>(null);

  const { onMakeScreenshot, base64Image, elementRef, onClearScreenshotData } = useElementScreenshot({
    backgroundColor: `rgba(37, 55, 76, 1)`,
  });

  const onChartReady = (type: string) => () => {
    if (!isChartsReady.current.hourly || !isChartsReady.current.daily) {
      isChartsReady.current = { ...isChartsReady.current, [type]: true };
      if (
        exportOptions.SEASONALITY?.chart &&
        isChartsReady.current.daily &&
        isChartsReady.current.hourly &&
        reportItem?.data
      ) {
        onMakeScreenshot();
      }
    }
  };

  useEffect(() => {
    const data = reportItem?.data;
    if (data) {
      let chartData = { ...data } as Seasonality;
      if (chartData.daily.length > 1 && showAverageValuesInChart) {
        chartData = {
          ...chartData,
          daily: [
            {
              locationId: 'average',
              locationName: 'Average',
              mostPopularDay: { dayOfWeekNumber: 1, visitsShare: 1 },
              data: chartData?.averageDailyAcrossLocations || [],
            },
            ...chartData.daily,
          ],
        };
      }
      if (chartData.hourlyByWeek.length > 1 && showAverageValuesInChart) {
        chartData = {
          ...chartData,
          hourlyByWeek: [
            {
              locationId: 'average',
              locationName: 'Average',
              mostPopularHour: { dayOfWeekNumber: 1, hourOfDay: 1, visitsShare: 1 },
              data: chartData?.averageHourlyByWeekAcrossLocations || [],
              weeklyAverage: [],
            },
            ...chartData.hourlyByWeek,
          ],
        } as Seasonality;
      }
      if (chartData.hourlyByDay.length > 1 && showAverageValuesInChart) {
        chartData = {
          ...chartData,
          hourlyByDay: [
            {
              locationId: 'average',
              locationName: 'Average',
              mostPopularHour: { dayOfWeekNumber: 1, hourOfDay: 1, visitsShare: 1 },
              data: chartData?.averageHourlyByDayAcrossLocations || [],
              weeklyAverage: [],
            },
            ...chartData.hourlyByDay,
          ],
        } as Seasonality;
      }
      setModifiedData(chartData);
    }
  }, [reportItem?.data, showAverageValuesInChart]);

  const render = (data: Seasonality | null) => {
    if (!data) return;

    if (contentVisualizationMode === ContentVisualizationMode.VISUALIZATION || isExportMode) {
      return (
        <SeasonalityDetailChart
          data={data}
          projectLocations={selectedProject?.locations || []}
          onReady={onChartReady}
          isScreenshotMode={isExportMode}
          useFixedScreenshotSize={useFixedScreenshotSize}
        />
      );
    }
    return <SeasonalityDetailTable data={reportItem?.data?.hourlyByWeek || []} />;
  };

  useEffect(() => {
    if (!reportItem.data) {
      exportSettingsModel.updateExportData({ [ReportItemType.SEASONALITY]: { chart: '', table: '' } });
    }
  }, []);

  useEffect(() => {
    if (base64Image) {
      exportSettingsModel.updateExportData({ [ReportItemType.SEASONALITY]: { chart: base64Image } });
      onClearScreenshotData();
    }
  }, [base64Image]);

  useEffect(() => {
    if (reportItem.data?.hourlyByWeek && exportOptions.SEASONALITY?.table) {
      const data = convertDataForExcel(reportItem.data.hourlyByWeek);
      exportSettingsModel.updateExportData({ [ReportItemType.SEASONALITY]: { table: data } });
    }
  }, [exportOptions.SEASONALITY]);

  const minimizedData = useMemo(() => render(modifiedData), [modifiedData, isExportMode, contentVisualizationMode]);
  return (
    <div ref={elementRef} style={{ width: isExportMode ? 'auto' : '100%', height: isExportMode ? 'auto' : '100%' }}>
      <ReportItemDetail
        type={ReportItemType.SEASONALITY}
        title={t('seasonality.title')}
        subtitle={t('seasonality.detail.showingPopularDays')}
        noData={!reportItem?.data || selectedLocationsIds.length <= 0}
        onMakeScreenshot={onMakeScreenshot}
      >
        {contentVisualizationMode === ContentVisualizationMode.VISUALIZATION && (
          <div>
            <ShowAverageToggle />
            <ShowHoursByToggle />
          </div>
        )}
        {reportItem?.data && minimizedData}
      </ReportItemDetail>
    </div>
  );
};
