import { ExportData, ExportDataOptions } from '../../model/interfaces';
import { AddFile, createZip, ZipInstance } from '../lib/zip';
import { generateFileName } from './generateFileName';

const parseDataString = (base64: string) => base64.split(',')[1];

const generateScreenshots = (
  zip: ZipInstance,
  addFile: AddFile,
  data: Partial<ExportDataOptions> | undefined,
  fileName: string,
) => {
  if (!data) return;

  const { chart, map } = data;

  if (chart) {
    addFile(zip, `${fileName} chart.jpg`, parseDataString(chart), { base64: true });
  }

  if (map) {
    addFile(zip, `${fileName} map.jpg`, parseDataString(map), { base64: true });
  }
};

export const generateZIP = (exportData: ExportData, name: string) => {
  const { zip, addFile, generate } = createZip();
  const fileName = generateFileName(name, 'zip');

  const { FOOTFALL, CATCHMENT, SEASONALITY, DURATION, MOVEMENT, LOYALTY, RETENTION, ABSORPTION, ATTRACTION } =
    exportData;

  generateScreenshots(zip, addFile, FOOTFALL, 'Visit trends');
  generateScreenshots(zip, addFile, CATCHMENT, 'Catchment');
  generateScreenshots(zip, addFile, DURATION, 'Duration');
  generateScreenshots(zip, addFile, SEASONALITY, 'Seasonality');
  generateScreenshots(zip, addFile, RETENTION, 'Retention');
  generateScreenshots(zip, addFile, LOYALTY, 'Loyalty');
  generateScreenshots(zip, addFile, MOVEMENT, 'Movement');
  generateScreenshots(zip, addFile, ATTRACTION, 'Attraction');
  generateScreenshots(zip, addFile, ABSORPTION, 'Absorption');

  generate(zip, fileName, { type: 'blob' });
};
