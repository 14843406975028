import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableDataFormat } from '../types';
import BaseTable, { BaseTableHeaderProps } from '../../../common/baseTable';
import { AbsorptionTargetLocation } from '../../../common/types/projectsHierarchy';
import { dateFormatter } from '../lib/dateFormatter';
import { useTimePeriod } from '../../../common/hooks/useTimePeriod';

const defaultTableHeaders: BaseTableHeaderProps[] = [{ key: 'value', name: '% of visits to POI', unit: '%' }];

interface Props {
  data: AbsorptionTargetLocation[];
  locationName: string;
}

const AbsorptionDetailTable: React.FC<Props> = ({ data, locationName }) => {
  const { t } = useTranslation();
  const timeFrameString = useTimePeriod();
  const tableData: TableDataFormat[] = data.map((item) => ({
    head: item.locationName,
    options: [
      {
        subhead: {
          value: locationName,
          formatter: () => locationName,
        },
        values: Object.fromEntries(
          new Map(item.dateChanges.map((change) => [`${change.timestamp}`, `${(change.absorption * 100).toFixed(2)}`])),
        ),
      },
    ],
  }));

  const exportTableData: TableDataFormat[] = data.map((item) => ({
    head: item.locationName,
    options: [
      {
        subhead: {
          value: locationName,
          formatter: () => locationName,
        },
        values: {
          ...Object.fromEntries(
            new Map(item.dateChanges.map((change) => [`${change.timestamp}`, `${change.absorption}`])),
          ),
          timeframe: timeFrameString,
        },
      },
    ],
  }));

  const tableHeaders = data[0]
    ? data[0].dateChanges.map((item) => ({
        key: `${item.timestamp}`,
        name: dateFormatter(`${item.timestamp}`, 'MMM YYYY'),
        unit: '%',
      }))
    : defaultTableHeaders;

  const exportHeaders = useMemo(
    () =>
      data[0]
        ? [
            ...data[0].dateChanges.map((item) => ({
              key: `${item.timestamp}`,
              name: dateFormatter(`${item.timestamp}`, 'YYYY-MM-DD'),
              unit: '',
            })),
            { key: 'timeframe', name: t('reportItem.timeFrame'), unit: '' },
          ]
        : defaultTableHeaders,
    [data],
  );

  return (
    <BaseTable<TableDataFormat>
      data={tableData}
      headers={tableHeaders}
      exportHeaders={exportHeaders}
      headName={t('absorption.detail.locationName')}
      subheadName={t('absorption.detail.source')}
      exportData={exportTableData}
    />
  );
};

export default AbsorptionDetailTable;
