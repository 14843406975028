import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../../../common/types/mainContext';
import styles from './popup.module.scss';
import { Popup } from '../../../../common/popup';
import { TextInput } from '../../../../common/controls/input/textInput';
import { TextArea } from '../../../../common/controls/input/textArea';
import { Button } from '../../../../common/controls/buttons';
import { ReportInfo } from '../../../../common/types/projectsHierarchy';
import { editReport } from '../../../../api/report/edit';

interface TProps {
  report: ReportInfo;
  isOpen: boolean;
  toggleOpen: (val: boolean) => void;
}

export const EditReportPopup = ({ report, isOpen, toggleOpen }: TProps) => {
  const { t } = useTranslation();
  const { projectsHierarchy, setProjectsHierarchy } = useContext(MainContext);

  const [reportName, setReportName] = useState<string>(report.name);
  const [reportDesc, setReportDesc] = useState<string>(report.description || '');
  const [isError, setError] = useState<boolean>(false);

  const onProjectSave = async () => {
    if (reportName.length > 0) {
      try {
        const res = await editReport(report.id, reportName, reportDesc, report.visualization_url);
        const hierarchy = projectsHierarchy.map((item) =>
          item.reports.some((r) => r.id === report.id)
            ? {
                ...item,
                reports: item.reports.map((r) =>
                  r.id === report.id ? { ...r, name: reportName, description: reportDesc } : r,
                ),
              }
            : item,
        );
        setProjectsHierarchy(hierarchy);
        toggleOpen(false);
      } catch (error) {
        console.error(error);
        setError(true);
      }
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={() => toggleOpen(false)} height={460}>
      <div className={styles.title}>{t('edit.reportTitle')}</div>
      <div className={styles.subtitle}>{t('edit.reportSubtitle')}</div>
      <div className={styles.formRow}>
        <div className={styles.left}>{t('create.name')}</div>
        <div className={styles.right}>
          <TextInput name="reportName" value={reportName} onChange={setReportName} />
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.left}>{t('create.description')}</div>
        <div className={styles.right}>
          <TextArea name="reportDescription" value={reportDesc} onChange={setReportDesc} />
        </div>
      </div>
      {isError && <div className={styles.error}>Unexpected error during saving process</div>}
      <div className={styles.buttons}>
        <Button onClick={() => toggleOpen(false)}>{t('reportItem.cancel')}</Button>
        <Button
          layout="primary"
          onClick={onProjectSave}
          disabled={reportName.length === 0 || (report.name === reportName && report.description === reportDesc)}
        >
          {t('reportItem.save')}
        </Button>
      </div>
    </Popup>
  );
};
