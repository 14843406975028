import React from 'react';
import styles from './yearMonthLabel.module.scss';
import { CalendarIcon } from '../../icons/yearMonthPicker';
import { CircleButton } from '../../controls/buttons/circleButton/circleButton';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  isMenuOpened: boolean;
  onToggleMenu: (event: React.MouseEvent) => void;
}

const YearMonthLabel: React.FC<Props> = ({ startDate, endDate, isMenuOpened, onToggleMenu }) => {
  const label =
    startDate === null || endDate === null
      ? 'Select time frames'
      : `${startDate.toLocaleString('default', {
          month: 'long',
        })} ${startDate.getFullYear()} - ${endDate.toLocaleString('default', {
          month: 'long',
        })} ${endDate.getFullYear()}`;

  return (
    <div className={styles.timeFrames} onClick={onToggleMenu} type="button">
      {label}
      <CircleButton active={isMenuOpened} icon={<CalendarIcon />} title="Report timeframe" />
    </div>
  );
};

export default YearMonthLabel;
