import React, { CSSProperties } from 'react';
import { Marker } from 'react-map-gl';
import styles from './pin.module.scss';
import { PrimaryPinIcon } from '../../icons/map/primaryPinIcon';
import { maxLengthOfString } from '../../utils/stringUtils';

interface PinProps {
  longitude: number;
  latitude: number;
  name?: string;
  value?: string | number;
  color?: string;
  primary?: boolean;
  onClick?: () => void;
}

const IDENTIFIER_NAME = 'pin';
const Pin: React.FC<PinProps> = ({ latitude, longitude, color, onClick, name, value, primary = false }) => (
  <Marker longitude={longitude} latitude={latitude}>
    <div
      className={styles.root}
      style={color ? ({ '--pin-color': color } as CSSProperties) : {}}
      onClick={onClick}
      data-identifier={IDENTIFIER_NAME}
    >
      <div className={styles.pin} data-identifier={IDENTIFIER_NAME}>
        {primary && (
          <div className={styles.primaryIcon} data-identifier={IDENTIFIER_NAME}>
            <PrimaryPinIcon />
          </div>
        )}
        <div className={primary ? styles.title_primary : styles.title} data-identifier={IDENTIFIER_NAME} title={name}>
          {maxLengthOfString(name || '', 18)}
        </div>
        <div className={styles.value} data-identifier={IDENTIFIER_NAME}>
          {value}
        </div>
        <div className={styles.arrow} data-identifier={IDENTIFIER_NAME} />
      </div>
    </div>
  </Marker>
);

export default React.memo(Pin);
