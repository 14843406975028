import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from '../../common/tooltips';

interface Props {
  onNo: VoidFunction;
  onYes: VoidFunction;
}
const ConfirmCloseTooltip: React.FC<Props> = ({ onNo, onYes }) => {
  const { t } = useTranslation();

  return (
    <Popup width="400px" title={t('create.cancel.title')} onNo={onNo} onYes={onYes} withCloseActions>
      <p>{t('create.cancel.paragraph1')}</p>
      <p>{t('create.cancel.paragraph2')}</p>
    </Popup>
  );
};

export default ConfirmCloseTooltip;
