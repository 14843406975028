import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable, { BaseTableHeaderProps } from '../../../common/baseTable';
import { CrossVisitationLocation } from '../../../common/types/projectsHierarchy';
import { toFixNumber } from '../../../common/lib';
import { useTimePeriod } from '../../../common/hooks/useTimePeriod';

interface TableDataFormat {
  head: string;
  options: {
    subhead: {
      value: string | number;
      formatter: () => string | number;
    };
    values: {
      visitors_loyalty: number;
      aerial_distance: string;
      ratio: string;
    };
  }[];
}

interface Props {
  data: CrossVisitationLocation[];
}

export const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const timeFrameString = useTimePeriod();

  const tableHeaders: BaseTableHeaderProps[] = [
    { key: 'aerial_distance', name: t('crossVisitation.detail.aerialDistance'), unit: '' },
    { key: 'visitors_loyalty', name: t('crossVisitation.detail.shareOfVisitors'), unit: '%' },
    { key: 'ratio', name: t('crossVisitation.detail.visitsRatio'), unit: '' },
  ];

  const exportTableHeaders: BaseTableHeaderProps[] = [
    { key: 'aerial_distance', name: t('crossVisitation.detail.aerialDistance'), unit: '' },
    { key: 'visitors_loyalty', name: t('crossVisitation.detail.shareOfVisitors'), unit: '' },
    { key: 'ratio', name: t('crossVisitation.detail.visitsRatio'), unit: '' },
    { key: 'timeframe', name: t('reportItem.timeFrame'), unit: '' },
  ];

  const tableData: TableDataFormat[] = useMemo(
    () =>
      data
        ? data.map((location) => ({
            head: location.primary_location_name || '',
            options:
              location.data.map((item) => ({
                subhead: { value: item.secondary_location_name, formatter: () => item.secondary_location_name },
                values: {
                  aerial_distance: item.aerial_distance > 0 ? `${item.aerial_distance} km` : '-',
                  visitors_loyalty: toFixNumber(item.visitors_loyalty * 100, 2),
                  ratio: item.ratio,
                },
              })) || [],
          }))
        : [],
    [data],
  );

  const exportTableData: TableDataFormat[] = useMemo(
    () =>
      data
        ? data.map((location) => ({
            head: location.primary_location_name || '',
            options:
              location.data.map((item) => ({
                subhead: { value: item.secondary_location_name, formatter: () => item.secondary_location_name },
                values: {
                  aerial_distance: item.aerial_distance > 0 ? `${item.aerial_distance} km` : '-',
                  visitors_loyalty: item.visitors_loyalty,
                  ratio: item.ratio,
                  timeframe: timeFrameString,
                },
              })) || [],
          }))
        : [],
    [data],
  );

  return (
    <BaseTable<TableDataFormat>
      data={tableData}
      headers={tableHeaders}
      headName={t('crossVisitation.detail.primaryLocation')}
      subheadName={t('crossVisitation.detail.secondaryLocation')}
      exportHeaders={exportTableHeaders}
      exportData={exportTableData}
    />
  );
};
