import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useURLParams } from '../../../../common/hooks';
import { MainContext } from '../../../../common/types/mainContext';
import styles from './locationsCountDropdown.module.scss';
import Dropdown from '../../../../common/controls/dropdown';
import { Option } from '../../../../common/controls/dropdown/types';
import { CatchmentLocationsCountEnum, ColorSchemeEnum } from '../../../../common/reportItems/types';
import { Skeleton } from '../../../../common/skeleton';
import { SwitchModes } from '../../../../common/types/visualizationObjects';

interface TProps {
  isLoading: boolean;
}

export const LocationsCountDropdown = ({ isLoading }: TProps) => {
  const { t } = useTranslation();
  const { onChangeParams } = useURLParams();
  const {
    reportItemsDetails: { catchmentLocationsCount },
    updateReportItemsDetailsValue,
    catchmentLayer,
    setCatchmentLayer,
  } = useContext(MainContext);

  const onDropdownChange = (option: Option<null>) => {
    if (option.id) {
      // TODO track action in mixpanel
      updateReportItemsDetailsValue('catchmentLocationsCount', option.id);
      onChangeParams('catchmentLocationsCount', option.id);
      if (option.id === CatchmentLocationsCountEnum.MULTIPLE.toString()) {
        updateReportItemsDetailsValue('colorScheme', ColorSchemeEnum.MONOCHROME);
        updateReportItemsDetailsValue('catchmentDataOverlay', true);
        onChangeParams('colorScheme', ColorSchemeEnum.MONOCHROME);
        onChangeParams('catchmentDataOverlay', 'YES');
        if (catchmentLayer !== SwitchModes.POSTAL_CODES) {
          setCatchmentLayer(SwitchModes.POSTAL_CODES);
        }
      } else {
        updateReportItemsDetailsValue('colorScheme', ColorSchemeEnum.COLORFUL);
        updateReportItemsDetailsValue('catchmentDataOverlay', false);
        onChangeParams('colorScheme', ColorSchemeEnum.COLORFUL);
        onChangeParams('catchmentDataOverlay', 'NO');
      }
    }
  };

  const dropdownOptions = Object.keys(CatchmentLocationsCountEnum).map((item) => ({
    id: item,
    label: t(`catchment.enum.${item}`),
    item: null,
    disabled: false,
  }));

  return (
    <div className={styles.locationsCountDropdown}>
      {isLoading ? <Skeleton /> : <span className={styles.label}>{t('catchment.card.analyzeBy')}</span>}

      <div className={styles.dropdownWrapper}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Dropdown<null> selectedId={catchmentLocationsCount} options={dropdownOptions} onChange={onDropdownChange} />
        )}
      </div>
    </div>
  );
};
