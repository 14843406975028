import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../../../common/types/mainContext';
import styles from './popup.module.scss';
import { editProject } from '../../../../api/projects/edit';
import { Popup } from '../../../../common/popup';
import { TextInput } from '../../../../common/controls/input/textInput';
import { TextArea } from '../../../../common/controls/input/textArea';
import { Button } from '../../../../common/controls/buttons';
import { ProjectInfo } from '../../../../common/types/projectsHierarchy';

interface TProps {
  project: ProjectInfo;
  isOpen: boolean;
  toggleOpen: (val: boolean) => void;
}

export const EditProjectPopup = ({ project, isOpen, toggleOpen }: TProps) => {
  const { t } = useTranslation();
  const { projectsHierarchy, setProjectsHierarchy } = useContext(MainContext);

  const [projectName, setProjectName] = useState<string>(project.name);
  const [projectDesc, setProjectDesc] = useState<string>(project.description || '');
  const [isError, setError] = useState<boolean>(false);

  const onProjectSave = async () => {
    if (projectName.length > 0) {
      try {
        const res = await editProject(project.id, projectName, projectDesc);
        const hierarchy = projectsHierarchy.map((item) =>
          item.id === project.id ? { ...item, name: projectName, description: projectDesc } : item,
        );
        setProjectsHierarchy(hierarchy);
        toggleOpen(false);
      } catch (error) {
        console.error(error);
        setError(true);
      }
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={() => toggleOpen(false)} height={460}>
      <div className={styles.title}>{t('edit.projectTitle')}</div>
      <div className={styles.subtitle}>{t('edit.projectSubtitle')}</div>
      <div className={styles.formRow}>
        <div className={styles.left}>{t('create.name')}</div>
        <div className={styles.right}>
          <TextInput name="projectName" value={projectName} onChange={setProjectName} />
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.left}>{t('create.description')}</div>
        <div className={styles.right}>
          <TextArea name="projectDescription" value={projectDesc} onChange={setProjectDesc} />
        </div>
      </div>
      {isError && <div className={styles.error}>Unexpected error during saving process</div>}
      <div className={styles.buttons}>
        <Button onClick={() => toggleOpen(false)}>{t('reportItem.cancel')}</Button>
        <Button
          layout="primary"
          onClick={onProjectSave}
          disabled={projectName.length === 0 || (project.name === projectName && project.description === projectDesc)}
        >
          {t('reportItem.save')}
        </Button>
      </div>
    </Popup>
  );
};
