export enum ValidationErrorType {
  NUMBER_OF_LOCATIONS,
  NUMBER_OF_CLUSTERS,
  POLYGON_SIZE,
  CSV,
}

export enum CSVErrorType {
  MISSING_NAME,
  WRONG_NUMBER_OF_COLUMNS,
  POLYGON_SIZE,
}

export interface ValidationError {
  type: ValidationErrorType;
}

export interface PolygonSizeValidationError extends ValidationError {
  size: number;
}

export interface ErrorMessageValidationError extends ValidationError {
  message: string;
}

export interface CSVImportValidationErrorRow {
  id?: string;
  row: number;
  type: CSVErrorType;
}

export interface CSVImportValidationError extends ValidationError {
  errors: CSVImportValidationErrorRow[];
}
