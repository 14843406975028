import React, { useMemo } from 'react';
import { EChartsOption } from 'echarts';
import { OrdinalRawValue } from 'echarts/types/src/util/types';
import { HourlySeasonality } from '../../../common/types/projectsHierarchy';
import { Chart } from '../../../common/chart/chart';
import { Skeleton } from '../../../common/skeleton';
import styles from './style.module.scss';

interface Props {
  data: HourlySeasonality[];
  mostPopularHour: HourlySeasonality;
  isLoading: boolean;
}

function getOptions(data: HourlySeasonality[], mostPopularHour: HourlySeasonality): EChartsOption {
  return {
    grid: {
      show: true,
      top: 0,
      left: 12,
      right: 12,
      bottom: 25,
      borderColor: 'rgba(255, 255, 255, 0.5)',
      borderWidth: 1,
      shadowColor: 'transparent',
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      type: 'category',
      min: 0,
      max: 24,
      data: data.map((el) => el.hourOfDay),
      axisLabel: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
        interval: 5,
        showMaxLabel: true,
        showMinLabel: true,
        formatter: (rawHour: OrdinalRawValue | number) => {
          const period = rawHour >= 12 ? 'pm' : 'am';
          const hour = rawHour > 12 ? `${Number(rawHour) - 12}` : Number(rawHour > 0 ? rawHour : 12);
          return `${hour}${period}`;
        },
      },
      axisLine: { onZero: true },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.1,
          type: 'solid',
          width: 1,
          color: '#ededed',
        },
      },
    },
    yAxis: {
      type: 'value',
      splitNumber: 3,
      axisLabel: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          opacity: 0.07,
          type: 'solid',
          width: 1,
          color: '#FFFFFF',
        },
      },
      nameGap: 50,
    },
    legend: {
      show: false,
    },
    tooltip: {
      show: false,
    },
    series: {
      type: 'line',
      smooth: true,
      symbol: 'none',
      markLine: {
        lineStyle: {
          type: 'dotted',
          color: '#ededed',
          width: 2,
        },
        symbol: ['none', 'none'],
        label: {
          show: false,
        },
        data: [
          {
            name: 'maximum',
            xAxis: mostPopularHour.hourOfDay,
          },
        ],
      },
      markPoint: {
        symbol: 'circle',
        symbolSize: 10,
        itemStyle: {
          color: '#FFFFFF',
        },
        data: [
          {
            name: 'maximum',
            type: 'max',
            label: {
              show: false,
            },
          },
        ],
      },
      data: data.map((entry) => entry.visitsShare),
      color: '#00b2f1',
    },
  };
}

const SeasonalityCardChart: React.FC<Props> = ({ data, mostPopularHour, isLoading }) => {
  const options = useMemo(() => getOptions(data, mostPopularHour), [data]);

  return (
    <div className="chart-container" style={{ height: '60px' }}>
      {isLoading ? <Skeleton className={styles.skeleton} /> : <Chart option={{ ...options }} />}
    </div>
  );
};

export default SeasonalityCardChart;
