import React from 'react';
import { PieItem } from '../../../../common/chart';
import { toFixNumber } from '../../../../common/lib';
import styles from './style.module.scss';

interface Props {
  data: PieItem[];
}
export const PieChartWrapperWithLeftLegend: React.FC<Props> = ({ data, children }) => (
  <div className={styles.component}>
    {children}
    <div className={styles.wrapper}>
      {data.map(({ value }) => (
        <div key={value}>{toFixNumber(Number(value), 1)}%</div>
      ))}
    </div>
  </div>
);
