import React, { useState, useContext, useRef } from 'react';
import i18n from '../../../../i18n';
import { useClickOutside } from '../../hooks';
import { MainContext } from '../../types/mainContext';
import { CountryIcon } from './countryIcon';
import { LanguagesContainer } from './languagesContainer';
import styles from './style.module.scss';

export const LanguageSelector = () => {
  const [isOpen, toggleOpen] = useState<boolean>(false);
  const { selectedLanguage, setSelectedLanguage } = useContext(MainContext);

  const ref = useRef(null);
  useClickOutside(ref, () => toggleOpen(false), isOpen);

  const languageChanged = (lng: string) => {
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng);
    toggleOpen(false);
  };

  return (
    <div className={styles.languageSelector} ref={ref}>
      <div className={styles.selectedLanguage}>
        <button
          type="button"
          style={{ background: `../../icons/countries/${selectedLanguage}.png` }}
          onClick={() => toggleOpen(!isOpen)}
        >
          <CountryIcon code={selectedLanguage} />
        </button>
      </div>
      {isOpen && <LanguagesContainer selected={selectedLanguage} onChange={languageChanged} />}
    </div>
  );
};
