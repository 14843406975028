import React, { FC, useMemo } from 'react';
import { EChartsOption } from 'echarts';
import { Chart } from '../../../common/chart/chart';
import { TEXT_STYLE } from '../../../common/chart/configuration';
import { setTooltipPosition } from '../../../common/chart/utils';
import { PieChartWrapperWithLeftLegend } from '../../../common/chart';
import { Skeleton } from '../../../common/skeleton';
import styles from './style.module.scss';

function getDurationChartOptions(data: any[]): EChartsOption {
  return {
    tooltip: {
      trigger: 'item',
      position(pos) {
        return setTooltipPosition(pos);
      },
    },
    legend: {
      top: 'center',
      orient: 'vertical',
      icon: 'circle',
      itemHeight: 10,
      itemWidth: 10,
      left: 95,
      textStyle: {
        ...TEXT_STYLE,
        fontWeight: 'normal',
        color: 'white',
      },
      formatter(name) {
        return `${name}`;
      },
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        width: 100,
        tooltip: {
          formatter({ name, value }: any) {
            return `<div>${name}: ${value}%</div>`;
          },
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: data.map((item, index) => ({
          ...item,
          itemStyle: {
            color: index === 0 ? 'rgba(255, 255, 255, 0.6)' : '#00B2F1',
          },
        })),
      },
    ],
  };
}

interface Props {
  data: any;
  isLoading: boolean;
}

export const CrossVisitationChart: FC<Props> = ({ data, isLoading }) => {
  const options = useMemo(() => getDurationChartOptions(data), [data]);

  return (
    <div className="chart-container" style={{ height: '88px' }}>
      {isLoading ? (
        <Skeleton className={styles.skeleton} />
      ) : (
        <PieChartWrapperWithLeftLegend data={data}>
          <Chart option={{ ...options }} />
        </PieChartWrapperWithLeftLegend>
      )}
    </div>
  );
};
