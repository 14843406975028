import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../common/controls/dropdown';
import { RecurrenceFrequencyEnum } from '../../../types';
import styles from './style.module.scss';

interface TProps {
  frequency: RecurrenceFrequencyEnum;
  onChange: (frequency: RecurrenceFrequencyEnum) => void;
  disabled?: boolean;
}

type TOption = {
  id: string;
  label: string;
  item: null;
};

export const RecurrenceFrequencyDropdown = ({ frequency, onChange, disabled }: TProps) => {
  const { t } = useTranslation();

  const optionsList: TOption[] = [
    { id: RecurrenceFrequencyEnum.MONTHLY, label: t('visitTrends.enum.MONTHLY'), item: null },
    { id: RecurrenceFrequencyEnum.WEEKLY, label: t('visitTrends.enum.WEEKLY'), item: null },
    ...(process.env.REACT_APP_API_URL !== 'https://portal-prod-1-dot-gifted-slice-218906.ey.r.appspot.com'
      ? [{ id: RecurrenceFrequencyEnum.DAILY, label: t('visitTrends.enum.DAILY'), item: null }]
      : []),
  ];

  const onDropdownChange = (item: TOption) => {
    if (item.id) {
      onChange(item.id as RecurrenceFrequencyEnum);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Dropdown<null>
        onChange={onDropdownChange}
        selectedId={frequency}
        options={optionsList}
        classNames={styles.dropdown}
        disabled={disabled}
      />
    </div>
  );
};
