import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import ContentContainer from '../page/pageContent/contentContainer';
import Table from '../page/table/table';
import { LocationManager } from './locationManager';
import { CreateLocation } from './createLocation';
import ButtonsContainer from '../page/pageContent/buttonsContainer';
import { Button } from '../controls/buttons';
import { useToggle } from '../hooks';
import { PolygonData } from './createLocation/interfaces';
import { SidebarContext } from '../sidebarMenu/sidebarContext';
import { LocationManagerModel } from './model';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';
import { LocationsData } from '../../createReport/types';
import isMaxNumberOfLocationsReached from './locationManager/validation/isMaxNumberOfLocationsReached';
import { ValidationError, ValidationErrorType } from './locationManager/validation/validationErrorType';
import ValidationErrorMessage from './locationManager/validation/ValidationErrorMessage';
import { MainContext } from '../types/mainContext';
import { fetchLimitations } from '../../api/limitations/fetchLimitations';
import styles from './manageLocations.module.scss';
import { useHeight } from '../reportItems/hooks/useHeight';
import { GDPRForm } from '../../createReport/steps/stepTwo/gdprForm';
import { IReportCategory } from '../../../types/report';

interface Props {
  data: LocationsData;
  setData: (data: LocationsData) => void;
  navigate?: (step: number) => void;
  projectId: string;
  projectName?: string;
  isEdit?: boolean;
  onCancel?: VoidFunction;
  reportCategory?: IReportCategory;
}

const ManageLocations = ({
                           data,
                           setData,
                           navigate = () => {},
                           projectId,
                           projectName,
                           isEdit = false,
                           onCancel,
                           reportCategory,
                         }: Props) => {
  const { t } = useTranslation();
  const [isValidationMessageOpened, setIsValidationMessageOpened] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<ValidationError>();
  const [isLocationEditorOpen, toggleIsLocationEditorOpen] = useToggle(false);
  const [editedLocation, setEditedLocation] = useState<PolygonData | null>(null);
  const { isOpened } = useContext(SidebarContext);
  const [isGDPRConfirmed, setIsGDPRConfirmed] = useState<boolean>(false);
  const {
    limitations: { maximumNumberOfLocations },
    setLimitations,
  } = useContext(MainContext);
  const title = isEdit ? t('locations.manageTitle') : t('create.addLocations');
  const subtitle = isEdit ? `${t('locations.manageSubtitle')} ${projectName}` : t('create.searchLocations');
  const blueTitle = isEdit ? undefined : `${t('create.step')} 2`;
  const confirmGDPR = () => setIsGDPRConfirmed(true);

  const projectLocations = useStore(LocationManagerModel.$projectLocations);
  const selectedLocationsInformation = useStore(LocationManagerModel.$selectedLocationsInformation);
  const selectedLocations = useStore(LocationManagerModel.$selectedLocations);
  const tableRef = useRef<HTMLDivElement>(null);
  const { height: tableHeight } = useHeight(tableRef);

  const getLimitations = async () => {
    try {
      const limitations = await fetchLimitations();
      const maximumNumberOfLocations = Number(limitations?.MAX_LOCATIONS?.value);
      const maximumNumberOfClusters = Number(limitations?.MAX_CLUSTERS?.value);
      const polygonSize = Number(limitations?.MAX_AREA?.value);
      const aoiPolygonSize = Number(limitations?.MAX_AREA_AOI?.value) || polygonSize * 100;
      setLimitations(maximumNumberOfLocations, maximumNumberOfClusters, polygonSize, aoiPolygonSize);
    } catch (e) {
      console.log('failed to fetch limitations');
      console.log(e);
    }
  };

  useEffect(() => {
    getLimitations();
  }, []);

  useEffect(() => {
    trackUserAction('Create report', UserActionsEnum.CREATE_REPORT, 'Step 2');
    if (data.locations.length === 0) {
      LocationManagerModel.resetLocations();
    }
  }, []);

  useEffect(() => {
    setData({ locations: selectedLocationsInformation });
  }, [selectedLocationsInformation]);

  useEffect(() => {
    getProjectLocations(projectId);
  }, [projectId]);

  useEffect(() => {
    if (isEdit) {
      LocationManagerModel.saveAndClearLocations(projectLocations);
    }
  }, [projectLocations, projectId]);

  const getProjectLocations = async (projectId: string) => {
    await LocationManagerModel.getLocations(projectId);
  };

  const validateData = () => data.locations.length > 0;

  const clickNext = () => {
    if (validateData()) {
      navigate(3);
    }
  };

  const onLocationCreatorOpen = (locationId: string | null) => {
    if (!isEdit && isMaxNumberOfLocationsReached(selectedLocations, maximumNumberOfLocations)) {
      onValidationError({ type: ValidationErrorType.NUMBER_OF_LOCATIONS });
    } else {
      if (locationId) {
        const location = selectedLocationsInformation.find((loc) => loc.id === locationId);
        setEditedLocation(location || null);
      } else {
        setEditedLocation(null);
      }
      toggleIsLocationEditorOpen(true);
    }
  };

  const onValidationError = (error: ValidationError) => {
    setIsValidationMessageOpened(true);
    setValidationError(error);
  };

  const onCloseValidationErrorMessage = () => setIsValidationMessageOpened(false);

  return (
      <>
        <ContentContainer isOpened={isOpened} spacing>
          {isValidationMessageOpened && validationError && (
              <ValidationErrorMessage
                  error={validationError}
                  onClose={onCloseValidationErrorMessage}
                  isOpen={isValidationMessageOpened}
              />
          )}
          {!isGDPRConfirmed && <GDPRForm isOpen={!isGDPRConfirmed} confirm={confirmGDPR} />}
          <Table title={title} subtitle={subtitle} blueTitle={blueTitle} className={styles.table} ref={tableRef}>
            {!isLocationEditorOpen && (
                <LocationManager
                    locations={projectLocations}
                    onGoToCreateNewLocation={onLocationCreatorOpen}
                    projectId={projectId}
                    projectName={projectName}
                    isEdit={isEdit}
                    onValidationError={onValidationError}
                    height={tableHeight}
                    reportCategory={reportCategory}
                />
            )}
          </Table>
          {isLocationEditorOpen && (
              <CreateLocation
                  onCancel={toggleIsLocationEditorOpen}
                  projectId={projectId}
                  onClose={() => toggleIsLocationEditorOpen(false)}
                  editedLocation={editedLocation}
                  isEdit={isEdit}
                  onValidationError={onValidationError}
                  reportCategory={reportCategory}
              />
          )}
          {!isEdit && !isLocationEditorOpen && (
              <ButtonsContainer className={styles.buttonContainer}>
                <div>
                  <Button onClick={() => navigate(1)}>{t('create.back')}</Button>
                  <Button layout="primary" onClick={clickNext} disabled={data.locations.length === 0}>
                    {t('create.next')}
                  </Button>
                </div>
                <Button onClick={onCancel}>{t('reportItem.cancel')}</Button>
              </ButtonsContainer>
          )}
        </ContentContainer>
      </>
  );
};

export default ManageLocations;
