import { CircleData } from '../../interfaces';

export const displayController = (data: CircleData[], index: number) => {
  const { length } = data;

  if (length === 1) {
    return false;
  }

  return true;
};
