import React from 'react';

export const LocationIcon = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.35 10.255C3.6275 10.645 3.95 11.035 4.2875 11.425L3.125 12.25L8 15.67L12.8825 12.25L11.7125 11.425C12.0575 11.035 12.3725 10.645 12.65 10.255L15.5 12.25L8 17.5L0.5 12.25L3.35 10.255ZM8 0.249995C10.4525 0.249995 13.25 2.09499 13.25 5.61249C13.25 7.95249 11.5025 10.54 8 13.375C4.4975 10.54 2.75 7.95249 2.75 5.61249C2.73192 4.91284 2.85452 4.21667 3.11053 3.56528C3.36654 2.91389 3.75075 2.32055 4.24038 1.82043C4.73 1.32031 5.31507 0.923608 5.96089 0.653844C6.6067 0.38408 7.30011 0.246749 8 0.249995ZM11.75 5.61249C11.75 3.28749 10.025 1.75 8 1.75C5.975 1.75 4.25 3.29499 4.25 5.61249C4.25 7.20999 5.465 9.1675 8 11.41C10.535 9.1675 11.75 7.20999 11.75 5.61249ZM8 6.8125C7.6519 6.8125 7.31806 6.67421 7.07192 6.42807C6.82578 6.18193 6.6875 5.84809 6.6875 5.5C6.6875 5.1519 6.82578 4.81806 7.07192 4.57192C7.31806 4.32578 7.6519 4.1875 8 4.1875C8.3481 4.1875 8.68194 4.32578 8.92808 4.57192C9.17422 4.81806 9.3125 5.1519 9.3125 5.5C9.3125 5.84809 9.17422 6.18193 8.92808 6.42807C8.68194 6.67421 8.3481 6.8125 8 6.8125Z"
      fill="white"
    />
  </svg>
);

export default LocationIcon;
