import React from 'react';
import {
  ProjectsHierarchy,
  ProjectSelection,
  ContentVisualizationMode,
  ReportItemInfo,
  ReportItems,
  LocationInfo,
  SelectedTimeFrames,
  ExportProjectSelection,
} from './projectsHierarchy';
import { ReportItemsDetails } from './reportItemsDetails';
import {
  CatchmentTypeEnum,
  ColorSchemeEnum,
  DataLevelEnum,
  LocationTypeEnum,
  OverlayHotspotsEnum,
  PeriodsEnum,
  ReportItemType,
  MovementPeriodEnum,
  MovementVisitorTypeEnum,
  CrossVisitationChartValues,
  CatchmentLocationsCountEnum,
  CatchmentMetricTypeEnum,
  CatchmentSocioDemoMetricEnum,
} from '../reportItems/types';
import { User } from './User';
import { SwitchModes, BasicMapPopupProps } from './visualizationObjects';
import { MapOptions } from '../maps/mapBase';
import { SortByEnum as SortByProjectEnum } from '../../projects/panelContent/projects/enums';
import { SortByEnum as SortByReportEnum } from '../../projects/panelContent/reports/enums';

export const MainContext = React.createContext({
  projectsHierarchy: [] as ProjectsHierarchy,
  // projectsHierarchyDate: null as Date | null,
  setProjectsHierarchy: (data: ProjectsHierarchy) => {},
  contentVisualizationMode: ContentVisualizationMode.MAP,
  setContentVisualizationMode: (mode: ContentVisualizationMode) => {},

  catchmentLayer: SwitchModes.POSTAL_CODES,
  movementMode: SwitchModes.HEATMAP,
  setCatchmentLayer: (layer: SwitchModes) => {},
  setMovementMode: (mode: SwitchModes) => {},
  limitations: {
    maximumNumberOfLocations: 30,
    maximumNumberOfClusters: 10,
    polygonSize: 32000,
    aoiPolygonSize: 5000000,
  },
  setLimitations: (
      maximumNumberOfLocations: number,
      maximumNumberOfClusters: number,
      polygonSize: number,
      aoiPolygonSize: number,
  ) => {},

  selection: {
    selectedProject: null,
    selectedReport: null,
    selectedReportItem: null,
  } as ProjectSelection,

  exportSelection: {
    selectedReportItem: null,
  } as ExportProjectSelection,

  reportItemsDetails: {
    period: PeriodsEnum.MONTHLY,
    retentionPeriod: PeriodsEnum.QUARTERLY,
    metricType: '',
    items: [],
    colorScheme: ColorSchemeEnum.MONOCHROME,
    catchmentType: CatchmentTypeEnum.HOME,
    catchmentLocationsCount: CatchmentLocationsCountEnum.SINGLE,
    catchmentDataOverlay: false,
    catchmentMetricType: CatchmentMetricTypeEnum.DESTINATION,
    catchmentSocioDemoMetric: CatchmentSocioDemoMetricEnum.INHABITANTS,
    dataLevel: DataLevelEnum.LOCATION,
    selectedCountry: null,
    catchmentTreshold: {
      min: 0.001,
      max: 1,
    },
    movementTreshold: {
      min: 0,
      max: 1,
    },
    showHoursByWeek: true,
    movementPeriod: MovementPeriodEnum.BEFORE,
    movementType: MovementVisitorTypeEnum.VISITORS,
    locationType: LocationTypeEnum.POIS,
    hotspotOverlay: OverlayHotspotsEnum.YES,
    showAverageValuesInChart: true,
    crossVisitationChartValues: CrossVisitationChartValues.CROSS_VISITATION,
    csv: '',
    locations: [],
  } as ReportItemsDetails,
  updateSelection: (data: ProjectSelection) => {},
  updateExportProjectSelection: (type: keyof ExportProjectSelection, data: ReportItemInfo<ReportItems> | null) => {},
  updateSelectedReportItem: (item: ReportItemInfo<ReportItems> | null) => {},
  updateReportItemsDetailsItems: (type: string, items: ReportItemInfo<ReportItems>[]) => {},
  updateReportItemsDetailsMetricType: (type: string) => {},
  updateReportItemsDetailsValue: (name: string, value: any) => {},
  updateReportItemOfSelectedReport: (item: ReportItemInfo<ReportItems>, forPrimaryLocationId?: string | null) => {},

  selectedLocations: [] as LocationInfo[],
  selectedLocationsIds: [] as string[],
  setSelectedLocations: (data: LocationInfo[]) => {},
  primaryLocationId: null as string | null,
  setPrimaryLocationId: (id: string | null) => {},

  selectedTimeFrames: null as SelectedTimeFrames,
  setSelectedTimeFrames: (timeFrames: string[] | null) => {},

  absorptionSourceLocation: null as LocationInfo | null,
  setAbsorptionSourceLocation: (location: LocationInfo | null) => {},
  attractionSourceLocation: null as LocationInfo | null,
  setAttractionSourceLocation: (location: LocationInfo | null) => {},

  saveUser: (data: User) => {},
  user: {
    userId: '',
    userName: '',
    avatar: '',
    userRole: '',
    accountId: '',
    userEmail: '',
    companyLogo: '',
  },
  applicationLoadProgress: 0,
  changeApplicationLoadProgress: (data: number) => {},
  onSearchItems: (type: string, value: string) => {},
  onChangeSortBy: (type: 'projects' | 'reports', value: SortByReportEnum | SortByProjectEnum) => {},
  search: { projects: '', reports: '' },
  sortBy: { projects: SortByProjectEnum.NAME, reports: SortByReportEnum.NAME },
  onSaveTicket: (data: string) => {},
  ticket: '',
  isSelectionDataLoaded: false,
  toggleIsSelectionDataLoaded: (data: boolean) => {},
  mapOptions: {
    default: { zoom: null, latitude: null, longitude: null },
    catchment: { zoom: null, latitude: null, longitude: null },
    catchmentTripRoutes: { zoom: null, latitude: null, longitude: null },
    movement: { zoom: null, latitude: null, longitude: null },
  } as MapOptions,
  popup: {
    ABSORPTION: { coordinates: [], title: '' },
    ATTRACTION: { coordinates: [], title: '' },
    DURATION: { coordinates: [], title: '' },
    FOOTFALL: { coordinates: [], title: '' },
    LOYALTY: { coordinates: [], title: '' },
    MOVEMENT: { coordinates: [], title: '' },
    RETENTION: { coordinates: [], title: '' },
    SEASONALITY: { coordinates: [], title: '' },
    CATCHMENT: { coordinates: [], title: '' },
  } as BasicMapPopupProps,
  setPopup: (popupData: Partial<BasicMapPopupProps> | null) => {},
  onChangeGlobalMapZoom: (data: Partial<MapOptions>) => {},
  clearReportItemsDetails: () => {},
  resetMapViewport: true,
  onChangeResetMapViewport: (data: boolean) => {},

  isDashboardLoaderVisible: false,
  dashboardLoaderProgress: 0,
  dashboardLoaderLabel: 'Loading...',
  updateDashboardLoader: (type: ReportItemType, visible: boolean, label?: string, progress?: number) => {},

  createReportStep: 1,
  setCreateReportStep: (step: number) => {},
  selectedLanguage: 'en',
  setSelectedLanguage: (lng: string) => {},
  setPrimaryLocationCoordinates: (coordinates: { longitude: null | number; latitude: null | number }) => {},
  primaryLocationCoordinates: { longitude: null, latitude: null } as {
    longitude: null | number;
    latitude: null | number;
  },
});
