import { bbox, BBox, multiPoint } from '@turf/turf';
import { Position } from '@turf/helpers/dist/js/lib/geojson';

/**
 * Creat bbox from array of Geo Polygons Object
 * @param geos - array of Geo Polygons Object
 * @return bbox
 */
export const getInitialBbox = (geos: GeoJSON.Feature<GeoJSON.Geometry>[]): BBox => {
  const coords: Position[] = [];
  geos.forEach((item) => {
    if (item && item.geometry.type === 'Point') {
      coords.push(item.geometry.coordinates);
    }
  });

  const feature = multiPoint(coords);

  return bbox(feature);
};
