import { WebMercatorViewport } from 'react-map-gl';
import { FitBoundsOptions } from '@math.gl/web-mercator/src/fit-bounds';
import { getViewportBoxFromGeo } from './getViewportBoxFromGeo';
import { getFitBounds } from './getFitBounds';

const calculateBounds = (
  minLng: number,
  minLat: number,
  maxLng: number,
  maxLat: number,
  width: number,
  height: number,
  options?: Omit<FitBoundsOptions, 'width' | 'height' | 'bounds'>,
) => {
  const vp = new WebMercatorViewport({
    width,
    height,
  });
  const { longitude, latitude, zoom } = vp.fitBounds(
    [
      [minLng, minLat],
      [maxLng, maxLat],
    ],
    options,
  );

  return { longitude, latitude, zoom };
};

export const getFitBoundsFromGeo = (
  width: number,
  height: number,
  geo: GeoJSON.Feature<GeoJSON.Geometry>[],
  options?: Omit<FitBoundsOptions, 'width' | 'height' | 'bounds'>,
) => {
  const [minLng, minLat, maxLng, maxLat] = getViewportBoxFromGeo(geo);

  try {
    return calculateBounds(minLng, minLat, maxLng, maxLat, width, height, options);
  } catch (error) {
    try {
      return calculateBounds(minLng, minLat, maxLng, maxLat, 500, height);
    } catch (error) {
      console.log(error);
    }
  }
};
