import React from 'react';

export const CrossVisitationIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2733 6.71967L7.27333 0.719674C7.03333 0.479674 6.7 0.333008 6.33333 0.333008H1.66666C0.933328 0.333008 0.333328 0.933008 0.333328 1.66634V6.33301C0.333328 6.69967 0.479995 7.03301 0.726662 7.27967L6.72666 13.2797C6.96666 13.5197 7.29999 13.6663 7.66666 13.6663C8.03333 13.6663 8.36666 13.5197 8.60666 13.273L13.2733 8.60634C13.52 8.36634 13.6667 8.03301 13.6667 7.66634C13.6667 7.29967 13.5133 6.95967 13.2733 6.71967ZM2.66666 3.66634C2.11333 3.66634 1.66666 3.21967 1.66666 2.66634C1.66666 2.11301 2.11333 1.66634 2.66666 1.66634C3.22 1.66634 3.66666 2.11301 3.66666 2.66634C3.66666 3.21967 3.22 3.66634 2.66666 3.66634ZM10.5133 9.17968L7.66666 12.0263L4.81999 9.17968C4.51999 8.87301 4.33333 8.45967 4.33333 7.99967C4.33333 7.07967 5.08 6.33301 6 6.33301C6.46 6.33301 6.88 6.51967 7.18 6.82634L7.66666 7.30634L8.15333 6.81967C8.45333 6.51967 8.87333 6.33301 9.33333 6.33301C10.2533 6.33301 11 7.07967 11 7.99967C11 8.45967 10.8133 8.87967 10.5133 9.17968Z"
      fill="white"
    />
  </svg>
);
