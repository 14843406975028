import React from 'react';
import styles from './style.module.scss';

interface Props {
  value: boolean;
  onChange: VoidFunction;
  disabled?: boolean;
}
export const FullFilledSwitcher: React.FC<Props> = ({ value, onChange, disabled }) => (
  <label className={styles.switch}>
    <input type="checkbox" checked={value} onChange={disabled ? () => {} : onChange} />
    <span className={`${styles.slider} ${styles.round}`} />
  </label>
);
