import React, { ChangeEvent, useMemo, useRef, useState, useEffect } from 'react';
import { CrossIcon, SearchIcon } from '../../icons/controls';
import { useClickOutside, useToggle } from '../../hooks';
import { Field } from '../../inputs';
import { Item } from './item';
import { SearchSelectItem } from './item/interfaces';
import styles from './style.module.scss';
import { DisplayLocation } from '../../manageLocations/model/interfaces';

interface Props {
  maxItemsShow?: number;
  options: SearchSelectItem[];
  onSelect: (id: string) => void;
  selectedOptions: DisplayLocation[];
}

const ITEM_HEIGHT = 49;
export const SearchSelect = ({ maxItemsShow = 5, options, onSelect, selectedOptions }: Props) => {
  const [isOpened, toggleOpened] = useToggle(false);
  const [searchValue, setSearchValue] = useState('');
  const componentRef = useRef<HTMLDivElement>(null);

  const onToggleOpen = () => {
    toggleOpened();
  };

  const onChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
  };

  const onClearSearch = () => {
    setSearchValue('');
  };

  const onDropdownItemSelect = (id: string) => {
    onSelect(id);
    toggleOpened(false);
  };

  useClickOutside(componentRef, onToggleOpen, isOpened);
  const maxListHeight = maxItemsShow * ITEM_HEIGHT;

  const searchedOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          option.label.toLowerCase().includes(searchValue.toLowerCase()) &&
          !selectedOptions.some((selectedOption) => selectedOption.id === option.id),
      ),
    [options, searchValue, selectedOptions],
  );

  const isMoreThanDisplay = searchedOptions.length > maxItemsShow;

  useEffect(() => {
    if (isOpened) {
      const element = document.getElementById(`locationManagerSearchField`);
      if (element) {
        element.focus();
      }
    }
  }, [isOpened]);

  return (
    <div className={styles.wrapper} ref={componentRef}>
      <div className={`${styles.field} ${isOpened ? styles.focus : ''}`} onClick={onToggleOpen}>
        <span>Search existing locations</span>
        {searchValue ? (
          <span className={styles.cross} onClick={onClearSearch}>
            <CrossIcon />
          </span>
        ) : (
          <SearchIcon />
        )}
      </div>
      {isOpened && (
        <div className={`${styles.list}`}>
          <div className={styles.input}>
            <Field
              id="locationManagerSearchField"
              name="field"
              onChange={onChangeSearchValue}
              value={searchValue}
              placeholder="Search"
            />
          </div>
          <div
            className={`custom-scrollbar ${styles.items} ${isMoreThanDisplay ? styles.scroll : ''}`}
            style={{ height: isMoreThanDisplay ? `${maxListHeight}px` : '100%' }}
          >
            {searchedOptions.length > 0 ? (
              searchedOptions.map(({ id, label, value }) => (
                <Item key={id} id={id} label={label} value={value} onClick={onDropdownItemSelect} />
              ))
            ) : (
              <span className={styles.noOptions}>No options</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
