import { Value } from '../../interfaces';
import { LocationVisitCount, LocationVisitCountValue, TrendModifiedData } from '../types';
import { toFixNumber } from '../../../common/lib';
import { getFromMonthToMonthAverage } from './getFromMonthToMonthAverage';
import { getOnlyValue } from './getOnlyValue';
import { transformOnlyValues } from './transformOnlyValues';

export const calculateLocationAverageAccordingToMetrics = (
  locationsAverage: Map<string, number>,
  average: number,
  values: Value<TrendModifiedData>[],
  dates: number[],
) => {
  const locationsCountVisitsByDate = new Map<string, LocationVisitCount<'array'>>();

  dates.forEach((date) => {
    values.forEach(({ data, location_id }) => {
      const dataAccordingToIteratedDate = data.find((item) => item.date === date);
      if (dataAccordingToIteratedDate) {
        const { date, absoluteLocation, allLocations, singleLocation, confidence_level } = dataAccordingToIteratedDate;
        const fixedValue = toFixNumber(Number(allLocations), 5);
        const allLocationsValue = {
          date,
          value: fixedValue,
        };
        const singleLocationValue = {
          date,
          value: singleLocation !== undefined ? singleLocation : 0,
        };
        const absoluteLocationsValue = {
          date,
          value: absoluteLocation,
        };
        const alreadyExistedValue = locationsCountVisitsByDate.get(location_id);
        locationsCountVisitsByDate.set(
          location_id,
          alreadyExistedValue
            ? {
                ...alreadyExistedValue,
                allLocations: [...alreadyExistedValue.allLocations, allLocationsValue],
                absoluteNumber: [...alreadyExistedValue.absoluteNumber, absoluteLocationsValue],
                singleLocation: [...alreadyExistedValue.singleLocation, singleLocationValue],
                confidenceLevel: confidence_level,
              }
            : {
                allLocations: [allLocationsValue],
                absoluteNumber: [absoluteLocationsValue],
                singleLocation: [singleLocationValue],
                confidenceLevel: confidence_level,
              },
        );
      }
    });
  });

  const locationsCountVisits = new Map<
    string,
    { allLocations: number; absoluteNumber: number; singleLocation: number; confidenceLevel?: number | boolean }
  >();

  const entries = Object.entries(Object.fromEntries(locationsCountVisitsByDate));

  const entriesRecordsCount = entries.length;
  const visitCounts = dates.reduce<LocationVisitCount<'array'>>(
    (curr, date) => {
      const { allLocations, absoluteNumber, singleLocation } = entries.reduce<LocationVisitCount>(
        (acc, curr) => {
          const allLocationsData = curr[1].allLocations;
          const absoluteNumberData = curr[1].absoluteNumber;
          const singleLocationData = curr[1].singleLocation;
          const locationId = curr[0];
          const confidenceLevel = curr[1].confidenceLevel;

          const iteratedLocationAllLocationsSum = allLocationsData.reduce(
            (curr, { value }) =>
              value === undefined ? 0 : toFixNumber(curr, 5) + toFixNumber(value ? Number(value) : 0, 5),
            0,
          );
          const iteratedLocationAllLocationAverage = toFixNumber(
            iteratedLocationAllLocationsSum / allLocationsData.length,
            5,
          );

          const iteratedLocationSingleLocationSum = singleLocationData.reduce(
            (curr, { value }) =>
              value === undefined ? 0 : toFixNumber(curr, 5) + toFixNumber(value ? Number(value) : 0, 5),
            0,
          );

          const iteratedLocationSingleLocationAverage = toFixNumber(
            iteratedLocationSingleLocationSum / singleLocationData.length,
            5,
          );

          const iteratedLocationAbsoluteNumbersSum = absoluteNumberData.reduce(
            (curr, { value }) => (value === undefined ? 0 : curr + value),
            0,
          );
          const iteratedLocationAbsoluteNumbersAverage = toFixNumber(
            iteratedLocationAbsoluteNumbersSum / absoluteNumberData.length,
            5,
          );

          locationsCountVisits.set(locationId, {
            allLocations: toFixNumber(iteratedLocationAllLocationAverage, 5),
            absoluteNumber: iteratedLocationAbsoluteNumbersAverage,
            singleLocation: toFixNumber(iteratedLocationSingleLocationAverage, 5),
            confidenceLevel,
          });
          const allLocationsAccordingToDate: LocationVisitCountValue | undefined = allLocationsData.find(
            (location) => location.date === date,
          );

          const singleLocationAccordingToDate: LocationVisitCountValue | undefined = singleLocationData.find(
            (location) => location.date === date,
          );

          const absoluteNumberAccordingToDate = absoluteNumberData.find((location) => location.date === date);

          const allLocationsValue = toFixNumber(
            Number(acc.allLocations.value) + Number(allLocationsAccordingToDate?.value),
            3,
          );

          const singleLocationValue = toFixNumber(
            Number(acc.singleLocation.value) + Number(singleLocationAccordingToDate?.value),
            4,
          );

          const absoluteNumberValue =
            absoluteNumberAccordingToDate?.value !== undefined
              ? toFixNumber(Number(acc.absoluteNumber.value) + Number(absoluteNumberAccordingToDate?.value), 3)
              : 0;

          return {
            allLocations: {
              date,
              value: allLocationsValue,
            },
            absoluteNumber: {
              date,
              value: absoluteNumberValue,
            },
            singleLocation: {
              date,
              value: singleLocationValue,
            },
          };
        },
        {
          allLocations: {
            date: null,
            value: 0,
          },
          absoluteNumber: {
            date: null,
            value: 0,
          },
          singleLocation: {
            date: null,
            value: 0,
          },
        },
      );

      const allLocationsAverageAccordingDate = toFixNumber(Number(allLocations.value) / entriesRecordsCount, 3);
      const singeLocationAverageAccordingDate = toFixNumber(Number(singleLocation.value) / entriesRecordsCount, 3);
      const absolutNumberAverageAccordingDate =
        absoluteNumber.value !== undefined
          ? toFixNumber(Number(absoluteNumber.value) / entriesRecordsCount, 3)
          : undefined;

      return {
        ...curr,
        allLocations: [...curr.allLocations, { date, value: allLocationsAverageAccordingDate }],
        singleLocation: [...curr.singleLocation, { date, value: singeLocationAverageAccordingDate }],
        absoluteNumber: [
          ...curr.absoluteNumber,
          {
            date,
            value: absolutNumberAverageAccordingDate,
          },
        ],
      };
    },
    {
      allLocations: [],
      absoluteNumber: [],
      singleLocation: [],
    },
  );

  const allLocationsSumVisitCount = visitCounts.allLocations.reduce((curr, { value }) => (value || 0) + curr, 0);
  const allLocationsAverageVisitCount = toFixNumber(allLocationsSumVisitCount / visitCounts.allLocations.length, 3);
  const singleLocationSumVisitCount = visitCounts.singleLocation.reduce((curr, { value }) => (value || 0) + curr, 0);
  const singleLocationAverageVisitCount = toFixNumber(
    singleLocationSumVisitCount / visitCounts.singleLocation.length,
    3,
  );
  const absoluteNumberSumVisitCount = visitCounts.absoluteNumber.reduce((curr, { value }) => (value || 0) + curr, 0);
  const absoluteNumberAverageVisitCount = toFixNumber(
    absoluteNumberSumVisitCount / visitCounts.absoluteNumber.length,
    3,
  );

  const visitCount = {
    allLocations: allLocationsAverageVisitCount,
    absoluteNumber: absoluteNumberAverageVisitCount,
    singleLocation: singleLocationAverageVisitCount,
    locationsCountVisits,
  };

  const averageByDifferentMetricsInLine = {
    absoluteNumber: entries.reduce(
      (acc, [locationId, data]) => ({ ...acc, [locationId]: getOnlyValue(data.absoluteNumber) }),
      {},
    ),
    singleLocations: entries.reduce(
      (acc, [locationId, data]) => ({ ...acc, [locationId]: getOnlyValue(data.singleLocation) }),
      {},
    ),
  };

  const fromMonthToMonthAverageChange = {
    allLocations: getFromMonthToMonthAverage(getOnlyValue(transformOnlyValues(visitCounts.allLocations))),
    absoluteNumber: getFromMonthToMonthAverage(getOnlyValue(transformOnlyValues(visitCounts.absoluteNumber))),
    singleLocations: getFromMonthToMonthAverage(getOnlyValue(transformOnlyValues(visitCounts.singleLocation))),
  };

  return {
    averageByDifferentMetricsInLine,
    fromMonthToMonthAverageChange,
    visitCount,
  };
};
