import { TrendValue } from '../../types';

export const getMissingLocations = (data: TrendValue[]) => {
  const allMissing: string[] = [];
  const monthMissing: string[] = [];
  data.forEach((location) => {
    const isLocationWithoutAbsoluteValues = location.data.map((item) => item.value).every((item) => item === '-');
    const isLocationWithMissingMonthlyAbsVal = location.data.map((item) => item.value).some((item) => item === '-');
    if (isLocationWithoutAbsoluteValues) {
      allMissing.push(location.location_name);
    } else if (isLocationWithMissingMonthlyAbsVal) {
      monthMissing.push(location.location_name);
    }
  });

  return { allMissing, monthMissing };
};
