import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseTable, { BaseTableHeaderProps } from '../../../../common/baseTable';
import { useTimePeriod } from '../../../../common/hooks/useTimePeriod';
import { PeriodsEnum } from '../../../../common/reportItems/types';
import { RetentionLocation } from '../../../../common/types/projectsHierarchy';
import { dateFormatter } from '../../utils';

interface TableDataFormat {
  head: string;
  options: {
    subhead: {
      value: string | number;
      formatter: () => string | number;
    };
    values: {
      value: number;
    };
  }[];
}

type Props = {
  data: RetentionLocation[];
  period: PeriodsEnum;
};

const convertDataToTableFormat = (
  data: RetentionLocation[],
  period: PeriodsEnum,
  isExport: boolean,
  dateFormat: string,
  timeFrame: string,
) =>
  data
    ? data.map((item) => ({
        head: item.location_name,
        options: item.data.map((subItem) => ({
          subhead: { value: subItem.date, formatter: () => dateFormatter(period, String(subItem.date), dateFormat) },
          values: isExport
            ? {
                value: Number(subItem.value),
                timeframe: timeFrame,
              }
            : {
                value: Number(subItem.value) * 100,
              },
        })),
      }))
    : [];

export const Table = ({ data, period }: Props) => {
  const { t } = useTranslation();
  const timeFrameString = useTimePeriod();
  const tableHeaders: BaseTableHeaderProps[] = [{ key: 'value', name: t('retention.title'), unit: '%' }];
  const exportTableHeaders: BaseTableHeaderProps[] = [
    { key: 'value', name: t('retention.title'), unit: '' },
    { key: 'timeframe', name: t('reportItem.timeFrame'), unit: '' },
  ];

  const tableData: TableDataFormat[] = useMemo(
    () => convertDataToTableFormat(data, period, false, 'MM, YYYY', timeFrameString),
    [data, period],
  );
  const exportTableData: TableDataFormat[] = useMemo(
    () => convertDataToTableFormat(data, period, true, 'YYYY-MM-DD', timeFrameString),
    [data, period],
  );

  return (
    <BaseTable<TableDataFormat>
      data={tableData}
      headers={tableHeaders}
      headName={t('location.location')}
      subheadName={t('reportItem.date')}
      exportHeaders={exportTableHeaders}
      exportData={exportTableData}
    />
  );
};
