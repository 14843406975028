import { createEffect } from 'effector';
import { SocialDemographicResponse, Retail } from './interfaces';
import { saveLocationInformation, saveRetails } from './events';
import { CatchmentTypeEnum } from '../../../../../common/reportItems/types';
import { getSocialDemographicData } from '../../../../../api/reportItems/catchment';
import { getCountryCurrency, separateThousandsByComma } from '../../../../../common/utils';
import { convertDataToRepresentationFormat } from './utils';

interface Params {
  reportItemId: string;
  primaryLocationId: string;
  type: CatchmentTypeEnum;
  timeFrames: string[] | null;
  minThreshold: number | null;
  maxThreshold: number | null;
  countryCode: string | null;
}

export const getDemographicData = createEffect(
  async ({ reportItemId, primaryLocationId, type, timeFrames, minThreshold, maxThreshold, countryCode }: Params) => {
    const data = await getSocialDemographicData<SocialDemographicResponse>(
      reportItemId,
      primaryLocationId,
      type,
      timeFrames,
      minThreshold,
      maxThreshold,
      countryCode,
    );
    const currency = getCountryCurrency(countryCode || '');
    const { retail, locationId, locationName, rank } = data;
    const retailWithCurrency = retail?.map(
      (item) => ({ ...item, value: `${currency} ${separateThousandsByComma(item.value)}` } as Retail),
    );
    saveRetails(retailWithCurrency || null);
    saveLocationInformation({
      name: locationName,
      id: locationId,
      rank: rank?.location || 0,
      total: rank?.total || 0,
    });
    return convertDataToRepresentationFormat(data, currency);
  },
);
