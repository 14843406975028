import React, { useContext, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import { DemographicChart } from '../../../../common/chart';
import { catchmentModel } from './model';
import { MainContext } from '../../../../common/types/mainContext';
import {
  CatchmentLocationsCountEnum,
  CatchmentMetricTypeEnum,
  CatchmentTypeEnum,
  ReportItemType,
} from '../../../../common/reportItems/types';
import { getDemographicData } from './model/effects';
import { LocationVisualizationMap } from './map';
import { usePoints } from './hooks';
import { Catchment, ReportItemInfo } from '../../../../common/types/projectsHierarchy';
import { reportItemDetailsModel } from '../../../model';
import styles from './style.module.scss';
import { countries, Country } from '../../../../common/utils';
import { Option } from '../../../../common/controls/dropdown/types';

interface Props {
  onLoad?: VoidFunction;
}
export const Chart = ({ onLoad }: Props) => {
  const {
    selection: { selectedReport },
    primaryLocationId,
    reportItemsDetails,
    selectedTimeFrames,
    updateReportItemsDetailsValue,
  } = useContext(MainContext);

  const categories = useStore(catchmentModel.$categories);
  const subCategories = useStore(catchmentModel.$subCategories);
  const retails = useStore(catchmentModel.$retails);
  const locationInformation = useStore(catchmentModel.$locationInformation);

  const { points, postalCodesPoints } = usePoints();

  const selectedReportItem = useMemo(
    () => selectedReport?.report_items?.find((i) => i.type === ReportItemType.CATCHMENT) as ReportItemInfo<Catchment>,
    [JSON.stringify(selectedReport), primaryLocationId],
  );

  const getData = async (
    reportItemId: string,
    primaryLocationId: string,
    type: CatchmentTypeEnum,
    selectedTimeFrames: null | string[],
    minThreshold: number | null,
    maxThreshold: number | null,
    countryCode: string | null = '',
  ) => {
    await getDemographicData({
      reportItemId,
      primaryLocationId,
      type: reportItemsDetails.catchmentType,
      timeFrames: selectedTimeFrames,
      minThreshold,
      maxThreshold,
      countryCode,
    });
  };

  useEffect(() => {
    if (selectedReportItem?.id && primaryLocationId && reportItemsDetails?.selectedCountry) {
      (async function () {
        reportItemDetailsModel.toggleReportItemDetailsLoading(true);
        await getData(
          selectedReportItem.id,
          primaryLocationId,
          reportItemsDetails.catchmentType,
          selectedTimeFrames,
          reportItemsDetails.catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION &&
            reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE
            ? reportItemsDetails.catchmentTreshold.min
            : 0.001,
          reportItemsDetails.catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION &&
            reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE
            ? reportItemsDetails.catchmentTreshold.max
            : null,
          reportItemsDetails.selectedCountry,
        );
        reportItemDetailsModel.toggleReportItemDetailsLoading(false);
      })();
    }
  }, [
    primaryLocationId,
    reportItemsDetails.catchmentType,
    selectedReportItem,
    selectedTimeFrames,
    reportItemsDetails.catchmentTreshold.min,
    reportItemsDetails.catchmentTreshold.max,
    reportItemsDetails.selectedCountry,
    reportItemsDetails.catchmentMetricType,
    reportItemsDetails.catchmentLocationsCount,
  ]);

  const updateSelectedCountry = (country: Option<Country>) => {
    updateReportItemsDetailsValue('selectedCountry', country.id);
  };

  const availableCountries = countries
    .filter((country) => selectedReportItem.data?.countries?.includes(country.code))
    .map((country) => ({
      id: country.code,
      label: country.label,
      disabled: false,
      item: country,
    }));

  return subCategories.length ? (
    <div className={styles.chart}>
      <DemographicChart
        country={reportItemsDetails.selectedCountry || ''}
        countries={availableCountries}
        onChangeCountry={updateSelectedCountry}
        categories={categories}
        subCategories={subCategories}
        retails={retails}
        locationName={locationInformation.name}
        rank={locationInformation.rank}
        total={locationInformation.total}
      >
        <LocationVisualizationMap points={points} postalCodesPoints={postalCodesPoints} onLoad={onLoad} />
      </DemographicChart>
    </div>
  ) : (
    <></>
  );
};
