import React from 'react';

export const SexIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8333 9.33333C15.41 9.33333 16.8889 9.83444 18.0744 10.69L24.32 4.44444H18.7222V2H28.5V11.7778H26.0556V6.16778L19.81 12.3889C20.6656 13.6111 21.1667 15.0778 21.1667 16.6667C21.1667 18.6116 20.394 20.4769 19.0188 21.8521C17.6435 23.2274 15.7783 24 13.8333 24C11.8884 24 10.0232 23.2274 8.64788 21.8521C7.27262 20.4769 6.5 18.6116 6.5 16.6667C6.5 14.7217 7.27262 12.8565 8.64788 11.4812C10.0232 10.106 11.8884 9.33333 13.8333 9.33333ZM13.8333 11.7778C12.5367 11.7778 11.2932 12.2929 10.3764 13.2097C9.45952 14.1265 8.94444 15.3701 8.94444 16.6667C8.94444 17.9633 9.45952 19.2068 10.3764 20.1236C11.2932 21.0405 12.5367 21.5556 13.8333 21.5556C15.1299 21.5556 16.3735 21.0405 17.2903 20.1236C18.2071 19.2068 18.7222 17.9633 18.7222 16.6667C18.7222 15.3701 18.2071 14.1265 17.2903 13.2097C16.3735 12.2929 15.1299 11.7778 13.8333 11.7778Z"
      fill="#EADED5"
    />
    <mask id="mask0_7879_61102" maskUnits="userSpaceOnUse" x="0" y="7" width="25" height="28">
      <path d="M24.2606 25.8033L5.9507 7.95117L0 20.3103L14.6479 34.5005L24.2606 25.8033Z" fill="white" />
    </mask>
    <g mask="url(#mask0_7879_61102)">
      <path
        d="M13.8239 9.32422C15.7664 9.32422 17.6292 10.0958 19.0028 11.4694C20.3763 12.8429 21.1479 14.7057 21.1479 16.6482C21.1479 20.2735 18.5113 23.2885 15.0446 23.8745V26.4134H17.4859V28.8547H15.0446V31.296H12.6033V28.8547H10.162V26.4134H12.6033V23.8745C9.13662 23.2885 6.5 20.2735 6.5 16.6482C6.5 14.7057 7.27163 12.8429 8.64513 11.4694C10.0186 10.0958 11.8815 9.32422 13.8239 9.32422ZM13.8239 11.7655C12.529 11.7655 11.2871 12.28 10.3714 13.1956C9.45573 14.1113 8.94131 15.3532 8.94131 16.6482C8.94131 17.9431 9.45573 19.185 10.3714 20.1007C11.2871 21.0164 12.529 21.5308 13.8239 21.5308C15.1189 21.5308 16.3608 21.0164 17.2765 20.1007C18.1922 19.185 18.7066 17.9431 18.7066 16.6482C18.7066 15.3532 18.1922 14.1113 17.2765 13.1956C16.3608 12.28 15.1189 11.7655 13.8239 11.7655Z"
        fill="#DBAA85"
      />
    </g>
  </svg>
);
