import { createStore } from 'effector';
import { getDemographicData } from './effects';
import { LocationInformation, Retail, SocialDemographicData } from './interfaces';
import { saveLocationInformation, saveRetails } from './events';

export const $categories = createStore([
  {
    id: 1,
    color: '#DBAA85',
    stake: 50,
  },
  {
    id: 2,
    color: '#A67BDD',
    stake: 25,
  },
  {
    id: 3,
    color: '#DD7BAA',
    stake: 25,
  },
]);

export const $subCategories = createStore<SocialDemographicData[]>([]);

export const $retails = createStore<Retail[]>([]);

export const $locationInformation = createStore<LocationInformation>({ name: '', id: '', rank: 0, total: 0 });

$subCategories.on(getDemographicData.doneData, (_, data) => data);

$retails.on(saveRetails, (_, data) => data || []);

$locationInformation.on(saveLocationInformation, (_, data) => data);
