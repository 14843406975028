import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Information } from './information';
import { Credentials } from './interfaces';
import { signUpModel } from './model';
import { BaseModal } from '../../baseModal';
import { Form } from './form';
import { useStore } from 'effector-react';
import { Routes } from '../../../routes';

export const SignUp = () => {
  const history = useHistory();
  const isSuccess = useStore(signUpModel.$isSignUpSuccessful);
  const userData = useStore(signUpModel.$userData);
  const error = useStore(signUpModel.$error);
  const formRef = useRef<{ submit: VoidFunction | null }>({ submit: null });

  useEffect(() => {
    if (isSuccess) history.push(Routes.signUpFinal, userData);
  }, [isSuccess]);

  const onSubmit = async (data: Credentials) => {
    signUpModel.errorOccurred('');
    await signUpModel.userSignUp(data);
  };

  const onTriggerSubmit = () => {
    const submit = formRef.current.submit;
    if (submit) submit();
  };

  return (
    <BaseModal
      subtitle="Enter your details below"
      title="Sign up"
      information={<Information onSubmit={onTriggerSubmit} />}
    >
      <Form onSubmit={onSubmit} ref={formRef} error={error} />
    </BaseModal>
  );
};
