import { replaceValueToNA } from '../replaceValueToNA';
import { StakeType } from '../../../../../../../common/chart/demographic/interfaces';
import { DistanceIcon } from '../../../../../../../common/icons/charts';

export const getDistance = (value: number | null) => ({
  id: 5,
  icon: DistanceIcon,
  type: 'information' as StakeType,
  styles: { item: { left: '-250px' } },
  data: [
    {
      id: 1,
      color: '#DD7BAA',
      title: 'distanceFromLocation',
      value: value ? `${value} km` : replaceValueToNA(value),
      stake: 100,
      styles: { item: { textAlign: 'right', width: '237px' } },
    },
  ],
});
