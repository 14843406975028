import React, {useEffect, useMemo, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { EChartsOption } from 'echarts';
import EChartsReact from 'echarts-for-react';
import { LocationHourlySeasonality, LocationInfo } from '../../../common/types/projectsHierarchy';
import Chart, { getTooltipHeight } from '../../../common/chart';
import { WeekDays } from '../../types';
import { ChartEvents } from '../../../common/chart/interfaces';
import { sortByValue } from '../../../common/utils';
import { useGraphHeight } from '../../../common/reportItems/hooks';
import { PeriodsEnum } from '../../../common/reportItems/types';
import styles from './style.module.scss';
import { DefaultLegend } from '../../../common/chart/legends';
import { DayListLegend } from './dayListLegend';

interface Props {
  data: LocationHourlySeasonality[];
  events: ChartEvents;
  projectLocations: LocationInfo[];
  isScreenshotMode?: boolean;
  useFixedScreenshotSize?: boolean;
  chartRef: React.Ref<EChartsReact>;
  locations: Record<string, boolean>;
}

function formatHour(hour: number): string {
  const hourFrom = hour % 12 === 0 ? 12 : hour % 12;
  const postfixFrom = hour >= 12 ? 'PM' : 'AM';
  const hourTo = `${(hour + 1) % 12 === 0 ? '12' : (hour + 1) % 12}`;
  const postfixTo = (hour + 1) % 24 >= 12 ? 'PM' : 'AM';

  return `${hourFrom} ${postfixFrom !== postfixTo ? postfixFrom : ''} - ${hourTo} ${postfixTo}`;
}

const formatDayHour = (hour: string): string => {
  switch (hour) {
    case '6':
      return `${6}am`;
    case '12':
      return `${12}pm`;
    case '18':
      return `${6}pm`;
    default:
      return '0';
  }
};

function getOptions(
  data: LocationHourlySeasonality[],
  projectLocations: LocationInfo[],
  t: (val: string) => string,
  selectedLocations: Record<string, boolean>,
  isScreenshotMode?: boolean,
): EChartsOption {
  return {
    grid: {
      top: 20,
      left: 70,
      right: 35,
      bottom: 75,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      type: 'category',
      data: data[0].data.map((el) => el.hourOfDay),
      axisLabel: {
        formatter: (value: number) => formatDayHour(String(value)),
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
        interval: 5,
      },
      axisLine: {
        onZero: true,
        lineStyle: {
          type: 'solid',
          opacity: 0.1,
          color: '#ffffff',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          width: 1,
          color: '#FFFFFF',
          opacity: 0.1,
        },
      },
    },
    yAxis: {
      type: 'value',
      name: t('seasonality.detail.visitProportion'),
      nameLocation: 'middle',
      axisLabel: {
        formatter: (value: number) => `${+(value * 100).toFixed(1)}%`,
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
      },
      axisLine: {
        lineStyle: {
          type: 'solid',
          opacity: 0.1,
          color: '#ffffff',
        },
      },
      nameTextStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      splitLine: {
        lineStyle: {
          opacity: 0.1,
          type: 'solid',
          width: 1,
          color: '#FFFFFF',
        },
      },
      nameGap: 50,
    },
    legend: {
      show: !isScreenshotMode,
      selected: selectedLocations,
      align: 'auto',
      itemGap: 25,
      bottom: 0,
      icon: 'circle',
      type: 'scroll',
      textStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      pageTextStyle: {
        color: 'white',
      },
      pageIconColor: 'rgba(255, 255, 255, .25',
    },
    tooltip: {
      borderColor: 'rgba(255, 255, 255, 0.3)',
      trigger: 'axis',
      triggerOn: 'click',
      formatter: (params: any) => {
        if (Array.isArray(params)) {
          const sortedData = sortByValue(params);
          const hour = +sortedData[0].name;
          const displayedHour = formatHour(hour);
          const displayedDay = WeekDays[data[sortedData[0].seriesIndex].data[sortedData[0].dataIndex].dayOfWeekNumber];
          const header = `
              <div class="${styles.header}">
                <span class="${styles.title}">${t('seasonality.detail.visitProportion')} (%)</span><br />
                <span class="${styles.date}">${displayedDay}, ${displayedHour}</span>
              </div>`;

          let items = '';
          sortedData.forEach((item) => {
            items += `<div class="${styles.row}">
                  <span class="${styles.location}">${item.marker} ${item.seriesName}</span>
                  <span class="${styles.value}">${(+item.value * 100).toFixed(2)}%</span>
                </div>`;
          });

          const maxItemsHeight = getTooltipHeight(params.length);
          return `
            ${header}
            <div class="custom-scrollbar ${styles.items}" style="height:${maxItemsHeight}px;">
              ${items}
            </div>
          `;
        }
        return '';
      },
      className: styles.seasonalityTooltip,
      backgroundColor: 'rgba(31,48,61, 0.6)',
      axisPointer: {
        type: 'line',
        axis: 'x',
        lineStyle: {
          color: '#999',
        },
      },
    },
    series: data.map((options, i) => ({
      type: 'line',
      smooth: true,
      symbol: 'none',
      stack: `${i}`,
      name: options.locationName,
      color: projectLocations.find((loc) => loc.id === options.locationId)?.color || '#FFF',
      data: [
        ...options.data.filter((item) => item.dayOfWeekNumber !== 1),
        ...options.data.filter((item) => item.dayOfWeekNumber === 1),
      ].map((el) => el.visitsShare),
      lineStyle:
        options.locationId === 'average'
          ? {
              type: 'dotted',
              width: 4,
            }
          : undefined,
    })),
  };
}

const SeasonalityDetailHourlyChart: React.FC<Props> = ({
  data,
  events,
  projectLocations,
  isScreenshotMode,
  useFixedScreenshotSize,
  chartRef,
  locations,
}) => {
  const { t } = useTranslation();
  const options = useMemo(
    () => getOptions(data, projectLocations, t, locations, isScreenshotMode),
    [data, isScreenshotMode, locations, t],
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { graphHeight } = useGraphHeight(wrapperRef, PeriodsEnum.MONTHLY, projectLocations.length);

  const legendItems = useMemo(
    () =>
      data?.map(({ locationId, locationName }) => {
        const fullLocationInformation = projectLocations.find(({ id }) => id === locationId);
        return {
          id: locationId,
          label: locationName,
          color: fullLocationInformation ? fullLocationInformation?.color || '' : 'grey',
        };
      }),
    [data],
  );

  return (
    <div>
      <div style={{ height: useFixedScreenshotSize ? '270px' : graphHeight, minHeight: '270px' }} ref={wrapperRef} className={styles.chart}>
        <Chart option={options} events={events} ref={chartRef} />
        <DayListLegend />
      </div>
      {isScreenshotMode && (
        <div className={styles.legend}>
          <DefaultLegend data={legendItems} />
        </div>
      )}
    </div>
  );
};

export default SeasonalityDetailHourlyChart;
