import React, { useRef } from 'react';
import styles from './style.module.scss';
import { CrossIcon } from '../icons/controls';
import { useClickOutside } from '../hooks';
import { DownloadIcon } from '../icons/export';
import { CircleButton } from '../controls/buttons/circleButton/circleButton';

interface Props {
  onClose: VoidFunction;
  title?: string;
  isOpen: boolean;
  initialPosition?: any;
  height?: number;
  onDownload?: VoidFunction;
}
export const Tooltip: React.FC<Props> = ({ children, onClose, title, isOpen, initialPosition, height, onDownload }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useClickOutside(elementRef, onClose, isOpen);
  return (
    <div className={`${styles.wrapper} ${isOpen ? styles.open : styles.close}`}>
      <div className={`${styles.background}`} />
      <div
        className={styles.position}
        ref={elementRef}
        style={{
          left: isOpen ? `50%` : `${initialPosition?.left || 0}px`,
          top: isOpen ? `50%` : `${initialPosition?.top || 0}px`,
          height: isOpen ? `${height}px` : `0px`,
        }}
      >
        <div className={styles.header}>
          {title && <h3>{title}</h3>}
          <div className={styles.actionBar}>
            {onDownload && <CircleButton icon={<DownloadIcon />} onClick={onDownload} title="Export" />}
            <span className={styles.cross} onClick={onClose}>
              <CrossIcon />
            </span>
          </div>
        </div>
        <span className={styles.content}>{children}</span>
      </div>
    </div>
  );
};
