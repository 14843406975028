import JsPDF from 'jspdf';
import { TFunction } from 'react-i18next';
import { ReportMetadata } from './ReportTooltip';
import { getLogoMark, getPreview } from '../../../common/export/exportSettings/utils';
import { generateFileName } from '../../../common/export';
import { getCompanyLogo } from '../../../common/export/exportSettings/utils/getCompanyLogo';

const generateBaseInstance = () => new JsPDF('portrait', 'mm', 'a4');

export const generateHeader = async (instance: JsPDF, companyLogo: string) => {
  const preview = getPreview();
  const logo = getLogoMark();
  instance.addImage({ imageData: preview, format: 'PNG', x: 0, y: 0, height: 75, width: 210 });
  instance.addImage({ imageData: logo, format: 'JPEG', x: 190, y: 55, height: 15, width: 15 });
  const companyLogoData = await getCompanyLogo(companyLogo, 1000, 1000, 500);
  if (companyLogoData) {
    instance.addImage({
      imageData: companyLogoData,
      format: 'PNG',
      x: 165,
      y: 55,
      height: 15,
      width: 15,
    });
  }
  return instance;
};

export const addReportDetails = async (instance: JsPDF, data: ReportMetadata, t: TFunction<'translation'>) => {
  const { name, totalSignalsAnalyzed, residentsExclusion, dateRange, created, createdBy } = data;
  instance
    .setTextColor('black')
    .setFontSize(16)
    .setFont('', 'bold')
    .text(t('report.tooltip.title'), 30, 95, { maxWidth: 130 });

  instance
    .setFont('', 'normal')
    .setFontSize(12)
    .text(`${t('report.tooltip.reportName')}:`, 30, 105, { maxWidth: 50 });
  instance.text(`${t('report.tooltip.createdBy')}:`, 30, 112, { maxWidth: 50 });
  instance.text(`${t('report.tooltip.dateCreated')}:`, 30, 119, { maxWidth: 50 });
  instance.text(`${t('report.tooltip.dateRange')}:`, 30, 126, { maxWidth: 50 });
  // instance.text(`${t('report.tooltip.residentsExclusion')}:`, 30, 133, { maxWidth: 50 });
  instance.text(`${t('report.tooltip.totalSignalsAnalyzed')}:`, 30, 133, { maxWidth: 50 });
  instance.setFont('', 'bold').text(name, 80, 105, { maxWidth: 120 });
  instance.text(createdBy || '', 80, 112, { maxWidth: 120 });
  instance.text(created || '', 80, 119, { maxWidth: 120 });
  instance.text(dateRange || '', 80, 126, { maxWidth: 120 });
  // instance.text(residentsExclusion || '', 80, 133, { maxWidth: 120 });
  instance.text(totalSignalsAnalyzed || '', 80, 133, { maxWidth: 120 });

  return instance;
};

export const addVisitTrends = async (
  instance: JsPDF,
  t: TFunction<'translation'>,
  y: number,
): Promise<[JsPDF, number]> => {
  instance.setFont('', 'normal').text(`${t('reportItem.enum.FOOTFALL')}:`, 30, y, { maxWidth: 50 });
  instance.setFont('', 'bold').text(t('report.tooltip.paragraph1'), 80, y, { maxWidth: 120 });
  return [instance, y + 7];
};

export const addCatchment = async (
  instance: JsPDF,
  data: ReportMetadata,
  t: TFunction<'translation'>,
  y: number,
): Promise<[JsPDF, number]> => {
  const { catchment } = data;
  instance.setFont('', 'normal').text(`${t('reportItem.enum.CATCHMENT')}:`, 30, y, { maxWidth: 50 });

  instance.text(`${t('report.tooltip.export.areaLevel')} - `, 80, y, { maxWidth: 120 });
  let width = instance.getTextWidth(`${t('report.tooltip.export.areaLevel')} - `);
  instance.setFont('', 'bold').text(catchment?.level ? catchment.level : '-', 80 + width, y, { maxWidth: 120 });

  instance.setFont('', 'normal').text(`${t('report.tooltip.export.catchmentRadius')} - `, 80, y + 7, { maxWidth: 120 });
  width = instance.getTextWidth(`${t('report.tooltip.export.catchmentRadius')} - `);
  instance
    .setFont('', 'bold')
    .text(catchment?.maxRadius ? catchment.maxRadius : '-', 80 + width, y + 7, { maxWidth: 120 });

  return [instance, y + 14];
};

export const addDuration = async (
  instance: JsPDF,
  t: TFunction<'translation'>,
  y: number,
): Promise<[JsPDF, number]> => {
  instance.setFont('', 'normal').text(`${t('reportItem.enum.DURATION')}:`, 30, y, { maxWidth: 50 });
  const width = instance.getTextWidth(t('report.tooltip.export.durationParagraph1'));
  instance.text(t('report.tooltip.export.durationParagraph1'), 80, y, { maxWidth: 120 });
  instance.setFont('', 'bold').text(t('report.tooltip.export.durationParagraph2'), 80 + width, y, { maxWidth: 120 });
  instance.setFont('', 'normal').text(t('report.tooltip.export.durationParagraph3'), 80, y + 5, { maxWidth: 120 });
  return [instance, y + 12];
};

export const addSeasonality = async (
  instance: JsPDF,
  t: TFunction<'translation'>,
  y: number,
): Promise<[JsPDF, number]> => {
  instance.setFont('', 'normal').text(`${t('reportItem.enum.SEASONALITY')}:`, 30, y, { maxWidth: 50 });
  instance.setFont('', 'bold').text(t('report.tooltip.paragraph1'), 80, y, { maxWidth: 120 });
  return [instance, y + 7];
};

export const addRetention = async (
  instance: JsPDF,
  t: TFunction<'translation'>,
  y: number,
): Promise<[JsPDF, number]> => {
  instance.setFont('', 'normal').text(`${t('reportItem.enum.RETENTION')}:`, 30, y, { maxWidth: 50 });
  instance.setFont('', 'bold').text(t('report.tooltip.paragraph1'), 80, y, { maxWidth: 120 });
  return [instance, y + 7];
};

const normalizePosition = (instance: JsPDF, y: number): number => {
  if (y >= 257) {
    instance.addPage();
    return 40;
  }
  return y;
};

export const addAttraction = async (
  instance: JsPDF,
  data: ReportMetadata,
  t: TFunction<'translation'>,
  y: number,
): Promise<[JsPDF, number]> => {
  instance.setFont('', 'normal').text(`${t('reportItem.enum.ATTRACTION')}:`, 30, y, { maxWidth: 50 });
  instance.text(`${t('report.tooltip.pois')}:`, 80, y, { maxWidth: 110 });
  let verticalPosition = y - 7;
  instance.setFont('', 'bold');
  data.attraction?.pois?.forEach((poi) => {
    verticalPosition = normalizePosition(instance, verticalPosition + 7);
    instance.text(poi, 140, verticalPosition, { maxWidth: 50 });
  });
  verticalPosition = normalizePosition(instance, verticalPosition + 7);

  instance.setFont('', 'normal').text(`${t('report.tooltip.linkPois')}:`, 80, verticalPosition, { maxWidth: 110 });
  instance.setFont('', 'bold').text(data.attraction?.linkPois || '', 140, verticalPosition, { maxWidth: 50 });

  verticalPosition = normalizePosition(instance, verticalPosition + 7);

  instance.setFont('', 'normal').text(`${t('report.tooltip.aois')}:`, 80, verticalPosition, { maxWidth: 110 });

  verticalPosition -= 7;
  instance.setFont('', 'bold');
  data.attraction?.aois?.forEach((poi) => {
    verticalPosition = normalizePosition(instance, verticalPosition + 7);
    instance.text(poi, 140, verticalPosition, { maxWidth: 50 });
  });
  verticalPosition = normalizePosition(instance, verticalPosition + 7);

  instance.setFont('', 'normal').text(`${t('report.tooltip.linkAois')}:`, 80, verticalPosition, { maxWidth: 110 });
  instance.setFont('', 'bold').text(data.attraction?.linkAois || '', 140, verticalPosition, { maxWidth: 50 });

  return [instance, verticalPosition + 7];
};

export const addAbsorption = async (
  instance: JsPDF,
  data: ReportMetadata,
  t: TFunction<'translation'>,
  y: number,
): Promise<[JsPDF, number]> => {
  instance.setFont('', 'normal').text(`${t('reportItem.enum.ABSORPTION')}:`, 30, y, { maxWidth: 50 });
  instance.text(`${t('report.tooltip.pois')}:`, 80, y, { maxWidth: 110 });
  let verticalPosition = y - 7;
  instance.setFont('', 'bold');
  data.absorption?.pois?.forEach((poi) => {
    verticalPosition = normalizePosition(instance, verticalPosition + 7);
    instance.text(poi, 140, verticalPosition, { maxWidth: 50 });
  });
  verticalPosition = normalizePosition(instance, verticalPosition + 7);

  instance.setFont('', 'normal').text(`${t('report.tooltip.linkPois')}:`, 80, verticalPosition, { maxWidth: 110 });
  instance.setFont('', 'bold').text(data.absorption?.linkPois || '', 140, verticalPosition, { maxWidth: 50 });

  verticalPosition = normalizePosition(instance, verticalPosition + 7);

  if (data.absorption?.radius) {
    instance.setFont('', 'normal').text(`${t('report.tooltip.areaType')}:`, 80, verticalPosition, { maxWidth: 110 });
    instance.setFont('', 'bold').text(`${t('report.tooltip.radius')}`, 140, verticalPosition, { maxWidth: 110 });
    verticalPosition = normalizePosition(instance, verticalPosition + 7);
    instance.setFont('', 'normal').text(`${t('report.tooltip.radius')}:`, 80, verticalPosition, { maxWidth: 110 });
    instance
      .setFont('', 'bold')
      .text(`${data.absorption.radius} ${t('reportItem.meters')}`, 140, verticalPosition, { maxWidth: 110 });
  } else {
    instance.setFont('', 'normal').text(`${t('report.tooltip.aois')}:`, 80, verticalPosition, { maxWidth: 110 });

    verticalPosition -= 7;
    instance.setFont('', 'bold');
    data.absorption?.aois?.forEach((poi) => {
      verticalPosition = normalizePosition(instance, verticalPosition + 7);
      instance.text(poi, 140, verticalPosition, { maxWidth: 50 });
    });
  }

  return [instance, verticalPosition + 7];
};

export const addCrossVisitation = async (
  instance: JsPDF,
  t: TFunction<'translation'>,
  y: number,
): Promise<[JsPDF, number]> => {
  instance.setFont('', 'normal').text(`${t('reportItem.enum.LOYALTY')}:`, 30, y, { maxWidth: 50 });
  instance.setFont('', 'bold').text(t('report.tooltip.paragraph1'), 80, y, { maxWidth: 120 });
  return [instance, y + 7];
};

export const addMovement = async (
  instance: JsPDF,
  data: ReportMetadata,
  t: TFunction<'translation'>,
  y: number,
): Promise<[JsPDF, number]> => {
  const { movement } = data;
  instance.setFont('', 'normal').text(`${t('reportItem.enum.MOVEMENT')}:`, 30, y, { maxWidth: 50 });

  instance.text(`${t('report.tooltip.radius')} - `, 80, y, { maxWidth: 120 });
  let width = instance.getTextWidth(`${t('report.tooltip.radius')} - `);
  instance.setFont('', 'bold').text(movement?.radius ? movement.radius : '-', 80 + width, y, { maxWidth: 120 });

  instance.setFont('', 'normal').text(`${t('report.tooltip.timeFrame')} - `, 80, y + 7, { maxWidth: 120 });
  width = instance.getTextWidth(`${t('report.tooltip.timeFrame')} - `);
  instance
    .setFont('', 'bold')
    .text(movement?.timeframe ? movement.timeframe : '-', 80 + width, y + 7, { maxWidth: 120 });

  return [instance, y + 14];
};

export const generatePDF = async (exportData: ReportMetadata, companyLogo: string, t: TFunction<'translation'>) => {
  let document = generateBaseInstance();

  document = await generateHeader(document, companyLogo);
  document = await addReportDetails(document, exportData, t);

  document.setFontSize(16).setFont('', 'bold').text(t('report.tooltip.reportParameters'), 30, 153, { maxWidth: 120 });
  document.setFontSize(12);
  let y = 163;

  if (exportData.footfall) {
    const [instance, verticalPosition] = await addVisitTrends(document, t, y);
    document = instance;
    y = verticalPosition;
  }

  if (exportData.catchment) {
    const [instance, verticalPosition] = await addCatchment(document, exportData, t, y);
    document = instance;
    y = verticalPosition;
  }

  if (exportData.duration) {
    const [instance, verticalPosition] = await addDuration(document, t, y);
    document = instance;
    y = verticalPosition;
  }

  if (exportData.seasonality) {
    const [instance, verticalPosition] = await addSeasonality(document, t, y);
    document = instance;
    y = verticalPosition;
  }

  if (exportData.retention) {
    const [instance, verticalPosition] = await addRetention(document, t, y);
    document = instance;
    y = verticalPosition;
  }

  if (exportData.crossVisitation) {
    const [instance, verticalPosition] = await addCrossVisitation(document, t, y);
    document = instance;
    y = verticalPosition;
  }

  if (exportData.movement) {
    const [instance, verticalPosition] = await addMovement(document, exportData, t, y);
    document = instance;
    y = verticalPosition;
  }

  if (exportData.attraction) {
    const [instance, verticalPosition] = await addAttraction(document, exportData, t, y);
    document = instance;
    y = verticalPosition;
  }

  if (exportData.absorption) {
    const [instance, verticalPosition] = await addAbsorption(document, exportData, t, y);
    document = instance;
    y = verticalPosition;
  }

  const fileName = generateFileName(exportData.name, 'pdf');
  document.save(fileName);
};
