import React from 'react';

export const CatchmentPostalCodesIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.42813 3.78744L1.33331 8.69629L8.80806 14.6663L14.6666 6.12467L9.54446 1.33301L2.42813 3.78744Z" />
    <circle cx="5.66669" cy="5.66699" r="1.5" fill="white" />
    <circle cx="10.3333" cy="7" r="1.5" fill="white" />
    <circle cx="7" cy="9.66699" r="1.5" fill="white" />
  </svg>
);
