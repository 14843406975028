import React from 'react';
import styles from './style.module.scss';

type positionTypes = 'fixed' | 'absolute' | 'relative';

interface Props {
  withBackground?: boolean;
  positionType?: positionTypes;
}
export const CircleLoader: React.FC<Props> = ({ withBackground, positionType = 'absolute' }) => (
  <div className={`${styles.wrapper} ${withBackground ? styles.background : ''} ${styles[positionType]}`}>
    <div className={styles.position}>
      <div className={styles.spinner}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);
