import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../common/tooltip';
import { TooltipPositionCoordinates } from '../../../../../common/reportItems/hooks';

interface Props {
  onClose: VoidFunction;
  position?: TooltipPositionCoordinates;
  isOpen: boolean;
}

export const CrossVisitationMainTooltip: React.FC<Props> = ({ onClose, isOpen, position }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      onClose={onClose}
      title={t('reportItem.howItWorks')}
      isOpen={isOpen}
      initialPosition={position}
      height={280}
    >
      <div>
        <p>{t('crossVisitation.tooltip.paragraph1')}</p>
        <p>{t('crossVisitation.tooltip.paragraph2')}</p>
      </div>
    </Tooltip>
  );
};
