import { useCallback, useMemo, useState } from 'react';
import { DataTypeExtended, DirectionTypes } from './types';
import { calculateOptions, calculateTotal, dataSort } from './utils';

const useBaseTable = (data: DataTypeExtended[]) => {
  const calculatedData = useMemo(() => calculateOptions(data), [data]);
  const total = useMemo(() => calculateTotal(calculatedData), [calculatedData]);

  const [order, setOrder] = useState<{
    key: string;
    direction: DirectionTypes;
  } | null>(null);

  const changeOrder = useCallback(
    (key: string) => {
      setOrder((preOrder) =>
        preOrder && preOrder.key === key
          ? { key, direction: preOrder.direction === 'asc' ? 'desc' : 'asc' }
          : { key, direction: 'asc' },
      );
    },
    [setOrder],
  );

  const sortingData = useMemo(
    () => (order ? dataSort(calculatedData, order.key, order.direction) : calculatedData),
    [order, calculatedData],
  );

  return {
    data: {
      items: sortingData,
      total,
      order,
    },
    handlers: {
      changeOrder,
    },
  };
};

export default useBaseTable;
