import React from 'react';
import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import { Field, InputTypesEnum } from '../../../../common/inputs';
import { Credentials } from '../interfaces';
import { validationSchema } from './schemes';
import styles from './style.module.scss';
import { Routes } from '../../../../routes';

interface Props {
  onSubmit: (data: Credentials) => void;
  error: string;
}
export const Form: React.FC<Props> = ({ onSubmit, error }) => {
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.fields}>
        <div className={styles.field}>
          <Field
            name="password"
            onChange={handleChange}
            value={values.password}
            placeholder="Password"
            id="password"
            error={errors.password}
            fieldType={InputTypesEnum.PASSWORD}
          />
        </div>
        <div className={styles.field}>
          <Field
            name="repeatPassword"
            onChange={handleChange}
            value={values.repeatPassword}
            placeholder="Repeat password"
            id="repeatPassword"
            error={errors.repeatPassword}
            fieldType={InputTypesEnum.PASSWORD}
          />
        </div>
      </div>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.actions}>
        <button type="submit" className={styles.button}>
          Update password
        </button>
        <NavLink to={Routes.dashboard} className={styles.textButton}>
          Login to a different account
        </NavLink>
      </div>
    </form>
  );
};
