import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import styles from './modal.module.css';

interface Props {
  isVisible?: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal = ({ isVisible: isVisibleProps = true, onClose, children }: Props): ReactElement => {
  const [isVisible, setIsVisible] = useState(isVisibleProps);

  const closeModal = useCallback(() => {
    setIsVisible(false);
    onClose();
  }, []);

  return (
    <ReactModal
      isOpen={isVisible}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalContent}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
