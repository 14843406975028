import React, { ReactElement } from 'react';
import styles from './editUserModal.module.css';

interface Props {
  id: string;
  type?: string;
  label: string;
  value: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({ id, label, type = 'text', value, disabled = false, onChange = () => {} }: Props): ReactElement => (
  <div className={styles.formRow}>
    <label htmlFor={id}>
      {label}
      <input type={type} id={id} name={id} value={value} disabled={disabled} onChange={onChange} />
    </label>
  </div>
);

export default Input;
