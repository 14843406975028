import React from 'react';
import styles from './yearMonthPicker.module.scss';
import { CaretLeftIcon, CaretRightIcon } from '../../icons/yearMonthPicker';

interface Props {
  date: Date;
  label: string;
  onChange: (amount: number) => void;
  isDecreaseEnabled: boolean;
  isIncreaseEnabled: boolean;
  disabled?: boolean;
}

const YearMonthPicker: React.FC<Props> = ({
  date,
  label,
  onChange,
  isDecreaseEnabled,
  isIncreaseEnabled,
  disabled,
}) => {
  const decreaseDate = () => {
    if (!disabled) {
      onChange(-1);
    }
  };

  const increaseDate = () => {
    if (!disabled) {
      onChange(1);
    }
  };

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
      <span>{label}</span>
      <div className={styles.yearContainer}>
        <span>{`${date.toLocaleString('default', {
          month: 'long',
        })} ${date.getFullYear()}`}</span>
        <div className={styles.buttonContainer}>
          <button type="button" onClick={decreaseDate} disabled={!isDecreaseEnabled}>
            <CaretLeftIcon fill={isDecreaseEnabled && !disabled ? '#00B2F1' : '#b8c6cc'} />
          </button>
          <button type="button" onClick={increaseDate} disabled={!isIncreaseEnabled}>
            <CaretRightIcon fill={isIncreaseEnabled && !disabled ? '#00B2F1' : '#b8c6cc'} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default YearMonthPicker;
