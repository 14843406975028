import { API_URLS } from '../../../routes';
import { toDataURL } from '../../../common/utils';
import { API_URL } from '../../../../api';
import { commonRequestHeaders } from '../../../commonRequestHeaders';

export const getAvatar = async (userId: string) => {
  const url = API_URLS.USER_AVATAR;

  return toDataURL(`${API_URL}/api/users/${userId}${url}`, commonRequestHeaders());
};
