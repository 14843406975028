import { createEvent, createStore, sample } from 'effector';
import { BaseItem, Category, FiltersOption, FiltersValues, LocationData, Metric, MetricType } from '../interfaces';
import { getData, getFilters } from './effects';
import { changeFilters, updateFiltersOptions, updateInitialFilters } from './events';
import { parseFiltersAccordingToInitialFilters } from './utils';
import { Option } from '../../../../../common/controls/dropdown/types';
import { countries } from '../../../../../common/utils';
import { enrichFilterOptionsByOptionAll } from './utils/enrichFilterOptionsByOptionAll';

const INITIAL_METRIC_FILTER = 'households';

const parseToOptions = <T extends BaseItem>(data: T[]): Option<null>[] =>
  data.map(({ value, label }) => ({ id: value, label, disabled: false, item: null }));

export const $data = createStore<LocationData[]>([]);

export const $metricType = createStore<MetricType | null>(null);

export const $yAxisLabel = createStore<string>('');

$data.on(getData.doneData, (store, { locations }) => locations.map((location: any) => ({ ...location, color: 'red' })));

$metricType.on(getData.doneData, (_, { metricType }) => metricType);
$yAxisLabel.on(getData.doneData, (_, { yAxisLabel }) => yAxisLabel);

export const $activeFilters = createStore<FiltersValues>({
  country: '',
  metric: '',
  category: '',
  subcategory: '',
});

export const $initialFilterOptions = createStore<FiltersOption[]>([]);

export const $filterOptions = createStore<{
  countries: Option<null>[];
  metrics: Option<null>[];
  categories: Option<null>[];
  subCategories: Option<null>[];
  filterOptionsId: string;
}>({
  countries: [],
  metrics: [],
  categories: [],
  subCategories: [],
  filterOptionsId: '',
});

const updateActiveFilters =
  createEvent<{ source: [FiltersOption[], FiltersValues]; clock: { type: keyof FiltersValues; value: string } }>();

sample({
  source: [$initialFilterOptions, $activeFilters],
  clock: changeFilters,
  fn: (source, clock) => ({ source, clock }),
  target: updateActiveFilters,
});

$activeFilters.on(updateActiveFilters, (store, { source, clock: { type, value } }) => {
  const [data, filters] = source;
  const country = data.find((item) => item.country === filters.country);
  if (type === 'metric') {
    const metric = country?.metrics.find((item) => item.value === value);
    const category = metric?.categories?.[0];
    const subcategory = category?.subcategories?.[0];
    return { ...store, [type]: value, category: category?.value || '', subcategory: subcategory?.value || '' };
  }

  if (type === 'category') {
    const metric = country?.metrics.find((item) => item.value === filters.metric);
    const category = metric?.categories.find((item) => item.value === value);
    return { ...store, [type]: value, subcategory: category?.subcategories?.[0]?.value || '' };
  }

  return { ...store, [type]: value };
});

sample({
  source: $activeFilters,
  clock: getFilters.doneData,
  fn: (filters, { data }) => ({ filters, data }),
  target: updateInitialFilters,
});

$filterOptions.on(updateInitialFilters, (_, { filters, data }) => {
  const allOptions = enrichFilterOptionsByOptionAll(data);
  const { metric, category, subcategories } = parseFiltersAccordingToInitialFilters(
    filters.country,
    null,
    null,
    allOptions,
  );
  return {
    metrics: parseToOptions<Metric>(metric),
    categories: parseToOptions<Category>(category),
    subCategories: parseToOptions<BaseItem>(subcategories),
    countries: parseToOptions<BaseItem>(countries.map((country) => ({ ...country, value: country.code }))),
    filterOptionsId: '',
  };
});

$initialFilterOptions.on(getFilters.doneData, (_, { data }) => enrichFilterOptionsByOptionAll(data));

$activeFilters.on(getFilters.doneData, ({ country: countryCode }, { data, paramsFilters }) => {
  const allOptions = enrichFilterOptionsByOptionAll(data);
  const filter = allOptions.find(({ country }) => country === countryCode);
  const metric = filter?.metrics.find(({ value }) => value === INITIAL_METRIC_FILTER) || filter?.metrics?.[0];
  const category = metric?.categories?.[0];
  const subCategory = category?.subcategories?.[0];

  return {
    country: paramsFilters.country || countryCode,
    metric: paramsFilters.metric || metric?.value || '',
    category: paramsFilters.category || category?.value || '',
    subcategory: paramsFilters.subcategory || subCategory?.value || '',
  };
});

$filterOptions.on(updateFiltersOptions, (store, { filters, initialData, filterOptionsId }) => {
  const { metric: metricFilter, category: categoryFilter, country: countryFilter } = filters;

  const { metric, category, subcategories } = parseFiltersAccordingToInitialFilters(
    countryFilter,
    metricFilter,
    categoryFilter,
    initialData,
  );

  return {
    ...store,
    metrics: parseToOptions<Metric>(metric),
    categories: parseToOptions<Category>(category),
    subCategories: parseToOptions<BaseItem>(subcategories),
    filterOptionsId,
  };
});
