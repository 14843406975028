import styles from '../style.module.scss';
import { PeriodsEnum } from '../../../../common/reportItems/types';
import { dateFormatter } from '../../utils';
import { sortByValue } from '../../../../common/utils';
import { getTooltipHeight } from '../../../../common/chart';

const isNumber = (value?: string) => value && !Number.isNaN(value) && !Number.isNaN(parseFloat(value));

export const generateTooltip = (
  params: any[],
  isPercentage: boolean,
  period: PeriodsEnum,
  t: (val: string) => string,
) => {
  const sortedData = sortByValue(params);
  const dataLength = sortedData.length;
  const date = dateFormatter(sortedData[0].axisValueLabel, period);
  const isAnyLocationWithoutValue =
    sortedData.some(({ value }: { value?: string }) => !isNumber(value)) && !isPercentage;

  const maxItemsHeight = getTooltipHeight(dataLength);
  const header = `<div class="${styles.header}">
                          <span class="${styles.title}"> ${
    isPercentage ? t('visitTrends.detail.percentOfTotalVisits') : t('visitTrends.detail.totalVisits')
  }</span>
                             <p class="${styles.date}">${
    period === PeriodsEnum.MONTHLY ? date : `${t('visitTrends.detail.weekStartingOn')}: ${date}`
  }</p>
                ${
                  isAnyLocationWithoutValue
                    ? `
                    <span class="${styles.alertInfo}">
                      <b>Note!</b> ${t('visitTrends.detail.insufficientData')}
                    </span>`
                    : ''
                }
                        </div>`;

  let items = '';
  sortedData.forEach(({ marker, seriesName, value }: any) => {
    items += `
                    <div class="${styles.item} ${!isPercentage && !isNumber(value) ? styles.disabled : ''}">
                    <div><span>${marker}</span><span class="${styles.name}">${seriesName}:</span></div>  
                       <span class="${styles.value}">${
      isPercentage && isNumber(value)
        ? `${(value * 100).toFixed(2)}%`
        : isNumber(value)
        ? Number(value.toFixed(0)).toLocaleString()
        : 'N/A'
    }</span>
                    </div>`;
  });
  const container = `<div class="${styles.echartsTooltip}">
                         ${header}
                           <div class="custom-scrollbar ${styles.items}" style="height:${maxItemsHeight}px;">
                             ${items}
                           </div>
                      </div>`;

  return container;
};
