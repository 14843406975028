import React from 'react';
import styles from './style.module.scss';
import { Circle } from '../../circle';
import { ParticleData } from '../../interfaces';

interface Props {
  data: ParticleData[];
  Icon?: React.ElementType;
}

export const ImageCircle: React.FC<Props> = ({ data, Icon }) => {
  const isSingle = data.length === 1;
  const options = {
    cx: 80,
    cy: 80,
    radius: 60,
    strokeWidth: 5,
    circ: 5,
    circumferenceAdjuster: isSingle ? 0 : 2,
  };
  return (
    <div className={styles.component}>
      <Circle data={data} options={options} useBackground />
      {Icon && (
        <span className={styles.icon}>
          <Icon />
        </span>
      )}
    </div>
  );
};
