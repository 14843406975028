import React from 'react';
import { ButtonTooltip } from '../../controls/tooltip/buttonTooltip';
import styles from './menuItem.module.scss';
import { MenuItemsEnum } from '../../sidebarMenu/menuItems';

interface Props {
  icon: React.ReactElement;
  id: MenuItemsEnum;
  onClick: (id: MenuItemsEnum) => void;
  selected?: boolean;
  title?: string;
}

export const MenuItem = ({ id, icon, selected, onClick, title }: Props) => (
  <div className={styles.menuItem}>
    <ButtonTooltip title={title}>
      <button
        type="button"
        id={id}
        className={`${styles.menuItemButton} ${selected ? styles.selected : ''}`}
        onClick={() => onClick(id)}
      >
        {icon}
      </button>
    </ButtonTooltip>
  </div>
);
