import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CatchmentLocationsCountEnum,
  CatchmentMetricTypeEnum,
  CatchmentSocioDemoMetricEnum,
  CatchmentTypeEnum,
  ColorSchemeEnum,
  DataLevelEnum,
  LocationTypeEnum,
  OverlayHotspotsEnum,
  PeriodsEnum,
  ReportItemType,
  ReportItemVisualizationMode,
} from '../reportItems/types';
import { useToggle } from './useToggle';
import { ContentVisualizationMode } from '../types/projectsHierarchy';
import { SwitchModes } from '../types/visualizationObjects';
import { TrendScopesEnum } from '../../reportItems/trends/enum';
import { MainContext } from '../types/mainContext';

interface Params {
  primaryLocation: string;
  reportItemType: ReportItemType;
  contentVisualizationMode: ContentVisualizationMode;
  reportItemVisualizationMode: ReportItemVisualizationMode;
  switchMode: SwitchModes;
  latitude: number | null;
  longitude: number | null;
  zoom: number | null;
  catchmentTripRoutesLatitude: number | null;
  catchmentTripRoutesLongitude: number | null;
  catchmentTripRoutesZoom: number | null;
  timeFrames: string;
  colorScheme: ColorSchemeEnum;
  dataLevel: DataLevelEnum;
  locationType: LocationTypeEnum;
  hotspotOverlay: OverlayHotspotsEnum;
  movementThresholdMin: null | number;
  movementThresholdMax: null | number;
  catchmentThresholdMin: null | number;
  catchmentThresholdMax: null | number;
  catchmentType: CatchmentTypeEnum;
  catchmentLocationsCount: CatchmentLocationsCountEnum;
  catchmentDataOverlay: null | 'YES' | 'NO';
  catchmentMetricType: CatchmentMetricTypeEnum;
  catchmentSocioDemoMetric: CatchmentSocioDemoMetricEnum;
  movementPeriod: string;
  period: PeriodsEnum;
  metricType: TrendScopesEnum;
  showHoursByWeek: '1' | '0' | null;
  showDetailsAverage: '1' | '0' | null;
  catchmentSocioCountry: string;
  catchmentSocioMetric: string;
  catchmentSocioCategory: string;
  catchmentSocioSubCategory: string;
  selectedLocations: string;
  reportsSortBy: string;
  projectsSortBy: string;
  password: string;
  login: string;
}

export const useURLParams = () => {
  const history = useHistory();
  const [params, setParams] = useState<Partial<Params>>({
    primaryLocation: '',
    reportItemType: '' as ReportItemType,
    contentVisualizationMode: '' as ContentVisualizationMode,
    reportItemVisualizationMode: '' as ReportItemVisualizationMode,
    switchMode: '' as SwitchModes,
    latitude: null,
    longitude: null,
    zoom: null,
    catchmentTripRoutesLatitude: null,
    catchmentTripRoutesLongitude: null,
    catchmentTripRoutesZoom: null,
    timeFrames: '',
    colorScheme: '' as ColorSchemeEnum,
    dataLevel: '' as DataLevelEnum,
    locationType: '' as LocationTypeEnum,
    hotspotOverlay: '' as OverlayHotspotsEnum,
    movementThresholdMin: null,
    movementThresholdMax: null,
    catchmentThresholdMin: null,
    catchmentThresholdMax: null,
    catchmentType: '' as CatchmentTypeEnum,
    catchmentLocationsCount: '' as CatchmentLocationsCountEnum,
    catchmentDataOverlay: 'NO',
    catchmentMetricType: '' as CatchmentMetricTypeEnum,
    catchmentSocioDemoMetric: '' as CatchmentSocioDemoMetricEnum,
    movementPeriod: '',
    period: '' as PeriodsEnum,
    metricType: '' as TrendScopesEnum,
    showHoursByWeek: null,
    showDetailsAverage: null,
    catchmentSocioCountry: '',
    catchmentSocioMetric: '',
    catchmentSocioCategory: '',
    catchmentSocioSubCategory: '',
    selectedLocations: '',
    reportsSortBy: '',
    projectsSortBy: '',
    password: '',
    login: '',
  });
  const [isParsingDone, toggleIsParsingDone] = useToggle(false);
  const [isParamsChecked, toggleIsParamsChecked] = useToggle(false);
  const { updateReportItemsDetailsValue } = useContext(MainContext);

  const getParams = (): Params => {
    const URLParams = new URLSearchParams(window.location.search);
    const string = URLParams.toString();
    const splitString = string.split('&');
    return splitString.reduce((acc, curr) => {
      const [key, value] = curr.split('=');

      return { ...acc, [key]: value };
    }, {}) as Params;
  };

  useEffect(() => {
    toggleIsParsingDone(false);
    const params = getParams();

    const {
      // catchment params
      catchmentThresholdMin,
      catchmentThresholdMax,
      colorScheme,
      catchmentType,
      catchmentLocationsCount,
      catchmentDataOverlay,
      catchmentMetricType,
      catchmentSocioDemoMetric,
      // movement params
      dataLevel,
      locationType,
      movementThresholdMin,
      movementThresholdMax,
      hotspotOverlay,
      movementPeriod,
    } = params;

    if (colorScheme) {
      updateReportItemsDetailsValue('colorScheme', colorScheme);
    }
    if (catchmentThresholdMin && catchmentThresholdMax) {
      updateReportItemsDetailsValue('catchmentTreshold', { min: catchmentThresholdMin, max: catchmentThresholdMax });
    }
    if (catchmentType) {
      updateReportItemsDetailsValue('catchmentType', catchmentType);
    }
    if (catchmentLocationsCount) {
      updateReportItemsDetailsValue('catchmentLocationsCount', catchmentLocationsCount);
    }
    if (catchmentDataOverlay) {
      updateReportItemsDetailsValue('catchmentDataOverlay', catchmentDataOverlay === 'YES');
    }
    if (catchmentMetricType) {
      updateReportItemsDetailsValue('catchmentMetricType', catchmentMetricType);
    }
    if (catchmentSocioDemoMetric) {
      updateReportItemsDetailsValue('catchmentSocioDemoMetric', catchmentSocioDemoMetric);
    }
    if (dataLevel) {
      updateReportItemsDetailsValue('dataLevel', dataLevel);
    }
    if (locationType) {
      updateReportItemsDetailsValue('locationType', locationType);
    }
    if (movementThresholdMin && movementThresholdMax) {
      updateReportItemsDetailsValue('movementTreshold', { min: movementThresholdMin, max: movementThresholdMax });
    }
    if (hotspotOverlay) {
      updateReportItemsDetailsValue('hotspotOverlay', hotspotOverlay);
    }
    if (movementPeriod) {
      updateReportItemsDetailsValue('movementPeriod', movementPeriod);
    }

    setParams(params);
    toggleIsParsingDone(true);
  }, []);

  const onChangeParams = (type: keyof typeof params, value: string | number) => {
    const {
      location: { pathname, search },
    } = window;
    const URLParams = new URLSearchParams(search);

    URLParams.set(type, String(value));

    const string = URLParams.toString();

    history.replace(`${pathname}?${string}`);
  };

  const onRemoveParam = (type: keyof typeof params) => {
    const {
      location: { pathname, search },
    } = window;
    const URLParams = new URLSearchParams(search);

    URLParams.delete(type);

    const string = URLParams.toString();

    history.replace(`${pathname}?${string}`);
  };

  const generateLinkWithSelectedParams = (types: (keyof typeof params)[]) => {
    const params = getParams();
    const selectedParams = types.map((type) => ({ type, value: params[type] }));
    const filteredParams = selectedParams.filter(({ value }) => value && value !== 'undefined');
    const searchString = filteredParams.reduce((curr, { type, value }) => `${curr}${type}=${value}&`, '');
    return searchString ? `?${searchString}` : '';
  };

  return {
    params,
    onChangeParams,
    onRemoveParam,
    isParsingDone,
    getParams,
    isParamsChecked,
    onParamsCheck: toggleIsParamsChecked,
    generateLinkWithSelectedParams,
  };
};
