import React from 'react';

interface Props {}

const PlacenseLogoBlue = (props: Props) => (
  <svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9933 15.4913L12.9956 17.489L19.6736 24.1757L17.0021 26.8482L8.40711 18.2454C7.63242 17.5616 7.00824 16.7239 6.57409 15.7854C6.13993 14.847 5.90525 13.8285 5.88507 12.7944C5.88507 10.9055 6.63421 9.0939 7.96759 7.75824C9.30097 6.42257 11.1095 5.67221 12.9953 5.67221C14.881 5.67221 16.6895 6.42257 18.0231 7.75824C19.3565 9.0939 20.1056 10.9055 20.1056 12.7944C20.0854 13.8285 19.8508 14.8472 19.4166 15.7856C18.9822 16.724 18.3578 17.5617 17.5829 18.2454L17.0002 18.8286L14.3337 16.155L14.998 15.4913C15.78 14.8036 16.259 13.8343 16.3309 12.7944C16.3191 11.9166 15.9627 11.0787 15.3387 10.4622C14.7148 9.84561 13.8735 9.49992 12.9971 9.49992C12.1208 9.49992 11.2796 9.84561 10.6556 10.4622C10.0316 11.0787 9.67521 11.9166 9.6634 12.7944C9.73512 13.834 10.2137 14.8032 10.9951 15.4913H10.9933ZM7.1156 19.6219L12.9981 25.5086L15.6679 28.1823L12.9969 30.8572L4.53129 22.3772C2.59006 20.6556 1.21782 18.383 0.596904 15.8615C-0.0240513 13.34 0.135611 10.6889 1.0547 8.26043C1.97383 5.83198 3.60866 3.74115 5.7425 2.26569C7.87612 0.790227 10.4076 0 13.0003 0C15.593 0 18.1242 0.790227 20.2581 2.26569C22.3917 3.74115 24.0267 5.83198 24.9457 8.26043C25.8649 10.6889 26.0244 13.34 25.4035 15.8615C24.7825 18.383 23.4103 20.6556 21.4693 22.3772L21.0072 22.8394L18.3394 20.1669L18.8817 19.6244C19.8501 18.7688 20.628 17.7188 21.165 16.5426C21.702 15.3663 21.9859 14.0901 21.9985 12.7968C21.979 10.4192 21.0225 8.14556 19.3369 6.4712C17.6516 4.79686 15.374 3.8574 13.0003 3.8574C10.6264 3.8574 8.34892 4.79686 6.66341 6.4712C4.97811 8.14556 4.02135 10.4192 4.00181 12.7968C4.01426 14.0894 4.29768 15.3649 4.83383 16.5406C5.36997 17.7164 6.14681 18.7662 7.11388 19.6219H7.1156Z"
      fill="#00B2F1"
    />
  </svg>
);

export default PlacenseLogoBlue;
