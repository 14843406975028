import React from 'react';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import MultiSelect from 'react-multi-select-component';

interface Props {
  options: Option[];
  value: Option[];
  onChange?: any;
  className?: string;
  disableSearch?: boolean;
}

const Multiselect = (props: Props) => {
  const customSearch = (options: Option[], searchValue: string) => {
    if (!searchValue) {
      return options;
    }
    return options.filter((option) => option.label && option.label.toLowerCase().includes(searchValue.toLowerCase()));
  };

  return (
    <MultiSelect
      className={props.className}
      options={props.options}
      value={props.value}
      onChange={props.onChange}
      labelledBy="Selected"
      hasSelectAll
      filterOptions={customSearch}
      disableSearch={props.disableSearch?props.disableSearch:false}
    />
  );
};

export default Multiselect;
