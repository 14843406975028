import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../common/tooltip';
import { TooltipPositionCoordinates } from '../../../../../common/reportItems/hooks';

interface Props {
  onClose: VoidFunction;
  position?: TooltipPositionCoordinates;
  isOpen: boolean;
}

export const AttractionMainTooltip: React.FC<Props> = ({ onClose, position, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      onClose={onClose}
      title={t('reportItem.howItWorks')}
      initialPosition={position}
      isOpen={isOpen}
      height={300}
    >
      <div>
        <p>
          {t('attraction.tooltip.paragraph1')}
          <br />
          {t('attraction.tooltip.paragraph2')}
          <br />
          {t('attraction.tooltip.paragraph3')}
        </p>
        <p>
          <i>
            {t('attraction.tooltip.exampleTitle')}
            <br />
            {t('attraction.tooltip.exampleParagraph1')}
          </i>
        </p>
      </div>
    </Tooltip>
  );
};
