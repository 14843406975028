import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/reducers';
import styles from './errorMessage.module.css';
import { clearErrorMessage } from '../../../actions/actions';

const ErrorMessage = (): ReactElement | null => {
  const message = useSelector<AppState, string | null>((state) => state.errorMessage.message);
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(clearErrorMessage());
    },
    [dispatch],
  );

  let content = null;

  if (message) {
    content = <p className={styles.message}>{message}</p>;
  }

  return content;
};

export default ErrorMessage;
