import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_LEVELS } from '../utils/color.config';
import { getGradientColor } from '../utils/getGradientUtil';
import s from './mapScale.module.css';

interface MapScaleProps {
  min: string;
  max: string;
  title?: string;
  isMonochrome?: boolean;
}

const MapScale: React.FC<MapScaleProps> = ({ min, max, title, isMonochrome = false }) => {
  const colors = [];
  const { t } = useTranslation();
  for (let i = 0; i < COLOR_LEVELS; i++) colors.push(i);

  return (
    <div className={s.root}>
      <h3 className={s.title}>{title || t('catchment.map.visitsShare')}</h3>
      <div className={s.information}>
        <span className={s.range__value}>{Number(min.replaceAll('%', '')) > 0 && min === max ? '0.00%' : min}</span>
        <div className={s.scale}>
          {colors.map((i) => (
            <span
              key={i}
              className={s.scale__color}
              style={{
                ['--color-fill' as any]: getGradientColor(i, isMonochrome),
              }}
            />
          ))}
        </div>
        <span className={s.range__value}>{max}</span>
      </div>
    </div>
  );
};

export default MapScale;
