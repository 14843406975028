import React from 'react';

export const AbsorptionIcon: React.FC = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.4 11.0397C6.16 11.2264 5.82 11.2264 5.58 11.0397L1.48 7.85302C1.24 7.66635 0.906667 7.66635 0.666667 7.85302C0.326667 8.11969 0.326667 8.63302 0.666667 8.89969L5.17333 12.4064C5.65333 12.7797 6.32667 12.7797 6.81333 12.4064L11.32 8.89969C11.66 8.63302 11.66 8.11969 11.32 7.85302L11.3133 7.84635C11.0733 7.65969 10.74 7.65969 10.5 7.84635L6.4 11.0397ZM6.82 9.02635L11.3267 5.51969C11.6667 5.25302 11.6667 4.73302 11.3267 4.46635L6.82 0.959688C6.34 0.586354 5.66667 0.586354 5.18 0.959688L0.673334 4.47302C0.333334 4.73969 0.333334 5.25969 0.673334 5.52635L5.18 9.03302C5.66 9.40635 6.34 9.40635 6.82 9.02635Z"
      fill="white"
    />
  </svg>
);

export default AbsorptionIcon;
