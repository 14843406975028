import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportItemType } from '../../../common/reportItems/types';
import { MainContext } from '../../../common/types/mainContext';
import {
  Attraction,
  AttractionData,
  AttractionTargetLocation,
  ContentVisualizationMode,
  ReportItemInfo,
} from '../../../common/types/projectsHierarchy';
import ReportItemDetail from '../../../common/reportItems/reportItemDetail';
import AttractionDetailChart from './AttractionDetailChart';
import AttractionDetailTable from './AttractionDetailTable';
import { useElementScreenshot } from '../../../common/hooks/useElementScreenshot';
import { useStore } from 'effector-react/effector-react.cjs';
import { exportSettingsModel } from '../../../common/export';
import { ChartEventsEnum } from '../../../common/chart/enums';
import { convertDataForExcel } from './utils';
import { ShowAverageToggle } from '../../../common/reportItems/reportItemDetail/showAverageToggle/showAverageToggle';

interface Props {
  useFixedScreenshotSize?: boolean;
}
export const AttractionDetail = ({ useFixedScreenshotSize }: Props) => {
  const { t } = useTranslation();

  const {
    selection: { selectedReport },
    attractionSourceLocation,
    selectedLocationsIds,
    selectedLocations,
    contentVisualizationMode,
  } = useContext(MainContext);
  const { onMakeScreenshot, base64Image, elementRef } = useElementScreenshot({
    backgroundColor: `rgba(37, 55, 76, 1)`,
  });
  const [modifiedData, setModifiedData] = useState<AttractionData | null>(null);
  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const isChartReady = useRef<boolean>(false);
  const isExportMode = useStore(exportSettingsModel.$isExportMode);

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.ATTRACTION,
  ) as ReportItemInfo<Attraction>;

  const filteredData: AttractionData | null = useMemo(() => {
    if (reportItem) {
      if (reportItem.data && attractionSourceLocation) {
        let attraction: AttractionData | null =
          reportItem.data?.data?.find((sourceLocation) => sourceLocation.location_id === attractionSourceLocation.id) ||
          null;
        if (attraction) {
          const targets = attraction.data.filter(({ targetLocationId }) =>
            selectedLocationsIds.includes(targetLocationId),
          );
          attraction = {
            ...attraction,
            average:
              targets
                .map(({ avgWeightedAttraction }) => avgWeightedAttraction)
                .reduce((prevValue, nextValue) => prevValue + nextValue, 0) / targets.length,
            data: targets,
          };
        }
        return attraction;
      }
      return null;
    }
    return null;
  }, [JSON.stringify(reportItem), attractionSourceLocation, selectedLocationsIds]);

  useEffect(() => {
    if (filteredData) {
      let chartData = { ...filteredData };
      const targets = chartData.data.filter(({ targetLocationId }) => selectedLocationsIds.includes(targetLocationId));
      if (targets?.length > 1) {
        const averageAcrossLocations = targets[0].dateChanges.map((header, headerIndex) => {
          const sum = targets.reduce(
            (acc, valueItem) =>
              valueItem.dateChanges[headerIndex]
                ? acc + (valueItem.dateChanges[headerIndex].attraction as number)
                : acc,
            0,
          );
          return {
            timestamp: header.timestamp,
            attraction: sum / targets.length || 0,
          };
        });
        chartData = {
          ...chartData,
          data: [
            {
              targetLocationId: 'average',
              targetLocationName: 'Average',
              avgWeightedAttraction: 0,
              dateChanges: averageAcrossLocations,
            },
            ...chartData.data,
          ],
        };
      }
      setModifiedData(chartData);
    }
  }, [filteredData]);

  const noData = useMemo(() => !filteredData || filteredData.data.length === 0, [filteredData]);

  const onChartReady = useCallback(() => {
    if (reportItem?.data && !isChartReady.current) {
      isChartReady.current = true;
      if (exportOptions.ATTRACTION?.chart) {
        onMakeScreenshot();
      }
    }
  }, []);

  const chartEvents = {
    [ChartEventsEnum.FINISHED]: onChartReady,
  };

  const attractionLocations = useMemo(() => {
    const locations = [...selectedLocations];
    if (attractionSourceLocation) {
      locations.push(attractionSourceLocation);
    }
    return locations;
  }, [selectedLocations, attractionSourceLocation]);

  useEffect(() => {
    if (!filteredData?.data) {
      exportSettingsModel.updateExportData({ [ReportItemType.ATTRACTION]: { chart: '', table: '' } });
    }
  }, [filteredData?.data]);

  useEffect(() => {
    if (base64Image) {
      exportSettingsModel.updateExportData({ [ReportItemType.ATTRACTION]: { chart: base64Image } });
    }
  }, [base64Image]);

  useEffect(() => {
    if (filteredData?.data && exportOptions.ATTRACTION?.table) {
      const data = convertDataForExcel(filteredData);
      exportSettingsModel.updateExportData({ [ReportItemType.ATTRACTION]: { table: data } });
    }
  }, [exportOptions.ATTRACTION]);

  const render = (data: AttractionTargetLocation[] | null | undefined) => {
    if (!data) return;

    if (contentVisualizationMode === ContentVisualizationMode.VISUALIZATION || isExportMode)
      return (
        <AttractionDetailChart
          data={data}
          projectLocations={attractionLocations}
          events={chartEvents}
          isScreenshotMode={isExportMode}
          useFixedScreenshotSize={useFixedScreenshotSize}
        />
      );

    return <AttractionDetailTable data={data} locationName={String(modifiedData?.location_name)} />;
  };

  const minimizedData = useMemo(
    () => render(modifiedData?.data),
    [modifiedData?.data, contentVisualizationMode, isExportMode],
  );

  return (
    <div ref={elementRef} style={{ width: '100%', height: '100%' }}>
      <ReportItemDetail
        type={ReportItemType.ATTRACTION}
        title={t('attraction.title')}
        subtitle={t('absorption.detail.showingShareOfVisitors')}
        noData={noData}
        onMakeScreenshot={onMakeScreenshot}
      >
        {contentVisualizationMode === ContentVisualizationMode.VISUALIZATION && <ShowAverageToggle />}
        {filteredData?.data && minimizedData}
      </ReportItemDetail>
    </div>
  );
};
