import React from 'react';
import { ChartData, StakeData } from './interfaces';
import { BackgroundCircle } from './backgroundCircle';
import styles from './style.module.scss';
import { Categories } from './categories';
import { LocationInformation } from './locationInformation';
import { Retail } from '../../../reportItems/catchment/detail/Chart/model';
import { Country } from '../../utils';
import Dropdown from '../../controls/dropdown';
import { Option } from '../../controls/dropdown/types';

interface Props {
  country: string;
  countries: Option<Country>[];
  onChangeCountry: (country: Option<Country>) => void;
  categories: ChartData[];
  subCategories: StakeData[];
  retails: Retail[];
  locationName: string | null;
  rank: number | null;
  total: number | null;
}

export const DemographicChart: React.FC<Props> = ({
  country,
  countries,
  onChangeCountry,
  categories,
  subCategories,
  retails,
  locationName,
  rank,
  total,
  children,
}) => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <div className={styles.countries}>
        <Dropdown<Country> onChange={onChangeCountry} options={countries} selectedId={country} placeholder="Country" />
      </div>
      {/* <CategoryRank category="Cafes, restaurants" /> */}
      <div className={styles.circle}>
        <Categories subCategories={subCategories} retails={retails} />
        <BackgroundCircle data={categories} />
        <LocationInformation image="/img/v2/temp-location.png" name={locationName} rank={rank} total={total}>
          {children}
        </LocationInformation>
      </div>
    </div>
  </div>
);
