import { convertPolygonToGeoData } from './convertPolygonToGeoData';
import { getCenterCoordinateOfGeoPolygon } from './getCenterCoordinateOfGeoPolygon';

/**
 * Get center fo the polygon
 * @param geo - Geo Polygon Object
 * @return center coordinate
 */
export const getCenterCoordinate = (polygonString: string) => {
  const geo = convertPolygonToGeoData(polygonString);
  return getCenterCoordinateOfGeoPolygon(geo);
};
