import { request } from '../../request';
import { API_URLS } from '../../../routes';
import { CatchmentTypeEnum } from '../../../common/reportItems/types';

export const getSocialDemographicData = <T>(
  reportItemId: string,
  locationId: string,
  type: CatchmentTypeEnum,
  timeFrames: string[] | null,
  minThreshold: number | null,
  maxThreshold: number | null,
  countryCode: string | null,
): Promise<T> => {
  const minThresh = minThreshold ? `minThreshold=${minThreshold}` : '';
  const maxThresh = maxThreshold ? `maxThreshold=${maxThreshold}` : '';
  const time = timeFrames ? `timeFrames=${timeFrames.join(',')}` : '';
  const url = `${
    API_URLS.REPORT_ITEMS_DATA
  }/${reportItemId}/data/social-demographics/${countryCode}/${locationId}/${type.toLowerCase()}?${minThresh}&${maxThresh}&${time}`;

  return request({ url, method: 'POST' });
};
