export const getPostalCodeOpacity = (value: number | null) => {
  if (!value) return 1;

  const zoom = Math.floor(value);

  if (zoom <= 20 && zoom > 15) {
    return 0.3;
  }

  if (zoom <= 15 && zoom > 12) {
    return 0.5;
  }

  if (zoom <= 12 && zoom > 10) {
    return 0.7;
  }

  if (zoom === 10) {
    return 0.8;
  }

  if (zoom === 9) {
    return 0.9;
  }

  if (zoom <= 8) {
    return 1;
  }
};
