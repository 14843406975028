import React from 'react';

export const RetentionIcon = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3.73138V7.4387C0 8.91356 0.597022 10.328 1.65973 11.3709C2.72243 12.4138 4.16377 12.9997 5.66667 12.9997C7.16956 12.9997 8.6109 12.4138 9.6736 11.3709C10.7363 10.328 11.3333 8.91356 11.3333 7.4387V3.73138H8.81481V7.4387C8.81481 8.25807 8.48314 9.04388 7.89274 9.62326C7.30235 10.2026 6.50161 10.5281 5.66667 10.5281C4.83173 10.5281 4.03098 10.2026 3.44059 9.62326C2.8502 9.04388 2.51852 8.25807 2.51852 7.4387V3.73138H0ZM8.81481 2.49561H11.3333V0.641951H8.81481V2.49561ZM0 2.49561H2.51852V0.641951H0V2.49561ZM6.2963 0.333008L3.77778 4.96715H5.03704V8.36553L7.55556 3.73138H6.2963V0.333008Z"
      fill="white"
    />
  </svg>
);
