import { center, polygon, area, pointOnFeature, inside, centerOfMass } from '@turf/turf';

/**
 * Get center fo the polygon
 * @param geo - Geo Polygon Object
 * @return center coordinate
 */
export const getCenterCoordinateOfGeoPolygon = (
  geo: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> | null,
) => {
  if (geo && geo.geometry.type === 'Polygon') {
    const feature = polygon(geo.geometry.coordinates);
    const point = center(feature);
    if (inside(point, feature)) {
      return point.geometry.coordinates as number[];
    }
    return centerOfMass(feature).geometry.coordinates as number[];
  }
  if (geo && geo.geometry.type === 'MultiPolygon') {
    // const feature = polygon(geo.geometry.coordinates[0]);
    const biggestPolygon = geo.geometry.coordinates?.reduce((res, i) =>
      area(polygon(res)) > area(polygon(i)) ? res : i,
    );
    const feature = polygon(biggestPolygon);

    const point = center(feature);
    if (inside(point, feature)) {
      return point.geometry.coordinates as number[];
    }
    return centerOfMass(feature).geometry.coordinates as number[];
  }
  return [] as number[];
};
