import React from 'react';
import { ActiveAccountCongratulations } from '../../auth';
import { IntroPageTemplate } from '../../common/templates';

export const ActivateAccountCongratulationsPage = () => {
  return (
    <IntroPageTemplate>
      <ActiveAccountCongratulations />
    </IntroPageTemplate>
  );
};
