import React from 'react';
import { ActivateAccountModal } from '../../auth';
import { IntroPageTemplate } from '../../common/templates';

interface Props {}
export const ActivateAccountPage: React.FC<Props> = () => {
  return (
    <IntroPageTemplate>
      <ActivateAccountModal />
    </IntroPageTemplate>
  );
};
