import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from '../../../../common/popup';
import styles from './style.module.scss';
import { Button } from '../../../../common/controls/buttons';
import { areaTypes } from '../areaType/areaTypeDropdown';

interface Props {
  isOpen: boolean;
  area: string;
  onClose: () => void;
  onSave: () => void;
}

const CatchmentRadiusPopup = ({ isOpen, area, onClose, onSave }: Props) => {
  const { t } = useTranslation();

  return (
    <Popup isOpen={isOpen} onClose={onClose} height={230}>
      <div className={styles.container}>
        <p>Are you sure you want to create a catchment on {areaTypes.find(({ id }) => id === area)?.label} level?</p>
        <p>Please check details for data granularity.</p>
        <p>Do you want to continue?</p>
        <div className={styles.buttons}>
          <Button onClick={onClose}>{t('reportItem.no')}</Button>
          <Button layout="primary" onClick={onSave}>
            {t('reportItem.yes')}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default CatchmentRadiusPopup;
