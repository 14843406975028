import { CrossVisitationLocation } from '../../../../common/types/projectsHierarchy';
import { toFixNumber } from '../../../../common/lib';

export const convertDataForExcel = (data: CrossVisitationLocation) => {
  return data.data.map(({ aerial_distance, visitors_loyalty, ratio }) => ({
    area_name: data.primary_location_name,
    aerial_distance: aerial_distance > 0 ? `${aerial_distance} km` : '-',
    visitors_loyalty: `${toFixNumber(visitors_loyalty * 100, 2)}%`,
    ratio: ratio,
  }));
};
