import { useEffect, useState } from 'react';
import { getRandomArbitrary } from '../utils';
import { useToggle } from './useToggle';

const INIT_VALUE = 0;
const MAX_SECOND_TICK = 30;
const UPDATE_EACH_MS = 500;
const DELAY_BETWEEN_FINISH_AND_DISPLAYING_CONTENT_MS = 100;

type Callback = ((value: number) => void) | null;
export const useLoadProgress = (callback?: Callback, start = true) => {
  const [currentProgress, setCurrentProgress] = useState(INIT_VALUE);
  const [isCompleted, toggleIsCompleted] = useToggle(false);

  useEffect(() => {
    if (start) {
      toggleIsCompleted(false);
      setCurrentProgress(INIT_VALUE);
      let prevProgress = INIT_VALUE;
      const STOP_LOAD_WHEN = 100;
      const INIT_SECOND_TICK = getRandomArbitrary(INIT_VALUE, MAX_SECOND_TICK);
      const interval = setInterval(() => {
        const value = Math.ceil(
          getRandomArbitrary(prevProgress, prevProgress === INIT_VALUE ? INIT_SECOND_TICK : STOP_LOAD_WHEN),
        );
        prevProgress = value;
        setCurrentProgress(value);

        if (callback) callback(value);

        if (value === STOP_LOAD_WHEN) {
          const timeout = setTimeout(() => {
            clearInterval(interval);
            clearTimeout(timeout);
            toggleIsCompleted(true);
          }, DELAY_BETWEEN_FINISH_AND_DISPLAYING_CONTENT_MS);
        }
      }, UPDATE_EACH_MS);

      return () => {
        clearInterval(interval);
      };
    }
  }, [start]);

  return { currentProgress, isCompleted };
};
