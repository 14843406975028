import { CONF_API_URLS } from '../../routes';
import { request } from '../request';
import { LimitationsResponse } from '../../common/types/limitations';

export const fetchLimitations = async () => {
  const accountId = localStorage.getItem('accountId');
  const url = CONF_API_URLS.LIMITATIONS.withParams(accountId);

  return request<LimitationsResponse>({
    url,
    headers: { 'Content-Type': 'application/json' },
    maxContentLength: 2000,
  });
};
