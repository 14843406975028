import React, { Component } from 'react';
import { ReportItemSettingDefinition } from '../../../types/reportItemSettingDefinition';
import styles from './EditReportItemSettingDefinitionsFormEntry.module.css';

interface Props {
  setting: ReportItemSettingDefinition;
  onChange: (setting: ReportItemSettingDefinition) => void;
  onDelete: () => void;
}

export default class EditReportItemSettingDefinitionsFormEntry extends Component<Props> {
  render() {
    return (
      <div className={styles.container}>
        {this.renderSettingTypeColumn()}
        {this.renderNameColumn()}
        {this.props.setting.settingType === 'singleChoice' && this.renderPossibleValuesColumnForSingleChoice()}
        {this.props.setting.settingType === 'simpleText' && this.renderPossibleValuesColumnForSimpleText()}
        {this.props.setting.settingType === 'singleChoice' && this.renderDefaultValueColumnForSingleChoice()}
        {this.props.setting.settingType === 'simpleText' && this.renderDefaultValueColumnForSimpleText()}
        {this.renderDescriptionColumn()}
        {this.renderIsMandatoryColumn()}
        {this.renderDeleteColumn()}
      </div>
    );
  }

  private renderSettingTypeColumn() {
    return (
      <div className={styles.smallColumn}>
        <div className={styles.fieldName}>Setting type:</div>
        <div className={styles.settingType} data-test="item-setting-type">
          {this.props.setting.settingType}
        </div>
      </div>
    );
  }

  private renderNameColumn() {
    return (
      <div className={styles.column}>
        <div className={styles.fieldName}>Name:</div>
        <input
          data-test="item-setting-name"
          className={styles.input}
          value={this.props.setting.name}
          onChange={(event) => {
            this.props.onChange({ ...this.props.setting, name: event.target.value });
          }}
        />
      </div>
    );
  }

  private renderPossibleValuesColumnForSingleChoice() {
    return (
      <div className={styles.column}>
        <div className={styles.fieldName}>Possible values:</div>
        {this.props.setting.possibleValues.map((possibleValue, index) => (
          <div key={index} className={styles.possibleValue}>
            <input
              className={styles.input}
              value={this.props.setting.possibleValues[index]}
              onChange={(event) => {
                this.props.onChange({
                  ...this.props.setting,
                  possibleValues: this.props.setting.possibleValues.map((oldPossibleValue, currentIndex) => {
                    if (currentIndex === index) {
                      return event.target.value;
                    }
                    return oldPossibleValue;
                  }),
                });
              }}
            />
            <button
              className={styles.removePossibleValueButton}
              onClick={() => {
                this.props.onChange({
                  ...this.props.setting,
                  possibleValues: this.props.setting.possibleValues.filter((p, currentIndex) => currentIndex !== index),
                });
              }}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          className={styles.addPossibleValueButton}
          onClick={() => {
            this.props.onChange({ ...this.props.setting, possibleValues: [...this.props.setting.possibleValues, ''] });
          }}
        >
          Add
        </button>
      </div>
    );
  }

  private renderPossibleValuesColumnForSimpleText() {
    return (
      <div className={styles.column}>
        <div className={styles.fieldName}>Possible values:</div>
        <div className={styles.possibleValuesForSimpleTextNote} data-test="item-setting-possible-values">
          everything
        </div>
      </div>
    );
  }

  private renderDefaultValueColumnForSimpleText() {
    return (
      <div className={styles.column}>
        <div className={styles.fieldName}>Default Value:</div>
        <input
          data-test="item-setting-default-value"
          className={styles.input}
          value={this.props.setting.defaultValue}
          onChange={(event) => {
            this.props.onChange({ ...this.props.setting, defaultValue: event.target.value });
          }}
        />
      </div>
    );
  }

  private renderDefaultValueColumnForSingleChoice() {
    return (
      <div className={styles.column}>
        <div className={styles.fieldName}>Default Value:</div>
        <select
          className={styles.select}
          value={this.props.setting.defaultValue}
          onChange={(event) => {
            this.props.onChange({ ...this.props.setting, defaultValue: event.target.value });
          }}
        >
          <option key="empty" />
          {this.props.setting.possibleValues.map((possibleValue, index) => (
            <option key={index}>{possibleValue}</option>
          ))}
        </select>
      </div>
    );
  }

  private renderDescriptionColumn() {
    return (
      <div className={styles.column}>
        <div className={styles.fieldName}>Description:</div>
        <input
          data-test="item-setting-description"
          className={styles.input}
          value={this.props.setting.description}
          onChange={(event) => {
            this.props.onChange({ ...this.props.setting, description: event.target.value });
          }}
        />
      </div>
    );
  }

  private renderIsMandatoryColumn() {
    return (
      <div className={styles.smallColumn}>
        <div className={styles.fieldName}>Is mandatory:</div>
        <input
          data-test="item-setting-is-mandatory"
          className={styles.input}
          type="checkbox"
          checked={this.props.setting.isMandatory}
          onChange={(event) => {
            this.props.onChange({ ...this.props.setting, isMandatory: !this.props.setting.isMandatory });
          }}
        />
      </div>
    );
  }

  private renderDeleteColumn() {
    return (
      <div className={styles.smallColumn}>
        <div className={styles.fieldName}>Actions:</div>
        <button
          className={styles.removeSettingButton}
          onClick={() => {
            this.props.onDelete();
          }}
        >
          Remove
        </button>
      </div>
    );
  }
}
