import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './recurringReportTooltip.module.scss';
import { CircleButton } from '../../../controls/buttons/circleButton/circleButton';
import { InformationIcon } from '../../../icons/reportItems';

interface TProps {
  year: string;
  month: string;
  day: string | number;
}

export const RecurringReportTooltip = ({ year, month, day }: TProps) => {
  const { t } = useTranslation();
  const [isOpen, toggleOpen] = useState<boolean>(false);

  const onBlurHandler = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      toggleOpen(false);
    }
  };

  return (
    <div className={styles.recurringReportTooltip} tabIndex={0} onBlur={onBlurHandler}>
      <CircleButton icon={<InformationIcon />} onClick={() => toggleOpen(!isOpen)} active={isOpen} />
      {isOpen && (
        <div className={styles.tooltipContent}>
          <span className={styles.title}>{t('reportItem.partialData')}</span>
          <span className={styles.subtitle}>{`${month}, ${year} includes data for ${month} 1-${day} only.`}</span>
        </div>
      )}
    </div>
  );
};
