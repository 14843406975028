import React from 'react';
import Dropdown from '../../../../../common/controls/dropdown';
import styles from './style.module.scss';
import { Option } from '../../../../../common/controls/dropdown/types';
import { FiltersValues } from '../interfaces';

interface Props {
  metrics: Option<null>[];
  categories: Option<null>[];
  subcategories: Option<null>[];
  countries: Option<null>[];
  values: FiltersValues;
  onChange: (type: keyof FiltersValues) => (data: string) => void;
}
export const Filters: React.FC<Props> = ({ metrics, categories, countries, subcategories, values, onChange }) => {
  const onChangeFilter = (type: keyof FiltersValues) => (item: Option<null>) => {
    onChange(type)(item.id);
  };

  return (
    <div className={styles.filters}>
      {/* SShould be uncommented when we have multiple countries */}
      <div className={styles.item}>
        <Dropdown<null>
          onChange={onChangeFilter('country')}
          options={countries}
          selectedId={values.country}
          placeholder="Country"
        />
      </div>
      <div className={styles.item}>
        <Dropdown<null>
          onChange={onChangeFilter('metric')}
          options={metrics}
          selectedId={values.metric}
          placeholder="Metric"
        />
      </div>
      {categories.length > 0 && (
        <div className={styles.item}>
          <Dropdown<null>
            onChange={onChangeFilter('category')}
            options={categories}
            selectedId={values.category}
            placeholder="Category"
          />
        </div>
      )}
      {subcategories.length > 0 && (
        <div className={styles.item}>
          <Dropdown<null>
            onChange={onChangeFilter('subcategory')}
            options={subcategories}
            selectedId={values.subcategory}
            placeholder="Subcategory"
          />
        </div>
      )}
    </div>
  );
};
