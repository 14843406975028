import React, { useEffect, useRef } from 'react';
import styles from './style.module.scss';

interface Props {
  onDelete?: VoidFunction;
  onCopy?: VoidFunction;
  onEdit?: VoidFunction;
  onRemoveFromReport?: VoidFunction;
  updateCoordinates?: (data: number[]) => VoidFunction;
}
export const Menu: React.FC<Props> = ({ onDelete, onCopy, onEdit, onRemoveFromReport, updateCoordinates }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current && updateCoordinates) {
      const { top, left } = ref.current.getBoundingClientRect();
      updateCoordinates([top, left]);
    }
  }, [ref]);

  return (
    <div className={styles.menu}>
      {onCopy && (
        <div className={styles.item} onClick={onCopy}>
          Copy
        </div>
      )}
      {onEdit && (
        <div className={styles.item} onClick={onEdit}>
          Edit
        </div>
      )}
      {onRemoveFromReport && (
        <div className={styles.item} onClick={onRemoveFromReport}>
          Remove from report
        </div>
      )}
      {onDelete && (
        <div className={styles.item} onClick={onDelete} ref={ref}>
          Delete
        </div>
      )}
    </div>
  );
};
