import { Attraction, LocationInfo, ReportItemInfo } from '../../common/types/projectsHierarchy';
import { AttractionDataResponse, AttractionDateItem } from './types';
import { ReportItemType } from '../../common/reportItems/types';
import alphabeticalSort from '../absorption/lib/alphabeticalSort';
import { generateMapPoiPolygons } from '../utils/mappers/generateMapPoiPolygons';
import { generateMapPoints } from '../utils/mappers/generateMapPoints';
import { getCorrectPointsIfCoordinatesAreWrong } from '../../common/maps/utils';

const calculateAvgWeightedAttraction = ({ avg_weighted_attraction, visits_in_source }: AttractionDateItem) => {
  if (visits_in_source === 0 || avg_weighted_attraction === 0) {
    return 0;
  }
  return (avg_weighted_attraction * visits_in_source) / visits_in_source;
};

export const mapResponse = (
  item: AttractionDataResponse,
  projectLocations: LocationInfo[],
): ReportItemInfo<Attraction> => {
  const locationPoints: {
    location_id: string;
    location_name: string;
    group_id: string;
    average: string;
  }[] = [];

  const aoiPoints: {
    location_id: string;
    location_name: string;
    group_id: string;
  }[] = [];

  for (let i = 0; i < item.values.length; i += 1) {
    const aoiPoint = {
      location_id: item.values[i].source_location_id,
      location_name: item.values[i].source_location_name,
      group_id: item.values[i].source_location_id,
    };
    aoiPoints.push(aoiPoint);
    for (let j = 0; j < item.values[i].data.length; j += 1) {
      let average: string | number = item.values[i].data[j].avg_weighted_attraction;
      if (typeof average === 'number') {
        average = `${(average * 100).toFixed(2)} %`;
      } else {
        average = '0.00%';
      }
      const point = {
        location_id: item.values[i].data[j].target_location_id,
        location_name: item.values[i].data[j].target_location_name,
        group_id: item.values[i].source_location_id,
        average,
      };
      locationPoints.push(point);
    }
  }

  const points = generateMapPoints(locationPoints, projectLocations);
  const poiPolygons = generateMapPoiPolygons(locationPoints, projectLocations);

  return {
    id: item.id,
    name: item.name,
    type: ReportItemType.ATTRACTION,
    data: {
      data: item.values
        .map((source) => {
          let value =
            source.data
              .map(({ avg_weighted_attraction }) => avg_weighted_attraction)
              .reduce((prevValue, nextValue) => prevValue + nextValue, 0) / source.data.length;
          if (typeof value !== 'number') {
            value = 0;
          }
          return {
            location_id: source.source_location_id,
            location_name: source.source_location_name,
            average: value,
            data: source.data
              .map((target) => ({
                targetLocationId: target.target_location_id,
                targetLocationName: target.target_location_name,
                avgWeightedAttraction:
                  typeof target.avg_weighted_attraction === 'number' ? target.avg_weighted_attraction : 0,
                dateChanges: target.dateChanges.map((dateChange) => ({
                  timestamp: dateChange.timestamp,
                  attraction: calculateAvgWeightedAttraction(dateChange),
                })),
              }))
              .sort((targetA, targetB) => alphabeticalSort(targetA.targetLocationName, targetB.targetLocationName)),
          };
        })
        .sort((sourceA, sourceB) => alphabeticalSort(sourceA.location_name, sourceB.location_name)),
    },
    visualization: {
      points: getCorrectPointsIfCoordinatesAreWrong(points, poiPolygons),
      poiPolygons,
      aoiPolygons: generateMapPoiPolygons(aoiPoints, projectLocations),
    },
  };
};
