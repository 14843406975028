import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import ReportItemDetail from '../../../common/reportItems/reportItemDetail';
import { MovementSpiderChart } from './chart/spiderChart';
import { MainContext } from '../../../common/types/mainContext';
import { ReportItemType, ReportItemVisualizationMode } from '../../../common/reportItems/types';
import { Movement, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import styles from './styles.module.scss';
import { useElementScreenshot } from '../../../common/hooks/useElementScreenshot';
import { exportSettingsModel } from '../../../common/export';
import { IReportCategory } from '../../../../types/report';
import { MovementSingleColumnChart } from './chart/singleColumnChart';

export const MovementDetail = () => {
  const { t } = useTranslation();
  const {
    primaryLocationId,
    selectedLocations,
    reportItemsDetails,
    contentVisualizationMode,
    selection: { selectedReport },
  } = useContext(MainContext);
  const [mode, setMode] = useState<ReportItemVisualizationMode>(ReportItemVisualizationMode.CHART);
  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const { onMakeScreenshot, base64Image, elementRef, onClearScreenshotData } = useElementScreenshot({
    backgroundColor: `rgba(37, 55, 76, 1)`,
  });
  const isChartReady = useRef(false);
  const isExportMode = useStore(exportSettingsModel.$isExportMode);
  const primaryLocation = selectedLocations.find(({ id }) => id === primaryLocationId) || null;

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.MOVEMENT,
  ) as ReportItemInfo<Movement>;

  const dataAccordingToPrimaryLocation = useMemo(() => {
    if (primaryLocationId) {
      if (reportItem) {
        const analyticsData = reportItem.data?.analyticsData?.[primaryLocationId];
        return analyticsData || null;
      }
    }
    return null;
  }, [reportItem.data, primaryLocation]);

  useEffect(() => {
    if (exportOptions.MOVEMENT?.chart) {
      if (reportItem.data && !isChartReady.current) {
        isChartReady.current = true;
        onMakeScreenshot();
      }
    }
  }, [dataAccordingToPrimaryLocation, reportItem.data, exportOptions.MOVEMENT]);

  useEffect(() => {
    if (base64Image && reportItem?.data) {
      if (reportItem.data.showAnalytics && dataAccordingToPrimaryLocation) {
        exportSettingsModel.updateExportData({ [ReportItemType.MOVEMENT]: { chart: base64Image } });
        onClearScreenshotData();
      } else {
        exportSettingsModel.updateExportData({ [ReportItemType.MOVEMENT]: { chart: '', table: '' } });
      }
    }
  }, [base64Image]);

  useEffect(() => {
    // In movement table is always empty string due to lack of table representation
    if (exportOptions.MOVEMENT?.table) {
      exportSettingsModel.updateExportData({ [ReportItemType.MOVEMENT]: { table: '' } });
    }
  }, [exportOptions.MOVEMENT]);

  const render = (data: Movement | null) => {
    if (!data) return <span className={styles.noAvailable}>{t('movement.detail.notAvailable')}</span>;

    if (
      (dataAccordingToPrimaryLocation || isExportMode) &&
      data?.showAnalytics &&
      selectedReport?.category !== IReportCategory.AOI_DEFAULT
    ) {
      return (
        <MovementSpiderChart
          data={dataAccordingToPrimaryLocation}
          primaryLocation={primaryLocation}
          dataLevel={reportItemsDetails.dataLevel}
          locationType={reportItemsDetails.locationType}
        />
      );
    }
    if (
      (dataAccordingToPrimaryLocation || isExportMode) &&
      data?.showAnalytics &&
      selectedReport?.category === IReportCategory.AOI_DEFAULT
    ) {
      return (
        <MovementSingleColumnChart
          data={dataAccordingToPrimaryLocation}
          dataLevel={reportItemsDetails.dataLevel}
          locationType={reportItemsDetails.locationType}
        />
      );
    }
    return <span className={styles.noAvailable}>{t('movement.detail.notAvailable')}</span>;
  };

  const minimizedData = useMemo(() => render(reportItem.data), [reportItem.data, reportItemsDetails]);
  const subtitle = useMemo(
    () =>
      selectedReport?.category === IReportCategory.AOI_DEFAULT
        ? `${t('movement.detail.showingTopAOILocations')} ${primaryLocation?.name}`
        : `${t('movement.detail.showingTopLocations')} ${primaryLocation?.name}`,
    [selectedReport?.category],
  );

  const title = t('movement.detail.title');
  return (
    <div ref={elementRef} style={{ width: '100%', height: '100%' }}>
      <ReportItemDetail
        type={ReportItemType.MOVEMENT}
        title={title}
        subtitle={subtitle}
        mode={mode}
        setMode={setMode}
        onMakeScreenshot={onMakeScreenshot}
      >
        {minimizedData}
      </ReportItemDetail>
    </div>
  );
};
