import React from 'react';
import { NavLink } from 'react-router-dom';
import { BaseModal } from '../../baseModal';
import { ExclamationIcon } from '../../../common/icons/auth';
import styles from './style.module.scss';
import { Routes } from '../../../routes';

interface Props {
  email: string;
}
export const SuccessEmailSent: React.FC<Props> = ({ email }) => (
  <BaseModal subtitle={`We sent a link to reset your password to <b>${email}</b>`} title="Thank you">
    <div className={styles.information}>
      <ExclamationIcon />
      <span>The link is valid for 10 minutes.</span>
    </div>
    <div className={styles.additionalInformation}>
      If you don't see an email from us, try checking your spam folder or{' '}
      <a href="https://placesense.ai/contact">contact support</a> to set a back up.
    </div>
    <NavLink to={Routes.dashboard} className={styles.goBack}>
      Login with different account
    </NavLink>
  </BaseModal>
);
