import moment, { DurationInputArg2, Moment } from 'moment';

export const getMinMaxMonth = (timeFrames: string[] | null): Moment[] => {
  if (timeFrames) {
    return [moment.utc(`${timeFrames[0]}-01`), moment.utc(`${timeFrames[timeFrames.length - 1]}-01`)];
  }
  return [];
};

export const getMinMaxWeek = (timeFrames: string[] | null): Moment[] => {
  if (timeFrames) {
    return [
      moment.utc(`${timeFrames[0]}-01`).startOf('isoWeek'),
      moment
        .utc(`${timeFrames[timeFrames.length - 1]}-01`)
        .endOf('month')
        .startOf('isoWeek'),
    ];
  }
  return [];
};

export const getAllQuartersBetweenPeriod = (minDate: Moment, maxDate: Moment): number[] => {
  const quarters: number[] = [];
  while (minDate <= maxDate) {
    quarters.push(minDate.valueOf());
    minDate.add(3, 'month');
  }
  return quarters;
};

export const getAllMonthsBetweenPeriod = (minDate: Moment, maxDate: Moment): number[] =>
  getAllTimeFramesBetweenPeriod(minDate, maxDate, 'M', 1, 'month');

export const getAllWeeksBetweenPeriod = (minDate: Moment, maxDate: Moment): number[] =>
  getAllTimeFramesBetweenPeriod(minDate, maxDate, 'D', 1, 'weeks');

export const getAllTimeFramesBetweenPeriod = (
  minDate: Moment,
  maxDate: Moment,
  format: string,
  amount: number,
  unit: DurationInputArg2,
): number[] => {
  const timeValues: number[] = [];

  while (maxDate > minDate || minDate.format(format) === maxDate.format(format)) {
    timeValues.push(minDate.valueOf());
    minDate.add(amount, unit);
  }
  return timeValues;
};

export const getDefaultReportDates = () => {
  const today = new Date();
  const reportEndDay = new Date(today);
  reportEndDay.setDate(0); // last day of prev month
  if (today.getDate() < 5) {
    reportEndDay.setMonth(reportEndDay.getMonth() - 1);
  }
  const reportStartDay = new Date(reportEndDay);
  reportStartDay.setUTCFullYear(reportStartDay.getFullYear() - 1, reportStartDay.getMonth() + 1, 1);
  return [reportStartDay, reportEndDay];
};

export const getDateString = (date: Date) =>
  `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
