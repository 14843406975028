import React from 'react';
import PolygonImage from '../../../common/maps/polygon/polygonImage';
import { Area } from '../../../common/types/visualizationObjects';

interface Props {
  polygons?: Area[];
}

export const AoiPolygons = ({ polygons }: Props) => {
  if (!polygons) {
    return <></>;
  }

  return (
    <PolygonImage
      id="AOI_POLYGONS"
      image="polygonBackground"
      data={{
        type: 'FeatureCollection',
        features: polygons.map((i) => ({ ...i.geo, properties: { color: '#CEEAF3' } })),
      }}
    />
  );
};
