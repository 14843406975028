import React, { forwardRef } from 'react';
import { EChartsOption } from 'echarts';
import ReactECharts from 'echarts-for-react';
import { DEFAULT_PALETTE } from './configuration';
import { ChartEvents } from './interfaces';
import EChartsReact from 'echarts-for-react';

type ChartProps = {
  option: EChartsOption;
  events?: Partial<ChartEvents>;
};

export const Chart = forwardRef<EChartsReact, ChartProps>(({ option, events }, ref) => (
  <ReactECharts
    ref={ref}
    style={{ height: '100%', width: '100%' }}
    onEvents={events as Record<string, Function> | undefined}
    notMerge
    option={{
      grid: {
        top: 0,
        left: 0,
        right: 0,
      },
      color: DEFAULT_PALETTE,
      legend: {
        align: 'auto',
        bottom: 0,
        icon: 'rect',
        type: 'scroll',
      },
      tooltip: {
        trigger: 'item',
      },
      ...option,
    }}
  />
));
