import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import Page from '../../templates/page/page';
import styles from './editUserPage.module.css';
import ErrorMessage from '../../organisms/errorMessage';
import EditUserForm from '../../organisms/EditUserForm/EditUserForm';

const EditUserPage = (): ReactElement => {
  const {
    location: {
      state: { userId },
    },
    push,
  } = useHistory();
  return (
    <Page title="Edit user">
      <div className={styles.formContainer}>
        <ErrorMessage />
        <EditUserForm userId={userId} />
      </div>
    </Page>
  );
};

export default EditUserPage;
