import { API_URLS } from '../../routes';
import { request } from '../request';

export const createProject = (name: string, description: string) => {
  const accountId = localStorage.getItem('accountId');
  const url = `${API_URLS.PROJECT}`;

  return request<string>({
    url,
    method: 'POST',
    data: {
      name,
      description,
      country: 'de', // TODO remove
      account_id: accountId,
    },
  });
};
