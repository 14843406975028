import { useEffect, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { dashboardModel } from '../../../dasboardLoader';

export interface TooltipPositionCoordinates {
  top: null | number;
  left: null | number;
}

export const useTooltipStartPosition = (scrollingElementId = 'side-panel-scroll') => {
  const [position, setPosition] = useState<TooltipPositionCoordinates>({ top: null, left: null });
  const tooltipIconRef = useRef<HTMLDivElement>(null);
  const isGlobalLoading = useStore(dashboardModel.$isLoading);

  const getTooltipPosition = () => {
    if (tooltipIconRef.current) {
      const box = tooltipIconRef.current.getBoundingClientRect();
      setPosition({ top: box.top, left: box.left });
    }
  };

  useEffect(() => {
    if (!isGlobalLoading) {
      getTooltipPosition();
    }
  }, [isGlobalLoading]);

  useEffect(() => {
    const element = document.getElementById(scrollingElementId);
    if (element) {
      element.addEventListener('scroll', getTooltipPosition);
    }
  }, []);

  return { position, tooltipIconRef };
};
