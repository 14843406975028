import React from 'react';

export const CatchmentIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.66671 1.66634C9.66671 0.926341 10.26 0.333008 11 0.333008C11.74 0.333008 12.3334 0.926341 12.3334 1.66634C12.3334 2.40634 11.74 2.99967 11 2.99967C10.26 2.99967 9.66671 2.40634 9.66671 1.66634ZM12.3334 13.6663V9.66634H14L12.3067 4.57967C12.12 4.03301 11.6134 3.66634 11.04 3.66634H10.96C10.3867 3.66634 9.87337 4.03301 9.69337 4.57967L9.12004 6.29967C9.84004 6.69967 10.3334 7.45301 10.3334 8.33301V13.6663H12.3334ZM7.33337 6.66634C7.88671 6.66634 8.33337 6.21967 8.33337 5.66634C8.33337 5.11301 7.88671 4.66634 7.33337 4.66634C6.78004 4.66634 6.33337 5.11301 6.33337 5.66634C6.33337 6.21967 6.78004 6.66634 7.33337 6.66634ZM2.66671 2.99967C3.40671 2.99967 4.00004 2.40634 4.00004 1.66634C4.00004 0.926341 3.40671 0.333008 2.66671 0.333008C1.92671 0.333008 1.33337 0.926341 1.33337 1.66634C1.33337 2.40634 1.92671 2.99967 2.66671 2.99967ZM4.00004 13.6663V8.99967H5.00004V4.99967C5.00004 4.26634 4.40004 3.66634 3.66671 3.66634H1.66671C0.933374 3.66634 0.333374 4.26634 0.333374 4.99967V8.99967H1.33337V13.6663H4.00004ZM8.33337 13.6663V10.9997H9.00004V8.33301C9.00004 7.78634 8.54671 7.33301 8.00004 7.33301H6.66671C6.12004 7.33301 5.66671 7.78634 5.66671 8.33301V10.9997H6.33337V13.6663H8.33337Z"
      fill="white"
    />
  </svg>
);
