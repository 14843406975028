import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './successBox.module.css';
import { AppState } from '../../../reducers/reducers';

interface Props {}

interface StateProps {
  message: string;
  visible: boolean;
}

interface DispatchProps {}

class SuccessBox extends Component<Props & StateProps & DispatchProps> {
  render() {
    if (this.props.visible) {
      return <div className={styles.box}>{this.props.message}</div>;
    }
    return null;
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  message: state.successBox.message,
  visible: state.successBox.visible,
});

const mapDispatchToProps: DispatchProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessBox);
