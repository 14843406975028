import { ReportItemCardConfiguration, ReportItemType } from './types';

import {
  DurationIcon,
  TrendIcon,
  SeasonalityIcon,
  CrossVisitationIcon,
  CatchmentIcon,
  RetentionIcon,
  MovementIcon,
  AbsorptionIcon,
  AttractionIcon,
} from '../icons/reportItems';

export const reportItemCardConfiguration = new Map<ReportItemType, ReportItemCardConfiguration>();

reportItemCardConfiguration.set(ReportItemType.CATCHMENT, {
  title: 'Catchment',
  icon: CatchmentIcon,
});

reportItemCardConfiguration.set(ReportItemType.DURATION, {
  title: 'Visit duration',
  icon: DurationIcon,
});

reportItemCardConfiguration.set(ReportItemType.FOOTFALL, {
  title: 'Visit trends',
  icon: TrendIcon,
});

reportItemCardConfiguration.set(ReportItemType.SEASONALITY, {
  title: 'Popular day & hour',
  icon: SeasonalityIcon,
});

reportItemCardConfiguration.set(ReportItemType.CROSS_VISITATION, {
  title: 'Cross-visitation',
  icon: CrossVisitationIcon,
});

reportItemCardConfiguration.set(ReportItemType.RETENTION, {
  title: 'Retention rate',
  icon: RetentionIcon,
});

reportItemCardConfiguration.set(ReportItemType.MOVEMENT, {
  title: 'Movement',
  icon: MovementIcon,
});

reportItemCardConfiguration.set(ReportItemType.ABSORPTION, {
  title: 'Absorption',
  icon: AbsorptionIcon,
});

reportItemCardConfiguration.set(ReportItemType.ATTRACTION, {
  title: 'Attraction',
  icon: AttractionIcon,
});
