import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup.string().required('The field is required'),
  company: yup.string().required('The field is required'),
  // role: yup.string().required('The field is required'),
  acceptedUsingInformation: yup.boolean().oneOf([true], 'The field is required'),
  agreedWithPrivacy: yup.boolean().oneOf([true], 'The field is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .max(12, 'Password should be of maximum 12 characters length')
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      'Password should consist of at least one number, symbol and upper letter',
    )
    .required('Password is required'),
  confirmedPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  recaptcha: yup.boolean().oneOf([true], 'The recaptcha is required'),
});
