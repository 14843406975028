/* eslint-disable */
const IS_PRODUCTION =
  process.env.REACT_APP_API_URL === 'https://portal-prod-1-dot-gifted-slice-218906.ey.r.appspot.com';
const IS_QA = process.env.REACT_APP_API_URL === 'https://portal-qa-dot-gifted-slice-218906.ey.r.appspot.com';

const HOTJAR_ID_PRODUCTION = 2981648;
const HOTJAR_ID_QA = 2981646;

if (IS_PRODUCTION || IS_QA) {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: IS_PRODUCTION ? HOTJAR_ID_PRODUCTION : HOTJAR_ID_QA, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}
