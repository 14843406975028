import { API_URLS } from '../../routes';
import { request } from '../request';

export const removeReport = async (reportId: string) => {
  const url = `${API_URLS.REPORT}/${reportId}`;
  return request({
    url,
    method: 'DELETE',
  });
};
