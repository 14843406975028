import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styles from './poiList.module.css';
import { copyToClipboard } from '../../../htmlUtils';
import { Location } from '../../../types/location';
import Button from '../../atoms/primaryButton/button';

interface Props {
  locations: Location[];
  deletePois: (pois: string[]) => any;
}

const PoiList = (props: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedPois, setSelectedPois] = useState<string[]>([]);
  const [sortByName, setSortByName] = useState<boolean>(false);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  useEffect(() => {
    const allLocationIds = props.locations.map(({ id }) => id);
    setSelectedPois((prevState) => {
      const newState = prevState.filter((locationId) => allLocationIds.includes(locationId));
      setAllChecked(allLocationIds.length > 0 && allLocationIds.length === selectedPois.length);
      return newState;
    });
  }, [props.locations]);

  const locationRows = sortByName
    ? props.locations.slice().sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    : props.locations.slice().reverse();

  const onSelectionChange = (locationId: string) => {
    if (selectedPois.includes(locationId)) {
      setSelectedPois((prevState) => {
        const newState = prevState.filter((i) => i !== locationId);
        setAllChecked(props.locations.length > 0 && newState.length === props.locations.length);
        return newState;
      });
    } else {
      setSelectedPois((prevState) => {
        const newState = [...prevState, locationId];
        setAllChecked(props.locations.length > 0 && newState.length === props.locations.length);
        return newState;
      });
    }
  };

  const onSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedPois((prevState) => [
        ...prevState,
        ...props.locations
          .filter((location) => !selectedPois.includes(location.id || ''))
          .map((location) => location.id || ''),
      ]);
    } else {
      setSelectedPois([]);
    }
    setAllChecked(checked);
  };

  const renderModal = (
    <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} ariaHideApp={false} className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.deleteMessage}>
          <h3 className={styles.emphasized}>You're about to delete:</h3>
          <ul className={styles.deleteMessageContent}>
            {props.locations
              .filter((location) => selectedPois.includes(location.id || ''))
              .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
              .map((location) => (
                <li key={location.id} className={styles.locationToBeDeleted}>
                  {location.name}
                </li>
              ))}
          </ul>
        </div>
        <div className={styles.modalButtons}>
          <Button
            onClick={() => {
              props.deletePois(selectedPois);
              setModalOpen(false);
            }}
          >
            Delete
          </Button>
          <Button secondary onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      {renderModal}
      <div className={styles.groupActions}>
        <Button onClick={() => setSortByName(!sortByName)}>Sort by {sortByName ? 'date' : 'name'}</Button>
        <Button
          onClick={() => {
            if (selectedPois.length) {
              setModalOpen(true);
            }
          }}
          disabled={selectedPois.length <= 0}
        >
          Delete selected
        </Button>
      </div>
      <table className={styles.locationTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>name</th>
            <th>description</th>
            <th>address</th>
            <th>street</th>
            <th>house number</th>
            <th>city</th>
            <th>country</th>
            <th>postalCode</th>
            <th>purpose</th>
            <th>setup</th>
            <th>polygon</th>
            <th>
              <label htmlFor="selectAllPoi">select all</label>
              <input
                type="checkbox"
                id="selectAllPois"
                name="selectAllPoi"
                onChange={onSelectAllChange}
                checked={allChecked}
                disabled={props.locations.length <= 0}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {locationRows.map((location) => (
            <tr key={location.id} className={styles.locationRow}>
              <td>{location.id}</td>
              <td>{location.name}</td>
              <td>{location.description}</td>
              <td>{location.address}</td>
              <td>{location.street}</td>
              <td>{location.houseNumber}</td>
              <td>{location.city}</td>
              <td>{location.country}</td>
              <td>{location.postalCode}</td>
              <td>{location.purpose}</td>
              <td>{location.setup}</td>
              <td>
                <button
                  className={styles.actionButton}
                  onClick={() => copyToClipboard(location.polygon.wktRepresentation || 'no polygon')}
                  type="button"
                >
                  copy
                </button>
              </td>
              <td>
                <input
                  className={styles.checkBox}
                  onChange={() => onSelectionChange(location.id || '')}
                  checked={selectedPois.includes(location.id || '')}
                  type="checkbox"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PoiList;
