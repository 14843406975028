import { LocationInfo } from '../../types/projectsHierarchy';
import { toDataURL } from '../../utils';

export const convertLocationsImagesURLToDataBase64 = (
  locations: LocationInfo[],
  headers?: any,
): Promise<LocationInfo[]> => {
  const newLocations = locations.map(async (location) => ({
    ...location,
    image: (location.image ? await toDataURL(location.image, headers) : '') as string,
  }));
  return Promise.all(newLocations);
};
