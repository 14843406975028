import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from './column';
import styles from './style.module.scss';
import { ItemInformation } from './ItemInformation';
import { ColumnWithIndex } from './column/ColumWithIndex';

interface Props {
  during: ItemInformation[];
}
export const SingleColumnChart = ({ during }: Props) => {
  const { t } = useTranslation();
  const entriesPerColumn = 10;
  const total = during.length;
  const columnData0 = total <= entriesPerColumn ? during : during.slice(0, entriesPerColumn);
  const columnData1 = total > entriesPerColumn ? during.slice(entriesPerColumn, entriesPerColumn * 2) : undefined;
  const columnData2 =
    total > entriesPerColumn * 2 ? during.slice(entriesPerColumn * 2, entriesPerColumn * 3) : undefined;
  const maxItemsPerColumn = columnData0.length;
  return (
    <div className={styles.singleColumnchart}>
      {/* <div className={styles.tripleColumnChart}> */}
      <ColumnWithIndex
        items={columnData0}
        connectionSide="during"
        title={t('movement.detail.topVisitedPois')}
        columnIndex={0}
        maxItemsPerColumn={maxItemsPerColumn}
      />
      {columnData1 && columnData1.length > 0 && (
        <ColumnWithIndex
          items={columnData1}
          connectionSide="during"
          title={t('')}
          columnIndex={1}
          maxItemsPerColumn={maxItemsPerColumn}
        />
      )}

      {columnData2 && columnData2.length > 0 && (
        <ColumnWithIndex
          items={columnData2}
          connectionSide="during"
          title={t('')}
          columnIndex={2}
          maxItemsPerColumn={maxItemsPerColumn}
        />
      )}
    </div>
  );
};
