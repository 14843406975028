import { splitCoordinatesArrayToPairs } from './parseCoordinatesToPolygon';

const MULTIPOLYGON = 'MULTIPOLYGON';
export const generatePolygonString = (data: string[]) => {
  const pairs = data.map((polygon) => {
    const coordinates = splitCoordinatesArrayToPairs(polygon.split(','));
    const concatCoordinates = coordinates.map((coordinate) => coordinate.join(' ')).join(',');
    return `((${concatCoordinates}))`;
  });

  const polygonString = pairs.join(',');
  const multiPolygon = `${MULTIPOLYGON}(${polygonString})`;

  return multiPolygon;
};
