import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { BackgroundMap } from '../../mainPage/backgroundMap/backgroundMap';
import { ContentVisualizationMode, ProjectInfo, ReportInfo } from '../../common/types/projectsHierarchy';
import ReportItem from '../../reportItems/ReportItem';
import { MainContext } from '../../common/types/mainContext';
import { getInitialReportItem, getProjectLocations } from '../../projects/panelContent/reports/utils';
import { TableauReport } from '../../projects/panelContent/reportItems/unique';
import { getReport } from '../../api/report';
import { SidebarContext } from '../../common/sidebarMenu/sidebarContext';
import { addImageUrlToLocations } from '../../common/locations/utils';
import { sortByName } from '../../common/utils';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';
import { DashboardLoader } from '../../dasboardLoader';
import { useURLParams } from '../../common/hooks';
import { IReportCategory } from '../../../types/report';
import { MovementPeriodEnum } from '../../common/reportItems/types';

export const DashboardPage = () => {
  const {
    selection,
    contentVisualizationMode,
    projectsHierarchy,
    updateSelection,
    setSelectedLocations,
    setPrimaryLocationId,
    toggleIsSelectionDataLoaded,
    setSelectedTimeFrames,
    updateReportItemsDetailsValue,
  } = useContext(MainContext);
  const { onChangeMenuTabs } = useContext(SidebarContext);
  const { id } = useParams();
  const { pathname } = useLocation();
  const { params, isParsingDone, onChangeParams } = useURLParams();

  useEffect(
    () => () => {
      updateSelection({ selectedReportItem: null, selectedReport: null, selectedProject: null });
      onChangeMenuTabs({ projects: false, reports: false });
    },
    [],
  );

  const setPrimaryLocation = (id?: string) => {
    if (params.primaryLocation && params.primaryLocation !== '') {
      setPrimaryLocationId(params.primaryLocation);
      return;
    }
    if (id && id !== '') {
      setPrimaryLocationId(id);
      onChangeParams('primaryLocation', id);
    }
  };

  const setReportItemType = (report: ReportInfo) => {
    const reportType = getInitialReportItem(report, params.reportItemType);

    if (!params.reportItemType) {
      if (reportType?.type) {
        onChangeParams('reportItemType', reportType.type);
      }
    }
    return reportType;
  };

  const getReportLocations = (project: ProjectInfo, report: ReportInfo) => {
    const locationsIds = getProjectLocations(project, report);

    if (params.selectedLocations) {
      const ids = params.selectedLocations.split('+');
      return { selectedLocations: locationsIds.selectedLocations.filter(({ id }) => ids.includes(id)) };
    }

    return locationsIds;
  };

  const setTimeFrames = () => {
    if (params.timeFrames) {
      const parsedTimeFrames = params.timeFrames.split('%2C');
      setSelectedTimeFrames(parsedTimeFrames);
    }
  };

  useEffect(() => {
    if (isParsingDone) {
      const isReport = pathname.includes('reports');
      (async function () {
        toggleIsSelectionDataLoaded(false);
        if (id) {
          if (isReport) {
            const project = projectsHierarchy.find((project) => {
              const { reports } = project;
              const report = reports.find((report) => report.id === id);
              if (report) {
                return project;
              }

              return null;
            });

            const reportData = project?.reports.find((report) => report.id === id);

            if (reportData && project) {
              const report = await getReport(selection.selectedReport ? selection.selectedReport : reportData);

              const { id, selectedLocations } = getReportLocations(project, report);
              setPrimaryLocation(id);
              trackUserAction(
                'Primary location has been changed',
                UserActionsEnum.PRIMARY_LOCATION_CHANGE,
                `Default primary location set. Location ID: ${id}`,
              );
              const locationsWithImages = addImageUrlToLocations(selectedLocations);
              setSelectedLocations(locationsWithImages);
              setTimeFrames();
              if (reportData.category === IReportCategory.AOI_DEFAULT) {
                updateReportItemsDetailsValue('movementPeriod', MovementPeriodEnum.DURING);
                onChangeParams('movementPeriod', MovementPeriodEnum.DURING);
              }

              onChangeMenuTabs({ projects: true, reports: true });
              updateSelection({
                ...selection,
                selectedProject: project,
                selectedReport: report,
                selectedReportItem: setReportItemType(report),
              });
            }
          } else {
            const project = projectsHierarchy?.find((project) => project.id === id);
            if (project) {
              updateSelection({
                ...selection,
                selectedProject: { ...project, locations: sortByName(project.locations) },
              });
              onChangeMenuTabs({ projects: true, reports: false });
            }
          }
        }
      })();
      toggleIsSelectionDataLoaded(true);
    }
  }, [projectsHierarchy, isParsingDone]);

  const report = selection.selectedReport;

  const isDefaultVisualizedReportItems =
    report?.id &&
    report?.visualization_url === '' &&
    (report.report_category === 'POI_DEFAULT' || report.report_category === 'AOI_DEFAULT');

  return (
    <>
      <DashboardLoader />
      {report && isDefaultVisualizedReportItems && <BackgroundMap />}
      {report && contentVisualizationMode !== ContentVisualizationMode.MAP && isDefaultVisualizedReportItems && (
        <ReportItem />
      )}

      {report && !isDefaultVisualizedReportItems && <TableauReport />}
    </>
  );
};
