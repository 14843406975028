import React from 'react';

export const IncomeIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.88867 27.0539L9.65058 24.2525V30.5556H4.88867V27.0539ZM13.7776 22.1515L18.222 24.6027V30.5556H13.7776L13.7776 22.1515ZM22.6664 22.1515L27.1109 19V30.5556H22.6665L22.6664 22.1515Z"
      fill="#A67BDD"
    />
    <path
      d="M23 13L17.7777 17.2168L13.3332 14L4.5 20L4.44434 23.2222L13.3332 17L17.7777 20.5555L27 13H23Z"
      fill="#A67BDD"
    />
    <path
      d="M16 0C12.688 0 10 2.688 10 6C10 9.312 12.688 12 16 12C19.312 12 22 9.312 22 6C22 2.688 19.312 0 16 0ZM16.846 9.654V10.8H15.244V9.642C14.218 9.426 13.348 8.766 13.282 7.602H14.458C14.518 8.232 14.95 8.724 16.048 8.724C17.224 8.724 17.488 8.136 17.488 7.77C17.488 7.272 17.224 6.804 15.886 6.486C14.398 6.126 13.378 5.514 13.378 4.284C13.378 3.252 14.212 2.58 15.244 2.358V1.2H16.846V2.37C17.962 2.64 18.52 3.486 18.556 4.404H17.38C17.35 3.738 16.996 3.282 16.048 3.282C15.148 3.282 14.608 3.69 14.608 4.266C14.608 4.77 14.998 5.1 16.21 5.412C17.422 5.724 18.718 6.246 18.718 7.758C18.712 8.856 17.89 9.456 16.846 9.654Z"
      fill="#DFD2EF"
    />
  </svg>
);
