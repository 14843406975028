import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import authReducer, { AuthState } from './authReducer';
import reportItemSettingDefinitionsReducer, {
  ReportItemSettingDefinitionsState,
} from './reportItemSettingDefinitionsReducer';
import locationsReducer, { LocationsState } from './locationsReducer';
import successBoxReducer, { SuccessBoxState } from './successBoxReducer';
import reportsReducer, { ReportsState } from './reportsReducer';
import poiManagementReducer, { PoiManagementState } from './poiManagementReducers';
import reportItemTypesReducer, { ReportItemTypesState } from './reportItemTypesReducer';
import countriesReducer, { CountriesState } from './countriesReducer';
import projectsReducer, { ProjectsState } from './projectsReducer';
import loginReducer, { LoginState } from './login';
import errorMessageReducer, { ErrorMessageState } from './errorMessageReducer';

export interface AppState {
  auth: AuthState;
  reportItemSettingDefinitions: ReportItemSettingDefinitionsState;
  locations: LocationsState;
  reports: ReportsState;
  successBox: SuccessBoxState;
  poiManagement: PoiManagementState;
  reportItemTypes: ReportItemTypesState;
  countries: CountriesState;
  projects: ProjectsState;
  login: LoginState;
  errorMessage: ErrorMessageState;
}

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    reportItemSettingDefinitions: reportItemSettingDefinitionsReducer,
    locations: locationsReducer,
    reports: reportsReducer,
    successBox: successBoxReducer,
    poiManagement: poiManagementReducer,
    reportItemTypes: reportItemTypesReducer,
    countries: countriesReducer,
    projects: projectsReducer,
    login: loginReducer,
    errorMessage: errorMessageReducer,
  });

// export default combineReducers(reducers);
export default createRootReducer;
