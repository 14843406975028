import { ReportItemType } from '../../../common/reportItems/types';

export const mapReportItemType = (value: string): ReportItemType => {
  switch (value) {
    case 'DURATION':
      return ReportItemType.DURATION;
    case 'FOOTFALL':
      return ReportItemType.FOOTFALL;
    case 'CATCHMENT':
      return ReportItemType.CATCHMENT;
    case 'RETENTION':
      return ReportItemType.RETENTION;
    case 'MOVEMENT':
      return ReportItemType.MOVEMENT;
    default:
      throw new Error(`Could not parse ${value} to ReportItemType enum`);
  }
};
