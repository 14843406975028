import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  calculateStrokeDashOffset,
  dataPercentage,
  getCircleTransformValue,
  getCircumference,
  getTextCoordinates,
} from './utils';
import { CircleData } from '../interfaces';

interface Props {
  options: {
    cx: number;
    cy: number;
    radius: number;
    strokeWidth: number;
    circumferenceAdjuster?: number;
    transformDeviations?: number[];
  };
  data: CircleData[];
  viewBox?: string;
  width?: string;
  height?: string;
  useBackground?: boolean;
}
export const Circle = ({ options, data, viewBox = '0 0 160 160', width, height, useBackground }: Props) => {
  const [chartData, setChartData] = useState<{ degrees: number; textX: number; textY: number; id: number }[]>([]);
  const angleOffset = useRef(-90);
  const { cx, cy, radius, strokeWidth, circumferenceAdjuster = 0, transformDeviations } = options;

  const totalValue = useMemo(() => data.reduce((acc, { stake }) => acc + stake, 0), [data]);

  useEffect(() => {
    const sortedData = data.sort((a, b) => b.stake - a.stake);
    const chartData = sortedData.map(({ stake, id }) => {
      const percentage = dataPercentage(stake, totalValue);
      const { x, y } = getTextCoordinates(stake, angleOffset.current, cx, cy, radius, percentage);
      const data = {
        degrees: angleOffset.current,
        textX: x,
        textY: y,
        id,
      };

      angleOffset.current = percentage * 360 + angleOffset.current;
      return data;
    });
    setChartData(chartData);
  }, [data]);

  const circumference = getCircumference(radius);
  return chartData.length > 0 ? (
    <svg height={height} width={width} viewBox={viewBox}>
      {!useBackground && (
        <defs>
          <mask id="hole-1">
            <rect width="100%" height="100%" fill="white" />
            <circle r="8" cx="132" cy="113" fill="black" />
            <circle r="8" cx="109" cy="132" fill="black" />
            <circle r="8" cx="80" cy="140" fill="black" />
            <circle r="8" cx="50" cy="132" fill="black" />
            <circle r="8" cx="27" cy="107" fill="black" />
          </mask>
          <mask id="hole-2">
            <rect width="100%" height="100%" fill="white" />
            <circle r="8" cx="132" cy="106" fill="black" />
            <circle r="8" cx="110" cy="132" fill="black" />
            <circle r="8" cx="80" cy="140" fill="black" />
          </mask>
          <mask id="hole-3">
            <rect width="100%" height="100%" fill="white" />
            <circle r="8" cx="140" cy="80" fill="black" />
            <circle r="8" cx="133" cy="110" fill="black" />
            <circle r="8" cx="113" cy="132" fill="black" />
          </mask>
        </defs>
      )}

      {data.map(({ color, stake, id: dataId }, index) => {
        const transformDeviation = transformDeviations?.[index];
        const chartItem = chartData.find(({ id }) => id === dataId);
        const degrees = chartItem?.degrees || 0;
        const transform = getCircleTransformValue(degrees, cx, cy, transformDeviation || 0);
        const percentage = dataPercentage(stake, totalValue);

        return (
          <circle
            cx={cx}
            cy={cy}
            r={radius}
            stroke={color}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference - circumferenceAdjuster}
            strokeDashoffset={calculateStrokeDashOffset(stake, circumference, percentage)}
            transform={transform}
            fill="transparent"
            mask={!useBackground ? `url(#hole-${index + 1})` : ''}
          />
        );
      })}
    </svg>
  ) : (
    <></>
  );
};

const test = '#A67BDD';
