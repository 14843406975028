import React, { useState, useEffect, useContext } from 'react';
import { TripsLayer } from '@deck.gl/geo-layers';
import { Position } from '@deck.gl/core/utils/positions';
import { PathLayer } from 'deck.gl';
import { Point, Trip } from '../../../common/types/visualizationObjects';
import { MainContext } from '../../../common/types/mainContext';
import { fetchPathFromPoints } from '../../../reportItems/catchment/services';
import { getTimestampsFromDistance } from '../../../common/maps/utils/getTimestampsFromDistance';
import DEFAULT_THEME from '../../../common/maps/mapBase/map-theme.config';
import DeckBase from '../../../common/maps/mapBase/deckBase';
import useTripAnimation from './useTripAnimation';
import { PointDistance } from './interfaces';
import { CircleLoader } from '../../../common/loaders';
import { backgroundMapModel } from '../model';

interface LocationSource {
  longitude: number | null;
  latitude: number | null;
}

interface TProps {
  points?: Point[];
  oneToOnePoints?: PointDistance[];
  type?: 'oneToOne' | 'manyToOne';
  apiType: 'walking' | 'driving';
  animationSpeed?: number;
  useDistance?: boolean;
  trailLength?: number;
}

export const TripsAnimation = ({
  points,
  type = 'manyToOne',
  oneToOnePoints,
  apiType,
  animationSpeed = 30,
  useDistance = false,
  trailLength = 400,
}: TProps) => {
  const [trips, setTrips] = useState<Trip[] | null>(null);
  const { selectedLocations, primaryLocationId } = useContext(MainContext);
  const [sourceLocation, setSourceLocation] = useState<LocationSource | null>(null);
  const [areTripsLoading, setTripsLoading] = useState<boolean>(true);

  useEffect(() => {
    const primaryLocation = selectedLocations.find((location) => location.id === primaryLocationId);
    if (primaryLocation) {
      setSourceLocation(primaryLocation);
    }

    return () => {
      backgroundMapModel.changeMapLoadingStatuses({ tripRoutes: false });
    };
  }, [selectedLocations]);

  const fetchTrip = async (point: number[], center: number[]): Promise<Trip> => {
    const trip = await fetchPathFromPoints([point, center], apiType);
    const path = trip.trips[0].geometry.coordinates;
    return {
      path,
      timestamps: getTimestampsFromDistance(path as [number, number][]),
    } as Trip;
  };

  const getManyToOneTrips = async (tripPoints: Point[], center: number[]) => {
    const tripsData: Trip[] = [];
    for (let i = 0; i < tripPoints.length; i++) {
      const trip = await fetchTrip([tripPoints[i].longitude, tripPoints[i].latitude] as number[], center);
      if (trip) {
        tripsData.push(trip);
      }
    }
    setTrips(tripsData);
    setTripsLoading(false);
  };

  const getOneToOneTrips = async (points: PointDistance[]) => {
    const tripsData: Trip[] = [];
    for (let i = 0; i < points.length; i++) {
      const current = points[i];
      const trip = await fetchTrip(current.from, current.to);
      if (trip) {
        tripsData.push(trip);
      }
    }
    setTrips(tripsData);
    setTripsLoading(false);
  };

  const onLayersLoad = () => {
    backgroundMapModel.changeMapLoadingStatuses({ tripRoutes: true });
  };

  useEffect(() => {
    if (type === 'manyToOne') {
      if (points && sourceLocation) {
        setTrips([]);
        getManyToOneTrips(points, [sourceLocation.longitude, sourceLocation.latitude] as number[]);
      }
    } else if (type === 'oneToOne') {
      if (oneToOnePoints && sourceLocation) {
        setTrips([]);
        getOneToOneTrips(oneToOnePoints);
      }
    }
  }, [points, oneToOnePoints, sourceLocation]);

  const distances = trips?.map(({ timestamps }) => Math.max(...timestamps));
  const times = useTripAnimation(animationSpeed, distances, useDistance);

  return (
    <>
      {areTripsLoading && <CircleLoader withBackground />}
      <DeckBase
        effects={DEFAULT_THEME.effects}
        onLayersLoad={onLayersLoad}
        layers={
          trips && trips?.length > 0
            ? [
                new PathLayer({
                  id: 'path-layer',
                  data: trips,
                  colorFormat: 'RGBA',
                  widthMinPixels: 5,
                  getPath: (d) => d.path as Position[],
                  getColor: (d) => [0, 178, 241, 0.5 * 255],
                  getWidth: (d) => 5,
                }),
                ...trips.map(
                  (trip, index) =>
                    new TripsLayer<Trip>({
                      id: 'trips',
                      data: [trip],
                      getPath: (d) => d.path as Position[],
                      getTimestamps: (d) => d.timestamps,
                      getColor: () => [0, 0, 0],
                      opacity: 0.9,
                      widthMinPixels: 3,
                      trailLength,
                      currentTime: useDistance
                        ? times[index]
                          ? times[index] % Math.max(...trip.timestamps)
                          : 0
                        : times[0]
                        ? times[0] % Math.max(...trip.timestamps)
                        : 0,
                      rounded: true,
                    }),
                ),
              ]
            : undefined
        }
      />
    </>
  );
};
