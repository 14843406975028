import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../../common/types/mainContext';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { CatchmentMetricTypeEnum, CatchmentTypeEnum } from '../../../common/reportItems/types';
import SingleBarPin from '../../../common/maps/chartPin/singleBarPin';
import { IndexArea } from '../../../common/types/visualizationObjects';
import { getSocioDemoFieldName, getValueFieldName } from './utils/getFieldName';
import { getChartsNumberByZoom } from './utils/getChartsNumberByZoom';

const SocioDemoBarChartPoints = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReportItem },
    primaryLocationId,
    reportItemsDetails: { catchmentType, catchmentTreshold, catchmentMetricType, catchmentSocioDemoMetric },
    mapOptions,
  } = useContext(MainContext);

  const valueFieldName = getValueFieldName(catchmentMetricType);
  const socioDemoFieldName = getSocioDemoFieldName(catchmentSocioDemoMetric);

  const postalCodePolygons = useMemo(() => {
    let locationPolygons: IndexArea | null = null;
    locationPolygons = primaryLocationId
      ? selectedReportItem?.visualization?.indexPolygons?.find((item) => item.location_id === primaryLocationId) || null
      : null;

    const data = catchmentType === CatchmentTypeEnum.HOME ? locationPolygons?.home : locationPolygons?.work;
    const filteredData = data
      ? {
          ...data,
          polygons: data.polygons
            .filter(
              (item) =>
                (item[valueFieldName] || 0) >= catchmentTreshold.min &&
                (item[valueFieldName] || 0) <= catchmentTreshold.max &&
                (item[socioDemoFieldName] || 0) > 0 &&
                item.centerPointLongitude &&
                item.centerPointLatitude,
            )
            .sort((a, b) => (b[socioDemoFieldName] || 0) - (a[socioDemoFieldName] || 0)),
        }
      : null;
    return filteredData;
  }, [primaryLocationId, catchmentType, catchmentTreshold, selectedReportItem, valueFieldName, socioDemoFieldName]);

  const chartsNumber = getChartsNumberByZoom(mapOptions.catchment.zoom || 9);

  return (
    <>
      {postalCodePolygons?.polygons.slice(0, chartsNumber).map((point) => (
        <SingleBarPin
          maxValue={postalCodePolygons.polygons[0][socioDemoFieldName]}
          key={point.location_id}
          longitude={point.centerPointLongitude}
          latitude={point.centerPointLatitude}
          name={point.name}
          unitLabel=""
          chartValues={[
            { name: t(`catchment.enum.${catchmentSocioDemoMetric}`), value: point[socioDemoFieldName] || 0 },
          ]}
        />
      ))}
    </>
  );
};

export default SocioDemoBarChartPoints;
