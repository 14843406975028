import React, { useContext, useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { MainContext } from '../../../../../common/types/mainContext';
import { addTableauEmbedIframeParams } from './utils/modifyEmbedIframe';
import { tableauServer } from './utils';
import styles from './style.module.scss';
import { SidebarContext } from '../../../../../common/sidebarMenu/sidebarContext';

export const TableauReport = () => {
  const { selection } = useContext(MainContext);
  const [iframeURL, setIframeURL] = useState('');
  const { isOpened } = useContext(SidebarContext);

  useEffect(() => {
    const report = selection.selectedReport;

    if (report) {
      const externalItemUrl = report.visualization_url;
      let iframeUrl;
      if (externalItemUrl && externalItemUrl.length) {
        iframeUrl =
          externalItemUrl.includes(tableauServer.domain) ||
          externalItemUrl.includes(tableauServer.oldDomain) ||
          externalItemUrl.includes(tableauServer.publicUrl)
            ? addTableauEmbedIframeParams(externalItemUrl)
            : externalItemUrl;

        setIframeURL(iframeUrl);
      }
    }
  }, [selection.selectedReport]);

  const handle = useFullScreenHandle();

  const isIframe = iframeURL.length > 0;
  return (
    <div
      className={`custom-scrollbar ${styles.tableu} ${!isOpened ? styles.full : styles.part} ${
        isIframe ? styles.wrapper : ''
      }`}
    >
      {/* <div className={styles.buttonWrapper}> */}
      {/*   <span className={styles.openButton} onClick={handle.enter}> */}
      {/*     <FullscreenIcon /> */}
      {/*   </span> */}
      {/* </div> */}
      {
        <FullScreen handle={handle} className={styles.fullscreen}>
          {/* <span onClick={handle.exit} className={styles.exitButton}> */}
          {/*   <ExitFullScreenIcon /> */}
          {/* </span> */}
          {isIframe ? (
            <iframe title="tableau-iframe" frameBorder="0" src={iframeURL} width="100%" height="100%" />
          ) : (
            <h1>
              Error <a href={iframeURL}>loading</a> data
            </h1>
          )}
        </FullScreen>
      }
    </div>
  );
};
