import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../tooltip';
import styles from './styles.module.scss';
import { Button } from '../../../controls/buttons';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
}

const MaximumNumberOfClustersErrorMessage = ({ onClose, isOpen }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip onClose={onClose} title={t('edit.validation.clusterSizeLimit.title')} isOpen={isOpen} height={350}>
      <>
        <p>
          <b>{t('edit.validation.clusterSizeLimit.paragraph1')}</b>
        </p>
        <p>
          {t('edit.validation.clusterSizeLimit.paragraph2')}
          <b>{t('edit.validation.clusterSizeLimit.paragraph2Bold')}</b>
        </p>
        <p className={styles.contactUsContainer}>
          {t('edit.validation.contactUs')} <a href="mailto:cs@placesense.ai">cs@placesense.ai</a>
        </p>
        <div className={styles.buttonContainer}>
          <Button layout="primary" onClick={onClose}>
            {t('edit.validation.continue')}
          </Button>
        </div>
      </>
    </Tooltip>
  );
};

export default MaximumNumberOfClustersErrorMessage;
