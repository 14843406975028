import { FiltersOption } from '../../interfaces';

export const enrichFilterOptionsByOptionAll = (data: FiltersOption[]): FiltersOption[] =>
  data.map((country) => ({
    ...country,
    metrics: country?.metrics?.map((metric) => ({
      ...metric,
      categories:
        metric?.categories?.length > 0
          ? metric.categories.map((category) => ({
              ...category,
              subcategories: category?.subcategories?.length > 0 ? category.subcategories : [],
            }))
          : [],
    })),
  }));
