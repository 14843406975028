export enum Periods {
  WHOLE_PERIOD = 'Whole period',
}

export const REQUEST_SHORT_DELAY = 100;
export const REQUEST_DELAY = 1000;

export const WeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const WeekDaysInOrder = [...[...WeekDays].splice(1, WeekDays.length)];

export const WeekDaysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export interface ReportItemSettings {
  settings: ReportItemSetting[];
}

export interface ReportItemSetting {
  name: string;
  value: string;
}
