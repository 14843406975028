import { AppActions } from '../actions/actionTypes';
import { Country } from '../types/country';

export interface CountriesState {
  entries?: Country[];
}

const initialState: CountriesState = {
  entries: undefined,
};

export default function countriesReducer(state = initialState, action: AppActions): CountriesState {
  switch (action.type) {
    case 'LOAD_COUNTRIES_SUCCESS':
      return {
        ...state,
        entries: action.payload.countries,
      };
    default:
      return state;
  }
}
