import * as turf from '@turf/turf';
import { GeoJSON } from 'echarts/types/src/coord/geo/geoTypes';
import { Area } from '../../types/visualizationObjects';

type Point = [number, number];

export const isPointInPolygon = (point: Point) => {
  const pt = turf.point(point);
  return ({ geo }: Area) => {
    if (geo.geometry.type === 'Polygon' || geo.geometry.type === 'MultiPolygon') {
      return turf.booleanPointInPolygon(pt, geo as GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>);
    }
    return false;
  };
};
