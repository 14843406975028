import React from 'react';

export const ImageIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_8748_12028)">
        <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.1" />
        <path
          d="M13.2998 5.5L17.1998 9.4V17.2C17.1998 17.5448 17.0628 17.8754 16.819 18.1192C16.5752 18.363 16.2446 18.5 15.8998 18.5H8.0998C7.75502 18.5 7.42436 18.363 7.18057 18.1192C6.93677 17.8754 6.7998 17.5448 6.7998 17.2V6.8C6.7998 6.45522 6.93677 6.12456 7.18057 5.88076C7.42436 5.63696 7.75502 5.5 8.0998 5.5H13.2998ZM15.8998 17.2V10.05H12.6498V6.8H8.0998V17.2H15.8998ZM15.2498 12.65V16.55H8.7498L11.9998 13.3L13.2998 14.6L15.2498 12.65ZM10.6998 11.025C10.6998 11.2836 10.5971 11.5316 10.4142 11.7144C10.2314 11.8973 9.98339 12 9.7248 12C9.46622 12 9.21822 11.8973 9.03538 11.7144C8.85253 11.5316 8.7498 11.2836 8.7498 11.025C8.7498 10.7664 8.85253 10.5184 9.03538 10.3356C9.21822 10.1527 9.46622 10.05 9.7248 10.05C9.98339 10.05 10.2314 10.1527 10.4142 10.3356C10.5971 10.5184 10.6998 10.7664 10.6998 11.025Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_8748_12028"
          x="-15"
          y="-15"
          width="54"
          height="54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8748_12028" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8748_12028" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
