import React from 'react';
import { AttractionDetail } from '../../../../../reportItems/attraction/detail/AttractionDetail';
import { TrendsDetail } from '../../../../../reportItems/trends/detail';
import { SeasonalityDetail } from '../../../../../reportItems/seasonality/detail/SeasonalityDetail';
import { DurationDetail } from '../../../../../reportItems/duration/detail/DurationDetail';
import { CrossVisitationDetails } from '../../../../../reportItems/crossVisitation/detail';
import { AbsorptionDetail } from '../../../../../reportItems/absorption/detail/AbsorptionDetail';
import { ExportOptions } from '../../model/interfaces';
import { CatchmentDetail } from '../../../../../reportItems/catchment/detail/CatchmentDetail';
import { MovementDetail } from '../../../../../reportItems/movement/detail';
import { RetentionDetail } from '../../../../../reportItems/retention/detail';
import { DefaultModesEnum } from '../../../../reportItems/types';

interface Props {
  exportOptions: ExportOptions;
}
export const ChartsContainer: React.FC<Props> = ({ exportOptions }) => {
  const { CATCHMENT, FOOTFALL, MOVEMENT, SEASONALITY, DURATION, RETENTION, LOYALTY, ABSORPTION, ATTRACTION } =
    exportOptions;
  return (
    <div style={{ position: 'fixed', opacity: 0 }}>
      {CATCHMENT?.chart || CATCHMENT?.table ? (
        <div style={{ width: '1430px', height: '635px' }}>
          <CatchmentDetail />
        </div>
      ) : (
        <></>
      )}{' '}
      {FOOTFALL?.chart || FOOTFALL?.table ? (
        <div style={{ width: '1430px', height: '640px' }}>
          <TrendsDetail useFixedScreenshotSize />
        </div>
      ) : (
        <></>
      )}
      {MOVEMENT?.chart || MOVEMENT?.table ? (
        <div style={{ width: '1430px', height: '700px' }}>
          <MovementDetail />
        </div>
      ) : (
        <></>
      )}
      {SEASONALITY?.chart || SEASONALITY?.table ? (
        <div style={{ width: '1430px', height: '864px', position: 'fixed', top: 0, zIndex: 2 }}>
          <SeasonalityDetail useFixedScreenshotSize />
        </div>
      ) : (
        <></>
      )}
      {DURATION?.chart || DURATION?.table ? (
        <div style={{ width: '1430px' }}>
          <DurationDetail />
        </div>
      ) : (
        <></>
      )}{' '}
      {LOYALTY?.chart || LOYALTY?.table ? (
        <div style={{ width: '1430px' }}>
          <CrossVisitationDetails />
        </div>
      ) : (
        <></>
      )}
      {ABSORPTION?.chart || ABSORPTION?.table ? (
        <div style={{ width: '1430px' }}>
          <AbsorptionDetail useFixedScreenshotSize />
        </div>
      ) : (
        <></>
      )}{' '}
      {ATTRACTION?.chart || ATTRACTION?.table ? (
        <div style={{ width: '1430px' }}>
          <AttractionDetail useFixedScreenshotSize />
        </div>
      ) : (
        <></>
      )}{' '}
      {RETENTION?.chart || RETENTION?.table ? (
        <div style={{ width: '1430px' }}>
          <RetentionDetail useFixedScreenshotSize />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
