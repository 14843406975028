import React, { useContext, useEffect } from 'react';
import { BaseModal } from '../../baseModal';
import styles from './style.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../common/controls/buttons';
import { useURLParams } from '../../../common/hooks';
import { login } from '../../../api';
import { processUserDataAfterLogin } from '../../utils';
import { MainContext } from '../../../common/types/mainContext';
import { Routes } from '../../../routes';

export const ActiveAccountCongratulations = () => {
  const { saveUser } = useContext(MainContext);
  const history = useHistory();
  const { params, isParsingDone } = useURLParams();

  useEffect(() => {
    if (isParsingDone) {
      const { password, login: email } = params;
      if (email && password) {
        (async function () {
          try {
            const { token } = await login({ password, email });
            const { user } = processUserDataAfterLogin(token);
            saveUser(user);
            history.replace(Routes.signUpActivateAccountCongratulationsVerified);
          } catch (error) {
            console.log(error);
          }
        })();
      }
    }
  }, [isParsingDone]);

  const onRedirect = () => {
    // his
  };

  return (
    <BaseModal subtitle={''} title={''}>
      <div className={styles.wrapper}>
        <p>Browse through our demo reports to see how Placesense.ai can help you make smarted business decisions</p>
        <Button onClick={onRedirect} layout={'primary'}>
          Browse reports
        </Button>
      </div>
    </BaseModal>
  );
};
