import React from 'react';
import Wrapper from 'react-loading-skeleton';

interface Props {
  className?: string;
  count?: number;
  width?: string;
}
export const Skeleton: React.FC<Props> = ({ className, count, width }) => (
  <Wrapper
    className={className}
    count={count}
    width={width}
    baseColor="rgba(255, 255, 255, 0.1)"
    highlightColor="rgba(255, 255, 255, 0.2)"
    duration={3}
  />
);
