import {
  deleteLocationOnFrontend,
  removeLocationFromReport,
  saveAndClearLocations,
  resetLocations,
  saveLocations,
  updateLocation,
} from './events';
import { $projectLocations, $selectedLocations, $selectedLocationsInformation } from './stores';
import { getLocations, deleteLocationOnBackend, createLocation, editLocation, createLocations } from './effects';

export const LocationManagerModel = {
  getLocations,
  deleteLocationOnBackend,
  $projectLocations,
  $selectedLocations,
  createLocation,
  createLocations,
  editLocation,
  deleteLocationOnFrontend,
  removeLocationFromReport,
  saveAndClearLocations,
  saveLocations,
  updateLocation,
  resetLocations,
  $selectedLocationsInformation,
};
