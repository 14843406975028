import { createContext } from 'react';
import { MovementMapBars, MovementMapTypesEnum } from './movement';
import { CatchmentMapBars, CatchmentMapTypesEnum } from './catchment';
import { LocationMapBarItem } from './interfaces';
import { SlidingBarViewOptions } from '../../sliders';

export const MapBottomBarContext = createContext({
  movement: { data: { heatmap: null }, view: { expanded: true, collapsed: false } } as {
    data: MovementMapBars;
    view: SlidingBarViewOptions;
  },
  catchment: {
    data: { postalCodes: null, heatmap: null, trips: null },
    view: { expanded: true, collapsed: false },
  } as { data: CatchmentMapBars; view: SlidingBarViewOptions },
  onChangeMovement: (type: MovementMapTypesEnum, data: LocationMapBarItem[]) => {},
  onChangeCatchment: (type: CatchmentMapTypesEnum, data: LocationMapBarItem[]) => {},
  onChangeCatchmentViewOptions: (data: Partial<SlidingBarViewOptions>) => {},
  onChangeMovementViewOptions: (data: Partial<SlidingBarViewOptions>) => {},
});
