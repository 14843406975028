import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportItemType } from '../../../common/reportItems/types';
import { Absorption, LocationInfo, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { MainContext } from '../../../common/types/mainContext';
import { getAbsorptionData, getSourceLocations } from '../services';
import ReportItemCard from '../../../common/reportItems/reportItemCard';
import SourceLocationDropdown from './SourceLocationDropdown';
import alphabeticalSort from '../lib/alphabeticalSort';
import AbsorptionCardChart from './AbsorptionCardChart';
import { toFixNumber } from '../../../common/lib';
import { AbsorptionMainTooltip } from './tooltips';
import { useToggle } from '../../../common/hooks';
import { dashboardModel } from '../../../dasboardLoader';
import { exportSettingsModel } from '../../../common/export';
import { useTooltipStartPosition } from '../../../common/reportItems/hooks';

const AbsorptionCard = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetched, setFetched] = useState<boolean>(true);
  const [sourceLocations, setSourceLocations] = useState<LocationInfo[]>([]);
  const [isTooltipOpen, toggleTooltipOpen] = useToggle(false);

  const {
    selection: { selectedReport, selectedProject, selectedReportItem },
    absorptionSourceLocation,
    setAbsorptionSourceLocation,
    selectedTimeFrames,
    updateReportItemOfSelectedReport,
    selectedLocationsIds,
  } = useContext(MainContext);
  const { tooltipIconRef, position } = useTooltipStartPosition();

  const reportItem = useMemo(
    () => selectedReport?.report_items?.find((i) => i.type === ReportItemType.ABSORPTION) as ReportItemInfo<Absorption>,
    [selectedReport],
  );

  const setSourceLocationsHandler = (fetchedSourceLocationIds: string[]) => {
    if (selectedProject && fetchedSourceLocationIds.length > 0) {
      const locations = selectedProject.locations
        .filter(({ id }) => fetchedSourceLocationIds.includes(id))
        .sort((locationA, locationB) => alphabeticalSort(locationA.name, locationB.name));
      setSourceLocations(locations);
      if (locations.length > 0) {
        setAbsorptionSourceLocation(locations[0]);
      } else {
        setAbsorptionSourceLocation(null);
      }
    } else {
      setSourceLocations([]);
      setAbsorptionSourceLocation(null);
    }
  };

  useEffect(() => {
    if (reportItem) {
      getSourceLocations(reportItem.id, setSourceLocationsHandler);
    }
  }, [reportItem?.id]);

  // fetch absorption data
  useEffect(() => {
    if (reportItem && !isLoading) {
      dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.ABSORPTION, value: false });
      getAbsorptionData(
        reportItem.id,
        reportItem.name,
        selectedTimeFrames,
        updateReportItemOfSelectedReport,
        setFetched,
        setIsLoading,
        selectedProject?.locations || [],
        selectedReportItem,
      );
      dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.ABSORPTION, value: true });
    }
  }, [reportItem?.id, selectedTimeFrames]);

  const noData = useMemo(
    () =>
      (fetched &&
        reportItem?.data?.data
          ?.find((sourceLocation) => sourceLocation.location_id === absorptionSourceLocation?.id)
          ?.data.filter((targetLocation) => selectedLocationsIds.includes(targetLocation.locationId)).length === 0) ||
      !reportItem?.data,
    [JSON.stringify(reportItem), fetched, absorptionSourceLocation, selectedLocationsIds],
  );

  const visitsFromAOIPercentage = useMemo(() => {
    const targets = reportItem?.data?.data
      ?.find((sourceLocation) => sourceLocation.location_id === absorptionSourceLocation?.id)
      ?.data.filter((targetLocation) => selectedLocationsIds.includes(targetLocation.locationId));

    return targets
      ? (targets
          .map(({ avgWeightedAbsorption }) => avgWeightedAbsorption)
          .reduce((prevValue, nextValue) => prevValue + nextValue, 0) /
          targets.length) *
          100
      : 0;
  }, [JSON.stringify(reportItem), absorptionSourceLocation, selectedLocationsIds]);

  const onTooltipClose = () => {
    toggleTooltipOpen(false);
  };
  useEffect(() => {
    if (visitsFromAOIPercentage && reportItem.data) {
      exportSettingsModel.updateReportItemsInformation({ absorption: { visitFrom: visitsFromAOIPercentage } });
    }
  }, [visitsFromAOIPercentage]);

  const availableTypes = selectedReport?.report_items?.map((value) => value.type);

  return !availableTypes?.includes(ReportItemType.ABSORPTION) ? (
    <div />
  ) : (
    <ReportItemCard
      reportItemType={ReportItemType.ABSORPTION}
      values={[
        {
          name: t('absorption.card.avgRateOfVisits'),
          value: `${toFixNumber(visitsFromAOIPercentage, 1)}%`,
        },
      ]}
      reportItem={reportItem}
      isLoading={isLoading}
      noData={noData}
      alwaysShowAdditionalInformation
      additionalInformation={
        <SourceLocationDropdown
          locations={sourceLocations}
          isLoading={isLoading}
          title={t('absorption.card.areaOfInterest')}
        />
      }
      onOpenTooltip={toggleTooltipOpen}
      tooltipRef={tooltipIconRef}
    >
      <>
        <AbsorptionMainTooltip onClose={onTooltipClose} isOpen={isTooltipOpen} position={position} />
        <AbsorptionCardChart visitsFromAOIPercentage={visitsFromAOIPercentage} isLoading={isLoading} />
      </>
    </ReportItemCard>
  );
};

export default AbsorptionCard;
