import React from 'react';
import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import { Field, InputTypesEnum } from '../../../../common/inputs';
import { Credentials } from '../interfaces';
import { validationSchema } from './schemes';
import { EmailIcon } from '../../../../common/icons/auth';
import styles from './style.module.scss';
import { Routes } from '../../../../routes';

interface Props {
  onSubmit: (data: Credentials) => void;
  error: string;
}
export const Form: React.FC<Props> = ({ onSubmit, error }) => {
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.field}>
        <Field
          name="email"
          onChange={handleChange}
          value={values.email}
          placeholder="Email"
          id="email"
          maxLabelWidth="64px"
          error={errors.email}
          icon={<EmailIcon />}
          fieldType={InputTypesEnum.WITH_ICON}
        />
      </div>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.actions}>
        <button className={styles.button}>Reset password</button>
        <NavLink to={Routes.dashboard} className={styles.textButton}>
          Login to a different account
        </NavLink>
      </div>
    </form>
  );
};
