import React, { useState, useEffect } from 'react';

interface TProps {
  code: string;
}

export const CountryIcon = ({ code }: TProps) => {
  const [flag, setFlag] = useState<string>('');

  useEffect(() => {
    import(`../../icons/countries/${code}.png`).then((image) => setFlag(image.default));
  }, [code]);

  return flag ? <img src={flag} /> : <span>{code.toUpperCase()}</span>;
};
