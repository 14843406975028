const oldDomain = '://tableau.placense.com/';
const domain = '://tableau.placesense.ai/';

export const tableauServer = {
  oldDomain,
  oldDomainWithTrustedAuth: `${oldDomain}trusted/`,
  domain,
  domainWithTrustedAuth: `${domain}trusted/`,
  publicUrl: 'public.tableau.com',
  showVizHomeParam: 'showVizHome=no',
  embedParam: 'embed=true',
};
