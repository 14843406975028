import { toFixNumber } from '../../../common/lib';

interface Item {
  name: string;
  address: string;
  category: string;
  subcategory: string;
  value: number;
}

const calculateValueAccordingToType = (data: Item[], denominator: number, home: number, work: number) => {
  const defaultData = data.map(({ name, address, category, subcategory, value }) => ({
    name,
    address,
    category,
    subcategory,
    value: toFixNumber(value / denominator, 6),
  }));

  const homeData = toFixNumber(home / denominator, 6);

  const workData = toFixNumber(work / denominator, 6);

  return { defaultData, homeData, workData };
};

export const convertAnalyticsData = (data: Item[], home: number, work: number, denominator: number) => {
  const defaultDenominator = denominator;

  const homeDenominator = denominator + home;
  const workDenominator = denominator + work;
  const homeAndWorkDenominator = denominator + home + work;

  const { defaultData, homeData, workData } = calculateValueAccordingToType(data, defaultDenominator, home, work);

  const {
    defaultData: withHomeData,
    homeData: homeDataWithHome,
    workData: workDataWithHome,
  } = calculateValueAccordingToType(data, homeDenominator, home, work);

  const {
    defaultData: withWorkData,
    homeData: homeDataWithWork,
    workData: workDataWithWork,
  } = calculateValueAccordingToType(data, workDenominator, home, work);

  const {
    defaultData: withWorkAndHomeData,
    homeData: homeDataWithWorkAndHome,
    workData: workDataWithWorkAndHome,
  } = calculateValueAccordingToType(data, homeAndWorkDenominator, home, work);

  return {
    //Without home and work
    default: defaultData,
    defaultHome: homeData,
    defaultWork: workData,
    //Only with home
    withHomeData,
    homeDataWithHome,
    workDataWithHome,
    //Only with work
    withWorkData,
    homeDataWithWork,
    workDataWithWork,
    //With home and work
    withWorkAndHomeData,
    homeDataWithWorkAndHome,
    workDataWithWorkAndHome,
  };
};
