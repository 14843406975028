import React from 'react';
import { PeriodsEnum } from '../../../../common/reportItems/types';
import { TrendValue } from '../../types';
import { ChartEvents } from '../../../../common/chart/interfaces';
import { MessageLoadGraph } from '../MessageLoadGraph';
import { Chart } from '../Chart';
import styles from './style.module.scss';
import { LocationInfo } from '../../../../common/types/projectsHierarchy';

interface Props {
  period: PeriodsEnum;
  data: TrendValue[];
  isPercentage: boolean;
  fetched: boolean;
  locationsWithMissingAbsolute: string[];
  projectLocations: LocationInfo[];
  events?: ChartEvents;
  isScreenMode?: boolean;
  useFixedScreenshotSize?: boolean;
}

export const ChartWithPreloaderScreen = ({
  data,
  period,
  isPercentage,
  locationsWithMissingAbsolute,
  fetched,
  projectLocations,
  events,
  isScreenMode,
  useFixedScreenshotSize,
}: Props) => (
  <div className={`${styles.container}`}>
    {fetched && data.length <= 0 && <MessageLoadGraph />}
    <Chart
      data={data}
      period={period}
      isPercentage={isPercentage}
      locationsWithMissingAbsolute={locationsWithMissingAbsolute}
      projectLocations={projectLocations}
      events={events}
      isScreenMode={isScreenMode}
      useFixedScreenshotSize={useFixedScreenshotSize}
    />
  </div>
);
