import moment from 'moment';

export const sortByName = <T extends { name: string }>(data: T[]) => data.sort((a, b) => a.name.localeCompare(b.name));

export const sortByNumberOfLocations = <T extends { locations_count: number | null }>(data: T[]) =>
  data.sort((a, b) => (b.locations_count || 0) - (a.locations_count || 0));

export const sortByNumberOfReports = <T extends { reports: any[] }>(data: T[]) =>
  data.sort((a, b) => b.reports.length - a.reports.length);

export const sortByCreatedDate = <T extends { created_date: string | null }>(data: T[]) =>
  data.sort((a, b) => {
    const firstDateUnix = moment(a.created_date, 'YYYY-MM-DD').valueOf();
    const secondDateUnix = moment(b.created_date, 'YYYY-MM-DD').valueOf();
    return secondDateUnix - firstDateUnix;
  });

export const sortByValue = <T extends { value: number | string }>(data: T[]) =>
  data.sort((a, b) => Number(b.value) - Number(a.value));
