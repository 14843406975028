import React from 'react';
import { Page, Title, Message, LinkButton } from '../common';

interface StateProps {
  errorMessage: string;
}

const ResetSuccessfullPage = (props: StateProps) => (
  <Page>
    <Title>Thank you</Title>
    <Message>
      Your password was updated successfully.
      <br />
      Click enter to login.
    </Message>

    <LinkButton url="/" text="Enter" />
  </Page>
);

export default ResetSuccessfullPage;
