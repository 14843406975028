import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { CrossIcon } from '../../icons/controls';
import { Button } from '../../controls/buttons';

interface Props {
  width?: string;
  title?: string;
  onNo?: VoidFunction;
  onYes?: VoidFunction;
  withActions?: boolean;
  withCloseActions?: boolean;
  withCross?: boolean;
}
export const Popup: React.FC<Props> = ({
  children,
  width = '100%',
  title,
  onNo = () => {},
  onYes = () => {},
  withActions,
  withCross,
  withCloseActions,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.tooltip} style={{ width }}>
      <div className={`${styles.header} ${title ? styles.withTitle : ''}`}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {withCross && (
          <span onClick={onNo}>
            <CrossIcon />
          </span>
        )}
      </div>
      <div className={styles.content}>{children}</div>
      {(withActions || withCloseActions) && (
        <div className={styles.actions}>
          <Button onClick={onNo}>{t('reportItem.no')}</Button>
          <Button layout="primary" onClick={onYes}>
            {t('reportItem.yes')}
          </Button>
        </div>
      )}
    </div>
  );
};
