import React, { Component } from 'react';
import classNames from 'classnames';
import Dropdown from 'react-dropdown';
import styles from './addPoiForm.module.css';
import Button from '../../atoms/primaryButton/button';

import { GeoPoint, Location } from '../../../types/location';
import { AddPoiResultType } from '../../../reducers/poiManagementReducers';
import { isWKTFormat } from '../../../polygonUtils';

import PolylineTool from '../../PolylineTool';
import { SetupType } from './SetupType';

interface Props {
  resultMessage: {
    message: string;
    type: AddPoiResultType;
  };
  createPoi: (poi: Location, onSuccess: () => void) => any;
}

interface State {
  poi: {
    name: string;
    description: string;
    address: string;
    street: string;
    houseNumber: string;
    city: string;
    country: string;
    postalCode: string;
    purpose: string;
    polygon: string;
    setup: string;
  };
  isPolylineToolVisible: boolean;
}

const initialState = {
  poi: {
    name: '',
    description: '',
    address: '',
    street: '',
    houseNumber: '',
    city: '',
    country: '',
    postalCode: '',
    purpose: '',
    polygon: '',
    setup: SetupType.UNDEFINED,
  },
  isPolylineToolVisible: false,
};

const setupTypesOptions = Object.values(SetupType);

export default class AddPoiForm extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = initialState;
  }

  private onSavePolygon(polygon: string) {
    this.setState((state) => ({
      ...state,
      poi: {
        ...state.poi,
        polygon,
      },
      isPolylineToolVisible: false,
    }));
  }

  private onSaveSetupType(setup: SetupType) {
    this.setState((state) => ({
      ...state,
      poi: {
        ...state.poi,
        setup,
      },
    }));
    return setup;
  }

  private static obtainPolygonPoints(polygonInput: string) {
    const polygonPoints: GeoPoint[] = polygonInput.split('\n').map((coordinatePair) => {
      const pairAsList = coordinatePair.replace(/ /g, '').split(',');
      return {
        longitude: pairAsList[0],
        latitude: pairAsList[1],
      };
    });
    return polygonPoints;
  }

  private createPoi() {
    const polygonInput = this.state.poi.polygon.replace('N((', 'N ((');
    const isWKT = isWKTFormat(polygonInput);
    this.props.createPoi(
      {
        name: this.state.poi.name,
        description: this.state.poi.description,
        address: this.state.poi.address,
        street: this.state.poi.street,
        houseNumber: this.state.poi.houseNumber,
        city: this.state.poi.city,
        country: this.state.poi.country,
        postalCode: this.state.poi.postalCode,
        purpose: this.state.poi.purpose,
        polygon: {
          points: isWKT ? undefined : AddPoiForm.obtainPolygonPoints(polygonInput),
          wktRepresentation: isWKT ? polygonInput : undefined,
        },
        setup: this.state.poi.setup,
      },
      () => this.setState(initialState),
    );
  }

  private renderField(fieldName: string, displayName: string) {
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>{displayName}</div>
        <div className={styles.valueContainer}>
          <input
            className={styles.input}
            value={(this.state.poi as any)[fieldName]}
            onChange={(event) => {
              const { value } = event.target;
              return this.setState((state) => ({
                ...state,
                poi: {
                  ...state.poi,
                  [`${fieldName}`]: value,
                },
              }));
            }}
          />
        </div>
      </div>
    );
  }

  private renderSetupField() {
    return (
      <div className={styles.formRow}>
        <div className={styles.fieldName}>location setup</div>
        <Dropdown
          options={setupTypesOptions}
          // @ts-ignore
          onChange={(event) => this.onSaveSetupType(event.value)}
          value={this.state.poi.setup}
          placeholder="Select type"
        />
      </div>
    );
  }

  private renderPolygonField() {
    return (
      <>
        <div className={styles.polygonText}>draw or enter polygon *</div>
        <div className={styles.valueContainer}>
          <textarea
            className={styles.textarea}
            value={this.state.poi.polygon}
            onChange={(event) => {
              const polygon = event.target.value;
              return this.setState((state) => ({
                ...state,
                poi: {
                  ...state.poi,
                  polygon,
                },
              }));
            }}
            placeholder={'longitude, latitude\nlongitude, latitude\nor WKT format'}
          />
        </div>
        <Button onClick={this.showPolylineTool.bind(this)}>Draw polygon</Button>
      </>
    );
  }

  render() {
    return (
      <div>
        {this.state.isPolylineToolVisible && (
          <PolylineTool onClose={this.closePolylineTool.bind(this)} onSavePolygon={this.onSavePolygon.bind(this)} />
        )}
        <div className={styles.addPoiForm}>
          {this.renderField('name', 'name *')}
          {this.renderField('description', 'description')}
          {this.renderField('address', 'address')}
          {this.renderField('street', 'street')}
          {this.renderField('houseNumber', 'house number')}
          {this.renderField('city', 'city')}
          {this.renderField('country', 'country')}
          {this.renderField('postalCode', 'postalCode')}
          {this.renderField('purpose', 'purpose')}
          {this.renderSetupField()}
          {this.renderPolygonField()}
        </div>
        <div
          className={classNames({
            [styles.addPoiMessage]: true,
            [styles.addPoiMessageError]: this.props.resultMessage.type === 'failure',
            [styles.addPoiMessageSuccess]: this.props.resultMessage.type === 'success',
          })}
        >
          {this.props.resultMessage.message}
        </div>
        <div className={styles.addPoiButtonContainer}>
          <Button onClick={this.createPoi.bind(this)}>Add</Button>
        </div>
      </div>
    );
  }

  private showPolylineTool() {
    this.updatePolylineTool(true);
  }

  private closePolylineTool() {
    this.updatePolylineTool(false);
  }

  private updatePolylineTool(isPolylineToolVisible: boolean) {
    this.setState((state) => ({
      ...state,
      isPolylineToolVisible,
    }));
  }
}
