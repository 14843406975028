import { convertPolygonToGeoData } from '../../maps/utils/convertPolygonToGeoData';
import { LocationInfo } from '../../types/projectsHierarchy';

const removeFirstSymbolFroHEXColor = (color = '') => color.substring(1);
export const generateLocationMapImageStyles = (locations: LocationInfo[]) => {
  const grateSymbolCode = '%23';
  const properties = {
    stroke: `${grateSymbolCode}ffffff`,
    fill: `${grateSymbolCode}ffffff`,
    'stroke-width': 2,
    'fill-opacity': 0.6,
  };

  return locations.map((location) => {
    const polygons = convertPolygonToGeoData(location.geometry);
    if (polygons) {
      return {
        ...location,
        polygons: {
          ...polygons,
          properties: {
            ...properties,
            stroke: `${grateSymbolCode}${removeFirstSymbolFroHEXColor(location.color)}`,
            fill: `${grateSymbolCode}${removeFirstSymbolFroHEXColor(location.color)}`,
          },
        },
      };
    }

    return {
      ...location,
      polygons: {
        properties,
      },
    };
  });
};
