import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import ReportItemDetail from '../../../common/reportItems/reportItemDetail';
import { Table } from './Table';
import { ContentVisualizationMode, ReportItemInfo, Trend } from '../../../common/types/projectsHierarchy';
import { DetailHeader } from './DetailHeader';
import { PeriodsEnum, ReportItemType } from '../../../common/reportItems/types';
import { fetchTrendsData } from '../services';
import { TrendScopesEnum } from '../enum';
import { MainContext } from '../../../common/types/mainContext';
import { getNormalizedDataByScope } from '../utils';
import { TrendValue } from '../types';
import { useToggle, useURLParams } from '../../../common/hooks';
import { AlertMessage } from './alertMessage';
import { convertDataForExcel, getMissingLocations } from './utils';
import { ChartWithPreloaderScreen } from './ChartWithPreloaderScreen';
import { Option } from '../../../common/controls/dropdown/types';

import { useElementScreenshot } from '../../../common/hooks/useElementScreenshot';
import { exportSettingsModel } from '../../../common/export';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import styles from './style.module.scss';
import { ChartEvents } from '../../../common/chart/interfaces';
import { ChartEventsEnum } from '../../../common/chart/enums';
import { RecurrenceFrequencyEnum } from '../../../createReport/types';
import { RecurringReportTooltip } from '../../../common/reportItems/reportItemDetail/recurringReportTooltip/recurringReportTooltip';
import moment from 'moment';

interface Props {
  useFixedScreenshotSize?: boolean;
}

export const TrendsDetail = ({ useFixedScreenshotSize }: Props) => {
  const { t } = useTranslation();
  const [modifiedData, setModifiedData] = useState<TrendValue[]>([]);
  const [average, setAverage] = useState(0);
  const [changeFromMonthToMonthAverage, setChangeFromMonthToMonthAverage] = useState<number>(0);
  const [isPercentage, toggleIsPercentage] = useToggle(true);
  const [locationsWithMissingAbsolute, setLocationsWithMissingAbsolute] = useState<string[]>([]);
  const [locationsWithMissingAbsoluteMonths, setLocationsWithMissingAbsoluteMonths] = useState<string[]>([]);
  const { onMakeScreenshot, base64Image, elementRef, onClearScreenshotData } = useElementScreenshot({
    backgroundColor: `rgba(37, 55, 76, 1)`,
  });
  const [fetched, toggleFetch] = useToggle(false);
  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const isChartReady = useRef<boolean>(false);
  const {
    selection: { selectedReport },
    selectedLocationsIds,
    updateReportItemsDetailsItems,
    updateReportItemsDetailsValue,
    selectedTimeFrames,
    selectedLocations,
    reportItemsDetails: { items, metricType, showAverageValuesInChart, period: detailsPeriod },
    contentVisualizationMode,
  } = useContext(MainContext);

  const [activeMetric, setActiveMetric] = useState('');
  const isExportMode = useStore(exportSettingsModel.$isExportMode);

  const [period, setPeriod] = useState(isExportMode ? detailsPeriod : PeriodsEnum.MONTHLY);
  const { onChangeParams, params, isParsingDone, isParamsChecked, onParamsCheck } = useURLParams();

  const reportItem = useMemo(
    () => items?.find((item) => item.type === ReportItemType.FOOTFALL) as ReportItemInfo<Trend>,
    [items],
  );

  const getData = async (customPeriod?: PeriodsEnum) => {
    if (reportItem?.id) {
      const response = await fetchTrendsData(
        reportItem.id,
        selectedLocationsIds,
        customPeriod || period,
        selectedLocations,
        selectedTimeFrames,
      );

      if (response) {
        updateReportItemsDetailsItems?.('trends', [response]);
      } else if (selectedTimeFrames?.length === 0) {
        const emptyData = { ...reportItem, visualization: null, data: null };
        updateReportItemsDetailsItems?.('trends', [emptyData]);
      }
    }
  };

  useEffect(() => {
    (async function () {
      if (reportItem?.data === undefined && reportItem?.id) {
        await getData();
      }
    })();
  }, [reportItem?.id]);

  useEffect(() => {
    (async function () {
      if ((reportItem?.data || reportItem?.data === null) && reportItem?.id && isParamsChecked) {
        await getData();
      }
    })();
  }, [period, selectedTimeFrames, selectedLocations, isParamsChecked]);

  useEffect(() => {
    if (isParsingDone && reportItem?.id) {
      const { period } = params;
      if (period) {
        setPeriod(period);
      }
      onParamsCheck(true);
    }
  }, [isParsingDone, reportItem?.id, metricType]);

  const onChangeTimeTypeOption = async (value: Option<PeriodsEnum>) => {
    trackUserAction(
      'Visit trends time period changed',
      UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
      `Time period: ${value.label}`,
    );
    const period = value.id as PeriodsEnum;
    setPeriod(period);
    updateReportItemsDetailsValue('period', period);
    onChangeParams('period', value.id);
  };

  const onChangeMetricType = (metricType: Option<TrendScopesEnum>) => {
    trackUserAction(
      'Visit trends metric type changed in the chart',
      UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
      `Metric type: ${metricType.label}`,
    );

    setActiveMetric(metricType.id);

    //Need to replace space to avoid extra symbols in URL
    const formattedId = metricType.id.replaceAll(' ', '_');
    onChangeParams('metricType', formattedId);
  };

  useEffect(() => {
    setActiveMetric(metricType);
  }, [metricType]);

  useEffect(() => {
    if (period === PeriodsEnum.WEEKLY) {
      (async function () {
        const item = {
          value: period,
          label: period,
          id: period,
          disabled: false,
          item: null,
        };
        await onChangeTimeTypeOption(item);
      })();
    }
  }, [selectedLocationsIds]);

  useEffect(() => {
    if (reportItem?.data) {
      const { absoluteNumber } = reportItem.data.visitCount;
      setAverage(absoluteNumber);

      const fromMonthToMonthAverage = getAverageChangeFromMonthToMonthByMetric(
        reportItem.data,
        activeMetric as TrendScopesEnum,
      );
      setChangeFromMonthToMonthAverage(Number(fromMonthToMonthAverage));
    }
  }, [reportItem, activeMetric]);

  useEffect(() => {
    if (reportItem?.data) {
      const data = getNormalizedDataByScope(activeMetric as TrendScopesEnum, reportItem.data.data);
      if (activeMetric === TrendScopesEnum.ABSOLUTE_NUMBER) {
        const { allMissing, monthMissing } = getMissingLocations(data);
        setLocationsWithMissingAbsolute(allMissing);
        setLocationsWithMissingAbsoluteMonths(monthMissing.filter((item) => !allMissing.includes(item)));
      }
      if (data.length > 1 && showAverageValuesInChart) {
        const averageAcrossLocations = data[0].data.map((header, headerIndex) => {
          const sum = data.reduce(
            (acc, valueItem) =>
              valueItem.data[headerIndex] && !Number.isNaN(+valueItem.data[headerIndex].value)
                ? acc + (valueItem.data[headerIndex].value as number)
                : acc,
            0,
          );
          return {
            date: header.date,
            value: sum / data.length,
            confidence_level: header.confidence_level,
            is_relative_shown: header.is_relative_shown,
          };
        });
        data.unshift({
          location_id: 'average',
          location_name: 'Average',
          data: averageAcrossLocations,
        });
      }
      setModifiedData(data);
    } else {
      setModifiedData([]);
      setChangeFromMonthToMonthAverage(0);
      setAverage(0);
      if (reportItem?.data === null) {
        toggleIsPercentage(false);
      }
    }
    toggleFetch(true);
  }, [reportItem?.data?.data, activeMetric, showAverageValuesInChart]);

  useEffect(() => {
    if (!reportItem?.data?.data) {
      exportSettingsModel.updateExportData({ [ReportItemType.FOOTFALL]: { chart: '', table: '' } });
    }
  }, [reportItem?.data?.data]);

  useEffect(() => {
    toggleIsPercentage(activeMetric !== TrendScopesEnum.ABSOLUTE_NUMBER);
  }, [activeMetric]);

  useEffect(() => {
    if (base64Image && modifiedData.length) {
      exportSettingsModel.updateExportData({ [ReportItemType.FOOTFALL]: { chart: base64Image } });
      onClearScreenshotData();
    }
  }, [base64Image, modifiedData.length]);

  useEffect(() => {
    if (exportOptions.FOOTFALL?.table && modifiedData.length) {
      const data = convertDataForExcel(modifiedData, isPercentage, period);
      exportSettingsModel.updateExportData({ [ReportItemType.FOOTFALL]: { table: data } });
    }
  }, [exportOptions.FOOTFALL?.table, modifiedData]);

  const onChartReady = useCallback(() => {
    //Not the best solution. Need to wait to make sure everything is really loaded to make proper picture
    // setTimeout(() => {
    if (isExportMode) {
      isChartReady.current = true;
      if (exportOptions.FOOTFALL?.chart) {
        onMakeScreenshot();
      }
    }
    // }, 5000);
  }, []);

  const getAverageChangeFromMonthToMonthByMetric = (
    { fromMonthToMonthAverageChange }: Trend,
    metric: TrendScopesEnum,
  ) => {
    switch (metric) {
      case TrendScopesEnum.ALL_LOCATION:
        return fromMonthToMonthAverageChange.allLocations;
      case TrendScopesEnum.SINGLE_LOCATION:
        return fromMonthToMonthAverageChange.singleLocations;
      default:
        return fromMonthToMonthAverageChange.absoluteNumber;
    }
  };

  const chartEvents: ChartEvents = useMemo(
    () => ({
      [ChartEventsEnum.FINISHED]: onChartReady,
    }),
    [reportItem?.data?.data],
  );

  const renderData = (data: TrendValue[]) => {
    if (contentVisualizationMode === ContentVisualizationMode.VISUALIZATION || isExportMode) {
      return (
        <ChartWithPreloaderScreen
          data={data}
          period={period}
          isPercentage={isPercentage}
          locationsWithMissingAbsolute={locationsWithMissingAbsolute}
          fetched={fetched}
          projectLocations={selectedLocations}
          isScreenMode={isExportMode}
          events={chartEvents}
          useFixedScreenshotSize={useFixedScreenshotSize}
        />
      );
    } else {
      return <Table data={data} period={period} isPercentage={isPercentage} />;
    }
  };

  const minimizedData = useMemo(
    () => renderData(modifiedData),
    [modifiedData, contentVisualizationMode, locationsWithMissingAbsolute, isExportMode],
  );

  const lastUpdated = moment(selectedReport?.end_date);

  return (
    <div className={styles.wrapper} ref={elementRef} style={{ width: isExportMode ? 'auto' : '100%', height: isExportMode ? 'auto' : '100%' }}>
      <ReportItemDetail
        title={t('visitTrends.title')}
        subtitle={
          activeMetric !== TrendScopesEnum.ABSOLUTE_NUMBER
            ? t('visitTrends.detail.title')
            : t('visitTrends.detail.titleAlt')
        }
        onMakeScreenshot={onMakeScreenshot}
        type={ReportItemType.FOOTFALL}
      >
        <DetailHeader
          activeTimeType={period}
          activeMetric={activeMetric}
          momAverage={changeFromMonthToMonthAverage}
          average={average}
          hideAdvancedInformation={contentVisualizationMode === ContentVisualizationMode.TABLE}
          onChangeTimeTypeOption={onChangeTimeTypeOption}
          onChangeMetricType={onChangeMetricType}
          isPercentage={isPercentage}
          isAvgNumbers={false}
        />
        {!isPercentage &&
          (locationsWithMissingAbsolute.length > 0 || locationsWithMissingAbsoluteMonths.length > 0) && (
            <AlertMessage
              locationsWithMissingAbsoluteValues={locationsWithMissingAbsolute}
              locationsWithMissingMonthlyAbsoluteValues={locationsWithMissingAbsoluteMonths}
            />
          )}
        {selectedReport?.recurring &&
          selectedReport.recurrence_frequency === RecurrenceFrequencyEnum.WEEKLY &&
          period !== PeriodsEnum.WEEKLY && (
            <RecurringReportTooltip
              year={lastUpdated.format('YYYY')}
              month={lastUpdated.format('MMM')}
              day={lastUpdated.format('DD')}
            />
          )}
        {reportItem?.data?.data && minimizedData}
      </ReportItemDetail>
    </div>
  );
};
