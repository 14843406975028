import React, { useContext, useMemo, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { LocationItem } from './locationItem';
import { SearchSelect } from '../../controls';
import { Button } from '../../controls/buttons';
import { PolygonData } from '../createLocation/interfaces';
import { LocationManagerModel } from '../model';
import isMaxNumberOfLocationsReached from './validation/isMaxNumberOfLocationsReached';
import { ValidationError, ValidationErrorType } from './validation/validationErrorType';
import { MainContext } from '../../types/mainContext';
import CSVImporter from '../csvimporter/CSVImporter';
import { Search } from '../../controls/searchSelect/search';
import { IReportCategory } from '../../../../types/report';
import * as turf from '@turf/turf';
import { splitCoordinatesByPolygons } from '../../polylineTool';
import createPolygon from './validation/createPolygon';

interface Props {
  locations: PolygonData[];
  onGoToCreateNewLocation: (locationId: string | null) => void;
  projectId: string;
  projectName?: string;
  isEdit?: boolean;
  // onValidationError: (validationErrorType: ValidationErrorType) => void;
  onValidationError: (error: ValidationError) => void;
  height: number | undefined;
  reportCategory?: IReportCategory;
}
export const LocationManager: React.FC<Props> = ({
                                                   projectId,
                                                   locations,
                                                   onGoToCreateNewLocation,
                                                   projectName,
                                                   isEdit,
                                                   onValidationError,
                                                   height,
                                                   reportCategory,
                                                 }) => {
  const projectLocations = useStore(LocationManagerModel.$projectLocations);
  const selectedLocationsInformation = useStore(LocationManagerModel.$selectedLocationsInformation);
  const selectedLocations = useStore(LocationManagerModel.$selectedLocations);
  const [searchString, setSearchString] = useState<string>('');
  const {
    limitations: { maximumNumberOfLocations, polygonSize },
  } = useContext(MainContext);
  const { t } = useTranslation();

  const formattedLocations = useMemo(
      () => locations.map(({ name, id }) => ({ label: name, value: String(id), id: String(id) })),
      [locations],
  );

  const calculatePolygonSize = (location: PolygonData | undefined) => {
    if (location) {
      const polygon = createPolygon(location);
      return polygon ? turf.area(polygon) : 0;
    }
    return 0;
  };

  const onSelectLocation = (id: string) => {
    const selectedLocation = projectLocations.find((location) => location.id === id);
    if (!isEdit && isMaxNumberOfLocationsReached(selectedLocations, maximumNumberOfLocations)) {
      onValidationError({ type: ValidationErrorType.NUMBER_OF_LOCATIONS });
    } else if (
        !isEdit &&
        reportCategory === IReportCategory.POI_DEFAULT &&
        calculatePolygonSize(selectedLocation) > polygonSize
    ) {
      onValidationError({ type: ValidationErrorType.POLYGON_SIZE });
    } else {
      if (selectedLocation) {
        LocationManagerModel.saveLocations([selectedLocation]);
      }
    }
  };

  const onDeleteLocation = (id: string) => {
    const selectedLocation = selectedLocationsInformation.find((location) => location.id === id);
    if (selectedLocation) {
      if ('isNew' in selectedLocation) {
        LocationManagerModel.deleteLocationOnFrontend(id);
      } else {
        const onSuccess = () => {
          LocationManagerModel.deleteLocationOnFrontend(id);
        };
        LocationManagerModel.deleteLocationOnBackend({ projectId, locationsIds: [id], onSuccess });
      }
    }
  };

  const onRemoveLocationFromReport = (id: string) => {
    const selectedLocation = selectedLocationsInformation.find((location) => location.id === id);
    if (selectedLocation) {
      LocationManagerModel.removeLocationFromReport(id);
    }
  };

  // const onDuplicateLocation = (id: string) => {
  //   const selectedLocation = selectedLocationsInformation.find((location) => location.id === id);
  //
  //   if (selectedLocation) {
  //     const { id, ...rest } = selectedLocation;
  //     const newLocation = { ...rest, id: String(Date.now()), name: `${rest.name} - copy` };
  //     LocationManagerModel.saveLocations([newLocation]);
  //   }
  // };

  return (
      <div className={styles.container} style={{ height }}>
        <div className={styles.leftPanel}>
          <Button type="button" layout="filled" onClick={() => onGoToCreateNewLocation(null)} className={styles.button}>
            Create a new location
          </Button>
          <CSVImporter isEdit={isEdit} projectId={projectId} onValidationError={onValidationError} />
        </div>
        <div className={styles.center} style={{ height }} />
        <div className={styles.rightPanel}>
          <div className={styles.searchField} style={{ marginBottom: '40px' }}>
            {isEdit ? (
                <Search searchString={searchString} onChange={setSearchString} />
            ) : (
                <SearchSelect
                    options={formattedLocations}
                    onSelect={onSelectLocation}
                    selectedOptions={selectedLocations}
                />
            )}
          </div>
          <div className={`custom-scrollbar ${styles.list}`}>
            {selectedLocations.length > 0 ? (
                <>
                  <div className={styles.noLocations}>
                    <h3>
                      {isEdit ? `${t('locations.locationsTitle')} ${projectName}` : `${t('create.stepTwo.locationsTitle')}`}
                    </h3>
                  </div>
                  {selectedLocations
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .filter((loc) => searchString === '' || loc.label.toLowerCase().includes(searchString.toLowerCase()))
                      .map(({ label, location, id, isUsed, isNew }) => (
                          <LocationItem
                              key={id}
                              id={id}
                              name={label}
                              location={location}
                              isEditable={(isEdit && !isUsed) || !isEdit}
                              onDelete={(isEdit && !isUsed) || (!isEdit && isNew) ? () => onDeleteLocation(id) : undefined}
                              onRemove={!isEdit && !isNew ? () => onRemoveLocationFromReport(id) : undefined}
                              // onCopy={onDuplicateLocation}
                              onEdit={(isEdit && !isUsed) || (!isEdit && !isUsed) ? () => onGoToCreateNewLocation(id) : undefined}
                          />
                      ))}
                </>
            ) : (
                <div className={styles.noLocations}>
                  <h3>
                    {isEdit ? `${t('locations.locationsTitle')} ${projectName}` : `${t('create.stepTwo.locationsTitle')}`}
                  </h3>
                  <p>No location added yet</p>
                </div>
            )}
          </div>
        </div>
      </div>
  );
};
