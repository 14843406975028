import { useEffect, useRef } from 'react';

interface ClosePopup {
  (): void;
}

export const usePopupPositions = (
  uniqueLabelForIcon: string,
  onClosePopup: ClosePopup,
  initTop: number,
  initLeft: number,
  offsetCorrection: number,
) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = document.getElementById('side-panel-scroll');
    const iconElement = document.getElementById(`header-report-item-${uniqueLabelForIcon}`);
    if (iconElement) {
      iconElement.addEventListener('click', (e) => {
        const cursorX = e.pageX;
        const cursorY = e.pageY;
        if (ref.current) {
          const elementHeight = ref.current?.clientHeight;
          ref.current.style.left = `${cursorX + initLeft}px`;
          ref.current.style.top = cursorY - elementHeight >= -5 ? `${cursorY - 5 - elementHeight}px` : '0px';
        }
      });
    }

    if (element) {
      element.addEventListener('scroll', (e) => {
        const icon = document.getElementById(`header-report-item-${uniqueLabelForIcon}`);
        const offsetTop = icon?.getBoundingClientRect().top || 0;
        if (ref.current) {
          // eslint-disable-next-line no-restricted-globals
          const availableHeight = screen.availHeight;
          const elementHeight = ref.current?.clientHeight;
          ref.current.style.top = `${offsetTop}px`;
          if (offsetTop <= 425 || offsetTop + 50 > availableHeight) {
            onClosePopup();
          } else if (offsetTop + elementHeight + 50 >= availableHeight) {
            ref.current.style.top = `${offsetTop - elementHeight}px`;
          }
        }
      });
    }
  }, []);

  return { ref };
};
