import { useContext, useMemo } from 'react';
import { CatchmentArea, Point } from '../../../../../common/types/visualizationObjects';
import { MainContext } from '../../../../../common/types/mainContext';
import { ReportItemType } from '../../../../../common/reportItems/types';

export const usePoints = () => {
  const { selection, primaryLocationId, reportItemsDetails } = useContext(MainContext);

  const postalCodesPoints = useMemo(() => {
    const reportItem = selection.selectedReport?.report_items.find(({ type }) => type === ReportItemType.CATCHMENT);

    const points: CatchmentArea[] =
      reportItem?.visualization?.indexPolygons?.find((location) => location.location_id === primaryLocationId)?.[
        reportItemsDetails.catchmentType.toLowerCase() as 'home' | 'work'
      ].polygons || [];

    const filteredPoints = points.filter(
      (item) =>
        (item.destinationValue || 0) <= reportItemsDetails.catchmentTreshold.max &&
        (item.destinationValue || 0) >= reportItemsDetails.catchmentTreshold.min,
    );

    return filteredPoints;
  }, [reportItemsDetails.catchmentTreshold]);

  const points = useMemo(() => {
    const reportItem = selection.selectedReport?.report_items.find(({ type }) => type === ReportItemType.CATCHMENT);

    const points: Point[] =
      reportItem?.visualization?.heatmap?.find((location) => location.location_id === primaryLocationId)?.[
        reportItemsDetails.catchmentType.toLowerCase() as 'home' | 'work'
      ].points || [];

    const filteredPoints = points.filter(
      (item) =>
        (item.value || 0) <= reportItemsDetails.catchmentTreshold.max &&
        (item.value || 0) >= reportItemsDetails.catchmentTreshold.min,
    );

    return filteredPoints;
  }, [selection.selectedReportItem, reportItemsDetails.catchmentTreshold]);

  return { points, postalCodesPoints };
};
