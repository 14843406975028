import React from 'react';
import { ConnectionSides, Item, ItemInformation } from '../ItemInformation';
import styles from './style.module.scss';
import { getYPosition } from '../utils';
import { getTitlePositionAccordingToCount } from '../utils/getTitlePositionAccordingToCount';

interface Props {
  items: ItemInformation[];
  connectionSide: ConnectionSides;
  title: string;
}
export const Column: React.FC<Props> = ({ items, connectionSide, title }) => {
  const size = 400;

  const titleYPosition = getTitlePositionAccordingToCount(items.length);
  return (
    <div className={`${styles.column} ${styles[connectionSide]}`}>
      <p className={`${styles.title} ${styles[connectionSide]}`} style={{ top: `${titleYPosition}px` }}>
        {title}
      </p>
      <div>
        {items.map(({ name, value }, index) => {
          const yPosition = getYPosition(index, size, items.length);
          return (
            <div
              className={styles.item}
              style={{
                top: `${yPosition - 15}px`,
              }}
            >
              <Item title={name} value={value} connectionSide={connectionSide} index={index} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
