import { toFixNumber } from '../lib';

function intlFormat(number: number) {
  return new Intl.NumberFormat().format(toFixNumber((number * 10) / 10, 1));
}

export const formatNumberToReadableFormat = (number?: number) => {
  if (!number) return 0;
  if (number >= 1000000) return `${intlFormat(number / 1000000)}M`;
  if (number >= 1000) return `${intlFormat(number / 1000)}k`;

  return intlFormat(number);
};
