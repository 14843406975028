import React from 'react';

export const DownloadIcon: React.FC = () => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.11742 4.23529L7.11742 0L2.88212 0L2.88212 4.23529H0.0585937L4.99977 9.17647L9.94095 4.23529H7.11742ZM4.99977 7.17882L3.46801 5.64706L4.29389 5.64706L4.29389 1.41176L5.70565 1.41176L5.70565 5.64706H6.53154L4.99977 7.17882ZM0.0585937 10.5882L9.94095 10.5882V12L0.0585937 12L0.0585937 10.5882Z"
      fill="white"
    />
  </svg>
);
