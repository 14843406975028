import { GeoPoint } from './types/location';

export function transformWktPolygonToGeoPoints(wktPolygon: string): GeoPoint[] {
  try {
    return wktPolygon
      .trim()
      .replace('POLYGON ((', '')
      .replace('))', '')
      .split(',')
      .map((s) => {
        const pair = s.split(' ');
        return {
          longitude: pair[pair.length - 2],
          latitude: pair[pair.length - 1],
        };
      });
  } catch (e) {
    console.error('Could not transform WKT to geo points: ', e);
    return [];
  }
}

export const isWKTFormat = (polygon: string): boolean => {
    const normalizedPolygon = polygon.trim().toUpperCase();
    return normalizedPolygon.startsWith("POLYGON") || normalizedPolygon.startsWith("MULTIPOLYGON");
}