import { RefObject, useEffect, useState } from 'react';
import { PeriodsEnum } from '../types';
import { useWindowSize } from '../../hooks';

export const useGraphHeight = (wrapperRef: RefObject<HTMLDivElement>, period: PeriodsEnum, locationsCount: number) => {
  const [graphHeight, setGraphHeight] = useState<string>('');
  const { height } = useWindowSize();

  useEffect(() => {
    const element = wrapperRef.current;

    if (element && height) {
      const BOTTOM_GRAPH_HEIGHT = 43;
      const offset = element.getBoundingClientRect().top + BOTTOM_GRAPH_HEIGHT;

      setGraphHeight(`${height - offset}px`);
    }
  }, [locationsCount, period, height]);

  return { graphHeight };
};
