import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { loginSuccess } from '../../../../actions/actions';

const LoginAsPage = () => {
  const { token } = useParams<{ token: string }>();

  const dispatch = useDispatch();
  const history = useHistory();

  if (token && token !== '') {
    dispatch(loginSuccess(token));
    history.push('/');
  } else {
    localStorage.clear();
  }

  return <></>;
};

export default LoginAsPage;
