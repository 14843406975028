import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../common/tooltip';
import { TooltipPositionCoordinates } from '../../../../../common/reportItems/hooks';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  position?: TooltipPositionCoordinates;
}

export const SeasonalityMainTooltip: React.FC<Props> = ({ onClose, isOpen, position }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      onClose={onClose}
      title={t('reportItem.howItWorks')}
      isOpen={isOpen}
      initialPosition={position}
      height={350}
    >
      <p>
        {t('seasonality.tooltip.paragraph1')}
        <br />
        {t('seasonality.tooltip.paragraph2')}
        <br />
        {t('seasonality.tooltip.paragraph3')}
      </p>
      <p>
        {t('seasonality.tooltip.exampleTitle')}:
        <i>
          <br />
          {t('seasonality.tooltip.exampleParagraph1')}
        </i>
      </p>
    </Tooltip>
  );
};
