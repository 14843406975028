import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useFormik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import ReCaptchaV2 from 'react-google-recaptcha';
import { validationSchema } from './schemes';
import styles from './style.module.scss';
import { Field, InputTypesEnum } from '../../../../common/inputs';
import { Credentials } from '../interfaces';
import { Checkbox } from '../../../../common/controls/checkbox';
import { GOOGLE_RECAPTCHA_KEY } from '../../../../../api';

interface Props {
  onSubmit: (data: Credentials) => void;
  error: string;
}
export const Form = forwardRef<{ submit: VoidFunction | null }, Props>(({ onSubmit, error }, ref) => {
  const { handleSubmit, handleChange, values, errors, submitForm, touched, setFieldValue, setTouched, setErrors } =
    useFormik({
      initialValues: {
        name: '',
        company: '',
        role: '',
        email: '',
        phone: '',
        password: '',
        confirmedPassword: '',
        acceptedUsingInformation: false,
        agreedWithPrivacy: false,
        recaptcha: false,
      },
      validationSchema,
      onSubmit,
    });

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const formRef = useRef<HTMLFormElement>(null);

  /**
   * Checks if errors are thrown & assigns variables for thrown errors
   */
  useEffect(() => {

    if (error === 'Please provide a first and last name') {
      setErrors({ name: error });
      return;
    }

    if (error === 'Account name taken') {
      setErrors({ company: error });
      return;
    }

    if (error === 'This email already exists in the system, please choose a different email address') {
      setErrors({ email: 'The email is already taken' });
    }

    if (error.indexOf('Illegal email:') !== -1) {
      setErrors({ email: 'Please provide a valid email address' });
      return;
    }

    if (error.indexOf('Illegal phone number:') !== -1) {
      setErrors({ phone: 'Please provide a valid phone number' });
      return;
    }

    if (error.indexOf(':') !== -1) {
      setErrors({ [error.substring(0, error.indexOf(':'))]: error.substring(error.indexOf(':') + 1, error.length) });
    }
  }, [error]);

  useImperativeHandle(ref, () => ({
    submit: submitForm,
  }));

  const onChangeRecaptcha = () => {
    setFieldValue('recaptcha', !values.recaptcha);
    setTouched({ ...touched, recaptcha: true });
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form} ref={formRef}>
      <div className={styles.wrapper}>
        <div className={styles.fields}>
          <div>
            <Field
              name="name"
              onChange={handleChange}
              value={values.name}
              id="name"
              label="Name"
              maxLabelWidth="64px"
              error={touched.name && errors.name ? errors.name : ''}
              fieldType={InputTypesEnum.TEXT}
            />
          </div>{' '}
          <div>
            <Field
              name="company"
              onChange={handleChange}
              value={values.company}
              id="company"
              label="Company"
              maxLabelWidth="64px"
              error={touched.company && errors.company ? errors.company : ''}
              fieldType={InputTypesEnum.TEXT}
            />
          </div>{' '}
          <div>
            <Field
              name="role"
              onChange={handleChange}
              value={values.role}
              id="role"
              label="Role"
              maxLabelWidth="64px"
              error={touched.role && errors.role ? errors.role : ''}
              fieldType={InputTypesEnum.TEXT}
            />
          </div>{' '}
          <div>
            <Field
              name="email"
              onChange={handleChange}
              value={values.email}
              id="email"
              label="Email"
              maxLabelWidth="64px"
              error={touched.email && errors.email ? errors.email : ''}
              fieldType={InputTypesEnum.TEXT}
            />
          </div>{' '}
          <div>
            <Field
              name="phone"
              onChange={handleChange}
              value={values.phone}
              id="phone"
              label="Phone"
              maxLabelWidth="64px"
              error={touched.phone && errors.phone ? errors.phone : ''}
              fieldType={InputTypesEnum.TEXT}
            />
          </div>
          <div>
            <Field
              name="password"
              onChange={handleChange}
              value={values.password}
              id="password"
              label="Password"
              maxLabelWidth="64px"
              error={touched.password && errors.password ? errors.password : ''}
              fieldType={InputTypesEnum.PASSWORD}
            />
          </div>
          <div>
            <Field
              name="confirmedPassword"
              onChange={handleChange}
              value={values.confirmedPassword}
              id="confirmedPassword"
              label="Confirmed Password"
              maxLabelWidth="64px"
              error={touched.confirmedPassword && errors.confirmedPassword ? errors.confirmedPassword : ''}
              fieldType={InputTypesEnum.PASSWORD}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.column} ${styles.right}`}>
        <div className={styles.additional}>
          <div className={styles.info}>
            <Checkbox
              isChecked={values.acceptedUsingInformation}
              name="acceptedUsingInformation"
              onChange={handleChange}
            />
            <p>By accepting, I agree that my information may be used for marketing campaigns</p>
          </div>
          {errors.acceptedUsingInformation && touched.acceptedUsingInformation && (
            <p className={styles.error}>{errors.acceptedUsingInformation}</p>
          )}
        </div>{' '}
        <div className={styles.additional}>
          <div className={styles.info}>
            <Checkbox isChecked={values.agreedWithPrivacy} name="agreedWithPrivacy" onChange={handleChange} />
            <p>
              By submitting this form, I confirm that I consent to the processing of my personal data for the purpose of
              this form in accordance with the{' '}
              <a href="" className={styles.link}>
                Placesense Privacy Notice.
              </a>
            </p>
          </div>
          {errors.agreedWithPrivacy && touched.agreedWithPrivacy && (
            <p className={styles.error}>{errors.agreedWithPrivacy}</p>
          )}
        </div>
        <div className={styles.recaptcha}>
          <ReCaptchaV2 ref={recaptchaRef} sitekey={GOOGLE_RECAPTCHA_KEY} onChange={onChangeRecaptcha} />
          {errors.recaptcha && touched.recaptcha && <p className={styles.error}>{errors.recaptcha}</p>}
        </div>
      </div>
    </form>
  );
});
