import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Page from '../../templates/page/page';
import ChangePasswordForm from '../../organisms/ChangePasswordForm';
import { changePassword } from '../../../actions/actions';

const ChangeProjectPage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const changePasswordSuccessHandler = (): void => {
    localStorage.clear();
    history.push('/');
    window.location.reload();
  };

  const changePasswordHandler = (currentPassword: string, newPassword: string, reTypedNewPassword: string): void => {
    dispatch(changePassword(currentPassword, newPassword, reTypedNewPassword, changePasswordSuccessHandler));
  };

  return (
    <Page title="Change password">
      <ChangePasswordForm onChange={changePasswordHandler} />
    </Page>
  );
};

export default ChangeProjectPage;
