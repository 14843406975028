import React, { Component } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import NewIcon from '@material-ui/icons/AddCircle';
import ExitIcon from '@material-ui/icons/ExitToApp';
import AppsIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/List';
import PlaceIcon from '@material-ui/icons/Place';
import GroupIcon from '@material-ui/icons/Group';
import MonitoringIcon from '@material-ui/icons/Score';
import RISettingsIcon from '@material-ui/icons/Settings';
import classnames from 'classnames';
import { VpnKey } from '@material-ui/icons';
import routes from '../../../router/routes';
import styles from './navigation.module.css';

type PathParams = { projectId: string };

class Navigation extends Component<RouteComponentProps<PathParams>> {
  render() {
    const { projectId } = this.props.match.params;
    return (
      <div className={styles.container}>
        {this.buildMainMenu(projectId)}
        <div className={styles.footer}>
          <hr className={styles.menuItemsDelimiter} />
          {this.changePassword()}
          <hr className={styles.menuItemsDelimiter} />
          <div className={styles.headerUser}>{this.logoutItem(logout)}</div>
        </div>
      </div>
    );

    function logout(): void {
      localStorage.clear();
      window.location.reload();
    }
  }

  private buildMainMenu(projectId: string) {
    return (
      <ul className={classnames(styles.headerNavList, styles.headerNavListMain)}>
        <li className={styles.headerNavItem}>
          <NavLink
            className={styles.headerNavLink}
            activeClassName={styles.headerNavLinkActive}
            to={routes.users.list}
            title="Users"
          >
            <GroupIcon />
            <span className={styles.textItem}>
              <strong>Users</strong>
            </span>
          </NavLink>
        </li>
        <li className={styles.headerNavItem}>
          <NavLink
            className={styles.headerNavLink}
            activeClassName={styles.headerNavLinkActive}
            to={routes.accounts.list}
            title="Accounts"
          >
            <GroupIcon />
            <span className={styles.textItem}>
              <strong>Accounts</strong>
            </span>
          </NavLink>
        </li>
        <li className={styles.headerNavItem}>
          <NavLink
            className={styles.headerNavLink}
            activeClassName={styles.headerNavLinkActive}
            to="/reportItemSettingDefinitions"
            title="Report Item Settings"
          >
            <RISettingsIcon />
            <span className={styles.textItem}>
              <strong>Report Item Settings</strong>
            </span>
          </NavLink>
        </li>

        <hr className={styles.menuItemsDelimiter} />

        <li className={styles.headerNavItem}>
          <NavLink
            className={styles.headerNavLink}
            activeClassName={styles.headerNavLinkActive}
            exact
            to={routes.projects.list}
            title="Projects"
          >
            <AppsIcon />
            <span className={styles.textItem}>
              <strong>Projects</strong>
            </span>
          </NavLink>
        </li>
        <li className={styles.headerNavItem}>
          <NavLink
            className={styles.headerNavLink}
            activeClassName={styles.headerNavLinkActive}
            to="/createProject"
            title="Create Project"
          >
            <NewIcon />
            <span className={styles.textItem}>
              <strong>Create Project</strong>
            </span>
          </NavLink>
        </li>

        <hr className={styles.menuItemsDelimiter} />

        <li className={styles.headerNavItem}>
          <NavLink
            className={styles.headerNavLink}
            activeClassName={styles.headerNavLinkActive}
            exact
            to={routes.customerPortalView.view}
            title="Customer portal view"
          >
            <AppsIcon />
            <span className={styles.textItem}>
              <strong>Customer portal view</strong>
            </span>
          </NavLink>
        </li>

        {this.buildMenuItemReports(projectId)}
        {this.buildMenuItemCreateReport(projectId)}
        {this.buildMenuItemPoiManagement(projectId)}
        {this.buildMenuItemReportItemMonitoring(projectId)}
      </ul>
    );
  }

  private buildMenuItemReports(projectId: string) {
    const target = routes.reports.list.withId(projectId);
    return projectId ? (
      <li className={styles.headerNavItem}>
        <NavLink
          className={styles.headerNavLink}
          activeClassName={styles.headerNavLinkActive}
          to={target}
          title="Reports"
        >
          <ListIcon />
          <span className={styles.textItem}>
            <strong>Reports</strong>
          </span>
        </NavLink>
      </li>
    ) : (
      ''
    );
  }

  private buildMenuItemCreateReport(projectId: string) {
    const target = routes.reports.create.withId(projectId);
    return projectId ? (
      <li className={styles.headerNavItem}>
        <NavLink
          className={styles.headerNavLink}
          activeClassName={styles.headerNavLinkActive}
          to={target}
          title="Create Report"
        >
          <NewIcon />
          <span className={styles.textItem}>
            <strong>Create Report</strong>
          </span>
        </NavLink>
      </li>
    ) : (
      ''
    );
  }

  private buildMenuItemPoiManagement(projectId: string) {
    const target = `/projects/${projectId}/poiManagement`;
    return projectId ? (
      <li className={styles.headerNavItem}>
        <NavLink
          className={styles.headerNavLink}
          activeClassName={styles.headerNavLinkActive}
          to={target}
          title="POI Management"
        >
          <PlaceIcon />
          <span className={styles.textItem}>
            <strong>POI Management</strong>
          </span>
        </NavLink>
      </li>
    ) : (
      ''
    );
  }

  private buildMenuItemReportItemMonitoring(projectId: string) {
    const target = `/projects/${projectId}/reportItemMonitoring`;
    return projectId ? (
      <li className={styles.headerNavItem}>
        <NavLink
          className={styles.headerNavLink}
          activeClassName={styles.headerNavLinkActive}
          to={target}
          title="Report Item Monitoring"
        >
          <MonitoringIcon />
          <span className={styles.textItem}>
            <strong>Report Item Monitoring</strong>
          </span>
        </NavLink>
      </li>
    ) : (
      ''
    );
  }

  private changePassword() {
    return (
      <NavLink
        className={styles.headerNavLinkChangePassword}
        to={routes.profile.changePassword}
        title="Change password"
      >
        <VpnKey />
        <span>
          <strong className={styles.textItem}>Change password</strong>
        </span>
      </NavLink>
    );
  }

  private logoutItem(logout: () => void) {
    return (
      <NavLink className={styles.headerNavLinkLogout} to="/login" title="Log Out" onClick={() => logout()}>
        <ExitIcon />
        <span>
          <strong className={styles.textItem}>Log Out</strong>
        </span>
      </NavLink>
    );
  }
}

export default withRouter(Navigation);
