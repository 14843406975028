import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from './style.module.scss';
import { Field } from '../../../inputs';
import { dataSetsOptions, formats, metricOptions } from './utils';
import { DropdownTypesEnum } from '../../../controls/dropdown';
import { RadioButton } from '../../../controls';
import { MainContext } from '../../../types/mainContext';
import { Option } from '../../../controls/dropdown/types';
import { Button } from '../../../controls/buttons';
import { Options } from '../interfaces';
import { CheckboxDropdown } from '../../../controls/checkboxDropdown';
import Dropdown from '../../../controls/dropdown/dropdown';
import { reportItems } from '../../../reportItems/utils';
import { mapReportItemTypeToHumanReadableName } from '../../../maps/SelectReportItemPanel/mappers';

interface Props {
  onClose: VoidFunction;
  onExport: (data: Options) => void;
}

const initialOptions = {
  metric: [],
  dataSet: [],
  format: formats[3].value,
};
export const MenuSettings: React.FC<Props> = ({ onClose, onExport }) => {
  const {
    selection: { selectedReport },
  } = useContext(MainContext);

  const [options, setOptions] = useState<Options>(initialOptions);

  const onChangeFormat = (id: string) => {
    setOptions((prevState) => ({ ...prevState, format: id }));
  };

  const onChange = (type: keyof typeof options) => (options: string[]) => {
    setOptions((prevState) => ({ ...prevState, [type]: options }));
  };

  const onChangeDropdown = (type: keyof typeof options) => (option: Option<null>) => {
    setOptions((prevState) => ({ ...prevState, [type]: option.id }));
  };

  const onCancel = () => {
    onClose();
    setOptions(initialOptions);
  };

  const onSubmit = () => {
    onExport(options);
  };

  useEffect(() => {
    const reportItemsTypes = selectedReport?.report_items.map(({ type }) => type);
    const dataSets = dataSetsOptions.map(({ id }) => id);
    if (reportItemsTypes) {
      setOptions((prevState) => ({ ...prevState, metric: reportItemsTypes, dataSet: dataSets }));
    }
  }, [selectedReport?.report_items]);

  const availableReportItemsOptions = useMemo(() => {
    const availableReportItems = selectedReport?.report_items.map(({ type }) => type);
    return reportItems
      .filter((reportItem) => availableReportItems?.includes(reportItem.type))
      .map(({ type }) => ({
        id: type,
        name: mapReportItemTypeToHumanReadableName(type),
        selected: options.metric.includes(type),
      }));
  }, [selectedReport, options.metric]);

  const { dataSet, format } = options;

  const availableDataSetsOptions = useMemo(
    () =>
      dataSetsOptions.map(({ id, name }) => ({
        id,
        name,
        selected: options.dataSet.includes(id),
      })),
    [dataSet],
  );

  const availableFormats = useMemo(() => {
    if (dataSet.includes(dataSetsOptions[1].id) || dataSet.includes(dataSetsOptions[0].id)) {
      return formats.filter(({ value }) => value !== 'csv');
    }

    return formats.filter(({ value }) => value === 'csv');
  }, [dataSet]);

  useEffect(() => {
    setOptions((prevState) => {
      const isPrevFormatAvailable =
        availableFormats.map(({ value }) => value).filter((value) => value === prevState.format).length > 0;
      return { ...prevState, format: isPrevFormatAvailable ? prevState.format : availableFormats[0].value };
    });
  }, [availableFormats]);

  return (
    <div className={styles.menu}>
      <div className={styles.block}>
        <h4 className={styles.title}>Settings</h4>
        <div className={styles.fields}>
          <div className={styles.field}>
            <label>Select metrics</label>
            <CheckboxDropdown
              items={availableReportItemsOptions}
              onSave={onChange('metric')}
              selectAllTitle="Select all metrics"
              title="Metrics"
            />
          </div>
          <div className={styles.field}>
            <label>Select data sets</label>
            <CheckboxDropdown
              items={availableDataSetsOptions}
              onSave={onChange('dataSet')}
              selectAllTitle="Select all data sets"
              title="Metrics"
            />
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <h4 className={styles.title}>Format</h4>
        <div className={styles.fields}>
          {availableFormats.map(({ label, value }) => (
            <div className={styles.row}>
              <label htmlFor={value}>{label}</label>
              <RadioButton id={value} isChecked={value === format} onChange={onChangeFormat} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.actions}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button layout="primary" onClick={onSubmit} animation>
          Export
        </Button>
      </div>
    </div>
  );
};
