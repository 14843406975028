import React, { useState } from 'react';
import styles from './locations.module.scss';
import { ArrowDownIcon } from '../icons/dropdown';
import LocationsList from './locationsList';

interface TProps {
  locations: any[];
  selectedLocations: any[];
  onChange: (locations: any[]) => void;
}

const LocationsDropdown = ({ locations, selectedLocations, onChange }: TProps) => {
  const [isOpened, toggleOpened] = useState<boolean>(false);

  const onDropdownBlur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      toggleOpened(false);
    }
  };

  const onClose = () => {
    toggleOpened(false);
  };

  return (
    <div className={styles.locationsDropdown} onBlur={onDropdownBlur} tabIndex={0}>
      <div className={styles.activeLocations} onClick={() => toggleOpened(!isOpened)}>
        <span className={styles.name}>
          {selectedLocations.length
            ? `${selectedLocations[0].name} ${
                selectedLocations.length - 1 ? `(+${selectedLocations.length - 1} more)` : ''
              }`
            : 'No selected location'}
        </span>
        <button type="button">
          <ArrowDownIcon />
        </button>
      </div>
      {isOpened && (
        <LocationsList
          locations={locations}
          selectedLocations={selectedLocations}
          onChange={onChange}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default LocationsDropdown;
