import React from 'react';
import { MapPopupData } from '../../../../../common/types/visualizationObjects';
import styles from '../../../../absorption/card/tooltips/map/style.module.scss';
import { toFixNumber } from '../../../../../common/lib';
import AbsorptionCardChart from '../../../../absorption/card/AbsorptionCardChart';
import { AbsorptionBriefData, AttractionBriefData } from '../../../../model/interfaces';
import { AttractionData } from '../../../../../common/types/projectsHierarchy';

interface Props {
  data: MapPopupData;
}
export const MapTooltip: React.FC<Props> = ({ data }) => {
  const { visitsFromAOI } = data.data as AttractionBriefData;

  return (
    <>
      <div className={styles.information}>
        <p className={styles.label}>Share of visits</p>
        <p className={styles.value}>{visitsFromAOI || 0}%</p>
      </div>
      <AbsorptionCardChart visitsFromAOIPercentage={visitsFromAOI || 0} isLoading={false} />
    </>
  );
};
