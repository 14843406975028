import { API_URLS } from '../../routes';
import { request } from '../../api';
import { ReportItemSettings, REQUEST_DELAY } from '../types';
import { AttractionDataResponse } from './types';
import { LocationInfo, ReportItemInfo, ReportItems, SelectedTimeFrames } from '../../common/types/projectsHierarchy';
import { mapResponse } from './mapper';
import { ReportItemType } from '../../common/reportItems/types';
import alphabeticalSort from '../absorption/lib/alphabeticalSort';

const fetchData = async (reportItemId: string, timeFrames: string[] | null) => {
  const timeFramesQueryParam = timeFrames !== null ? `timeFrames=${timeFrames.join(',')}` : '';
  const queryParams = [timeFramesQueryParam].join('&');

  const url = `${API_URLS.REPORT_ITEMS_DATA}/${reportItemId}/data?${queryParams}`;

  return request<AttractionDataResponse>({ url });
};

export const getAttractionData = async (
  reportItemId: string,
  reportItemName: string,
  timeFrames: SelectedTimeFrames,
  updateReportItemOfSelectedReport: (item: ReportItemInfo<ReportItems>) => void,
  setFetched: (value: boolean) => void,
  setIsLoading: (value: boolean) => void,
  projectLocations: LocationInfo[],
  selectedReportItem: ReportItemInfo<ReportItems> | null,
) => {
  try {
    setIsLoading(true);
    if (selectedReportItem?.type !== ReportItemType.ATTRACTION) {
      await new Promise((resolve) => setTimeout(resolve, REQUEST_DELAY));
    }
    const responseData = await fetchData(reportItemId, timeFrames);

    if (responseData.values.length > 0) {
      const data = mapResponse(responseData, projectLocations);
      updateReportItemOfSelectedReport(data);
    } else {
      updateReportItemOfSelectedReport({
        id: reportItemId,
        name: reportItemName,
        type: ReportItemType.ATTRACTION,
        data: null,
        visualization: null,
      });
    }
  } catch (e) {
    updateReportItemOfSelectedReport({
      id: reportItemId,
      name: reportItemName,
      type: ReportItemType.ATTRACTION,
      data: null,
      visualization: null,
    });
  } finally {
    setFetched(true);
    setIsLoading(false);
  }
};

export const getSourceLocations = async (
  reportItemId: string,
  changeSourceLocations: (ids: string[]) => void,
): Promise<string[]> => {
  const url = `${API_URLS.REPORT_ITEMS_DATA}/${reportItemId}`;
  const res = await request<ReportItemSettings>({ url });
  const rawSourceLocationIds = res.settings.find(({ name }) => name === 'aois');
  if (rawSourceLocationIds) {
    const locations = rawSourceLocationIds.value.split(';');
    changeSourceLocations(locations);
    return locations;
  }
  changeSourceLocations([]);
  return [];

  return [];
};
