import React from 'react';
import styles from './style.module.scss';

interface Props {
  image: string;
  title: string;
}
export const Center: React.FC<Props> = ({ image, title }) => (
  <div className={styles.wrapper}>
    <div className={styles.circle}>
      <div className={styles.image}>
        <img src={image} alt={title} />
      </div>
    </div>
    <p className={styles.title}>{title}</p>
  </div>
);
