import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { getEditHandleStyle, getFeatureStyle } from './style';
import { ObjectSignature } from '../../types/ObjectSignature.interface';
import { Marker } from 'react-map-gl';
import { EditTools } from '../polygonTool/editTools';
import { addNumbersToPolygons, addNumberToPolygon } from '../polygonTool/utils';

interface EditorProps {
  polygon: GeoJSON.Feature<GeoJSON.Polygon>[];
  setPolygon: (polygon: GeoJSON.Feature<GeoJSON.Polygon>[]) => void;
  styles?: ObjectSignature;
}

type Mode = EditingMode | DrawPolygonMode | undefined;

export interface EditorHandlers {
  drawMode: VoidFunction;
}
export const PolygonEditor = forwardRef<EditorHandlers, EditorProps>(({ polygon, setPolygon, styles }, ref) => {
  const [selectedPolygon, setSelectedPolygon] = useState<GeoJSON.Feature<GeoJSON.Polygon> | null>(
    polygon.length > 0 ? polygon[0] : null,
  );
  const [mode, setMode] = useState<Mode>(polygon.length > 0 ? new EditingMode() : new DrawPolygonMode());

  const editorRef = useRef<Editor>(null);

  useImperativeHandle(ref, () => ({
    drawMode: () => {
      setMode(new DrawPolygonMode());
    },
  }));

  useEffect(() => {
    if (polygon) {
      if (mode?.constructor !== EditingMode) setMode(new DrawPolygonMode());

      const currentSelected = polygon.find(({ id }) => selectedPolygon?.id === id);
      if (!currentSelected) setSelectedPolygon(null);
    }
    if (!polygon && editorRef.current) {
      setMode(new DrawPolygonMode());
    }
  }, [polygon, setMode]);

  const onUpdateHandler = useCallback(
    (e: any) => {
      if (e?.editType === 'addFeature') {
        setMode(new EditingMode());
      }

      if (e.data) {
        const data = e.data as GeoJSON.Feature<GeoJSON.Polygon>[];

        data.forEach((_, index: number) => {
          const element = data[index];
          addNumberToPolygon(element, index + 1);
          if (!element.id) element.id = Date.now();
        });

        const updatedSelected = data.find((element) => element.id === selectedPolygon?.id);

        if (updatedSelected) setSelectedPolygon(updatedSelected);

        setPolygon(data);
      }
    },
    [setMode, setPolygon, selectedPolygon],
  );

  const editToolsPoint = useMemo(() => {
    if (selectedPolygon && selectedPolygon.geometry.type === 'Polygon') {
      const coordinates = [...selectedPolygon?.geometry.coordinates[0]];
      const [first, second] = coordinates?.sort((a, b) => a[1] - b[1]);
      const latitude = (first[1] + second[1]) / 2;
      const longitude = (first[0] + second[0]) / 2;
      return [longitude, latitude];
    }
    return null;
  }, [selectedPolygon]);

  const onEdit = () => {
    if (mode?.constructor !== EditingMode) setMode(new EditingMode());
    else setMode(new DrawPolygonMode());
  };

  const onRemove = () => {
    const withoutRemovedPolygon = polygon.filter(({ id }) => selectedPolygon?.id !== id);
    setPolygon(addNumbersToPolygons(withoutRemovedPolygon));
    setSelectedPolygon(null);
  };

  const onSelect = (data: { selectedFeature: GeoJSON.Feature<GeoJSON.Polygon> }) => {
    setSelectedPolygon(data.selectedFeature);
  };

  return (
    <>
      {editToolsPoint?.[0] && (
        <Marker longitude={editToolsPoint[0] - 0.01} latitude={editToolsPoint[1] - 0.01}>
          <EditTools onEdit={onEdit} onTrash={onRemove} number={selectedPolygon?.properties?.number} />
        </Marker>
      )}
      <Editor
        ref={editorRef}
        style={{ width: '100%', height: '100%', ...styles }}
        clickRadius={12}
        mode={mode}
        onUpdate={onUpdateHandler}
        editHandleShape="circle"
        featureStyle={getFeatureStyle}
        editHandleStyle={getEditHandleStyle}
        featuresDraggable
        features={polygon as any}
        onSelect={onSelect}
      />
    </>
  );
});
