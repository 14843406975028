import React from 'react';
import { SmallLogoIcon } from '../../../icons/chart';
import styles from './style.module.scss';
import { ConnectionSides } from './interfaces';

interface Props {
  title: string;
  value: string;
  connectionSide: ConnectionSides;
  index: number;
}

export const Item = ({ title, value, connectionSide = 'after' }: Props) => (
  <div
    className={`${styles.wrapper} ${
      connectionSide === 'after' ? styles.after : connectionSide === 'before' ? styles.before : styles.during
    }`}
  >
    <div className={styles.information} title={title}>
      <SmallLogoIcon />
      <p className={styles.title}>{title}</p>
      <span className={styles.value}>{value}</span>
    </div>
  </div>
);
