import React, { useContext } from 'react';
import { useStore } from 'effector-react';
import { MixCircleClouds } from '../common/loaders';
import { dashboardModel } from './model';
import { MainContext } from '../common/types/mainContext';
import { ContentVisualizationMode } from '../common/types/projectsHierarchy';

export const DashboardLoader = () => {
  const { contentVisualizationMode } = useContext(MainContext);
  const isGlobalLoading = useStore(dashboardModel.$isLoading);

  if (isGlobalLoading && contentVisualizationMode === ContentVisualizationMode.MAP) {
    return <MixCircleClouds />;
  }

  return <></>;
};
