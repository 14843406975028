import React from 'react';

export const ChangePasswordIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4H16V12H12V13.6C12 13.8122 12.0843 14.0157 12.2343 14.1657C12.3843 14.3157 12.5878 14.4 12.8 14.4H14.4V16H12.4C11.96 16 11.2 15.64 11.2 15.2C11.2 15.64 10.44 16 10 16H8V14.4H9.6C9.81217 14.4 10.0157 14.3157 10.1657 14.1657C10.3157 14.0157 10.4 13.8122 10.4 13.6V2.4C10.4 2.18783 10.3157 1.98434 10.1657 1.83431C10.0157 1.68429 9.81217 1.6 9.6 1.6H8V0H10C10.44 0 11.2 0.36 11.2 0.8C11.2 0.36 11.96 0 12.4 0H14.4V1.6H12.8C12.5878 1.6 12.3843 1.68429 12.2343 1.83431C12.0843 1.98434 12 2.18783 12 2.4V4ZM0 4H8.8V5.6H1.6V10.4H8.8V12H0V4ZM14.4 10.4V5.6H12V10.4H14.4ZM5.2 8C5.2 7.68174 5.07357 7.37652 4.84853 7.15147C4.62348 6.92643 4.31826 6.8 4 6.8C3.68174 6.8 3.37652 6.92643 3.15147 7.15147C2.92643 7.37652 2.8 7.68174 2.8 8C2.8 8.31826 2.92643 8.62348 3.15147 8.84853C3.37652 9.07357 3.68174 9.2 4 9.2C4.31826 9.2 4.62348 9.07357 4.84853 8.84853C5.07357 8.62348 5.2 8.31826 5.2 8ZM8.8 7.112C8.312 6.664 7.552 6.704 7.104 7.2C6.656 7.68 6.696 8.44 7.2 8.888C7.64 9.304 8.344 9.304 8.8 8.888V7.112Z"
      fill="white"
    />
  </svg>
);

export default ChangePasswordIcon;
