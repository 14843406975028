import React from 'react';
import SeasonalityCardMostPopularDay from '../../seasonalityCardMostPopularDay';
import SeasonalityCardChart from '../../SeasonalityCardChart';
import { mostPopularHourMock } from '../../../mockData';
import styles from './style.module.scss';
import { MapPopupData } from '../../../../../common/types/visualizationObjects';
import { SeasonalityBriefData } from '../../../../model/interfaces';

interface Props {
  data: MapPopupData;
}
export const MapTooltip: React.FC<Props> = ({ data }) => {
  const { chartData, popularHour, popularDay } = data.data as SeasonalityBriefData;

  return (
    <div>
      <div className={styles.information}>
        <div className={styles.row}>
          <p className={styles.label}>Popular day & hour</p>
          <p className={styles.value}>{`${popularDay} ${popularHour}`}</p>
        </div>
      </div>
      <div className={styles.chart}>
        <SeasonalityCardMostPopularDay dayOfWeek={chartData.dayOfWeek || 1} isLoading={false} />
        <SeasonalityCardChart
          data={chartData.average}
          mostPopularHour={chartData.mostPopularHour || mostPopularHourMock}
          isLoading={false}
        />
      </div>
    </div>
  );
};
