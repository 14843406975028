import React from 'react';
import backgroundImage from './PricingPageFull_v2.png';
import styles from './style.module.scss';

export const PricingBackground: React.FC = ({ children }) => (
  // <center>
  <img src={backgroundImage} className={styles.background} />
  // </center>
  //   <div className={styles.background} style={{ backgroundImage: 'url(/img/v2/PricingPageFull.png)' }}>
  //   {children}
  // </div>
);
