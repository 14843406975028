import { useContext, useMemo } from 'react';
import { MainContext } from '../types/mainContext';

export const useTimePeriod = (): string => {
  const {
    selection: { selectedReport },
    selectedTimeFrames,
  } = useContext(MainContext);
  const timeFramesString = useMemo(() => {
    if (selectedReport) {
      if (selectedTimeFrames && selectedTimeFrames.length > 0) {
        return selectedTimeFrames.join(', ');
      }
      return `${selectedReport.start_date} - ${selectedReport.end_date}`;
    }
    return '';
  }, [selectedReport, selectedTimeFrames]);
  return timeFramesString;
};
