import React from 'react';

export const ReportIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.25 2.75H3.75C2.925 2.75 2.25 3.425 2.25 4.25V14.75C2.25 15.575 2.925 16.25 3.75 16.25H14.25C15.075 16.25 15.75 15.575 15.75 14.75V4.25C15.75 3.425 15.075 2.75 14.25 2.75ZM14.25 14.75H3.75V4.25H14.25V14.75Z"
      fill="white"
    />
    <path d="M6.75 9.5H5.25V13.25H6.75V9.5Z" fill="white" />
    <path d="M12.75 5.75H11.25V13.25H12.75V5.75Z" fill="white" />
    <path d="M9.75 11H8.25V13.25H9.75V11Z" fill="white" />
    <path d="M9.75 8H8.25V9.5H9.75V8Z" fill="white" />
  </svg>
);

export default ReportIcon;
