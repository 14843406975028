import { ReportItemType } from '../../reportItems/types';

export const mapReportItemTypeToHumanReadableName = (type: ReportItemType) => {
  switch (type) {
    case ReportItemType.DURATION:
      return 'Visit duration';
    case ReportItemType.FOOTFALL:
      return 'Visit trends';
    case ReportItemType.RETENTION:
      return 'Retention rate';
    case ReportItemType.SEASONALITY:
      return 'Popular day & hour';
    case ReportItemType.CATCHMENT:
      return 'Catchment';
    case ReportItemType.CROSS_VISITATION:
      return 'Cross-visitation';
    case ReportItemType.MOVEMENT:
      return 'Movement';
    case ReportItemType.ABSORPTION:
      return 'Absorption';
    case ReportItemType.ATTRACTION:
      return 'Attraction';
    default:
      return type;
  }
};
