import React from 'react';

export const CSVIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_bd_1760_5432)">
      <rect x="0" y="0" width="24" height="24" rx="12" fill="white" fillOpacity="0.1" shapeRendering="crispEdges" />
      <path
        style={{ transform: 'translate(-10px, -10px)' }}
        d="M23.3 15.5H18.1C17.3785 15.5 16.8 16.085 16.8 16.8V27.2C16.8 27.9215 17.3785 28.5 18.1 28.5H25.9C26.6215 28.5 27.2 27.9215 27.2 27.2V19.4L23.3 15.5ZM25.9 27.2H18.1V16.8H22.65V20.05H25.9V27.2ZM22.585 23.625L24.47 26.55H23.3L22 24.34L20.7 26.55H19.53L21.415 23.625L19.53 20.7H20.7L22 22.91L23.3 20.7H24.47L22.585 23.625Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_bd_1760_5432"
        x="-25"
        y="-25"
        width="94"
        height="94"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="17.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1760_5432" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.113725 0 0 0 0 0.145098 0 0 0 0.25 0" />
        <feBlend mode="overlay" in2="effect1_backgroundBlur_1760_5432" result="effect2_dropShadow_1760_5432" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1760_5432" result="shape" />
      </filter>
    </defs>
  </svg>
);
