import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../tooltip';
import { TooltipPositionCoordinates } from '../../reportItems/hooks';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  position: TooltipPositionCoordinates;
}
const ImportLocationsTooltip: React.FC<Props> = ({ onClose, position, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      onClose={onClose}
      initialPosition={position}
      isOpen={isOpen}
      height={175}
      title={t('edit.CSVImport.importLocations')}
    >
      <p style={{ whiteSpace: 'pre-line' }}>{t('edit.CSVImport.importLocationsTooltip')}</p>
    </Tooltip>
  );
};

export default ImportLocationsTooltip;
