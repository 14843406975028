import { API_URLS } from '../../../routes';
import { request } from '../../request';
import { API_URL } from '../../../../api';
import { PolygonData } from '../../../common/manageLocations/createLocation/interfaces';

export const removeLocations = async (projectId: string, locationsIds: string[]) => {
  const url = `${API_URLS.PROJECT}/${projectId}/locations`;
  return request({
    url,
    method: 'DELETE',
    data: { locationIds: locationsIds },
  });
};
