import JSZip from 'jszip';
import FileSaver from 'file-saver';

export type AddFile = (folder: JSZip, path: string, data: string, options?: JSZip.JSZipFileOptions) => void;

export type ZipInstance = JSZip;

export const createZip = () => {
  const zip = new JSZip();

  const addFolder = (instance: JSZip, name: string) => {
    return instance.folder(name);
  };

  const addFile: AddFile = (folder: JSZip, path: string, data: string, options?: JSZip.JSZipFileOptions) => {
    return folder.file<'base64'>(path, data, options);
  };

  const generate = (instance: JSZip, fileName: string, options: JSZip.JSZipGeneratorOptions) => {
    zip.generateAsync(options).then(function (content) {
      FileSaver.saveAs(content, fileName);
    });
  };

  return {
    zip,
    addFolder,
    addFile,
    generate,
  };
};
