import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dropdown, { Option } from 'react-dropdown';
import styles from './reportsPage.module.css';
import Page from '../../templates/page/page';
import { AppState } from '../../../reducers/reducers';
import {
  changeProjectStatus,
  deleteReport,
  loadLocations,
  loadReports,
  getProjectStatus,
  changeReportStatus,
  clearErrorMessage,
  fetchUsers,
  loadProjects,
  getSelectedUsers,
  saveSelectedUsers,
  saveProjectCollaborationType,
  getProjectCollaborationType,
} from '../../../actions/actions';
import { Report, IReportCategory } from '../../../types/report';
import { Location } from '../../../types/location';
import ConfirmDeleteModal from '../../molecules/ConfirmDeleteModal/ConfirmDeleteModal';
import routes from '../../../router/routes';

import { STATUS } from '../../pages/projectsPage/status';
import ErrorMessage from '../../organisms/errorMessage';
import { CollaborationEnum } from './collaboration';
import MultiUserFilter from '../../molecules/dropdowns/multiUserFilter/multiUserFilter';
import { Project } from '../../../types/project';
import { SharedUser } from '../../../types/SharedUser';
import { MultiOption } from '../../atoms/multiselectWithButtons';

interface State {
  isDeleteReportModalVisible: boolean;
  reportName: string;
  reportId?: string;
  status: string;
  collaborationType: CollaborationEnum;
  selectedCollaborationUsers: SharedUser[];
}

interface StateProps {
  reports?: Report[];
  projects?: Project[];
  locations?: Location[];
}

interface DispatchProps {
  loadReports: (projectId: string) => any;
  loadLocations: (projectId: string) => any;
  deleteReport: (projectId: string, reportId: string) => any;
  changeProjectStatus: (projectId: string, status: string) => any;
  changeReportStatus: (reportId: string, status: string) => any;
  clearErrorMessage: () => void;
  fetchUsers: (accountIds: string[]) => (dispatch: Dispatch) => void;
  loadProjects: (accountIds: string[]) => (dispatch: Dispatch) => void;
}

type PathParams = { projectId: string; reportId: string };

const collaborationOptions = Object.keys(CollaborationEnum);

class ReportsPage extends Component<StateProps & DispatchProps & RouteComponentProps<PathParams>, State> {
  constructor(props: StateProps & DispatchProps & RouteComponentProps<PathParams>) {
    super(props);
    this.state = {
      isDeleteReportModalVisible: false,
      reportName: '',
      reportId: '',
      status: '',
      collaborationType: CollaborationEnum.PUBLIC,
      selectedCollaborationUsers: [],
    };
  }

  projectStatus = async (projectId: string) => {
    try {
      const status = await getProjectStatus(projectId);
      this.setState({ status });
    } catch (error) {
      console.log(error);
    }
  };

  getCurrentProject = () => {
    const { projects } = this.props;
    const { projectId } = this.props.match.params;

    const project = projects?.find((project) => project.id === projectId);
    return project;
  };

  getSelectedAccounts = () => {
    const selectedAccounts: Option[] =
      localStorage.getItem('accountFilter') === null ? [] : JSON.parse(localStorage.getItem('accountFilter') || '');

    return selectedAccounts.map(({ value }) => value);
  };

  getActiveAccountsIds = () => {
    const selectedAccountsIds = this.getSelectedAccounts();
    const project = this.getCurrentProject();

    return project?.account_id ? [project.account_id] : selectedAccountsIds;
  };

  getSelectedUsers = async (projectId: string) => {
    try {
      const users = await getSelectedUsers(projectId);
      this.setState({ selectedCollaborationUsers: users });
    } catch (error) {
      console.log(error);
    }
  };

  getUsersToAccount = async (projectId: string) => {
    const { fetchUsers, loadProjects } = this.props;

    const project = this.getCurrentProject();

    const activeAccountsIds = this.getActiveAccountsIds();
    if (project) {
      fetchUsers(activeAccountsIds);
      await this.getSelectedUsers(projectId);
    } else {
      loadProjects(activeAccountsIds);
    }
  };

  getCollaborationType = async (projectId: string) => {
    try {
      const type = await getProjectCollaborationType(projectId);
      if (type) this.setState({ collaborationType: type as CollaborationEnum });
    } catch (error) {}
  };

  componentDidMount(): void {
    const { projectId } = this.props.match.params;
    this.props.clearErrorMessage();
    this.props.loadReports(projectId);
    this.props.loadLocations(projectId);
    this.projectStatus(projectId);
    this.getUsersToAccount(projectId);
    this.getCollaborationType(projectId);
  }

  componentDidUpdate(
    prevProps: Readonly<StateProps & DispatchProps & RouteComponentProps<PathParams>>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    const { projectId } = this.props.match.params;
    if (prevProps?.projects?.length !== this.props?.projects?.length) {
      this.getUsersToAccount(projectId);
    }
  }

  openDeleteReportModal(e: any, { name, id }: Report): void {
    e.preventDefault();
    this.setState({ isDeleteReportModalVisible: true, reportName: name, reportId: id });
  }

  closeDeleteReportModal(): void {
    this.setState({ isDeleteReportModalVisible: false });
  }

  changeCollaboration = async (option: Option) => {
    const { projectId } = this.props.match.params;
    await saveProjectCollaborationType(projectId, option.value as CollaborationEnum);
    this.setState({ collaborationType: option.value as CollaborationEnum });
  };

  onChangeAccountFilter = async (options: MultiOption[]) => {
    const { projectId } = this.props.match.params;
    const formattedUsers = options.map(({ value, id }) => ({ id, shared: value }));
    await saveSelectedUsers(projectId, formattedUsers);
    await this.getSelectedUsers(projectId);
  };

  modifySharedUsers = () => {
    const { selectedCollaborationUsers } = this.state;
    return selectedCollaborationUsers.map(({ id, shared, name }) => ({ id, value: shared, label: name }));
  };

  render() {
    const { projectId } = this.props.match.params;

    const handleChange = () => {
      this.setState((prevState) => {
        const status =
          prevState.status.toLowerCase() !== STATUS.PRESENTABLE ? STATUS.PRESENTABLE_VALUE : STATUS.READY_VALUE;
        this.props.changeProjectStatus(projectId, status);
        return {
          ...prevState,
          status,
        };
      });
    };
    const modifiedOptions = this.modifySharedUsers();

    return (
      <Page title="Reports">
        <ErrorMessage />
        <div className={styles.options}>
          {(this.state.status.toLowerCase() === STATUS.PRESENTABLE ||
            this.state.status.toLowerCase() === STATUS.READY) && (
            <FormControlLabel
              className={styles.isVisible}
              onClick={(e) => {
                e.preventDefault();
                handleChange();
              }}
              control={<Switch checked={this.state.status.toLowerCase() === STATUS.PRESENTABLE} name="isVisible" />}
              label="Is Visible"
            />
          )}
          <div className={styles.collaborationRow}>
            <div className={styles.fieldName}>Collaboration type:</div>
            <div className={styles.valueContainer}>
              <Dropdown
                options={collaborationOptions}
                onChange={this.changeCollaboration}
                value={this.state.collaborationType}
                placeholder="Select collaboration type"
              />
            </div>
          </div>
          {this.state.collaborationType === CollaborationEnum.PRIVATE && (
            <MultiUserFilter onChange={this.onChangeAccountFilter} options={modifiedOptions} label="Shared with" />
          )}
        </div>
        {this.state.isDeleteReportModalVisible && (
          <ConfirmDeleteModal
            title={`Delete report "${this.state.reportName}"?`}
            isVisible={this.state.isDeleteReportModalVisible}
            onDeleteRedirectURL={routes.reports.list.withId(projectId)}
            onClose={() => this.closeDeleteReportModal()}
            onDelete={() => this.props.deleteReport(projectId, this.state.reportId || '')}
          />
        )}
        <div className={styles.container}>{this.renderReports()}</div>
      </Page>
    );
  }

  private renderReports() {
    const { projectId } = this.props.match.params;

    const isVisibleHandler = (reportId: string, currentStatus: string) => {
      const status = currentStatus.toLowerCase() !== STATUS.PRESENTABLE ? STATUS.PRESENTABLE_VALUE : STATUS.READY_VALUE;
      this.props.changeReportStatus(reportId, status);
    };

    const isDisabled = (report: Report) =>
      !report.status ||
      (report.status !== STATUS.PRESENTABLE_VALUE &&
        report.status !== STATUS.READY_VALUE &&
        report.reportCategory !== IReportCategory.FFI);

    if (this.props.reports && this.props.locations) {
      return this.props.reports.reverse().map((report) => (
        <Link to={`/projects/${projectId}/reports/${report.id}`} className={styles.reportContainer} key={report.id}>
          <div className={styles.report}>
            <div className={styles.heading}>
              <div className={styles.headingActions}>
                <NavLink
                  className={styles.headerNavLink}
                  activeClassName={styles.headerNavLinkActive}
                  to={{
                    pathname: routes.reports.create.withId(projectId),
                    state: { sourceReport: report },
                  }}
                  title="Duplicate Report"
                >
                  <FileCopyIcon className={styles.duplicateReportIcon} fontSize="inherit" />
                </NavLink>
                <DeleteIcon
                  className={styles.optionDeleteIcon}
                  fontSize="inherit"
                  onClick={(e) => this.openDeleteReportModal(e, report)}
                />
              </div>
            </div>
            <div className={styles.reportProperties}>
              <div className={styles.reportProperty}>
                <div className={styles.reportPropertyName}>ID:</div>
                <div className={styles.reportPropertyValue}>{report.id}</div>
              </div>
              <div className={styles.reportProperty}>
                <div className={styles.reportPropertyName}>Name:</div>
                <div className={styles.reportPropertyValue}>{report.name}</div>
              </div>
              {/* <div className={styles.reportProperty}>
                <div className={styles.reportPropertyName}>Description:</div>
                <div className={styles.reportPropertyValue}>{report.description}</div>
              </div> */}
              <div className={styles.reportProperty}>
                <div className={styles.reportPropertyName}>Date:</div>
                <div className={styles.reportPropertyValue}>
                  {report.date && report.date.replace('-', '/').replace('-', '/')}
                </div>
              </div>
              <div className={styles.reportProperty}>
                <div className={styles.reportPropertyName}>Recurring:</div>
                <div className={styles.reportPropertyValue}>{report.recurring ? 'YES' : 'NO'}</div>
              </div>
              {report.recurring && report.recurrence_frequency && (
                <div className={styles.reportProperty}>
                  <div className={styles.reportPropertyName}>Recurrence frequency:</div>
                  <div className={styles.reportPropertyValue}>{report.recurrence_frequency}</div>
                </div>
              )}
              {report.recurring && report.last_updated && (
                <div className={styles.reportProperty}>
                  <div className={styles.reportPropertyName}>Last updated:</div>
                  <div className={styles.reportPropertyValue}>
                    {report.last_updated.replace('-', '/').replace('-', '/')}
                  </div>
                </div>
              )}
              <div className={styles.reportProperty}>
                <div className={styles.reportPropertyName}>Places:</div>
                <div className={styles.reportPropertyValue}>{this.reportPlaces(report.locations)}</div>
              </div>
              <div className={styles.reportProperty}>
                <div className={styles.reportPropertyName}>Status:</div>
                {this.reportStatus(report)}
              </div>
              <FormControlLabel
                className={styles.isVisible}
                onClick={(e) => {
                  e.preventDefault();
                  if (!isDisabled(report)) {
                    isVisibleHandler(report.id || '', report.status || '');
                  }
                }}
                control={
                  <Switch
                    checked={report.status?.toLowerCase() === STATUS.PRESENTABLE}
                    name="isVisible"
                    disabled={isDisabled(report)}
                  />
                }
                label="Is Visible"
              />
            </div>
          </div>
        </Link>
      ));
    }
    return null;
  }

  private reportPlaces(locationsIds: string[]): string {
    if (this.props.locations) {
      return this.props.locations
        .filter((location) => locationsIds.includes(location.id || ''))
        .map((location) => location.name)
        .join(', ');
    }
    return '';
  }

  private reportStatus(report: Report) {
    const isReady =
      (report.status && (report.status === 'READY' || report.status === 'PRESENTABLE')) ||
      !report.reportItems.find((item) => item.status !== 'confirmed');
    const isError = report.reportItems.find((item) => item.status === 'error');
    return (
      <div
        className={classNames({
          [styles.reportPropertyValue]: true,
          [styles.statusReady]: isReady,
          [styles.statusProcessing]: !isReady,
          [styles.statusError]: isError,
        })}
      >
        {isError ? 'ERROR' : isReady || report.reportCategory === 'FFI' ? 'Complete' : 'Processing'}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  reports: state.reports.entries,
  projects: state.projects.entries,
  locations: state.locations.entries,
});

const mapDispatchToProps: DispatchProps = {
  loadReports,
  loadLocations,
  deleteReport,
  changeProjectStatus,
  changeReportStatus,
  clearErrorMessage,
  fetchUsers,
  loadProjects,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsPage as any));
