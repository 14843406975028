import React, { ReactNode } from 'react';
import styles from './reportItemContainer.module.scss';

interface Props {
  children: ReactNode;
}

const ReportItemContainer: React.FC<Props> = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.headerContainer} />
    {children}
  </div>
);

export default ReportItemContainer;
