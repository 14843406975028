import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { DownloadIcon } from '../../../icons/export';
import { useClickOutside, useToggle } from '../../../hooks';
import { MenuSettings } from '../menu';
import { Options } from '../interfaces';
import { exportSettingsModel } from '../model';
import { dataSetsOptions, formats } from '../menu/utils';
import { MainContext } from '../../../types/mainContext';
import { ReportItemType } from '../../../reportItems/types';
import { generatePDF, generateZIP, generatePowerPoint } from './utils';
import { ChartsContainer } from './chartsContainer';
import { CircleLoader } from '../../../loaders';
import { useTimeFrameFormattedLabel } from '../../../YearMonthPicker/hooks';
import { getYearsGap } from '../../../YearMonthPicker/utils';
import { trackUserAction, UserActionsEnum } from '../../../../../mixpanel';
import { generateExcel } from './utils/generateExcel';
import { dashboardModel } from '../../../../dasboardLoader';
import { Skeleton } from '../../../skeleton';
import { CircleButton } from '../../../controls/buttons/circleButton/circleButton';
import { MapContainer } from './mapContainer';
import styles from './style.module.scss';

interface Props {}
export const ExportSettings: React.FC<Props> = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReport },
    primaryLocationId,
    selectedLocations,
  } = useContext(MainContext);
  const [isOpen, toggleOpen] = useToggle(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const exportData = useStore(exportSettingsModel.$exportData);
  const advancedReportItemsInformation = useStore(exportSettingsModel.$advancedReportItemsInformation);
  const exportedFormats = useStore(exportSettingsModel.$exportedFormats);
  const exportedMetrics = useStore(exportSettingsModel.$exportedMetrics);
  const usePDFPreview = useStore(exportSettingsModel.$usePDFPreview);
  const isLoading = useStore(exportSettingsModel.$isLoading);
  const isGlobalLoading = useStore(dashboardModel.$isLoading);
  const {
    user: { companyLogo },
  } = useContext(MainContext);

  const [showChartsPage, toggleShowChartsPage] = useState(false);
  const [showMapPage, toggleShowMapPage] = useState(false);

  const { timeFrames } = useMemo(() => {
    if (selectedReport) {
      const { start_date, end_date } = selectedReport;
      return getYearsGap(start_date, end_date);
    }
    return { timeFrames: {} };
  }, [selectedReport]);

  const { label: timeFrame } = useTimeFrameFormattedLabel({
    onlyString: true,
    timeFrames,
  });

  const primaryLocation = useMemo(
    () => selectedLocations.find(({ id }) => primaryLocationId === id),
    [selectedLocations],
  );

  const onOpen = () => {
    toggleOpen(true);
  };

  const onClose = () => {
    toggleOpen(false);
  };

  const includeForExport = () => {
    const hasCSV = exportedFormats === formats[3].value || exportedMetrics.includes(dataSetsOptions[2].id);
    const hasPDF = exportedFormats === formats[1].value;
    // ZIP - for storing a bunch of images
    const hasZIP = exportedFormats === formats[0].value;

    const hasPowerPoint = exportedFormats === formats[2].value;

    return { csv: hasCSV, pdf: hasPDF, zip: hasZIP, powerPoint: hasPowerPoint };
  };

  useEffect(() => {
    const entries = Object.entries(exportOptions);
    const includedReportItems = entries.filter(([_, data]) => {
      if (data) {
        const { map, table, chart } = data;
        return map || table || chart;
      }
    });

    const entriesInformation = includedReportItems.map(([type, value]) => {
      const data = exportData[type as ReportItemType];
      if (value) {
        const optionsEntries = Object.entries(value);
        const filteredOptions = optionsEntries.filter(([_, value]) => value);

        if (data) {
          const dataAccordingOptions = filteredOptions.map(([type]) => data[type as 'map' | 'chart' | 'table']);

          // Is important. When report item has not enough data we set empty string to let it know it has been loaded
          const filteredData = dataAccordingOptions.filter((data) => data !== null);

          return filteredData.length === filteredOptions.length;
        }
      }
    });

    const filteredInformation = entriesInformation.filter((value) => value);
    const isCompleted = filteredInformation.length === includedReportItems.length;

    if (isCompleted && selectedReport && primaryLocation && isLoading) {
      const { pdf, csv, zip, powerPoint } = includeForExport();

      if (pdf) {
        generatePDF(
          exportData,
          exportOptions,
          selectedReport,
          primaryLocation,
          timeFrame as string,
          advancedReportItemsInformation,
          usePDFPreview,
          companyLogo,
          t,
        );
      }

      if (csv) {
        generateExcel(exportData, advancedReportItemsInformation, selectedReport.name);
      }

      if (powerPoint) {
        generatePowerPoint(
          exportData,
          selectedReport.name,
          timeFrame as string,
          advancedReportItemsInformation,
          primaryLocation,
          usePDFPreview,
          companyLogo,
          t,
        );
      }
      if (zip) {
        generateZIP(exportData, selectedReport.name);
      }

      toggleShowChartsPage(false);
      toggleShowMapPage(false);
      exportSettingsModel.resetOptionsData();
      exportSettingsModel.toggleIsLoading(false);
      exportSettingsModel.toggleIsExportMode(false);
    }
  }, [exportData]);

  const getExportOption = (dataSet: string[]) => {
    let baseOptions = { map: false, chart: false, table: false };

    // // Check is 'select data set' the map
    if (dataSet.includes(dataSetsOptions[0].id)) {
      baseOptions = { ...baseOptions, map: true };
    }

    // Check is 'select data set' the chart
    if (dataSet.includes(dataSetsOptions[1].id)) {
      baseOptions = { ...baseOptions, chart: true };
    }

    // Check is 'select data set' the table
    if (dataSet.includes(dataSetsOptions[2].id)) {
      baseOptions = { ...baseOptions, table: true };
    }

    return baseOptions;
  };

  const onExport = ({ metric, dataSet, format }: Options) => {
    const options = { ...exportOptions };
    const availableReportItemsTypes = selectedReport?.report_items
      .filter(({ type }) => metric.includes(type))
      .map(({ type }) => type);

    const exportOption = getExportOption(dataSet);
    availableReportItemsTypes?.forEach((curr) => {
      options[curr] = exportOption;
    });

    trackUserAction(
      'Left panel export',
      UserActionsEnum.REPORT_EXPORT,
      `Metric type: ${metric.join(', ')}; Dataset: ${exportOption.map ? 'Maps, ' : ''}${
        exportOption.chart ? 'Charts, ' : ''
      }${exportOption.table ? 'Tables, ' : ''}; Format: ${format}`,
    );

    if (exportOption.chart || exportOption.table) toggleShowChartsPage(true);
    if (exportOption.map) toggleShowMapPage(true);
    exportSettingsModel.updateExportOptions(options);
    exportSettingsModel.updateExportedFormats(format);
    exportSettingsModel.updateExportedMetrics(dataSet);
    exportSettingsModel.toggleIsLoading(true);
    exportSettingsModel.toggleIsExportMode(true);
    exportSettingsModel.toggleUsePDFPreview(true);
  };

  useClickOutside(menuRef, onClose, isOpen);

  return (
    <div className={styles.wrapper} ref={menuRef}>
      {isLoading && <CircleLoader withBackground positionType="fixed" />}
      {isGlobalLoading ? (
        <Skeleton className={styles.skeletonDownloadButton} />
      ) : (
        <CircleButton icon={<DownloadIcon />} onClick={onOpen} active={isOpen} title="Export" />
      )}
      {isOpen && (
        <span className={styles.menu}>
          <MenuSettings onClose={onClose} onExport={onExport} />
        </span>
      )}
      {showChartsPage && <ChartsContainer exportOptions={exportOptions} />}
      {showMapPage && <MapContainer />}
    </div>
  );
};
