import React from 'react';
import { Moment } from 'moment';
import styles from './yearPicker.module.scss';
import { CaretLeftIcon, CaretRightIcon } from '../../icons/yearMonthPicker';

interface Props {
  date: Moment;
  label: string;
  onChange: (amount: number) => void;
  isDecreaseEnabled: boolean;
  isIncreaseEnabled: boolean;
}

const YearPicker: React.FC<Props> = ({ date, label, onChange, isDecreaseEnabled, isIncreaseEnabled }) => {
  const changeStartYearHandler = () => {
    onChange(-1);
  };

  const changeEndYearHandler = () => {
    onChange(1);
  };

  return (
    <div className={styles.container}>
      <span>{label}</span>
      <div className={styles.yearContainer}>
        <span>{date.format('MMMM, YYYY')}</span>
        <div className={styles.buttonContainer}>
          <button type="button" onClick={changeStartYearHandler} disabled={!isDecreaseEnabled}>
            <CaretLeftIcon fill={isDecreaseEnabled ? '#00B2F1' : '#b8c6cc'} />
          </button>
          <button type="button" onClick={changeEndYearHandler} disabled={!isIncreaseEnabled}>
            <CaretRightIcon fill={isIncreaseEnabled ? '#00B2F1' : '#b8c6cc'} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default YearPicker;
