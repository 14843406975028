import React, { useContext, useMemo } from 'react';
import { MainContext } from '../../../common/types/mainContext';
import { getGradientColor, getColorIndex } from '../../../common/maps/utils/getGradientUtil';
import Polygon from '../../../common/maps/polygon/polygon';
import {
  CatchmentLocationsCountEnum,
  CatchmentMetricTypeEnum,
  CatchmentTypeEnum,
  ColorSchemeEnum,
  ReportItemType,
} from '../../../common/reportItems/types';
import { getCenterCoordinateOfGeoPolygon } from '../../../common/maps/utils/getCenterCoordinateOfGeoPolygon';
import { IndexArea } from '../../../common/types/visualizationObjects';
import { Catchment, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { getPostalCodeOpacity } from './utils';
import { ExportMapContext } from '../../../common/export/exportSettings/settings/mapContainer';

interface Props {
  ignoreShadow?: boolean;
  useCatchmentPostalCodes?: boolean;
  id?: string;
  ignoreOutline?: boolean;
  outlineColor?: string;
}
export const CircleChartPolygons = ({
  ignoreShadow,
  useCatchmentPostalCodes,
  id = '',
  ignoreOutline,
  outlineColor,
}: Props) => {
  const {
    selection: { selectedReport, selectedReportItem },
    primaryLocationId,
    reportItemsDetails: { catchmentType, catchmentTreshold, colorScheme, catchmentMetricType, catchmentLocationsCount },
    mapOptions,
    exportSelection,
  } = useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const reportItem = isExportMap ? exportSelection.selectedReportItem : selectedReportItem;

  const indexPolygons = useMemo(() => {
    let locationPolygons: IndexArea | null = null;

    const minThresholdCalculated =
      catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION &&
      catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE
        ? catchmentTreshold.min
        : 0.001;
    const maxThresholdCalculated =
      catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION &&
      catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE
        ? catchmentTreshold.max
        : 1;

    if (useCatchmentPostalCodes) {
      const reportItem = selectedReport?.report_items.find(
        ({ type }) => type === ReportItemType.CATCHMENT,
      ) as ReportItemInfo<Catchment>;
      if (reportItem) {
        const polygons = reportItem.visualization?.indexPolygons?.find(
          (item) => item.location_id === primaryLocationId,
        );

        if (polygons) {
          locationPolygons = polygons;
        }
      }
    } else {
      locationPolygons = primaryLocationId
        ? reportItem?.visualization?.indexPolygons?.find((item) => item.location_id === primaryLocationId) || null
        : null;
    }

    if (catchmentType === CatchmentTypeEnum.HOME) {
      return locationPolygons?.home
        ? {
            ...locationPolygons.home,
            polygons: locationPolygons.home.polygons.filter(
              (item) =>
                (item.destinationValue || 0) >= minThresholdCalculated &&
                (item.destinationValue || 0) <= maxThresholdCalculated,
            ),
          }
        : null;
    }
    if (catchmentType === CatchmentTypeEnum.WORK) {
      return locationPolygons?.work
        ? {
            ...locationPolygons.work,
            polygons: locationPolygons.work.polygons.filter(
              (item) =>
                (item.destinationValue || 0) >= minThresholdCalculated &&
                (item.destinationValue || 0) <= maxThresholdCalculated,
            ),
          }
        : null;
    }
    return null;
  }, [
    primaryLocationId,
    catchmentType,
    catchmentTreshold,
    selectedReport,
    reportItem,
    catchmentMetricType,
    catchmentLocationsCount,
  ]);

  const { zoom } = mapOptions.catchment;
  return (
    <>
      {indexPolygons && indexPolygons.polygons && (
        <Polygon
          id={`POI_POLYGONS${id}`}
          data={{
            type: 'FeatureCollection',
            features: indexPolygons.polygons.map((i) => ({
              ...i.geo,
              properties: {
                color: getGradientColor(
                  getColorIndex(
                    i.destinationRank || indexPolygons.destinationMinRank,
                    indexPolygons.destinationMinRank,
                    indexPolygons.destinationMaxRank,
                  ),
                  colorScheme === ColorSchemeEnum.MONOCHROME,
                ),
                popupTitle: i.name,
                centerPoint: getCenterCoordinateOfGeoPolygon(i.geo),
                popupData: i.popupData?.postalCodes,
              },
            })),
          }}
          ignoreShadow={ignoreShadow}
          ignoreOutline={ignoreOutline}
          borderLineWidth={1}
          outlineColor={outlineColor}
          opacity={getPostalCodeOpacity(zoom)}
        />
      )}
    </>
  );
};
