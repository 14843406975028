import React from 'react';
import { styleCorrections } from '../utils';
import { InformationParticle } from '../informationParticle';
import styles from './style.module.scss';
import { StakeData } from '../interfaces';
import { Retail } from '../../../../reportItems/catchment/detail/Chart/model';

interface Props {
  subCategories: StakeData[];
  retails: Retail[];
}

const height = 429;

const frags = 360 / 10;
const rx = 215;
const ry = 215;

const rightIndexes = [1, 2, 3, 10, 9];

export const Categories: React.FC<Props> = ({ subCategories, retails }) => (
  <div className={styles.items}>
    {subCategories.map(({ data, icon, type, styles: customStyles }, index) => {
      const flag = (frags / 180) * index * Math.PI;
      const posx = Math.round(rx * Math.cos(flag));
      const posy = Math.round(ry * Math.sin(flag));
      const top = height / 2 - parseInt(String(posy));
      const left = height / 2 + parseInt(String(posx));
      const { top: topCorrection, left: leftCorrection } = styleCorrections(index);
      return (
        <div
          key={index}
          className={`${styles.item} ${index + 1}`}
          style={{ top: `${top + topCorrection}px`, left: `${left + leftCorrection}px` }}
        >
          <InformationParticle
            data={data}
            icon={icon}
            side={rightIndexes.includes(index + 1) ? 'right' : 'left'}
            contentType={type}
            customStyles={customStyles}
            retails={retails}
          />
        </div>
      );
    })}
  </div>
);
