import React from 'react';
import { Logo } from '../../common/logo';
import styles from './style.module.scss';

interface Props {
  title: string;
  subtitle: string;
  information?: React.ReactNode;
  subtitleFirst?: boolean;
}
export const BaseModal: React.FC<Props> = ({ children, title, subtitle, information, subtitleFirst }) => {
  const titleHTML = <h1 className={`${styles.title} ${!subtitleFirst ? styles.topSpace : ''}`}>{title}</h1>;
  const subtitleHTML = (
    <p
      className={`${styles.subtitle} ${subtitleFirst ? styles.topSpace : ''}`}
      dangerouslySetInnerHTML={{ __html: subtitle }}
    />
  );

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <Logo />
        <div className={styles.introduction}>
          {!subtitleFirst ? titleHTML : subtitleHTML}
          {!subtitleFirst ? subtitleHTML : titleHTML}
        </div>
        <div>{children}</div>
      </div>
      {information}
    </div>
  );
};
