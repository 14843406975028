import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from '../../../../../tooltips';

interface Props {
  onNo: VoidFunction;
  onYes: VoidFunction;
}
export const UnSaveChangesTooltip: React.FC<Props> = ({ onNo, onYes }) => {
  const { t } = useTranslation();
  return (
    <Popup width="400px" title={t('locations.closeCreateLocation')} onNo={onNo} onYes={onYes} withActions>
      <p>{t('locations.closeCreateLocationParagraph1')}</p>
      <p>{t('locations.closeCreateLocationParagraph2')}</p>
    </Popup>
  );
};
