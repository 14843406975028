import { ReportItemType } from '../reportItems/types';
import {
  AbsorptionBriefData,
  AttractionBriefData,
  CrossVisitationBriefData,
  RetentionBriefData,
  SeasonalityBriefData,
  VisitDurationBriefData,
  VisitTrendsBriefData,
} from '../../reportItems/model/interfaces';

type PopupValue = {
  name: string;
  value: string | number;
};

export type Point = {
  location_id: string;
  group_id?: string;
  name: string;
  rank?: number;
  value: string | number;
  longitude: number;
  latitude: number;
  color: string;
};

export type Area = {
  location_id: string;
  group_id?: string;
  name: string;
  rank?: number;
  value?: number;
  geo: GeoJSON.Feature<GeoJSON.Geometry>;
  popupData?: PopupValue[];
};

export type CatchmentArea = Area & {
  centerPointLongitude: number;
  centerPointLatitude: number;
  sourceRank?: number;
  destinationRank?: number;
  scoreRank?: number;
  sourceValue?: number;
  destinationValue?: number;
  scoreValue?: number;
  sourceIsochrone?: number;
  destinationIsochrone?: number;
  scoreIsochrone?: number;
  isochroneAerialDistanceRange?: string;
  inhabitants?: number;
  households?: number;
  average_income?: number;
  purchasing_power?: number;
  popupData?: {
    postalCodes?: PopupValue[];
    isochrones?: PopupValue[];
  };
};

export type TopLocation = {
  locationId: string;
  locationName: string;
  value: number;
  color: string;
};

export type CatchmentMultiArea = Area & {
  centerPointLongitude: number;
  centerPointLatitude: number;
  inhabitants?: number;
  households?: number;
  average_income?: number;
  purchasing_power?: number;
  sourceTopLocations: TopLocation[];
  destinationTopLocations: TopLocation[];
  scoreTopLocations: TopLocation[];
};

export type IndexArea = {
  location_id: string;
  home: {
    sourceMinRank: number;
    sourceMaxRank: number;
    destinationMinRank: number;
    destinationMaxRank: number;
    scoreMinRank: number;
    scoreMaxRank: number;

    isochroneSourceMinRank: number;
    isochroneSourceMaxRank: number;
    isochroneDestinationMinRank: number;
    isochroneDestinationMaxRank: number;
    isochroneScoreMinRank: number;
    isochroneScoreMaxRank: number;

    polygons: CatchmentArea[];
  };
  work: {
    sourceMinRank: number;
    sourceMaxRank: number;
    destinationMinRank: number;
    destinationMaxRank: number;
    scoreMinRank: number;
    scoreMaxRank: number;

    isochroneSourceMinRank: number;
    isochroneSourceMaxRank: number;
    isochroneDestinationMinRank: number;
    isochroneDestinationMaxRank: number;
    isochroneScoreMinRank: number;
    isochroneScoreMaxRank: number;

    polygons: CatchmentArea[];
  };
};

export type IndexMultiArea = {
  locationIds: string[];
  home: {
    inhabitantsMinRank: number;
    inhabitantsMaxRank: number;
    householdsMinRank: number;
    householdsMaxRank: number;
    averageIncomeMinRank: number;
    averageIncomeMaxRank: number;
    purchasingPowerMinRank: number;
    purchasingPowerMaxRank: number;

    polygons: CatchmentMultiArea[];
  };
  work: {
    inhabitantsMinRank: number;
    inhabitantsMaxRank: number;
    householdsMinRank: number;
    householdsMaxRank: number;
    averageIncomeMinRank: number;
    averageIncomeMaxRank: number;
    purchasingPowerMinRank: number;
    purchasingPowerMaxRank: number;

    polygons: CatchmentMultiArea[];
  };
};

export type IndexPoints = {
  location_id: string;
  home: {
    minValue: number;
    maxValue: number;
    points: Point[];
  };
  work: {
    minValue: number;
    maxValue: number;
    points: Point[];
  };
};

export interface Trip {
  path: number[][];
  timestamps: number[];
}

export enum SwitchModes {
  POSTAL_CODES = 'PostalCodes',
  HEATMAP = 'Heatmap',
  TRIPS = 'Trips',
  ISOCHRONES = 'Isochrones',
  WINNER = 'Winner',
  SOCIO = 'Socio',
  HOTSPOTS = 'Hotspots',
}

export interface MapPopupData {
  title: string;
  coordinates: number[];
  data?:
    | VisitTrendsBriefData
    | SeasonalityBriefData
    | VisitDurationBriefData
    | RetentionBriefData
    | CrossVisitationBriefData
    | AbsorptionBriefData
    | AttractionBriefData;
}

export type BasicMapPopupProps = Record<ReportItemType, MapPopupData>;
