import { generateUrlToGetReportItemLocationImage } from '../../../../api';
import { convertLocationsImagesURLToDataBase64 } from '../../../locations/utils';
import { commonRequestHeaders } from '../../../../commonRequestHeaders';
import { LocationInfo } from '../../../types/projectsHierarchy';

export const getLocationsImages = async (
  reportItemId: string,
  selectedLocations: LocationInfo[],
  mode = '',
  defaultImage = '',
) => {
  const locationWithImagesUrls = selectedLocations.map((location) => ({
    ...location,
    image: generateUrlToGetReportItemLocationImage(reportItemId, location.id, mode),
  }));

  const locationsWithImages = await convertLocationsImagesURLToDataBase64(
    locationWithImagesUrls,
    commonRequestHeaders(),
  );
  const formattedData = locationsWithImages.map(({ id, name, image }) => ({
    id,
    name,
    image: image === 'data:' || !image ? defaultImage : image,
  }));
  return formattedData;
};
