import React from 'react';
import styles from './switchToggle.module.scss';
import CheckIcon from '../../icons/controls/check';

interface Props {
  leftLabel: string;
  leftValue: string;
  rightLabel: string;
  rightValue: string;
  selectedValue: string;
  onClick: (value: string) => void;
  disabled?: boolean;
  leftLabelSize?: string;
}

export const SwitchToggle: React.FC<Props> = ({
  leftLabel,
  leftValue,
  rightLabel,
  rightValue,
  selectedValue,
  onClick,
  disabled = false,
  leftLabelSize = '41px',
}) => {
  const isLeftValueSelected = selectedValue === leftValue;

  const onClickHandler = () => {
    onClick(isLeftValueSelected ? rightValue : leftValue);
  };

  return (
    <div className={styles.container}>
      <label
        htmlFor={leftValue}
        className={`${isLeftValueSelected ? styles.active : ''}`}
        style={{ width: leftLabelSize }}
      >
        {leftLabel}
      </label>
      <div className={styles.switcher} onClick={onClickHandler}>
        <button className={isLeftValueSelected ? '' : styles.disabled} id={leftValue} type="button" disabled={disabled}>
          <span style={{ display: isLeftValueSelected ? 'block' : 'none' }}>
            <CheckIcon />
          </span>
        </button>
        <button
          className={isLeftValueSelected ? styles.disabled : ''}
          id={rightValue}
          type="button"
          disabled={disabled}
        >
          <span style={{ display: isLeftValueSelected ? 'none' : 'block' }}>
            <CheckIcon />
          </span>
        </button>
      </div>
      <label htmlFor={rightValue} className={`${isLeftValueSelected ? '' : styles.active}`}>
        {rightLabel}
      </label>
    </div>
  );
};
