import React, { Component } from 'react';
import { CSVReader } from 'react-papaparse';
import { CSVLink } from 'react-csv';
import classNames from 'classnames';
import styles from './addPoiViaCsv.module.css';

import { AddPoiResult } from '../../../reducers/poiManagementReducers';

interface Props {
  resetBatchPoiUploadResults: Function;
  batchAddPoisResult: AddPoiResult[];
  createCsvPois: (rowsToProcess: any[]) => any;
}

interface State {
  csvReaderKey: number;
}

export default class AddPoiViaCsv extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      csvReaderKey: Math.random(),
    };
  }

  render() {
    return (
      <div>
        <h2>CSV import</h2>
        {this.renderDownloadExampleFile()}
        <div>{this.renderCsvDropZone()}</div>
        <div className={styles.resultContainer}>
          {this.props.batchAddPoisResult.map((singleResult) => (
            <div
              key={singleResult.name}
              className={classNames(
                styles.singleResult,
                { [styles.singleResultSuccess]: singleResult.type === 'success' },
                { [styles.singleResultError]: singleResult.type === 'failure' },
              )}
            >
              {singleResult.name || 'unknown name'}: {singleResult.message}
            </div>
          ))}
        </div>
      </div>
    );
  }

  private renderCsvDropZone() {
    return (
      <CSVReader
        key={this.state.csvReaderKey}
        isReset
        onDrop={this.onCsvUpload.bind(this)}
        onError={() => {
          console.log('error loading csv file');
        }}
      >
        <span>Drop file or click</span>
      </CSVReader>
    );
  }

  private onCsvUpload(rows: any[]) {
    this.props.resetBatchPoiUploadResults();
    this.setState({ csvReaderKey: Math.random() });
    const rowsToProcess: any = rows.slice(1, rows.length);
    this.props.createCsvPois(rowsToProcess);
  }

  private renderDownloadExampleFile() {
    const csvData = [
      [
        'name',
        'description',
        'address',
        'street',
        'houseNumber',
        'city',
        'country',
        'postalCode',
        'purpose',
        'location setup',
        'polygon (WKT)',
      ],
    ];
    return (
      <div className={styles.templateFile}>
        <CSVLink filename="pois.csv" data={csvData}>
          Download template file
        </CSVLink>
      </div>
    );
  }
}
