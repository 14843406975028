import React from 'react';

export const CheckIcon = () => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.75005 9.14995L1.60005 5.99995L0.550049 7.04995L4.75005 11.25L13.75 2.24995L12.7 1.19995L4.75005 9.14995Z"
      fill="#1F303D"
      stroke="#1F303D"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckIcon;
