import React, { ReactElement, useCallback, useState } from 'react';
import MaterialUiButton from '@material-ui/core/Button';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { useHistory } from 'react-router';
import styles from './confirmRecalculateRIsModal.module.css';
import Button from '../../atoms/primaryButton/button';
import Modal from '../Modal/Modal';

interface Props {
  title: string;
  isVisible?: boolean;
  onRecalculateRIsRedirectURL?: string;
  onClose: () => void;
  onRecalculateRIs: () => void;
}

const ConfirmRecalculateRIsModal = ({
  isVisible: isVisibleProps,
  onRecalculateRIsRedirectURL,
  onClose,
  onRecalculateRIs,
  title,
}: Props): ReactElement => {
  const [isVisible, setIsVisible] = useState(isVisibleProps);
  const history = useHistory();

  const closeModal = useCallback(() => {
    setIsVisible(false);
    onClose();
  }, []);

  const recalculateRIsHandler = useCallback(() => {
    onRecalculateRIs();
    closeModal();
    if (onRecalculateRIsRedirectURL) {
      history.replace(onRecalculateRIsRedirectURL);
    }
  }, []);

  return (
    <Modal onClose={onClose} isVisible={isVisible}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.buttons}>
        <div id={styles.recalculateRIsButton} className={styles.recalculateRIsButton}>
          <MaterialUiButton
            variant="contained"
            color="secondary"
            startIcon={<AgricultureIcon />}
            onClick={recalculateRIsHandler}
          >
            Recalculate all report items
          </MaterialUiButton>
        </div>
        <div>
          <Button onClick={closeModal} secondary>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmRecalculateRIsModal;
