import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import MapGL, { MapRef } from 'react-map-gl';
import { MAP_STYLE, MAP_TOKEN, TRANSPARENT_MAP_STYLE } from '../map.config';
import { MapViewPort } from '../mapBase';

type style = 'transparent' | 'main' | 'satellite';

interface Props {
  initialViewport: MapViewPort | null;
  children: ReactNode;
  onLoad?: VoidFunction;
  mapStyle?: style;
}

const baseViewport = {
  latitude: 0,
  longitude: 0,
  zoom: 0,
};

const getMapStyle = (type: style) => {
  switch (type) {
    case 'transparent':
      return TRANSPARENT_MAP_STYLE;
    case 'main':
      return MAP_STYLE;
    case 'satellite':
      return MAP_STYLE;
    default:
      return MAP_STYLE;
  }
};

export const SimpleMap = forwardRef<MapRef, Props>(
  ({ children, initialViewport, onLoad, mapStyle = 'transparent' }, ref) => {
    const [viewport, setViewport] = useState<MapViewPort>(baseViewport);

    useEffect(() => {
      if (initialViewport) setViewport(initialViewport);
    }, [initialViewport]);

    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle={getMapStyle(mapStyle)}
        mapboxApiAccessToken={MAP_TOKEN}
        attributionControl={false}
        maxZoom={19}
        ref={ref}
        preserveDrawingBuffer
        onLoad={onLoad}
        onViewportChange={setViewport}
      >
        {children}
      </MapGL>
    );
  },
);
