import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './placenseLogoWhite.module.css';

export default class PlacenseLogoWhite extends Component {
  render() {
    return (
      <Link to="/">
        <div className={styles.logo}>
          <svg xmlns="http://www.w3.org/2000/svg" className={styles.svg} viewBox="0 0 200 100">
            <path d="M95 47l6 6 20 20-8 8-26-26a22 22 0 0 1-8-16 21 21 0 0 1 43 0 22 22 0 0 1-7 16l-2 2-8-8 2-2a12 12 0 0 0 4-8 10 10 0 0 0-20 0 12 12 0 0 0 4 8M83 59l18 18 8 8-8 8-26-26a39 39 0 1 1 51 0l-1 2-8-8 2-2a28 28 0 0 0 9-20 27 27 0 0 0-54 0 28 28 0 0 0 9 20z" />
          </svg>
        </div>
      </Link>
    );
  }
}
