import React from 'react';

export const CalendarIcon = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.25 6.75H4.75V8.25H3.25V6.75ZM13.75 3V13.5C13.75 14.325 13.075 15 12.25 15H1.75C0.9175 15 0.25 14.325 0.25 13.5L0.2575 3C0.2575 2.175 0.9175 1.5 1.75 1.5H2.5V0H4V1.5H10V0H11.5V1.5H12.25C13.075 1.5 13.75 2.175 13.75 3ZM1.75 4.5H12.25V3H1.75V4.5ZM12.25 13.5V6H1.75V13.5H12.25ZM9.25 8.25H10.75V6.75H9.25V8.25ZM6.25 8.25H7.75V6.75H6.25V8.25Z"
      fill="white"
    />
  </svg>
);

export default CalendarIcon;
