import React, { useContext, useMemo } from 'react';
import moment, { Moment } from 'moment';
import styles from './yearMonthLabel.module.scss';
import { CalendarIcon } from '../../icons/yearMonthPicker';
import { TimeFrames } from '../types';
import { MainContext } from '../../types/mainContext';
import { CircleButton } from '../../controls/buttons/circleButton/circleButton';
import { useTimeFrameFormattedLabel } from '../hooks';

interface Props {
  timeFrames: TimeFrames;
  isMenuOpened: boolean;
  onToggleMenu: (event: React.MouseEvent) => void;
}

const YearMonthLabel: React.FC<Props> = ({ timeFrames, isMenuOpened, onToggleMenu }) => {
  const { label } = useTimeFrameFormattedLabel({ timeFrames });

  return (
    <div className={styles.timeFrames} onClick={onToggleMenu}>
      {label}
      <CircleButton active={isMenuOpened} icon={<CalendarIcon />} title="Reports timeframe" />
    </div>
  );
};

export default YearMonthLabel;
