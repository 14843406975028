import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import { fetchAccounts } from '../../../../actions/actions';
import { AppState } from '../../../../reducers/reducers';
import { AuthState } from '../../../../reducers/authReducer';
import { Account } from '../../../../types/Account';
import { AccountStatus } from '../../../../types/AccountStatus';
import styles from './accountFilterProjectsPage.module.css';
import MultiSelectWithOptionLimit from "../../../atoms/multiselectWithOptionLimit/multiSelectWithOptionLimit";

type TProps = {
  onChange: (items: Option[]) => void;
};

const AccountFilterProjectsPage: React.FC<TProps> = (props: TProps) => {
  const dispatch = useDispatch();
  const { accounts } = useSelector<AppState, AuthState>((state) => state.auth);

  const selectedAccounts: Option[] =
    localStorage.getItem('accountFilter') === null ? [] : JSON.parse(localStorage.getItem('accountFilter') || '');

  const mapAccountToOption = ({ name, id }: Account): Option => ({
    label: name,
    value: id,
    key: id,
  });

  const mapActiveAccountToOptions = (accounts: Account[]): Option[] =>
    accounts
      .filter(({ status }) => AccountStatus.ACTIVE === status)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(mapAccountToOption);

  const onChangeHandler = (items: Option[]) => {
    localStorage.setItem('accountFilter', JSON.stringify(items));
    props.onChange(items);
  };

  useEffect(() => {
    dispatch(fetchAccounts());
  }, []);

  return (
    <div className={styles.accountsContainer}>
      <label htmlFor="accounts">Accounts</label>
      <MultiSelectWithOptionLimit
        className={styles.accountsMultiSelect}
        options={selectedAccounts.length <1 ? mapActiveAccountToOptions(accounts):selectedAccounts}
        value={selectedAccounts}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default AccountFilterProjectsPage;
