import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import Table from '../../../common/page/table/table';
import { Field, InputTypesEnum } from '../../../common/inputs';
import { validationSchema } from './schemes';
import { SubmitHandler } from '../interfaces';
import styles from './styles.module.scss';

interface Props {
  onSubmit: SubmitHandler;
  onCancel: VoidFunction;
  error: string;
  success: string;
}
export const Form = forwardRef<VoidFunction, Props>(({ onSubmit, onCancel, error, success }: Props, ref) => {
  const { handleSubmit, handleChange, values, errors, submitForm, resetForm } = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      reTypedNewPassword: '',
    },
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (success) resetForm();
  }, [success]);

  useImperativeHandle(ref, () => submitForm);

  return (
    <Table title="Password" subtitle="Password settings to help you keep your account secure.">
      <form onSubmit={handleSubmit}>
        <div className={styles.underlined}>
          <div className={styles.field}>
            <Field
              label="Password"
              id="currentPassword"
              value={values.currentPassword}
              error={errors.currentPassword}
              name="currentPassword"
              onChange={handleChange}
              maxLabelWidth="124px"
              columnGap="20px"
              fieldType={InputTypesEnum.PASSWORD}
            />
          </div>
        </div>
        <div className={styles.noUnderlined}>
          <div className={styles.field}>
            <Field
              label="New password"
              id="newPassword"
              value={values.newPassword}
              error={errors.newPassword}
              name="newPassword"
              onChange={handleChange}
              maxLabelWidth="124px"
              columnGap="20px"
              fieldType={InputTypesEnum.PASSWORD}
            />{' '}
          </div>
          <div className={styles.field}>
            <Field
              label="Re-enter password"
              id="reTypedNewPassword"
              value={values.reTypedNewPassword}
              error={errors.reTypedNewPassword}
              name="reTypedNewPassword"
              onChange={handleChange}
              maxLabelWidth="124px"
              columnGap="20px"
              fieldType={InputTypesEnum.PASSWORD}
            />
          </div>
        </div>
        {error && <div className={`${styles.message} ${styles.error}`}>{error}</div>}
        {success && <div className={`${styles.message} ${styles.success}`}>{success}</div>}
      </form>
    </Table>
  );
});
