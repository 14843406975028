import { request } from '../request';
import { Credentials } from '../../auth/signIn/interfaces';
import { API_URLS } from '../../routes';

interface Response {
  redirectProjectId: any;
  redirectReportId: any;
  redirectReportItemId: any;
  redirectReportItemName: any;
  token: string;
}

export const login = (data: Credentials) => {
  const url = API_URLS.LOGIN;
  return request<Response>({ url, data, method: 'POST' });
};
