import React from 'react';
import { useSelector } from 'react-redux';
import styles from './usersFilter.module.scss';
import { AppState } from '../../../../reducers/reducers';
import { AuthState } from '../../../../reducers/authReducer';
import UserRole from '../../../../types/UserRole';

interface Props {
  value: string;
  onChange: (userId: string) => void;
  disabled?: boolean;
}

const UsersFilter = (props: Props) => {
  const { users } = useSelector<AppState, AuthState>((state) => state.auth);

  return (
    <div className={styles.usersContainer}>
      <label htmlFor="users-dropdown">Users</label>
      <select
        id="users-dropdown"
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        disabled={!!props.disabled}
      >
        <option key="empty" value="">
          Select user
        </option>
        {users
          .filter(
            (user) =>
              user.role === UserRole.REGULAR_USER ||
              user.role === UserRole.PLACENSE_USER ||
              user.role === UserRole.ACCOUNT_BETA_ADMIN ||
              user.role === UserRole.ACCOUNT_ADMIN,
          )
          .map((user) => (
            <option key={user.userId} value={user.userId}>
              {`${user.firstName || ''} ${user.lastName || ''}`}
            </option>
          ))}
      </select>
    </div>
  );
};

export default UsersFilter;
