import { getAnalyticsData, getMovementHotspots, getReportItems } from '../../api';
import { AnalyticsDataResponse, MovementResponse, DataLevel } from './types';
import { mapReportItemType } from '../utils';
import { Point } from '../../common/types/visualizationObjects';
import { convertAnalyticsData } from './utils';
import { IReportCategory } from '../../../types/report';
import {SelectedTimeFrames} from "../../common/types/projectsHierarchy";

const convertDataLevel = (workData: number, homeData: number, denominator: number, items: DataLevel) => {
  const {
    default: pois,
    defaultHome: poisHome,
    defaultWork: poisWork,
    // Only with home
    withHomeData: mainWithHomeDataPois,
    homeDataWithHome: homeDataWithHomePois,
    workDataWithHome: workDataWithHomePois,
    // Only with work
    withWorkData: mainWithWorkDataPois,
    homeDataWithWork: homeDataWithWorkPois,
    workDataWithWork: workDataWithWorkPois,
    // With home and work
    withWorkAndHomeData: mainWithWorkAndHomeDataPois,
    homeDataWithWorkAndHome: homeDataWithWorkAndHomePois,
    workDataWithWorkAndHome: workDataWithWorkAndHomePois,
  } = convertAnalyticsData(items.pois, homeData, workData, items.poisDenominator);

  const {
    default: buildings,
    defaultHome: buildingsHome,
    defaultWork: buildingsWork,
    // Only with home
    withHomeData: mainWithHomeDataBuildings,
    homeDataWithHome: homeDataWithHomeBuildings,
    workDataWithHome: workDataWithHomeBuildings,
    // Only with work
    withWorkData: mainWithWorkDataBuildings,
    homeDataWithWork: homeDataWithWorkBuildings,
    workDataWithWork: workDataWithWorkBuildings,
    // With home and work
    withWorkAndHomeData: mainWithWorkAndHomeDataBuildings,
    homeDataWithWorkAndHome: homeDataWithWorkAndHomeBuildings,
    workDataWithWorkAndHome: workDataWithWorkAndHomeBuildings,
  } = convertAnalyticsData(items.buildings, homeData, workData, items.buildingsDenominator);

  return {
    pois: {
      additional: {
        work: poisWork,
        home: poisHome,
        homeDataWithHome: homeDataWithHomePois,
        workDataWithHome: workDataWithHomePois,
        homeDataWithWork: homeDataWithWorkPois,
        workDataWithWork: workDataWithWorkPois,
        homeDataWithWorkAndHome: homeDataWithWorkAndHomePois,
        workDataWithWorkAndHome: workDataWithWorkAndHomePois,
      },
      main: pois,
      mainWithHomeData: mainWithHomeDataPois,
      mainWithWorkData: mainWithWorkDataPois,
      mainWithWorkAndHomeData: mainWithWorkAndHomeDataPois,
    },
    buildings: {
      additional: {
        work: buildingsWork,
        home: buildingsHome,
        homeDataWithHome: homeDataWithHomeBuildings,
        workDataWithHome: workDataWithHomeBuildings,
        homeDataWithWork: homeDataWithWorkBuildings,
        workDataWithWork: workDataWithWorkBuildings,
        homeDataWithWorkAndHome: homeDataWithWorkAndHomeBuildings,
        workDataWithWorkAndHome: workDataWithWorkAndHomeBuildings,
      },
      main: buildings,
      mainWithHomeData: mainWithHomeDataBuildings,
      mainWithWorkData: mainWithWorkDataBuildings,
      mainWithWorkAndHomeData: mainWithWorkAndHomeDataBuildings,
    },
  };
};
const convertTypeData = (
  workData: number,
  homeData: number,
  denominator: number,
  categories: DataLevel,
  locations: DataLevel,
) => {
  const categoriesData = convertDataLevel(workData, homeData, denominator, categories);
  const locationsData = convertDataLevel(workData, homeData, denominator, locations);

  return {
    categories: categoriesData,
    locations: locationsData,
  };
};

export const getAnalytics = async (reportItemId: string, primaryLocationId: string, selectedTimeFrame: string[]|null) => {
  try {
    const data = await getAnalyticsData<AnalyticsDataResponse>(reportItemId, primaryLocationId,selectedTimeFrame);

    // if (
    //   !((data?.before?.locations?.pois.length || 0) > 0) ||
    //   !((data?.after?.locations?.pois.length || 0) > 0) ||
    //   !((data?.during?.locations?.pois.length || 0) > 0)
    // )
    //   return null;

    const before = data?.before
      ? convertTypeData(
          data.before.work,
          data.before.home,
          data.before.denominator,
          data.before.categories,
          data.before.locations,
        )
      : null;
    const after = data?.after
      ? convertTypeData(
          data.after.work,
          data.after.home,
          data.after.denominator,
          data.after.categories,
          data.after.locations,
        )
      : null;
    const during = data?.during
      ? convertTypeData(
          data.during.work,
          data.during.home,
          data.during.denominator,
          data.during.categories,
          data.during.locations,
        )
      : null;

    return { before, after, during };
  } catch (error) {
    return null;
  }
};

export const fetchMovementData = async (
  reportItemId: string,
  locationId: string,
  selectedTimeFrames: string[] | null,
  updateLoader: (visible: boolean, label?: string, progress?: number) => void,
  reportCategory?: IReportCategory,
) => {
  try {
    const {
      id,
      name,
      type,
      values,
      created_date: createdDate,
      timeFrame,
      maxDistance,
    } = await getReportItems<MovementResponse>(reportItemId, null, [locationId], selectedTimeFrames);
    updateLoader(true, 'Processing results', 50);

    if (values.length === 0) {
      return {
        id,
        name,
        type: mapReportItemType(type),
        data: null,
      };
    }

    return {
      id,
      name,
      type: mapReportItemType(type),
      data: {
        data: values,
        timeFrame,
        maxDistance,
      },
      createdDate,
      visualization:
        reportCategory === IReportCategory.POI_DEFAULT
          ? {
              points: values[0].data
                ?.map((point) => {
                  const [longitude, latitude] = point.geo_entity_code.split(',').map((i) => Number(i));

                  return { longitude, latitude };
                })
                .filter((i) => i !== null) as Point[],
            }
          : undefined,
    };
  } catch (error) {
    return {
      data: null,
    };
  }
};

export const getHotspots = async (reportItemId: string, locationId: string, selectedTimeFrame: string[]|null) => {
  try {
    const data = await getMovementHotspots(reportItemId, locationId, selectedTimeFrame);

    return data;
  } catch (error) {
    console.log("Error: Can't get Movement Hotspots data");
  }
};
