import { AppActions } from '../actions/actionTypes';
import { Report, IReportCategory } from '../types/report';
import {
  CHANGE_PROJECT_STATUS_SUCCESS,
  CHANGE_REPORT_STATUS_SUCCESS,
  DELETE_REPORT_SUCCESS,
  UPDATE_REPORT_ERROR,
  UPDATE_REPORT_SUCCESS,
  CREATE_PPS_SUCCESS,
  CREATE_PPS_ERROR
} from '../actions/actions';
import { STATUS } from '../components/pages/projectsPage/status';

export interface ReportsState {
  entries?: Report[];
}

const initialState: ReportsState = {
  entries: undefined,
};

export default function reportsReducer(state = initialState, action: AppActions) {
  switch (action.type) {
    case 'LOAD_REPORTS_SUCCESS': {
      return {
        ...state,
        entries: action.payload.reports,
      };
    }
    case DELETE_REPORT_SUCCESS: {
      return {
        ...state,
        entries: (state.entries || []).filter((entry) => entry.id !== action.payload.reportId),
      };
    }
    case UPDATE_REPORT_SUCCESS: {
      return {
        ...state,
        entries: (state.entries || []).map((entry) => {
          if (entry.id === action.payload.reportId) {
            return {
              ...entry,
              name: action.payload.name,
              description: action.payload.description,
              visualizationURL: action.payload.visualizationURL,
              recurrentNotificationEmail: action.payload.recurrentNotificationEmail,
            };
          }
          return entry;
        }),
      };
    }
    case CHANGE_REPORT_STATUS_SUCCESS: {
      return {
        ...state,
        entries: (state.entries || []).map((entry) => {
          if (entry.id === action.payload.reportId) {
            return {
              ...entry,
              status: action.payload.status,
            };
          }
          return entry;
        }),
      };
    }
    case CHANGE_PROJECT_STATUS_SUCCESS: {
      return {
        ...state,
        entries: (state.entries || []).map((entry) => ({
          ...entry,
          status:
              (entry.reportCategory === IReportCategory.FFI ||
                  entry.status === STATUS.READY_VALUE ||
                  entry.status === STATUS.PRESENTABLE_VALUE) &&
              (action.payload.status === STATUS.READY_VALUE || action.payload.status === STATUS.PRESENTABLE_VALUE)
                  ? action.payload.status
                  : entry.status,
        })),
      };
    }
    case UPDATE_REPORT_ERROR: {
      return {
        ...state,
        errorMessage: action.payload.reason,
      };
    }
    case CREATE_PPS_SUCCESS: {
      return {
        ...state,
        entries: (state.entries || []).map((entry) => {
          if (entry.id === action.payload.reportId) {
            return {
              ...entry,
              rerunStatus: action.payload.code,
            };
          }
          return entry;
        }),
      };
    }
    case CREATE_PPS_ERROR: {
      return {
        ...state,
        entries: (state.entries || []).map((entry) => {
          if (entry.id === action.payload.reportId) {
            return {
              ...entry,
              rerunStatus: action.payload.reason,

            };
          }
          return entry;
        }),
      };
    }
    default:
      return state;
  }
}
