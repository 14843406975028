import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../../common/types/mainContext';
import { Movement, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import Polygon from '../../../common/maps/polygon/polygon';
import { ColorSchemeEnum, LocationTypeEnum, MovementPeriodEnum } from '../../../common/reportItems/types';
import { convertPolygonToGeoData } from '../../../common/maps/utils/convertPolygonToGeoData';
import { getCenterCoordinate } from '../../../common/maps/utils/getCenterCoordinate';
import { getGradientColor, getColorIndex } from '../../../common/maps/utils/getGradientUtil';
import { getCenterCoordinateOfGeoPolygon } from '../../../common/maps/utils/getCenterCoordinateOfGeoPolygon';
import { getPointsColorScheme } from '../../../common/maps/utils/getPointsColorScheme';
import Pin from '../../../common/maps/pin/pin';
import { toFixNumber } from '../../../common/lib';
import { getNotEmptyString } from '../../../common/utils';
import { ExportMapContext } from '../../../common/export/exportSettings/settings/mapContainer';
import { TFunction } from 'i18next';
import { MovementHotspotsLocation } from '../../../reportItems/movement/types';
import * as GeoJSON from 'geojson';
import { IReportCategory } from '../../../../types/report';

interface Polygon extends Omit<MovementHotspotsLocation, 'geometry' | 'location_category' | 'value'> {
  geo: GeoJSON.Feature<GeoJSON.Geometry> | null;
  value?: number;
  geometry?: string;
  location_category?: string;
}

const createFeatureDataFromPolygon = <T extends Polygon>(
  polygons: T[],
  movementPeriod: MovementPeriodEnum,
  colorScheme: ColorSchemeEnum,
  min: number,
  max: number,
  t: TFunction,
  defaultPolygonName?: string,
  extended?: boolean,
) =>
  polygons.map((polygon) => ({
    ...(polygon.geo as GeoJSON.Feature),
    properties: {
      color: getGradientColor(
        getColorIndex(polygon.visits_share || min || 0, min || 0, max || 100),
        colorScheme === ColorSchemeEnum.MONOCHROME,
      ),
      popupTitle:
        getNotEmptyString(polygon.location_name) ||
        `${defaultPolygonName} (${
          getNotEmptyString(polygon.location_subcategory) ||
          getNotEmptyString(polygon.location_category) ||
          getNotEmptyString(polygon.location_address)
        })`,
      centerPoint: getCenterCoordinateOfGeoPolygon(polygon.geo),
      popupData: [
        ...(extended
          ? [
              { name: t('movement.detail.visitsShare'), value: `${toFixNumber(polygon.visits_share * 100, 2)} %` },
              { name: t('movement.card.category'), value: polygon.location_subcategory || '' },
            ]
          : []),
        { name: t('movement.detail.period'), value: movementPeriod },
      ],
    },
  }));

export const HotspotPolygons = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReportItem, selectedReport, selectedProject },
    reportItemsDetails: { colorScheme, movementPeriod, locationType, movementTreshold },
    exportSelection,
    primaryLocationId,
  } = useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const colors = getPointsColorScheme();
  const reportItem = (
    isExportMap ? exportSelection.selectedReportItem : selectedReportItem
  ) as ReportItemInfo<Movement>;

  const hotspotsAllData = reportItem?.data?.hotspotsData;

  const selectedPrimaryLocation = useMemo(
    () => selectedProject?.locations.find((location) => location.id === primaryLocationId),
    [primaryLocationId],
  );

  const primaryLocationPolygon = useMemo(() => {
    if (!selectedPrimaryLocation) return null;

    const { geometry, name, address, id } = selectedPrimaryLocation;
    return {
      id,
      visits_share: 1,
      location_name: name,
      location_address: address,
      location_subcategory: '',
      geo: convertPolygonToGeoData(geometry),
      centerPoint: getCenterCoordinate(geometry),
    };
  }, [selectedPrimaryLocation]);

  const hotspotsLocations = useMemo(() => {
    if (movementPeriod === MovementPeriodEnum.BEFORE) {
      if (locationType === LocationTypeEnum.POIS) {
        return hotspotsAllData?.before?.locations.pois;
      }
      return hotspotsAllData?.before?.locations.buildings;
    }
    if (movementPeriod === MovementPeriodEnum.AFTER) {
      if (locationType === LocationTypeEnum.POIS) {
        return hotspotsAllData?.after?.locations.pois;
      }
      return hotspotsAllData?.after?.locations.buildings;
    }
    if (selectedReport?.category === IReportCategory.AOI_DEFAULT) {
      if (locationType === LocationTypeEnum.POIS) {
        return hotspotsAllData?.during?.locations.pois;
      }
      return hotspotsAllData?.during?.locations.buildings;
    }
    return null;
  }, [movementPeriod, locationType, hotspotsAllData]);

  const maxValue = useMemo(
    () => hotspotsLocations?.reduce((res, i) => (res.visits_share > i.visits_share ? res : i), { visits_share: 0 }),
    [hotspotsLocations],
  );

  const minValue = useMemo(
    () => hotspotsLocations?.reduce((res, i) => (res.visits_share < i.visits_share ? res : i), { visits_share: 1 }),
    [hotspotsLocations],
  );

  const defaultPolygonName = useMemo(
    () => (locationType === LocationTypeEnum.BUILDINGS ? t('movement.detail.building') : 'POI'),
    [locationType],
  );

  const polygons = useMemo(
    () =>
      hotspotsLocations
        ?.map((location) => ({
          ...location,
          geo: convertPolygonToGeoData(location.geometry),
          centerPoint: getCenterCoordinate(location.geometry),
        }))
        .filter(
          (polygon) =>
            polygon.geo &&
            polygon.centerPoint &&
            polygon.visits_share >= movementTreshold.min &&
            polygon.visits_share <= movementTreshold.max,
        )
        .sort((a, b) => b.visits_share - a.visits_share),
    [hotspotsLocations, movementTreshold.min, movementTreshold.max],
  );

  return (
    <>
      {polygons && polygons.length > 0 && (
        <Polygon
          id="POI_POLYGONS"
          data={{
            type: 'FeatureCollection',
            features: createFeatureDataFromPolygon(
              polygons,
              movementPeriod,
              colorScheme,
              minValue?.visits_share || 0,
              maxValue?.visits_share || 100,
              t,
              defaultPolygonName,
              true,
            ),
          }}
        />
      )}
      {!polygons && primaryLocationPolygon && (
        <Polygon
          id="POI_POLYGONS"
          data={{
            type: 'FeatureCollection',
            features: createFeatureDataFromPolygon(
              [primaryLocationPolygon],
              movementPeriod,
              colorScheme,
              0,
              100,
              t,
              defaultPolygonName,
            ),
          }}
        />
      )}
      {polygons?.slice(0, 10).map((polygon, index) => (
        <Pin
          key={polygon.id}
          longitude={polygon.centerPoint[0]}
          latitude={polygon.centerPoint[1]}
          color={colors[index % colors.length]}
          name={
            getNotEmptyString(polygon.location_name) ||
            `${defaultPolygonName} (${
              getNotEmptyString(polygon.location_subcategory) ||
              getNotEmptyString(polygon.location_category) ||
              getNotEmptyString(polygon.location_address)
            })`
          }
          value={`${toFixNumber(polygon.visits_share * 100, 2)} %`}
        />
      ))}
    </>
  );
};
