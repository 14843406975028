import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Page from '../../templates/page/page';
import ProjectForm from '../../organisms/ProjectForm';
import { updateProject } from '../../../actions/actions';
import { Project } from '../../../types/project';
import routes from '../../../router/routes';

const UpdateProjectPage = (): ReactElement => {
  const dispatch = useDispatch();
  const {
    location: {
      state: { sourceProject },
    },
    push,
  } = useHistory();

  const updateProjectHandler = (project: Project): void => {
    const callback = () => push(routes.projects.list);
    dispatch(updateProject(project.id || '', project.name, project.description, callback));
  };

  return (
    <Page title="Update project">
      <ProjectForm onSubmit={updateProjectHandler} sourceProject={sourceProject} />
    </Page>
  );
};

export default UpdateProjectPage;
