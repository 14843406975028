import React from 'react';

export const SignOutIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.7778 1C11.2493 1 11.7015 1.16857 12.0349 1.46863C12.3683 1.76869 12.5556 2.17565 12.5556 2.6V4.2H10.7778V2.6H2.77778V15.4H10.7778V13.8H12.5556V15.4C12.5556 15.8243 12.3683 16.2313 12.0349 16.5314C11.7015 16.8314 11.2493 17 10.7778 17H2.77778C2.30628 17 1.8541 16.8314 1.5207 16.5314C1.1873 16.2313 1 15.8243 1 15.4V2.6C1 2.17565 1.1873 1.76869 1.5207 1.46863C1.8541 1.16857 2.30628 1 2.77778 1H10.7778Z" fill="white"/>
<path d="M13.2222 10.6V13L17.6667 9L13.2222 5V7.4H7V10.6H13.2222Z" fill="white"/>
</svg>

);

export default SignOutIcon;
