import { AppActions } from '../actions/actionTypes';

export interface LoginState {
  errorMessage: string;
  isAuthenticated: string;
  token: string;
}

const initialState: LoginState = {
  errorMessage: '',
  isAuthenticated: '',
  token: '',
};

export default function loginReducer(state = initialState, action: AppActions) {
  switch (action.type) {
    case 'LOGIN_ERROR':
    case 'FORGOT_PASSWORD_ERROR':
      return {
        ...state,
        errorMessage: action.payload.reason,
      };
    case 'LOGIN_SUCCESS':
      // localStorage.setItem('isAuthenticated', "true");
      return {
        ...state,
        isAuthenticated: 'true',
        // entries: action.payload.token,
        token: action.payload.token,
      };
    default:
      return state;
  }
}
