import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react/effector-react.cjs';
import { ReportItemType } from '../../../common/reportItems/types';
import { MainContext } from '../../../common/types/mainContext';
import {
  Absorption,
  AbsorptionData,
  AbsorptionTargetLocation,
  ContentVisualizationMode,
  ReportItemInfo,
} from '../../../common/types/projectsHierarchy';
import ReportItemDetail from '../../../common/reportItems/reportItemDetail';
import AbsorptionDetailChart from './AbsorptionDetailChart';
import AbsorptionDetailTable from './AbsorptionDetailTable';
import { useElementScreenshot } from '../../../common/hooks/useElementScreenshot';
import { exportSettingsModel } from '../../../common/export';
import { ChartEventsEnum } from '../../../common/chart/enums';
import { convertDataForExcel } from './utils';
import { ShowAverageToggle } from '../../../common/reportItems/reportItemDetail/showAverageToggle/showAverageToggle';

interface Props {
  useFixedScreenshotSize?: boolean;
}
export const AbsorptionDetail = ({ useFixedScreenshotSize }: Props) => {
  const { t } = useTranslation();

  const {
    selection: { selectedReport },
    absorptionSourceLocation,
    selectedLocationsIds,
    selectedLocations,
    contentVisualizationMode,
  } = useContext(MainContext);
  const { onMakeScreenshot, base64Image, elementRef, onClearScreenshotData } = useElementScreenshot({
    backgroundColor: `rgba(37, 55, 76, 1)`,
  });
  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const isChartReady = useRef<boolean>(false);
  const isExportMode = useStore(exportSettingsModel.$isExportMode);
  const [modifiedData, setModifiedData] = useState<AbsorptionData | null>(null);

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.ABSORPTION,
  ) as ReportItemInfo<Absorption>;

  const filteredData: AbsorptionData | null = useMemo(() => {
    if (reportItem) {
      if (reportItem.data && absorptionSourceLocation) {
        let absorption: AbsorptionData | null =
          reportItem.data?.data?.find((sourceLocation) => sourceLocation.location_id === absorptionSourceLocation.id) ||
          null;
        if (absorption) {
          const targets = absorption.data.filter(({ locationId }) => selectedLocationsIds.includes(locationId));
          absorption = {
            ...absorption,
            average:
              targets
                .map(({ avgWeightedAbsorption }) => avgWeightedAbsorption)
                .reduce((prevValue, nextValue) => prevValue + nextValue, 0) / targets.length,
            data: targets,
          };
        }
        return absorption;
      }
      return null;
    }
    return null;
  }, [JSON.stringify(reportItem), absorptionSourceLocation, selectedLocationsIds]);

  useEffect(() => {
    if (filteredData) {
      let chartData = { ...filteredData };
      const targets = chartData.data.filter(({ locationId }) => selectedLocationsIds.includes(locationId));
      if (targets?.length > 1) {
        const averageAcrossLocations = targets[0].dateChanges.map((header, headerIndex) => {
          const sum = targets.reduce(
            (acc, valueItem) =>
              valueItem.dateChanges[headerIndex]
                ? acc + (valueItem.dateChanges[headerIndex].absorption as number)
                : acc,
            0,
          );
          return {
            timestamp: header.timestamp,
            absorption: sum / targets.length || 0,
          };
        });
        chartData = {
          ...chartData,
          data: [
            {
              locationId: 'average',
              locationName: 'Average',
              avgWeightedAbsorption: 0,
              dateChanges: averageAcrossLocations,
            },
            ...chartData.data,
          ],
        };
      }
      setModifiedData(chartData);
    }
  }, [filteredData]);

  const noData = useMemo(() => !filteredData || filteredData.data.length === 0, [filteredData]);

  const absorbtionLocations = useMemo(() => {
    const locations = [...selectedLocations];
    if (absorptionSourceLocation) {
      locations.push(absorptionSourceLocation);
    }
    return locations;
  }, [selectedLocations, absorptionSourceLocation]);

  const onChartReady = useCallback(() => {
    if (reportItem?.data && !isChartReady.current) {
      isChartReady.current = true;
      if (exportOptions.ABSORPTION?.chart) {
        onMakeScreenshot();
      }
    }
  }, []);

  const chartEvents = {
    [ChartEventsEnum.FINISHED]: onChartReady,
  };

  useEffect(() => {
    if (!filteredData?.data) {
      exportSettingsModel.updateExportData({ [ReportItemType.ABSORPTION]: { chart: '', table: '' } });
    }
  }, [filteredData?.data]);

  useEffect(() => {
    if (base64Image) {
      exportSettingsModel.updateExportData({ [ReportItemType.ABSORPTION]: { chart: base64Image } });
      onClearScreenshotData();
    }
  }, [base64Image]);

  useEffect(() => {
    if (filteredData?.data && exportOptions.ABSORPTION?.table) {
      const data = convertDataForExcel(filteredData);
      exportSettingsModel.updateExportData({ [ReportItemType.ABSORPTION]: { table: data } });
    }
  }, [exportOptions.ABSORPTION]);

  const render = (data?: AbsorptionTargetLocation[] | null) => {
    if (!data) return;

    if (contentVisualizationMode === ContentVisualizationMode.VISUALIZATION || isExportMode) {
      return (
        <AbsorptionDetailChart
          data={data}
          projectLocations={absorbtionLocations}
          events={chartEvents}
          isScreenshotMode={isExportMode}
          useFixedScreenshotSize={useFixedScreenshotSize}
        />
      );
    }

    return <AbsorptionDetailTable data={data} locationName={String(modifiedData?.location_name)} />;
  };

  const minimizedData = useMemo(
    () => render(modifiedData?.data),
    [modifiedData?.data, contentVisualizationMode, isExportMode],
  );

  return (
    <div ref={elementRef} style={{ width: '100%', height: '100%' }}>
      <ReportItemDetail
        type={ReportItemType.ABSORPTION}
        title={t('absorption.title')}
        subtitle={t('absorption.detail.showingShareOfVisitors')}
        noData={noData}
        onMakeScreenshot={onMakeScreenshot}
      >
        {contentVisualizationMode === ContentVisualizationMode.VISUALIZATION && <ShowAverageToggle />}
        {filteredData?.data && minimizedData}
      </ReportItemDetail>
    </div>
  );
};
