import React from 'react';
import { ReportItemCardValue } from './types';
import styles from './reportItemCardValueList.module.scss';
import { Skeleton } from '../../../skeleton';
import { getTextWidth } from '../../../utils';

interface Props {
  values: ReportItemCardValue[];
  isGlobalLoading: boolean;
}

const ReportItemCardValueList: React.FC<Props> = ({ values, isGlobalLoading }) => {
  if (values.length <= 0) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      {values.map(({ name, value }) => {
        const nameWidth = getTextWidth(name, 'regular 14px ');
        const valueWidth = getTextWidth(value, 'regular 14px ');
        return (
          <div key={name} className={styles.item}>
            {isGlobalLoading ? (
              <Skeleton className={styles.skeletonName} width={`${nameWidth ? nameWidth + 20 : 0}px`} />
            ) : (
              <span className={styles.name}>{name}</span>
            )}
            {isGlobalLoading ? (
              <Skeleton className={styles.skeletonValue} width={`${valueWidth ? valueWidth + 10 : 0}px`} />
            ) : (
              <span className={styles.value}>{value}</span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReportItemCardValueList;
