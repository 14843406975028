import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MainContext } from '../../common/types/mainContext';
import { Point, SwitchModes } from '../../common/types/visualizationObjects';
import MapBase from '../../common/maps/mapBase/mapBase';
import Points from './points/points';
import CatchmentSwitch from './catchmentSwitch/catchmentSwitch';
import MovementSwitch from './movementSwitch/movementSwitch';
import { ReportItemType } from '../../common/reportItems/types';
import { MapBottomBar } from '../../common/maps/mapBottomBar';
import { getPolygonsByPoints } from './polygons/utils';
import { getClickHandlerTypeByReportItemType } from '../../common/maps/mapBase/utils/getClickHandlerTypeByReportItemType';
import { getClickedPolygon } from '../../common/maps/mapBase/utils/isPointInsidePolygon';
import { useAoiPolygons, usePoiPolygons } from './polygons/hooks';
import { AoiWithRoads } from './aoiWithRoads';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';
import { useURLParams } from '../../common/hooks';
import { useTripsPoints } from './trips/hooks';
import { ReportItemInfo, ReportItems } from '../../common/types/projectsHierarchy';
import { ExportMapContext } from '../../common/export/exportSettings/settings/mapContainer';
import { BasicPopup } from './popups';
import { IReportCategory } from '../../../types/report';
import { getPointsFromPolygon } from './polygons/utils/getPointsFromPolygon';

interface Props {
  onIdle?: VoidFunction;
}

export const BackgroundMap = ({ onIdle }: Props) => {
  const [viewport, setViewPort] = useState<{ geo: GeoJSON.Feature<GeoJSON.Geometry>[]; isByPoints: boolean }>({
    geo: [],
    isByPoints: false,
  });

  const {
    selection,
    primaryLocationId,
    setPopup,
    mapOptions,
    onChangeGlobalMapZoom,
    setPrimaryLocationId,
    resetMapViewport,
    catchmentLayer,
    movementMode,
    exportSelection,
    primaryLocationCoordinates,
    selectedLanguage,
    selectedLocations,
  } = useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);
  const [selectedReportItem, setSelectedReportItem] = useState<ReportItemInfo<ReportItems> | null>(null);
  const { onChangeParams } = useURLParams();

  const { polygons: poiPolygons, renderPolygons: poiRenderPolygons } = usePoiPolygons();
  const { polygons: aoiPolygons, renderPolygons: aoiRenderPolygons } = useAoiPolygons();
  const tripsPoints = useTripsPoints();

  const [showPois, togglePois] = useState<boolean>(true);

  const primaryLocation = useMemo(
    () => selectedLocations.find((loc) => loc.id === primaryLocationId),
    [primaryLocationId],
  );

  useEffect(() => {
    if (isExportMap) {
      setSelectedReportItem(exportSelection.selectedReportItem);
    } else {
      setSelectedReportItem(selection.selectedReportItem);
    }
  }, [selection.selectedReportItem, isExportMap, exportSelection.selectedReportItem]);

  const inIncludeAOIPolygons =
    selectedReportItem?.type === ReportItemType.ATTRACTION || selectedReportItem?.type === ReportItemType.ABSORPTION;

  const points = useMemo(() => {
    const points: Point[] =
      selectedReportItem?.visualization?.points ||
      selectedReportItem?.visualization?.heatmap?.find((location) => location.location_id === primaryLocationId)?.home
        .points ||
      (selectedReportItem?.type === ReportItemType.MOVEMENT &&
      selection.selectedReport?.category === IReportCategory.AOI_DEFAULT
        ? getPointsFromPolygon(primaryLocation?.geometry)
        : undefined) ||
      [];

    return points;
  }, [selectedReportItem, primaryLocationId]);

  const geoPoints = useMemo(
    () =>
      points.map((point) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
      })) as GeoJSON.Feature<GeoJSON.Geometry>[],
    [points],
  );

  const tripGeoPoints = useMemo(
    () =>
      tripsPoints.map((point) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
      })) as GeoJSON.Feature<GeoJSON.Geometry>[],
    [tripsPoints],
  );

  useEffect(() => {
    if (geoPoints.length > 0 && !inIncludeAOIPolygons) {
      if (poiPolygons) {
        // Locations = 0 when it's catchment/movement report item
        const locations = getPolygonsByPoints(poiPolygons);
        if (locations.length > 0) {
          const data = {
            geo: locations,
            isByPoints: true,
          };
          setViewPort(data);
        }
      } else if (catchmentLayer !== SwitchModes.TRIPS) {
        const data = {
          geo: geoPoints,
          isByPoints: true,
        };
        setViewPort(data);
      } else if (tripGeoPoints.length) {
        const data = {
          geo: tripGeoPoints,
          isByPoints: true,
        };
        setViewPort(data);
      }
    }
  }, [points, poiPolygons, catchmentLayer]);

  useEffect(() => {
    if (inIncludeAOIPolygons) {
      if (aoiPolygons) {
        const locations = getPolygonsByPoints(aoiPolygons);
        if (locations.length > 0) {
          const geo = [...geoPoints, ...locations];
          const data = {
            geo,
            isByPoints: true,
          };
          setViewPort(data);
        }
      }
    }
  }, [geoPoints, aoiPolygons]);

  /* Uncomment to perform polygon strings parsing test
  useEffect(() => {
    testParsingPolygons();
  }, []); */

  const openPopupHandler = (props: any | null) => {
    if (props && props.popupTitle && props.centerPoint && selectedReportItem) {
      setPopup({
        [selectedReportItem.type]: {
          title: props.popupTitle,
          coordinates: JSON.parse(props.centerPoint),
          data: props.popupData ? JSON.parse(props.popupData) : undefined,
        },
      });
    }
  };

  const onClickByPolygon = (point: [number, number]) => {
    const polygons = selection.selectedReportItem?.visualization?.poiPolygons;
    if (polygons) {
      const clickedPolygon = getClickedPolygon(point, polygons);

      if (clickedPolygon) {
        trackUserAction(
          'Primary location has been changed',
          UserActionsEnum.PRIMARY_LOCATION_CHANGE,
          `Set by click on map polygon. Location ID: ${clickedPolygon.location_id}`,
        );
        setPrimaryLocationId(clickedPolygon.location_id);
      }
    }
  };

  const isCatchment = useMemo(() => selectedReportItem?.type === ReportItemType.CATCHMENT, [selectedReportItem]);
  const isMovement = useMemo(() => selectedReportItem?.type === ReportItemType.MOVEMENT, [selectedReportItem]);

  const onChangeByType = (type: keyof typeof mapOptions, zoom: number, longitude: number, latitude: number) => {
    onChangeGlobalMapZoom({ [type]: { zoom, longitude, latitude } });
  };

  const onChangeViewportParams = (zoom: number, longitude: number, latitude: number) => {
    if (catchmentLayer === SwitchModes.TRIPS) {
      onChangeParams('catchmentTripRoutesZoom', zoom);
      onChangeParams('catchmentTripRoutesLongitude', longitude);
      onChangeParams('catchmentTripRoutesLatitude', latitude);
      return;
    }
    onChangeParams('zoom', zoom);
    onChangeParams('longitude', longitude);
    onChangeParams('latitude', latitude);
  };

  const onViewportChange = (zoom: number, longitude: number, latitude: number) => {
    if (viewport.isByPoints) {
      if (isCatchment) {
        if (catchmentLayer === SwitchModes.TRIPS) {
          onChangeByType('catchmentTripRoutes', zoom, longitude, latitude);
        } else {
          onChangeByType('catchment', zoom, longitude, latitude);
        }
        onChangeViewportParams(zoom, longitude, latitude);
        return;
      }
      if (isMovement) {
        onChangeByType('movement', zoom, longitude, latitude);
        onChangeViewportParams(zoom, longitude, latitude);
        return;
      }
      onChangeByType('default', zoom, longitude, latitude);
      onChangeViewportParams(zoom, longitude, latitude);
    }
  };

  const interactiveLayers = useMemo(() => {
    if (
      (isCatchment && (catchmentLayer === SwitchModes.POSTAL_CODES || catchmentLayer === SwitchModes.ISOCHRONES)) ||
      (isMovement && movementMode === SwitchModes.HOTSPOTS)
    ) {
      return ['POI_POLYGONS_layer'];
    }
    return null;
  }, [isCatchment, isMovement, catchmentLayer, movementMode]);

  return (
    <MapBase
      geo={viewport.geo}
      bboxPadding={100}
      onViewportChange={onViewportChange}
      bottomBar={<MapBottomBar />}
      onClickByCode={openPopupHandler}
      onClickByPolygon={onClickByPolygon}
      interactiveLayerIds={interactiveLayers}
      mapOptions={mapOptions}
      isReady={viewport.isByPoints}
      resetMapViewport={resetMapViewport}
      primaryLocationId={primaryLocationId}
      clickHandlerType={getClickHandlerTypeByReportItemType(selection.selectedReportItem?.type)}
      isCatchment={isCatchment}
      isMovement={isMovement}
      catchmentLayer={catchmentLayer}
      onIdle={onIdle}
      isExportMode={isExportMap}
      selectedReportItemName={selectedReportItem?.name}
      primaryLocationCoordinates={primaryLocationCoordinates}
      showPois={showPois}
      togglePois={togglePois}
      language={selectedLanguage}
      selectedReport={selection.selectedReport}
    >
      <Points />
      {Number(mapOptions.default.zoom) >
        (selection.selectedReport?.category === IReportCategory.AOI_DEFAULT ? 7.5 : 12) &&
        !inIncludeAOIPolygons &&
        poiRenderPolygons}
      {isCatchment && <CatchmentSwitch showPois={showPois} />}
      {isMovement && <MovementSwitch />}
      {inIncludeAOIPolygons && (
        <AoiWithRoads
          aoiRender={aoiRenderPolygons}
          poiRender={poiRenderPolygons}
          aoiPolygons={aoiPolygons}
          poiPolygons={poiPolygons}
        />
      )}
      <BasicPopup />
    </MapBase>
  );
};
