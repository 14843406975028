import { Retail } from '../../interfaces';
import { toFixNumber } from '../../../../../../../common/lib';
import { MarketIcon } from '../../../../../../../common/icons/charts';
import { separateThousandsByComma } from '../../../../../../../common/utils';

const colors = ['#DFD2EF', '#A67BDD', '#c1a3e7', '#d3bdee', '#e4d7f5', '#A6ADB6'];

const DEFAULT_ITEM = {
  id: 1,
  color: colors[1],
  title: 'Purchasing power',
  value: `N/A`,
  stake: 100,
  styles: {
    item: {
      width: '135px',
    },
    label: {
      width: '120px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '2',
    },
  },
};

const generateMain = (data: Retail[], currency: string) => {
  const main = data.slice(0, 5);
  return main.map(({ value, category }, index) => ({
    id: index + 1,
    color: colors[index],
    title: category,
    value: `${currency} ${separateThousandsByComma(value)}`,
    stake: Number(value),
    styles: {
      item: {
        width: '135px',
      },
      label: {
        width: '120px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
      },
    },
  }));
};

const generateAdditional = (data: Retail[], currency: string) => {
  const additional = data.slice(5, data.length);
  const value = additional.reduce((acc, { value }) => acc + Number(value), 0);
  return {
    id: 6,
    color: '#DFD2EF',
    title: 'More',
    value: `${currency} ${separateThousandsByComma(toFixNumber(value, 4))}`,
    stake: value,
    options: { clickable: true },
    styles: {
      label: {
        width: '120px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  };
};

export const getMarket = (value: Retail[] | null, currency: string) => ({
  id: 8,
  icon: MarketIcon,
  styles: { item: { left: value ? '-430px' : '-90px', top: '90px' } },
  data: value
    ? [...generateMain(value, currency), ...(value.length > 5 ? [generateAdditional(value, currency)] : [])]
    : [DEFAULT_ITEM],
});
