export const checkIsUserAuthenticated = () => {
  const dateTimeNow = new Date();
  const timeStamp = Math.floor(dateTimeNow.getTime() / 1000);
  const auth = localStorage.getItem('isAuthenticated') ? localStorage.getItem('isAuthenticated') : 'false';
  const storedExpireTimeStamp = localStorage.getItem('expireTimeStamp');
  if (storedExpireTimeStamp && timeStamp.toString() < storedExpireTimeStamp && auth && auth === 'true') {
    const updateDateTime = new Date();
    const expireDateTime = new Date(updateDateTime.setDate(updateDateTime.getDate() + 30));
    const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000);
    localStorage.setItem('expireTimeStamp', expireTimeStamp.toString());
    return true;
  }

  if (auth && auth === 'true') {
    localStorage.clear();
    window.location.reload();
  } else {
    localStorage.clear();
  }
  return false;
};
