import React from 'react';
import { ObjectSignature } from '../../types/ObjectSignature.interface';

interface Props {
  style?: ObjectSignature;
  fill: string;
  onClick?: () => void;
}
export const ArrowIcon: React.FC<Props> = ({ style, fill, ...rest }) => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} {...rest}>
    <path
      d="M5.25834 0.591406C4.93334 0.266406 4.40834 0.266406 4.08334 0.591406L0.258337 4.41641C-0.0666626 4.74141 -0.0666626 5.26641 0.258337 5.59141L4.08334 9.4164C4.40834 9.7414 4.93334 9.7414 5.25834 9.4164C5.58334 9.0914 5.58334 8.5664 5.25834 8.24141L2.025 4.99974L5.25834 1.76641C5.58334 1.44141 5.575 0.908073 5.25834 0.591406Z"
      fill={fill}
    />
  </svg>
);
