import React from 'react';

export const TrendIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 5.3335C13.0333 5.3335 12.4933 6.2935 12.7133 7.00683L10.3467 9.38016C10.1467 9.32016 9.85333 9.32016 9.65333 9.38016L7.95333 7.68016C8.18 6.96683 7.64 6.00016 6.66666 6.00016C5.7 6.00016 5.15333 6.96016 5.38 7.68016L2.34 10.7135C1.62666 10.4935 0.666664 11.0335 0.666664 12.0002C0.666664 12.7335 1.26666 13.3335 2 13.3335C2.96666 13.3335 3.50666 12.3735 3.28666 11.6602L6.32 8.62016C6.52 8.68016 6.81333 8.68016 7.01333 8.62016L8.71333 10.3202C8.48666 11.0335 9.02666 12.0002 10 12.0002C10.9667 12.0002 11.5133 11.0402 11.2867 10.3202L13.66 7.9535C14.3733 8.1735 15.3333 7.6335 15.3333 6.66683C15.3333 5.9335 14.7333 5.3335 14 5.3335Z"
      fill="white"
    />
    <path d="M10 6L10.6267 4.62L12 4L10.6267 3.38L10 2L9.38667 3.38L8 4L9.38667 4.62L10 6Z" fill="white" />
    <path
      d="M2.33333 7.33333L2.66666 6L4 5.66667L2.66666 5.33333L2.33333 4L2 5.33333L0.666664 5.66667L2 6L2.33333 7.33333Z"
      fill="white"
    />
  </svg>
);
