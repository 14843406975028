import React from 'react';
import { MapPopupData } from '../../../../../common/types/visualizationObjects';
import { TrendsChart } from '../../chart';
import { VisitTrendsBriefData } from '../../../../model/interfaces';
import styles from './style.module.scss';

interface Props {
  data: MapPopupData;
}
export const MapTooltip: React.FC<Props> = ({ data }) => {
  const { chartData, avgTraffic } = data.data as VisitTrendsBriefData;

  return chartData ? (
    <div>
      <div className={styles.header}>
        <label>Avg. monthly footfall</label>
        <p>{avgTraffic}</p>
      </div>
      <TrendsChart
        data={chartData.data}
        dates={chartData.dates}
        isPercentage={chartData.isPercentage}
        isLoading={false}
        isAbsoluteNumbersDisabled={chartData?.isAbsoluteNumbersDisabled}
        chartSizeType={'small'}
      />
    </div>
  ) : null;
};
