import React, { useContext, useMemo } from 'react';
import SeasonalityDetailDailyChart from './SeasonalityDetailDailyChart';
import { LocationInfo, Seasonality } from '../../../common/types/projectsHierarchy';
import SeasonalityDetailHourlyChart from './SeasonalityDetailHourlyChart';
import { ChartEvents } from '../../../common/chart/interfaces';
import { ChartEventsEnum } from '../../../common/chart/enums';
import { useDoubleClick } from '../../../common/chart/hooks';
import { MainContext } from '../../../common/types/mainContext';

interface Props {
  data: Seasonality | null;
  projectLocations: LocationInfo[];
  onReady?: (type: string) => VoidFunction;
  isScreenshotMode?: boolean;
  useFixedScreenshotSize?: boolean;
}

const SeasonalityDetailChart: React.FC<Props> = ({
  data,
  projectLocations,
  onReady,
  isScreenshotMode,
  useFixedScreenshotSize,
}) => {
  // const [locations, setLocations] = useState<{ [key in string]: boolean }>({});
  const { chartRef, onChange, selectedLocations: locations } = useDoubleClick();
  const { reportItemsDetails } = useContext(MainContext);

  const hourlyChartEvents: ChartEvents = useMemo(
    () => ({
      [ChartEventsEnum.LEGEND_SELECT_CHANGED]: onChange,
      [ChartEventsEnum.FINISHED]: onReady?.('hourly'),
    }),
    [data],
  );

  const dailyChartEvents: ChartEvents = useMemo(
    () => ({
      [ChartEventsEnum.FINISHED]: onReady?.('daily'),
    }),
    [data],
  );

  return (
    data && (
      <div>
        <SeasonalityDetailDailyChart
          data={data.daily}
          locations={locations}
          projectLocations={projectLocations}
          events={dailyChartEvents}
        />
        <SeasonalityDetailHourlyChart
          data={reportItemsDetails.showHoursByWeek ? data.hourlyByWeek : data.hourlyByDay}
          events={hourlyChartEvents}
          projectLocations={projectLocations}
          isScreenshotMode={isScreenshotMode}
          useFixedScreenshotSize={useFixedScreenshotSize}
          chartRef={chartRef}
          locations={locations}
        />
      </div>
    )
  );
};

export default SeasonalityDetailChart;
