import { ChangeEvent, useState } from 'react';

type UploadCallback = (file: File) => void;

const initialOptions = {
  maxSize: 1048576,
  formats: ['png'],
};

export const useImageUploader = (
  onUpload: UploadCallback,
  defaultImage = '',
  trackAction: () => void,
  options = initialOptions,
) => {
  const [url, setUrl] = useState<string | null>(defaultImage);
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState({ maxSize: '', format: '' });

  const checkFormat = (format: string) => {
    const ext = format.split('/')[1];

    if (ext) {
      return options.formats.includes(ext);
    }
  };

  const checkMaxSize = (size: number) => size <= options.maxSize;

  const resetAvatar = () => {
    setErrors({ maxSize: '', format: '' });
    setUrl(defaultImage);
    setFile(null);
  };

  const handler = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (files) {
      const file = files[0];

      const isFormatValid = checkFormat(file.type);
      const isSizeValid = checkMaxSize(file.size);

      if (file) {
        const imageURL = window.URL.createObjectURL(file);
        if (isFormatValid && isSizeValid) {
          onUpload(file);
          setUrl(imageURL);
          setFile(file);
          setErrors({ maxSize: '', format: '' });
          trackAction();
        }

        if (!isFormatValid) {
          setErrors({ format: `Selected format isn't supported`, maxSize: '' });
          return;
        }

        if (!isSizeValid) {
          setErrors({ maxSize: `Max image size is 1MB`, format: '' });
        }
      }
    }
  };

  return { file, url, handler, errors, resetAvatar };
};
