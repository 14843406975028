enum FfiPlan {
  FFI_NL_PROFESSIONAL = 'FFI_NL_PROFESSIONAL',
  FFI_NL_ESSENTIAL = 'FFI_NL_ESSENTIAL',
  FFI_DE_PROFESSIONAL = 'FFI_DE_PROFESSIONAL',
  FFI_DE_ESSENTIAL = 'FFI_DE_ESSENTIAL',
}

export const ffiPlanTranslationMap = new Map();
ffiPlanTranslationMap.set(FfiPlan.FFI_NL_PROFESSIONAL, 'FFI Professional for Netherlands');
ffiPlanTranslationMap.set(FfiPlan.FFI_NL_ESSENTIAL, 'FFI Essential for Netherlands');
ffiPlanTranslationMap.set(FfiPlan.FFI_DE_PROFESSIONAL, 'FFI Professional for Germany');
ffiPlanTranslationMap.set(FfiPlan.FFI_DE_ESSENTIAL, 'FFI Essential for Germany');

export default FfiPlan;
