import { SwitcherItem } from '../../../common/switchers/default/interfaces';
import { MovementPeriodEnum, MovementVisitorTypeEnum } from '../../../common/reportItems/types';
import { SelectItem } from '../../../common/types/SelectItem.interface';

export const periodDays: SelectItem[] = [
  { value: MovementPeriodEnum.BEFORE, label: MovementPeriodEnum.BEFORE },
  { value: MovementPeriodEnum.DURING, label: MovementPeriodEnum.DURING },
  { value: MovementPeriodEnum.AFTER, label: MovementPeriodEnum.AFTER },
];

export const visitorsTypes: SwitcherItem[] = [
  { value: MovementVisitorTypeEnum.VISITORS, id: 1 },
  { value: MovementVisitorTypeEnum.RESIDENTS_WORKERS, id: 2 },
];
