import React, { useContext } from 'react';
import { NavigationControl } from 'react-map-gl';
import s from './navigation.module.scss';
import { MapBaseOptions } from '../mapBase/mapBase';

interface Props {
  showCompass?: boolean;
  rounded?: boolean;
}
export const Navigation = ({ showCompass = true, rounded }: Props) => {
  const { isHidden } = useContext(MapBaseOptions);

  if (isHidden) return <></>;

  return (
    <NavigationControl
      className={`${s.navigation} ${rounded ? s.rounded : ''}`}
      zoomInLabel="Zoom in"
      zoomOutLabel="Zoom out"
      showCompass={showCompass}
    />
  );
};
