import { request } from '../request';
import { API_URLS } from '../../routes';
import { Credentials } from '../../auth/forgotPassword/createNewPassword/interfaces';

interface Data extends Credentials {
  token: string;
}

export const createNewPassword = (data: Data) => {
  const url = API_URLS.CREATE_NEW_PASSWORD;
  return request<Response>({ url, data, method: 'POST' });
};
