import React from 'react';

export const PowerPointIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_bd_8811_12021)">
        <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.1" />
        <path
          d="M23.3 15.5H18.1C17.3785 15.5 16.8 16.085 16.8 16.8V27.2C16.8 27.9215 17.3785 28.5 18.1 28.5H25.9C26.6215 28.5 27.2 27.9215 27.2 27.2V19.4L23.3 15.5ZM25.9 27.2H18.1V16.8H22.65V20.05H25.9V27.2ZM24.08 21.22C23.755 20.895 23.365 20.7 22.52 20.7H20.05V26.55H21.09V24.405H22.52C23.365 24.405 23.69 24.275 24.08 23.95C24.47 23.625 24.6 23.17 24.6 22.585C24.6 22 24.47 21.61 24.08 21.22ZM23.235 23.3C23.04 23.495 22.845 23.625 22.455 23.625H21.09V21.545H22.39C22.78 21.545 23.04 21.675 23.235 21.87C23.43 22.065 23.495 22.26 23.495 22.585C23.495 22.91 23.43 23.105 23.235 23.3Z"
          fill="white"
          style={{ transform: 'translate(-10px,-9px)' }}
        />
      </g>
      <defs>
        <filter id="filter0_bd_8811_12021" x="-15" y="-15" width="54" height="54" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="17.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8811_12021" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.113725 0 0 0 0 0.145098 0 0 0 0.25 0" />
          <feBlend mode="overlay" in2="effect1_backgroundBlur_8811_12021" result="effect2_dropShadow_8811_12021" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_8811_12021" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
