import { MovementMapTypesEnum } from '../enums';
import { MovementPeriodEnum } from '../../../../reportItems/types';

const typeToRequestMode = {
  [`${MovementPeriodEnum.BEFORE}_${MovementMapTypesEnum.HEAT_MAP}`]: 'VISITOR_BEFORE',
  [`${MovementPeriodEnum.DURING}_${MovementMapTypesEnum.HEAT_MAP}`]: 'VISITOR_DURING',
  [`${MovementPeriodEnum.AFTER}_${MovementMapTypesEnum.HEAT_MAP}`]: 'VISITOR_AFTER',
};

export const getRequestModeFromType = (movementPeriod: MovementPeriodEnum, item: MovementMapTypesEnum) =>
  typeToRequestMode[`${movementPeriod}_${item}`];
