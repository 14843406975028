import React from 'react';

export const PlusIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.66671 5.66634H5.66671V9.66634H4.33337V5.66634H0.333374V4.33301H4.33337V0.333008H5.66671V4.33301H9.66671V5.66634Z"
      fill="white"
    />
  </svg>
);
