import React from 'react';
import styles from './styles.module.scss';

interface Props {
  locationsWithMissingAbsoluteValues: string[];
  locationsWithMissingMonthlyAbsoluteValues: string[];
}

export const AlertMessage: React.FC<Props> = ({
  locationsWithMissingAbsoluteValues,
  locationsWithMissingMonthlyAbsoluteValues,
}) => (
  <div className={styles.container}>
    <span>
      <span className={styles.messageContainer}>
        {locationsWithMissingAbsoluteValues.length > 0 && (
          <span>
            Please note! We might not be able to provide precise absolute footfall estimations for some locations due to
            limited training data.
          </span>
        )}
        {locationsWithMissingMonthlyAbsoluteValues.length > 0 && (
          <span>
            Please note! We might not be able to provide precise absolute footfall estimations in some months due to
            limited training data.
          </span>
        )}
      </span>
    </span>
    <span className={styles.footer}>
      Learn how to improve the accuracy by providing training data:{' '}
      <a href="https://placesense.ai/data-partnership/">click here</a>.
    </span>
  </div>
);
