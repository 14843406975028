import React from 'react';
import DurationCardChart from '../../durationCardChart';
import { MapPopupData } from '../../../../../common/types/visualizationObjects';
import { VisitDurationBriefData } from '../../../../model/interfaces';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  data: MapPopupData;
}

export const MapTooltip = ({ data }: Props) => {
  const { chartData, avgDuration } = data.data as VisitDurationBriefData;
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.information}>
        <p className={styles.label}>Average time spent in location</p>
        <p className={styles.value}>{`${avgDuration?.toFixed(0)} ${t('reportItem.min')}`}</p>
      </div>
      <DurationCardChart data={chartData.data} average={avgDuration || 0} isLoading={false} />
    </>
  );
};
