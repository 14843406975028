import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportItemInfo, Trend, TrendAverage, TrendsMapPointsData } from '../../../common/types/projectsHierarchy';
import { MainContext } from '../../../common/types/mainContext';
import { TrendsChart } from './chart';

import { TrendScopesEnum } from '../enum';
import { PeriodsEnum, ReportItemType } from '../../../common/reportItems/types';
import ReportItemCard from '../../../common/reportItems/reportItemCard';
import { MetricType } from './metricType';
import { fetchTrendsData } from '../services';
import { SelectItem } from '../../../common/types/SelectItem.interface';
import { generateMapPoints } from '../../utils/mappers/generateMapPoints';
import { useToggle, useURLParams } from '../../../common/hooks';
import { formatNumberToReadableFormat } from '../../../common/utils';
import { toFixNumber } from '../../../common/lib';
import { VisitTrendsMainTooltip } from './tooltips/main';
import { dashboardModel } from '../../../dasboardLoader';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { useTooltipStartPosition } from '../../../common/reportItems/hooks';
import { reportItemDetailsModel } from '../../model';
import { REQUEST_DELAY } from '../../types';

export const TrendsCard = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReport, selectedProject, selectedReportItem },
    selectedTimeFrames,
    selectedLocationsIds,
    selectedLocations,
    primaryLocationId,
    updateSelectedReportItem,
    updateReportItemsDetailsItems,
    updateReportItemOfSelectedReport,
    updateReportItemsDetailsMetricType,
  } = useContext(MainContext);

  const [modifiedData, setModifiedData] = useState<number[]>([]);
  const [scope, setScope] = useState(TrendScopesEnum.ALL_LOCATION);
  const [isPercentage, toggleIsPercentage] = useToggle(true);
  const [isLoading, setIsLoading] = useToggle(false);
  const [fetched, setFetched] = useToggle(true);
  const [isTooltipOpen, toggleTooltipOpen] = useToggle(false);
  const isFirstDataLoadRef = useRef(false);
  const { tooltipIconRef, position } = useTooltipStartPosition();
  const { params, isParsingDone, onChangeParams } = useURLParams();

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.FOOTFALL,
  ) as ReportItemInfo<Trend>;

  const locations = selectedProject?.locations || [];

  const getAverageData = (data: Trend | null) => {
    if (!data) return '';

    const { locationsCountVisits } = data.visitCount;

    if (primaryLocationId) {
      const dataAccordingToPrimaryLocation = locationsCountVisits.get(primaryLocationId);
      const isBelowConfidence: boolean = (dataAccordingToPrimaryLocation?.confidenceLevel || 0) < 0.65;
      return isBelowConfidence
        ? 'N/A'
        : String(formatNumberToReadableFormat(dataAccordingToPrimaryLocation?.absoluteNumber));
    }

    return '';
  };

  const getAverageNumberForPointsByScope = (
    { singleLocation, absoluteLocation, allLocations }: TrendAverage,
    scope: TrendScopesEnum,
  ) => {
    switch (scope) {
      case TrendScopesEnum.ALL_LOCATION:
        return allLocations;
      default:
        return absoluteLocation;
    }
  };

  const convertDataForPoints = (data: TrendsMapPointsData[], scope: TrendScopesEnum) =>
    data.map(({ average, location_id, location_name, confidenceLevel }) => {
      const averageNumberByScope = getAverageNumberForPointsByScope(average, scope);
      const isBelowConfidenceLevel = (confidenceLevel || 0) < 0.65;
      return {
        location_id,
        location_name,
        average:
          scope === TrendScopesEnum.ABSOLUTE_NUMBER
            ? isBelowConfidenceLevel
              ? 'N/A'
              : formatNumberToReadableFormat(averageNumberByScope)
            : averageNumberByScope !== 0
            ? `${toFixNumber(averageNumberByScope * 100, 2)}%`
            : '0',
      };
    });

  useEffect(() => {
    if (reportItem?.data) {
      if (selectedReportItem?.type === ReportItemType.FOOTFALL) {
        updateSelectedReportItem({
          ...reportItem,
          visualization: {
            ...reportItem.visualization,
            points: generateMapPoints(convertDataForPoints(reportItem.data.mapPointsData, scope), locations),
          },
        });
      }
    }
  }, [selectedReportItem?.type, scope, JSON.stringify(reportItem?.data)]);

  useEffect(() => {
    if (reportItem?.data === undefined && reportItem?.id && !isLoading) {
      (async function () {
        await getData(true);
      })();
    }
  }, [reportItem]);

  useEffect(() => {
    if (reportItem?.data || (reportItem?.data === null && reportItem?.id && !isLoading)) {
      (async function () {
        await getData();
      })();
    }
  }, [selectedLocations, selectedTimeFrames]);

  const getData = async (setDetails?: boolean) => {
    setFetched(false);
    setIsLoading(true);
    dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.FOOTFALL, value: false });
    if (selectedReportItem?.type !== ReportItemType.FOOTFALL) {
      await new Promise((resolve) => setTimeout(resolve, REQUEST_DELAY));
    }
    const response = await fetchTrendsData(
      reportItem.id,
      selectedLocationsIds,
      PeriodsEnum.MONTHLY,
      selectedLocations,
      selectedTimeFrames,
    );
    setFetched(true);
    setIsLoading(false);
    if (response) {
      updateReportItemOfSelectedReport(response);
      if (setDetails) {
        updateReportItemsDetailsItems?.('trends', [response]);
      }
    } else if (selectedTimeFrames?.length === 0) {
      const emptyData = { ...reportItem, visualization: null, data: null };
      updateReportItemOfSelectedReport(emptyData);
      if (setDetails) {
        updateReportItemsDetailsItems?.('trends', [emptyData]);
      }
    }
    dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.FOOTFALL, value: true });
  };

  const isAbsoluteNumbersDisabled = reportItem?.data?.isAbsoluteNumbersDisabled;

  useEffect(() => {
    if (reportItem?.data) {
      const data =
        reportItem.data.averageByDifferentMetricsInLine[
          isAbsoluteNumbersDisabled ? 'singleLocations' : 'absoluteNumber'
        ];

      if (primaryLocationId) {
        const dataAccordingToPrimaryLocation = data[primaryLocationId];
        setModifiedData(dataAccordingToPrimaryLocation);
      }
    }
  }, [reportItem?.data, primaryLocationId]);

  useEffect(() => {
    if (isParsingDone) {
      const { metricType } = params;
      if (metricType) {
        updateReportItemsDetailsMetricType(metricType);
        if (metricType === TrendScopesEnum.SINGLE_LOCATION) {
          setScope(TrendScopesEnum.ALL_LOCATION);
        } else setScope(metricType as TrendScopesEnum);
      } else {
        setScope(TrendScopesEnum.ALL_LOCATION);
        updateReportItemsDetailsMetricType(TrendScopesEnum.ALL_LOCATION);
      }
    }
  }, [isParsingDone]);

  useEffect(() => {
    toggleIsPercentage(scope !== TrendScopesEnum.ABSOLUTE_NUMBER);
  }, [scope]);

  const onChangeMetricType = (type: SelectItem) => {
    trackUserAction(
      'Visit trends metric type changed in control panel',
      UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
      `Metric type: ${type.value}`,
    );
    setScope(type.value as TrendScopesEnum);

    // Need to replace space to avoid extra symbols in URL
    const formattedId = type.value.replaceAll(' ', '_');
    onChangeParams('metricType', formattedId);
    updateReportItemsDetailsMetricType(type.value);
  };

  const metricTypes = useMemo(
    () =>
      Object.values(TrendScopesEnum)
        .map((value) => ({
          value,
          label: `visitTrends.enum.${value}`,
          disabled: value === TrendScopesEnum.ABSOLUTE_NUMBER && isAbsoluteNumbersDisabled,
        }))
        .filter(({ disabled, value }) => !disabled && value !== TrendScopesEnum.SINGLE_LOCATION),
    [isAbsoluteNumbersDisabled],
  );

  const noData = useMemo(
    () => fetched && (!reportItem || !reportItem?.data),
    [reportItem, selectedTimeFrames, fetched, isLoading, selectedLocationsIds],
  );

  const availableTypes = selectedReport?.report_items?.map((value) => value.type);

  useEffect(() => {
    if (!isFirstDataLoadRef.current && reportItem?.data?.data) {
      isFirstDataLoadRef.current = true;
      const withAbsoluteNumbers = Object.values(TrendScopesEnum).length === metricTypes.length;
      if (withAbsoluteNumbers || !isAbsoluteNumbersDisabled) {
        setScope(TrendScopesEnum.ABSOLUTE_NUMBER);
        updateReportItemsDetailsMetricType(TrendScopesEnum.ABSOLUTE_NUMBER);
      }
    }
  }, [reportItem?.data?.data]);

  useEffect(() => {
    if (reportItem?.data) {
      reportItemDetailsModel.reportItemsBriefDataChanged({
        [ReportItemType.FOOTFALL]: {
          avgTraffic: getAverageData(reportItem?.data),
          chartData: {
            data: modifiedData,
            dates: reportItem?.data?.dates,
            isPercentage,
            isAbsoluteNumbersDisabled: Boolean(isAbsoluteNumbersDisabled),
          },
        },
      });
    }
  }, [modifiedData, reportItem?.data, isPercentage, isAbsoluteNumbersDisabled]);

  const onClose = () => {
    toggleTooltipOpen(false);
  };

  return !availableTypes?.includes(ReportItemType.FOOTFALL) ? (
    <div />
  ) : (
    <ReportItemCard
      reportItemType={ReportItemType.FOOTFALL}
      values={
        !isAbsoluteNumbersDisabled
          ? [
              {
                name: t('visitTrends.card.avgMonthlyTraffic'),
                value: getAverageData(reportItem?.data),
              },
            ]
          : []
      }
      reportItem={reportItem}
      isLoading={isLoading}
      noData={noData}
      additionalInformation={
        <MetricType
          activeItem={scope}
          items={reportItem.data?.data ? metricTypes : []}
          onChangeMetricType={onChangeMetricType}
          isLoading={isLoading}
        />
      }
      onOpenTooltip={toggleTooltipOpen}
      tooltipRef={tooltipIconRef}
    >
      <>
        <VisitTrendsMainTooltip onClose={onClose} isOpen={isTooltipOpen} position={position} />
        <TrendsChart
          data={modifiedData}
          dates={reportItem?.data?.dates}
          isPercentage={isPercentage}
          isLoading={isLoading}
          isAbsoluteNumbersDisabled={Boolean(isAbsoluteNumbersDisabled)}
        />
      </>
    </ReportItemCard>
  );
};
