import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../style.module.scss';
import { ReportItemsData, LocationsData } from '../../../types';
import { FullFilledSwitcher } from '../../../../common/switchers';
import LocationsDropdown from '../../../../common/locationsDropdown/locationsDropdown';
import { ButtonTooltip } from '../../../../common/controls/tooltip/buttonTooltip';
import { InformationIcon } from '../../../../common/icons/reportItems';

interface TProps {
  data: ReportItemsData;
  locationsData: LocationsData;
  onChange: (data: ReportItemsData) => void;
}

export const AttractionSettings = ({ data, locationsData, onChange }: TProps) => {
  const { t } = useTranslation();

  const tooltipIcon = (title: string) => (
    <ButtonTooltip title={title}>
      <span>
        <InformationIcon />
      </span>
    </ButtonTooltip>
  );

  return (
    <div className={styles.settingsBox}>
      <div className={styles.row}>
        <div className={styles.header}>
          {t('create.stepThree.selectLocationsToAnalyze')}
          {tooltipIcon(t('create.stepThree.selectAttractionPOIsTooltip'))}
        </div>
        <div className={styles.component}>
          <LocationsDropdown
            locations={locationsData.locations}
            selectedLocations={data.attraction_pois}
            onChange={(pois) => onChange({ ...data, attraction_pois: pois })}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.header}>
          {t('create.stepThree.linkLocations')}
          {tooltipIcon(t('create.stepThree.linkPOIsTooltip'))}
        </div>
        <div className={styles.component}>
          <FullFilledSwitcher
            value={data.attraction_linkPois}
            onChange={() => onChange({ ...data, attraction_linkPois: !data.attraction_linkPois })}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.header}>
          {t('create.stepThree.selectCloseByAreas')}
          {tooltipIcon(t('create.stepThree.selectAOIsTooltip'))}
        </div>
        <div className={styles.component}>
          <LocationsDropdown
            locations={locationsData.locations}
            selectedLocations={data.attraction_aois}
            onChange={(aois) => onChange({ ...data, attraction_aois: aois })}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.header}>
          {t('create.stepThree.linkAois')}
          {tooltipIcon(t('create.stepThree.linkAOIsTooltip'))}
        </div>
        <div className={styles.component}>
          <FullFilledSwitcher
            value={data.attraction_linkAois}
            onChange={() => onChange({ ...data, attraction_linkAois: !data.attraction_linkAois })}
          />
        </div>
      </div>
    </div>
  );
};
