import React from 'react';

export const SliderIcon = () => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.425 13.4963C9.04029 14.164 8.07682 14.164 7.69211 13.4963L0.779097 1.49927C0.394948 0.832603 0.87612 1.54716e-06 1.64555 1.47976e-06L15.4716 2.68693e-07C16.241 2.01296e-07 16.7222 0.832603 16.338 1.49927L9.425 13.4963Z"
      fill="#00B2F1"
    />
  </svg>
);

export default SliderIcon;
