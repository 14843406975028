export function maxLengthOfString(value: string | null, maxLen: number) {
  if (value && value.length > maxLen) {
    const cutLen = maxLen >= 3 ? maxLen - 3 : 0;
    return `${value.substring(0, cutLen)}...`;
  }
  return value;
}

export const getTextWidth = (text: string, font: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    canvas.remove();
    return metrics.width;
  }
  return null;
};

export function getNotEmptyString(value: string | null | undefined) {
  if (value && value.length > 0) return value;
  return null;
}

export const replaceStringSpace = (value?: string, searchValue = ' ', replacer = '') => {
  if (!value) return '';

  return value.replaceAll(searchValue, replacer);
};

export const capitalizeEachWordInString = (value: string) => {
  const arr = value.split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(' ');
};

export const convertStringToCamelCase = (value: string) => {
  return value.split(' ').join('');
};

export const makeFirstLetterLow = (value: string) => {
  return value.charAt(0).toLowerCase() + value.slice(1);
};
