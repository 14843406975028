import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { ReportItemType, ReportItemVisualizationMode } from '../../../common/reportItems/types';
import { MainContext } from '../../../common/types/mainContext';
import { Catchment, ContentVisualizationMode, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import ReportItemDetail from '../../../common/reportItems/reportItemDetail';
import CatchmentDetailTable from './CatchmentDetailTable';
import { CatchmentItemValue } from '../types';
import { Chart } from './Chart';
import { CharacteristicOfAudienceChart } from './CharacteristicOfAudience';
import { useElementScreenshot } from '../../../common/hooks/useElementScreenshot';
import { exportSettingsModel } from '../../../common/export';
import { convertDataForExcel } from './utils/convertDataForExcel';
import { HeaderTooltip } from './CharacteristicOfAudience/tooltip/headerTooltip';

const catchmentLevels = new Map<number, string>();
catchmentLevels.set(1, 'neighborhood');
catchmentLevels.set(2, 'large city/municipality');
catchmentLevels.set(3, 'municipality');
catchmentLevels.set(4, 'region/district');
catchmentLevels.set(5, 'state/province');

const titles = {
  [ReportItemVisualizationMode.CHART]: 'catchment.socioTitle',
  [ReportItemVisualizationMode.Graph]: 'catchment.socioTitle',
};

const subtitles = {
  [ReportItemVisualizationMode.CHART]: 'catchment.detail.showVisitorsAnalytic',
  [ReportItemVisualizationMode.Graph]: 'catchment.detail.showVisitorsAnalytic',
};

export const CatchmentDetail = () => {
  const { t } = useTranslation();

  const {
    selection: { selectedReport },
    primaryLocationId,
    reportItemsDetails,
    contentVisualizationMode,
    selectedTimeFrames,
  } = useContext(MainContext);
  const [mode, setMode] = useState<ReportItemVisualizationMode>(ReportItemVisualizationMode.CHART);
  const { onMakeScreenshot, base64Image, elementRef, onClearScreenshotData } = useElementScreenshot({
    backgroundColor: `rgba(37, 55, 76, 1)`,
  });
  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const isExportMode = useStore(exportSettingsModel.$isExportMode);
  const catchment =
    (selectedReport?.report_items?.find(
      ({ type }) => ReportItemType.CATCHMENT === type,
    ) as ReportItemInfo<Catchment>) || null;

  const primaryLocationData = useMemo(() => {
    const reportItem = selectedReport?.report_items?.find(
      (i) => i.type === ReportItemType.CATCHMENT,
    ) as ReportItemInfo<Catchment>;

    if (reportItem && reportItem.data && primaryLocationId) {
      return reportItem.data.data.find((item) => item.location_id === primaryLocationId);
    }
    return null;
  }, [JSON.stringify(selectedReport), primaryLocationId]);

  const onMapLoad = () => {
    if (primaryLocationData && exportOptions.CATCHMENT?.chart && isExportMode) {
      onMakeScreenshot();
    }
  };

  const renderData = (data: CatchmentItemValue) => {
    if (contentVisualizationMode === ContentVisualizationMode.TABLE) {
      return <CatchmentDetailTable data={data} source={reportItemsDetails.catchmentType} />;
    }

    if (contentVisualizationMode === ContentVisualizationMode.VISUALIZATION || isExportMode) {
      if (mode === ReportItemVisualizationMode.CHART) return <Chart onLoad={onMapLoad} />;
      if (mode === ReportItemVisualizationMode.Graph) return <CharacteristicOfAudienceChart />;
    }
  };

  useEffect(() => {
    if (!primaryLocationData) {
      exportSettingsModel.updateExportData({ [ReportItemType.CATCHMENT]: { chart: '', table: '' } });
    }
  }, [primaryLocationData]);

  useEffect(() => {
    if (base64Image) {
      exportSettingsModel.updateExportData({ [ReportItemType.CATCHMENT]: { chart: base64Image } });
      onClearScreenshotData();
    }
  }, [base64Image]);

  useEffect(() => {
    if (primaryLocationData && exportOptions.CATCHMENT?.table) {
      const data = convertDataForExcel(
        primaryLocationData,
        reportItemsDetails.catchmentType,
        reportItemsDetails.catchmentMetricType,
      );
      exportSettingsModel.updateExportData({ [ReportItemType.CATCHMENT]: { table: data } });
    }
  }, [exportOptions.CATCHMENT, primaryLocationData, reportItemsDetails.catchmentMetricType]);

  const getTitle = () => {
    if (ContentVisualizationMode.TABLE === contentVisualizationMode) {
      return t('catchment.tableTitle');
    }
    return t(titles[mode]);
  };

  const getSubtitle = () => {
    if (ContentVisualizationMode.TABLE === contentVisualizationMode) {
      const level = catchment?.data?.catchmentLevel ? catchmentLevels.get(catchment.data.catchmentLevel) : '-';
      return t('catchment.detail.tableSubtitle').replace('POSTCODE', level || '-');
    }
    return t(subtitles[mode]);
  };

  return (
    <div ref={elementRef} style={{ width: '100%', height: '100%' }}>
      <ReportItemDetail
        type={ReportItemType.CATCHMENT}
        title={getTitle()}
        subtitle={getSubtitle()}
        mode={mode}
        setMode={setMode}
        onMakeScreenshot={onMakeScreenshot}
        hideToggle={isExportMode || contentVisualizationMode === ContentVisualizationMode.TABLE}
        tooltipComponent={
          contentVisualizationMode === ContentVisualizationMode.VISUALIZATION ? (
            <HeaderTooltip mode={mode} />
          ) : undefined
        }
      >
        {primaryLocationData && renderData(primaryLocationData)}
      </ReportItemDetail>
    </div>
  );
};
