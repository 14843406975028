import { RefObject, useEffect, useState } from 'react';
import { useWindowSize } from '../../hooks';

export const useHeight = (wrapperRef: RefObject<HTMLDivElement>) => {
  const [graphHeight, setGraphHeight] = useState<number>();
  const { height } = useWindowSize();

  useEffect(() => {
    const element = wrapperRef.current;

    if (element && height) {
      const offset = element.getBoundingClientRect().height;

      setGraphHeight(offset);
    }
  }, [height]);

  return { height: graphHeight };
};
