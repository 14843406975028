import { Age } from '../../interfaces';
import { replaceValueToNA } from '../replaceValueToNA';
import { AgesIcon } from '../../../../../../../common/icons/charts';
import { StakeType } from '../../../../../../../common/chart/demographic/interfaces';

const getTop = (length: number) => {
  const ITEMS_PER_ROW = 4;
  const rowsCount = Math.ceil(length / ITEMS_PER_ROW);
  switch (rowsCount) {
    case 1:
      return 45;
    case 2:
      return 70;
    default:
      return 95;
  }
};
const mockData = [
  {
    category: '18-24',
    value: null,
    valuePct: 100,
  },
  {
    category: '25-34',
    value: null,
    valuePct: 0,
  },
  {
    category: '35-44',
    value: null,
    valuePct: 0,
  },
  {
    category: '45-54',
    value: null,
    valuePct: 0,
  },
  {
    category: '55-64',
    value: null,
    valuePct: 0,
  },
  {
    category: '55-64',
    value: null,
    valuePct: 0,
  },
  {
    category: '60+',
    value: null,
    valuePct: 0,
  },
];
const colors = [
  '#EADED5',
  '#DBAA85',
  '#e6c4aa',
  '#edd5c2',
  '#f4e6da',
  '#A6ADB6',
  '#E4B7A0',
  '#A45C40',
  '#B97D60',
  '#F0CCB0',
];
const generateData = (data: Age[], useValue: boolean) =>
  data.map(({ category, valuePct, value }, index) => {
    const usedValue = useValue ? value : valuePct;
    return {
      id: index,
      color: colors[index],
      title: category,
      value: usedValue ? `${usedValue}%` : replaceValueToNA(usedValue),
      stake: valuePct,
      styles: { label: { width: '50px' }, value: {}, item: {} },
    };
  });
export const getAge = (value: Age[] | null) => {
  const isDataAvailable = Boolean(value);
  return {
    id: 9,
    icon: AgesIcon,
    type: 'statistic' as StakeType,
    styles: { item: { gridTemplateColumns: `repeat(4, 1fr)`, top: getTop(value?.length || mockData.length) } },
    data: generateData(value || mockData, !isDataAvailable),
  };
};
