import React from 'react';
import { PanelTitle } from '../common/page/controlPanel';
import styles from './createReportPanel.module.scss';

interface TProps {
  step: number;
}
export const CreateReportPanel = ({ step }: TProps) => (
  <>
    <PanelTitle title="Create report" />
    <div className={styles.createReportPanel}>
      <div className={`${styles.row} ${step === 1 ? styles.active : ''}`}>
        <div className={styles.circle}>1</div>
        <div className={styles.title}>Define report</div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.placeholder} ${styles.verticalLine}`} />
        <div className={styles.subtitle}>Add basic info to define your report</div>
      </div>
      <div className={`${styles.row} ${step === 2 ? styles.active : ''}`}>
        <div className={styles.circle}>2</div>
        <div className={styles.title}>Add locations</div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.placeholder} ${styles.verticalLine}`} />
        <div className={styles.subtitle}>Search locations or create new</div>
      </div>
      <div className={`${styles.row} ${step === 3 ? styles.active : ''}`}>
        <div className={styles.circle}>3</div>
        <div className={styles.title}>Setup data</div>
      </div>
      <div className={styles.row}>
        <div className={styles.placeholder} />
        <div className={styles.subtitle}>Check out the report items</div>
      </div>
    </div>
  </>
);
