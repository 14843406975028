import JsPDF from 'jspdf';
import { getPreview } from './getPreview';
import { getLogoMark } from './getLogoMark';
import { getCompanyLogo } from './getCompanyLogo';

export const generatePreviewPage = async (instance: JsPDF, reportName: string, companyLogo: string) => {
  const preview = getPreview();
  const logo = getLogoMark();
  instance.addImage({ imageData: preview, format: 'PNG', x: 0, y: 0, height: 230, width: 300 });
  instance.addImage({ imageData: logo, format: 'JPEG', x: 277, y: 195, height: 15, width: 15 });
  const companyLogoData = await getCompanyLogo(companyLogo, 1000, 1000, 500);
  if (companyLogoData) {
    instance.addImage({
      imageData: companyLogoData,
      format: 'PNG',
      x: 175,
      y: 145,
      height: 20,
      width: 20,
    });
  }
  instance.setTextColor('#707070').setFontSize(22).setFont('', 'bold').text(reportName, 175, 180, { maxWidth: 80 });
  return instance;
};
