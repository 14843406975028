import { AppActions } from '../actions/actionTypes';
import { Location } from '../types/location';

export interface LocationsState {
  entries?: Location[];
}

const initialState: LocationsState = {
  entries: undefined,
};

export default function locationsReducer(state = initialState, action: AppActions): LocationsState {
  switch (action.type) {
    case 'LOAD_LOCATIONS_SUCCESS':
      return {
        ...state,
        entries: action.payload.locations,
      };
    default:
      return state;
  }
}
