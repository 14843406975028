export enum UserActionsEnum {
  // Default
  ACTION_NOT_SET = 'ACTION_NOT_SET',
  // User profile
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PROFILE_IMAGE = 'CHANGE_PROFILE_IMAGE',
  CHANGE_COMPANY_LOGO = 'CHANGE_COMPANY_LOGO',
  USER_INFO = 'USER_INFO',
  // Rerport actions
  TOGGLE_CONTROL_PANEL = 'TOGGLE_CONTROL_PANEL',
  OPEN_PROJECT = 'OPEN_PROJECT',
  OPEN_REPORT = 'OPEN_REPORT',
  OPEN_REPORT_ITEM = 'OPEN_REPORT_ITEM',
  VISUALIZATION_MODE_CHANGED = 'VISUALIZATION_MODE_CHANGED',
  VISUALIZATION_SUB_MODE_CHANGED = 'VISUALIZATION_SUB_MODE_CHANGED',
  REPORT_ITEM_SETTING_CHANGED = 'REPORT_ITEM_SETTING_CHANGED',
  TOOLTIP_ICON_CLICK = 'TOOLTIP_iCON_CLICK',
  POLYGON_OR_POINT_CLICK = 'POLYGON_OR_POINT_CLICK',
  // Export
  REPORT_EXPORT = 'REPORT_EXPORT',
  // Filtering
  PRIMARY_LOCATION_CHANGE = 'PRIMARY_LOCATION_CHANGE',
  TIME_PICKER_CHANGE = 'TIME_PICKER_CHANGE',
  SORTING_CHANGE = 'SORTING_CHANGE',
  SEARCH_PROJECTS_REPORTS = 'SEARCH_PROJECTS',
  GALLERY_CLICK = 'GALLERY_CLICK',
  // Map related
  RESET_MAP = 'RESET_MAP',
  // Create
  CREATE_REPORT = 'CREATE_REPORT',
}

export enum EnvironmentsEnum {
  PRODUCTION = 'Production',
  QA = 'QA',
  DEV = 'Development',
  LOCAL = 'Localhost',
  UNKNOWN = 'Unknown',
}
