import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { MainContext } from '../../common/types/mainContext';
import { ReportData } from '../types';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';
import ContentContainer from '../../common/page/pageContent/contentContainer';
import ButtonsContainer from '../../common/page/pageContent/buttonsContainer';
import Table from '../../common/page/table/table';
import TableRow from '../../common/page/table/tableRow/tableRow';
import { SidebarContext } from '../../common/sidebarMenu/sidebarContext';
import { Button } from '../../common/controls/buttons';
import { ProjectsDropdown } from './controls/projects/projectsDropdown';
import { TextInput } from '../../common/controls/input/textInput';
import { TextArea } from '../../common/controls/input/textArea';
import { AreaTypeDropdown } from './controls/areaType/areaTypeDropdown';
import { FullFilledSwitcher } from '../../common/switchers';
import { getDefaultReportDates } from '../../common/utils';
import YearMonthRangePicker from '../../common/yearMonthRangePicker/YearMonthRangePicker';
import { LocationManagerModel } from '../../common/manageLocations/model';
import { RecurrenceFrequencyDropdown } from './controls/recurrence/recurrenceFrequencyDropdown';
import styles from './stepOne.module.scss';
import CatchmentRadiusPopup from './controls/catchmentRadiusPopup/CatchmentRadiusPopup';
import TableRowTooltip from '../../common/page/table/tableRow/tooltip';
import { useTooltipStartPosition } from '../../common/reportItems/hooks';
import CatchmentTooltip from './controls/tooltips/catchmentTooltip';
import { ReportCategoryDropdown } from './controls/areaType/reportCategoryDropdown';

interface TProps {
  data: ReportData;
  setData: (data: ReportData) => void;
  navigate: (step: number) => void;
  onCancel: VoidFunction;
}

export const StepOne = ({ data, setData, navigate, onCancel }: TProps) => {
  const { t } = useTranslation();
  const selectedLocationsInformation = useStore(LocationManagerModel.$selectedLocationsInformation);
  const { isOpened } = useContext(SidebarContext);
  const {
    selection: { selectedProject },
    projectsHierarchy,
  } = useContext(MainContext);
  const reportDates = getDefaultReportDates();
  const [isCatchmentPopActive, setIsCatchmentPopupActive] = useState<boolean>(false);
  const [catchmentLevel, setCatchmentLevel] = useState<string>('0');
  const [isRecurrenceTooltipOpened, setIsRecurrenceTooltipOpened] = useState(false);
  const { tooltipIconRef: recurrenceTooltipRef, position: recurrenceTooltipPosition } = useTooltipStartPosition();
  const [isCatchmentTooltipOpened, setIsCatchmentTooltipOpened] = useState(false);
  const { tooltipIconRef: catchmentTooltipRef, position: catchmentTooltipPosition } = useTooltipStartPosition();

  const closeCatchmentPopup = () => setIsCatchmentPopupActive(false);

  useEffect(() => {
    trackUserAction('Create report', UserActionsEnum.CREATE_REPORT, 'Step 1');
    if (data.startDate === null || data.endDate === null || data.projectId === '') {
      const firstProject = projectsHierarchy.sort((a, b) => a.name.localeCompare(b.name))[0]?.id;
      const projectId = selectedProject?.id || firstProject || '';
      setData({ ...data, projectId, startDate: reportDates[0], endDate: reportDates[1] });
    }
  }, []);

  const clickNext = () => {
    if (data.name.length > 0) {
      navigate(2);
    }
  };

  const projectsDropdownChange = (projectId: string) => {
    if (projectId !== data.projectId) {
      const locationsToRemove = selectedLocationsInformation.filter((loc) => !loc.isNew);
      locationsToRemove.forEach((loc) => {
        LocationManagerModel.removeSelectedLocation(loc.id.toString());
      });
      setData({ ...data, projectId });
    }
  };

  const toggleRecurrence = () => {
    if (data.recurrence) {
      setData({ ...data, recurrence: false });
    } else {
      setData({ ...data, recurrence: true, endDate: reportDates[1] });
    }
  };

  const confirmCatchmentPopup = () => {
    setData({ ...data, areaType: catchmentLevel });
    setIsCatchmentPopupActive(false);
  };

  const openCatchmentPopAlert = (areaType: string) => {
    if (Number.parseInt(areaType) >= 4) {
      setCatchmentLevel(areaType);
      setIsCatchmentPopupActive(true);
    } else {
      setData({ ...data, areaType });
    }
  };

  return (
    <ContentContainer isOpened={isOpened} spacing>
      <CatchmentRadiusPopup
        isOpen={isCatchmentPopActive}
        area={catchmentLevel}
        onClose={closeCatchmentPopup}
        onSave={confirmCatchmentPopup}
      />
      <Table title={t('create.defineReport')} subtitle={t('create.addBasicInfo')} blueTitle={`${t('create.step')} 1`}>
        <TableRow
          name={t('create.project')}
          value={<ProjectsDropdown projectId={data.projectId} onChange={projectsDropdownChange} />}
        />
        <TableRow
          name={t('create.name')}
          value={<TextInput name="reportName" value={data.name} onChange={(name) => setData({ ...data, name })} />}
        />
        <TableRow
          name={t('create.description')}
          value={
            <TextArea
              name="reportDescription"
              value={data.description}
              onChange={(description) => setData({ ...data, description })}
              width="350px"
              height="140px"
            />
          }
        />
        <TableRow
          name={t('create.reportType')}
          tooltipTitle={t('create.reportType')}
          // TODO add tooltip when design is ready
          /* tooltip={
            <TableRowTooltip
              onClose={() => setIsRecurrenceTooltipOpened(false)}
              isOpen={isRecurrenceTooltipOpened}
              position={recurrenceTooltipPosition}
              title={t('create.recurrence')}
              content={t('create.recurrenceTooltip')}
            />
          } */
          openTooltip={() => setIsCatchmentTooltipOpened(true)}
          tooltipRef={catchmentTooltipRef}
          value={
            <ReportCategoryDropdown
              reportCategory={data.category}
              onChange={(category) => setData({ ...data, category })}
            />
          }
        />
        <TableRow
          name={t('create.recurrence')}
          tooltipTitle={t('create.recurrenceTooltip')}
          tooltip={
            <TableRowTooltip
              onClose={() => setIsRecurrenceTooltipOpened(false)}
              isOpen={isRecurrenceTooltipOpened}
              position={recurrenceTooltipPosition}
              title={t('create.recurrence')}
              content={t('create.recurrenceTooltip')}
            />
          }
          openTooltip={() => setIsRecurrenceTooltipOpened(true)}
          tooltipRef={recurrenceTooltipRef}
          value={
            <div className={styles.automaticUpdates}>
              <FullFilledSwitcher value={data.recurrence} onChange={toggleRecurrence} />
              <RecurrenceFrequencyDropdown
                frequency={data.recurrenceFrequency}
                onChange={(value) => setData({ ...data, recurrenceFrequency: value })}
                disabled={!data.recurrence}
              />
            </div>
          }
        />
        <TableRow
          name={t('create.timeFrame')}
          value={
            data.startDate &&
            data.endDate && (
              <YearMonthRangePicker
                start={data.startDate}
                end={data.endDate}
                min={new Date(new Date().setDate(new Date().getDate() - 365 * 3))}
                max={reportDates[1]}
                onChange={(start, end) => setData({ ...data, startDate: start, endDate: end })}
                disabledTo={data.recurrence}
              />
            )
          }
        />
        <TableRow
          name={t('create.areaType')}
          tooltipTitle={t('create.areaType')}
          tooltip={
            <CatchmentTooltip
              onClose={() => setIsCatchmentTooltipOpened(false)}
              isOpen={isCatchmentTooltipOpened}
              position={catchmentTooltipPosition}
            />
          }
          openTooltip={() => setIsCatchmentTooltipOpened(true)}
          tooltipRef={catchmentTooltipRef}
          value={<AreaTypeDropdown areaType={data.areaType} onChange={openCatchmentPopAlert} />}
        />
      </Table>
      <ButtonsContainer className={styles.buttonContainer}>
        <Button layout="primary" onClick={onCancel}>
          {t('reportItem.cancel')}
        </Button>
        <Button layout="primary" onClick={clickNext} disabled={data.name.length === 0 || data.projectId.length === 0}>
          {t('create.next')}
        </Button>
      </ButtonsContainer>
    </ContentContainer>
  );
};
