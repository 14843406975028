import React from 'react';
import styles from './controlPanel.module.scss';
import { TCommonProps } from '../../types/commonProps';

interface Props extends TCommonProps {
  isOpen?: boolean;
}
export const ControlPanel = ({ children, isOpen }: Props) => (
  <div className={`${styles.wrapper} ${isOpen ? styles.expanded : styles.closed}`}>
    <div className={styles.panel}>{children}</div>
  </div>
);
