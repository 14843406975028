import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { AppState } from '../../../../reducers/reducers';
import { resetPassword } from '../../../../actions/actions';
import { Page, Title, Message, ErrorMessage, PasswordInput, SubmitButton, LinkButton } from '../common';

interface IResetPasswordPageParams {
  token: string;
}

const ResetPasswordPage = () => {
  const { token } = useParams<IResetPasswordPageParams>();
  const [newPassword, setNewPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const errorMessage = useSelector<AppState, string | null>((state) => state.errorMessage.message);

  const resetPasswordHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(resetPassword(token, newPassword, repeatedPassword, () => history.replace('/resetPassword/thankYou')));
  };

  return (
    <Page>
      <form name="resetPasswordForm" onSubmit={(e) => resetPasswordHandler(e)}>
        <Title>New password</Title>
        <Message>Password must be at least 8 characters long and include letters, digits and symbols.</Message>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <PasswordInput
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          error={!!(errorMessage && errorMessage !== '')}
        />
        <PasswordInput
          value={repeatedPassword}
          onChange={(e) => {
            setRepeatedPassword(e.target.value);
          }}
          error={!!(errorMessage && errorMessage !== '')}
        />
        <SubmitButton text="Update password" />
        <LinkButton url="/" text="Login to a different account" />
      </form>
    </Page>
  );
};

export default ResetPasswordPage;
