import React from 'react';
import styles from './loader.module.scss';
import { Logo } from '../../logo';

interface Props {
  label: string;
  progress: number;
}

export const Loader: React.FC<Props> = ({ label, progress }) => (
  <div className={styles.modal}>
    <Logo />
    <div className={styles.progressBar}>
      <span className={styles.progress} style={{ width: `${progress}%` }} />
      <span className={styles.background} />
    </div>
    <div className={styles.information}>
      <span className={styles.status}>{label}</span>
      <span className={styles.percentage}>{progress}%</span>
    </div>
  </div>
);
