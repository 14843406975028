import React from 'react';

export const VisualizationTableIcon: React.FC = () => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1.5" cy="2" r="1.5" fill="white" />
    <path
      d="M16 3H6C5.44771 3 5 2.55228 5 2C5 1.44772 5.44771 1 6 1H16C16.5523 1 17 1.44772 17 2C17 2.55228 16.5523 3 16 3Z"
      fill="white"
    />
    <circle cx="1.5" cy="7" r="1.5" fill="white" />
    <path
      d="M16 8H6C5.44771 8 5 7.55228 5 7C5 6.44772 5.44771 6 6 6H16C16.5523 6 17 6.44772 17 7C17 7.55228 16.5523 8 16 8Z"
      fill="white"
    />
    <circle cx="1.5" cy="12" r="1.5" fill="white" />
    <path
      d="M16 13H6C5.44771 13 5 12.5523 5 12C5 11.4477 5.44771 11 6 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13Z"
      fill="white"
    />
  </svg>
);
