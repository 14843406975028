import React, { useMemo } from 'react';
import * as turf from '@turf/turf';
import { GeoJSON } from 'echarts/types/src/coord/geo/geoTypes';
import { Area } from '../../../common/types/visualizationObjects';
import { getPolygonsByPoints } from '../polygons/utils';
import { getDistanceBetweenTwoPoints } from '../../../common/maps/utils';
import { getClosestPoint } from './utils';
import { TripsAnimation } from '../trips/TripsAnimation';
import { PointDistance } from '../trips/interfaces';

interface Props {
  aoiRender: React.ReactNode;
  poiRender: React.ReactNode;
  aoiPolygons?: Area[];
  poiPolygons?: Area[];
}
export const AoiWithRoads = ({ aoiRender, poiRender, aoiPolygons, poiPolygons }: Props) => {
  const points = useMemo(() => {
    const polygons = poiPolygons?.map((log) => getPolygonsByPoints([log]));

    return polygons?.map((locations) => {
      const locationsClosestPoints: PointDistance[] = [];
      locations.forEach(({ geometry }) => {
        if (geometry.type === 'Point') {
          const [longitude, latitude] = geometry.coordinates;
          const point = [longitude, latitude];
          if (aoiPolygons) {
            const aoiPoints = getPolygonsByPoints(aoiPolygons);
            const pointsDistances: PointDistance[] = [];
            aoiPoints.forEach((polygon) => {
              const { geometry } = polygon;
              if (geometry.type === 'Point') {
                const { coordinates } = geometry;
                const distance = getDistanceBetweenTwoPoints(point, coordinates);
                pointsDistances.push({ distance, to: point, from: coordinates });
              }
            });
            const closestPointToLocation = getClosestPoint(pointsDistances);

            locationsClosestPoints.push(closestPointToLocation);
          }
        }
      });

      return getClosestPoint(locationsClosestPoints);
    });
  }, [poiPolygons, aoiPolygons]);

  const isIntersection = useMemo(() => {
    const aoiGEO = aoiPolygons?.[0].geo;
    return poiPolygons?.some((polygon) => {
      const { geo } = polygon;

      if (aoiGEO) {
        const aoiPolygonType = aoiGEO.geometry.type;
        if (
          (aoiPolygonType === 'Polygon' || aoiPolygonType === 'MultiPolygon') &&
          (aoiPolygonType === 'Polygon' || aoiPolygonType === 'MultiPolygon')
        ) {
          const intersection = turf.intersect(
            geo as GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>,
            aoiGEO as GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>,
          );
          return Boolean(intersection);
        }
      }
      return false;
    });
  }, [poiPolygons, aoiPolygons]);

  return (
    <div>
      {aoiRender}
      {poiRender}
      {!isIntersection && (
        <TripsAnimation
          oneToOnePoints={points}
          type="oneToOne"
          apiType={'walking'}
          animationSpeed={550}
          useDistance
          trailLength={70}
        />
      )}
    </div>
  );
};
