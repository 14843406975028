import React from 'react';
import { Marker } from 'react-map-gl';
import styles from './centerPin.module.scss';
import { CenterPinIcon } from '../../icons/map/centerPinIcon';

interface PinProps {
  longitude: number;
  latitude: number;
  onClick?: () => void;
  name?: string;
  showName?: boolean;
  pointSize: 'big' | 'small';
}

const CenterPin: React.FC<PinProps> = ({ latitude, longitude, onClick, name, showName, pointSize }) => (
  <Marker longitude={longitude} latitude={latitude} className={styles.mapGlMarker}>
    <div className={styles.root} onClick={onClick}>
      <div className={`${styles.pin} ${styles[pointSize]}`}>
        <CenterPinIcon />
      </div>
      {showName && name && (
        <div className={styles.name} title={name}>
          <span>{name}</span>
        </div>
      )}
    </div>
  </Marker>
);

export default React.memo(CenterPin);
