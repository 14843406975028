import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './linkButton.module.scss';

interface TProps {
  text: string;
  url: string;
  external?: boolean;
  filled?: boolean;
}

export const LinkButton = (props: TProps) =>
  props.external ? (
    <div className={`${styles.loginPageLink} ${props.filled ? styles.filled : ''}`}>
      <a href={props.url}>{props.text}</a>
    </div>
  ) : (
    <div className={styles.loginPageLink}>
      <NavLink to={props.url} title={props.text}>
        {props.text}
      </NavLink>
    </div>
  );
