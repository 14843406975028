import React from 'react';

interface Props {
  fill: string;
}
export const CaretLeftIcon: React.FC<Props> = ({ fill }) => (
  <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.2584 1.09168C4.9334 0.766681 4.4084 0.766681 4.0834 1.09168L0.258398 4.91668C-0.0666016 5.24168 -0.0666016 5.76668 0.258398 6.09168L4.0834 9.91668C4.4084 10.2417 4.9334 10.2417 5.2584 9.91668C5.5834 9.59168 5.5834 9.06668 5.2584 8.74168L2.02506 5.50001L5.2584 2.26668C5.5834 1.94168 5.57507 1.40835 5.2584 1.09168Z"
      fill={fill}
    />
  </svg>
);
