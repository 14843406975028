import React from 'react';
import { Layer, Source } from 'react-map-gl';
import * as GeoJSON from 'geojson';

interface PolygonProps {
  id: string;
  data: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection | null;
  lineColor?: string;
  ignoreShadow?: boolean;
  borderLineWidth?: number;
  borderLineOpacity?: number;
  opacity?: number;
  ignoreOutline?: boolean;
  outlineColor?: string;
}

const Polygon: React.FC<PolygonProps> = ({
  id,
  data,
  lineColor = '#5a6673',
  ignoreShadow,
  borderLineWidth = 2,
  borderLineOpacity = 0.6,
  outlineColor = 'white',
  opacity = 1,
  ignoreOutline,
}) =>
  data ? (
    <Source id={id} type="geojson" data={data}>
      {!ignoreShadow && (
        <Layer
          id={`${id}_layer_shadow`}
          type="line"
          paint={{
            'line-color': 'black',
            'line-translate': [6, 6],
            'line-blur': 15,
            'line-width': {
              stops: [
                [1, 8],
                [6, 9],
                [9, 10],
                [12, 11],
                [17, 12],
              ],
            },
            'line-opacity': 0.6,
          }}
        />
      )}
      <Layer
        id={`${id}_layer`}
        type="fill"
        paint={{
          'fill-color': ['get', 'color'],
          'fill-opacity': opacity,
        }}
      />
      {!ignoreOutline && (
        <Layer
          id={`${id}_layer_outline`}
          type="line"
          paint={{
            'line-color': outlineColor,
            'line-width': borderLineWidth,
            'line-opacity': borderLineOpacity,
          }}
        />
      )}
    </Source>
  ) : null;

export default Polygon;
