import React from 'react';
import styles from './style.module.scss';

interface Props {
  onChange: (id: string) => void;
  isChecked: boolean;
  id: string;
}
export const RadioButton: React.FC<Props> = ({ onChange, isChecked, id }) => (
  <span className={`${styles.wrapper} ${isChecked ? styles.checked : ''}`}>
    <input type="radio" checked={isChecked} onChange={() => onChange(id)} id={id} />
    <label htmlFor={id} onChange={() => onChange(id)} />
  </span>
);
