import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectInfo } from '../../../common/types/projectsHierarchy';
import { ThreeDotsMenu } from '../../../common/threeDotsMenu';
import { EditProjectPopup } from './popup/editProjectPopup';
import { DeleteProjectPopup } from './popup/deleteProjectPopup';

interface TProps {
  project: ProjectInfo;
}

export const ProjectMenu = ({ project }: TProps) => {
  const { t } = useTranslation();
  const [isEditPopupOpen, toggleEditPopup] = useState<boolean>(false);
  const [isDeletePopupOpen, toggleDeletePopup] = useState<boolean>(false);

  const onItemClick = (item: string) => {
    if (item === 'Edit') {
      toggleEditPopup(true);
    }
    if (item === 'Delete') {
      toggleDeletePopup(true);
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ThreeDotsMenu title="Settings" options={['Edit', 'Delete']} onChange={onItemClick} id={project.id} />
      <EditProjectPopup project={project} isOpen={isEditPopupOpen} toggleOpen={toggleEditPopup} />
      <DeleteProjectPopup project={project} isOpen={isDeletePopupOpen} toggleOpen={toggleDeletePopup} />
    </div>
  );
};
