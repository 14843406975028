import React, { useContext, useEffect, useMemo } from 'react';
import { MainContext } from '../../../common/types/mainContext';
import Pin from '../../../common/maps/pin/pin';
import { Point } from '../../../common/types/visualizationObjects';
import { getPointClickHandlerAccordingReportItemType } from './utils';
import { usePoints } from './hooks';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { useStore } from 'effector-react';
import { reportItemDetailsModel } from '../../../reportItems/model';

const Points = () => {
  const {
    selection: { selectedReportItem, selectedProject },
    primaryLocationId,
    setPopup,
    popup,
    setPrimaryLocationId,
    attractionSourceLocation,
    absorptionSourceLocation,
    setPrimaryLocationCoordinates,
  } = useContext(MainContext);
  const popupReportItemsData = useStore(reportItemDetailsModel.$reportItemsBriefData);
  const points = usePoints();

  const primaryLocation = useMemo(() => {
    return selectedProject?.locations.find((location) => location.id === primaryLocationId);
  }, [primaryLocationId]);

  const setNewPrimaryLocation = (locationId: string) => {
    if (locationId !== primaryLocationId) {
      trackUserAction(
        'Primary location has been changed',
        UserActionsEnum.PRIMARY_LOCATION_CHANGE,
        `Set by click on map pin. Location ID: ${locationId}`,
      );
      setPrimaryLocationId(locationId);
    }
  };

  //Note: The useEffect change data in the popup when switch between locations
  useEffect(() => {
    if (selectedReportItem && primaryLocation && popup) {
      const currentPopupData = popup[selectedReportItem?.type];

      if (currentPopupData.data) {
        const [longitude, latitude] = currentPopupData.coordinates;
        const data = getPointClickHandlerAccordingReportItemType(
          selectedReportItem,
          primaryLocation.name,
          primaryLocation.id,
          { longitude, latitude },
          popupReportItemsData,
          attractionSourceLocation?.id,
        );

        if (data) {
          setPopup({ [selectedReportItem.type]: data });
        }
      }
    }
  }, [popupReportItemsData]);

  const onPinClick = (point: Point) => {
    setNewPrimaryLocation(point.location_id);
    setPrimaryLocationCoordinates({ latitude: point.latitude, longitude: point.longitude });
    if (selectedReportItem && primaryLocation) {
      const data = getPointClickHandlerAccordingReportItemType(
        selectedReportItem,
        primaryLocation.name,
        primaryLocation.id,
        point,
        popupReportItemsData,
        attractionSourceLocation?.id,
        absorptionSourceLocation?.id,
      );

      if (data) {
        setPopup({ [selectedReportItem.type]: data });
      }
    }
  };

  return (
    <>
      {points
        .filter(({ longitude, latitude }) => longitude && latitude)
        .map((point) => (
          <Pin
            key={point.location_id}
            longitude={point.longitude}
            latitude={point.latitude}
            color={point.color}
            name={point.name}
            value={point.value}
            primary={primaryLocationId !== null && point.location_id === primaryLocationId}
            onClick={() => onPinClick(point)}
          />
        ))}
    </>
  );
};

export default Points;
