import React from 'react';

export const EditAvatarIcon = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5158 8.32632L13.7789 9.06316L12.3053 7.58947L13.0421 6.85263C13.1158 6.77895 13.1895 6.70526 13.3368 6.70526C13.4105 6.70526 13.5579 6.77895 13.6316 6.85263L14.5895 7.81053C14.6632 7.95789 14.6632 8.17895 14.5158 8.32632ZM7.36842 12.4526V14H8.91579L13.4105 9.50526L11.9368 8.03158L7.36842 12.4526ZM6.04947 9.45368L4.60526 7.71474L2.57895 10.3158H7.45684L9.32842 8.51053L8.07579 6.84526L6.04947 9.45368ZM5.89474 12.4526V11.8263L5.93158 11.7895H1.47368V1.47368H11.7895V6.12316L13.2632 4.70105V1.47368C13.2632 0.663158 12.6074 0 11.7895 0H1.47368C0.663158 0 0 0.663158 0 1.47368V11.7895C0 12.6074 0.663158 13.2632 1.47368 13.2632H5.89474V12.4526Z"
      fill="white"
    />
  </svg>
);
