import moment from 'moment';
import { PeriodsEnum } from '../../../common/reportItems/types';

const initialOptions = {
  format: {
    monthly: 'MMM, YY',
    weekly: 'MMM DD, YY',
  },
};
export const dateFormatter = (value: string, period?: PeriodsEnum, options = initialOptions) => {
  const {
    format: { monthly, weekly },
  } = options;
  return moment(Number(value)).format(period === PeriodsEnum.WEEKLY ? weekly : monthly);
};
