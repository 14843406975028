import { AppActions } from '../actions/actionTypes';
import { Project } from '../types/project';
import { DELETE_PROJECT_SUCCESS, FETCH_FFI_PLANS_SUCCESS, UPDATE_PROJECT_SUCCESS } from '../actions/actions';
import FfiPlan from '../types/FfiPlan';

export interface ProjectsState {
  entries?: Project[];
  ffiPlans: FfiPlan[];
}

const initialState: ProjectsState = {
  entries: undefined,
  ffiPlans: [],
};

export default function projectsReducer(state = initialState, action: AppActions): ProjectsState {
  switch (action.type) {
    case 'LOAD_PROJECTS_SUCCESS':
      return {
        ...state,
        entries: action.payload.projects,
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        entries: (state.entries || []).filter((entry) => entry.id !== action.payload.projectId),
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        entries: (state.entries || []).map((entry) => {
          if (entry.id === action.payload.projectId) {
            return {
              ...entry,
              name: action.payload.name,
              description: action.payload.description,
            };
          }
          return { ...entry };
        }),
      };
    case FETCH_FFI_PLANS_SUCCESS: {
      return {
        ...state,
        ffiPlans: action.payload.ffiPlans,
      };
    }
    default:
      return state;
  }
}
