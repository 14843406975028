import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { DefaultPageTemplate } from '../../common/templates/deafult';
import { Routes } from '../../routes';
import UserProfilePage from '../../userProfile/userProfilePage';
import { DashboardPage } from '../dashboard';
import { ChangePasswordPage } from '../changPassword';
import { SidebarContext } from '../../common/sidebarMenu/sidebarContext';
import { useToggle } from '../../common/hooks';
import {
  CatchmentMapBars,
  LocationMapBarItem,
  MapBottomBarContext,
  MovementMapBars,
  MovementMapTypesEnum,
  CatchmentMapTypesEnum,
} from '../../common/maps/mapBottomBar';
import { SlidingBarViewOptions } from '../../common/sliders';
import CreateReportPage from '../../createReport/createReportPage';
import { ActivateAccountCongratulationsPage } from '../activateAccountCongratulations';
import ManageLocationsPage from '../manageLocations';

export const PagesWithSidebar = () => {
  const [menuTabs, setMenuTabs] = useState({ projects: false, reports: false });
  const [isOpenedSidebar, toggleIsOpenedSidebar] = useToggle(false);
  const [catchmentMapData, setCatchmentMapData] = useState<CatchmentMapBars>({
    heatmap: null,
    postalCodes: null,
    trips: null,
  });
  const [catchmentMapViewOptions, setCatchmentMapViewOptions] = useState<SlidingBarViewOptions>({
    expanded: true,
    collapsed: false,
  });

  const [movementMapViewOptions, setMovementMapViewOptions] = useState<SlidingBarViewOptions>({
    expanded: true,
    collapsed: false,
  });

  const [movementMapData, setMovementMapData] = useState<MovementMapBars>({ heatmap: null });

  const onChangeMenuTabs = (state: any) => {
    setMenuTabs((prevState) => ({ ...prevState, ...state }));
  };

  const onChangeCatchmentMapData = (type: CatchmentMapTypesEnum, data: LocationMapBarItem[]) => {
    setCatchmentMapData((prevState) => ({ ...prevState, [type]: data }));
  };

  const onChangeCatchmentViewOptions = (data: Partial<SlidingBarViewOptions>) => {
    setCatchmentMapViewOptions((prevState) => ({ ...prevState, ...data }));
  };

  const onChangeMovementMapData = (type: MovementMapTypesEnum, data: LocationMapBarItem[]) => {
    setMovementMapData((prevState) => ({ ...prevState, [type]: data }));
  };

  const onChangeMovementViewOptions = (data: Partial<SlidingBarViewOptions>) => {
    setMovementMapViewOptions((prevState) => ({ ...prevState, ...data }));
  };

  return (
    <SidebarContext.Provider
      value={{ menuTabs, onChangeMenuTabs, isOpened: isOpenedSidebar, onToggleIsOpenedSidebar: toggleIsOpenedSidebar }}
    >
      <MapBottomBarContext.Provider
        value={{
          movement: {
            data: movementMapData,
            view: movementMapViewOptions,
          },
          catchment: {
            data: catchmentMapData,
            view: catchmentMapViewOptions,
          },
          onChangeMovement: onChangeMovementMapData,
          onChangeCatchmentViewOptions,
          onChangeMovementViewOptions,
          onChangeCatchment: onChangeCatchmentMapData,
        }}
      >
        <DefaultPageTemplate>
          <Switch>
            <Route exact path={Routes.userProfile}>
              <UserProfilePage />
            </Route>
            <Route exact path={Routes.changePassword}>
              <ChangePasswordPage />
            </Route>
            <Route exact path={Routes.signUpActivateAccountCongratulationsVerified}>
              <ActivateAccountCongratulationsPage />
            </Route>
            <Route exact path={Routes.createReport}>
              <CreateReportPage />
            </Route>
            <Route exact path="/projects/:id">
              <DashboardPage />
            </Route>
            <Route exact path={Routes.manageLocations.raw}>
              <ManageLocationsPage />
            </Route>
            <Route exact path="/reports/:id">
              <DashboardPage />
            </Route>
            <Route exact>
              <DashboardPage />
            </Route>
          </Switch>
        </DefaultPageTemplate>
      </MapBottomBarContext.Provider>
    </SidebarContext.Provider>
  );
};
