import React, { ReactElement } from 'react';
import Page from '../../templates/page/page';
import styles from './addUserPage.module.css';

import UserForm from '../../organisms/UserForm/UserForm';

const AddUserPage = (): ReactElement => (
  <Page title="Add user">
    <div className={styles.formContainer}>
      <UserForm />
    </div>
  </Page>
);

export default AddUserPage;
