import React, { Component } from 'react';
// @ts-ignore
import ReactTooltip from 'react-tooltip';
import * as _ from 'lodash';
import styles from './help.module.css';

interface Props {
  helpMessage: string;
}

export default class Help extends Component<Props> {
  private tooltipIdentifier: string;

  constructor(props: Readonly<Props>) {
    super(props);
    this.tooltipIdentifier = `tooltip-${_.random(0, 1000000)}`;
  }

  render() {
    return (
      <div className={styles.container} data-tip data-for={this.tooltipIdentifier}>
        <div>?</div>
        <ReactTooltip id={this.tooltipIdentifier}>{this.props.helpMessage}</ReactTooltip>
      </div>
    );
  }
}
