import React, { useRef, useContext, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { dashboardModel } from '../../../dasboardLoader';
import { MainContext } from '../../types/mainContext';
import styles from './style.module.scss';
import { Card } from './Card';
import { Dot } from '../dot';
import { Switcher } from '../switcher';
import { useSlider } from '../hooks';
import { LocationInfo } from '../../types/projectsHierarchy';
import { addImageUrlToLocations, convertLocationsImagesURLToDataBase64 } from '../../locations/utils';
import { CollapseIcon, UncollapseIcon } from '../../icons/sliders';
import { useToggle, useURLParams } from '../../hooks';
import { LocationGallery } from '../../locationsGallery';
import { CircleButton } from '../../controls/buttons/circleButton/circleButton';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { Skeleton } from '../../skeleton';

const PORTION_SIZE = 4;
const SCROLL_SIZE = 4;
const DEFAULT_IMAGE = '/img/v2/project.png';

export const LocationsMiniSlidingBar = () => {
  const { selectedLocations, primaryLocationId, setPrimaryLocationId } = useContext(MainContext);
  const [primaryLocationImage, setPrimaryLocationImage] = useState(DEFAULT_IMAGE);
  const [isGalleryOpened, toggleGalleryOpened] = useToggle(false);
  const [locations, setLocations] = useState<LocationInfo[]>([]);

  const areAllRIsLoaded = useStore(dashboardModel.$areAllReportItemsLoaded);

  const itemsRef = useRef<HTMLDivElement>(null);
  const sliderControlRef = useRef<HTMLDivElement>(null);
  const { onChangeParams } = useURLParams();

  const onLocationClick = (id: string) => {
    if (areAllRIsLoaded) {
      trackUserAction(
        'Primary location has been changed',
        UserActionsEnum.PRIMARY_LOCATION_CHANGE,
        `Set by click on the image in Locations filter. Location ID: ${id}`,
      );
      setPrimaryLocationId(id);
      onChangeParams('primaryLocation', id);
    }
  };

  const onToggleGallery = () => {
    toggleGalleryOpened();
  };

  useEffect(() => {
    if (selectedLocations.length) {
      (async function () {
        // const locations = await convertLocationsImagesURLToDataBase64(selectedLocations);
        const updatedLocations = selectedLocations.map((selectedLocation) => {
          const locationImg = addImageUrlToLocations([selectedLocation], 160, 100);
          return {
            ...selectedLocation,
            image: locationImg[0]?.image && locationImg[0]?.image.length < 8000 ? locationImg[0]?.image : DEFAULT_IMAGE,
          };
        });
        setLocations(updatedLocations);
      })();
    }
  }, [selectedLocations]);

  useEffect(() => {
    if (primaryLocationId) {
      const primaryLocation = selectedLocations.find(({ id }) => id === primaryLocationId);

      if (primaryLocation) {
        const updatedPrimaryLocation = addImageUrlToLocations([primaryLocation], 290, 100);
        const location = updatedPrimaryLocation[0];
        if (location) {
          setPrimaryLocationImage(location.image && location.image.length < 8000 ? location.image : DEFAULT_IMAGE);
        }
      }
    }
  }, [primaryLocationId]);

  const { isLast, isFirst, onChangeCurrent, current, portionsCount, currentItemsPortion, onPrevSlide, onNextSlide } =
    useSlider<LocationInfo>(locations, selectedLocations.length, PORTION_SIZE, SCROLL_SIZE, 10, true);

  const isLoading = selectedLocations.length === 0;
  return (
    <div className={styles.wrapper}>
      <div className={styles.selectedLocation}>
        {isLoading ? (
          <Skeleton className={styles.image} />
        ) : (
          <img className={styles.image} src={primaryLocationImage} alt="" />
        )}
      </div>
      <div className={styles.information}>
        <div className={`${styles.items} ${isLast ? styles.full : styles.withCut}`} ref={itemsRef}>
          {currentItemsPortion.map(({ name, id, image }) => (
            <Card
              key={id}
              id={id}
              img={image || DEFAULT_IMAGE}
              name={name}
              isActive={id === primaryLocationId}
              onClick={onLocationClick}
              isLoading={isLoading}
            />
          ))}
        </div>
        {!isLoading && (
          <div className={styles.header}>
            <div className={styles.sliderControl} ref={sliderControlRef}>
              <Switcher
                isActiveLeftArrow={!isFirst}
                isActiveRightArrow={!isLast}
                onPrev={onPrevSlide}
                onNext={onNextSlide}
              />
              <div className={styles.dots}>
                {portionsCount?.map((value) => (
                  <Dot key={value} isActive={value === current} onClick={onChangeCurrent} value={value} />
                ))}
              </div>
            </div>
            <div>
              <CircleButton
                onClick={onToggleGallery}
                active={isGalleryOpened}
                icon={<UncollapseIcon />}
                activeIcon={<CollapseIcon />}
                title="View all locations"
                disabled={!areAllRIsLoaded}
              />
              {/* <div onClick={onToggleGallery} className={`${styles.gallery} ${isGalleryOpened ? styles.opened : ''}`}>
              {isGalleryOpened ? <CollapseIcon /> : <UncollapseIcon />}
            </div> */}
              {isGalleryOpened && locations.length > 0 && (
                <LocationGallery
                  locations={locations}
                  primaryLocationId={primaryLocationId}
                  onClose={onToggleGallery}
                  onSelectLocation={onLocationClick}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
