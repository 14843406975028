import React, { ReactElement, useEffect, useState } from 'react';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styles from './projectsPage.module.css';
import Page from '../../templates/page/page';
import ConfirmDeleteModal from '../../molecules/ConfirmDeleteModal/ConfirmDeleteModal';
import routes from '../../../router/routes';
import { deleteProject, loadProjects } from '../../../actions/actions';
import { AppState } from '../../../reducers/reducers';
import { Project } from '../../../types/project';
import ProjectCard from './projectCard';
import ErrorMessage from '../../organisms/errorMessage';
import AccountFilterProjectsPage from "../../molecules/dropdowns/accountFilterProjectsPage/accountFilterProjectsPage";

const ProjectsPage = (): ReactElement => {
  const defaultSelectedAccounts: Option[] =
    localStorage.getItem('accountFilter') === null ? [] : JSON.parse(localStorage.getItem('accountFilter') || '');

  const dispatch = useDispatch();
  const [selectedAccounts, setSelectedAccounts] = useState<Option[]>(defaultSelectedAccounts);
  const [isDeleteProjectModalVisible, setIsDeleteProjectModalVisible] = useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState('');
  const [projectNameToDelete, setProjectNameToDelete] = useState('');
  const [sortType, setSortType] = useState('date');
  const projects = useSelector<AppState, Project[] | undefined>((state) => state.projects.entries);
  const history = useHistory();

  useEffect(() => {
    const selectedAccountIds: string[] = selectedAccounts.map(({ value }) => value);
    dispatch(loadProjects(selectedAccountIds));
  }, [JSON.stringify(selectedAccounts)]);

  const openDeleteProjectModalHandler = (project: Project) => {
    setIsDeleteProjectModalVisible(true);
    setProjectIdToDelete(project.id || '');
    setProjectNameToDelete(project.name);
  };

  const closeDeleteProjectModalHandler = () => {
    setIsDeleteProjectModalVisible(false);
    setProjectIdToDelete('');
    setProjectNameToDelete('');
  };

  function sortProjects(projects: Project[] | undefined, type: string) {
    if (type === 'name') {
      const listWithoutAccount = projects ? getProjectsListWithAccounts(projects) : undefined;
      return !!projects && getProjectsListWithoutAccounts(projects).concat(listWithoutAccount || []);
    }
    return !!projects && sortProjectsByDate(projects);
  }

  function sortProjectsByDate(projects: Project[] | undefined) {
    return !!projects && getProjectsSortedByDate(projects);
  }

  const getProjectsSortedByDate = (projects: Project[]) =>
    projects
      .filter((value) => value.date)
      .sort((a, b) => (a.date && b.date ? b.date.localeCompare(a.date) : 0))
      .map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          onEditProject={editProjectHandler}
          onDeleteProject={openDeleteProjectModalHandler}
        />
      ));

  const getProjectsListWithoutAccounts = (projects: Project[]) =>
    projects
      .filter((value) => value.name)
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((value) => !value.account_name)
      .map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          onEditProject={editProjectHandler}
          onDeleteProject={openDeleteProjectModalHandler}
        />
      ));

  const getProjectsListWithAccounts = (projects: Project[]) =>
    !!projects &&
    projects.length > 0 &&
    projects
      .filter((value) => value.name)
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((value) => value.account_name)
      .sort((a, b) => (a.account_name && b.account_name ? a.account_name.localeCompare(b.account_name) : 0))
      .map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          onEditProject={editProjectHandler}
          onDeleteProject={openDeleteProjectModalHandler}
        />
      ));

  const deleteProjectHandler = () => dispatch(deleteProject(projectIdToDelete));

  const editProjectHandler = (project: Project) => {
    history.push({
      pathname: routes.projects.update.withId(project.id),
      state: { sourceProject: project },
    });
  };

  return (
    <Page title="Projects">
      <div className={styles.container}>
        {isDeleteProjectModalVisible && (
          <ConfirmDeleteModal
            title={`Delete project "${projectNameToDelete}"?`}
            isVisible={isDeleteProjectModalVisible}
            onDeleteRedirectURL={routes.projects.list}
            onClose={closeDeleteProjectModalHandler}
            onDelete={deleteProjectHandler}
          />
        )}
        <ErrorMessage />
        <div className={styles.top}>
          <AccountFilterProjectsPage onChange={setSelectedAccounts} />
          <button
            className={sortType === 'date' ? styles.selected : styles.notSelected}
            onClick={() => {
              setSortType('date');
            }}
          >
            Sort by date
          </button>
          <button
            className={sortType === 'name' ? styles.selected : styles.notSelected}
            onClick={() => setSortType('name')}
          >
            Sort by name
          </button>
        </div>
        <div className={styles.content}>{!!projects && projects.length > 0 && sortProjects(projects, sortType)}</div>
      </div>
    </Page>
  );
};

export default ProjectsPage;
