export const saveUserCredentials = (
  token: string,
  accountId: string,
  accountType: string,
  userRole: string,
  userName: string,
  userEmail: string,
  userId: string,
  companyName?: string,
  title?: string,
  phone?: string,
) => {
  const updateDateTime = new Date();
  const expireDateTime = new Date(updateDateTime.setDate(updateDateTime.getDate() + 30));
  localStorage.setItem('isAuthenticated', 'true');
  localStorage.setItem('jwt', token);
  localStorage.setItem('accountId', accountId);
  localStorage.setItem('accountType', accountType);
  localStorage.setItem('userRole', userRole);
  localStorage.setItem('userName', userName);
  localStorage.setItem('userEmail', userEmail);
  localStorage.setItem('userId', userId);

  localStorage.setItem('companyName', companyName || '');
  localStorage.setItem('roleTitle', title || '');
  localStorage.setItem('phone', phone || '');
  const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000) || Math.floor(updateDateTime.getTime() / 1000);
  localStorage.setItem('expireTimeStamp', expireTimeStamp.toString());
};
