import React, { useContext, useEffect } from 'react';
import { MainContext } from '../../../types/mainContext';
import { LocationsSlidingBar } from '../../../sliders';
import { MapBottomBarContext } from '../context';
import { getLocationsImages } from '../utils';
import { MovementMapScale } from '../../../../mainPage/backgroundMap/polygons/movementMapScale';
import { getRequestModeFromType, getTypeFromMapMode } from './utils';
import { Movement, ReportItemInfo } from '../../../types/projectsHierarchy';
import { MapBaseOptions } from '../../mapBase/mapBase';

const DEFAULT_IMAGE = '/img/v2/catchment_heatmap_blur.jpg';

export const MovementBottomBar = () => {
  const {
    selectedLocations,
    selection: { selectedReportItem },
    movementMode,
    reportItemsDetails: { movementPeriod },
  } = useContext(MainContext);
  const { isHidden } = useContext(MapBaseOptions);

  const { movement, onChangeMovement, onChangeMovementViewOptions } = useContext(MapBottomBarContext);

  const typeAccordingMapMode = getTypeFromMapMode(movementMode);
  const isAlreadyDataAccordingToMapMode = movement.data[typeAccordingMapMode];
  const mode = getRequestModeFromType(movementPeriod, typeAccordingMapMode);

  useEffect(() => {
    if (selectedReportItem?.id && isAlreadyDataAccordingToMapMode) {
      (async function () {
        const data = await getLocationsImages(selectedReportItem.id, selectedLocations, mode, DEFAULT_IMAGE);

        onChangeMovement(typeAccordingMapMode, data);
      })();
    }
  }, [selectedLocations, movementMode, movementPeriod]);

  useEffect(() => {
    if (selectedReportItem?.id && !isAlreadyDataAccordingToMapMode) {
      (async function () {
        const data = await getLocationsImages(selectedReportItem.id, selectedLocations, mode, DEFAULT_IMAGE);

        onChangeMovement(typeAccordingMapMode, data);
      })();
    }
  }, [selectedLocations, movementMode, movementPeriod]);

  const data = movement.data[typeAccordingMapMode];

  const { collapsed, expanded } = movement.view;

  const reportItem = selectedReportItem as ReportItemInfo<Movement>;

  if (isHidden) return <></>;

  return data && data.length > 0 && reportItem?.data?.showGallery ? (
    <LocationsSlidingBar
      items={data}
      shareBlock={<MovementMapScale />}
      isExpanded={expanded}
      isCollapsed={collapsed}
      onChangeViewOptions={onChangeMovementViewOptions}
    />
  ) : (
    <></>
  );
};
