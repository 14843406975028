import { replaceValueToNA } from '../replaceValueToNA';
import { PowerIcon } from '../../../../../../../common/icons/charts';
import { StakeType } from '../../../../../../../common/chart/demographic/interfaces';
import { separateThousandsByComma } from '../../../../../../../common/utils';

export const getPurchase = (value: number | null, currency: string) => ({
  id: 7,
  icon: PowerIcon,
  type: 'information' as StakeType,
  styles: { item: { left: '-145px' } },
  data: [
    {
      id: 1,
      color: '#A67BDD',
      title: 'purchasingPower',
      value: value ? `${currency} ${separateThousandsByComma(value)}` : replaceValueToNA(value),
      stake: 100,
      styles: { item: { textAlign: 'right' } },
    },
  ],
});
