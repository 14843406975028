import React from 'react';

export const PowerIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6888 27.9113C13.6888 29.194 12.6603 30.2224 11.3777 30.2224C10.095 30.2224 9.06656 29.194 9.06656 27.9113C9.06656 26.6287 10.1066 25.6002 11.3777 25.6002C12.6488 25.6002 13.6888 26.6402 13.6888 27.9113ZM22.9332 25.6002C21.6621 25.6002 20.6221 26.6402 20.6221 27.9113C20.6221 29.1824 21.6621 30.2224 22.9332 30.2224C24.2043 30.2224 25.2443 29.194 25.2443 27.9113C25.2443 26.6287 24.2159 25.6002 22.9332 25.6002ZM11.5741 21.8447L11.6088 21.706L12.6488 19.8224H21.2577C22.1243 19.8224 22.887 19.3487 23.2799 18.6322L27.7403 10.5318L25.7297 9.42244H25.7181L24.447 11.7336L21.2577 17.5113H13.1457L12.9954 17.1993L10.407 11.7336L9.30922 9.42244L8.223 7.11133H4.44434V9.42244H6.75545L10.9154 18.1931L9.35545 21.0242C9.17056 21.3478 9.06656 21.7291 9.06656 22.1336C9.06656 23.4162 10.1066 24.4447 11.3777 24.4447H25.2443V22.1336H11.863C11.7128 22.1336 11.5741 22.0064 11.5741 21.8447Z"
      fill="#A67BDD"
    />
    <path
      d="M15.9289 11.6528L16.8575 12.4442L17.786 11.6528C20.8813 8.64514 23.0479 6.7456 23.0479 4.37117C23.0479 2.31333 21.5003 0.888672 19.6432 0.888672C18.5599 0.888672 17.4765 1.36356 16.8575 2.15503C16.2384 1.36356 15.1551 0.888672 14.0718 0.888672C12.2146 0.888672 10.667 2.47162 10.667 4.37117C10.667 6.7456 12.8337 8.64514 15.9289 11.6528Z"
      fill="#DFD2EF"
    />
  </svg>
);
