import { toFixNumber } from '../../../../common/lib';
import { TrendValue } from '../../types';
import { dateFormatter } from '../../utils';
import { PeriodsEnum } from '../../../../common/reportItems/types';

const dateOptions = {
  format: {
    monthly: 'MMM DD, YYYY',
    weekly: 'MMM YYYY',
  },
};

export const convertDataForExcel = (data: TrendValue[], isPercentage: boolean, period: PeriodsEnum) => {
  const entities = data.map(({ data, location_name, location_id }) => {
    return data.map(({ value, date }) => {
      return {
        location: location_name,
        time_range: dateFormatter(String(date), period, dateOptions),
        footfall_index: isPercentage
          ? `${toFixNumber(Number(value) * 100, 2)}`
          : String(toFixNumber(Number(value), 0)),
      };
    });
  });
  return entities.reduce((acc, curr) => [...acc, ...curr], []);
};
