import React, { FC } from 'react';
import styles from './style.module.scss';
import { ValueBreakZonesEnum, RatioBreakZonesEnum } from '../../enums';
import { CrossVisitationChartValues } from '../../../../../common/reportItems/types';

const colorsByRange = {
  '0-15': styles.lightBlue,
  '16-25': styles.blue,
  '26-40': styles.yellow,
  '41-50': styles.orange,
  '50+': styles.red,
};

const colorsByRatio = {
  '100 : 0-5': styles.lightBlue,
  '100 : 5-10': styles.blue,
  '100 : 10-25': styles.yellow,
  '100 : 25-50': styles.orange,
  '100 : 50+': styles.red,
};

interface Props {
  chartValues: CrossVisitationChartValues;
}
export const Legend: FC<Props> = ({ chartValues }) => {
  const valueOptions = Object.values(ValueBreakZonesEnum);
  const ratioOptions = Object.values(RatioBreakZonesEnum);

  return (
    <div className={styles.legend}>
      {chartValues !== CrossVisitationChartValues.VISIT_RATIO && (
        <div className={styles.legendRow}>
          {valueOptions.map((option, index) => (
            <div className={styles.option} key={`legend-ratio-${index}`}>
              <div className={`${styles.circle} ${colorsByRange[option]}`} />
              <span className={styles.content}>{option} %</span>
            </div>
          ))}
        </div>
      )}

      {chartValues !== CrossVisitationChartValues.CROSS_VISITATION && (
        <div className={styles.legendRow}>
          {ratioOptions.map((option, index) => (
            <div className={styles.option} key={`legend-crossVisitation-${index}`}>
              <div className={`${styles.circle} ${colorsByRatio[option]}`} />
              <span className={styles.content}>{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
