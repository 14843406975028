import React from 'react';
import { Popup } from 'react-map-gl';
import s from './MapPopup.module.scss';

interface MapPopupProps {
  coordinate: number[];
  onClose: () => void;
}

const MapPopup: React.FC<MapPopupProps> = ({ coordinate, children, onClose }) => (
  <Popup
    longitude={coordinate[0]}
    latitude={coordinate[1]}
    closeButton={false}
    closeOnClick={false}
    onClose={onClose}
    anchor="left"
    dynamicPosition
    className={s.popup}
  >
    {children}
  </Popup>
);

export default MapPopup;
