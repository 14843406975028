import React, { useState, useMemo, useContext, ChangeEvent, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { MainContext } from '../../../common/types/mainContext';
import { ReportInfo } from '../../../common/types/projectsHierarchy';
import styles from '../panelContent.module.scss';
import { SingleReport } from './singleReport';
import CreateReport from '../../../common/icons/reports/createReport';
import { SortBy } from '../../../common/sortBy';
import { SortByEnum } from './enums';
import { replaceStringSpace, sortByCreatedDate, sortByName, sortByNumberOfLocations } from '../../../common/utils';
import { getInitialReportItem, getProjectLocations } from './utils';
import { getReport } from '../../../api/report';
import { SidebarContext } from '../../../common/sidebarMenu/sidebarContext';

import { addImageUrlToLocations } from '../../../common/locations/utils';
import { Field, InputTypesEnum } from '../../../common/inputs';
import { CrossIcon } from '../../../common/icons/controls';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { useURLParams } from '../../../common/hooks';

export const ReportsList = () => {
  const {
    projectsHierarchy,
    selection,
    updateSelection,
    setSelectedLocations,
    setPrimaryLocationId,
    onSearchItems,
    clearReportItemsDetails,
    onChangeResetMapViewport,
    onChangeGlobalMapZoom,
    search: { reports: search },
    sortBy: { reports: sortBy },
    onChangeSortBy,
  } = useContext(MainContext);
  const { onChangeMenuTabs } = useContext(SidebarContext);
  const history = useHistory();
  const { params, isParsingDone, onChangeParams, generateLinkWithSelectedParams } = useURLParams();

  useEffect(() => {
    if (isParsingDone) {
      if (params.reportsSortBy) {
        const reportsSortBy = replaceStringSpace(params.reportsSortBy, '+', ' ');
        onChangeSortBy('reports', reportsSortBy as SortByEnum);
      }
    }
  }, [isParsingDone]);

  const filteredReports = useMemo(() => {
    const project = projectsHierarchy.find((i) => i.id === selection.selectedProject?.id);
    if (project) {
      if (search !== '') {
        return project.reports.filter(
          (i) =>
            i.name.toLowerCase().includes(search.toLowerCase()) ||
            i.description?.toLowerCase().includes(search.toLowerCase()),
        );
      }
      return project.reports;
    }
    return [];
  }, [projectsHierarchy, search]);

  const setPrimaryLocation = (id?: string) => {
    if (params.primaryLocation) {
      setPrimaryLocationId(params.primaryLocation);
      return;
    }
    if (id) {
      setPrimaryLocationId(id);
      onChangeParams('primaryLocation', id);
    }
  };

  const setReportItemType = (report: ReportInfo) => {
    const reportType = getInitialReportItem(report, params.reportItemType);

    if (!params.reportItemType) {
      if (reportType?.type) {
        onChangeParams('reportItemType', reportType.type);
      }
    }
    return reportType;
  };

  const onSingleReportClick = async (data: ReportInfo) => {
    onChangeResetMapViewport(false);
    const report = await getReport(data);
    trackUserAction('Report opened', UserActionsEnum.OPEN_REPORT, `Report ID: ${report.id}`);

    if (report.id !== selection.selectedReport?.id) {
      const search = generateLinkWithSelectedParams(['projectsSortBy', 'reportsSortBy']);
      clearReportItemsDetails();
      history.replace(`/reports/${report.id}${search}`);
      onChangeGlobalMapZoom({
        default: {
          zoom: null,
          latitude: null,
          longitude: null,
        },
        catchment: {
          zoom: null,
          latitude: null,
          longitude: null,
        },
        movement: {
          zoom: null,
          latitude: null,
          longitude: null,
        },
      });
      if (selection.selectedProject) {
        const { id, selectedLocations } = getProjectLocations(selection.selectedProject, report);
        setPrimaryLocation(id);
        trackUserAction(
          'Primary location has been changed',
          UserActionsEnum.PRIMARY_LOCATION_CHANGE,
          `Default primary location set. Location ID: ${id}`,
        );

        const locationsWithImages = addImageUrlToLocations(selectedLocations);
        setSelectedLocations(locationsWithImages);
      }
      updateSelection({
        ...selection,
        selectedReport: report,
        selectedReportItem: setReportItemType(report),
      });
    }
    onChangeMenuTabs({ projects: true, reports: true });
    onChangeResetMapViewport(true);
  };

  const onSort = (option: string) => {
    onChangeSortBy('reports', option as SortByEnum.NAME);
    onChangeParams('reportsSortBy', option);
  };

  const sortedItems = useMemo(() => {
    switch (sortBy) {
      case SortByEnum.NAME:
        return sortByName(filteredReports);
      case SortByEnum.NUMBER_OF_LOCATIONS:
        return sortByNumberOfLocations(filteredReports);
      case SortByEnum.DATE_CREATED:
        return sortByCreatedDate(filteredReports);
      default:
        return filteredReports;
    }
  }, [filteredReports, sortBy, search]);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    trackUserAction('Search field changed', UserActionsEnum.SEARCH_PROJECTS_REPORTS, value);
    onSearchItems('reports', value);
  };

  const onClearSearch = () => {
    trackUserAction('Search field cleared', UserActionsEnum.SEARCH_PROJECTS_REPORTS);
    onSearchItems('reports', '');
  };
  /*
  const onMouseMove = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, report: ReportInfo) => {
    const { pageX, pageY } = e;
    const project = selection.selectedProject;
    const locations = project?.locations || [];
    const tooltip = tooltipRef.current;
    if (tooltip) {
      const left = `${pageX + 10}px`;
      const top = `${pageY - 10}px`;
      tooltip.style.display = 'block';
      tooltip.style.left = left;
      tooltip.style.top = top;
      const parsedLocations = locations.filter(({ id }) => report.location_ids?.includes(id)).map(({ name }) => name);
      const currentLocations = parsedLocations.join(',');
      const prevLocations = hoveredLocationsNames.join(',');
      if (currentLocations !== prevLocations) setHoveredLocationsNames(parsedLocations);
    }
  };

  const onMouseOut = () => {
    const element = tooltipRef.current;

    if (element) {
      element.style.display = 'none';
    }
  };

  const onMouseEnter = () => {
    const element = tooltipRef.current;

    if (element) {
      element.style.display = 'block';
    }
  };
*/
  return (
    <div className={styles.itemsList}>
      <div className={styles.header}>
        <div className={styles.searchInput}>
          <Field
            id="test"
            name="test"
            onChange={onSearch}
            value={search}
            placeholder="Search reports"
            fieldType={InputTypesEnum.WITH_ICON}
            icon={search && <CrossIcon />}
            onIconClick={onClearSearch}
            iconSide="right"
          />
        </div>
        <SortBy options={Object.values(SortByEnum)} activeOption={sortBy} onChange={onSort} title="Sort reports" />
      </div>
      {sortedItems && sortedItems.length > 0 ? (
        <div className={`custom-scrollbar ${styles.scrollable}`} id="side-panel-scroll">
          {/* <div className={styles.tooltip} ref={tooltipRef}>
            {hoveredLocationsNames.map((location) => (
              <span className={styles.item}>{location}</span>
            ))}
          </div> */}
          {sortedItems.map((report) => (
            <SingleReport key={report.id} report={report} onClick={onSingleReportClick} />
          ))}
        </div>
      ) : (
        <div>
          <div className={styles.emptyListTitle}>
            <div className={styles.emptyListContent}>You still haven’t created any reports</div>
            <div className={styles.emptyListContent}>in {selection.selectedProject?.name}</div>
          </div>
          <div className={styles.addFirstItem}>
            {/* <div className={styles.emptyListContent}>Create a new report now</div> //TODO: Unmark when we'll support the create operation */}
            <div className={styles.addFirstItemImg}>
              <CreateReport />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
