export const toDataURL = (url: string, headers?: any) => {
  if (url.length > 8000) {
    return null;
  }
  return fetch(url, { headers })
    .then(async (response) => {
      if (response.status !== 200) {
        return null;
      }
      return response.blob();
    })
    .then((blob) => {
      if (!blob) return null;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};
