import { API_URLS } from '../../routes';
import { request } from '../../api';
import { CatchmentData, PolygonData, ResponseGeoData } from './types';
import { MAP_TOKEN } from '../../common/maps/mapBase/map.config';
import { mapResponse } from './mapper';
import { Catchment, ReportItemInfo, ReportItems } from '../../common/types/projectsHierarchy';
import { isValidDateOfReport } from '../utils';

export const fetchCatchmentData = async (reportItemId: string, locationId: string, timeFrames: string[] | null) => {
  const timeFramesQueryParam = timeFrames !== null ? `timeFrames=${timeFrames.join(',')}` : '';
  const locationsQueryParam = `locations=${locationId}`;
  const queryParams = [locationsQueryParam, timeFramesQueryParam].join('&');
  const url = `${API_URLS.REPORT_ITEMS_DATA}/${reportItemId}/data?${queryParams}`;
  return request<CatchmentData>({ url });
};

export const fetchPolygons = async (indexes: Set<string>, reportItemId: string) => {
  const url = `${API_URLS.REPORT_ITEMS_DATA}/${reportItemId}/data/indexes`;
  return request<PolygonData>({ url, method: 'POST', data: Array.from(indexes) });
};

export const fetchPathFromPoints = async (points: number[][], type: 'walking' | 'driving') => {
  const pointsStr = points.map((i) => i.join(',')).join(';');
  const url = `https://api.mapbox.com/optimized-trips/v1/mapbox/${type}/${pointsStr}?roundtrip=false&source=first&destination=last&geometries=geojson&overview=full&access_token=${MAP_TOKEN}`;
  return request<ResponseGeoData>({ url });
};

export const getCatchmentData = async (
  reportItemId: string,
  primaryLocationId: string,
  reportItemData: ReportItemInfo<Catchment>,
  updateReportItemOfSelectedReport: (item: ReportItemInfo<ReportItems>, forPrimaryLocationId: string) => void,
  updateReportItemDetailsValue: (name: string, value: any) => void,
  timesFrames: string[] | null,
  updateLoader: (visible: boolean, label?: string, progress?: number) => void,
  createdDate?: string | null,
) => {
  try {
    if (reportItemId && primaryLocationId) {
      const catchmentResponse = await fetchCatchmentData(reportItemId, primaryLocationId, timesFrames);
      const selectedCountry = catchmentResponse?.reportLocations.find(
        (location) => location.locationId === primaryLocationId,
      )?.country;
      updateReportItemDetailsValue('locations', catchmentResponse?.reportLocations);
      updateReportItemDetailsValue('selectedCountry', selectedCountry);
      updateLoader(true, 'Processing catchment data...', 30);
      const catchmentData = catchmentResponse;
      if (catchmentData?.values.length > 0) {
        const indexes = new Set<string>();
        catchmentData.values[0].home.forEach((i) => indexes.add(i.index));
        catchmentData.values[0].work.forEach((i) => indexes.add(i.index));
        updateLoader(true, 'Fetching catchment polygons...', 60);
        const polygonsResponse = await fetchPolygons(indexes, reportItemId);

        const data = mapResponse(catchmentData, polygonsResponse);
        const isAlreadyPresentData = reportItemData.data?.data.find(
          ({ location_id }) => location_id === data.data?.data?.[0].location_id,
        );
        const isPostalCodesPresetData = reportItemData.visualization?.indexPolygons?.find(
          ({ location_id }) => location_id === data.visualization?.indexPolygons?.[0].location_id,
        );
        const isHeatMapPresetData = reportItemData.visualization?.heatmap?.find(
          ({ location_id }) => location_id === data.visualization?.heatmap?.[0].location_id,
        );

        const dateToUse = catchmentResponse.created_date || createdDate;
        const showGallery = dateToUse ? isValidDateOfReport(dateToUse) : false;
        const mergedData =
          reportItemData.data && reportItemData.visualization
            ? ({
                ...data,
                data: {
                  reportLocations: catchmentResponse.reportLocations,
                  showGallery,
                  data: isAlreadyPresentData
                    ? reportItemData.data.data.map((item) =>
                        item.location_id === isAlreadyPresentData.location_id ? data.data?.data?.[0] : item,
                      )
                    : [...reportItemData.data.data, ...(data.data?.data || [])],
                },
                visualization: {
                  indexPolygons: isPostalCodesPresetData
                    ? reportItemData.visualization.indexPolygons?.map((entity) =>
                        entity.location_id === isPostalCodesPresetData.location_id
                          ? data.visualization?.indexPolygons?.[0]
                          : entity,
                      )
                    : [
                        ...(reportItemData.visualization.indexPolygons || []),
                        ...(data.visualization?.indexPolygons || []),
                      ],
                  heatmap: isHeatMapPresetData
                    ? reportItemData.visualization.indexPolygons?.map((entity) =>
                        entity.location_id === isHeatMapPresetData.location_id
                          ? data.visualization?.heatmap?.[0]
                          : entity,
                      )
                    : [...(reportItemData.visualization.heatmap || []), ...(data.visualization?.heatmap || [])],
                  multiLocationPolygons: reportItemData.visualization.multiLocationPolygons,
                },
              } as ReportItemInfo<Catchment>)
            : data;

        const dataOfPrimaryLocation = mergedData?.data?.data?.find(
          (location) => location.location_id === primaryLocationId,
        );

        // TODO why is this here? Probably we should remove it
        const topArea = dataOfPrimaryLocation?.home.reduce((res, i) =>
          res.geo_entity_share_of_source > i.geo_entity_share_of_source ? res : i,
        );
        const lowArea = dataOfPrimaryLocation?.home.reduce((res, i) =>
          res.geo_entity_share_of_source < i.geo_entity_share_of_source ? res : i,
        );

        const formattedData = {
          ...mergedData,
          data: {
            ...mergedData.data,
            showGallery,
            countries: catchmentData.countries,
            reportLocations: mergedData.data?.reportLocations,
            topArea,
            lowArea,
            maxRadius: catchmentData.maxRadius,
            isochronesRadius: catchmentData.isochronesRadius,
            catchmentLevel: catchmentData.catchmentLevel,
          },
        } as ReportItemInfo<Catchment>;
        updateReportItemOfSelectedReport(formattedData, primaryLocationId);
      } else if (timesFrames?.length === 0) {
        if (catchmentData) {
          updateReportItemOfSelectedReport(
            {
              ...catchmentData,
              data: null,
              visualization: null,
              settings: [],
            } as ReportItemInfo<Catchment>,
            primaryLocationId,
          );
        }
      }
    }
  } catch (error) {}
};
