import React, { ReactElement } from 'react';
import classnames from 'classnames';
import styles from './button.module.css';

interface Props {
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
  onClick?: () => void;
  icon?: ReactElement;
  layout?: 'primary' | 'secondary' | 'filled';
  children?: React.ReactNode;
  animation?: boolean;
  className?: string;
}

export const Button = (props: Props) => (
  <button
    type={props.type ? props.type : 'button'}
    className={`${classnames({
      [styles.button]: true,
      [styles.primary]: props.layout === 'primary',
      [styles.filled]: props.layout === 'filled',
      [styles.disabled]: props.disabled,
      [styles.animation]: props.animation,
    })} ${props.className ? props.className : ''}`}
    onClick={() => {
      if (props.onClick && !props.disabled) {
        props.onClick();
      }
    }}
  >
    {props.icon}

    {props.children}
  </button>
);
