import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Page from '../../templates/page/page';
import styles from './reportIsBeingGenerated.module.css';
import Button from '../../atoms/primaryButton/button';
import routes from '../../../router/routes';

type PathParams = { projectId: string };

class ReportIsBeingGeneratedPage extends Component<RouteComponentProps<PathParams>> {
  render() {
    const { projectId } = this.props.match.params;
    return (
      <Page title="">
        <div className={styles.container}>
          <div className={styles.message}>Report is being generated</div>
          <Button onClick={() => {}}>
            <Link to={routes.reports.list.withId(projectId)} className={styles.link}>
              Go to reports page
            </Link>
          </Button>
        </div>
      </Page>
    );
  }
}

export default withRouter(ReportIsBeingGeneratedPage);
