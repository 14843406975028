import React, { useContext, useMemo } from 'react';
import { MainContext } from '../../../common/types/mainContext';
import { getGradientColor, getColorIndex } from '../../../common/maps/utils/getGradientUtil';
import Polygon from '../../../common/maps/polygon/polygon';
import {
  CatchmentSocioDemoMetricEnum,
  CatchmentTypeEnum,
  ColorSchemeEnum,
  ReportItemType,
} from '../../../common/reportItems/types';
import { getCenterCoordinateOfGeoPolygon } from '../../../common/maps/utils/getCenterCoordinateOfGeoPolygon';
import { CatchmentMultiArea, IndexMultiArea } from '../../../common/types/visualizationObjects';
import { Catchment, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { getPostalCodeOpacity } from './utils';
import { ExportMapContext } from '../../../common/export/exportSettings/settings/mapContainer';

interface Props {
  ignoreShadow?: boolean;
  useCatchmentPostalCodes?: boolean;
  id?: string;
  ignoreOutline?: boolean;
  outlineColor?: string;
}
export const MultiLocationPostalCodePolygons = ({
  ignoreShadow,
  useCatchmentPostalCodes,
  id = '',
  ignoreOutline,
  outlineColor,
}: Props) => {
  const {
    selection: { selectedReport, selectedReportItem },
    primaryLocationId,
    reportItemsDetails: {
      catchmentType,
      catchmentTreshold,
      colorScheme,
      catchmentMetricType,
      catchmentSocioDemoMetric,
    },
    mapOptions,
    exportSelection,
  } = useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const reportItem = isExportMap ? exportSelection.selectedReportItem : selectedReportItem;

  const indexPolygons = useMemo(() => {
    let locationPolygons: IndexMultiArea | null = null;
    if (useCatchmentPostalCodes) {
      const reportItem = selectedReport?.report_items.find(
        ({ type }) => type === ReportItemType.CATCHMENT,
      ) as ReportItemInfo<Catchment>;
      if (reportItem) {
        locationPolygons = reportItem.visualization?.multiLocationPolygons || null;
      }
    } else {
      locationPolygons = reportItem?.visualization?.multiLocationPolygons || null;
    }
    const fieldName =
      catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.INHABITANTS
        ? 'inhabitants'
        : catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLDS
        ? 'households'
        : catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLD_INCOME
        ? 'average_income'
        : 'purchasing_power';

    if (catchmentType === CatchmentTypeEnum.HOME) {
      return locationPolygons?.home
        ? {
            ...locationPolygons.home,
            polygons: locationPolygons.home.polygons.filter(
              (item) =>
                (item[fieldName] || 0) >= catchmentTreshold.min && (item[fieldName] || 0) <= catchmentTreshold.max,
            ),
          }
        : null;
    }
    if (catchmentType === CatchmentTypeEnum.WORK) {
      return locationPolygons?.work
        ? {
            ...locationPolygons.work,
            polygons: locationPolygons.work.polygons.filter(
              (item) =>
                (item[fieldName] || 0) >= catchmentTreshold.min && (item[fieldName] || 0) <= catchmentTreshold.max,
            ),
          }
        : null;
    }
    return null;
  }, [primaryLocationId, catchmentType, catchmentTreshold, selectedReport, reportItem, catchmentMetricType]);

  const rank: number[] = useMemo(() => {
    if (indexPolygons) {
      if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.INHABITANTS) {
        return [indexPolygons.inhabitantsMinRank, indexPolygons.inhabitantsMaxRank];
      }
      if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLDS) {
        return [indexPolygons.householdsMinRank, indexPolygons.householdsMaxRank];
      }
      if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLD_INCOME) {
        return [indexPolygons.averageIncomeMinRank, indexPolygons.averageIncomeMaxRank];
      }
      if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.RETAIL_SPENDING) {
        return [indexPolygons.purchasingPowerMinRank, indexPolygons.purchasingPowerMaxRank];
      }
      // default: inhabitants
      return [indexPolygons.inhabitantsMinRank, indexPolygons.inhabitantsMaxRank];
    }
    return [0, 100];
  }, [indexPolygons, catchmentSocioDemoMetric]);

  const getRankByMetricType = (item: CatchmentMultiArea) => {
    if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.INHABITANTS) {
      return item.inhabitants;
    }
    if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLDS) {
      return item.households;
    }
    if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLD_INCOME) {
      return item.average_income;
    }
    if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.RETAIL_SPENDING) {
      return item.purchasing_power;
    }
    return item.inhabitants;
  };

  const { zoom } = mapOptions.catchment;
  return (
    <>
      {indexPolygons && indexPolygons.polygons && (
        <Polygon
          id={`POI_POLYGONS${id}`}
          data={{
            type: 'FeatureCollection',
            features: indexPolygons.polygons.map((i) => ({
              ...i.geo,
              properties: {
                color: getGradientColor(
                  getColorIndex(getRankByMetricType(i) || rank[0], rank[0], rank[1]),
                  colorScheme === ColorSchemeEnum.MONOCHROME,
                ),
                popupTitle: i.name,
                centerPoint: getCenterCoordinateOfGeoPolygon(i.geo),
                popupData: i.popupData,
              },
            })),
          }}
          ignoreShadow={ignoreShadow}
          ignoreOutline={ignoreOutline}
          borderLineWidth={1}
          outlineColor={outlineColor}
          opacity={getPostalCodeOpacity(zoom)}
        />
      )}
    </>
  );
};
