import React from 'react';
import { IconProps } from '../types';

export const ChartIcon: React.FC<IconProps> = ({ active }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 13.9928L6.125 8.36031L9.125 11.3603L14.4425 5.38281L15.5 6.44031L9.125 13.6103L6.125 10.6103L1.625 15.1178L0.5 13.9928ZM1.625 10.6178L6.125 6.11031L9.125 9.11031L15.5 1.94031L14.4425 0.882812L9.125 6.86031L6.125 3.86031L0.5 9.49281L1.625 10.6178Z"
      fill={active ? '#323232' : 'white'}
    />
  </svg>
);

export default ChartIcon;
