import { MonthData } from '../types';

export const months: MonthData[] = [
  { id: 'january', label: 'January', month: '01' },
  { id: 'february', label: 'February', month: '02' },
  { id: 'march', label: 'March', month: '03' },
  { id: 'april', label: 'April', month: '04' },
  { id: 'may', label: 'May', month: '05' },
  { id: 'june', label: 'June', month: '06' },
  { id: 'july', label: 'July', month: '07' },
  { id: 'august', label: 'August', month: '08' },
  { id: 'september', label: 'September', month: '09' },
  { id: 'october', label: 'October', month: '10' },
  { id: 'november', label: 'November', month: '11' },
  { id: 'december', label: 'December', month: '12' },
];
