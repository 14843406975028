import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './primaryButton.module.css';

interface Props {
  disabled?: boolean;
  onClick: (e?: undefined | null | any) => void;
  secondary?: boolean;
  warning?: boolean;
  type?: 'reset' | 'submit' | 'button';
  className?: string;
}

export default class Button extends Component<Props, {}> {
  static defaultProps = {
    onClick: () => {},
  };

  render() {
    const classNames = classnames(
      styles.button,
      { [styles.disabled]: this.props.disabled },
      { [styles.secondary]: this.props.secondary },
      { [styles.warning]: this.props.warning },
      !!this.props.className && this.props.className,
    );
    return (
      <button
        className={classNames}
        onClick={() => {
          if (!this.props.disabled) {
            this.props.onClick();
          }
        }}
        type={this.props.type}
      >
        {this.props.children}
      </button>
    );
  }
}
