import React from 'react';

export const resetMapIcon = () => (
  <svg width="14" height="15" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#FFFFFFFF" stroke="none">
      <path d="M2330 5109 c-511 -47 -995 -248 -1392 -577 l-80 -65 139 -139 c76 -76 142 -138 146 -138 5 0 41 26 80 59 276 225 662 392 1035 448 144 21 460 21 604 0 373 -56 759 -223 1035 -448 39 -33 75 -59 80 -59 4 0 71 62 147 139 l138 138 -97 79 c-460 375 -1038 579 -1625 573 -69 -1 -163 -5 -210 -10z" />
      <path d="M2287 4364 c-513 -80 -969 -376 -1254 -812 -110 -168 -199 -374 -243 -562 -25 -105 -49 -258 -42 -265 3 -3 106 -5 228 -5 249 0 226 -8 239 80 43 287 231 612 466 806 261 215 544 318 879 318 338 0 621 -104 884 -322 233 -195 418 -516 461 -802 13 -88 -10 -80 239 -80 122 0 225 2 228 5 14 14 -43 297 -86 420 -201 586 -684 1030 -1286 1185 -195 50 -513 65 -713 34z" />
      <path d="M574 4165 c-144 -176 -265 -373 -355 -576 -388 -878 -251 -1891 355 -2634 l79 -97 138 138 c77 76 139 143 139 147 0 5 -26 41 -59 80 -229 279 -397 674 -452 1062 -16 116 -16 434 0 550 55 388 221 778 452 1062 33 39 59 75 59 80 0 4 -62 71 -139 147 l-138 138 -79 -97z" />
      <path d="M4327 4122 c-75 -76 -137 -141 -137 -145 0 -5 26 -41 59 -80 181 -220 340 -544 407 -826 46 -195 57 -293 57 -511 0 -218 -11 -316 -57 -511 -67 -282 -226 -606 -407 -826 -33 -39 -59 -75 -59 -80 0 -4 62 -71 139 -147 l138 -138 79 97 c144 176 265 373 355 576 355 802 274 1717 -216 2449 -52 77 -198 265 -215 275 -3 2 -68 -58 -143 -133z" />
      <path d="M2405 3505 c-239 -52 -450 -237 -534 -469 -73 -203 -66 -395 25 -666 96 -290 334 -671 607 -975 l45 -50 37 35 c20 19 76 80 124 135 372 428 561 808 587 1181 31 448 -308 827 -735 823 -53 0 -123 -6 -156 -14z" />
      <path d="M748 2395 c-2 -3 1 -43 8 -90 109 -739 640 -1329 1364 -1515 263 -67 617 -67 880 0 659 169 1179 693 1330 1340 25 105 49 258 42 265 -3 3 -106 5 -228 5 -249 0 -226 8 -239 -80 -43 -287 -231 -612 -466 -806 -261 -215 -544 -318 -879 -318 -338 0 -621 104 -884 322 -233 195 -418 516 -461 802 -13 88 10 80 -239 80 -122 0 -225 -2 -228 -5z" />
      <path d="M997 792 l-139 -139 80 -65 c241 -200 515 -354 807 -452 830 -281 1741 -117 2420 438 l97 79 -138 138 c-76 77 -143 139 -147 139 -5 0 -41 -26 -80 -59 -276 -225 -662 -392 -1035 -448 -144 -21 -460 -21 -604 0 -373 56 -759 223 -1035 448 -39 33 -75 59 -80 59 -4 0 -70 -62 -146 -138z" />
    </g>
  </svg>
);

export default resetMapIcon;
