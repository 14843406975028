import React from 'react';
import { WeekDaysShort } from '../../types';
import styles from './seasonalityCardMostPopularDay.module.scss';
import { Skeleton } from '../../../common/skeleton';

interface Props {
  dayOfWeek: number;
  isLoading: boolean;
}

const days = [...WeekDaysShort.slice(1), WeekDaysShort[0]];

const SeasonalityCardMostPopularDay: React.FC<Props> = ({ dayOfWeek, isLoading }) => {
  const normalizeDayOfWeek = (dayOfWeek: number) => {
    if (dayOfWeek === 1) {
      return 6;
    }
    return dayOfWeek - 2;
  };

  return isLoading ? (
    <Skeleton className={styles.skeleton} />
  ) : (
    <div className={styles.container}>
      {days.map((day, index) => (
        <span className={`${styles.value} ${index === normalizeDayOfWeek(dayOfWeek) ? styles.isActive : ''}`} key={day}>
          {day}
        </span>
      ))}
    </div>
  );
};

export default SeasonalityCardMostPopularDay;
