import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'effector-react';
import { MainContext } from '../../common/types/mainContext';
import { ProjectsList, ReportsList, ReportItemsList } from '../panelContent';
import { PanelHeader } from './panelHeader';
import { SelectionsEnum } from './enums';
import { SidebarContext } from '../../common/sidebarMenu/sidebarContext';
import { dashboardModel } from '../../dasboardLoader';
import { useURLParams } from '../../common/hooks';

export const ProjectsPanel = () => {
  const history = useHistory();
  const { selection, projectsHierarchy } = useContext(MainContext);
  const { menuTabs, onChangeMenuTabs } = useContext(SidebarContext);
  const { generateLinkWithSelectedParams } = useURLParams();
  const isGlobalLoading = useStore(dashboardModel.$isLoading);

  const onGoBack = (type: string) => {
    const activeParams = generateLinkWithSelectedParams(['reportsSortBy', 'projectsSortBy']);
    switch (type) {
      case SelectionsEnum.REPORTS: {
        const projectId = selection.selectedProject?.id;
        history.replace(`/projects/${projectId}${activeParams}`);
        onChangeMenuTabs({ reports: false });
        break;
      }
      case SelectionsEnum.PROJECTS: {
        history.replace(activeParams);
        onChangeMenuTabs({ reports: false, projects: false });
        break;
      }
      default:
        break;
    }
  };

  const title = useMemo(
    () =>
      selection.selectedReport
        ? selection.selectedReport.name
        : selection.selectedProject
        ? `${selection.selectedProject.name} (${selection.selectedProject.reports.length})`
        : `My Projects (${projectsHierarchy.length})`,
    [selection.selectedProject, selection.selectedReport, projectsHierarchy.length],
  );

  const report = selection.selectedReport;

  const isDefaultVisualizedReportItems =
    report?.id &&
    report?.visualization_url === '' &&
    (report.report_category === 'POI_DEFAULT' || report.report_category === 'AOI_DEFAULT');

  return (
    <>
      <PanelHeader
        onGoBack={onGoBack}
        hasOpenedReports={menuTabs.reports}
        hasOpenedProjects={menuTabs.projects}
        title={title || 'My Projects'}
        isLoading={isGlobalLoading}
      />
      {!menuTabs.projects && <ProjectsList />}
      {menuTabs.projects && !menuTabs.reports && <ReportsList />}
      {menuTabs.reports && isDefaultVisualizedReportItems && <ReportItemsList />}
    </>
  );
};
