import React from 'react';
import { LogoIcon } from '../icons/auth';
import styles from './style.module.scss';

export const Logo = () => (
  <div className={styles.logo}>
    <LogoIcon />
    <span>PlaceSense</span>
  </div>
);
