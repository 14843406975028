import React from 'react';
import { WeekDaysInOrder } from '../../../types';
import styles from './style.module.scss';

interface Props {}
export const DayListLegend: React.FC<Props> = () => {
  return (
    <div className={styles.days}>
      {WeekDaysInOrder.map((week) => {
        return <span>{week}</span>;
      })}
    </div>
  );
};
