import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../../../common/types/mainContext';
import styles from './popup.module.scss';
import { removeProject } from '../../../../api/projects/remove';
import { Popup } from '../../../../common/popup';
import { Button } from '../../../../common/controls/buttons';
import { ProjectInfo } from '../../../../common/types/projectsHierarchy';

interface TProps {
  project: ProjectInfo;
  isOpen: boolean;
  toggleOpen: (val: boolean) => void;
}

export const DeleteProjectPopup = ({ project, isOpen, toggleOpen }: TProps) => {
  const { t } = useTranslation();
  const { projectsHierarchy, setProjectsHierarchy } = useContext(MainContext);

  const [isError, setError] = useState<boolean>(false);

  const onProjectDelete = async () => {
    if (project.reports.length === 0) {
      try {
        const res = await removeProject(project.id);
        const hierarchy = projectsHierarchy.filter((item) => item.id !== project.id);
        setProjectsHierarchy(hierarchy);
        toggleOpen(false);
      } catch (error) {
        console.error(error);
        setError(true);
      }
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={() => toggleOpen(false)} height={300}>
      <div className={styles.title}>
        {project.reports.length === 0 ? t('delete.projectTitle') : t('delete.projectTitleAlt')}
      </div>
      <div className={styles.subtitle}>
        {project.reports.length === 0 ? t('delete.paragraph1') : t('delete.paragraph1Alt')}
      </div>
      <div className={styles.subtitle}>
        {project.reports.length === 0 ? t('delete.paragraph2') : t('delete.paragraph2Alt')}
      </div>
      <div style={{ height: '50px' }} />
      {isError && <div className={styles.error}>Unexpected error during deleting of the project</div>}
      <div className={styles.buttons}>
        <Button onClick={() => toggleOpen(false)}>
          {project.reports.length === 0 ? t('reportItem.cancel') : t('reportItem.close')}
        </Button>
        {project.reports.length === 0 && (
          <Button layout="primary" onClick={onProjectDelete}>
            {t('reportItem.delete')}
          </Button>
        )}
      </div>
    </Popup>
  );
};
