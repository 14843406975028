import React, { ChangeEvent } from 'react';
import { ClosedEyeIcon, OpenedEyeIcon } from '../../../common/icons/passwords';
import { useToggle } from '../../../common/hooks';
import { DefaultInput } from '../default';
import styles from './style.module.scss';

interface Props {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  isError?: boolean;
  isBorder?: boolean;
  name: string;
  id?: string;
  maxLength?: number;
}

export const PasswordInput = ({
  value = '',
  onChange,
  placeholder = '',
  isError,
  isBorder,
  name,
  id,
  maxLength,
}: Props) => {
  const [isVisible, toggleIsVisible] = useToggle(false);

  return (
    <div className={styles.container}>
      <div>
        <div onClick={() => toggleIsVisible()} className={styles.icon}>
          {isVisible ? <OpenedEyeIcon /> : <ClosedEyeIcon />}
        </div>
        <DefaultInput
          type={isVisible ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          id={id}
          maxLength={maxLength}
          isBorder={isBorder}
        />
      </div>
    </div>
  );
};
