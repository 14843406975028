import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { BaseModal } from '../../baseModal';
import styles from './style.module.scss';
import { Routes } from '../../../routes';

export const SelfSignUpFinal = () => {
  const { state } = useLocation<{ email: string; name: string }>();

  if (!state?.email || !state?.name) return <Redirect to={Routes.signUp} />;

  return (
    <BaseModal subtitle={`Welcome ${state.name},`} title="Thank you for registering with PlaceSense!" subtitleFirst>
      <div className={styles.wrapper}>
        <div className={styles.footer}>
          We will contact you as soon as your account is activated. If you have any questions you can{' '}
          <span className={styles.highlight}>
            <a href="mailto:cs@placesense.ai">contact us via email.</a>
          </span>{' '}
        </div>
      </div>
    </BaseModal>
  );
};