import React from 'react';

export enum ReportItemType {
  CATCHMENT = 'CATCHMENT',
  DURATION = 'DURATION',
  SEASONALITY = 'SEASONALITY',
  RETENTION = 'RETENTION',
  FOOTFALL = 'FOOTFALL',
  CROSS_VISITATION = 'LOYALTY',
  MOVEMENT = 'MOVEMENT',
  ABSORPTION = 'ABSORPTION',
  ATTRACTION = 'ATTRACTION',
}

export interface ReportItemCardConfiguration {
  title: string;
  icon: React.ComponentType;
}

export enum PeriodsEnum {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  QUARTERLY = 'QUARTERLY',
  WHOLE_PERIOD = 'WHOLE_PERIOD',
}

export enum ColorSchemeEnum {
  MONOCHROME = 'MONOCHROME',
  COLORFUL = 'COLORFUL',
}

export enum CatchmentTypeEnum {
  HOME = 'HOME',
  WORK = 'WORK',
}

export enum CatchmentLocationsCountEnum {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum CatchmentMetricTypeEnum {
  SOURCE = 'SOURCE',
  DESTINATION = 'DESTINATION',
  SCORE = 'SCORE',
}

export enum CatchmentSocioDemoMetricEnum {
  HOUSEHOLDS = 'HOUSEHOLDS',
  INHABITANTS = 'INHABITANTS',
  HOUSEHOLD_INCOME = 'HOUSEHOLD_INCOME',
  RETAIL_SPENDING = 'RETAIL_SPENDING',
}

export enum DataLevelEnum {
  CATEGORY = 'CATEGORIES',
  LOCATION = 'LOCATIONS',
}

export enum LocationTypeEnum {
  POIS = 'POIS',
  BUILDINGS = 'BUILDINGS',
}

export enum OverlayHotspotsEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum MovementPeriodEnum {
  BEFORE = 'Before',
  DURING = 'During',
  AFTER = 'After',
}

export enum MovementVisitorTypeEnum {
  VISITORS = 'Visitors',
  RESIDENTS_WORKERS = 'Residents, workers',
}

export enum ReportItemVisualizationMode {
  CHART = 'Chart',
  Graph = 'Graph',
}

export enum DefaultModesEnum {
  CHART = 'CHART',
}

export enum CrossVisitationChartValues {
  CROSS_VISITATION = 'CROSS_VISITATION',
  VISIT_RATIO = 'VISIT_RATIO',
  BOTH = 'BOTH',
}
