import Pptxgen from 'pptxgenjs';
import { TFunction } from 'react-i18next';
import { ExportData } from '../../model/interfaces';
import { getLogoMark, getPreview } from '../../utils';
import { convertImageToBase64, maxLengthOfString } from '../../../../utils';
import {
  CatchmentExportInformation,
  ReportItemsInformation,
} from '../../model/interfaces/ReportItemsInformation.interface';
import { toFixNumber } from '../../../../lib';
import { LocationInfo } from '../../../../types/projectsHierarchy';
import { generateFileName } from './generateFileName';
import { getCompanyLogo } from '../../utils/getCompanyLogo';

const SLIDE_VIEW_IMAGE_HEIGHT = 4.5;
const SLIDE_VIEW_IMAGE_WIDTH = 9.6;

export const generatePreviewPage = async (instance: Pptxgen, reportName: string, companyLogo: string) => {
  const slide = instance.addSlide();
  const preview = getPreview();
  const logo = getLogoMark();

  slide.addImage({
    data: await convertImageToBase64(preview, 2550, 2000),
    w: '70%',
    h: '100%',
    x: '0%',
    y: '0%',
  });

  slide.addText(reportName, { x: '65%', y: '80%', color: '#707070', w: '25%' });
  slide.addImage({
    data: await convertImageToBase64(logo),
    w: '11%',
    h: '10%',
    x: '92.8%',
    y: '90%',
  });
  const companyLogoData = await getCompanyLogo(companyLogo, 1000, 1000, 500);
  if (companyLogoData) {
    slide.addImage({
      data: companyLogoData,
      w: 0.8,
      h: 0.8,
      x: 7.11,
      y: 2.81,
    });
  }

  return instance;
};

const generateBasePage = async (
  instance: Pptxgen,
  title: string,
  mainImage: string,
  reportItemName: string,
  informationTitle: string,
  companyLogo: string,
) => {
  const slide = instance.addSlide();
  const logo = getLogoMark();

  const image = new Image();
  image.src = mainImage;
  await image.decode();
  const { width, height } = image;

  const imageHeightPPL = height / 143;
  const imageWidthPPL = width / 148;

  slide.addText(title, { x: '2%', y: '4%', fontSize: 12, bold: true });
  // slide.addText(reportItemName, { x: '24%', y: '4%', fontSize: 10, align: 'right' });
  slide.addImage({
    data: mainImage,
    w: imageWidthPPL > SLIDE_VIEW_IMAGE_WIDTH ? SLIDE_VIEW_IMAGE_WIDTH : imageWidthPPL,
    h: imageHeightPPL > SLIDE_VIEW_IMAGE_HEIGHT ? SLIDE_VIEW_IMAGE_HEIGHT : imageHeightPPL,
    x: '2%',
    y: '7%',
  });
  slide.addText(informationTitle, { x: '2%', y: '89%', fontSize: 8, align: 'left' });
  slide.addText('2022 PlaceSense.ai @ All rights reserved', { x: '24%', y: '89%', fontSize: 7, align: 'right' });

  slide.addImage({ data: await convertImageToBase64(logo), w: '11%', h: '10%', x: '92.8%', y: '90%' });
  const companyLogoData = await getCompanyLogo(companyLogo, 1000, 1000, 500);
  if (companyLogoData) {
    slide.addImage({
      data: companyLogoData,
      w: 0.28,
      h: 0.28,
      x: 8.9,
      y: 5.15,
    });
  }

  return slide;
};

const generateCatchmentSlide = async (
  instance: Pptxgen,
  mainImage: string,
  reportItemName: string,
  reportItemType: string,
  informationTitle: string,
  topAreaTitle: string,
  shareOfVisitsTitle: string,
  drivingTimeTitle: string,
  distanceFromLocationTitle: string,
  data: CatchmentExportInformation | null,
  companyLogo: string,
) => {
  const slide = await generateBasePage(
    instance,
    reportItemType,
    mainImage,
    reportItemName,
    informationTitle,
    companyLogo,
  );

  if (data) {
    if (data.topArea) {
      slide.addText(`${topAreaTitle}:`, { x: '2%', y: '92%', fontSize: 7, align: 'left' });
      slide.addText(maxLengthOfString(data.topArea, 20) || '', {
        x: '15%',
        y: '92%',
        fontSize: 7,
        align: 'left',
        bold: true,
      });
    }

    slide.addText(`${shareOfVisitsTitle}:`, { x: '2%', y: '94%', fontSize: 7, align: 'left' });
    slide.addText(String(toFixNumber((data.visitsShare || 0) * 100, 2)), {
      x: '15%',
      y: '94%',
      fontSize: 7,
      align: 'left',
      bold: true,
    });

    const missedFields = [
      { label: drivingTimeTitle, field: 'drivingTime', metric: 'min' },
      { label: distanceFromLocationTitle, field: 'aerialDistance', metric: 'km' },
    ];

    let prevY = 96;
    missedFields.forEach(({ field, label, metric }) => {
      const dataField = data[field as 'drivingTime' | 'aerialDistance'];
      if (dataField) {
        slide.addText(`${label}`, { x: '2%', y: `${prevY}%`, fontSize: 7, align: 'left' });
        slide.addText(`${dataField} ${metric}`, { x: '15%', y: `${prevY}%`, fontSize: 7, align: 'left', bold: true });
        prevY += 2;
      }
    });
  }

  return instance;
};

const generateSeasonalitySlide = async (
  instance: Pptxgen,
  mainImage: string,
  reportItemName: string,
  reportItemType: string,
  informationTitle: string,
  popularDayTitle: string,
  popularHourTitle: string,
  popularDay: string | null,
  popularHour: string | null,
  companyLogo: string,
) => {
  const slide = await generateBasePage(
    instance,
    reportItemType,
    mainImage,
    reportItemName,
    informationTitle,
    companyLogo,
  );

  if (popularDay) {
    slide.addText(popularDayTitle, { x: '2%', y: '92%', fontSize: 7, align: 'left' });
    slide.addText(popularDay, { x: '14%', y: '92%', fontSize: 7, align: 'left', bold: true });
  }

  if (popularHour) {
    slide.addText(popularHourTitle, { x: '2%', y: '94%', fontSize: 7, align: 'left' });
    slide.addText(popularHour, { x: '14%', y: '94%', fontSize: 7, align: 'left', bold: true });
  }

  return instance;
};

const generateUniversalSlide = async (
  instance: Pptxgen,
  mainImage: string,
  reportItemName: string,
  reportItemType: string,
  informationTitle: string,
  valueDescription: string,
  value: string | null,
  companyLogo: string,
) => {
  const slide = await generateBasePage(
    instance,
    reportItemType,
    mainImage,
    reportItemName,
    informationTitle,
    companyLogo,
  );

  if (value) {
    slide.addText(`${valueDescription}:`, { x: '2%', y: '92%', fontSize: 7, align: 'left' });
    slide.addText(value, { x: '50%', y: '92%', fontSize: 7, align: 'left', bold: true });
  }

  return instance;
};

const generateMapPage = (instance: Pptxgen, image: string) => {
  const slide = instance.addSlide();

  slide.addImage({ data: image, w: '100%', h: '100%' });
};

const generateBaseInstance = () => new Pptxgen();

export const generatePowerPoint = async (
  exportData: ExportData,
  name: string,
  timeFrame: string,
  reportItemsInformation: ReportItemsInformation,
  primaryLocation: LocationInfo,
  usePreview: boolean,
  companyLogo: string,
  t: TFunction<'translation'>,
) => {
  const instance = generateBaseInstance();

  const primaryLocationName = primaryLocation.name;

  const { FOOTFALL, MOVEMENT, SEASONALITY, DURATION, RETENTION, LOYALTY, ABSORPTION, ATTRACTION, CATCHMENT } =
    exportData;
  const { absorption, attraction, seasonality, catchment, duration, retention, crossVisitation } =
    reportItemsInformation;

  if (usePreview) await generatePreviewPage(instance, name, companyLogo);

  if (FOOTFALL?.chart) {
    await generateUniversalSlide(
      instance,
      FOOTFALL.chart,
      name,
      t('visitTrends.title'),
      t('visitTrends.detail.showingMonthlyEstimates'),
      t('visitTrends.card.timeframe'),
      timeFrame,
      companyLogo,
    );
  }

  if (FOOTFALL?.map) generateMapPage(instance, FOOTFALL.map);

  if (CATCHMENT?.chart) {
    await generateCatchmentSlide(
      instance,
      CATCHMENT.chart,
      name,
      t('catchment.title'),
      t('catchment.detail.showingShareOfVisitors'),
      t('catchment.card.topArea'),
      t('catchment.card.visitsShare'),
      t('catchment.card.drivingTime'),
      t('catchment.card.distanceFromLocation'),
      catchment,
      companyLogo,
    );
  }

  if (CATCHMENT?.map) generateMapPage(instance, CATCHMENT.map);

  if (DURATION?.chart) {
    const { averageTime } = duration;
    await generateUniversalSlide(
      instance,
      DURATION?.chart,
      name,
      t('duration.title'),
      t('duration.detail.showingAvgTime'),
      t('duration.card.avgTimeOnAllLocations'),
      averageTime ? `${toFixNumber(averageTime, 0)} ${t('reportItem.min')}` : null,
      companyLogo,
    );
  }

  if (DURATION?.map) generateMapPage(instance, DURATION.map);

  if (SEASONALITY?.chart) {
    const { popularDay, popularHour } = seasonality;
    await generateSeasonalitySlide(
      instance,
      SEASONALITY?.chart,
      name,
      t('seasonality.title'),
      t('seasonality.detail.showingPopularDays'),
      t('seasonality.card.popularDay'),
      t('seasonality.card.popularHour'),
      popularDay,
      popularHour,
      companyLogo,
    );
  }

  if (SEASONALITY?.map) generateMapPage(instance, SEASONALITY.map);

  if (RETENTION?.chart) {
    const { returningAverage } = retention;
    await generateUniversalSlide(
      instance,
      RETENTION?.chart,
      name,
      t('retention.title'),
      t('retention.detail.showingTheShare'),
      t('retention.card.avgRateOfLocations'),
      returningAverage ? `${toFixNumber(returningAverage * 100, 2)}%` : null,
      companyLogo,
    );
  }

  if (RETENTION?.map) generateMapPage(instance, RETENTION.map);

  if (LOYALTY?.chart) {
    const { singleLocationAverage } = crossVisitation;
    await generateUniversalSlide(
      instance,
      LOYALTY?.chart,
      name,
      t('crossVisitation.title'),
      t('crossVisitation.detail.shareOfCrossVisitation'),
      t('crossVisitation.card.avgShareOfCrossVisits'),
      singleLocationAverage ? `${toFixNumber(Number(singleLocationAverage), 1)}%` : null,
      companyLogo,
    );
  }

  if (LOYALTY?.map) generateMapPage(instance, LOYALTY.map);

  if (MOVEMENT?.chart) {
    await generateUniversalSlide(
      instance,
      MOVEMENT?.chart,
      name,
      t('movement.title'),
      `${t('movement.detail.showingTopLocations')} ${primaryLocationName}`,
      '',
      null,
      companyLogo,
    );
  }

  if (MOVEMENT?.map) generateMapPage(instance, MOVEMENT.map);

  if (ATTRACTION?.chart) {
    const { visitFrom } = attraction;
    await generateUniversalSlide(
      instance,
      ATTRACTION?.chart,
      name,
      t('attraction.title'),
      t('absorption.detail.showingShareOfVisitors'),
      t('absorption.card.avgRateOfVisits'),
      visitFrom ? `${toFixNumber(visitFrom, 1)}%` : null,
      companyLogo,
    );
  }

  if (ATTRACTION?.map) generateMapPage(instance, ATTRACTION.map);

  if (ABSORPTION?.chart) {
    const { visitFrom } = absorption;
    await generateUniversalSlide(
      instance,
      ABSORPTION?.chart,
      name,
      t('absorption.title'),
      t('absorption.detail.showingShareOfVisitors'),
      t('absorption.card.avgRateOfVisits'),
      visitFrom ? `${toFixNumber(visitFrom, 1)}%` : null,
      companyLogo,
    );
  }

  if (ABSORPTION?.map) generateMapPage(instance, ABSORPTION.map);

  const fileName = generateFileName(name);

  await instance.writeFile({ fileName });
};
