import { convertDegreesToRadians } from './convertDegreesToRadians';

export const getTextCoordinates = (
  value: number,
  angleOffset: number,
  cx: number,
  cy: number,
  radius: number,
  percentage: number,
) => {
  const angle = (percentage * 360) / 2 + angleOffset;
  const radians = convertDegreesToRadians(angle);

  const textCoords = {
    x: radius * Math.cos(radians) + cx,
    y: radius * Math.sin(radians) + cy,
  };
  return textCoords;
};
