import React from 'react';
import { TooltipPositionCoordinates } from '../../../reportItems/hooks';
import { Tooltip } from '../../../tooltip';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  position: TooltipPositionCoordinates;
  title?: string;
  content?: string;
}
const TableRowTooltip: React.FC<Props> = ({ onClose, position, isOpen, title, content }) => (
  <Tooltip onClose={onClose} initialPosition={position} isOpen={isOpen} height={300} title={title}>
    <p style={{ whiteSpace: 'pre-line' }}>{content}</p>
  </Tooltip>
);

export default TableRowTooltip;
