import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { connect } from 'react-redux'; // react-router v4/v5
import ProjectsPage from '../../pages/projectsPage/projectsPage';
import ReportItemSettingDefinitionsPage from '../../pages/reportItemSettingDefinitionsPage/reportItemSettingDefinitionsPage';
import CreateReportPage from '../../pages/createReportPage/createReportPage';
import ReportIsBeingGeneratedPage from '../../pages/reportIsBeingGeneratedPage/reportIsBeingGeneratedPage';
import ReportsPage from '../../pages/reportsPage/reportsPage';
import ReportItemMonitoringPage from '../../pages/reportItemMonitoringPage/reportItemMonitoringPage';
import ReportPage from '../../pages/reportPage/reportPage';
import PoiManagementPage from '../../pages/poiManagementPage/poiManagementPage';
import CreateProjectPage from '../../pages/createProjectPage/CreateProjectPage';
import LoginAsPage from '../../pages/Authentication/loginAs/loginAsPage';
import ForgotPasswordPage from '../../pages/Authentication/forgotPassword/forgotPasswordPage';
import ResetPasswordPage from '../../pages/Authentication/resetPassword/resetPasswordPage';
import ThankYouPage from '../../pages/Authentication/forgotPassword/thankYouPage';
import ResetSuccessfullPage from '../../pages/Authentication/resetPassword/resetSuccessfullPage';
import { AppState } from '../../../reducers/reducers';
import routes from '../../../router/routes';
import UpdateProjectPage from '../../pages/UpdateProjectPage';
import ChangePasswordPage from '../../pages/ChangePasswordPage';
import UsersPage from '../../pages/UsersPage';
import AddUserPage from '../../pages/AddUserPage';
import AccountsPage from '../../pages/AccountsPage';
import AddAccountPage from '../../pages/AddAccountPage';
import EditUserPage from '../../pages/EditUserPage';
import PortalViewPage from '../../pages/PortalView';
import MainPage from '../../../customerPortalv2/mainPage/mainPage';
import 'react-loading-skeleton/dist/skeleton.css';

interface StateProps {
  isAuth: string;
  isAdmin: boolean;
}

class AppRouter<StateProps> extends Component {
  render() {
    const isAdmin = localStorage.getItem('userRole') === 'placenseAdmin';
    let portal = (
      <Switch>
        <Route exact path="/loginAs/:token">
          <LoginAsPage />
        </Route>
        <Route exact path="/loginAs/">
          <LoginAsPage />
        </Route>
        <Route>
          <MainPage />
        </Route>
      </Switch>
    );

    // if user is admin - show admin portal
    if (isAdmin) {
      portal = (
        <Switch>
          <Route exact path={routes.projects.list} component={ProjectsPage} />
          <Route exact path="/createProject" component={CreateProjectPage} />
          <Route exact path={routes.projects.update.raw} component={UpdateProjectPage} />
          <Route path="/projects/:projectId/reports/:reportId" component={ReportPage} />
          <Route exact path={routes.reports.list.raw} component={ReportsPage} />
          <Route exact path="/reportItemSettingDefinitions" component={ReportItemSettingDefinitionsPage} />
          <Route exact path={routes.reports.create.raw} component={CreateReportPage} />
          <Route exact path="/projects/:projectId/reportIsBeingGenerated" component={ReportIsBeingGeneratedPage} />
          <Route exact path="/projects/:projectId/reportItemMonitoring" component={ReportItemMonitoringPage} />
          <Route exact path="/projects/:projectId/poiManagement" component={PoiManagementPage} />
          <Route exact path={routes.profile.changePassword} component={ChangePasswordPage} />
          <Route exact path={routes.users.update.raw} component={EditUserPage} />
          <Route exact path={routes.users.list} component={UsersPage} />
          <Route exact path={routes.users.add} component={AddUserPage} />
          <Route exact path={routes.accounts.list} component={AccountsPage} />
          <Route exact path={routes.accounts.add} component={AddAccountPage} />
          <Route exact path={routes.customerPortalView.view} component={PortalViewPage} />
          <Route component={ProjectsPage} />
        </Switch>
      );
    }

    return isAuthenticated() ? (
      portal
    ) : (
      <Switch>
        <Route exact path="/forgotPassword" component={ForgotPasswordPage} />
        <Route exact path="/forgotPassword/thankYou" component={ThankYouPage} />
        <Route exact path="/resetPassword/thankYou" component={ResetSuccessfullPage} />
        <Route exact path="/resetPassword/:token" component={ResetPasswordPage} />
        <Route exact path="/loginAs/:token" component={LoginAsPage} />
        <Route exact path="/loginAs/" component={LoginAsPage} />
        <Route path="/" component={MainPage} />
        <Route component={MainPage} /> {/* {NoMatch} /> */}
      </Switch>
    );

    function isAuthenticated(): boolean {
      const dateTimeNow = new Date();
      const timeStamp = Math.floor(dateTimeNow.getTime() / 1000);
      const auth = localStorage.getItem('isAuthenticated') ? localStorage.getItem('isAuthenticated') : 'false';
      const storedExpireTimeStamp = localStorage.getItem('expireTimeStamp');
      if (storedExpireTimeStamp && timeStamp.toString() < storedExpireTimeStamp && auth && auth === 'true') {
        const updateDateTime = new Date();
        const expireDateTime = new Date(updateDateTime.setDate(updateDateTime.getDate() + 30));
        const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000);
        localStorage.setItem('expireTimeStamp', expireTimeStamp.toString());
        return true;
      }
      if (auth && auth === 'true') {
        localStorage.clear();
        window.location.reload();
      } else {
        localStorage.clear();
      }
      return false;
    }
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isAuth: state.login.isAuthenticated,
  isAdmin: state.auth?.userRole === 'placenseAdmin',
});

export default withRouter(connect(mapStateToProps)(AppRouter as any));
