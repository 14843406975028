import React from 'react';
import styles from './toggle.module.scss';
import { IconProps } from '../../icons/types';

interface Props<T> {
  leftValue: T;
  LeftIcon: React.ComponentType<IconProps>;
  rightValue: T;
  RightIcon: React.ComponentType<IconProps>;
  selectedValue: T;
  onClick?: (value: T) => void;
  disabled?: boolean;
  dark?: boolean;
  withFrame?: boolean;
  withOptionFrame?: boolean;
}

export const Toggle = <T,>({
  leftValue,
  LeftIcon,
  rightValue,
  RightIcon,
  selectedValue,
  onClick,
  disabled,
  dark,
  withFrame,
  withOptionFrame,
}: Props<T>) => {
  const isLeftValueSelected = selectedValue === leftValue;

  const onClickHandler = () => {
    if (onClick) {
      onClick(isLeftValueSelected ? rightValue : leftValue);
    }
  };

  return (
    <button
      className={`${styles.container} ${dark ? styles.dark : ''} ${withFrame ? styles.frame : ''} ${
        withOptionFrame ? styles.optionFrame : ''
      }`}
      type="button"
      onClick={onClickHandler}
      disabled={disabled}
    >
      <span className={isLeftValueSelected ? '' : styles.disabled} id={`${leftValue}`} title={`${leftValue}`}>
        <LeftIcon active={isLeftValueSelected} />
      </span>
      <span className={isLeftValueSelected ? styles.disabled : ''} id={`${rightValue}`} title={`${rightValue}`}>
        <RightIcon active={!isLeftValueSelected} />
      </span>
    </button>
  );
};

Toggle.defaultProps = {
  disabled: false,
  dark: false,
};
