import { Gender } from '../../interfaces';
import { replaceValueToNA } from '../replaceValueToNA';
import { SexIcon } from '../../../../../../../common/icons/charts';

export const getSex = (value: Gender | null) => {
  const { totalFemalePct, totalMalePct } = value || {};
  return {
    id: 3,
    icon: SexIcon,
    data: [
      {
        id: 1,
        color: '#DBAA85',
        title: 'female',
        value: totalFemalePct ? `${totalFemalePct}%` : replaceValueToNA(totalFemalePct),
        stake: totalFemalePct || 100,
      },
      {
        id: 2,
        color: '#EADED5',
        title: 'male',
        value: totalMalePct ? `${totalMalePct}%` : replaceValueToNA(totalFemalePct),
        stake: totalMalePct || 0,
      },
    ],
  };
};
