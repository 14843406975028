import { CallbackDataParams } from 'echarts/types/dist/shared';
import styles from './style.module.scss';
import { separateThousandsByComma } from '../../../../../common/utils';

export const generateTooltip = (data: CallbackDataParams[], isRelevantMetric: boolean, currency: string | null) => {
  const title = data[0].name;
  const shouldBeOrdered = data.length > 5;
  const columnCount = Math.ceil(data.length / 5);

  let toolTipHtml = '<div>';
  toolTipHtml += `<p class="${styles.title}">${title}</p>`;
  toolTipHtml += `<div class="${styles.wrapper}" style="${`grid-template-rows: repeat(${
    shouldBeOrdered ? 5 : data.length
  }, 1fr);grid-template-columns: repeat(${columnCount}, 1fr)`}">`;
  data.forEach(({ marker, value, seriesName }) => {
    toolTipHtml += `<div class="${styles.row}">${marker} <span class="${styles.item}">${seriesName?.replace(
      '<CURRENCY>',
      currency || '',
    )}: <p class="${styles.value}">${
      !isRelevantMetric ? (currency || '') + separateThousandsByComma(String(value)) : value
    }${isRelevantMetric ? '%' : ''}</p></span></div>`;
  });
  toolTipHtml += `</div>`;
  toolTipHtml += '</div>';
  return toolTipHtml;
};
