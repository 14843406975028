import React from 'react';

interface Props {
  style: { [key: string]: string | number };
}
export const ArrowIcon = ({ style }: Props) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M9.55668 0.515684L6.00001 4.07235L2.44335 0.515684C2.08585 0.158184 1.50835 0.158184 1.15085 0.515684C0.793346 0.873184 0.793346 1.45068 1.15085 1.80818L5.35835 6.01568C5.71585 6.37318 6.29335 6.37318 6.65085 6.01568L10.8583 1.80818C11.2158 1.45068 11.2158 0.873184 10.8583 0.515684C10.5008 0.16735 9.91418 0.158184 9.55668 0.515684Z"
      fill="#00B2F1"
    />
  </svg>
);
