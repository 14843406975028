import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../controls/dropdown';
import styles from './style.module.scss';
import { Button } from '../../../controls/buttons';
import { useToggle } from '../../../hooks';
import { UnSaveChangesTooltip } from './tooltip';
import { PolygonData } from '../interfaces';
import { PolygonField } from './polygonField';
import { TextInput } from '../../../controls/input/textInput';
import { TextArea } from '../../../controls/input/textArea';
import { SetupType } from '../interfaces/SetupType';
import { useTooltipStartPosition } from '../../../reportItems/hooks';
import LocationSetupTooltip from './tooltip/locationSetupTooltip/locationSetupTooltip';
import { InformationIcon } from '../../../icons/reportItems';

interface Props {
  onCancel: VoidFunction;
  onSetPolygon: (data: string) => void;
  onSubmit: (data: PolygonData) => void;
  coordinates: string;
  editedLocation: PolygonData | null;
  isEdit?: boolean;
}

export const Form: React.FC<Props> = ({
  onCancel,
  coordinates,
  onSetPolygon,
  onSubmit,
  editedLocation,
  isEdit = false,
}) => {
  const { t } = useTranslation();
  const [locationData, setLocationData] = useState<PolygonData>(
    editedLocation
      ? { ...editedLocation }
      : {
          id: '',
          polygonString: '',
          polygon: null,
          name: '',
          description: '',
          country: '',
          street: '',
          city: '',
          postalCode: '',
          setup: SetupType.UNDEFINED,
          isUsed: false,
          isNew: true,
        },
  );
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const { tooltipIconRef, position } = useTooltipStartPosition();

  const closeTooltip = () => setIsTooltipOpened(false);
  const openTooltip = () => setIsTooltipOpened(true);

  const [isUnSaveChangesTooltipOpen, toggleUnSaveChangesTooltipOpen] = useToggle(false);

  const locationTypeOptions = Object.keys(SetupType).map((item) => ({
    id: item,
    label: t(`locations.enum.${item}`),
    item: null,
    disabled: SetupType.UNDEFINED === item,
  }));

  const onCreate = () => {
    onSubmit(locationData);
  };

  const onCancelWithoutSaving = () => {
    if (locationData.polygonString === '' && locationData.name === '' && locationData.setup) {
      onCancel();
    } else {
      toggleUnSaveChangesTooltipOpen(true);
    }
  };

  const isValid =
    locationData.polygonString && locationData.name && locationData.setup && locationData.setup != SetupType.UNDEFINED;

  const polygonField = useMemo(
    () => <PolygonField polygon={locationData.polygonString} onChange={onSetPolygon} />,
    [locationData.polygonString],
  );

  useEffect(() => {
    setLocationData({ ...locationData, polygonString: coordinates || '' });
  }, [coordinates]);

  const hideUnsavedChangesPopup = () => toggleUnSaveChangesTooltipOpen(false);

  return (
    <div className={styles.form}>
      <LocationSetupTooltip onClose={closeTooltip} isOpen={isTooltipOpened} position={position} />
      <h4 className={styles.title}>{t('locations.createLocation')}</h4>
      <p className={styles.subTitle}>{t('locations.drawAPolygon')}</p>
      <div className={styles.fields}>
        <div className={styles.field}>
          {/* Carry on working on multiple polygons inside textarea once it's needed */}
          <div className={styles.lineField}>
            <label className={styles.label}>
              {t('locations.polygon')} <span>*</span>
            </label>
            {polygonField}
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.lineField}>
            <label className={styles.label}>
              {t('locations.name')} <span>*</span>
            </label>
            <TextInput
              name="name"
              value={locationData.name}
              onChange={(val) => setLocationData({ ...locationData, name: val })}
            />
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.lineField}>
            <label className={styles.label}>{t('locations.description')}</label>
            <TextArea
              name="description"
              value={locationData.description}
              onChange={(val) => setLocationData({ ...locationData, description: val })}
              height="120px"
            />
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.lineField}>
            <label className={styles.label}>{t('locations.country')}</label>
            <TextInput
              name="country"
              value={locationData.country}
              onChange={(val) => setLocationData({ ...locationData, country: val })}
              disabled={isEdit}
            />
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.lineField}>
            <label className={styles.label}>{t('locations.address')}</label>
            <TextInput
              name="street"
              value={locationData.street}
              onChange={(val) => setLocationData({ ...locationData, street: val })}
              disabled={isEdit}
            />
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.lineField}>
            <label className={styles.label}>City</label>
            <TextInput
              name="city"
              value={locationData.city}
              onChange={(val) => setLocationData({ ...locationData, city: val })}
              disabled={isEdit}
            />
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.lineField}>
            <label className={styles.label}>{t('locations.postcode')}</label>
            <TextInput
              name="postalCode"
              value={locationData.postalCode}
              onChange={(val) => setLocationData({ ...locationData, postalCode: val })}
              disabled={isEdit}
            />
          </div>
        </div>

        <div className={styles.field}>
          <div className={styles.lineField}>
            <div className={styles.labelTooltipContainer}>
              <label className={styles.label}>
                {t('locations.locationType.title')} <span>*</span>
              </label>
              <span className={styles.tooltipIconContainer} ref={tooltipIconRef}>
                <span className={styles.tooltipIcon} onClick={openTooltip} title={t('locations.locationType.title')}>
                  <InformationIcon />
                </span>
              </span>
            </div>
            <Dropdown<null>
              options={locationTypeOptions}
              onChange={(val) => setLocationData({ ...locationData, setup: val.id as SetupType })}
              selectedId={locationData.setup}
              placeholder={t('locations.selectType')}
              classNames={styles.dropdown}
            />
          </div>
        </div>

        {isUnSaveChangesTooltipOpen && (
          <div className={`${styles.tooltip} ${styles.unSave}`}>
            <UnSaveChangesTooltip onNo={hideUnsavedChangesPopup} onYes={onCancel} />
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <Button onClick={onCancelWithoutSaving}>Cancel</Button>
        <Button layout="primary" disabled={!isValid} onClick={onCreate}>
          {editedLocation ? 'Update' : 'Create'}
        </Button>
      </div>
    </div>
  );
};
