import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Fade from '@material-ui/core/Fade';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from 'react-redux';
import { changeProjectStatus } from '../../../actions/actions';
import { Project } from '../../../types/project';
import routes from '../../../router/routes';
import styles from './projectCard.module.css';
import { STATUS } from './status';

interface State {
  project: Project;
  onEditProject: (project: Project) => void;
  onDeleteProject: (project: Project) => void;
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#c4b498',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const ProjectCard = ({ project, onEditProject, onDeleteProject }: State): ReactElement => {
  const editProjectHandler = (event: React.MouseEvent): void => {
    event.preventDefault();
    onEditProject(project);
  };

  const deleteProjectHandler = (event: React.MouseEvent): void => {
    event.preventDefault();
    onDeleteProject(project);
  };

  const dispatch = useDispatch();

  const [status, setStatus] = React.useState(project.status);
  const handleChange = () => {
    if (project.status?.toLowerCase() !== STATUS.PRESENTABLE) {
      setStatus(STATUS.PRESENTABLE);
      project.status = STATUS.PRESENTABLE_VALUE;
    } else {
      setStatus(STATUS.READY);
      project.status = STATUS.READY_VALUE;
    }
    if (project.id) {
      dispatch(changeProjectStatus(project.id, project.status));
    }
  };

  const stylesWhenPresentable = {
    border: '3px solid green',
    borderRadius: '10px',
  };

  const stylesWhenNotPresentable = {
    border: 'none',
  };
  return (
    <Link
      to={routes.reports.list.withId(project.id)}
      className={styles.container}
      key={project.id}
      style={project.status?.toLowerCase() === STATUS.PRESENTABLE ? stylesWhenPresentable : stylesWhenNotPresentable}
    >
      <div className={styles.project}>
        <div className={styles.heading}>
          <div className={styles.headingActions}>
            <EditIcon className={styles.optionEditIcon} fontSize="inherit" onClick={editProjectHandler} />
            <DeleteIcon className={styles.optionDeleteIcon} fontSize="inherit" onClick={deleteProjectHandler} />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.row}>
            <span className={styles.propertyName}>ID:</span>
            <span className={styles.propertyValue}>{project.id}</span>
          </div>
          <div className={styles.row}>
            <span className={styles.propertyName}>Name:</span>
            <span className={styles.propertyValue}>{project.name}</span>
          </div>
          <div className={styles.row}>
            <span className={styles.propertyName}>Description:</span>
            <span className={styles.propertyValue}>{project.description}</span>
          </div>
          <div className={styles.row}>
            <HtmlTooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={
                <>
                  <h2>id=</h2>
                  {project.account_id}
                </>
              }
              arrow
            >
              <div
                className={styles.propertyName}
                // onClick={(e) => copyAccountId(e, project, project.account_id ? project.account_id : "")}
              >
                Account:
              </div>
            </HtmlTooltip>
            <div className={styles.propertyValue}>{project.account_name}</div>
          </div>
          {project.account_id && (
            <CopyToClipboard text={project.account_id ? project.account_id : ''}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Copy Account ID
              </button>
            </CopyToClipboard>
          )}
          <div className={styles.row}>
            <span className={styles.propertyName}>Date:</span>
            <span className={styles.propertyValue}>
              {project.date && project.date.replace('-', '/').replace('-', '/')}
            </span>
          </div>
          {(project.status?.toLowerCase() === STATUS.PRESENTABLE || project.status?.toLowerCase() === STATUS.READY) && (
            <FormControlLabel
              onClick={(e) => {
                e.preventDefault();
                handleChange();
              }}
              control={<Switch checked={status?.toLowerCase() === STATUS.PRESENTABLE} name="isVisible" />}
              label="Is Visible"
            />
          )}
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
