import { getReportItems } from '../../api';
import { SeasonalityResponse } from './types';

import { mapResponse } from './mapper';
import { Periods, REQUEST_DELAY } from '../types';
import { ProjectInfo, ReportItemInfo, ReportItems, SelectedTimeFrames } from '../../common/types/projectsHierarchy';
import { PeriodsEnum, ReportItemType } from '../../common/reportItems/types';

export const getSeasonalityData = async (
  reportItemId: string,
  timeFrames: SelectedTimeFrames,
  locations: string[],
  selectedProject: ProjectInfo | null,
  updateReportItemOfSelectedReport: (item: ReportItemInfo<ReportItems>) => void,
  setIsFetching: (value: boolean) => void,
  setFetched: (value: boolean) => void,
  selectedReportItem: ReportItemInfo<ReportItems> | null,
) => {
  try {
    setIsFetching(true);
    const projectLocations = selectedProject?.locations || [];
    const timeFrameResolution = Periods.WHOLE_PERIOD.replace(/ /g, '_').toUpperCase();
    if (selectedReportItem?.type !== ReportItemType.SEASONALITY) {
      await new Promise((resolve) => setTimeout(resolve, REQUEST_DELAY));
    }
    let responseData = await getReportItems<SeasonalityResponse>(
      reportItemId,
      timeFrameResolution as PeriodsEnum,
      locations,
      timeFrames,
    );
    if (responseData) {
      responseData = {
        ...responseData,
        hourly_values: responseData.hourly_values.sort((valueA, valueB) =>
          valueA.location_name.localeCompare(valueB.location_name),
        ),
        daily_values: responseData.daily_values.sort((valueA, valueB) =>
          valueA.location_name.localeCompare(valueB.location_name),
        ),
      };
      const data = mapResponse(responseData, projectLocations);
      updateReportItemOfSelectedReport(data);
    }
  } finally {
    setFetched(true);
    setIsFetching(false);
  }
};
