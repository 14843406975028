import React from 'react';
import { RefreshIcon } from '../../icons/map';
import styles from './style.module.scss';

interface Props {
  onUndo: VoidFunction;
  onCreateMode: VoidFunction;
}
export const EditorActions: React.FC<Props> = ({ onUndo, onCreateMode }) => (
  <div className={styles.wrapper}>
    <div className={styles.button} onClick={onUndo} title="Undo">
      <RefreshIcon />
    </div>
    <div className={`${styles.button} ${styles.custom}`} onClick={onCreateMode} title="Create mode">
      C
    </div>
  </div>
);
