import React from 'react';
import styles from './style.module.scss';

interface Props {
  name: string;
  id: string;
  value: string;
  placeholder?: string;
  onChange: any;
  isError: boolean;
  isDisabled?: boolean;
  maxLength?: number;
}
export const Textarea = ({ name, id, value, onChange, isError, placeholder, isDisabled, maxLength }: Props) => {
  return (
    <textarea
      className={`${styles.textarea} ${isError ? styles.error : ''}`}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={isDisabled}
      maxLength={maxLength}
    />
  );
};
