import { useCallback, useState } from 'react';

type ReturnValue = [boolean, (value?: boolean) => void];

export const useToggle = (initialValue = false): ReturnValue => {
  const [isToggle, setToggle] = useState(initialValue);

  const onToggle = useCallback((value?: boolean) => {
    setToggle((prevState) => (typeof value === 'boolean' ? value : !prevState));
  }, []);

  return [isToggle, onToggle];
};
