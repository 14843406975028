import React from 'react';

export const TrashIcon = () => {
  return (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.18611 5.81L5.53722 7.45889L3.88055 5.81L2.78388 6.90667L4.44055 8.55556L2.79166 10.2044L3.88833 11.3011L5.53722 9.65222L7.18611 11.3011L8.28277 10.2044L6.63388 8.55556L8.28277 6.90667L7.18611 5.81ZM8.25944 0.777778L7.48166 0H3.59277L2.815 0.777778H0.0927734V2.33333H10.9817V0.777778H8.25944ZM0.870551 12.4444C0.870551 13.3 1.57055 14 2.42611 14H8.64833C9.50388 14 10.2039 13.3 10.2039 12.4444V3.11111H0.870551V12.4444ZM2.42611 4.66667H8.64833V12.4444H2.42611V4.66667Z"
        fill="white"
      />
    </svg>
  );
};
