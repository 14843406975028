import React from 'react';
import { IconProps } from '../types';

export const GraphIcon: React.FC<IconProps> = ({ active }) => (
  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.4999 0.399902C8.0469 0.399902 9.2999 1.6529 9.2999 3.1999C9.2999 4.7469 8.0469 5.9999 6.4999 5.9999C4.9529 5.9999 3.6999 4.7469 3.6999 3.1999C3.6999 1.6529 4.9529 0.399902 6.4999 0.399902ZM6.4999 1.7999C5.7299 1.7999 5.0999 2.4299 5.0999 3.1999C5.0999 3.9699 5.7299 4.5999 6.4999 4.5999C7.2699 4.5999 7.8999 3.9769 7.8999 3.1999C7.8999 2.4229 7.2769 1.7999 6.4999 1.7999ZM6.4999 6.6999C8.3689 6.6999 12.0999 7.6309 12.0999 9.4999V10.5999C12.0999 11.1522 11.6522 11.5999 11.0999 11.5999H1.8999C1.34762 11.5999 0.899902 11.1522 0.899902 10.5999V9.4999C0.899902 7.6309 4.6309 6.6999 6.4999 6.6999ZM6.4999 8.0299C4.4209 8.0299 2.2299 9.0519 2.2299 9.4999V10.2699H10.7699V9.4999C10.7699 9.0519 8.5789 8.0299 6.4999 8.0299Z"
      fill={!active ? 'white' : '#1F303D'}
    />
    <path
      d="M4.5 14.6001C4.5 15.1524 4.05228 15.6001 3.5 15.6001C2.94772 15.6001 2.5 15.1524 2.5 14.6001C2.5 14.0478 2.94772 13.6001 3.5 13.6001C4.05228 13.6001 4.5 14.0478 4.5 14.6001Z"
      fill={!active ? 'white' : '#1F303D'}
    />
    <path
      d="M7.5 14.6001C7.5 15.1524 7.05228 15.6001 6.5 15.6001C5.94772 15.6001 5.5 15.1524 5.5 14.6001C5.5 14.0478 5.94772 13.6001 6.5 13.6001C7.05228 13.6001 7.5 14.0478 7.5 14.6001Z"
      fill={!active ? 'white' : '#1F303D'}
    />
    <path
      d="M10.5 14.6001C10.5 15.1524 10.0523 15.6001 9.5 15.6001C8.94772 15.6001 8.5 15.1524 8.5 14.6001C8.5 14.0478 8.94772 13.6001 9.5 13.6001C10.0523 13.6001 10.5 14.0478 10.5 14.6001Z"
      fill={!active ? 'white' : '#1F303D'}
    />
  </svg>
);
