import React, { Component } from 'react';
import Modal from 'react-modal';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import styles from './editReportItemModal.module.css';
import Button from '../../atoms/primaryButton/button';
import { ReportItem, ReportItemSetting } from '../../../types/report';
import { ReportItemSettingDefinition } from '../../../types/reportItemSettingDefinition';
import Help from '../../atoms/help/help';

interface Props {
  reportItem: ReportItem;
  settingDefinitions: ReportItemSettingDefinition[];
  editReportItem: (reportItem: ReportItem) => any;
  saveEditedDescription: (reportItem: ReportItem) => any;
  reloadReportItemsInReportsPage: () => any;
}

interface State {
  editedReportItem: ReportItem;
  editedDescription: boolean;
  editedSettings: boolean;
  isModalOpen: boolean;
}

export default class EditReportItemModal extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    const inputRi = this.props.reportItem;
    this.state = {
      editedReportItem: {
        id: inputRi.id,
        name: inputRi.name,
        description: inputRi.description,
        reportItemType: inputRi.reportItemType,
        settings: inputRi.settings,
        reportId: inputRi.reportId,
      },
      editedDescription: false,
      editedSettings: false,
      isModalOpen: false,
    };
  }

  render() {
    return (
      <>
        {this.renderModal()}
        <Tooltip title="Edit Report Item">
          <EditIcon
            className={styles.optionIcon}
            fontSize="inherit"
            onClick={() => {
              this.openModal();
            }}
          />
        </Tooltip>
      </>
    );
  }

  private renderName() {
    return (
      <div className={styles.nameBox}>
        <h1 className={styles.title}>
          <input
            className={this.state.editedDescription ? styles.editedInput : styles.input}
            value={this.state.editedReportItem.name}
            onChange={(event) => {
              const reportItem = this.state.editedReportItem;
              reportItem.name = event.target.value;
              this.setState({ editedDescription: true, editedReportItem: reportItem });
            }}
          />
        </h1>
      </div>
    );
  }

  private renderDescription() {
    return (
      <div className={styles.descriptionBox}>
        <textarea
          className={this.state.editedDescription ? styles.editedInput : styles.input}
          value={this.state.editedReportItem.description}
          onChange={(event) => {
            const reportItem = this.state.editedReportItem;
            reportItem.description = event.target.value;
            this.setState({ editedDescription: true, editedReportItem: reportItem });
          }}
        />
        {this.renderSaveDescriptionButtons()}
      </div>
    );
  }

  private renderSaveDescriptionButtons() {
    if (this.state.editedDescription) {
      return (
        <div className={styles.descriptionButtons}>
          <div className={styles.saveButton}>
            <Button
              onClick={() => {
                this.saveEditedDescription();
              }}
            >
              Save Metadata
            </Button>
          </div>
          <div className={styles.resetDescriptionButton}>
            <Button
              onClick={() => {
                this.setState({ editedDescription: false });
                const reportItem = this.state.editedReportItem;
                reportItem.name = this.props.reportItem.name;
                reportItem.description = this.props.reportItem.description;
                this.setState({ editedDescription: true, editedReportItem: reportItem });
                this.saveEditedDescription();
              }}
            >
              Reset Metadata
            </Button>
          </div>
        </div>
      );
    }
  }

  private saveEditedDescription() {
    this.props.saveEditedDescription(this.state.editedReportItem);
    this.setState({ editedDescription: false });
  }

  private renderSettings() {
    const { settingDefinitions } = this.props;
    if (!settingDefinitions || settingDefinitions.length === 0) {
      return;
    }
    return (
      <div className={styles.formContainer}>
        {settingDefinitions.map((settingDefinition) => this.renderSetting(settingDefinition))}
      </div>
    );
  }

  private renderSetting(settingDefinition: ReportItemSettingDefinition) {
    const isSettingInvalid = this.isSettingInvalid(settingDefinition.name);
    if (settingDefinition.settingType === 'simpleText') {
      return this.renderSimpleTextSettingForItem(settingDefinition, isSettingInvalid);
    }
    if (settingDefinition.settingType === 'singleChoice') {
      return this.renderSingleChoiceSettingForItem(settingDefinition, isSettingInvalid);
    }
    return null;
  }

  private renderSimpleTextSettingForItem(settingDefinition: ReportItemSettingDefinition, isSettingInvalid: boolean) {
    return (
      <div key={settingDefinition.name} className={styles.formRow}>
        <div className={styles.fieldName}>
          {settingDefinition.name}
          {settingDefinition.isMandatory ? '*' : ''}
          {settingDefinition.description && <Help helpMessage={settingDefinition.description} />}
        </div>
        <div className={styles.valueContainer}>
          <input
            className={classNames(styles.input, { [styles.inputInvalid]: isSettingInvalid })}
            value={this.itemSettingValue(settingDefinition.name, settingDefinition.defaultValue)}
            onChange={(event) => {
              const { value } = event.target;
              return this.updateItemSetting(settingDefinition, value);
            }}
          />
          {isSettingInvalid && <div className={styles.fieldErrorMessage}>This field is required</div>}
        </div>
      </div>
    );
  }

  private renderSingleChoiceSettingForItem(settingDefinition: ReportItemSettingDefinition, isSettingInvalid: boolean) {
    return (
      <div key={settingDefinition.name} className={styles.formRow}>
        <div className={styles.fieldName}>
          {settingDefinition.name}
          {settingDefinition.isMandatory ? '*' : ''}
          {settingDefinition.description && <Help helpMessage={settingDefinition.description} />}
        </div>
        <div className={styles.valueContainer}>
          <select
            className={classNames(styles.select, { [styles.inputInvalid]: isSettingInvalid })}
            value={this.itemSettingValue(settingDefinition.name, settingDefinition.defaultValue)}
            onChange={(event) => {
              const { value } = event.target;
              return this.updateItemSetting(settingDefinition, value);
            }}
          >
            {settingDefinition.possibleValues.map((possibleValue) => (
              <option key={possibleValue}>{possibleValue}</option>
            ))}
          </select>
          {isSettingInvalid && <div className={styles.fieldErrorMessage}>This field is required</div>}
        </div>
      </div>
    );
  }

  private isSettingInvalid(settingName: string) {
    // TODO:
    // const item = this.state.itemErrors.find(item => item.reportItemDetails.internalId === reportItemDetails.internalId) || {errors: []};
    // return item.errors.find(erroneousSettingName => erroneousSettingName === settingName);
    return false;
  }

  private updateItemSetting(settingDefinition: ReportItemSettingDefinition, value: string) {
    if (!value || value === '') {
      return;
    }
    const newEditedSettings = this.updateSettings(this.state.editedReportItem.settings, settingDefinition, value);
    const reportItem = this.state.editedReportItem;
    reportItem.settings = newEditedSettings;
    this.setState({ editedReportItem: reportItem });
  }

  private updateSettings(
    settings: ReportItemSetting[],
    settingDefinition: ReportItemSettingDefinition,
    newValue: string,
  ) {
    if (settings.find((s) => s.name === settingDefinition.name)) {
      return settings.map((setting) => {
        if (setting.name === settingDefinition.name) {
          return { ...setting, value: newValue };
        }
        return setting;
      });
    }
    return [
      ...settings,
      {
        name: settingDefinition.name,
        value: newValue,
      },
    ];
  }

  private itemSettingValue(settingName: string, defaultValue: string): string {
    const maybeSettingInProps = this.props.reportItem.settings.find((setting) => setting.name === settingName);
    const maybeSettingInState = this.state.editedReportItem.settings.find((setting) => setting.name === settingName);
    if (maybeSettingInState) {
      if (
        !this.state.editedSettings &&
        (!maybeSettingInProps || maybeSettingInProps.value !== maybeSettingInState.value)
      ) {
        this.setState({ editedSettings: true });
      }
      return maybeSettingInState.value;
    }
    if (!this.state.editedSettings && maybeSettingInProps) {
      this.setState({ editedSettings: true });
    }
    return defaultValue;
  }

  private renderModal() {
    return (
      <Modal
        isOpen={this.state.isModalOpen}
        onRequestClose={() => this.closeModal()}
        ariaHideApp={false}
        className={styles.modal}
      >
        <div className={styles.modalContent}>
          <div>
            {this.renderName()}
            <h1 className={styles.title}>{this.props.reportItem.name}</h1>
            <h4>(of type {this.props.reportItem.reportItemType})</h4>
            {this.renderDescription()}
          </div>
          <div className={styles.modalButtons}>
            <Button
              disabled={!this.state.editedSettings}
              onClick={() => {
                if (this.state.editedSettings) {
                  this.editReportItem();
                  this.closeModal();
                  this.reloadReports();
                }
              }}
            >
              Approve
            </Button>
            <Button
              // secondary={true}
              onClick={() => {
                this.resetReportItemSettings();
                this.closeModal();
              }}
            >
              Cancel
            </Button>
            <div className={styles.resetSettingsButton}>
              <Button
                disabled={!this.state.editedSettings}
                onClick={() => {
                  if (this.state.editedSettings) {
                    this.resetReportItemSettings();
                  }
                }}
              >
                Reset Settings
              </Button>
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <hr className={styles.delimiter} />
            {this.renderSettings()}
          </div>
        </div>
      </Modal>
    );
  }

  private openModal() {
    this.setState({ isModalOpen: true });
  }

  private closeModal() {
    this.setState({ isModalOpen: false });
  }

  private reloadReports() {
    this.props.reloadReportItemsInReportsPage();
    setTimeout(this.props.reloadReportItemsInReportsPage(), 20);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 80);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 200);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 200);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 300);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 1000);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 1000);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 10000);
  }

  private editReportItem() {
    this.props.editReportItem(this.state.editedReportItem);
  }

  private resetReportItemSettings() {
    const inputRi = this.props.reportItem;
    this.setState({
      editedReportItem: {
        id: inputRi.id,
        name: inputRi.name,
        description: inputRi.description,
        reportItemType: inputRi.reportItemType,
        settings: inputRi.settings,
      },
      editedDescription: false,
      editedSettings: false,
    });
  }
}
