import React, { ReactElement, useState } from 'react';
import styles from './changePasswordForm.module.css';
import Button from '../../atoms/primaryButton/button';
import ErrorMessage from '../errorMessage';

interface Props {
  onChange: (currentPassword: string, newPassword: string, reTypedNewPassword: string) => void;
}

const ChangePasswordForm = ({ onChange }: Props): ReactElement => {
  const [state, setState] = useState({
    currentPassword: '',
    newPassword: '',
    reTypedNewPassword: '',
  });

  const updateInputFieldHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  const changePasswordHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onChange(state.currentPassword, state.newPassword, state.reTypedNewPassword);
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <ErrorMessage />
        <form className={styles.form} onSubmit={changePasswordHandler}>
          <div className={styles.formRow}>
            <label htmlFor="currentPassword" className={styles.label}>
              Current password
              <input
                className={styles.input}
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={state.currentPassword}
                onChange={updateInputFieldHandler}
              />
            </label>
          </div>
          <div className={styles.formRow}>
            <label htmlFor="newPassword" className={styles.label}>
              New password
              <input
                className={styles.input}
                type="password"
                id="newPassword"
                name="newPassword"
                value={state.newPassword}
                onChange={updateInputFieldHandler}
              />
            </label>
          </div>
          <div className={styles.formRow}>
            <label htmlFor="reTypedNewPassword" className={styles.label}>
              Re-type new password
              <input
                className={styles.input}
                type="password"
                id="reTypedNewPassword"
                name="reTypedNewPassword"
                value={state.reTypedNewPassword}
                onChange={updateInputFieldHandler}
              />
            </label>
          </div>
          <div className={styles.buttonContainer}>
            <Button type="submit">Change password</Button>
          </div>
        </form>
      </div>
      <div className={styles.requirementsContainer}>
        <span>Password requirements:</span>
        <ul className={styles.requirementsList}>
          <li>at least 8 characters</li>
          <li>at least 1 lowercase</li>
          <li>at least 1 uppercase</li>
          <li>at least 1 digit</li>
          <li>at least 1 special character, such as: !@#$%^&*().,</li>
        </ul>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
