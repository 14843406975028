import React from 'react';
import Dropdown from '../../../../common/controls/dropdown';
import styles from '../style.module.scss';

interface TProps {
  areaType: string;
  onChange: (areaType: string) => void;
}

type TOption = {
  id: string;
  label: string;
  item: null;
};

export const areaTypes: TOption[] = [
  { id: '1', label: 'High (by neighborhood, 50km radius)', item: null },
  { id: '2', label: 'Mid-high (by large city/municipality, 70km radius)', item: null },
  { id: '3', label: 'Medium (by municipality, 100km radius)', item: null },
  { id: '4', label: 'Mid-low (by region/district, 300km radius)', item: null },
  { id: '5', label: 'Low (by state/province, 1000km radius)', item: null },
];

export const AreaTypeDropdown = ({ areaType, onChange }: TProps) => {
  const onDropdownChange = (item: TOption) => {
    if (item.id) {
      onChange(item.id);
    }
  };
  return (
    <div className={styles.wrapper}>
      <Dropdown<null>
        onChange={onDropdownChange}
        selectedId={areaType}
        options={areaTypes}
        classNames={styles.dropdown}
      />
    </div>
  );
};
