import React from 'react';
import { Redirect } from 'react-router-dom';
import { checkIsUserAuthenticated } from '../auth/utils';
import { useToggle } from '../common/hooks';
import { Routes } from '../routes';

export const RedirectAuthedUser: React.FC = ({ children }) => {
  const [isAuthed, setIsAuthed] = useToggle(checkIsUserAuthenticated());

  if (isAuthed) return <Redirect to={Routes.dashboard} />;

  return <>{children}</>;
};
