import React from 'react';

export const InfoIcon = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.50033 0.666748C3.90033 0.666748 0.166992 4.40008 0.166992 9.00008C0.166992 13.6001 3.90033 17.3334 8.50033 17.3334C13.1003 17.3334 16.8337 13.6001 16.8337 9.00008C16.8337 4.40008 13.1003 0.666748 8.50033 0.666748ZM9.33366 13.1667H7.66699V11.5001H9.33366V13.1667ZM9.33366 9.83342H7.66699V4.83342H9.33366V9.83342Z"
      fill="#00B2F1"
    />
  </svg>
);
