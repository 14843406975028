import { HouseHold } from '../../interfaces';
import { replaceValueToNA } from '../replaceValueToNA';
import { FamilyStatusIcon } from '../../../../../../../common/icons/charts';
import {
  capitalizeEachWordInString,
  convertStringToCamelCase,
  makeFirstLetterLow,
} from '../../../../../../../common/utils';

const getTop = (length: number) => {
  const ITEMS_PER_ROW = 3;
  const rowsCount = Math.ceil(length / ITEMS_PER_ROW);
  switch (rowsCount) {
    case 1:
      return 70;
    default:
      return 30;
  }
};

const data = [
  {
    id: 1,
    color: '#EADED5',
    title: 'familiesWithKids',
    value: 'N/A',
    stake: 100,
    styles: { label: { width: '66px' }, value: {}, item: {} },
  },
  {
    id: 2,
    color: '#DBAA85',
    title: 'familiesNoKids',
    value: 'N/A',
    stake: 0,
    styles: { label: { width: '62px' }, value: {}, item: {} },
  },
  {
    id: 3,
    color: 'rgba(219, 170, 133, 0.7)',
    title: 'singles',
    value: 'N/A',
    stake: 0,
    styles: { label: { width: '62px' }, value: {}, item: {} },
  },
];

const colors = [
  '#EADED5',
  '#DBAA85',
  '#e6c4aa',
  '#edd5c2',
  '#f4e6da',
  '#A6ADB6',
  '#E4B7A0',
  '#A45C40',
  '#B97D60',
  '#F0CCB0',
];
export const getFamily = (values: HouseHold[] | null) => ({
  id: 10,
  icon: FamilyStatusIcon,
  styles: { item: { gridTemplateColumns: `repeat(3, 1fr)`, top: getTop(values?.length || data.length) } },
  data: values?.length
    ? values?.map(({ value, category }, index) => ({
        id: index + 1,
        color: colors[index],
        title: makeFirstLetterLow(convertStringToCamelCase(capitalizeEachWordInString(category))),
        value: value ? `${value}%` : replaceValueToNA(value),
        stake: Number(value),
        styles: { label: { width: '66px' }, value: {}, item: {} },
      }))
    : data,
});
