export default {
  GOOGLE_KEY: 'AIzaSyC4fR9UBJ4FTff_BvpGdrJIQ6ZD-8CxAfo',
  MAPBOXAPIACCESSTOKEN: 'pk.eyJ1IjoicGxhY2Vuc2UiLCJhIjoiY2p3aXhqM3NlMGJuNzRhbzVicjhwMG5mMSJ9.1azhIssKagg16HZ1yL2BUQ',
  defaultViewport: {
    longitude: 9,
    latitude: 50,
    zoom: 4,
    pitch: 0,
    bearing: 0,
  },
};
