import { API_URLS } from '../../routes';
import { request } from '../request';

export const removeProject = async (projectId: string) => {
  const url = `${API_URLS.PROJECT}/${projectId}`;
  return request({
    url,
    method: 'DELETE',
  });
};
