import { Position } from '@turf/helpers/dist/js/lib/geojson';

export const splitCoordinatesByPolygons = (coordinates: string[]) => {
  const splitPolygons = coordinates.map((value) => (value === '' ? 'SPLIT' : value));

  return splitPolygons
    .join(' ')
    .split('SPLIT')
    .map((value) => value.trim());
};

export const validateCoordinates = (data: string[]) => {
  const coordinates = splitCoordinatesByPolygons(data);
  const polygons = coordinates.map((coordinate) => splitCoordinatesArrayToPairs(coordinate.split(',')));
  const areAllPolygonsValid = polygons.map((coordinates) =>
    coordinates.every((pair) => {
      const hasBothCoordinates = pair.length;
      if (hasBothCoordinates) {
        const areBothCoordinatesNumbers = !Number.isNaN(pair[0]) && !Number.isNaN(pair[1]);
        return areBothCoordinatesNumbers;
      }
      return false;
    }),
  );

  return !areAllPolygonsValid.includes(false);
};

export const splitCoordinatesArrayToPairs = (coordinates: string[]) => {
  const result: number[][] = [];
  let pair: number[] = [];
  let current = 0;

  coordinates.forEach((coordinate) => {
    const value = Number(coordinate);
    if (current === 0) {
      pair.push(value);
      current += 1;
    } else if (current === 1) {
      result.push([...pair, value]);
      pair = [];
      current = 0;
    }
  });

  return result;
};

export const parseCoordinatesToPolygon = (coordinates: string[]) => {
  const result = splitCoordinatesArrayToPairs(coordinates);

  const polygon = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [result],
    },
  };

  return polygon as GeoJSON.Feature<GeoJSON.Polygon>;
};

export const transformCoordinatesToPolygons = (data: string[]) => {
  const coordinates = splitCoordinatesByPolygons(data);
  return coordinates.map((coordinate) => parseCoordinatesToPolygon(coordinate.split(',')));
};
