import React from 'react';
import styles from './style.module.scss';
import { Skeleton } from '../../../skeleton';

interface Props {
  id: string;
  img: string;
  name: string;
  isActive: boolean;
  onClick: (id: string) => void;
  isLoading: boolean;
}
export const Card = ({ img, name, isActive, onClick, id, isLoading }: Props) => (
  <div
    className={`${styles.card} ${isActive ? styles.active : ''} ${styles.partSize}`}
    onClick={() => onClick(id)}
    title={name}
  >
    {isLoading ? <Skeleton className={styles.image} /> : <img className={styles.image} src={img} alt={name} />}
    {isLoading ? <Skeleton className={styles.skeletonText} /> : <p className={styles.text}>{name}</p>}
  </div>
);
