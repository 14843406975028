import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EChartsOption } from 'echarts';
import { TEXT_STYLE } from '../../../common/chart/configuration';
import { Chart } from '../../../common/chart/chart';
import { setTooltipPosition } from '../../../common/chart/utils';
import { toFixNumber } from '../../../common/lib';
import { PieChartWrapperWithLeftLegend, PieItem } from '../../../common/chart';
import { Skeleton } from '../../../common/skeleton';
import styles from './style.module.scss';

interface Props {
  visitsFromAOIPercentage: number;
  isLoading: boolean;
}

function getOptions(data: any[]): EChartsOption {
  return {
    tooltip: {
      trigger: 'item',
      position(pos) {
        return setTooltipPosition(pos);
      },
    },
    legend: {
      top: 'center',
      orient: 'vertical',
      icon: 'circle',
      itemHeight: 10,
      itemWidth: 10,
      left: 95,
      textStyle: {
        ...TEXT_STYLE,
        fontWeight: 'normal',
        color: 'white',
      },
      formatter(name) {
        return `${name}`;
      },
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        width: 100,
        tooltip: {
          formatter({ name, value }: any) {
            return `<div>${name}: ${toFixNumber(value, 2)}%</div>`;
          },
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: data.map((item) => ({
          ...item,
          itemStyle: {
            color: item.name === 'Other visits' ? 'rgba(255, 255, 255, 0.6)' : '#00B2F1',
          },
        })),
      },
    ],
  };
}

const AttractionCardChart: React.FC<Props> = ({ visitsFromAOIPercentage, isLoading }) => {
  const { t } = useTranslation();

  const data: PieItem[] = [
    {
      name: t('absorption.card.visitsFromAoi'),
      value: visitsFromAOIPercentage,
    },
    {
      name: t('absorption.card.otherVisits'),
      value: 100 - visitsFromAOIPercentage,
    },
  ];
  const options = useMemo(() => getOptions(data), [data]);

  return (
    <div className="chart-container" style={{ height: '88px' }}>
      {isLoading ? (
        <Skeleton className={styles.skeleton} />
      ) : (
        <PieChartWrapperWithLeftLegend data={data}>
          <Chart option={{ ...options }} />
        </PieChartWrapperWithLeftLegend>
      )}
    </div>
  );
};

export default AttractionCardChart;
