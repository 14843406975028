import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContentContainer from '../../common/page/pageContent/contentContainer';
import { Form } from './form';
import { Button } from '../../common/controls/buttons';
import styles from './style.module.scss';
import { Credentials } from './interfaces';
import { changePassword } from '../../api/user';
import { SidebarContext } from '../../common/sidebarMenu/sidebarContext';
import { trackUserAction, UserActionsEnum } from '../../../mixpanel';

export const ChangePasswordPage = () => {
  const history = useHistory();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const submitRef = useRef<VoidFunction>(null);
  const { isOpened } = useContext(SidebarContext);

  const onCancel = () => {
    history.push('/userProfile');
  };

  const onButtonSubmit = async () => {
    const handler = submitRef.current;

    if (handler) {
      await handler();
    }
  };

  const onSubmit = async (data: Credentials) => {
    setSuccess('');
    setError('');
    try {
      await changePassword(data);
      setSuccess('Password has been changed successfully!');
      trackUserAction('Password successfully changed', UserActionsEnum.CHANGE_PASSWORD);
    } catch ({ response }) {
      if (response.data.reason) {
        setError(response.data.reason);
        trackUserAction(
          'Password change usuccessfull',
          UserActionsEnum.CHANGE_PASSWORD,
          response.data.reason as string,
        );
      }
    }
  };

  return (
    <ContentContainer isOpened={isOpened}>
      <div className={styles.wrapper}>
        <Form onSubmit={onSubmit} onCancel={onCancel} ref={submitRef} error={error} success={success} />
        <div className={styles.buttons}>
          <Button type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="button" layout="primary" onClick={onButtonSubmit}>
            Save password
          </Button>
        </div>
      </div>
    </ContentContainer>
  );
};
