import React, { useContext, useMemo } from 'react';
import { MainContext } from '../../../common/types/mainContext';
import CenterPin from '../../../common/maps/pin/centerPin';

interface Props {
  showName?: boolean;
  pointSize?: 'small' | 'big';
}

export const CenterPoint = ({ showName, pointSize = 'small' }: Props) => {
  const { selectedLocations, primaryLocationId } = useContext(MainContext);
  const primaryLocation = useMemo(
    () => selectedLocations.find((location) => location.id === primaryLocationId),
    [selectedLocations, primaryLocationId],
  );

  return (
    <>
      {primaryLocation && primaryLocation.longitude && primaryLocation.latitude && (
        <CenterPin
          key={primaryLocation.id}
          longitude={primaryLocation.longitude}
          latitude={primaryLocation.latitude}
          name={primaryLocation.name}
          showName={showName}
          pointSize={pointSize}
        />
      )}
    </>
  );
};
