import React from 'react';

interface Props {
  dataPopup?: boolean;
}
export const CameraIcon: React.FC<Props> = ({ dataPopup }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-popup={dataPopup}
    >
      <path
        d="M13.2 11.84C12.64 11.84 12.24 11.36 12.24 10.88C12.24 10.32 12.72 9.92 13.2 9.92C13.68 9.92 14.16 10.4 14.16 10.88C14.24 11.36 13.76 11.84 13.2 11.84ZM13.2 8C11.68 8 10.4 9.28 10.4 10.8C10.4 12.88 13.2 16 13.2 16C13.2 16 16 12.88 16 10.8C16 9.28 14.72 8 13.2 8ZM5.6 0L4.16 1.6H1.6C0.72 1.6 0 2.32 0 3.2V12.8C0 13.68 0.72 14.4 1.6 14.4H10C9.68 13.92 9.36 13.36 9.2 12.8H1.6V3.2H4.88L6.32 1.6H9.68L11.12 3.2H14.4V6.56C14.96 6.72 15.52 7.04 16 7.44V3.2C16 2.32 15.28 1.6 14.4 1.6H11.84L10.4 0H5.6ZM8 4C5.76 4 4 5.76 4 8C4 10.24 5.76 12 8 12C8.32 12 8.64 12 8.96 11.92C8.88 11.52 8.8 11.2 8.8 10.8V10.24C8.56 10.32 8.24 10.4 8 10.4C6.64 10.4 5.6 9.36 5.6 8C5.6 6.64 6.64 5.6 8 5.6C9.12 5.6 10.08 6.4 10.32 7.44C10.72 7.12 11.2 6.8 11.76 6.64C11.2 5.04 9.68 4 8 4Z"
        fill="white"
      />
    </svg>
  );
};
