import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../common/tooltip';
import { TooltipPositionCoordinates } from '../../../../../common/reportItems/hooks';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  position?: TooltipPositionCoordinates;
}

export const VisitTrendsMainTooltip: React.FC<Props> = ({ onClose, isOpen, position }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      onClose={onClose}
      title={t('reportItem.howItWorks')}
      isOpen={isOpen}
      initialPosition={position}
      height={360}
    >
      <>
        <p>{t('visitTrends.tooltip.paragraph1')}</p>
        <ol>
          <p>{t('visitTrends.tooltip.paragraph2')}</p>
          <li>{t('visitTrends.tooltip.point1')}</li> <li>{t('visitTrends.tooltip.point2')}</li>
        </ol>

        <p>{t('visitTrends.tooltip.paragraph3')}</p>
        <p>
          <b>{t('visitTrends.tooltip.example1Title')}</b>
          <br />
          <br />
          <i>{t('visitTrends.tooltip.example1Paragraph1')}</i>
          <br />
          <i>{t('visitTrends.tooltip.example1Paragraph2')}</i>
          <br />
          {t('visitTrends.tooltip.example1Paragraph3')}
        </p>
        <p>
          <b>{t('visitTrends.tooltip.example2Title')}</b>
          <br />
          <br />
          <i>
            {t('visitTrends.tooltip.example2Paragraph1')}
            <br />
            {t('visitTrends.tooltip.example2Paragraph2')}
          </i>
        </p>
      </>
    </Tooltip>
  );
};
