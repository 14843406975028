import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { FullFilledSwitcher } from '../../../../../common/switchers';

interface Props {
  data: { home: boolean; work: boolean };
  onChange: (type: 'work' | 'home') => () => void;
}
export const Switcher: React.FC<Props> = ({ data, onChange }) => {
  const { t } = useTranslation();
  const { home, work } = data;
  return (
    <div className={styles.rows}>
      <div className={styles.row}>
        <span className={styles.title}>{t('catchment.card.home')}</span>
        <span className={styles.option}>{home ? t('reportItem.yes') : t('reportItem.no')}</span>
        <FullFilledSwitcher value={home} onChange={onChange('home')} />
      </div>
      <div>
        <span className={styles.title}>{t('catchment.card.work')}</span>
        <span className={styles.option}>{work ? t('reportItem.yes') : t('reportItem.no')}</span>
        <FullFilledSwitcher value={work} onChange={onChange('work')} />
      </div>
    </div>
  );
};
