import React, { ChangeEvent, MouseEvent, MutableRefObject, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SingleProject } from './singleProject';
import { MainContext } from '../../../common/types/mainContext';
import { ProjectInfo } from '../../../common/types/projectsHierarchy';
import styles from '../panelContent.module.scss';
import CreateReport from '../../../common/icons/reports/createReport';
import { SortBy } from '../../../common/sortBy';
import { SortByEnum } from './enums';
import {
  replaceStringSpace,
  sortByCreatedDate,
  sortByName,
  sortByNumberOfLocations,
  sortByNumberOfReports,
} from '../../../common/utils';
import { SidebarContext } from '../../../common/sidebarMenu/sidebarContext';
import { Field, InputTypesEnum } from '../../../common/inputs';
import { CrossIcon } from '../../../common/icons/controls';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { useURLParams } from '../../../common/hooks';

export const ProjectsList = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    projectsHierarchy,
    selection,
    updateSelection,
    onSearchItems,
    search: { projects: search },
    sortBy: { projects: sortBy },
    onChangeSortBy,
  } = useContext(MainContext);
  const { onChangeMenuTabs } = useContext(SidebarContext);
  const { params, onChangeParams, isParsingDone } = useURLParams();

  useEffect(() => {
    if (isParsingDone) {
      if (params.projectsSortBy) {
        const reportsSortBy = replaceStringSpace(params.projectsSortBy, '+', ' ');
        onChangeSortBy('projects', reportsSortBy as SortByEnum);
      }
    }
  }, [isParsingDone]);

  const filteredProjects = useMemo(() => {
    if (search !== '') {
      return projectsHierarchy.filter(
        (i) =>
          i.name.toLowerCase().includes(search.toLowerCase()) ||
          i.description?.toLowerCase().includes(search.toLowerCase()),
      );
    }
    return projectsHierarchy;
  }, [projectsHierarchy, search]);

  const onSingleProjectClick = (
    event: MouseEvent<HTMLDivElement>,
    manageLocationsRef: MutableRefObject<HTMLAnchorElement | null>,
    project: ProjectInfo,
  ) => {
    if (event.target !== manageLocationsRef?.current) {
      const { search } = location;
      history.replace(`/projects/${project.id}${search}`);
      trackUserAction('Project opened', UserActionsEnum.OPEN_PROJECT, `Project ID: ${project.id}`);
      updateSelection({
        ...selection,
        selectedProject: { ...project, locations: sortByName(project.locations) },
      });
      onChangeMenuTabs({ projects: true, reports: false });
    }
  };

  const onSort = (option: string) => {
    onChangeSortBy('projects', option as SortByEnum.NAME);
    onChangeParams('projectsSortBy', option);
  };

  const sortedItems = useMemo(() => {
    switch (sortBy) {
      case SortByEnum.NAME:
        return sortByName(filteredProjects);
      case SortByEnum.NUMBER_OF_LOCATIONS:
        return sortByNumberOfLocations(filteredProjects);
      case SortByEnum.NUMBER_OF_REPORTS:
        return sortByNumberOfReports(filteredProjects);
      case SortByEnum.DATE_CREATED:
        return sortByCreatedDate(filteredProjects);
      default:
        return filteredProjects;
    }
  }, [filteredProjects, sortBy, search]);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    trackUserAction('Search field changed', UserActionsEnum.SEARCH_PROJECTS_REPORTS, value);
    onSearchItems('projects', value);
  };

  const onClearSearch = () => {
    trackUserAction('Search field cleared', UserActionsEnum.SEARCH_PROJECTS_REPORTS);
    onSearchItems('projects', '');
  };
  return (
    <div className={styles.itemsList}>
      <div className={styles.header}>
        <div className={styles.searchInput}>
          <Field
            id="test"
            name="test"
            onChange={onSearch}
            value={search}
            placeholder="Search projects"
            fieldType={InputTypesEnum.WITH_ICON}
            icon={search && <CrossIcon />}
            onIconClick={onClearSearch}
            iconSide="right"
          />
        </div>
        <SortBy options={Object.values(SortByEnum)} activeOption={sortBy} onChange={onSort} title="Sort projects" />
      </div>
      {sortedItems && sortedItems.length > 0 ? (
        <div className={`custom-scrollbar ${styles.scrollable}`} id="side-panel-scroll">
          {sortedItems.map((project) => (
            <SingleProject key={project.id} project={project} onClick={onSingleProjectClick} />
          ))}
        </div>
      ) : (
        <div>
          <div className={styles.emptyListTitle}>
            <div className={styles.emptyListContent}>No projects created yet..</div>
          </div>
          <div>
            {/* <div className={styles.emptyListContent}>Create a new project now</div> //TODO: Unmark when we'll support the create operation */}
            <div className={styles.addFirstItemImg}>
              <CreateReport />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
