import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './projectsPanel.module.scss';
import { MainContext } from '../../common/types/mainContext';
import { PointerIcon } from '../../common/icons/menuItems';
import { BackArrowIcon, FilledArrowIcon, ReportsListIcon } from '../../common/icons/controlPanel';
import { SelectionsEnum } from './enums';
import { MenuContext } from '../../common/sidebarMenu/menuContext';
import { maxLengthOfString, getDateString } from '../../common/utils';
import { ButtonTooltip } from '../../common/controls/tooltip/buttonTooltip';
import { PlusIcon } from '../../common/icons/sliders';
import { CircleButton } from '../../common/controls/buttons/circleButton/circleButton';
import { Routes } from '../../routes';
import { CreateProjectPopup } from '../../createReport/steps/controls/projects/createProjectPopup';

import { Skeleton } from '../../common/skeleton';
import { InformationIcon } from '../../common/icons/reportItems';
import { useTooltipStartPosition } from '../../common/reportItems/hooks';
import ReportTooltip from './tooltip/ReportTooltip';

interface Props {
  onGoBack: (value: string) => void;
  hasOpenedReports: boolean;
  hasOpenedProjects: boolean;
  title: string;
  isLoading: boolean;
}
export const PanelHeader = ({ onGoBack, hasOpenedReports, hasOpenedProjects, title, isLoading }: Props) => {
  const { closeMenu } = useContext(MenuContext);
  const { projectsHierarchy, setProjectsHierarchy } = useContext(MainContext);
  const userRole = localStorage.getItem('userRole');
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { tooltipIconRef, position } = useTooltipStartPosition();
  const { t } = useTranslation();

  const [isProjectPopupOpen, toggleProjectPopupOpen] = useState<boolean>(false);
  const [isReportTooltipOpen, toggleReportTooltipOpen] = useState<boolean>(false);

  const onPlusIconClick = () => {
    if (!hasOpenedProjects) {
      // create project
      toggleProjectPopupOpen(true);
    } else {
      // create report
      localStorage.setItem('prevUrl', `${pathname}${search}`);
      history.push(Routes.createReport);
    }
  };

  const onProjectSave = (id: string, name: string, description: string) => {
    if (id.length > 0 && name.length > 0) {
      const today = new Date();
      setProjectsHierarchy([
        ...projectsHierarchy,
        {
          id,
          name,
          description,
          locations: [],
          locations_count: 0,
          reports: [],
          picture_url: null,
          created_date: getDateString(today),
        },
      ]);
    }
  };

  return (
    <div className={styles.panel}>
      <ReportTooltip onClose={() => toggleReportTooltipOpen(false)} isOpen={isReportTooltipOpen} position={position} />
      <button type="button" onClick={closeMenu} className={styles.backButton}>
        <BackArrowIcon />
      </button>
      <div className={styles.item}>
        {hasOpenedProjects && (
          <>
            <ButtonTooltip title="My projects">
              <div className={styles.icon} onClick={() => onGoBack(SelectionsEnum.PROJECTS)}>
                <PointerIcon />
              </div>
            </ButtonTooltip>
            <div className={styles.arrow}>
              <FilledArrowIcon />
            </div>
          </>
        )}
        {!hasOpenedReports && (
          <h2 className={styles.information} title={title}>
            {maxLengthOfString(title, 20)}
          </h2>
        )}
      </div>

      {hasOpenedReports && (
        <div className={`${styles.item} ${styles.reportItem}`}>
          <div className={styles.reportItemContainer}>
            <ButtonTooltip title="My reports">
              <div className={styles.icon} onClick={() => onGoBack(SelectionsEnum.REPORTS)}>
                <ReportsListIcon />
              </div>
            </ButtonTooltip>
            <div className={styles.arrow}>
              <FilledArrowIcon />
            </div>
            <h2 className={styles.information} title={title}>
              {maxLengthOfString(title, 20)}
            </h2>
          </div>
          <span className={styles.tooltipIconContainer} ref={tooltipIconRef}>
            {isLoading ? (
              <Skeleton className={styles.skeletonIcon} />
            ) : (
              <span className={styles.tooltipIcon} onClick={toggleReportTooltipOpen} title={t('report.tooltip.title')}>
                <InformationIcon />
              </span>
            )}
          </span>
        </div>
      )}
      {(userRole === 'placenseUser' || userRole === 'accountBetaAdmin') && !hasOpenedReports && (
        <>
          <CircleButton
            className={styles.plusButton}
            icon={<PlusIcon />}
            title={hasOpenedProjects ? 'Create report' : 'Create project'}
            onClick={onPlusIconClick}
          />
          {!hasOpenedProjects && (
            <CreateProjectPopup
              isOpen={isProjectPopupOpen}
              toggleOpen={toggleProjectPopupOpen}
              onSave={onProjectSave}
            />
          )}
        </>
      )}
    </div>
  );
};
