import React, { useEffect, useState, useMemo } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import { useDispatch } from 'react-redux';
import { FullscreenIcon } from './fullscreen';
import Page from '../../templates/page/page';
import styles from './portalViewPage.module.css';
import Button from '../../atoms/primaryButton/button';
import { fetchUsers } from '../../../actions/actions';
import { getUserToken } from './services/services';
import ErrorMessage from '../../organisms/errorMessage';
import AccountFilter from '../../molecules/dropdowns/accountFilter/accountFilter';
import UsersFilter from '../../molecules/dropdowns/usersFilter/usersFilter';

const PortalViewPage = () => {
  const dispatch = useDispatch();

  const defaultSelectedAccounts: Option[] =
    localStorage.getItem('accountFilter') === null ? [] : JSON.parse(localStorage.getItem('accountFilter') || '');

  const handle = useFullScreenHandle();
  const [userToken, setUserToken] = useState<string | null>(null);
  const [selectedAccounts, setSelectedAccounts] = useState<Option[]>(defaultSelectedAccounts);
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const fetchUserToken = async () => {
    if (selectedUserId && selectedUserId !== '') {
      const token = await getUserToken(selectedUserId);
      setUserToken(token);
    } else {
      setUserToken(null);
    }
  };

  useEffect(() => {
    dispatch(fetchUsers(selectedAccounts.map((account) => account.value)));
    setSelectedUserId('');
  }, [JSON.stringify(selectedAccounts)]);

  const currentUrl = window.location.origin;
  const previewUrl = useMemo(() => {
    if (currentUrl.includes('portal.place')) return currentUrl.replace('portal', 'previewportal');
    if (currentUrl.includes('qa.place')) return currentUrl.replace('qa', 'previewqa');
    if (currentUrl.includes('dev.place')) return currentUrl.replace('dev', 'previewdev');
    return 'https://previewdev.placesense.ai';
  }, [currentUrl]);

  return (
    <Page title="Customer portal view">
      <ErrorMessage />
      <div className={styles.header}>
        <AccountFilter onChange={setSelectedAccounts} />
        <UsersFilter
          value={selectedUserId}
          onChange={setSelectedUserId}
          disabled={!selectedAccounts || selectedAccounts.length === 0}
        />
        <Button type="submit" onClick={fetchUserToken} disabled={!selectedUserId || selectedUserId === ''}>
          Log in as...
        </Button>
        {userToken && userToken !== '' && (
          <Button onClick={handle.enter} className={styles.fullscreenButton}>
            <FullscreenIcon />
          </Button>
        )}
      </div>
      {userToken && userToken !== '' ? (
        <FullScreen handle={handle} className={styles.fullscreen}>
          <iframe src={`${previewUrl}/loginAs/${userToken}`} width="100%" height="100%" title="customerPortalIframe" />
        </FullScreen>
      ) : (
        <iframe src={`${previewUrl}/loginAs/`} width="100%" height="0" title="customerPortalIframe" />
      )}
    </Page>
  );
};

export default PortalViewPage;
