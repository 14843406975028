import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { MovementPeriodEnum } from '../../../../common/reportItems/types';
import { Skeleton } from '../../../../common/skeleton';
import { Select } from '../../../../common/select';
import { SelectItem } from '../../../../common/types/SelectItem.interface';

interface Props {
  items: SelectItem[];
  activeItem: MovementPeriodEnum;
  onChange: (item: SelectItem) => void;
  isLoading?: boolean;
}
export const MovementPeriod: React.FC<Props> = ({ items, activeItem, onChange, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Skeleton className={styles.labelSkeleton} />
      ) : (
        <span className={styles.label}>{t('movement.card.movementTime')}:</span>
      )}
      {isLoading ? (
        <Skeleton className={styles.selectSkeleton} />
      ) : (
        <Select
          activeValue={activeItem}
          items={items}
          onChange={onChange}
          customActiveItemClassName={styles.activeItem}
          customSelectClassName={styles.select}
          customActiveSelectClassName={styles.activeSelect}
        />
      )}
    </div>
  );
};
