import React from 'react';
import styles from './multiUserFilter.module.scss';
import { MultiOption, MultiSelectWithButtons } from '../../../atoms/multiselectWithButtons';

type TProps = {
  onChange: (items: MultiOption[]) => void;
  options: MultiOption[];
  label: string;
};

const MultiUserFilter: React.FC<TProps> = ({ onChange, label, options }: TProps) => (
  <div className={styles.container}>
    <label htmlFor="users">{label}</label>
    <MultiSelectWithButtons options={options} onSave={onChange} />
  </div>
);

export default MultiUserFilter;
