import React from 'react';
import classNames from 'classnames';
import { viewBox } from './Sprite';

interface IconProps {
  iconId: keyof typeof viewBox;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ iconId, className }) => (
  <svg className={classNames(className, `${iconId.toString()}`)} viewBox={viewBox[iconId]}>
    <use href={`#${iconId}`} />
  </svg>
);

export default Icon;
