import React from 'react';
import { Layer, Source } from 'react-map-gl';
import * as GeoJSON from 'geojson';

interface PolygonImageProps {
  id: string;
  data: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection | null;
  image: string;
}

const PolygonImage: React.FC<PolygonImageProps> = ({ id, data, image }) =>
  data ? (
    <Source id={id} type="geojson" data={data}>
      <Layer
        id={`${id}_layer`}
        type="fill"
        paint={{
          'fill-opacity': 0.2,
          'fill-pattern': image,
        }}
      />
      <Layer
        id={`${id}_layer_outline`}
        type="line"
        paint={{
          'line-color': ['get', 'color'],
          'line-width': 3,
          'line-dasharray': [2, 1],
          'line-opacity': 0.4,
        }}
      />
    </Source>
  ) : null;

export default PolygonImage;
