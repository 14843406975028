import React, { useEffect, useRef, useState } from 'react';
import { CrossVisitationLocation } from '../../../../common/types/projectsHierarchy';
import styles from './style.module.scss';
import { toFixNumber } from '../../../../common/lib';
import { getColorByValue, getColorByRatio, parseVisitRatio } from './utils';
import { Legend } from './Legend';
import { Tooltip } from './Tooltip';
import { useWindowSize } from '../../../../common/hooks';
import { ChartValuesDropdown } from './chartValuesDropdown/chartValuesDropdown';
import { CrossVisitationChartValues } from '../../../../common/reportItems/types';

const CELL_WIDTH = 122;
const CELL_HEIGHT = 40;
interface Props {
  data: CrossVisitationLocation[];
  isScreenshotMode?: boolean;
  onChartReady?: VoidFunction;
  chartValues: CrossVisitationChartValues;
}
export const Chart: React.FC<Props> = ({ data, isScreenshotMode, onChartReady, chartValues }) => {
  const [hoveredTooltip, setHoveredTooltip] = useState({
    value: 'null',
    primaryLocation: '',
    secondaryLocation: '',
    distance: '',
    ratio: '',
    chartVal: '',
  });
  const [chartHeight, setChartHeight] = useState<number | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { height } = useWindowSize();

  const columns = data.map(({ primary_location_id, primary_location_name }) => ({
    id: primary_location_id,
    value: primary_location_name,
  }));

  useEffect(() => {
    if (height) {
      const SPACE = 397;
      const chartHeight = height - SPACE;
      const incomingHeight = data.length * CELL_HEIGHT;
      if (incomingHeight > chartHeight) {
        setChartHeight(chartHeight);
      } else {
        setChartHeight(null);
      }
      if (onChartReady) onChartReady();
    }
  }, [height, data]);

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { pageX, pageY } = event;
    const target = event.target as HTMLDivElement;
    const value = target.getAttribute('data-value') || '';
    const primaryLocation = target.getAttribute('data-primary') || '';
    const secondaryLocation = target.getAttribute('data-secondary') || '';
    const distance = target.getAttribute('data-distance') || '';
    const ratio = target.getAttribute('data-ratio') || '';
    const chartVal = target.getAttribute('data-chartValues') || '';
    const element = tooltipRef.current;

    if (element) {
      element.style.display = 'block';
      element.style.top = `${pageY - 10}px`;
      element.style.left = `${pageX + 20}px`;
      setHoveredTooltip({ value, primaryLocation, secondaryLocation, distance, ratio, chartVal });
    }
  };

  const onMouseOut = () => {
    const element = tooltipRef.current;

    if (element) {
      element.style.display = 'none';
    }
  };

  return (
    <div>
      <Tooltip ref={tooltipRef} data={hoveredTooltip} />
      <ChartValuesDropdown />
      <div className={styles.table}>
        <div
          className={`custom-scrollbar ${styles.content} ${isScreenshotMode ? styles.noScroll : styles.scroll}`}
          style={{ height: chartHeight ? `${chartHeight}px` : '' }}
        >
          <div className={styles.leftPanel}>
            {columns.map(({ id, value }) => (
              <div key={id} className={styles.item} title={value}>
                <span>{value}</span>
              </div>
            ))}
          </div>
          <div className={styles.main}>
            <div className={styles.header} style={{ width: CELL_WIDTH * columns.length }}>
              {columns.map(({ id, value }) => (
                <div key={id} className={styles.item} title={value}>
                  <span>{value}</span>
                </div>
              ))}
            </div>
            <div
              className={styles.data}
              style={{ width: CELL_WIDTH * columns.length }}
              onMouseMove={onMouseMove}
              onMouseOut={onMouseOut}
              onBlur={() => {}}
            >
              {data.map(({ data, primary_location_id, primary_location_name }, rowIndex) => (
                <div className={styles.row}>
                  {columns.map(({ id, value: secondaryLocationName }, columnIndex) => {
                    const isLastElement = data.length === rowIndex;
                    const item = data.find(({ secondary_location_id }) => secondary_location_id === id);
                    const isCurrentIntersection = primary_location_id === id;

                    const value = item?.visitors_loyalty ? toFixNumber(Number(item?.visitors_loyalty) * 100, 1) : 0;
                    const currentValue = isCurrentIntersection ? 100 : value;
                    const convertedValue = `${currentValue}%`;
                    const visitRatio = isCurrentIntersection ? 100 : (item?.ratio && parseVisitRatio(item?.ratio)) || 0;

                    const dataProps = {
                      'data-value': convertedValue,
                      'data-primary': primary_location_name,
                      'data-secondary': secondaryLocationName,
                      'data-distance': item?.aerial_distance,
                      'data-ratio': visitRatio,
                      'data-chartvalues': chartValues,
                    };
                    return (
                      <div
                        key={`crossvisitation-chart-${rowIndex}-${columnIndex}`}
                        className={`${styles.item} ${styles.light} ${styles.colorLabel} ${
                          !isLastElement ? styles.borderRight : ''
                        } ${isCurrentIntersection ? styles.grey : ''}`}
                        {...dataProps}
                      >
                        <div
                          className={styles.colorStripe}
                          style={{
                            background:
                              chartValues === CrossVisitationChartValues.VISIT_RATIO
                                ? getColorByRatio(isCurrentIntersection ? 101 : visitRatio)
                                : getColorByValue(isCurrentIntersection ? 101 : currentValue),
                          }}
                          {...dataProps}
                        />
                        {chartValues !== CrossVisitationChartValues.VISIT_RATIO && (
                          <span {...dataProps}>{convertedValue}</span>
                        )}
                        {chartValues !== CrossVisitationChartValues.CROSS_VISITATION && (
                          <span
                            style={
                              chartValues === CrossVisitationChartValues.BOTH
                                ? { color: getColorByRatio(isCurrentIntersection ? 101 : visitRatio) }
                                : {}
                            }
                            {...dataProps}
                          >
                            {visitRatio === 100 ? '1 : 1' : `100 : ${toFixNumber(visitRatio, 1)}`}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Legend chartValues={chartValues} />
    </div>
  );
};
