import { ReportItemType } from '../../../../common/reportItems/types';

export const getGroupCondition = (
  type?: ReportItemType,
  absorptionSourceLocationId?: string,
  attractionSourceLocationId?: string,
) => {
  let groupCondition: string | undefined;
  if (type === ReportItemType.ABSORPTION) {
    groupCondition = absorptionSourceLocationId;
  } else if (type === ReportItemType.ATTRACTION) {
    groupCondition = attractionSourceLocationId;
  }

  return groupCondition;
};
