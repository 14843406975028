import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ManageLocations from '../../common/manageLocations';
import { LocationsData } from '../../createReport/types';
import { MainContext } from '../../common/types/mainContext';

const ManageLocationsPage = () => {
  const { projectId } = useParams();
  const [locationsData, setLocationsData] = useState<LocationsData>({ locations: [] });
  const { projectsHierarchy } = useContext(MainContext);

  const projectName = useMemo(
    () => projectsHierarchy.find((project) => project.id === projectId)?.name || '',
    [projectId],
  );

  return (
    <ManageLocations
      projectId={projectId}
      projectName={projectName}
      data={locationsData}
      setData={setLocationsData}
      isEdit
    />
  );
};
export default ManageLocationsPage;
