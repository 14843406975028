import { useEffect, useRef, useState } from 'react';

const loopLength = 9999999999999;

const useTripAnimation = (animationSpeed: number, distances: number[] = [], useDistance: boolean) => {
  const [time, setTime] = useState<number[]>([]);
  const prevDistances = useRef<number[]>([]);

  useEffect(() => {
    let id: any;
    prevDistances.current = distances;
    const animate = () => {
      if (useDistance) {
        if (prevDistances.current.length) {
          setTime((t) => {
            return prevDistances.current.map((data, index) => {
              const res = (t[index] || 0) + data / animationSpeed;
              return Number.isFinite(res) ? res : 0;
            });
          });
        }
      } else {
        setTime((t) => {
          const value = ((t[0] || 0) + animationSpeed) % loopLength;
          return [value];
        });
      }
      id = window.requestAnimationFrame(animate);
    };

    id = window.requestAnimationFrame(animate);
    return () => window.cancelAnimationFrame(id);
  }, [distances]);

  return time;
};

export default useTripAnimation;
