import React from 'react';

interface Props {}
export const SearchIcon: React.FC<Props> = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2084 10.8334H11.4843L11.2276 10.5859C12.3276 9.30258 12.8959 7.55175 12.5843 5.69092C12.1534 3.14259 10.0268 1.10759 7.46008 0.795919C3.58258 0.319252 0.319252 3.58258 0.795919 7.46008C1.10759 10.0268 3.14259 12.1534 5.69092 12.5843C7.55175 12.8959 9.30258 12.3276 10.5859 11.2276L10.8334 11.4843V12.2084L14.7293 16.1043C15.1051 16.4801 15.7193 16.4801 16.0951 16.1043C16.4709 15.7284 16.4709 15.1143 16.0951 14.7384L12.2084 10.8334ZM6.70842 10.8334C4.42592 10.8334 2.58342 8.99092 2.58342 6.70842C2.58342 4.42592 4.42592 2.58342 6.70842 2.58342C8.99092 2.58342 10.8334 4.42592 10.8334 6.70842C10.8334 8.99092 8.99092 10.8334 6.70842 10.8334Z"
        fill="white"
      />
    </svg>
  );
};
