import React from 'react';
import { CrossIcon } from '../../../../icons/controls';
import styles from './style.module.scss';
import { Retail } from '../../../../../reportItems/catchment/detail/Chart/model';

interface Props {
  data: Retail[];
  title: string;
  onClose: VoidFunction;
}
export const DetailInformationPopup = ({ data, title, onClose }: Props) => (
  <div className={styles.popup}>
    <h4 className={styles.title}>
      {title}{' '}
      <span className={styles.icon} onClick={onClose}>
        <CrossIcon />
      </span>
    </h4>
    <div className={styles.list}>
      {data.map(({ category, value }) => (
        <div className={styles.item}>
          <p className={styles.category}>{category}</p>
          <span className={styles.value}>{value}</span>
        </div>
      ))}
    </div>
  </div>
);
