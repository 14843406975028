import JsPDF from 'jspdf';
import { TFunction } from 'react-i18next';
import { ExportData, ExportOptions } from '../../model/interfaces';
import { LocationInfo, ReportInfo } from '../../../../types/projectsHierarchy';
import { maxLengthOfString } from '../../../../utils';
import { toFixNumber } from '../../../../lib';
import {
  CatchmentExportInformation,
  ReportItemsInformation,
} from '../../model/interfaces/ReportItemsInformation.interface';
import { generateFileName } from './generateFileName';
import { generatePreviewPage, getLogoMark } from '../../utils';
import { getCompanyLogo } from '../../utils/getCompanyLogo';

const generateBasePage = async (
  instance: JsPDF,
  title: string,
  mainImage: string,
  reportItemName: string,
  informationTitle: string,
  companyLogo: string,
) => {
  const pageWidth = instance.internal.pageSize.getWidth();
  const pageHeight = instance.internal.pageSize.getHeight();

  const logo = getLogoMark();
  instance.addPage();
  instance.setFontSize(16).setTextColor('#000000').text(title, 7, 14);
  instance.setFontSize(10);
  instance.text(reportItemName, 288, 14, { align: 'right', maxWidth: 100 });
  const { height, width } = instance.getImageProperties(mainImage);
  const imageWidth = width * 0.26458333333719;
  const imageHeight = height * 0.26458333333719;

  const wc = imageWidth / pageWidth;

  instance.addImage(mainImage, 'JPEG', 0, 20, imageWidth / wc, imageHeight / wc);

  instance.setFontSize(11);
  instance.text(informationTitle, 9, 189);
  instance.setFontSize(7).text('2022 PlaceSense.ai @ All rights reserved', 245, 189);
  instance.addImage({ imageData: logo, format: 'JPEG', x: 277, y: 195, height: 15, width: 15 });
  const companyLogoData = await getCompanyLogo(companyLogo, 500, 500, 250);
  if (companyLogoData) {
    instance.addImage({
      imageData: companyLogoData,
      format: 'PNG',
      x: 267,
      y: 198,
      height: 8,
      width: 8,
    });
  }
  return instance;
};

const generateCatchmentPage = async (
  instance: JsPDF,
  mainImage: string,
  reportItemName: string,
  reportItemType: string,
  informationTitle: string,
  topAreaTitle: string,
  shareOfVisitsTitle: string,
  drivingTimeTitle: string,
  distanceFromLocationTitle: string,
  data: CatchmentExportInformation,
  companyLogo: string,
) => {
  const page = await generateBasePage(
    instance,
    reportItemType,
    mainImage,
    reportItemName,
    informationTitle,
    companyLogo,
  );
  if (data?.topArea) {
    page.setFont('', 'normal').setFontSize(8).text(`${topAreaTitle}:`, 9, 197);
    page
      .setFont('', 'bold')
      .setFontSize(9)
      .text(maxLengthOfString(data?.topArea, 20) || '', 37, 197);
  }

  page.setFont('', 'normal').setFontSize(8).text(`${shareOfVisitsTitle}:`, 9, 201);
  page
    .setFont('', 'bold')
    .setFontSize(9)
    .text(((data?.visitsShare || 0) * 100).toFixed(2), 37, 201);

  const missedFields = [
    { label: drivingTimeTitle, field: 'drivingTime', metric: 'min' },
    { label: distanceFromLocationTitle, field: 'aerialDistance', metric: 'km' },
  ];

  let prevY = 205;
  missedFields.forEach(({ field, label, metric }) => {
    const dataField = data[field as 'drivingTime' | 'aerialDistance'];
    if (dataField) {
      page.setFont('', 'normal').setFontSize(8).text(`${label}`, 9, prevY);
      page.setFont('', 'bold').setFontSize(9).text(`${dataField} ${metric}`, 37, prevY);
      prevY += 4;
    }
  });

  return page;
};

const generateSeasonalityPage = (
  instance: JsPDF,
  mainImage: string,
  reportItemName: string,
  reportItemType: string,
  informationTitle: string,
  popularDayTitle: string,
  popularHourTitle: string,
  popularDay: string,
  popularHour: string,
  companyLogo: string,
) => {
  const page = generateBasePage(instance, reportItemType, mainImage, reportItemName, informationTitle, companyLogo);
  instance.setFont('', 'normal').setFontSize(8).text(popularDayTitle, 9, 199);
  instance.setFont('', 'bold').setFontSize(9).text(popularDay, 37, 199);
  instance.setFont('', 'normal').setFontSize(8).text(popularHourTitle, 9, 203);
  instance.setFont('', 'bold').setFontSize(9).text(popularHour, 37, 203);
  return page;
};

const generateUniversalPage = (
  instance: JsPDF,
  mainImage: string,
  reportItemName: string,
  reportItemType: string,
  informationTitle: string,
  valueDescription: string,
  value: string | null,
  companyLogo: string,
) => {
  const page = generateBasePage(instance, reportItemType, mainImage, reportItemName, informationTitle, companyLogo);
  if (value) {
    instance.setFont('', 'normal').setFontSize(8).text(`${valueDescription}:`, 9, 197, { maxWidth: 35 });
    instance.setFont('', 'bold').setFontSize(9).text(value, 45, 199);
  }
  return page;
};

const generateMapPage = (instance: JsPDF, mainImage: string) => {
  const pageWidth = instance.internal.pageSize.getWidth();
  const pageHeight = instance.internal.pageSize.getHeight();

  instance.addPage();
  instance.addImage(mainImage, 'JPEG', 0, 0, pageWidth, pageHeight);

  return instance;
};

const generateBaseInstance = () => new JsPDF('landscape', 'mm', 'a4');

export const generatePDF = async (
  exportData: ExportData,
  exportOptions: ExportOptions,
  report: ReportInfo,
  primaryLocation: LocationInfo,
  timeFrame: string,
  reportItemsInformation: ReportItemsInformation,
  usePDFPreview: boolean,
  companyLogo: string,
  t: TFunction<'translation'>,
) => {
  const { FOOTFALL, SEASONALITY, DURATION, LOYALTY, ABSORPTION, ATTRACTION, MOVEMENT, CATCHMENT, RETENTION } =
    exportData;
  const { absorption, attraction, seasonality, catchment, duration, retention, crossVisitation } =
    reportItemsInformation;
  const { name } = report;
  const primaryLocationName = primaryLocation.name;
  let document = generateBaseInstance();

  if (usePDFPreview) document = await generatePreviewPage(document, name, companyLogo);

  if (FOOTFALL?.chart && exportOptions.FOOTFALL?.chart) {
    document = await generateUniversalPage(
      document,
      FOOTFALL?.chart,
      name,
      t('visitTrends.title'),
      t('visitTrends.detail.showingMonthlyEstimates'),
      t('visitTrends.card.timeframe'),
      timeFrame,
      companyLogo,
    );
  }

  if (FOOTFALL?.map && exportOptions.FOOTFALL?.map) {
    document = generateMapPage(document, FOOTFALL.map);
  }

  if (CATCHMENT?.chart && exportOptions.CATCHMENT?.chart) {
    if (catchment) {
      document = await generateCatchmentPage(
        document,
        CATCHMENT?.chart,
        name,
        t('catchment.title'),
        t('catchment.detail.showingShareOfVisitors'),
        t('catchment.card.topArea'),
        t('catchment.card.visitsShare'),
        t('catchment.card.drivingTime'),
        t('catchment.card.distanceFromLocation'),
        catchment,
        companyLogo,
      );
    }
  }

  if (CATCHMENT?.map && exportOptions.CATCHMENT?.map) {
    document = generateMapPage(document, CATCHMENT.map);
  }

  if (DURATION?.chart && exportOptions.DURATION?.chart) {
    const { averageTime } = duration;
    if (averageTime) {
      document = await generateUniversalPage(
        document,
        DURATION?.chart,
        name,
        t('duration.title'),
        t('duration.detail.showingAvgTime'),
        t('duration.card.avgTimeOnAllLocations'),
        `${toFixNumber(averageTime, 0)} ${t('reportItem.min')}`,
        companyLogo,
      );
    }
  }

  if (DURATION?.map && exportOptions.DURATION?.map) {
    document = generateMapPage(document, DURATION.map);
  }

  if (SEASONALITY?.chart && exportOptions.SEASONALITY?.chart) {
    const { popularDay, popularHour } = seasonality;
    if (popularDay && popularHour) {
      document = await generateSeasonalityPage(
        document,
        SEASONALITY?.chart,
        name,
        t('seasonality.title'),
        t('seasonality.detail.showingPopularDays'),
        t('seasonality.card.popularDay'),
        t('seasonality.card.popularHour'),
        popularDay,
        popularHour,
        companyLogo,
      );
    }
  }

  if (SEASONALITY?.map && exportOptions.SEASONALITY?.map) {
    document = generateMapPage(document, SEASONALITY.map);
  }

  if (RETENTION?.chart && exportOptions.RETENTION?.chart) {
    const { returningAverage } = retention;

    if (returningAverage) {
      document = await generateUniversalPage(
        document,
        RETENTION?.chart,
        name,
        t('retention.title'),
        t('retention.detail.showingTheShare'),
        t('retention.card.avgRateOfLocations'),
        `${toFixNumber(returningAverage * 100, 2)}%`,
        companyLogo,
      );
    }
  }

  if (RETENTION?.map && exportOptions.RETENTION?.map) {
    document = generateMapPage(document, RETENTION.map);
  }

  if (LOYALTY?.chart && exportOptions.LOYALTY?.chart) {
    const { singleLocationAverage } = crossVisitation;
    if (singleLocationAverage) {
      document = await generateUniversalPage(
        document,
        LOYALTY?.chart,
        name,
        t('crossVisitation.title'),
        t('crossVisitation.detail.shareOfCrossVisitation'),
        t('crossVisitation.card.avgShareOfCrossVisits'),
        `${toFixNumber(Number(singleLocationAverage), 1)}%`,
        companyLogo,
      );
    }
  }

  if (LOYALTY?.map && exportOptions.LOYALTY?.map) {
    document = generateMapPage(document, LOYALTY.map);
  }

  if (MOVEMENT?.chart && exportOptions.MOVEMENT?.chart) {
    document = await generateUniversalPage(
      document,
      MOVEMENT?.chart,
      name,
      t('movement.title'),
      `${t('movement.detail.showingTopLocations')} ${primaryLocationName}`,
      '',
      null,
      companyLogo,
    );
  }

  if (MOVEMENT?.map && exportOptions.MOVEMENT?.map) {
    document = generateMapPage(document, MOVEMENT.map);
  }

  if (ATTRACTION?.chart && exportOptions.ATTRACTION?.chart) {
    const { visitFrom } = attraction;

    if (visitFrom) {
      document = await generateUniversalPage(
        document,
        ATTRACTION?.chart,
        name,
        t('attraction.title'),
        t('absorption.detail.showingShareOfVisitors'),
        t('absorption.card.avgRateOfVisits'),
        `${toFixNumber(visitFrom, 1)}%`,
        companyLogo,
      );
    }
  }

  if (ATTRACTION?.map && exportOptions.ATTRACTION?.map) {
    document = generateMapPage(document, ATTRACTION.map);
  }

  if (ABSORPTION?.chart && exportOptions.ABSORPTION?.chart) {
    const { visitFrom } = absorption;

    if (visitFrom) {
      document = await generateUniversalPage(
        document,
        ABSORPTION?.chart,
        name,
        t('absorption.title'),
        t('absorption.detail.showingShareOfVisitors'),
        t('absorption.card.avgRateOfVisits'),
        `${toFixNumber(visitFrom, 1)}%`,
        companyLogo,
      );
    }
  }

  if (ABSORPTION?.map && exportOptions.ABSORPTION?.map) {
    document = generateMapPage(document, ABSORPTION.map);
  }

  if (!usePDFPreview) document.deletePage(1);

  const fileName = generateFileName(name, 'pdf');
  document.save(fileName);
};
