import { PeriodsEnum } from '../../common/reportItems/types';
import { API_URLS } from '../../routes';
import { request } from '../request';

export const getReportItems = async <T>(
  reportId: string,
  period?: PeriodsEnum | null,
  locations?: string[],
  timeFrames?: string[] | null,
): Promise<T> => {
  const timeFramesQueryParam = timeFrames ? `timeFrames=${timeFrames.join(',')}` : '';
  const periodQueryParam = period ? `period=${period}` : '';
  const query = [periodQueryParam, timeFramesQueryParam].filter((item) => item.length > 0).join('&');

  const url = locations?.length
    ? `${API_URLS.REPORT_ITEMS_DATA}/${reportId}/data?locations=${locations.join(',')}&${query}`
    : `${API_URLS.REPORT_ITEMS_DATA}/${reportId}/data?${query}`;

  return request({ url });
};
