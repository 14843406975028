import React from 'react';
import { MainContext } from '../../../common/types/mainContext';
import { TripsAnimation } from './TripsAnimation';
import Pin from '../../../common/maps/pin/pin';
import { Point } from '../../../common/types/visualizationObjects';
import { useTripsPoints } from './hooks';

const Trips: React.FC = () => {
  const points = useTripsPoints();

  return points ? (
    <>
      <TripsAnimation points={points} type="manyToOne" apiType={'driving'} animationSpeed={15} />
      {points &&
        points.map((point: Point) => (
          <Pin
            key={point.location_id}
            name={point.name}
            value={typeof point.value === 'number' ? `${(point.value * 100).toFixed(2)} %` : point.value}
            longitude={point.longitude}
            latitude={point.latitude}
            color="gray"
          />
        ))}
    </>
  ) : null;
};

export default Trips;
