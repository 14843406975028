import React, { useContext, useMemo } from 'react';
import { MainContext } from '../../../common/types/mainContext';
import { getGradientColor, getColorIndex } from '../../../common/maps/utils/getGradientUtil';
import Polygon from '../../../common/maps/polygon/polygon';
import {
  CatchmentMetricTypeEnum,
  CatchmentTypeEnum,
  ColorSchemeEnum,
  ReportItemType,
} from '../../../common/reportItems/types';
import { getCenterCoordinateOfGeoPolygon } from '../../../common/maps/utils/getCenterCoordinateOfGeoPolygon';
import { CatchmentArea, IndexArea } from '../../../common/types/visualizationObjects';
import { Catchment, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { getPostalCodeOpacity } from './utils';
import { ExportMapContext } from '../../../common/export/exportSettings/settings/mapContainer';

interface Props {
  ignoreShadow?: boolean;
  useCatchmentPostalCodes?: boolean;
  id?: string;
  ignoreOutline?: boolean;
  outlineColor?: string;
}
export const PostalCodePolygons = ({
  ignoreShadow,
  useCatchmentPostalCodes,
  id = '',
  ignoreOutline,
  outlineColor,
}: Props) => {
  const {
    selection: { selectedReport, selectedReportItem },
    primaryLocationId,
    reportItemsDetails: { catchmentType, catchmentTreshold, colorScheme, catchmentMetricType },
    mapOptions,
    exportSelection,
  } = useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const reportItem = isExportMap ? exportSelection.selectedReportItem : selectedReportItem;

  const indexPolygons = useMemo(() => {
    let locationPolygons: IndexArea | null = null;

    if (useCatchmentPostalCodes) {
      const reportItem = selectedReport?.report_items.find(
        ({ type }) => type === ReportItemType.CATCHMENT,
      ) as ReportItemInfo<Catchment>;
      if (reportItem) {
        const polygons = reportItem.visualization?.indexPolygons?.find(
          (item) => item.location_id === primaryLocationId,
        );

        if (polygons) {
          locationPolygons = polygons;
        }
      }
    } else {
      locationPolygons = primaryLocationId
        ? reportItem?.visualization?.indexPolygons?.find((item) => item.location_id === primaryLocationId) || null
        : null;
    }

    const fieldName =
      catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION
        ? 'destinationValue'
        : catchmentMetricType === CatchmentMetricTypeEnum.SCORE
        ? 'scoreValue'
        : 'sourceValue';

    if (catchmentType === CatchmentTypeEnum.HOME) {
      return locationPolygons?.home
        ? {
            ...locationPolygons.home,
            polygons: locationPolygons.home.polygons.filter(
              (item) =>
                (item[fieldName] || 0) >= catchmentTreshold.min && (item[fieldName] || 0) <= catchmentTreshold.max,
            ),
          }
        : null;
    }
    if (catchmentType === CatchmentTypeEnum.WORK) {
      return locationPolygons?.work
        ? {
            ...locationPolygons.work,
            polygons: locationPolygons.work.polygons.filter(
              (item) =>
                (item[fieldName] || 0) >= catchmentTreshold.min && (item[fieldName] || 0) <= catchmentTreshold.max,
            ),
          }
        : null;
    }
    return null;
  }, [primaryLocationId, catchmentType, catchmentTreshold, selectedReport, reportItem, catchmentMetricType]);

  const rank: number[] = useMemo(() => {
    if (indexPolygons) {
      if (catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION) {
        return [indexPolygons.destinationMinRank, indexPolygons.destinationMaxRank];
      }
      if (catchmentMetricType === CatchmentMetricTypeEnum.SCORE) {
        return [indexPolygons.scoreMinRank, indexPolygons.scoreMaxRank];
      }
      return [indexPolygons.sourceMinRank, indexPolygons.sourceMaxRank];
    }
    return [0, 100];
  }, [indexPolygons, catchmentMetricType]);

  const getRankByMetricType = (item: CatchmentArea) => {
    if (catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION) {
      return item.destinationRank;
    }
    if (catchmentMetricType === CatchmentMetricTypeEnum.SCORE) {
      return item.scoreRank;
    }
    return item.sourceRank;
  };

  const { zoom } = mapOptions.catchment;
  return (
    <>
      {indexPolygons && indexPolygons.polygons && (
        <Polygon
          id={`POI_POLYGONS${id}`}
          data={{
            type: 'FeatureCollection',
            features: indexPolygons.polygons.map((i) => ({
              ...i.geo,
              properties: {
                color: getGradientColor(
                  getColorIndex(getRankByMetricType(i) || rank[0], rank[0], rank[1]),
                  colorScheme === ColorSchemeEnum.MONOCHROME,
                ),
                popupTitle: i.name,
                centerPoint: getCenterCoordinateOfGeoPolygon(i.geo),
                popupData: i.popupData?.postalCodes,
              },
            })),
          }}
          ignoreShadow={ignoreShadow}
          ignoreOutline={ignoreOutline}
          borderLineWidth={1}
          outlineColor={outlineColor}
          opacity={getPostalCodeOpacity(zoom)}
        />
      )}
    </>
  );
};
