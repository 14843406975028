export const getDistancePixelsAccordingToCount = (count: number) => {
  switch (count) {
    case 1:
      return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    case 2:
      return [180, 220, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    case 3:
      return [161, 201, 241, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    case 4:
      return [140, 180, 220, 260, 0, 0, 0, 0, 0, 0, 0, 0];
    case 5:
      return [121, 161, 201, 241, 281, 0, 0, 0, 0, 0, 0, 0];
    case 6:
      return [100, 140, 180, 220, 260, 300, 0, 0, 0, 0, 0, 0];
    case 7:
      return [81, 121, 161, 201, 241, 281, 321, 0, 0, 0, 0, 0];
    case 8:
      return [60, 100, 140, 180, 220, 260, 300, 340, 0, 0, 0, 0];
    case 9:
      return [41, 81, 121, 161, 201, 241, 281, 321, 361, 0, 0, 0];
    case 10:
      return [20, 60, 100, 140, 180, 220, 260, 300, 340, 380, 0, 0];
    case 11:
      return [1, 41, 81, 121, 161, 201, 241, 281, 321, 361, 401, 0];
    case 12:
      return [-20, 20, 60, 100, 140, 180, 220, 260, 300, 340, 380, 420];
    default:
      return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  }
};
