import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EChartsOption } from 'echarts';
import Chart, { getTooltipHeight } from '../../../common/chart';
import {
  DurationCategory,
  DurationData,
  DurationLocation,
  LocationInfo,
} from '../../../common/types/projectsHierarchy';
import styles from './durationDetailChart.module.scss';
import { sortByValue } from '../../../common/utils';
import { ChartEvents } from '../../../common/chart/interfaces';
import { DefaultLegend } from '../../../common/chart/legends';
import { useDoubleClick } from '../../../common/chart/hooks';
import {
  getDurationCategoryByNumber,
  getDurationCategoryNumberByName,
} from '../../../common/utils/itemizeDurationCategoryObject';
import { ChartEventsEnum } from '../../../common/chart/enums';

interface Props {
  data: DurationLocation[];
  projectLocations: LocationInfo[];
  isScreenshotMode?: boolean;
  durationCategories: DurationCategory[];
  onReady: () => void;
}

function getDurationChartOptions(
  data: DurationLocation[],
  projectLocations: LocationInfo[],
  t: (val: string) => string,
  selectedLocations: Record<string, boolean>,
  durationCategories: DurationCategory[],
  isScreenshotMode?: boolean,
): EChartsOption {
  return {
    grid: {
      top: 20,
      left: 70,
      right: 35,
      bottom: 75,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      type: 'category',
      data: durationCategories,
      axisLabel: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
      },
      axisLine: { onZero: true },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.6,
          type: 'solid',
          width: 1,
          color: '#FFFFFF',
        },
      },
    },
    yAxis: {
      type: 'value',
      name: t('duration.detail.visitProportion'),
      nameLocation: 'middle',
      axisLabel: {
        formatter: (value: number) => `${value * 100}%`,
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
      },
      nameTextStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      splitLine: {
        lineStyle: {
          opacity: 0.1,
          type: 'solid',
          width: 1,
          color: '#FFFFFF',
        },
      },
      nameGap: 50,
    },
    legend: {
      selected: selectedLocations,
      show: !isScreenshotMode,
      align: 'auto',
      itemGap: 25,
      bottom: 0,
      icon: 'circle',
      type: 'scroll',
      textStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      pageTextStyle: {
        color: 'white',
      },
      pageIconColor: 'rgba(255, 255, 255, .25',
    },
    tooltip: {
      trigger: 'axis',
      triggerOn: 'click',
      appendToBody: true,
      formatter: (params: any) => {
        if (Array.isArray(params)) {
          const sortedData = sortByValue(params);
          const header = `
              <div class="${styles.header}">
                <span class="${styles.title}">${t('reportItem.visitsShare')} (%)</span><br />
                <span class="${styles.date}">${sortedData[0].name}</span>
              </div>`;
          let items = '';
          sortedData.forEach((item, index) => {
            items += `<div class="${styles.row}">
                  <span class="${styles.location}">${item.marker} ${item.seriesName}:</span>
                  <span class="${styles.value}">${(+item.value * 100).toFixed(2)}%</span>
                </div>`;
          });

          const maxItemsHeight = getTooltipHeight(params.length, 5);

          return `
            ${header}
            <div class="custom-scrollbar ${styles.items}" style="height:${maxItemsHeight}px;">
              ${items}
            </div> 
          `;
        }
        return '';
      },
      className: styles.durationTooltip,
      borderColor: 'rgba(255, 255, 255, 0.3)',
      backgroundColor: '##1F303D',
      axisPointer: {
        type: 'line',
        axis: 'x',
        lineStyle: {
          color: '#999',
        },
      },
    },
    series: data.map((item, i) => {
      const location = projectLocations.find((loc) => loc.id === item.location_id);
      return {
        id: item.location_id,
        name: item.location_name,
        type: 'bar',
        stack: `${i}`,
        color: location?.color || '#FFF',
        data: item.data.map((el) => el.value),
        itemStyle: {
          borderRadius: [2, 2, 0, 0],
        },
        label: {
          fontFamily: 'Open Sans, sans-serif',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          color: location?.color || '#FFF',
          show: true,
          position: 'top',
          formatter: (params) => `${(+params.value * 100).toFixed(0)}`,
        },
      };
    }),
  };
}

const DurationDetailChart: React.FC<Props> = ({
  data,
  projectLocations,
  isScreenshotMode,
  durationCategories,
  onReady,
}) => {
  const { chartRef, onChange, selectedLocations } = useDoubleClick();
  const { t } = useTranslation();
  const options = useMemo(
    () => getDurationChartOptions(data, projectLocations, t, selectedLocations, durationCategories, isScreenshotMode),
    [data, isScreenshotMode, selectedLocations],
  );

  const legendItems = useMemo(
    () =>
      data?.map(({ location_id, location_name }) => {
        const fullLocationInformation = projectLocations.find(({ id }) => id === location_id);
        return {
          id: location_id,
          label: location_name,
          color: fullLocationInformation ? fullLocationInformation?.color || '' : 'grey',
        };
      }),
    [data],
  );

  const chartEvents: ChartEvents = useMemo(
    () => ({
      [ChartEventsEnum.LEGEND_SELECT_CHANGED]: onChange,
      [ChartEventsEnum.FINISHED]: onReady,
    }),
    [data],
  );

  return (
    <div>
      <div style={{ height: '270px' }}>
        <Chart option={{ ...options }} events={chartEvents} ref={chartRef} />
      </div>
      {isScreenshotMode && (
        <div className={styles.legend}>
          <DefaultLegend data={legendItems} />
        </div>
      )}
    </div>
  );
};

export default DurationDetailChart;
