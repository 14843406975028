import React from 'react';
import { ContentVisualizationMode } from '../../../types/projectsHierarchy';
import { CircleButton } from '../../../controls/buttons/circleButton/circleButton';
import { BarIcon, MapIcon, VisualizationTableIcon } from '../../../icons/reportItems';
import styles from './visualizationModeToggle.module.scss';
import { ReportItemType } from '../../../reportItems/types';

interface Props {
  selectedValue: ContentVisualizationMode;
  onClick: (value: ContentVisualizationMode) => void;
  reportItemType?: ReportItemType;
  hideVisualizationMode: boolean;
}

export const VisualizationModeToggle = ({ selectedValue, onClick, reportItemType, hideVisualizationMode }: Props) => (
  <div className={styles.visualizationModeToggle}>
    <CircleButton
      icon={<MapIcon />}
      onClick={() => onClick(ContentVisualizationMode.MAP)}
      active={selectedValue === ContentVisualizationMode.MAP}
      title="Map view"
    />
    {!hideVisualizationMode && (
      <CircleButton
        icon={<BarIcon />}
        onClick={() => onClick(ContentVisualizationMode.VISUALIZATION)}
        active={selectedValue === ContentVisualizationMode.VISUALIZATION}
        title="Graph view"
      />
    )}
    {reportItemType !== ReportItemType.MOVEMENT && (
      <CircleButton
        icon={<VisualizationTableIcon />}
        onClick={() => onClick(ContentVisualizationMode.TABLE)}
        active={selectedValue === ContentVisualizationMode.TABLE}
        title="Table view"
      />
    )}
  </div>
);
