import { getReportItems } from '../../api';
import { CrossVisitationResponse } from './types';
import { calculateAvgBetweenSelectedLocations } from './lib';
import { CrossVisitation, ReportItemInfo, SelectedTimeFrames } from '../../common/types/projectsHierarchy';
import { PeriodsEnum } from '../../common/reportItems/types';

const convertData = ({ id, name, type, values }: CrossVisitationResponse): ReportItemInfo<CrossVisitation> => {
  const { singleLocationAverage, crossVisitationAverage } = calculateAvgBetweenSelectedLocations(values);

  return {
    id,
    name,
    type,
    data: {
      singleLocationAverage,
      crossVisitationAverage,
      data: values,
    },
    visualization: null,
  };
};

export const fetchCrossVisitationData = async (
  reportId: string,
  locationsIds: string[],
  selectedTimeFrames: string[] | null,
) => {
  try {
    const response = await getReportItems<CrossVisitationResponse>(
      reportId,
      PeriodsEnum.MONTHLY,
      locationsIds,
      selectedTimeFrames,
    );
    const data = {
      ...response,
      values: response.values
        .map((location) => ({
          ...location,
          data: location.data.filter(({ secondary_location_id }) => locationsIds.includes(secondary_location_id)),
        }))
        .filter(({ primary_location_id }) => locationsIds.includes(primary_location_id)),
    };
    return convertData(data);
  } catch (error) {}
};
