import React from 'react';
import styles from './style.module.scss';

interface Props {
  data: { label: string; color: string; id: string }[];
}
export const DefaultLegend: React.FC<Props> = ({ data }) => {
  return (
    <div className={styles.legend}>
      {data.map(({ label, id, color: background }) => {
        return (
          <div key={id} className={styles.item}>
            <span className={styles.color} style={{ background }} />
            <p>{label}</p>
          </div>
        );
      })}
    </div>
  );
};
