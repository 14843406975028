import moment, { Moment } from 'moment';
import { getYearsBetweenDates } from '../lib/getYearsBetweenDates';
import { months } from '../lib/data';

const isMonthDisabled = (startDate: Moment, endDate: Moment, yearMonth: string): boolean => {
  const date = moment(`${yearMonth}-01`);
  return date.isBefore(startDate, 'month') || date.isAfter(endDate, 'month');
};

export const getYearsGap = (startDate: string, endDate: string) => {
  const reportStartDate = moment(startDate);
  const reportEndDate = moment(endDate);
  const years = getYearsBetweenDates(reportStartDate, reportEndDate);
  const timeFrames = {};
  years
    .map((year) =>
      months
        .map(({ month }) => month)
        .filter((month) => !isMonthDisabled(reportStartDate, reportEndDate, `${year}-${month}`))
        .map((month) => `${year}-${month}`),
    )
    .reduce((previousValue, nextValue) => [...previousValue, ...nextValue], [])
    .map((timeFrame) => ({ [timeFrame]: true }))
    .forEach((timeFrame) => Object.assign(timeFrames, timeFrame));

  return { timeFrames, startDate: reportStartDate, endDate: reportEndDate };
};
