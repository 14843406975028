import React, { useContext, useMemo } from 'react';
import { getGroupCondition } from '../utils';
import { MainContext } from '../../../../common/types/mainContext';
import { AoiPolygons } from '../AoiPolygons';
import { ExportMapContext } from '../../../../common/export/exportSettings/settings/mapContainer';

export const useAoiPolygons = () => {
  const { selection, attractionSourceLocation, absorptionSourceLocation, selectedLocations, exportSelection } =
    useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const selectedReportItem = isExportMap ? exportSelection.selectedReportItem : selection.selectedReportItem;

  const polygons = useMemo(() => {
    const reportItem = selectedReportItem;
    const aoiPolygons = reportItem?.visualization?.aoiPolygons;

    const groupCondition = getGroupCondition(
      reportItem?.type,
      absorptionSourceLocation?.id,
      attractionSourceLocation?.id,
    );

    return aoiPolygons?.filter((polygon) => !groupCondition || polygon.group_id === groupCondition);
  }, [
    selectedReportItem?.visualization?.poiPolygons,
    attractionSourceLocation,
    absorptionSourceLocation,
    selectedLocations,
  ]);

  const renderPolygons = useMemo(() => <AoiPolygons polygons={polygons} />, [polygons]);

  return { polygons, renderPolygons };
};
