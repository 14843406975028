export type Country = {
  label: string;
  code: string;
  currency: string;
  language: string;
  translation: boolean;
};

export const countries: Country[] = [
  { label: 'Austria', code: 'at', currency: '€', language: 'Deutsch', translation: false },
  { label: 'England', code: 'en', currency: '£', language: 'English', translation: true },
  { label: 'Germany', code: 'de', currency: '€', language: 'Deutsch', translation: true },
  { label: 'Israel', code: 'il', currency: '₪', language: 'Hebrew', translation: true },
  { label: 'Italy', code: 'it', currency: '€', language: 'Italiano', translation: true },
  { label: 'Netherlands', code: 'nl', currency: '€', language: 'Netherlands', translation: true },
  { label: 'Switzerland', code: 'ch', currency: 'CHF', language: 'Deutsch', translation: false },
];

export const getCountryCurrency = (countryCode: string): string => {
  const country = countries.find(({ code }) => code === countryCode.toLowerCase());
  if (country) {
    return country.currency;
  }
  return '€';
};
