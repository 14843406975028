import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { BBox } from '@turf/helpers';
import { DeckGL } from 'deck.gl';
import { Effect, Layer } from '@deck.gl/core';
import { MapContext } from 'react-map-gl';
import { MapBaseViewport } from './mapBase';
import { useToggle } from '../../hooks';

export interface MapViewPort {
  longitude: number;
  latitude: number;
  zoom: number;
}

export interface DeckBaseProps {
  layers?: Layer<any>[];
  initialBbox?: BBox;
  effects: Effect[];
  panel?: ReactNode;
  onLayersLoad?: VoidFunction;
}

const DeckBase: React.FC<DeckBaseProps> = ({ layers, effects, onLayersLoad }) => {
  const viewport = useContext(MapBaseViewport);
  const [isLoaded, toggleIsLoaded] = useToggle(false);

  const onIsLoad = () => {
    const isLoaded = layers?.every(({ isLoaded }) => isLoaded);
    if (isLoaded) {
      toggleIsLoaded();
      if (onLayersLoad) onLayersLoad();
    }
  };

  return (
    <DeckGL
      layers={layers}
      effects={effects}
      viewState={viewport as MapViewPort}
      ContextProvider={MapContext.Provider}
      glOptions={{ preserveDrawingBuffer: true }}
      onAfterRender={!isLoaded ? onIsLoad : undefined}
    />
  );
};

export default DeckBase;
