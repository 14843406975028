import React, { useContext, useMemo } from 'react';
import { cellToBoundary } from 'h3-js';
import { MainContext } from '../../../common/types/mainContext';
import { getGradientColor, getColorIndex } from '../../../common/maps/utils/getGradientUtil';
import { getMinMaxValue } from '../../../reportItems/movement/utils';
import Polygon from '../../../common/maps/polygon/polygon';
import { ColorSchemeEnum } from '../../../common/reportItems/types';
import { Movement, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { getPostalCodeOpacity } from './utils';
import { ExportMapContext } from '../../../common/export/exportSettings/settings/mapContainer';
import { IReportCategory } from '../../../../types/report';

export const MovementHexagons = () => {
  const {
    selection: { selectedReport, selectedReportItem },
    reportItemsDetails: { colorScheme },
    mapOptions,
    exportSelection,
  } = useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const reportItem = (
    isExportMap ? exportSelection.selectedReportItem : selectedReportItem
  ) as ReportItemInfo<Movement>;

  const currentData = useMemo(() => reportItem?.data?.data[0].data, [reportItem?.data]);

  const minMax = useMemo(() => (currentData ? getMinMaxValue(currentData) : [0, 1]), [currentData]);

  const { zoom } = mapOptions.movement;
  return (
    <>
      {currentData && selectedReport?.category === IReportCategory.AOI_DEFAULT && (
        <Polygon
          id="AOI_HEXAGONS"
          data={{
            type: 'FeatureCollection',
            features: currentData
              .filter((i) => i.h3_index && i.h3_index.length > 0)
              .map((i) => {
                const hexagonJson = cellToBoundary(i.h3_index as string, true);
                return {
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: [hexagonJson],
                  },
                  properties: {
                    color: getGradientColor(
                      getColorIndex(i.visits_share || minMax[0], minMax[0], minMax[1]),
                      colorScheme === ColorSchemeEnum.MONOCHROME,
                    ),
                  },
                };
              }),
          }}
          ignoreShadow
          ignoreOutline
          opacity={getPostalCodeOpacity(zoom)}
        />
      )}
    </>
  );
};
