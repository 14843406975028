import React, { useContext, useEffect, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import styles from './reportItemsList.module.scss';
import DurationCard from '../../../reportItems/duration/card';
import { TrendsCard } from '../../../reportItems/trends/card';
import CatchmentCard from '../../../reportItems/catchment/card';
import LocationsCard from '../locationsFilter/locationsCard';
import SeasonalityCard from '../../../reportItems/seasonality/card';
import { CrossVisualizationCard } from '../../../reportItems/crossVisitation/card';
import YearMonthPicker from '../../../common/YearMonthPicker';
import { RetentionCard } from '../../../reportItems/retention/card';
import { MovementCard } from '../../../reportItems/movement/card';
import AbsorptionCard from '../../../reportItems/absorption/card';
import AttractionCard from '../../../reportItems/attraction/card';
import { dashboardModel } from '../../../dasboardLoader';
import { MainContext } from '../../../common/types/mainContext';
import { ExportSettings } from '../../../common/export';
import { useURLParams } from '../../../common/hooks';

// The order of report items in the ccc panel are:
//
// Visit trends (footfall)
// Catchment
// Popular day & hour (seasonality)
// Visit duration
// Retention rate
// Movement
// Cross-visitation (loyalty)
// Visit frequency
// Conversion (absorption/attraction)

export const ReportItemsList = () => {
  const {
    selection: { selectedReportItem },
  } = useContext(MainContext);
  const [isStart, toggleIsStart] = useState(true);
  const menuRef = useRef<null | HTMLDivElement>(null);
  const isGlobalLoading = useStore(dashboardModel.$isLoading);
  const reportItemsLoadStatuses = useStore(dashboardModel.$reportItemsLoadStatuses);
  const { isParsingDone, params } = useURLParams();

  useEffect(() => {
    const element = menuRef.current;

    if (element) {
      const handler = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scroll = target.scrollTop;
        if (scroll === 0) {
          toggleIsStart(true);
        } else {
          toggleIsStart(false);
        }
      };

      element.addEventListener('scroll', handler);

      return () => {
        element.removeEventListener('scroll', handler);
      };
    }
  }, []);

  useEffect(() => {
    const element = menuRef.current;

    if (isParsingDone) {
      if (element) {
        if (selectedReportItem) {
          const reportItemCard = document.getElementById(`report-item-card-${selectedReportItem.type}`);
          if (reportItemCard) {
            element.scrollTo({
              top: reportItemCard.offsetTop,
              behavior: 'smooth',
            });
          }
        }
      }
    }
  }, [isParsingDone, selectedReportItem]);

  useEffect(() => {
    if (selectedReportItem) {
      const isSelectedLoading = reportItemsLoadStatuses[selectedReportItem.type];

      dashboardModel.toggleDashboardLoading(!isSelectedLoading);
    }
  }, [reportItemsLoadStatuses, selectedReportItem]);

  return (
    <div className={`${styles.container}`}>
      <div className={styles.pickerAndExport}>
        <YearMonthPicker />
        <ExportSettings />
      </div>
      <LocationsCard showShadow={!isStart} />
      <div
        className={`${isGlobalLoading ? 'custom-transparent-scrollbar' : 'custom-scrollbar'} ${
          styles.scrollingWrapper
        }`}
        id="side-panel-scroll"
        ref={menuRef}
      >
        <TrendsCard />
        <CatchmentCard />
        <SeasonalityCard />
        <DurationCard />
        <RetentionCard />
        <MovementCard />
        <CrossVisualizationCard />
        <AbsorptionCard />
        <AttractionCard />
      </div>
    </div>
  );
};
