import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../tooltip';
import { CSVErrorType, CSVImportValidationErrorRow } from './validationErrorType';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  errors: CSVImportValidationErrorRow[] | null | undefined;
}

const CSVImportErrorMessage = ({ onClose, isOpen, errors }: Props) => {
  const { t } = useTranslation();
  return (
    <Tooltip onClose={onClose} title={t('edit.validation.CSVImport.title')} isOpen={isOpen} height={400}>
      {!errors && <p>{t('edit.validation.CSVImport.emptyFile')}</p>}
      {errors && (
        <ul>
          {errors.map((error) => (
            <li key={error.row}>
              <b>{error.id ? `Location with name "${error.id}" ` : `Location on row "${error.row}" `}</b>
              {error.type === CSVErrorType.MISSING_NAME && <span>has missing name</span>}
              {error.type === CSVErrorType.WRONG_NUMBER_OF_COLUMNS && <span>has missing one or more values</span>}
              {error.type === CSVErrorType.POLYGON_SIZE && <span>has exceeded polygon size</span>}
            </li>
          ))}
        </ul>
      )}
    </Tooltip>
  );
};

export default CSVImportErrorMessage;
