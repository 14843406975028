import { dateFormatter } from '../../../utils';
import { toFixNumber } from '../../../../../common/lib';

export const generateChartTooltip = (params: any, isAbsoluteNumbersDisabled: boolean, labels: string[]) => {
  const selectedDate = params.axisValue;
  let toolTipHtml = "<div class='location-tooltip'>";
  toolTipHtml += `<div>${labels[0]}: ${dateFormatter(selectedDate)}</>`;
  toolTipHtml += `<div>${labels[1]}: ${toFixNumber(
    isAbsoluteNumbersDisabled ? params?.value * 100 : params.value,
    0,
  ).toLocaleString()}${isAbsoluteNumbersDisabled ? '%' : ''}</div>`;
  toolTipHtml += '</div>';
  return toolTipHtml;
};
