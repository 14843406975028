import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { EChartsOption } from 'echarts';
import EChart from '../../../../common/chart';
import { PeriodsEnum } from '../../../../common/reportItems/types';
import styles from './style.module.scss';
import { dateFormatter } from '../../utils';
import { generateTooltip } from './tooltip';
import { LocationInfo, RetentionLocation } from '../../../../common/types/projectsHierarchy';
import { sortByValue } from '../../../../common/utils';
import { useGraphHeight } from '../../../../common/reportItems/hooks';
import { ChartEvents } from '../../../../common/chart/interfaces';
import { DefaultLegend } from '../../../../common/chart/legends';
import { useDoubleClick } from '../../../../common/chart/hooks';
import {toFixNumber} from "../../../../common/lib";

function getChartOptions(
  data: RetentionLocation[],
  period: PeriodsEnum,
  projectLocations: LocationInfo[],
  t: (val: string) => string,
  selectedLocations: Record<string, boolean>,
  isScreenshotMode?: boolean,
): EChartsOption {
  return {
    grid: {
      top: 20,
      left: 70,
      right: 0,
      bottom: 100,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      type: 'category',
      data: data[0] ? data[0].data.map((el) => el.date) : [],
      axisLabel: {
        formatter: (value: number) => dateFormatter(period, String(value)),
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        rotate: 90,
        opacity: 0.6,
        margin: 15,
      },
      axisLine: { onZero: true },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.1,
          type: 'dashed',
          width: 1,
          color: '#ededed',
        },
      },
    },
    yAxis: {
      type: 'value',
      name: t('retention.detail.percentOfReturning'),
      nameLocation: 'middle',
      axisLabel: {
        formatter: (value: number) => `${toFixNumber(value * 100, 2)}%`,
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
      },
      nameTextStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.07)',
        },
      },
      nameGap: 50,
    },
    legend: {
      selected: selectedLocations,
      show: !isScreenshotMode,
      align: 'auto',
      itemGap: 25,
      bottom: 0,
      icon: 'circle',
      type: 'scroll',
      textStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      pageTextStyle: {
        color: 'white',
      },
      pageIconColor: 'rgba(255, 255, 255, .25',
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      shadowColor: 'transparent',
      className: styles.tooltip,
      formatter: (params: any) => {
        if (Array.isArray(params)) {
          const sortedData = sortByValue(params);
          return generateTooltip(sortedData, t('retention.detail.percentOfReturning'), period);
        }
        return '';
      },
      axisPointer: {
        type: 'line',
        axis: 'x',
        lineStyle: {
          color: '#999',
        },
      },
    },
    series: data.map((options, i) => ({
      type: 'line',
      smooth: true,
      name: options.location_name,
      color:
        options.location_id !== 'average'
          ? projectLocations.find((loc) => loc.id === options.location_id)?.color
          : '#FFF',
      lineStyle:
        options.location_id === 'average'
          ? {
              type: 'dotted',
              width: 4,
            }
          : undefined,
      data: options.data.map((el, index) => (el.value === 0 && index + 1 === options.data.length ? NaN : el.value)),
    })),
  };
}

interface RetentionProps {
  data: RetentionLocation[];
  period: PeriodsEnum;
  projectLocations: LocationInfo[];
  events: ChartEvents;
  isScreenshotMode?: boolean;
  useFixedScreenshotSize?: boolean;
}

export const RetentionChart = ({
  data,
  period,
  projectLocations,
  events,
  isScreenshotMode,
  useFixedScreenshotSize,
}: RetentionProps) => {
  const { t } = useTranslation();
  const { chartRef, onChange, selectedLocations } = useDoubleClick();
  const options = useMemo(
    () => getChartOptions(data, period, projectLocations, t, selectedLocations, isScreenshotMode),
    [data, period, isScreenshotMode, selectedLocations, t],
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { graphHeight } = useGraphHeight(wrapperRef, PeriodsEnum.MONTHLY, projectLocations.length);

  const legendItems = useMemo(
    () =>
      data?.map(({ location_id, location_name }) => {
        const fullLocationInformation = projectLocations.find(({ id }) => id === location_id);
        return {
          id: location_id,
          label: location_name,
          color: fullLocationInformation ? fullLocationInformation?.color || '' : 'grey',
        };
      }),
    [data],
  );

  return (
    <div>
      <div
        className="chart-container"
        style={{ height: useFixedScreenshotSize ? '300px' : graphHeight }}
        ref={wrapperRef}
      >
        <EChart option={options} events={{ legendselectchanged: onChange, ...events }} ref={chartRef} />
      </div>
      {isScreenshotMode && (
        <div className={styles.legend}>
          <DefaultLegend data={legendItems} />
        </div>
      )}
    </div>
  );
};
