import { useContext, useMemo } from 'react';
import { CatchmentTypeEnum } from '../../../../common/reportItems/types';
import { MainContext } from '../../../../common/types/mainContext';
import { ExportMapContext } from '../../../../common/export/exportSettings/settings/mapContainer';

const DISPLAY_MAX = 10;

export const useTripsPoints = () => {
  const { selection, primaryLocationId, reportItemsDetails, exportSelection } = useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);
  const reportItem = isExportMap ? exportSelection.selectedReportItem : selection.selectedReportItem;

  const data = useMemo(() => {
    const locationPolygons = primaryLocationId
      ? reportItem?.visualization?.heatmap?.find((item) => item.location_id === primaryLocationId) || null
      : null;
    if (reportItemsDetails.catchmentType === CatchmentTypeEnum.HOME) {
      return locationPolygons?.home || null;
    }
    if (reportItemsDetails.catchmentType === CatchmentTypeEnum.WORK) {
      return locationPolygons?.work || null;
    }
    return null;
  }, [primaryLocationId, reportItemsDetails.catchmentType, reportItem?.visualization?.heatmap]);

  const topPoints = useMemo(() => data?.points?.slice(0, DISPLAY_MAX), [data]);

  return topPoints || [];
};
