import React from 'react';
import styles from './style.module.scss';

interface Props {
  id: string;
  img?: string;
  name: string;
  isActive: boolean;
  onClick: (id: string) => void;
}
export const Card = ({ img, name, isActive, onClick, id }: Props) => (
  <div className={`${styles.card} ${isActive ? styles.active : ''}`} onClick={() => onClick(id)}>
    <img src={img} alt={name} />
    <p className={styles.text}>{name}</p>
  </div>
);
