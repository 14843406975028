import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styles from './userForm.module.css';
import Button from '../../atoms/primaryButton/button';
import { AccountStatus } from '../../../types/AccountStatus';
import { AddUser } from '../../../types/AddUser';
import { userRoleTranslationMap, UserRole } from '../../../types/UserRole';
import ErrorMessage from '../../organisms/errorMessage';
import { addUser, fetchAccounts, fetchFfiPlans, fetchUserRoles } from '../../../actions/actions';
import routes from '../../../router/routes';
import { AppState } from '../../../reducers/reducers';
import { ProjectsState } from '../../../reducers/projectsReducer';
import { AuthState } from '../../../reducers/authReducer';
import FfiPlans from '../../molecules/dropdowns/ffiPlans/FfiPlans';

const UserForm = (): ReactElement => {
  const [state, setState] = useState<AddUser>({
    phone: '',
    title: '',
    userId: '',
    accountId: '',
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    role: UserRole.ACCOUNT_ADMIN,
    ffiPlans: {},
    status: '',
    notifyUser: false,
  });
  const [ffi, setFfi] = useState({
    NL: '',
    DE: '',
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { ffiPlans } = useSelector<AppState, ProjectsState>((state) => state.projects);
  const { accounts, userRoles } = useSelector<AppState, AuthState>((state) => state.auth);

  const message = useSelector<AppState, string | null>((state) => state.errorMessage.message);

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchFfiPlans());
    dispatch(fetchUserRoles());
  }, []);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ffiPlans: {},
    }));
  }, [JSON.stringify(ffiPlans)]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      accountId: accounts[0] ? accounts[0].id : prevState.accountId,
    }));
  }, [accounts]);

  const updateInputFieldHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateAccountFieldHandler = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    setState((prevState) => ({
      ...prevState,
      accountId: value,
    }));
  };

  const updateRoleFieldHandler = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    setState((prevState) => ({
      ...prevState,
      role: value,
    }));
  };

  const updateFfiPlansHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    setState((prevState) => ({
      ...prevState,
      ffiPlans: {
        ...state.ffiPlans,
        [value]: true,
      },
    }));
    setFfi((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateNotifyUser = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => ({
      ...prevState,
      notifyUser: !prevState.notifyUser,
    }));
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    dispatch(addUser(state, () => history.replace(routes.users.list)));
  };

  return (
    <form className={styles.form} onSubmit={submitHandler}>
      <div className={styles.formTopWithError}>
        <div className={styles.formTop}>
          <div>
            <label htmlFor="account" className={styles.formRow}>
              Account
              <select name="account" id="account" onChange={updateAccountFieldHandler}>
                <option key="empty" value="">
                  Choose an account
                </option>
                {accounts
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .filter(({ status }) => status === AccountStatus.ACTIVE)
                  .map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </label>
          </div>
          <div>
            <label htmlFor="firstName" className={styles.formRow}>
              First name
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={state.firstName}
                onChange={updateInputFieldHandler}
              />
            </label>
          </div>
          <div>
            <label htmlFor="lastName" className={styles.formRow}>
              Last name
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={state.lastName}
                onChange={updateInputFieldHandler}
              />
            </label>
          </div>
          <div className={styles.inputWithError}>
            <label htmlFor="email" className={styles.formRow}>
              Email
              <input
                type="text"
                id="email"
                name="email"
                value={state.email}
                onChange={updateInputFieldHandler}
                autoComplete="off"
              />
            </label>
          </div>
          <div>
            <label htmlFor="phone" className={styles.formRow}>
              Phone
              <input type="text" id="phone" name="phone" value={state.phone} onChange={updateInputFieldHandler} />
            </label>
          </div>
          <div>
            <label htmlFor="title" className={styles.formRow}>
              Title
              <input type="text" id="title" name="title" value={state.title} onChange={updateInputFieldHandler} />
            </label>
          </div>
          <div>
            <label htmlFor="role" className={styles.formRow}>
              Role
              <select name="role" id="role" value={state.role} onChange={updateRoleFieldHandler}>
                {userRoles.map((role) => (
                  <option key={role} value={role}>
                    {userRoleTranslationMap.get(role)}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <label htmlFor="password" className={styles.formRow}>
              Password
              <input
                type="password"
                id="password"
                name="password"
                value={state.password}
                onChange={updateInputFieldHandler}
                autoComplete="off"
              />
            </label>
          </div>
        </div>
        <div className={styles.emailError}>{message?.includes('email') && <ErrorMessage />}</div>
        <div className={styles.passwordError}>{message?.includes('password') && <ErrorMessage />}</div>
      </div>
      {ffiPlans && ffiPlans.length > 0 && (
        <>
          <div className={`${styles.formRow} ${styles.ffiHeading}`}>
            <h3>
              <u>FFI plans:</u>
            </h3>
          </div>
          <ul>
            <li>
              <FfiPlans
                label="FFI for Netherlands"
                name="NL"
                value={ffi.NL}
                handler={updateFfiPlansHandler}
                includes="FFI_NL"
                ffiPlans={state.ffiPlans}
              />
            </li>
            <li>
              <FfiPlans
                label="FFI for Germany"
                name="DE"
                value={ffi.DE}
                handler={updateFfiPlansHandler}
                includes="FFI_DE"
                ffiPlans={state.ffiPlans}
              />
            </li>
          </ul>
        </>
      )}
      <div className={styles.formTop}>
        <hr />
        <div className={styles.formRow}>
          <label htmlFor="notifyUser">
            <input type="checkbox" name="notifyUser" checked={state.notifyUser} onChange={updateNotifyUser} />
            Notify user
          </label>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
};

export default UserForm;
