import React, { useContext } from 'react';
import { useStore } from 'effector-react';
import ContentContainer from '../common/page/pageContent/contentContainer';
import { DurationDetail } from './duration/detail/DurationDetail';
import ReportItemContainer from '../common/reportItems/ReportItemContainer';
import { MainContext } from '../common/types/mainContext';
import { ReportItemType } from '../common/reportItems/types';

import { ReportItemInfo, ReportItems } from '../common/types/projectsHierarchy';
import { CatchmentDetail } from './catchment/detail/CatchmentDetail';
import { SeasonalityDetail } from './seasonality/detail/SeasonalityDetail';
import { TrendsDetail } from './trends/detail';
import { CrossVisitationDetails } from './crossVisitation/detail';
import { RetentionDetail } from './retention/detail';
import { AbsorptionDetail } from './absorption/detail/AbsorptionDetail';
import { AttractionDetail } from './attraction/detail/AttractionDetail';
import { SidebarContext } from '../common/sidebarMenu/sidebarContext';
import { MovementDetail } from './movement/detail';
import { reportItemDetailsModel } from './model';
import { CircleLoader } from '../common/loaders';
import { dashboardModel } from '../dasboardLoader';

const ReportItem = () => {
  const {
    selection: { selectedReportItem },
  } = useContext(MainContext);
  const { isOpened } = useContext(SidebarContext);
  const isLoading = useStore(reportItemDetailsModel.$isLoading);
  const isGlobalLoading = useStore(dashboardModel.$isLoading);

  const renderDetails = (selectedReportItem: ReportItemInfo<ReportItems>) => {
    switch (selectedReportItem.type) {
      case ReportItemType.CATCHMENT:
        return <CatchmentDetail />;
      case ReportItemType.DURATION:
        return <DurationDetail />;
      case ReportItemType.MOVEMENT:
        return <MovementDetail />;
      case ReportItemType.RETENTION:
        return <RetentionDetail />;
      case ReportItemType.FOOTFALL:
        return <TrendsDetail />;
      case ReportItemType.SEASONALITY:
        return <SeasonalityDetail />;
      case ReportItemType.CROSS_VISITATION:
        return <CrossVisitationDetails />;
      case ReportItemType.ABSORPTION:
        return <AbsorptionDetail />;
      case ReportItemType.ATTRACTION:
        return <AttractionDetail />;
      default:
        return null;
    }
  };

  return (
    <ContentContainer isOpened={isOpened}>
      <>
        {(isGlobalLoading ? isGlobalLoading : isLoading) && <CircleLoader withBackground />}
        <ReportItemContainer>{selectedReportItem && renderDetails(selectedReportItem)}</ReportItemContainer>
      </>
    </ContentContainer>
  );
};

export default ReportItem;
