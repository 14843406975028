import React from 'react';

export const ClosedEyeIcon = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 3.50002C11.8425 3.50002 14.3775 5.09753 15.615 7.62503C15.1725 8.54003 14.55 9.32753 13.8075 9.96502L14.865 11.0225C15.9075 10.1 16.7325 8.94503 17.25 7.62503C15.9525 4.33253 12.75 2.00002 9 2.00002C8.0475 2.00002 7.1325 2.15002 6.27 2.42752L7.5075 3.66502C7.995 3.56752 8.49 3.50002 9 3.50002ZM8.1975 4.35502L9.75 5.90753C10.1775 6.09503 10.5225 6.44002 10.71 6.86753L12.2625 8.42003C12.3225 8.16503 12.3675 7.89502 12.3675 7.61753C12.375 5.75752 10.86 4.25002 9 4.25002C8.7225 4.25002 8.46 4.28752 8.1975 4.35502ZM1.5075 1.90252L3.5175 3.91252C2.295 4.87253 1.3275 6.14752 0.75 7.62503C2.0475 10.9175 5.25 13.25 9 13.25C10.14 13.25 11.235 13.0325 12.24 12.635L14.805 15.2L15.8625 14.1425L2.565 0.837524L1.5075 1.90252ZM7.1325 7.52752L9.09 9.48502C9.06 9.49253 9.03 9.50002 9 9.50002C7.965 9.50002 7.125 8.66003 7.125 7.62503C7.125 7.58752 7.1325 7.56502 7.1325 7.52752ZM4.5825 4.97752L5.895 6.29003C5.7225 6.70253 5.625 7.15252 5.625 7.62503C5.625 9.48503 7.14 11 9 11C9.4725 11 9.9225 10.9025 10.3275 10.73L11.0625 11.465C10.4025 11.645 9.7125 11.75 9 11.75C6.1575 11.75 3.6225 10.1525 2.385 7.62503C2.91 6.55253 3.675 5.66752 4.5825 4.97752Z"
      fill="white"
    />
  </svg>
);
