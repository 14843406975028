import React, { useContext, useState, useEffect, useMemo, ChangeEvent } from 'react';
import styles from '../locations.module.scss';
import { MainContext } from '../../../../common/types/mainContext';
import LocationRow from './locationRow';
import { Button } from '../../../../common/controls/buttons';
import { maxLengthOfString } from '../../../../common/utils/stringUtils';
import { addImageUrlToLocations } from '../../../../common/locations/utils';
import { trackUserAction, UserActionsEnum } from '../../../../../mixpanel';
import { useURLParams } from '../../../../common/hooks';
import { Checkbox } from '../../../../common/controls/checkbox';
import { Field, InputTypesEnum } from '../../../../common/inputs';
import { CrossIcon } from '../../../../common/icons/controls';

type SimpleLocationItem = {
  id: string;
  name: string;
  selected: boolean;
};

type TProps = {
  onClose: () => void;
};

const LocationsList = ({ onClose }: TProps) => {
  const { selection, selectedLocations, setSelectedLocations, primaryLocationId, setPrimaryLocationId } =
    useContext(MainContext);
  const { selectedProject, selectedReport } = selection;
  const [preselected, setPreselected] = useState<SimpleLocationItem[]>([]);
  const { onChangeParams } = useURLParams();
  const [searchString, setSearchString] = useState<string>('');

  const reportLocations = useMemo(
    () =>
      selectedProject?.locations.filter((location) => selectedReport?.location_ids?.some((id) => id === location.id)),
    [],
  );

  const onPreselectionChange = (id: string) => {
    const newPreselected = preselected.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setPreselected(newPreselected);
  };

  const onSelectAllClick = () => {
    if (reportLocations?.length === preselected.filter((item) => item.selected).length) {
      const newPreselected = preselected.map((item) => ({ ...item, selected: false }));
      setPreselected(newPreselected);
    } else {
      const newPreselected = preselected.map((item) => ({ ...item, selected: true }));
      setPreselected(newPreselected);
    }
  };

  const onSave = () => {
    const newSelected =
      reportLocations?.filter((reportLocation) =>
        preselected.some((item) => item.selected && item.id === reportLocation.id),
      ) || [];

    const selectedLocationsIds = newSelected.map(({ id }) => id).join(' ');
    const locationWithImage = addImageUrlToLocations(newSelected);
    setSelectedLocations(locationWithImage);
    onChangeParams('selectedLocations', selectedLocationsIds);

    if (!newSelected.some((location) => location.id === primaryLocationId)) {
      const locationId = newSelected[0].id;
      setPrimaryLocationId(locationId);
      trackUserAction(
        'Primary location has been changed',
        UserActionsEnum.PRIMARY_LOCATION_CHANGE,
        `Default primary location set after changing the list of selected locations. Location ID: ${locationId}`,
      );
      onChangeParams('primaryLocation', locationId);
    }
    onClose();
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    trackUserAction('Location search field changed', UserActionsEnum.SEARCH_PROJECTS_REPORTS, value);
    setSearchString(value);
  };

  useEffect(() => {
    const selectionData: SimpleLocationItem[] =
      reportLocations?.map((location) => ({
        id: location.id,
        name: location.name,
        selected: selectedLocations.some((selectedLocation) => selectedLocation.id === location.id),
      })) || [];
    setPreselected(selectionData);
  }, []);

  const items = useMemo(
    () => (reportLocations ? reportLocations?.map(({ id, name }) => ({ id, name, selected: false })) : []),
    [reportLocations],
  );

  return (
    <div className={styles.locationsList}>
      <div className={styles.title}>{`Search ${maxLengthOfString(selectedReport?.name || '', 35)}`}</div>
      <div className={styles.searchInput}>
        <Field
          id="locationsSearch"
          name="locationsSearch"
          onChange={onSearch}
          value={searchString}
          placeholder="Search locations"
          fieldType={InputTypesEnum.WITH_ICON}
          icon={searchString && <CrossIcon />}
          onIconClick={() => setSearchString('')}
          iconSide="right"
        />
      </div>
      <div className={`${styles.reportLocations} custom-scrollbar`}>
        <div className={styles.groupTitle}>
          <span>Locations on view</span>
          <Checkbox
            isChecked={reportLocations?.length === preselected.filter((item) => item.selected).length}
            disabled={!reportLocations || reportLocations.length === 0}
            onChange={onSelectAllClick}
            name="select-all-locations"
          />
        </div>
        {preselected
          .filter((location) => searchString === '' || location.name.toLowerCase().includes(searchString.toLowerCase()))
          .map((location) => (
            <LocationRow
              id={location.id}
              name={location.name}
              selected={location.selected}
              onClick={onPreselectionChange}
            />
          ))}
      </div>
      <div className={styles.buttons}>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          layout="primary"
          onClick={onSave}
          disabled={preselected.filter((item) => item.selected).length === 0}
          animation
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default LocationsList;
