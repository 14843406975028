import { IReportCategory } from '../../types/report';
import { PolygonData } from '../common/manageLocations/createLocation/interfaces';

export enum RecurrenceFrequencyEnum {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  DAILY = 'daily',
}

export type ReportData = {
  projectId: string;
  name: string;
  description: string;
  startDate: Date | null;
  endDate: Date | null;
  recurrence: boolean;
  recurrenceFrequency: RecurrenceFrequencyEnum;
  category: IReportCategory;
  areaType: string;
  residenceExclusion: boolean;
};

export type LocationsData = {
  locations: PolygonData[];
};

export type ReportItemsData = {
  attraction_isActive: boolean;
  attraction_linkPois: boolean;
  attraction_pois: PolygonData[];
  attraction_linkAois: boolean;
  attraction_aois: PolygonData[];

  absorption_isActive: boolean;
  absorption_linkPois: boolean;
  absorption_pois: PolygonData[];
  absorption_aoi: PolygonData | null;
  absorption_areaType: string;
  absorption_radius: number;
};

export const defaultReportData: ReportData = {
  name: '',
  description: '',
  projectId: '',
  startDate: null,
  endDate: null,
  recurrence: false,
  recurrenceFrequency: RecurrenceFrequencyEnum.MONTHLY,
  category: IReportCategory.POI_DEFAULT,
  areaType: '1',
  residenceExclusion: true,
};

export const defaultReportItemsData: ReportItemsData = {
  attraction_isActive: false,
  attraction_linkPois: false,
  attraction_pois: [],
  attraction_linkAois: false,
  attraction_aois: [],

  absorption_isActive: false,
  absorption_linkPois: false,
  absorption_pois: [],
  absorption_aoi: null,
  absorption_areaType: 'AOI',
  absorption_radius: 250,
};

export const reportItemsDefaultSettings = [
  {
    name: 'catchment',
    description: '',
    reportItemType: 'POI_DEFAULT.catchment',
    settings: [
      {
        name: 'radius [km]',
        value: '50',
      },
    ],
  },
  {
    name: 'footfall',
    description: '',
    reportItemType: 'POI_DEFAULT.footfall',
    settings: [
      {
        name: 'Requested version',
        value: '',
      },
    ],
  },
  {
    name: 'seasonality',
    description: '',
    reportItemType: 'POI_DEFAULT.seasonality',
    settings: [],
  },
  {
    name: 'retention',
    description: '',
    reportItemType: 'POI_DEFAULT.retention',
    settings: [],
  },
  {
    name: 'loyalty',
    description: '',
    reportItemType: 'POI_DEFAULT.loyalty',
    settings: [
      {
        name: 'Selection Type',
        value: 'loyal',
      },
    ],
  },
  {
    name: 'duration',
    description: '',
    reportItemType: 'POI_DEFAULT.duration',
    settings: [],
  },
  {
    name: 'movement',
    description: '',
    reportItemType: 'POI_DEFAULT.movement',
    settings: [],
  },
];
