import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReportItemDetail from '../../../common/reportItems/reportItemDetail';
import { ContentVisualizationMode, CrossVisitation, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { Chart } from './Chart';
import { Table } from './Table';
import { ReportItemType } from '../../../common/reportItems/types';
import { MainContext } from '../../../common/types/mainContext';
import { useElementScreenshot } from '../../../common/hooks/useElementScreenshot';
import { useStore } from 'effector-react/effector-react.cjs';
import { exportSettingsModel } from '../../../common/export';
import { convertDataForExcel } from './utils';

export const CrossVisitationDetails = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReport },
    contentVisualizationMode,
    reportItemsDetails: { crossVisitationChartValues },
    primaryLocationId,
  } = useContext(MainContext);
  const { onMakeScreenshot, base64Image, elementRef, onClearScreenshotData } = useElementScreenshot({
    backgroundColor: `rgba(37, 55, 76, 1)`,
  });
  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const isChartReady = useRef<boolean>(false);
  const isExportMode = useStore(exportSettingsModel.$isExportMode);

  const reportItem = useMemo(
    () =>
      selectedReport?.report_items?.find(
        (item) => item.type === ReportItemType.CROSS_VISITATION,
      ) as ReportItemInfo<CrossVisitation>,
    [selectedReport],
  );

  const onChartReady = () => {
    if (reportItem?.data && !isChartReady.current) {
      isChartReady.current = true;
      if (exportOptions.LOYALTY?.chart) {
        onMakeScreenshot();
      }
    }
  };

  useEffect(() => {
    if (!reportItem?.data?.data) {
      exportSettingsModel.updateExportData({ [ReportItemType.CROSS_VISITATION]: { chart: '', table: '' } });
    }
  }, [reportItem?.data?.data]);

  useEffect(() => {
    if (base64Image) {
      exportSettingsModel.updateExportData({ [ReportItemType.CROSS_VISITATION]: { chart: base64Image } });
      onClearScreenshotData();
    }
  }, [base64Image]);

  useEffect(() => {
    if (reportItem?.data?.data && exportOptions.LOYALTY?.table) {
      const locationData = reportItem.data.data.find(
        ({ primary_location_id }) => primary_location_id === primaryLocationId,
      );
      if (locationData) {
        const data = convertDataForExcel(locationData);
        exportSettingsModel.updateExportData({ [ReportItemType.CROSS_VISITATION]: { table: data } });
      }
    }
  }, [exportOptions.LOYALTY]);

  const renderData = (data: CrossVisitation | null) => {
    if (!data) return null;
    if (contentVisualizationMode === ContentVisualizationMode.VISUALIZATION || isExportMode) {
      return (
        <Chart
          data={data.data}
          onChartReady={onChartReady}
          isScreenshotMode={isExportMode}
          chartValues={crossVisitationChartValues}
        />
      );
    } else {
      return <Table data={data.data} />;
    }
  };

  const minimizedData = useMemo(
    () => renderData(reportItem?.data),
    [contentVisualizationMode, reportItem, crossVisitationChartValues, isExportMode],
  );

  return (
    <div ref={elementRef} style={{ width: '100%', height: '100%' }}>
      <ReportItemDetail
        type={ReportItemType.CROSS_VISITATION}
        title={t('crossVisitation.title')}
        subtitle={t('crossVisitation.detail.shareOfCrossVisitation')}
        onMakeScreenshot={onMakeScreenshot}
      >
        {reportItem?.data && minimizedData}
      </ReportItemDetail>
    </div>
  );
};
