import moment, { Moment } from 'moment';
import { AppActions } from '../actions/actionTypes';
import { dateInThePast } from '../time/timeUtils';

export const successBoxDisplayTimeInMillis = 5000;

export interface SuccessBoxState {
  message: string;
  visibleUntil: Moment;
  visible: boolean;
}

const initialState: SuccessBoxState = {
  message: '',
  visible: false,
  visibleUntil: dateInThePast,
};

export default function successBoxReducer(state = initialState, action: AppActions) {
  switch (action.type) {
    case 'SHOW_SUCCESS_MESSAGE': {
      return {
        ...state,
        message: action.payload.message,
        visible: true,
        visibleUntil: moment().add(5, 'seconds'),
      };
    }
    case 'HIDE_SUCCESS_MESSAGE_IF_REQUIRED': {
      if (state.visibleUntil.isBefore(moment())) {
        return {
          ...state,
          message: '',
          visible: false,
          visibleUntil: dateInThePast,
        };
      }
      return state;
    }
    default:
      return state;
  }
}
