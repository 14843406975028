import React, { ReactElement, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from 'react-redux';
import { Project } from '../../../types/project';
import Button from '../../atoms/primaryButton/button';
import styles from './projectForm.module.css';
import ErrorMessage from '../errorMessage';

import { changeProjectStatus } from '../../../actions/actions';

import { STATUS } from '../../pages/projectsPage/status';
import { Account } from '../../../types/Account';

interface Props {
  sourceProject?: Project;
  onSubmit: (project: Project) => void;
  accounts?: Account[];
  selectedAccount?: string;
}

const ProjectForm = ({ sourceProject, onSubmit, accounts, selectedAccount }: Props): ReactElement => {
  const [name, setName] = useState<string>(sourceProject?.name || '');
  const [description, setDescription] = useState<string>(sourceProject?.description || '');
  const [accountId, setAccount] = useState<string>(selectedAccount || '');

  const dispatch = useDispatch();
  const [status, setStatus] = React.useState(sourceProject ? sourceProject.status : '');
  const handleChange = () => {
    if (sourceProject) {
      if (sourceProject.status?.toLowerCase() !== STATUS.PRESENTABLE) {
        setStatus(STATUS.PRESENTABLE);
        sourceProject.status = STATUS.PRESENTABLE_VALUE;
      } else {
        setStatus(STATUS.READY);
        sourceProject.status = STATUS.READY_VALUE;
      }
      if (sourceProject.id) {
        dispatch(changeProjectStatus(sourceProject.id, sourceProject.status));
      }
    }
  };

  const isInEditMode = !!sourceProject;

  const submitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit({
      ...sourceProject,
      name,
      description,
      status,
      account_id: accountId,
    });
  };

  return (
    <form className={styles.formContainer} onSubmit={submitHandler}>
      <div className={styles.row}>
        <label htmlFor="name" className={styles.label}>
          Project name
        </label>
        <input
          className={styles.name}
          id="name"
          type="text"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="description" className={styles.label}>
          Project description
        </label>
        <textarea
          className={styles.description}
          id="description"
          value={description}
          onChange={({ target: { value } }) => setDescription(value)}
          rows={5}
        />
      </div>
      {!isInEditMode && (
        <div className={styles.row}>
          <label htmlFor="account" className={styles.label}>
            Account
          </label>
          <select
            className={styles.countries}
            id="account"
            value={accountId}
            onChange={({ target: { value } }) => setAccount(value)}
          >
            {(accounts || []).map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {sourceProject &&
        (sourceProject.status?.toLowerCase() === STATUS.PRESENTABLE ||
          sourceProject.status?.toLowerCase() === STATUS.READY) && (
          <FormControlLabel
            htmlFor="status"
            onClick={(e) => {
              e.preventDefault();
              handleChange();
            }}
            control={<Switch checked={status?.toLowerCase() === STATUS.PRESENTABLE} name="isVisible" />}
            label="Is Visible"
          />
        )}
      <ErrorMessage />
      <Button type="submit" className={styles.submitButton}>
        {isInEditMode ? 'Update' : 'Create'}
      </Button>
    </form>
  );
};

export default ProjectForm;
