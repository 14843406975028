import { API_URLS } from '../../routes';
import { request } from '../../api';
import { DurationResponse, DurationResponseValue } from './types';

import { mapResponse } from './mapper';
import { PeriodsEnum, ReportItemType } from '../../common/reportItems/types';
import { ProjectInfo, ReportItemInfo, ReportItems, SelectedTimeFrames } from '../../common/types/projectsHierarchy';
import { REQUEST_DELAY } from '../types';

const localeCompare = (itemA: DurationResponseValue, itemB: DurationResponseValue) =>
  itemA.location_name.localeCompare(itemB.location_name);

const fetchData = async (reportItemId: string, period: string, locations: string[], timeFrames: string[] | null) => {
  const timeFramesQueryParam = timeFrames !== null ? `timeFrames=${timeFrames.join(',')}` : '';
  const periodQueryParam = `period=${period}`;
  const query = [periodQueryParam, timeFramesQueryParam].filter((item) => item.length > 0).join('&');

  const url = locations.length
    ? `${API_URLS.REPORT_ITEMS_DATA}/${reportItemId}/data?locations=${locations.join(',')}&${query}`
    : `${API_URLS.REPORT_ITEMS_DATA}/${reportItemId}/data?${query}`;
  return request<DurationResponse>({ url });
};

export const getDurationData = async (
  reportItemId: string,
  timeFrames: SelectedTimeFrames,
  locations: string[],
  selectedProject: ProjectInfo | null,
  updateReportItemOfSelectedReport: (item: ReportItemInfo<ReportItems>) => void,
  setFetched: (value: boolean) => void,
  setIsLoading: (value: boolean) => void,
  selectedReportItem: ReportItemInfo<ReportItems> | null,
) => {
  try {
    setIsLoading(true);
    const projectLocations = selectedProject?.locations || [];
    const timeFrameResolution = PeriodsEnum.WHOLE_PERIOD.replace(/ /g, '_').toUpperCase();
    if (selectedReportItem?.type !== ReportItemType.DURATION) {
      await new Promise((resolve) => setTimeout(resolve, REQUEST_DELAY));
    }
    let responseData = await fetchData(reportItemId, timeFrameResolution, locations, timeFrames);
    if (responseData) {
      responseData = {
        ...responseData,
        values: responseData.values.sort(localeCompare),
      };
      const data = mapResponse(responseData, projectLocations);
      updateReportItemOfSelectedReport(data);
    }
  } finally {
    setFetched(true);
    setIsLoading(false);
  }
};
