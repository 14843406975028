import React from 'react';
import { useTranslation } from 'react-i18next';
import { IReportCategory } from '../../../../../types/report';
import Dropdown from '../../../../common/controls/dropdown';
import styles from '../style.module.scss';

interface TProps {
  reportCategory: string;
  onChange: (reportCategory: string) => void;
}

type TOption = {
  id: string;
  label: string;
  item: null;
};

export const ReportCategoryDropdown = ({ reportCategory, onChange }: TProps) => {
  const { t } = useTranslation();

  const reportCategories: TOption[] = [
    { id: IReportCategory.POI_DEFAULT, label: t(`create.enum.${IReportCategory.POI_DEFAULT}`), item: null },
    { id: IReportCategory.AOI_DEFAULT, label: t(`create.enum.${IReportCategory.AOI_DEFAULT}`), item: null },
  ];

  const onDropdownChange = (item: TOption) => {
    if (item.id) {
      onChange(item.id);
    }
  };
  return (
    <div className={styles.wrapper}>
      <Dropdown<null>
        onChange={onDropdownChange}
        selectedId={reportCategory}
        options={reportCategories}
        classNames={styles.dropdown}
      />
    </div>
  );
};
