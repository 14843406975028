import {
  Duration,
  DurationCategory,
  DurationData,
  DurationLocation,
  LocationInfo,
  ReportItemInfo,
} from '../../common/types/projectsHierarchy';
import { DurationResponse, DurationResponseValue, DurationResponseValueData } from './types';
import { mapReportItemType } from '../utils';
import { generateMapPoints } from '../utils/mappers/generateMapPoints';
import { generateMapPoiPolygons } from '../utils/mappers/generateMapPoiPolygons';
import { getCorrectPointsIfCoordinatesAreWrong } from '../../common/maps/utils';

export const mapDurationCategory = (value: string): DurationCategory => {
  switch (value) {
    case '0-5 Min':
    case '0-5':
      return DurationCategory['0-5'];
    case '5-15 Min':
    case '5-15':
      return DurationCategory['5-15'];
    case '15-30 Min':
    case '15-30':
      return DurationCategory['15-30'];
    case '30-60 Min':
    case '30-60':
      return DurationCategory['30-60'];
    case '60-120 Min':
    case '60-120':
      return DurationCategory['60-120'];
    case '120+ Min':
    case '120+':
      return DurationCategory['120+'];
    default:
      throw new Error(`Could not parse ${value} to DurationCategory enum`);
  }
};

export const mapValueToCategory = (value: number): DurationCategory => {
  if (value <= 5) {
    return DurationCategory['0-5'];
  }
  if (value <= 15) {
    return DurationCategory['5-15'];
  }
  if (value <= 30) {
    return DurationCategory['15-30'];
  }
  if (value <= 60) {
    return DurationCategory['30-60'];
  }
  if (value <= 120) {
    return DurationCategory['60-120'];
  }
  return DurationCategory['120+'];
};

export const mapDurationResponseValues = (values: DurationResponseValue[]): DurationLocation[] =>
  values.map((location) => ({
    location_id: location.location_id,
    location_name: location.location_name,
    location_average: calculateAverageDurationForLocation(location.data),
    data: location.data.map((locationData) => ({
      category: mapDurationCategory(locationData.category),
      value: locationData.value,
    })),
  }));

const calculateAverageVisitDuration = (values: DurationResponseValue[]): number =>
  values
    .map((location) =>
      location.data
        .map(({ avgVisitDuration, value }) => [value * avgVisitDuration, value])
        .reduce(
          (previousValue, nextValue) => [previousValue[0] + nextValue[0], previousValue[1] + nextValue[1]],
          [0, 0],
        ),
    )
    .reduce((previousValue, nextValue) => [previousValue[0] + nextValue[0], previousValue[1] + nextValue[1]])
    .reduce((previousValue, nextValue) => previousValue / nextValue);

export const calculateAverageDurationForLocation = (data: DurationResponseValueData[]): number =>
  data
    .map(({ avgVisitDuration, value }) => [avgVisitDuration * value, value])
    .reduce((previousValue, nextValue) => [previousValue[0] + nextValue[0], previousValue[1] + nextValue[1]], [0, 0])
    .reduce((previousValue, nextValue) => previousValue / nextValue);

export const mapResponse = (responseData: DurationResponse, locations: LocationInfo[]): ReportItemInfo<Duration> => {
  let data = null;
  let visualization = null;
  const isEmptyResponse = !responseData?.values || responseData.values.length === 0;
  if (!isEmptyResponse) {
    const totalCategories = Object.keys(DurationCategory).length;
    const averageData: DurationData[] = [];
    for (let i = 0; i < totalCategories; i++) {
      let category = Object.keys(DurationCategory)[i];
      if (responseData.values[0].data[i]) {
        category = responseData.values[0].data[i].category;
      }
      const categoryAverage = responseData.values
        .map((location) => location.data[i]?.value || 0)
        .reduce((previousValue, nextValue) => previousValue + nextValue, 0);
      averageData.push({
        category: mapDurationCategory(category),
        value: categoryAverage / responseData.values.length,
      });
    }

    const mapPointsData = responseData.values.map((location) => ({
      location_id: location.location_id,
      location_name: location.location_name,
      average: `${calculateAverageDurationForLocation(location.data).toFixed(0)} min`,
    }));

    data = {
      average: calculateAverageVisitDuration(responseData.values),
      averageData,
      data: mapDurationResponseValues(responseData.values),
    };

    const points = generateMapPoints(mapPointsData, locations);
    const poiPolygons = generateMapPoiPolygons(mapPointsData, locations);

    visualization = {
      points: getCorrectPointsIfCoordinatesAreWrong(points, poiPolygons),
      poiPolygons,
    };
  }

  return {
    id: responseData.id,
    name: responseData.name,
    type: mapReportItemType(responseData.type),
    data,
    visualization,
    settings: [],
  };
};
