import React, { useContext, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Chart } from './chart';
import { Filters } from './filters';
import styles from './style.module.scss';
import { characteristicAudienceModel } from './model';
import { MainContext } from '../../../../common/types/mainContext';
import { reportItemDetailsModel } from '../../../model';
import { useURLParams } from '../../../../common/hooks';
import { getCountryCurrency, replaceStringSpace } from '../../../../common/utils';
import { Catchment, ReportItemInfo } from '../../../../common/types/projectsHierarchy';
import { Option } from '../../../../common/controls/dropdown/types';

interface Props {}
export const CharacteristicOfAudienceChart: React.FC<Props> = () => {
  const {
    selection: { selectedReport, selectedReportItem },
    reportItemsDetails: { catchmentTreshold, catchmentType, selectedCountry },
    selectedTimeFrames,
    selectedLocationsIds,
  } = useContext(MainContext);

  const { metrics, categories, countries, subCategories } = useStore(characteristicAudienceModel.$filterOptions);
  const data = useStore(characteristicAudienceModel.$data);
  const filters = useStore(characteristicAudienceModel.$activeFilters);
  const initialFiltersData = useStore(characteristicAudienceModel.$initialFilterOptions);
  const metricType = useStore(characteristicAudienceModel.$metricType);
  const yAxisLabel = useStore(characteristicAudienceModel.$yAxisLabel);
  const { onChangeParams, params, onRemoveParam, isParsingDone } = useURLParams();

  useEffect(() => {
    if (selectedCountry) {
      onChangeFilter('country')(selectedCountry);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (isParsingDone) {
      const { country, metric, category, subcategory } = filters;
      if (selectedReportItem?.id && metric) {
        const { id } = selectedReportItem;
        const { min, max } = catchmentTreshold;
        const payload = {
          reportItemId: id,
          countryCode: country,
          catchmentType,
          locationsIds: selectedLocationsIds,
          timeFrames: selectedTimeFrames,
          minThreshold: min,
          maxThreshold: max,
          metric,
          category,
          subcategory,
        };

        (async () => {
          reportItemDetailsModel.toggleReportItemDetailsLoading(true);
          await characteristicAudienceModel.getData(payload);
          reportItemDetailsModel.toggleReportItemDetailsLoading(false);
        })();
      }
    }
  }, [
    filters.country,
    filters.subcategory,
    filters.category,
    filters.metric,
    selectedTimeFrames,
    selectedLocationsIds,
    catchmentType,
    catchmentTreshold.min,
    catchmentTreshold.max,
    isParsingDone,
  ]);

  useEffect(() => {
    const { country, metric, category, subcategory } = filters;

    if (country) {
      onChangeParams('catchmentSocioCountry', country);
    } else {
      onRemoveParam('catchmentSocioCountry');
    }

    if (metric) {
      onChangeParams('catchmentSocioMetric', metric);
    } else {
      onRemoveParam('catchmentSocioMetric');
    }

    if (category) {
      onChangeParams('catchmentSocioCategory', category);
    } else {
      onRemoveParam('catchmentSocioCategory');
    }

    if (subcategory) {
      onChangeParams('catchmentSocioSubCategory', country);
    } else {
      onRemoveParam('catchmentSocioSubCategory');
    }
  }, [filters]);

  useEffect(() => {
    if (isParsingDone) {
      if (selectedReportItem?.id) {
        (async function () {
          const { catchmentSocioCountry, catchmentSocioMetric, catchmentSocioCategory, catchmentSocioSubCategory } =
            params;

          const fitlers = {
            country: replaceStringSpace(catchmentSocioCountry, '+', ' '),
            metric: replaceStringSpace(catchmentSocioMetric, '+', ' '),
            category: replaceStringSpace(catchmentSocioCategory, '+', ' '),
            subcategory: replaceStringSpace(catchmentSocioSubCategory, '+', ' '),
          };
          await characteristicAudienceModel.getFilters({ reportItemId: selectedReportItem.id, paramsFilters: fitlers });
        })();
      }
    }
  }, [isParsingDone]);

  useEffect(() => {
    characteristicAudienceModel.updateFiltersOptions({
      filters,
      initialData: initialFiltersData,
      filterOptionsId: selectedReportItem?.id || '',
    });
  }, [initialFiltersData, filters]);

  const onChangeFilter = (type: keyof typeof filters) => (value: string) => {
    characteristicAudienceModel.changeFilters({ type, value });
  };

  const filteredCountries: Option<null>[] = countries?.filter((country) =>
    (selectedReportItem as ReportItemInfo<Catchment>)?.data?.countries?.includes(country.id),
  );

  return (
    <div className={styles.wrapper}>
      <Filters
        metrics={metrics}
        countries={filteredCountries}
        categories={categories}
        subcategories={subCategories}
        values={filters}
        onChange={onChangeFilter}
      />
      {data.length ? (
        <Chart
          currency={getCountryCurrency(filters.country)}
          data={data}
          isRelativeMetric={metricType === 'PERCENTAGE'}
          metricType={metricType}
          yAxisLabel={yAxisLabel}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
