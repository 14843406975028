import React, { useState, useEffect } from 'react';
import { CloudsLoader } from '../clouds';
import { CircleLoader } from '../circle';

export const MixCircleClouds = () => {
  const [showClouds, toggleClouds] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => toggleClouds(true), 4000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {showClouds && <CloudsLoader />}
      <CircleLoader />
    </div>
  );
};
