import React, { useContext, useMemo } from 'react';
import { ColorDomain } from '@deck.gl/core/utils/color';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { Position } from '@deck.gl/core';
import { useStore } from 'effector-react';
import { MainContext } from '../../../../common/types/mainContext';
import { getMinMaxValue } from '../../../../reportItems/movement/utils';
import { Movement, ReportItemInfo } from '../../../../common/types/projectsHierarchy';
import DeckBase from '../../../../common/maps/mapBase/deckBase';
import DEFAULT_THEME from '../../../../common/maps/mapBase/map-theme.config';
import { ColorSchemeEnum, MovementVisitorTypeEnum } from '../../../../common/reportItems/types';
import { MovementData } from '../../../../reportItems/movement/types';
import { getColorScale } from '../../../../common/maps/utils/getGradientUtil';
import { exportSettingsModel } from '../../../../common/export';
import { ExportMapContext } from '../../../../common/export/exportSettings/settings/mapContainer';

interface Props {}
export const DeckHeatmap: React.FC<Props> = () => {
  const {
    selection: { selectedReportItem },
    reportItemsDetails: { colorScheme, movementType, movementPeriod },
    mapOptions,
    exportSelection,
  } = useContext(MainContext);
  const { isExportMap } = useContext(ExportMapContext);

  const isExportMode = useStore(exportSettingsModel.$isExportMode);

  const reportItem = (
    isExportMap && isExportMode ? exportSelection.selectedReportItem : selectedReportItem
  ) as ReportItemInfo<Movement>;

  const currentData = useMemo(() => {
    const activeVisitor = movementType === MovementVisitorTypeEnum.VISITORS ? 'visitor' : 'resident_worker';
    return reportItem?.data?.data[0].data?.filter((item) =>
      movementType === MovementVisitorTypeEnum.RESIDENTS_WORKERS
        ? item.visitor_type === activeVisitor
        : item.visitation_period === movementPeriod.toLocaleLowerCase(),
    );
  }, [reportItem?.data?.data[0], movementType, movementPeriod]);

  const minMax = useMemo(() => (currentData ? getMinMaxValue(currentData) : null), [currentData]);

  const pointDiameter = useMemo(
    () => Math.max(30, ((mapOptions.default.zoom || 12) - 12) * 27),
    [mapOptions.default.zoom],
  );

  return currentData ? (
    <DeckBase
      effects={DEFAULT_THEME.effects}
      layers={[
        new HeatmapLayer<MovementData>({
          id: 'optimizedHeatmaps',
          data: currentData,
          getPosition: (d) => d.geo_entity_code.split(',').map((item) => Number(item)) as Position,
          getWeight: (d) => d.visits_share,
          aggregation: 'SUM',
          radiusPixels: pointDiameter,
          colorRange: getColorScale(colorScheme === ColorSchemeEnum.MONOCHROME).map(({ r, g, b }) => [r, g, b]),
          opacity: 0.1,
          intensity: 1.8,
          colorDomain: minMax as ColorDomain,
        }),
      ]}
    />
  ) : null;
};
