import { LocationDailySeasonalityResponse, LocationHourlySeasonalityResponse, SeasonalityResponse } from './types';
import {
  DailySeasonality,
  HourlySeasonality,
  LocationInfo,
  ReportItemInfo,
  Seasonality,
} from '../../common/types/projectsHierarchy';
import { ReportItemType } from '../../common/reportItems/types';
import { Area, Point } from '../../common/types/visualizationObjects';
import { convertPolygonToGeoData } from '../../common/maps/utils/convertPolygonToGeoData';
import { WeekDaysShort } from '../types';
import { getCorrectPointsIfCoordinatesAreWrong } from '../../common/maps/utils';

type SeasonalityLocation = {
  locationId: string;
  locationName: string;
  mostPopularDayAndHour: HourlySeasonality;
};

const calculateMostPopularHourForLocation = (location: LocationHourlySeasonalityResponse): HourlySeasonality => {
  const hourlyAverage = new Map<number, number[]>();
  location.data.forEach(({ hour_of_day, visits_share }) => {
    if (hourlyAverage.has(hour_of_day)) {
      const items = hourlyAverage.get(hour_of_day) || [];
      items.push(visits_share);
      hourlyAverage.set(hour_of_day, items);
    } else {
      hourlyAverage.set(hour_of_day, [visits_share]);
    }
  });

  const average: HourlySeasonality[] = Array.from(hourlyAverage.keys()).map((hour) => ({
    dayOfWeekNumber: -1,
    hourOfDay: hour,
    visitsShare: (hourlyAverage.get(hour) || []).reduce((previousValue, nextValue) => previousValue + nextValue, 0) / 7,
  }));

  return calculateMostPopularHour(average);
};

const calculateWeeklyAverage = (data: LocationHourlySeasonalityResponse): HourlySeasonality[] => {
  const hourlyAverage = new Map<number, number[]>();

  data.data.forEach(({ hour_of_day, visits_share }) => {
    if (hourlyAverage.has(hour_of_day)) {
      const items = hourlyAverage.get(hour_of_day) || [];
      items.push(visits_share);
      hourlyAverage.set(hour_of_day, items);
    } else {
      hourlyAverage.set(hour_of_day, [visits_share]);
    }
  });

  const weeklyAvg = Array.from(hourlyAverage.keys()).map((hour) => ({
    dayOfWeekNumber: -1,
    hourOfDay: hour,
    visitsShare: (hourlyAverage.get(hour) || []).reduce((previousValue, nextValue) => previousValue + nextValue, 0) / 7,
  }));
  weeklyAvg.push({
    dayOfWeekNumber: -1,
    hourOfDay: 24,
    visitsShare: weeklyAvg[0].visitsShare,
  });
  return weeklyAvg;
};

const calculateMostPopularHour = (data: HourlySeasonality[]): HourlySeasonality => {
  let mostPopularHour = data[0];
  data.forEach((item) => {
    if (item.visitsShare > mostPopularHour.visitsShare) {
      mostPopularHour = item;
    }
  });
  return mostPopularHour;
};

const calculateMostPopularDayForLocation = (location: LocationDailySeasonalityResponse): DailySeasonality => {
  const dailySum = new Map<number, number>();
  location.data.forEach(({ day_of_week_number, visits_share }) => {
    if (dailySum.has(day_of_week_number)) {
      const previousDailySum = dailySum.get(day_of_week_number) || 0;
      dailySum.set(day_of_week_number, previousDailySum + visits_share);
    } else {
      dailySum.set(day_of_week_number, visits_share);
    }
  });

  const dailyAverage: DailySeasonality[] = Array.from(dailySum.keys()).map((day) => ({
    dayOfWeekNumber: day,
    visitsShare: dailySum.get(day) || 0,
  }));

  let mostPopularDay = dailyAverage[0];

  dailyAverage.forEach((item) => {
    if (item.visitsShare > mostPopularDay.visitsShare) {
      mostPopularDay = item;
    }
  });

  return mostPopularDay;
};

const normalizeMostPopularDayAndHour = (data: HourlySeasonality) => {
  const hour = data.hourOfDay;
  const day = WeekDaysShort[data.dayOfWeekNumber - 1];
  const from = hour > 12 ? hour - 12 : hour;
  const to = hour >= 12 ? hour - 12 + 1 : hour + 1;
  const period = hour >= 12 ? 'pm' : 'am';
  return `${day}, ${from}-${to} ${period}`;
};

const mapPoints = (values: SeasonalityLocation[], locations: LocationInfo[]): Point[] => {
  const points = values
    .map((value) => {
      const location = locations.find((loc) => loc.id === value.locationId);
      return location && location.latitude && location.longitude
        ? {
            location_id: value.locationId,
            name: value.locationName,
            longitude: location.longitude,
            latitude: location.latitude,
            value: normalizeMostPopularDayAndHour(value.mostPopularDayAndHour),
            color: location.color,
          }
        : null;
    })
    .filter((item) => item);
  return points as Point[];
};

const mapPoiPolygons = (values: SeasonalityLocation[], locations: LocationInfo[]): Area[] => {
  const polygons = values
    .map((value) => {
      const location = locations.find((loc) => loc.id === value.locationId);
      return location && location.geometry
        ? {
            location_id: value.locationId,
            name: value.locationName,
            geo: convertPolygonToGeoData(location.geometry),
          }
        : null;
    })
    .filter((item) => item && item.geo);
  return polygons as Area[];
};

export const mapResponse = (response: SeasonalityResponse, locations: LocationInfo[]): ReportItemInfo<Seasonality> => {
  let data: Seasonality | null = null;
  let visualization = null;
  const isEmptyResponse = response.hourly_values.length === 0 || response.daily_values.length === 0;

  if (!isEmptyResponse) {
    const hourlyByDay = response.hourly_values.map((location) => {
      const dayMap = new Map();
      location.data.map((entry) => {
        const key = entry.day_of_week_number;
        if (!dayMap.has(key)) {
          dayMap.set(key, 0);
        }
        const value = dayMap.get(key) + entry.visits_share;
        dayMap.set(key, value);
      });
      return {
        locationId: location.location_id,
        locationName: location.location_name,
        mostPopularHour: calculateMostPopularHourForLocation(location),
        average: [], // todo implement
        data: location.data.map((entry) => ({
          dayOfWeekNumber: entry.day_of_week_number,
          hourOfDay: entry.hour_of_day,
          visitsShare:
            dayMap.get(entry.day_of_week_number) === 0 ? 0 : entry.visits_share / dayMap.get(entry.day_of_week_number),
        })),
      };
    });

    data = {
      hourlyByWeek: response.hourly_values.map((location) => ({
        locationId: location.location_id,
        locationName: location.location_name,
        mostPopularHour: calculateMostPopularHourForLocation(location),
        average: calculateWeeklyAverage(location),
        data: location.data.map((entry) => ({
          dayOfWeekNumber: entry.day_of_week_number,
          hourOfDay: entry.hour_of_day,
          visitsShare: entry.visits_share,
        })),
      })),
      hourlyByDay,
      daily: response.daily_values.map((location) => ({
        locationId: location.location_id,
        locationName: location.location_name,
        mostPopularDay: calculateMostPopularDayForLocation(location),
        data: location.data.map((entry) => ({
          dayOfWeekNumber: entry.day_of_week_number,
          visitsShare: entry.visits_share,
        })),
      })),
      averageDailyAcrossLocations: response.daily_values[0].data.map((header, headerIndex) => {
        const sum = response.daily_values.reduce(
          (acc, valueItem) =>
            valueItem.data[headerIndex] ? acc + (valueItem.data[headerIndex].visits_share as number) : acc,
          0,
        );
        return {
          dayOfWeekNumber: header.day_of_week_number,
          visitsShare: sum / response.daily_values.length || 0,
        };
      }),
      averageHourlyByWeekAcrossLocations: response.hourly_values[0].data.map((header, headerIndex) => {
        const sum = response.hourly_values.reduce(
          (acc, valueItem) =>
            valueItem.data[headerIndex] ? acc + (valueItem.data[headerIndex].visits_share as number) : acc,
          0,
        );
        return {
          dayOfWeekNumber: header.day_of_week_number,
          hourOfDay: header.hour_of_day,
          visitsShare: sum / response.daily_values.length || 0,
        };
      }),
      averageHourlyByDayAcrossLocations: response.hourly_values[0].data.map((header, headerIndex) => {
        const sum = hourlyByDay.reduce(
          (acc, valueItem) =>
            valueItem.data[headerIndex] ? (valueItem.data[headerIndex].visitsShare as number) + acc : acc,
          0,
        );
        return {
          dayOfWeekNumber: header.day_of_week_number,
          hourOfDay: header.hour_of_day,
          visitsShare: sum / response.daily_values.length || 0,
        };
      }),
    };
    const seasonalityLocations: SeasonalityLocation[] = data.hourlyByWeek.map(
      ({ locationId, locationName, mostPopularHour }) => ({
        locationId,
        locationName,
        mostPopularDayAndHour: {
          ...mostPopularHour,
          dayOfWeekNumber: data
            ? data.daily.find((item) => item.locationId === locationId)?.mostPopularDay.dayOfWeekNumber || 1
            : 1,
        },
      }),
    );

    const points = mapPoints(seasonalityLocations, locations);
    const poiPolygons = mapPoiPolygons(seasonalityLocations, locations);

    visualization = {
      points: getCorrectPointsIfCoordinatesAreWrong(points, poiPolygons),
      poiPolygons,
    };
  }

  return {
    id: response.id,
    name: response.name,
    type: ReportItemType.SEASONALITY,
    data: data || null,
    visualization,
    settings: [],
  };
};
