import { SwitchModes } from '../../../../types/visualizationObjects';
import { MovementMapTypesEnum } from '../enums';

const mapModestoTypes = {
  [SwitchModes.HEATMAP]: MovementMapTypesEnum.HEAT_MAP,
  [SwitchModes.POSTAL_CODES]: MovementMapTypesEnum.HEAT_MAP,
  [SwitchModes.TRIPS]: MovementMapTypesEnum.HEAT_MAP,
  [SwitchModes.HOTSPOTS]: MovementMapTypesEnum.HEAT_MAP,
};

export const getTypeFromMapMode = (item: SwitchModes) => mapModestoTypes[item];
