import React from 'react';

export const CreateReport = () => (
  <svg width="233" height="202" viewBox="0 0 233 202" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_bd_5733_52468)">
      <path
        d="M121.949 119.536L97.7867 111.935L72.8448 56H92.8625L120.515 87.4161L146.597 56H174.732C177.979 56 180.838 58.1385 181.752 61.2512L187.239 79.9506L121.949 119.536Z"
        fill="white"
        fillOpacity="0.6"
        shapeRendering="crispEdges"
      />
      <path
        d="M109.136 127.305L38.4812 104.566L30.2961 132.652C28.9336 137.327 32.4446 142 37.3196 142H84.8989L109.136 127.305Z"
        fill="white"
        fillOpacity="0.6"
        shapeRendering="crispEdges"
      />
      <path
        d="M108.055 142L124.404 132.219L154.797 142H108.055Z"
        fill="white"
        fillOpacity="0.6"
        shapeRendering="crispEdges"
      />
      <path
        d="M137.45 124.413L190.902 92.4332L202.7 132.639C204.073 137.317 200.561 142 195.68 142H193.354L137.45 124.413Z"
        fill="white"
        fillOpacity="0.6"
        shapeRendering="crispEdges"
      />
      <path
        d="M58.1243 56H59.6536L82.2341 107.042L41.4948 94.2257L51.1008 61.2642C52.0099 58.145 54.8718 56 58.1243 56Z"
        fill="white"
        fillOpacity="0.6"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M152.5 4.125V16.5H164.875V24.75H152.5V37.125H144.25V24.75H131.875V16.5H144.25V4.125H152.5ZM119.5 53.625C114.962 53.625 111.25 49.9125 111.25 45.375C111.25 40.8375 114.962 37.125 119.5 37.125C124.037 37.125 127.75 40.8375 127.75 45.375C127.75 49.9125 124.037 53.625 119.5 53.625ZM123.625 12.6225V20.955C122.26 20.7394 120.881 20.6291 119.5 20.625C105.681 20.625 94.75 31.2262 94.75 46.2C94.75 55.8525 102.794 68.64 119.5 83.9025C136.206 68.64 144.25 55.8937 144.25 46.2V45.375H152.5V46.2C152.5 59.895 141.486 76.1062 119.5 94.875C97.5138 76.1062 86.5 59.895 86.5 46.2C86.5 25.6575 102.175 12.375 119.5 12.375C120.903 12.375 122.264 12.4575 123.625 12.6225Z"
      fill="#00B2F1"
    />
    <defs>
      <filter
        id="filter0_bd_5733_52468"
        x="-5"
        y="21"
        width="243"
        height="181"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="17.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5733_52468" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="30" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.113725 0 0 0 0 0.145098 0 0 0 0.4 0" />
        <feBlend mode="overlay" in2="effect1_backgroundBlur_5733_52468" result="effect2_dropShadow_5733_52468" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5733_52468" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default CreateReport;
