import {
  DataTypeExtended,
  DataTypeExtendedCalculated,
  DirectionTypes,
  SortingType,
  BaseTableHeaderProps,
} from './types';

//= ==== Calculate =====

export const calculateOptions = (data: DataTypeExtended[]): DataTypeExtendedCalculated[] =>
  data.map((row) => ({
    ...row,
    total: row.options.reduce((res, option) => {
      Object.keys(option.values).forEach((key) => {
        if (typeof option.values[key] === 'number') {
          if (res[key]) res[key] += option.values[key] as number;
          else res[key] = option.values[key] as number;
        } else {
          res[key] = 0;
        }
      });
      return res;
    }, {} as Record<string, number>),
  }));

export const calculateTotal = (data: DataTypeExtendedCalculated[]): Record<string, number> =>
  data.reduce((res, i) => {
    Object.keys(i.total).forEach((key) => {
      if (res[key]) res[key] += i.total[key];
      else res[key] = i.total[key];
    });
    return res;
  }, {} as Record<string, number>);

//= ==== Sorting =====

const getDirectionFactor = (direction: DirectionTypes): 1 | -1 => (direction === 'asc' ? 1 : -1);

const sortUtil = (a: string | number, b: string | number): SortingType => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

const headSort = (data: DataTypeExtendedCalculated[], direction: DirectionTypes): DataTypeExtendedCalculated[] =>
  data
    .map((i) => ({
      ...i,
      options: i.options.sort((a, b) => sortUtil(a.subhead.value, b.subhead.value) * getDirectionFactor(direction)),
    }))
    .sort((a, b) => {
      if (a?.options[0]?.subhead && b?.options[0]?.subhead) {
        return (
          (sortUtil(a.head, b.head) || sortUtil(a.options[0].subhead?.value, b.options[0].subhead.value)) *
          getDirectionFactor(direction)
        );
      }
      return sortUtil(a.head, b.head) * getDirectionFactor(direction);
    });

const valuesSort = (
  data: DataTypeExtendedCalculated[],
  key: string,
  direction: DirectionTypes,
): DataTypeExtendedCalculated[] =>
  data
    .map((i) => ({
      ...i,
      options: i.options.sort((a, b) => sortUtil(a.values[key], b.values[key]) * getDirectionFactor(direction)),
    }))
    .sort((a, b) => sortUtil(a.total[key], b.total[key]) * getDirectionFactor(direction));

export const dataSort = (
  data: DataTypeExtendedCalculated[],
  key: string,
  direction: DirectionTypes,
): DataTypeExtendedCalculated[] => (key === 'head' ? headSort(data, direction) : valuesSort(data, key, direction));

//= ==== Create CSV Data =====

const CSV_DELIMITER = '; ';
const CSV_ROW_DELIMITER = '\n';

/**
 * Create csv string from data
 * @param data - items array
 * @param headName - head title
 * @param subheadName - subhead title
 * @param headers - header config array
 * @param total - all data total record
 * @return csv string
 */
export const createCSVData = (
  data: DataTypeExtendedCalculated[],
  headName: string,
  subheadName: string,
  headers: BaseTableHeaderProps[],
  total: Record<string, number>,
  showTotal: boolean,
  showGrandTotal: boolean,
  subheadAsString = true,
): string => {
  const header =
    headName +
    CSV_DELIMITER +
    subheadName +
    CSV_DELIMITER +
    headers.map((i) => i.name).join(CSV_DELIMITER) +
    CSV_ROW_DELIMITER;

  const content = data
    .map(
      (i) =>
        i.options.reduce(
          (res, option) =>
            `${res + i.head + CSV_DELIMITER}${subheadAsString ? '"' : ''}${(
              option.subhead.formatter() as string
            ).replace(/"/g, '""')}${subheadAsString ? '"' : ''}${CSV_DELIMITER}${headers
              .map((value) => `${String(option.values[value.key])} ${value.unit}` || '')
              .join(CSV_DELIMITER)}${CSV_ROW_DELIMITER}`,
          '',
        ) +
        (showTotal
          ? `${CSV_DELIMITER}Total${CSV_DELIMITER}${headers
              .map((value) => `${String(i.total[value.key])} ${value.unit}` || '')
              .join(CSV_DELIMITER)}${CSV_ROW_DELIMITER}`
          : ''),
    )
    .join('');

  const footer = showGrandTotal
    ? `Grand Total${CSV_DELIMITER}${CSV_DELIMITER}${headers
        .map((value) => `${String(total[value.key])} ${value.unit}` || '')
        .join(CSV_DELIMITER)}${CSV_ROW_DELIMITER}`
    : '';

  return header + content + footer;
};
