import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../common/tooltip';
import { TooltipPositionCoordinates } from '../../../../../common/reportItems/hooks';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  position: TooltipPositionCoordinates;
}
const TableRowTooltip: React.FC<Props> = ({ onClose, position, isOpen }) => {
  const { t } = useTranslation();

  return (
    <Tooltip onClose={onClose} initialPosition={position} isOpen={isOpen} height={300} title={t('create.areaType')}>
      <span>
        {t('create.catchment.paragraph1')}
        <br />
      </span>
      <span>
        {t('create.catchment.paragraph2')}
        <br />
      </span>
      <span>
        <i>{t('create.catchment.paragraph3')}</i>
        <br />
      </span>
      <span>
        {t('create.catchment.paragraph4')}
        <br />
      </span>
      <span>{t('create.catchment.paragraph5')}</span>
    </Tooltip>
  );
};

export default TableRowTooltip;
