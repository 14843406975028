import React from 'react';
import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import { validationSchema } from './schemes';
import styles from './style.module.scss';
import { Field, InputTypesEnum } from '../../../common/inputs';
import { Credentials } from '../interfaces';
import { Routes } from '../../../routes';

interface Props {
  onSubmit: (data: Credentials) => void;
  error: string;
}
export const Form: React.FC<Props> = ({ onSubmit, error }) => {
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.wrapper}>
        <div className={styles.fields}>
          <div className={styles.field}>
            <Field
              name="email"
              onChange={handleChange}
              value={values.email}
              placeholder="Email"
              id="email"
              label="Email"
              maxLabelWidth="64px"
              error={errors.email}
              fieldType={InputTypesEnum.TEXT}
            />
          </div>
          <div className={styles.field}>
            <Field
              name="password"
              onChange={handleChange}
              value={values.password}
              placeholder="Password"
              id="password"
              label="Password"
              maxLabelWidth="64px"
              error={errors.password}
              fieldType={InputTypesEnum.PASSWORD}
            />
          </div>
        </div>
        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.footer}>
          <NavLink to={Routes.forgotPassword}>
            <span>Forgot password?</span>
          </NavLink>
          <button className={styles.button}>Login</button>
        </div>
      </div>
    </form>
  );
};
