import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../molecules/Modal/Modal';
import styles from './editUserModal.module.css';
import Button from '../../atoms/primaryButton/button';
import { AddUser } from '../../../types/AddUser';
import { AppState } from '../../../reducers/reducers';
import { AuthState } from '../../../reducers/authReducer';
import { fetchAccounts, fetchFfiPlans, fetchUser, fetchUserRoles, updateUser } from '../../../actions/actions';
import { userRoleTranslationMap } from '../../../types/UserRole';
import UserStatus from '../../../types/UserStatus';
import { User } from '../../../types/User';
import ChangePasswordForEditUserModalForm from '../ChangePasswordForm/ChangePasswordForEditUserModalForm';
import ErrorMessage from '../errorMessage';
import Input from './Input';
import { ProjectsState } from '../../../reducers/projectsReducer';
import FfiPlans from '../../molecules/dropdowns/ffiPlans/FfiPlans';

interface Props {
  isVisible?: boolean;
  onClose: () => void;
  onSave: () => void;
  userId: string;
}

const mapFfiPlans = (allPlans: string[], selectedPlans: string[]): { [id: string]: boolean } => {
  const result: { [id: string]: boolean } = {};
  allPlans.forEach((plan) => (result[plan] = !!selectedPlans && selectedPlans.includes(plan)));
  return result;
};

const EditUserModal = ({ isVisible: isVisibleProps, onClose, onSave, userId }: Props): ReactElement => {
  const [isVisible, setIsVisible] = useState(isVisibleProps);
  const history = useHistory();
  const { ffiPlans } = useSelector<AppState, ProjectsState>((state) => state.projects);
  const [state, setState] = useState<AddUser>({
    accountId: '',
    userId: '',
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    ffiPlans: {},
    status: '',
    phone: '',
    title: '',
    notifyUser: true,
  });
  const [ffi, setFfi] = useState({
    NL: '',
    DE: '',
  });
  const [userFfiPlans, setUserFfiPlans] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { accounts, userRoles } = useSelector<AppState, AuthState>((state) => state.auth);

  const userFetchedSuccessHandler = (user: User) => {
    setUserFfiPlans(user.ffiPlans);
    setState((prevState) => ({
      ...prevState,
      ...user,
      ffiPlans: mapFfiPlans(ffiPlans, user.ffiPlans),
    }));
  };

  useEffect(() => {
    dispatch(fetchUser(userId, userFetchedSuccessHandler));
  }, [userId]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ffiPlans: mapFfiPlans(ffiPlans, userFfiPlans),
    }));
    setFfi({
      NL: state.ffiPlans.FFI_NL_PROFESSIONAL
        ? 'FFI_NL_PROFESSIONAL'
        : state.ffiPlans.FFI_NL_ESSENTIAL
        ? 'FFI_NL_ESSENTIAL'
        : '',
      DE: state.ffiPlans.FFI_DE_PROFESSIONAL
        ? 'FFI_DE_PROFESSIONAL'
        : state.ffiPlans.FFI_DE_ESSENTIAL
        ? 'FFI_DE_ESSENTIAL'
        : '',
    });
  }, [JSON.stringify(userFfiPlans), state.userId]);

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchFfiPlans());
    dispatch(fetchUserRoles());
  }, []);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      accountId: accounts[0] ? accounts[0].id : prevState.accountId,
    }));
  }, [accounts]);

  const updateInputFieldHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateRoleFieldHandler = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    setState((prevState) => ({
      ...prevState,
      role: value,
    }));
  };

  const updateStatusFieldHandler = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    setState((prevState) => ({
      ...prevState,
      status: value,
    }));
  };

  const updateNotifyUser = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => ({
      ...prevState,
      notifyUser: !prevState.notifyUser,
    }));
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    dispatch(updateUser(state, updateHandler));
  };

  const closeModal = useCallback(() => {
    setIsVisible(false);
    onClose();
  }, []);

  const updateHandler = useCallback(() => {
    onSave();
    closeModal();
    history.go(0);
  }, []);

  const updateFfiPlansHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLSelectElement>): void => {
    if (name === 'NL') {
      setState((prevState) => ({
        ...prevState,
        ffiPlans: {
          ...state.ffiPlans,
          FFI_NL_PROFESSIONAL: false,
          FFI_NL_ESSENTIAL: false,
          [value]: true,
        },
      }));
    }
    if (name === 'DE') {
      setState((prevState) => ({
        ...prevState,
        ffiPlans: {
          ...state.ffiPlans,
          FFI_DE_PROFESSIONAL: false,
          FFI_DE_ESSENTIAL: false,
          [value]: true,
        },
      }));
    }
    setFfi((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal onClose={onClose} isVisible={isVisible}>
      <h2 className={styles.title}>Edit User</h2>
      <ErrorMessage />
      <form className={styles.form} onSubmit={submitHandler}>
        <div className={styles.topForm}>
          <Input id="account" label="Account" value={state.accountId} disabled />
          <Input id="firstName" label="First name" value={state.firstName} onChange={updateInputFieldHandler} />
          <Input id="lastName" label="Last name" value={state.lastName} onChange={updateInputFieldHandler} />
          <Input id="email" label="Email" value={state.email} disabled />
          <div className={styles.formRow}>
            <label htmlFor="role">
              Role
              <select name="role" id="role" value={state.role} onChange={updateRoleFieldHandler}>
                <option key="empty" value="" disabled />
                {userRoles.map((role) => (
                  <option key={role} value={role}>
                    {userRoleTranslationMap.get(role)}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className={styles.formRow}>
            <label htmlFor="status">
              Status
              <select name="status" id="status" value={state.status} onChange={updateStatusFieldHandler}>
                <option key="empty" value="" disabled />
                <option key={UserStatus.ACTIVE} value={UserStatus.ACTIVE}>
                  {UserStatus.ACTIVE}
                </option>
                <option key={UserStatus.DISABLED} value={UserStatus.DISABLED}>
                  {UserStatus.DISABLED}
                </option>
              </select>
            </label>
          </div>
          <Input id="password" label="Password" value={state.password} type="password" disabled />
        </div>
        {state.ffiPlans && Object.keys(state.ffiPlans).length > 0 && (
          <>
            <div className={`${styles.formRow} ${styles.ffiHeading}`}>
              <h3>
                <u>FFI plans:</u>
              </h3>
            </div>
            <ul>
              <li>
                <FfiPlans
                  label="FFI for Netherlands"
                  name="NL"
                  value={ffi.NL}
                  handler={updateFfiPlansHandler}
                  includes="FFI_NL"
                  ffiPlans={state.ffiPlans}
                />
              </li>
              <li>
                <FfiPlans
                  label="FFI for Germany"
                  name="DE"
                  value={ffi.DE}
                  handler={updateFfiPlansHandler}
                  includes="FFI_DE"
                  ffiPlans={state.ffiPlans}
                />
              </li>
            </ul>
          </>
        )}
        <div className={styles.formTop}>
          <hr />
          <div className={styles.formRow}>
            <label htmlFor="notifyUser">
              <input type="checkbox" name="notifyUser" checked={state.notifyUser} onChange={updateNotifyUser} />
              Notify user
            </label>
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.saveButtonContainer}>
            <Button type="submit">Save</Button>
          </div>
          <div>
            <Button onClick={closeModal} secondary>
              Cancel
            </Button>
          </div>
        </div>
      </form>
      <hr className={styles.horizontalDivider} />
      <ChangePasswordForEditUserModalForm email={state.email} onSave={updateHandler} />
    </Modal>
  );
};

export default EditUserModal;
