import { CatchmentItemData, CatchmentItemValue } from '../../types';
import { CatchmentMetricTypeEnum, CatchmentTypeEnum } from '../../../../common/reportItems/types';
import { toFixNumber } from '../../../../common/lib';

export const convertDataForExcel = (
  data: CatchmentItemValue,
  type: CatchmentTypeEnum,
  metric: CatchmentMetricTypeEnum,
) => {
  const typeData = type === CatchmentTypeEnum.WORK ? data.work : data.home;

  const getTableDataValue = (item: CatchmentItemData) => {
    if (CatchmentMetricTypeEnum.SCORE === metric) {
      return `${toFixNumber(item.geo_entity_catchment_score * 100, 2)}`;
    }
    if (CatchmentMetricTypeEnum.SOURCE === metric) {
      return `${toFixNumber(item.geo_entity_share_of_source * 100, 2)}%`;
    }
    if (CatchmentMetricTypeEnum.DESTINATION === metric) {
      return `${toFixNumber(item.geo_entity_share_of_target * 100, 2)}%`;
    }
    return `0%`;
  };

  return typeData.map((item) => ({
    location: data.location_name,
    postal_code: `${item.geo_entity_name}: ${item.geo_entity_code}`,
    aerial_distance: item.aerial_distance > 0 ? `${item.aerial_distance} km` : '-',
    visits_share: getTableDataValue(item),
  }));
};
