import * as yup from 'yup';

export const validationSchema = yup.object({
  password: yup.string().required('Password is required'),
  repeatPassword: yup
    .string()
    .required('Password is required')
    .test('passwords-match', 'Passwords must match', function (value) {
      if (!this.parent.password) return true;
      if (!value) return true;
      return this.parent.password === value;
    }),
});
