import React, { useContext } from 'react';
import styles from './panelTitle.module.scss';
import { BackArrowIcon } from '../../../icons/controlPanel';
import { MenuContext } from '../../../sidebarMenu/menuContext';

interface TProps {
  title: string;
  backButton?: boolean;
  children?: React.ReactElement;
}

export const PanelTitle = (props: TProps) => {
  const { closeMenu } = useContext(MenuContext);
  return (
    <div className={styles.titleContainer}>
      {props.backButton && (
        <button type="button" onClick={closeMenu} className={styles.backButton}>
          <BackArrowIcon />
        </button>
      )}
      <div className={styles.title}>{props.title}</div>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};
