import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CSVReader } from 'react-papaparse';
import { CSVLink } from 'react-csv';
import { useStore } from 'effector-react';
import * as turf from '@turf/turf';
import { useTranslation } from 'react-i18next';
import styles from './csvimporter.module.scss';
import { PolygonData } from '../createLocation/interfaces';
import { LocationManagerModel } from '../model';
import {
  CSVErrorType,
  CSVImportValidationError,
  CSVImportValidationErrorRow,
  ValidationError,
  ValidationErrorType,
} from '../locationManager/validation/validationErrorType';
import { MainContext } from '../../types/mainContext';
import { createPolygonFromWKT } from '../locationManager/validation/createPolygon';
import { isMaxNumberOfClustersReachedForLocations } from '../locationManager/validation/isMaxNumberOfClustersReached';
import { InformationIcon } from '../../icons/reportItems';
import { useTooltipStartPosition } from '../../reportItems/hooks';
import ImportLocationsTooltip from './tooltip';

const sampleCSVData = [
  ['sep=,'],
  ['Polygon (WKT)', 'Name', 'Description', 'Country', 'Address', 'City', 'Postcode', 'Location Type'],
];

interface Props {
  isEdit: boolean | undefined;
  projectId: string;
  onValidationError: (error: ValidationError) => void;
}

const CSVImporter = ({ isEdit, projectId, onValidationError }: Props) => {
  const [key, setKey] = useState(Math.random());
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const { t } = useTranslation();
  const selectedLocations = useStore(LocationManagerModel.$selectedLocationsInformation);
  const {
    limitations: { maximumNumberOfLocations, maximumNumberOfClusters, aoiPolygonSize },
  } = useContext(MainContext);
  const { tooltipIconRef, position } = useTooltipStartPosition();

  const isValid = (rows: any[]) => {
    if (rows.length <= 1) {
      onValidationError({ type: ValidationErrorType.CSV });
      return false;
    }

    const locations: PolygonData[] = [];
    const errors: CSVImportValidationErrorRow[] = [];

    const dataRows = rows[0]?.data[0]?.trim().startsWith('sep=,') ? rows.slice(2) : rows.slice(1);

    dataRows.forEach((row, index) => {
      const { data } = row;
      const name = data.length >= 2 ? data[1].trim() : '';
      if (name === '') {
        errors.push({
          row: index,
          type: CSVErrorType.MISSING_NAME,
        });
      } else if (data.length !== 8) {
        errors.push({
          row: index,
          id: name,
          type: CSVErrorType.WRONG_NUMBER_OF_COLUMNS,
        });
      } else {
        const geometry = createPolygonFromWKT(data[0]?.trim());
        const areaSize = turf.area(geometry);

        if (areaSize > aoiPolygonSize) {
          errors.push({ row: index, id: name, type: CSVErrorType.POLYGON_SIZE });
        } else {
          const location = {
            id: uuidv4(),
            polygonString: data[0]?.trim(),
            name,
            description: data[2]?.trim(),
            country: data[3]?.trim(),
            address: data[4]?.trim(),
            city: data[5]?.trim(),
            postalCode: data[6]?.trim(),
            setup: data[7]?.trim(),
            street: '',
            polygon: null,
            isNew: true,
            isUsed: false,
          };
          locations.push(location);
        }
      }
    });

    if (!isEdit && selectedLocations.length + locations.length > maximumNumberOfLocations) {
      onValidationError({ type: ValidationErrorType.NUMBER_OF_LOCATIONS });
    } else if (
        !isEdit &&
        isMaxNumberOfClustersReachedForLocations([...selectedLocations, ...locations], maximumNumberOfClusters)
    ) {
      onValidationError({ type: ValidationErrorType.NUMBER_OF_CLUSTERS });
    } else if (errors.length > 0) {
      onValidationError({
        type: ValidationErrorType.CSV,
        errors,
      } as CSVImportValidationError);
    } else if (isEdit) {
      const onSuccess = () => {
        LocationManagerModel.getLocations(projectId);
      };
      LocationManagerModel.createLocations({ projectId, data: locations, onSuccess });
    } else {
      LocationManagerModel.saveLocations(locations);
    }
  };

  const saveLocations = (rows: any[]) => {
    setKey(Math.random);
    isValid(rows);
  };

  const hideTooltip = () => setIsTooltipOpened(false);
  const openTooltip = () => setIsTooltipOpened(true);

  return (
      <div className={styles.container}>
        <ImportLocationsTooltip onClose={hideTooltip} isOpen={isTooltipOpened} position={position} />
        <div className={styles.titleContainer}>
          <div className={styles.importLocationsContainer}>
            <span>{t('edit.CSVImport.importLocations')}</span>
            <span className={styles.tooltipIconContainer} ref={tooltipIconRef}>
            <span className={styles.tooltipIcon} onClick={openTooltip} title={t('edit.CSVImport.importLocations')}>
              <InformationIcon />
            </span>
          </span>
          </div>
          <CSVLink filename="poi.csv" data={sampleCSVData}>
            {t('edit.CSVImport.downloadTemplate')}
          </CSVLink>
        </div>
        <CSVReader
            key={key}
            isReset
            onDrop={saveLocations}
            onError={() => {
              console.log('error loading csv file');
            }}
        >
          <span>{t('edit.CSVImport.dragNDropTitle')}</span>
        </CSVReader>
      </div>
  );
};

export default CSVImporter;
