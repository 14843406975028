import React from 'react';
import styles from './forgotPasswordPage.module.scss';
import { Page, Title, Message, LinkButton } from '../common';
import { InfoIcon } from '../common/icon/info';

interface StateProps {
  errorMessage: string;
  forgotPassword: (email: string) => void;
}

const email = sessionStorage.getItem('forgotPasswordEmail') || 'your email';

const ThankYouPage = (props: StateProps) => (
  <Page>
    <Title>Thank you</Title>
    <Message>
      We sent a link to reset your password to <br />
      <strong>{email}</strong>
    </Message>
    <div className={styles.capsule}>
      <InfoIcon />
      The link is <b>valid for 10 minutes.</b>
    </div>
    <Message>
      If you don't see an email from us, try checking your spam folder or{' '}
      <a href="https://placesense.ai/contact">contact support</a> to set a back up.
    </Message>

    <LinkButton url="/" text="Login with different account" />
  </Page>
);

export default ThankYouPage;
