import React, { ReactElement, useCallback, useState } from 'react';
import MaterialUiButton from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router';
import styles from './confirmDeleteModal.module.css';
import Button from '../../atoms/primaryButton/button';
import Modal from '../Modal/Modal';

interface Props {
  title: string;
  isVisible?: boolean;
  onDeleteRedirectURL?: string;
  onClose: () => void;
  onDelete: () => void;
}

const ConfirmDeleteModal = ({
  isVisible: isVisibleProps,
  onDeleteRedirectURL,
  onClose,
  onDelete,
  title,
}: Props): ReactElement => {
  const [isVisible, setIsVisible] = useState(isVisibleProps);
  const history = useHistory();

  const closeModal = useCallback(() => {
    setIsVisible(false);
    onClose();
  }, []);

  const deleteHandler = useCallback(() => {
    onDelete();
    closeModal();
    if (onDeleteRedirectURL) {
      history.replace(onDeleteRedirectURL);
    }
  }, []);

  return (
    <Modal onClose={onClose} isVisible={isVisible}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.buttons}>
        <div id={styles.deleteButton} className={styles.deleteButton}>
          <MaterialUiButton variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={deleteHandler}>
            Delete
          </MaterialUiButton>
        </div>
        <div>
          <Button onClick={closeModal} secondary>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
