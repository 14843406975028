import React, { useState } from 'react';
import styles from './menu.module.scss';
import YearMonthIntervalPicker from './YearMonthIntervalPicker';
import { Button } from '../../controls/buttons';

interface Props {
  startDate: Date;
  endDate: Date;
  minDate: Date | null;
  maxDate: Date | null;
  onClose: () => void;
  onSave: (start: Date, end: Date) => void;
  disabledFrom?: boolean;
  disabledTo?: boolean;
}

const Menu: React.FC<Props> = ({ startDate, endDate, minDate, maxDate, onClose, onSave, disabledFrom, disabledTo }) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(endDate);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h3>Calendar</h3>
        <span>Time range is on a monthly resolution</span>
      </div>
      <YearMonthIntervalPicker
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        minDate={minDate || startDate}
        maxDate={maxDate || endDate}
        onChangeStartDate={setSelectedStartDate}
        onChangeEndDate={setSelectedEndDate}
        disabledFrom={disabledFrom}
        disabledTo={disabledTo}
      />
      <div className={styles.buttonContainer}>
        <Button onClick={onClose}>Cancel</Button>
        <Button layout="primary" onClick={() => onSave(selectedStartDate, selectedEndDate)} animation>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Menu;
