import React from 'react';
import styles from './style.module.scss';

interface Props {
  image: string;
  name: string | null;
  rank: number | null;
  total: number | null;
}

export const LocationInformation: React.FC<Props> = ({ children, name, rank, total }) => (
  <div className={styles.information}>
    {/* <div className={styles.rank}> */}
    {/*   <h4>{rank}</h4> */}
    {/*   <p>{total} total</p> */}
    {/* </div> */}
    <div className={styles.wrapper}>{children}</div>
    <p className={styles.name}>{name}</p>
  </div>
);
