import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { LongArrowIcon } from '../../../../common/icons/reportItems';
import { PeriodsEnum } from '../../../../common/reportItems/types';
import { toFixNumber } from '../../../../common/lib';
import { TrendScopesEnum } from '../../enum';
import Dropdown from '../../../../common/controls/dropdown';
import { Option } from '../../../../common/controls/dropdown/types';
import { capitalizeLetter } from '../../../../common/lib/capitalize';
import { ShowAverageToggle } from '../../../../common/reportItems/reportItemDetail/showAverageToggle/showAverageToggle';

interface Props {
  average: number;
  momAverage: number;
  onChangeTimeTypeOption: (value: Option<PeriodsEnum>) => void;
  onChangeMetricType: (value: Option<TrendScopesEnum>) => void;
  activeTimeType: string;
  activeMetric: string;
  hideAdvancedInformation: boolean;
  isPercentage?: boolean;
  isAvgNumbers?: boolean;
}

const transformToOptionItem = <T extends PeriodsEnum | TrendScopesEnum>(value: T, t: (val: string) => string) => ({
  id: value,
  label: t(`visitTrends.enum.${value}`),
  item: value,
  disabled: false,
});

export const DetailHeader = ({
  average,
  momAverage,
  onChangeTimeTypeOption,
  onChangeMetricType,
  activeTimeType,
  activeMetric,
  hideAdvancedInformation,
  isPercentage,
  isAvgNumbers,
}: Props) => {
  const { t } = useTranslation();
  const periodOptions: Option<PeriodsEnum>[] = useMemo(
    () => [PeriodsEnum.MONTHLY, PeriodsEnum.WEEKLY].map((scope) => transformToOptionItem(scope, t)),
    [],
  );

  const metricOptions: Option<TrendScopesEnum>[] = useMemo(
    () =>
      Object.values(TrendScopesEnum)
        .filter((value) => value !== TrendScopesEnum.ABSOLUTE_NUMBER)
        .map((scope) => transformToOptionItem(scope, t)),
    [],
  );

  function getAvgSummaries() {
    return (
      <>
        {!hideAdvancedInformation ? (
          <div className={styles.items}>
            {!isPercentage ? (
              <div className={styles.item}>
                <p className={styles.information}>
                  {!Number.isNaN(average) ? toFixNumber(average, 0).toLocaleString() : average !== 0 ? '0' : '0'}
                </p>
                <p className={styles.subTitle}>
                  Avg. {activeTimeType === PeriodsEnum.MONTHLY ? 'monthly' : 'weekly'} visit count
                </p>
              </div>
            ) : (
              <></>
            )}
            <div className={styles.item}>
              <p className={styles.information}>
                <LongArrowIcon
                  style={{ stroke: momAverage < 0 ? '#EB5757' : '#399A2A' }}
                  parentStyle={{ transform: `rotate(${momAverage < 0 ? '-180deg' : 0})` }}
                />
                <span className={styles.value}>{toFixNumber(momAverage, 1) || 0}%</span>
              </p>
              <p className={styles.subTitle}>
                Avg. % change {activeTimeType === PeriodsEnum.MONTHLY ? 'Monthly' : 'Weekly'}
              </p>
            </div>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }

  return (
    <div className={`${styles.header} ${isPercentage ? styles.percentage : styles.absolute}`}>
      {!hideAdvancedInformation && <ShowAverageToggle />}
      {isAvgNumbers ? getAvgSummaries() : <div />}
      {isPercentage && (
        <Dropdown<TrendScopesEnum>
          onChange={onChangeMetricType}
          selectedId={activeMetric}
          options={metricOptions}
          classNames={styles.sourceLocationDropdown}
          selectedItemClassName={styles.selectedItemClassName}
          maxLengthToShow={100}
        />
      )}
      <Dropdown<PeriodsEnum>
        onChange={onChangeTimeTypeOption}
        selectedId={activeTimeType}
        options={periodOptions}
        classNames={styles.sourceLocationDropdown}
        selectedItemClassName={styles.selectedItemClassName}
        maxLengthToShow={100}
      />
    </div>
  );
};
