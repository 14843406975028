import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Modal from '../../molecules/Modal/Modal';
import styles from './updatePasswordModal.module.css';
import Button from '../../atoms/primaryButton/button';
import { changePasswordForUser } from '../../../actions/actions';
import ErrorMessage from '../errorMessage';
import { EyeIcon } from '../../pages/Authentication/common/icon/eye';
import logo from './placense-logo.jpg';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  email: string;
}

const UpdatePasswordModal = ({ isVisible, onClose, email }: Props): ReactElement => {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const [state, setState] = useState({
    newPassword: '',
    reEnterNewPassword: '',
  });

  const updateInputFieldHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  const changePasswordHandler = (): void => {
    if (state.newPassword === state.reEnterNewPassword) {
      dispatch(changePasswordForUser(email || '', state.newPassword, onClose));
      setErrorMessage('');
    } else {
      setErrorMessage('The passwords should be the same');
    }
  };

  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(!newPasswordShown);
  };

  const [reEnterPasswordShown, setReEnterPasswordShown] = useState(false);
  const toggleReEnterPasswordVisiblity = () => {
    setReEnterPasswordShown(!reEnterPasswordShown);
  };

  return (
    <Modal onClose={onClose} isVisible={isVisible}>
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <img src={logo} className={styles.logo} />
          <h2 className={styles.title}>New password</h2>
          <p className={styles.subtitle}>
            Password must be at least 8 characters long and include letters, digits and symbols
          </p>
          <form className={styles.form} onSubmit={changePasswordHandler}>
            <div className={styles.formRow}>
              <label htmlFor="otherUser_newPassword" className={styles.label}>
                <Input
                  className={styles.input}
                  type={newPasswordShown ? 'text' : 'password'}
                  id="otherUser_newPassword"
                  name="newPassword"
                  placeholder="New password"
                  value={state.newPassword}
                  onChange={updateInputFieldHandler}
                  autoComplete="new-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <EyeIcon onClick={toggleNewPasswordVisiblity} />
                    </InputAdornment>
                  }
                />
              </label>
            </div>
            <div className={styles.formRow}>
              <label htmlFor="otherUser_reEnterNewPassword">
                <Input
                  className={styles.input}
                  type={reEnterPasswordShown ? 'text' : 'password'}
                  id="otherUser_reEnterNewPassword"
                  name="reEnterNewPassword"
                  placeholder="Re enter new password"
                  value={state.reEnterNewPassword}
                  onChange={updateInputFieldHandler}
                  autoComplete="re-enter-new-password"
                  endAdornment={
                    <InputAdornment position="end" className={styles.icon}>
                      <EyeIcon onClick={toggleReEnterPasswordVisiblity} />
                    </InputAdornment>
                  }
                />
              </label>
            </div>
            <div className={styles.errorMessage}>
              {errorMessage}
              <ErrorMessage />
            </div>
          </form>
        </div>
      </div>
      <div className={styles.buttons}>
        <div className={styles.buttonContainer}>
          <Button onClick={changePasswordHandler} className={styles.buttonContainer}>
            Update password
          </Button>
        </div>
        <div>
          <Button onClick={onClose} secondary>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdatePasswordModal;
