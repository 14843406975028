import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReportItemCard from '../../../common/reportItems/reportItemCard';
import { ReportItemType } from '../../../common/reportItems/types';
import { MainContext } from '../../../common/types/mainContext';
import { CrossVisitation, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { fetchCrossVisitationData } from '../services';
import { CrossVisitationChart } from './Chart';
import { getAllLocationsFromNestedLocations } from '../lib';
import { generateMapPoints } from '../../utils/mappers/generateMapPoints';
import { generateMapPoiPolygons } from '../../utils/mappers/generateMapPoiPolygons';
import { useToggle } from '../../../common/hooks';
import { toFixNumber } from '../../../common/lib';
import { getCorrectPointsIfCoordinatesAreWrong } from '../../../common/maps/utils';
import { CrossVisitationMainTooltip } from './tooltips';
import { dashboardModel } from '../../../dasboardLoader';
import { exportSettingsModel } from '../../../common/export';
import { useTooltipStartPosition } from '../../../common/reportItems/hooks';
import { REQUEST_DELAY } from '../../types';

export const CrossVisualizationCard = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReport, selectedReportItem },
    primaryLocationId,
    selectedLocations,
    selectedTimeFrames,
    selectedLocationsIds,
    updateReportItemOfSelectedReport,
  } = useContext(MainContext);

  const [isLoading, setIsLoading] = useToggle(false);
  const [fetched, setFetched] = useToggle(true);
  const [isTooltipOpen, toggleTooltipOpen] = useToggle(false);

  const { tooltipIconRef, position } = useTooltipStartPosition();

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.CROSS_VISITATION,
  ) as ReportItemInfo<CrossVisitation>;

  const { visualization, ...reportItemNoVisualization } = reportItem || {};

  const changePintsAccordingToPrimaryLocation = (reportItem: ReportItemInfo<CrossVisitation>) => {
    const allLocations = getAllLocationsFromNestedLocations(reportItem.data?.data, String(primaryLocationId));

    if (allLocations) {
      const mapPointsData = allLocations.map(({ location_id, location_name, value }) => ({
        location_id,
        location_name,
        average: value,
      }));
      const points = generateMapPoints(mapPointsData, selectedLocations);
      const poiPolygons = generateMapPoiPolygons(mapPointsData, selectedLocations);
      const data = {
        ...reportItem,
        visualization: {
          points: getCorrectPointsIfCoordinatesAreWrong(points, poiPolygons),
          poiPolygons,
        },
      };
      updateReportItemOfSelectedReport(data);
    }
  };

  useEffect(() => {
    if (reportItemNoVisualization?.id && !isLoading) {
      (async function () {
        setIsLoading(true);
        setFetched(false);
        dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.CROSS_VISITATION, value: false });
        if (selectedReportItem?.type !== ReportItemType.CROSS_VISITATION) {
          await new Promise((resolve) => setTimeout(resolve, REQUEST_DELAY));
        }
        const response = await fetchCrossVisitationData(
          reportItemNoVisualization.id,
          selectedLocationsIds,
          selectedTimeFrames,
        );
        if (response) {
          updateReportItemOfSelectedReport(response);
          changePintsAccordingToPrimaryLocation(response);
        } else if (selectedTimeFrames?.length === 0) {
          const emptyData = { ...reportItemNoVisualization, data: null, visualization: null };
          updateReportItemOfSelectedReport(emptyData);
        }
        setIsLoading(false);
        setFetched(true);
        dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.CROSS_VISITATION, value: true });
      })();
    }
  }, [JSON.stringify(reportItemNoVisualization), selectedLocationsIds, selectedTimeFrames]);

  useEffect(() => {
    if (reportItem?.data) {
      changePintsAccordingToPrimaryLocation(reportItem);
    }
  }, [primaryLocationId, selectedLocations]);

  const noData = useMemo(
    () => fetched && (!reportItem || !reportItem.data || reportItem.data.data.length === 0),
    [reportItem, selectedTimeFrames, fetched, isLoading, selectedLocationsIds],
  );

  const data = useMemo(
    () => [
      { value: reportItem?.data?.singleLocationAverage, name: t('crossVisitation.card.singleLocationVisits') },
      { value: reportItem?.data?.crossVisitationAverage, name: t('crossVisitation.card.crossVisitationShare') },
    ],
    [reportItem, t],
  );

  useEffect(() => {
    if (reportItem?.data?.singleLocationAverage) {
      exportSettingsModel.updateReportItemsInformation({
        crossVisitation: { singleLocationAverage: reportItem.data?.singleLocationAverage },
      });
    }
  }, [reportItem?.data?.singleLocationAverage]);

  const onTooltipClose = () => {
    toggleTooltipOpen(false);
  };

  const availableTypes = selectedReport?.report_items?.map((value) => value.type);

  return !availableTypes?.includes(ReportItemType.CROSS_VISITATION) ? (
    <div />
  ) : (
    <ReportItemCard
      reportItemType={ReportItemType.CROSS_VISITATION}
      values={[
        {
          name: t('crossVisitation.card.avgShareOfCrossVisits'),
          value: `${toFixNumber(Number(reportItem?.data?.singleLocationAverage), 1)}%`,
        },
      ]}
      reportItem={reportItem}
      isLoading={isLoading}
      noData={noData}
      onOpenTooltip={toggleTooltipOpen}
      tooltipRef={tooltipIconRef}
    >
      <>
        <CrossVisitationMainTooltip onClose={onTooltipClose} isOpen={isTooltipOpen} position={position} />
        <CrossVisitationChart data={data} isLoading={isLoading} />
      </>
    </ReportItemCard>
  );
};
