import React from 'react';
import Polygon from '../../../common/maps/polygon/polygon';
import { Area } from '../../../common/types/visualizationObjects';

interface Props {
  polygons?: Area[];
}

export const PoiPolygons = ({ polygons }: Props) => {
  if (!polygons) return <></>;

  return (
    <Polygon
      id="POI_POLYGONS"
      data={{
        type: 'FeatureCollection',
        features: polygons.map((i) => ({ ...i.geo, properties: { color: '#a8b3bf' } })),
      }}
      opacity={0.5}
      borderLineWidth={3}
      borderLineOpacity={0.9}
    />
  );
};
