import React from 'react';

export const PDFIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_8748_12028)">
        <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.1" shapeRendering="crispEdges" />
        <path
          d="M26.875 15.5H17.125C16.2312 15.5 15.5 16.2312 15.5 17.125V26.875C15.5 27.7688 16.2312 28.5 17.125 28.5H26.875C27.7687 28.5 28.5 27.7688 28.5 26.875V17.125C28.5 16.2312 27.7687 15.5 26.875 15.5ZM27.1071 27.1071H16.8929V16.8929H27.1071V27.1071ZM23.625 23.625V21.1875C23.625 20.7406 23.2594 20.375 22.8125 20.375H21.1875V24.4375H22.8125C23.2594 24.4375 23.625 24.0719 23.625 23.625ZM22 21.1875H22.8125V23.625H22V21.1875ZM25.25 22.8125H26.0625V22H25.25V21.1875H26.0625V20.375H24.4375V24.4375H25.25V22.8125ZM18.75 22.8125H19.5625C20.0094 22.8125 20.375 22.4469 20.375 22V21.1875C20.375 20.7406 20.0094 20.375 19.5625 20.375H17.9375V24.4375H18.75V22.8125ZM18.75 21.1875H19.5625V22H18.75V21.1875Z"
          fill="white"
          style={{ transform: 'translate(-10px,-9px)' }}
        />
      </g>
      <defs>
        <filter
          id="filter0_b_8748_12028"
          x="-15"
          y="-15"
          width="54"
          height="54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8748_12028" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8748_12028" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
