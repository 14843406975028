import { getFitBoundsFromGeo } from '../../../common/maps/mapBase/utils';
import { Padding } from '@math.gl/web-mercator/src/fit-bounds';

export const getViewport = (
  geo: any,
  offsetWidth: number,
  offsetHeight: number,
  bboxPadding: Padding | number = 100,
) => {
  const options = {
    padding: bboxPadding,
  };
  return getFitBoundsFromGeo(offsetWidth, offsetHeight, geo, options);
};
