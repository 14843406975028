import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { MainContext } from '../../../common/types/mainContext';
import MapScale from '../../../common/maps/scale/mapScale';
import {
  CatchmentLocationsCountEnum,
  CatchmentMetricTypeEnum,
  CatchmentSocioDemoMetricEnum,
  CatchmentTypeEnum,
  ColorSchemeEnum,
} from '../../../common/reportItems/types';
import { toFixNumber } from '../../../common/lib';
import { exportSettingsModel } from '../../../common/export';
import { SwitchModes } from '../../../common/types/visualizationObjects';

export const CatchmentMapScale: React.FC = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReportItem },
    reportItemsDetails: {
      catchmentMetricType,
      catchmentType,
      colorScheme,
      catchmentLocationsCount,
      catchmentSocioDemoMetric,
    },
    primaryLocationId,
    exportSelection,
    catchmentLayer,
  } = useContext(MainContext);

  const isExportMode = useStore(exportSettingsModel.$isExportMode);

  const reportItem = isExportMode ? exportSelection.selectedReportItem : selectedReportItem;

  const dataForPrimaryLocation = reportItem?.visualization?.indexPolygons?.find(
    (loc) => loc.location_id === primaryLocationId,
  );

  const rank: number[] = useMemo(() => {
    if (catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE) {
      const data =
        catchmentType === CatchmentTypeEnum.HOME ? dataForPrimaryLocation?.home : dataForPrimaryLocation?.work;
      if (data) {
        if (catchmentLayer === SwitchModes.POSTAL_CODES) {
          if (catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION) {
            return [data.destinationMinRank, data.destinationMaxRank];
          }
          if (catchmentMetricType === CatchmentMetricTypeEnum.SCORE) {
            return [data.scoreMinRank, data.scoreMaxRank];
          }
          // default: source - former visit_share
          return [data.sourceMinRank, data.sourceMaxRank];
        }
        if (catchmentLayer === SwitchModes.ISOCHRONES) {
          if (catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION) {
            return [data.isochroneDestinationMinRank, data.isochroneDestinationMaxRank];
          }
          if (catchmentMetricType === CatchmentMetricTypeEnum.SCORE) {
            return [data.isochroneScoreMinRank, data.isochroneScoreMaxRank];
          }
          // default: source - former visit_share
          return [data.isochroneSourceMinRank, data.isochroneSourceMaxRank];
        }
        if (catchmentLayer === SwitchModes.HEATMAP) {
          return [data.destinationMinRank, data.destinationMaxRank];
        }
      }
    } else if (catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE) {
      const data =
        catchmentType === CatchmentTypeEnum.HOME
          ? reportItem?.visualization?.multiLocationPolygons?.home
          : reportItem?.visualization?.multiLocationPolygons?.work;
      if (data) {
        if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.INHABITANTS) {
          return [data.inhabitantsMinRank, data.inhabitantsMaxRank];
        }
        if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLDS) {
          return [data.householdsMinRank, data.householdsMaxRank];
        }
        if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLD_INCOME) {
          return [data.averageIncomeMinRank, data.averageIncomeMaxRank];
        }
        if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.RETAIL_SPENDING) {
          return [data.purchasingPowerMinRank, data.purchasingPowerMaxRank];
        }
      }
    }
    return [0, 100];
  }, [
    catchmentType,
    catchmentMetricType,
    catchmentLayer,
    catchmentLocationsCount,
    catchmentSocioDemoMetric,
    reportItem?.visualization,
  ]);

  const scaleTitle: string = useMemo(() => {
    if (catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE) {
      if (catchmentLayer === SwitchModes.POSTAL_CODES || catchmentLayer === SwitchModes.ISOCHRONES) {
        if (catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION) {
          return t('catchment.enum.DESTINATION');
        }
        if (catchmentMetricType === CatchmentMetricTypeEnum.SCORE) {
          return t('catchment.enum.SCORE');
        }
        return t('catchment.enum.SOURCE');
      }
      if (catchmentLayer === SwitchModes.HEATMAP) {
        return t('catchment.enum.DESTINATION');
      }
    } else if (catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE) {
      if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.INHABITANTS) {
        return t('catchment.enum.INHABITANTS');
      }
      if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLDS) {
        return t('catchment.enum.HOUSEHOLDS');
      }
      if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLD_INCOME) {
        return t('catchment.enum.HOUSEHOLD_INCOME');
      }
      if (catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.RETAIL_SPENDING) {
        return t('catchment.enum.RETAIL_SPENDING');
      }
    }
    return t('catchment.enum.DESTINATION');
  }, [catchmentMetricType, catchmentLayer, catchmentLocationsCount, catchmentSocioDemoMetric, t]);

  return rank ? (
    <MapScale
      min={
        catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE &&
        catchmentMetricType !== CatchmentMetricTypeEnum.SCORE
          ? `${toFixNumber(rank[0], 2)}%`
          : `${toFixNumber(rank[0], 0)}`
      }
      max={
        catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE &&
        catchmentMetricType !== CatchmentMetricTypeEnum.SCORE
          ? `${toFixNumber(rank[1], 2)}%`
          : `${toFixNumber(rank[1], 0)}`
      }
      title={scaleTitle}
      isMonochrome={colorScheme === ColorSchemeEnum.MONOCHROME}
    />
  ) : null;
};
