import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { MainContext } from '../../../common/types/mainContext';
import MapScale from '../../../common/maps/scale/mapScale';
import { ColorSchemeEnum, MovementVisitorTypeEnum } from '../../../common/reportItems/types';
import { toFixNumber } from '../../../common/lib';
import { exportSettingsModel } from '../../../common/export';
import { Movement, ReportItemInfo } from '../../../common/types/projectsHierarchy';
import { getMinMaxValue } from '../heatmaps/movement/getMinMaxValue';
import { IReportCategory } from '../../../../types/report';

export const MovementMapScale: React.FC = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReport, selectedReportItem },
    reportItemsDetails: { colorScheme, movementType, movementPeriod },
    exportSelection,
  } = useContext(MainContext);

  const isExportMode = useStore(exportSettingsModel.$isExportMode);

  const reportItem = (
    isExportMode ? exportSelection.selectedReportItem : selectedReportItem
  ) as ReportItemInfo<Movement>;

  const minMax = useMemo(() => {
    const activeVisitor = movementType === MovementVisitorTypeEnum.VISITORS ? 'visitor' : 'resident_worker';
    const currentData =
      selectedReport?.category === IReportCategory.AOI_DEFAULT
        ? reportItem?.data?.data[0].data
        : reportItem?.data?.data[0].data.filter((item) =>
            movementType === MovementVisitorTypeEnum.RESIDENTS_WORKERS
              ? item.visitor_type === activeVisitor
              : item.visitation_period === movementPeriod.toLocaleLowerCase(),
          );
    return currentData ? getMinMaxValue(currentData) : null;
  }, [reportItem?.data, movementType, movementPeriod]);

  return minMax ? (
    <MapScale
      min={`${toFixNumber(minMax[0] * 100, 2)}%`}
      max={`${toFixNumber(minMax[1] * 100, 2)}%`}
      isMonochrome={colorScheme === ColorSchemeEnum.MONOCHROME}
    />
  ) : null;
};
