import {
  AppActions,
  CreatePoiErrorAction,
  CreatePoiSuccessAction,
  DeletePoisErrorAction,
  DeletePoisSuccessAction,
} from '../actions/actionTypes';

export type AddPoiResultType = 'success' | 'failure';
export type DeletePoiResultType = 'success' | 'failure';

export interface AddPoiResult {
  name: string;
  message: string;
  type: AddPoiResultType;
}

export interface ErrorPoiResult {
  id: string;
  reason: string;
}

export interface DeletePoisResult {
  message: string;
  type: DeletePoiResultType;
}

export interface PoiManagementState {
  addPoiResult: AddPoiResult;
  batchAddPoisResult: AddPoiResult[];
  deletePoisResult: DeletePoisResult;
}

const initialState: PoiManagementState = {
  addPoiResult: {
    name: '',
    message: '',
    type: 'success',
  },
  batchAddPoisResult: [],
  deletePoisResult: {
    message: '',
    type: 'success',
  },
};

export default function poiManagementReducer(state = initialState, action: AppActions) {
  switch (action.type) {
    case 'CREATE_POI_SUCCESS':
      if (action.payload.inBatch) {
        return {
          ...state,
          batchAddPoisResult: [...state.batchAddPoisResult, ...addPoiSuccessfulResultFromAction(action)],
        };
      }
      return {
        ...state,
        addPoiResult: addPoiSuccessfulResultFromAction(action),
      };

    case 'CREATE_POI_ERROR':
      if (action.payload.inBatch) {
        return {
          ...state,
          batchAddPoisResult: [...state.batchAddPoisResult, ...addPoiErrorResultFromAction(action)],
        };
      }
      return {
        ...state,
        addPoiResult: addPoiErrorResultFromAction(action),
      };

    case 'CREATE_CSV_POIS_ERROR':
      return {
        ...state,
        batchAddPoisResult: action.payload.result,
      };

    case 'RESET_BATCH_POI_UPLOAD_RESULTS':
      return {
        ...state,
        batchAddPoisResult: [],
      };
    case 'DELETE_POIS_SUCCESS':
      return {
        ...state,
        deletePoisResult: deletePoisSuccessfulResultFromAction(action),
      };
    case 'DELETE_POIS_ERROR':
      return {
        ...state,
        deletePoisResult: deletePoisErrorResultFromAction(action),
      };
    default:
      return state;
  }
}

function addPoiSuccessfulResultFromAction(action: CreatePoiSuccessAction) {
  return action.payload.poiNames.map(poi => ({
    name: poi,
    message: `POI ${poi} created`,
    type: 'success',
  }));
}

function addPoiErrorResultFromAction(action: CreatePoiErrorAction) {
  return action.payload.errors.map(poi => ({
    name: poi.id,
    message: poi.reason,
    type: 'failure',
  }));
}

function deletePoisSuccessfulResultFromAction(action: DeletePoisSuccessAction) {
  return {
    message: `POI(s) successfully deleted`,
    type: 'success',
  };
}

function deletePoisErrorResultFromAction(action: DeletePoisErrorAction) {
  return {
    message: action.payload.reason,
    type: 'failure',
  };
}
