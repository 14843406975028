import { TrendScopesEnum } from '../enum';
import { TrendModifiedData, TrendValue } from '../types';
import { TrendLocation } from '../../../common/types/projectsHierarchy';

const getValues = (scope: TrendScopesEnum, { allLocations, singleLocation, absoluteLocation }: TrendModifiedData) => {
  switch (scope) {
    case TrendScopesEnum.ALL_LOCATION:
      return typeof allLocations === 'number' ? Number(allLocations) : '-';
    case TrendScopesEnum.SINGLE_LOCATION:
      return typeof singleLocation === 'number' ? Number(singleLocation) : '-';
    default:
      return typeof absoluteLocation === 'number' ? Number(absoluteLocation) : '-';
  }
};

export const getNormalizedDataByScope = (scope: TrendScopesEnum, data: TrendLocation[] = []) =>
  data.map(
    ({ location_id, location_name, data }): TrendValue => ({
      location_id,
      location_name,
      data: data.map((locationData) => ({
        date: locationData.date,
        value: getValues(scope, locationData),
        confidence_level: Number(locationData.confidence_level),
        is_relative_shown: Boolean(locationData.is_relative_shown),
      })),
    }),
  );

export const getSingleLineDataByScope = ({ absoluteNumber }: any) => {
  return absoluteNumber;
};
