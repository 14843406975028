import React from 'react';
import { Circle } from '../circle';
import { CircleData } from '../interfaces';
import styles from './style.module.scss';
import { displayController, getControllerColor } from './utils';

interface Props {
  data: CircleData[];
}
export const BackgroundCircle: React.FC<Props> = ({ data }) => {
  const isSingle = data.length === 1;
  const options = {
    cx: 80,
    cy: 80,
    radius: 60,
    strokeWidth: 0.6,
    circumferenceAdjuster: 0,
    transformDeviations: [isSingle ? 0 : 1],
  };

  return (
    <div className={styles.circle}>
      <div className={`${styles.controllers} ${styles.top}`}>
        {displayController(data, 4) && <span style={{ background: getControllerColor(data, 4) }} />}
        {displayController(data, 1) && <span style={{ background: getControllerColor(data, 1) }} />}
      </div>
      <div className={styles.wrapper}>
        <Circle options={options} data={data} width="567px" height="567px" />
      </div>
      <div className={`${styles.controllers} ${styles.bottom}`}>
        {displayController(data, 3) && <span style={{ background: getControllerColor(data, 3) }} />}
        {displayController(data, 2) && <span style={{ background: getControllerColor(data, 2) }} />}
      </div>
    </div>
  );
};
