import { ReportItemType } from '../../../../common/reportItems/types';
import {
  Absorption,
  Attraction,
  AttractionData,
  CrossVisitation,
  ReportItemInfo,
  ReportItems,
} from '../../../../common/types/projectsHierarchy';
import { ReportItemsBriefData } from '../../../../reportItems/model/interfaces';
import { toFixNumber } from '../../../../common/lib';

interface MinimalPoint {
  longitude: number;
  latitude: number;
  location_id?: string;
}
const crossVisitationHandler = (
  reportItem: ReportItemInfo<CrossVisitation>,
  primaryLocationId: string | null,
  point: MinimalPoint,
) => {
  const { location_id } = point;
  if (reportItem.data) {
    const primaryLocation = reportItem.data.data.find(
      ({ primary_location_id }) => primary_location_id === primaryLocationId,
    );

    if (primaryLocation) {
      const secondaryLocation = primaryLocation?.data?.find(
        ({ secondary_location_id }) => secondary_location_id === location_id,
      );

      if (secondaryLocation) {
        const { aerial_distance, visitors_loyalty, ratio } = secondaryLocation;
        const data = {
          shareOfVisits: visitors_loyalty,
          visitRatio: ratio,
          distanceFromLocation: aerial_distance,
        };
        return data;
      }
    }
  }
};

const attractionHandler = (reportItem: ReportItemInfo<Attraction>, point: MinimalPoint, sourceLocationId?: string) => {
  const target = reportItem?.data?.data?.find((sourceLocation) => sourceLocation.location_id === sourceLocationId);

  if (target) {
    const location = target.data.find(({ targetLocationId }) => targetLocationId === point.location_id);

    if (!location) return null;

    return {
      visitsFromAOI: location ? toFixNumber(location.avgWeightedAttraction * 100, 1) : null,
    };
  }
};

const absorptionHandler = (reportItem: ReportItemInfo<Absorption>, point: MinimalPoint, sourceLocationId?: string) => {
  const target = reportItem?.data?.data?.find((sourceLocation) => sourceLocation.location_id === sourceLocationId);

  if (target) {
    const location = target.data.find(({ locationId }) => locationId === point.location_id);

    if (!location) return null;

    return {
      visitsFromAOI: toFixNumber(location.avgWeightedAbsorption * 100, 1),
    };
  }
};

const getPopupData = (
  popupReportItemsData: ReportItemsBriefData,
  reportItem: ReportItemInfo<ReportItems>,
  primaryLocationId: string,
  point: MinimalPoint,
  attractionSourceLocationId?: string,
  absorptionSourceLocationId?: string,
) => {
  switch (reportItem.type) {
    case ReportItemType.CROSS_VISITATION:
      return crossVisitationHandler(reportItem as ReportItemInfo<CrossVisitation>, primaryLocationId, point);
    case ReportItemType.ABSORPTION:
      return absorptionHandler(reportItem as ReportItemInfo<Absorption>, point, absorptionSourceLocationId);
    case ReportItemType.ATTRACTION:
      return attractionHandler(reportItem as ReportItemInfo<Attraction>, point, attractionSourceLocationId);
    default:
      return popupReportItemsData[reportItem.type];
  }
};

export const getPointClickHandlerAccordingReportItemType = <T extends MinimalPoint>(
  reportItem: ReportItemInfo<ReportItems>,
  primaryLocationName: string,
  primaryLocationId: string,
  { longitude, latitude, location_id }: T,
  popupReportItemsData: ReportItemsBriefData,
  attractionSourceLocationId?: string,
  absorptionSourceLocationId?: string,
) => {
  const point = { longitude, latitude, location_id };
  const centerPoint = [longitude, latitude];

  const popupData = getPopupData(
    popupReportItemsData,
    reportItem,
    primaryLocationId,
    point,
    attractionSourceLocationId,
    absorptionSourceLocationId,
  );

  const data = {
    title: primaryLocationName,
    coordinates: centerPoint,
    data: popupData,
  };

  if (!data.data) return null;

  return data;
};
