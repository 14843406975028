import React from 'react';
import { NavLink } from 'react-router-dom';
import { BaseModal } from '../../baseModal';
import { Routes } from '../../../routes';
import styles from './style.module.scss';

interface Props {}
export const SuccessCreatedNewPassword: React.FC<Props> = () => (
  <BaseModal subtitle="Your password was updated successfully. Click enter to login." title="Thank you">
    <NavLink to={Routes.dashboard} className={styles.button}>
      Enter
    </NavLink>
  </BaseModal>
);
