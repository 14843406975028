import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from '../../../common/popup';
import styles from './gdprForm.module.scss';
import { Button } from '../../../common/controls/buttons';
import { Checkbox } from '../../../common/controls/checkbox';

interface Props {
  isOpen: boolean;
  confirm: () => void;
}

export const GDPRForm = ({ isOpen, confirm }: Props) => {
  const { t } = useTranslation();
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
  };

  const getGroupPart = () => {
    const content = [];
    for (let i = 2; i <= 8; i++) {
      content.push(<li>{t(`create.stepTwo.gdpr.paragraph${i}`)}</li>);
    }
    return content;
  };

  return (
    <Popup isOpen={isOpen} onClose={() => {}} height={700}>
      <h3 className={styles.title}>{t('create.stepTwo.gdpr.title')}</h3>
      <p className={styles.paragraph}>{t('create.stepTwo.gdpr.paragraph1')}</p>
      <ul className={styles.gdprList}>{getGroupPart()}</ul>
      <p className={styles.paragraph}>{t('create.stepTwo.gdpr.paragraph9')}</p>
      <p className={styles.paragraph}>
        {t('create.stepTwo.gdpr.paragraph10')}{' '}
        <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj" target="_blank" rel="noreferrer">
          {t('create.stepTwo.gdpr.paragraph11')}
        </a>
      </p>
      <p className={`${styles.paragraph} ${styles.confirmParagraph}`}>
        <Checkbox onChange={changeHandler} isChecked={confirmed} id="confirmGDPR" name="confirmGDPR" />
        {t('create.stepTwo.gdpr.paragraph12')}
      </p>
      <br />
      <p className={styles.paragraph}>
        {t('create.stepTwo.gdpr.paragraph13')} <a href="mailto:cs@placesense.ai">cs@placesense.ai</a>
      </p>
      <div className={styles.btnContainer}>
        <Button layout="primary" onClick={confirm} disabled={!confirmed}>
          {t('create.stepTwo.gdpr.continue')}
        </Button>
      </div>
    </Popup>
  );
};
