import React, { useContext } from 'react';
import { MainContext } from '../../../common/types/mainContext';
import CenterPin from '../../../common/maps/pin/centerPin';

interface Props {
  showName?: boolean;
  pointSize?: 'small' | 'big';
}

export const MultiCenterPoint = ({ showName, pointSize = 'small' }: Props) => {
  const { selectedLocations } = useContext(MainContext);

  return (
    <>
      {selectedLocations
        .filter((loc) => loc.longitude && loc.latitude)
        .map((location) => (
          <CenterPin
            key={location.id}
            longitude={location.longitude || 0}
            latitude={location.latitude || 0}
            name={location.name}
            showName={showName}
            pointSize={pointSize}
          />
        ))}
    </>
  );
};
