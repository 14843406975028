import { request } from '../request';
import { Credentials } from '../../auth/signUp/registration/interfaces';
import { API_URLS } from '../../routes';

interface Response {
  redirectProjectId: any;
  redirectReportId: any;
  redirectReportItemId: any;
  redirectReportItemName: any;
  token: string;
}

export const signUp = (data: Partial<Credentials>) => {
  const url = API_URLS.SIGN_UP;
  return request<Response>({ url, data, method: 'POST' });
};
