export enum UserRole {
  PLACENSE_ADMIN = 'PLACENSE_ADMIN',
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  REGULAR_USER = 'REGULAR_USER',
  PLACENSE_USER = 'PLACENSE_USER',
  ACCOUNT_BETA_ADMIN = 'ACCOUNT_BETA_ADMIN',
}

export const userRoleTranslationMap = new Map();
userRoleTranslationMap.set(UserRole.REGULAR_USER, 'regular user');
userRoleTranslationMap.set(UserRole.ACCOUNT_ADMIN, 'account admin');
userRoleTranslationMap.set(UserRole.ACCOUNT_BETA_ADMIN, 'account BETA admin (experimental)');
userRoleTranslationMap.set(UserRole.PLACENSE_USER, 'placense user');
userRoleTranslationMap.set(UserRole.PLACENSE_ADMIN, 'placense admin');

export default UserRole;
