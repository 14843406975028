import { useRef, useState } from 'react';
import EChartsReact from 'echarts-for-react';
import { LegendSelectChangedParams } from '../interfaces';

export const useDoubleClick = () => {
  const isClickedRef = useRef(false);
  const executeDoubleClick = useRef<any | null>(null);
  const chartRef = useRef<EChartsReact>(null);
  const [locations, setLocations] = useState<Record<string, boolean>>({});

  const onDoubleClick = (selected: Record<string, boolean>, clickedName: string) => {
    const selectOnlyClicked = Object.fromEntries(
      Object.entries(selected).map(([location, value]) =>
        location === clickedName ? [location, true] : [location, false],
      ),
    );
    setLocations(selectOnlyClicked);
  };

  const onChange = (e: LegendSelectChangedParams) => {
    if (isClickedRef.current) {
      onDoubleClick(e.selected, e.name);
      isClickedRef.current = false;
      if (executeDoubleClick.current) clearTimeout(executeDoubleClick.current);
      return locations;
    }

    executeDoubleClick.current = setTimeout(() => {
      isClickedRef.current = false;
      setLocations(e.selected);
    }, 200);

    if (chartRef.current) {
      const chart = chartRef.current.getEchartsInstance();
      chart.setOption({ animation: false });
      chart.dispatchAction({
        type: 'legendSelect',
        name: e.name,
      });
      chart.setOption({ animation: true });
    }

    isClickedRef.current = true;
  };

  return { onChange, chartRef, selectedLocations: locations };
};
