import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../common/tooltip';
import { TooltipPositionCoordinates } from '../../../../../common/reportItems/hooks';

interface Props {
  onClose: VoidFunction;
  isOpen: boolean;
  position?: TooltipPositionCoordinates;
}
export const MovementMainTooltip: React.FC<Props> = ({ onClose, position, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      onClose={onClose}
      title={t('reportItem.howItWorks')}
      initialPosition={position}
      isOpen={isOpen}
      height={200}
    >
      <div>
        <p>
          {t('movement.tooltip.paragraph1')}
          <br />
          {t('movement.tooltip.paragraph2')}
        </p>
      </div>
    </Tooltip>
  );
};
