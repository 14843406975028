import * as GeoJSON from 'geojson';
import { parse } from 'wellknown';

export const convertPolygonToGeoData = (polygon: string): GeoJSON.Feature<GeoJSON.Geometry> | null => {
  const geojson = parse(polygon);
  return {
    type: 'Feature',
    geometry: geojson,
  } as GeoJSON.Feature<GeoJSON.Geometry>;
};
