import React, { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import styles from './buttonTooltip.module.scss';

interface TProps {
  title?: string;
  children: ReactElement;
  placement?: 'top' | 'left' | 'right' | 'bottom';
}

export const ButtonTooltip = ({ title, children, placement }: TProps) =>
  title ? (
    <Tooltip title={title} arrow classes={{ popper: styles.buttonTooltip }} placement={placement}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
