import React, { useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import styles from './menu.module.scss';
import MonthCheckbox from './MonthCheckbox';
import { months } from '../lib/data';
import YearIntervalPicker from './YearIntervalPicker';
import { Button } from '../../controls/buttons';
import { TimeFrames } from '../types';
import { getYearsBetweenDates } from '../lib/getYearsBetweenDates';
import { useWindowSize } from '../../hooks';
import { Checkbox } from '../../controls/checkbox';

interface Props {
  timeFrames: TimeFrames;
  reportStartDate: Moment;
  reportEndDate: Moment;
  onClose: () => void;
  onSave: (item: TimeFrames) => void;
  startDate: Moment;
  setStartDate: (year: Moment) => void;
  endDate: Moment;
  setEndDate: (year: Moment) => void;
  minYear: number;
  maxYear: number;
  lastUpdated?: Moment;
}

const BREAK_POINT_HEIGHT = 690;
const Menu: React.FC<Props> = ({
  reportStartDate,
  reportEndDate,
  onClose,
  onSave,
  timeFrames,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  minYear,
  maxYear,
  lastUpdated,
}) => {
  const [selectedTimeFrames, setSelectedTimeFrames] = useState<TimeFrames>(timeFrames);
  const { height } = useWindowSize();

  const isMonthDisabled = (yearMonth: string): boolean => {
    const date = moment(`${yearMonth}-01`);
    return date.isBefore(reportStartDate, 'month') || date.isAfter(reportEndDate, 'month');
  };

  const isChecked = (value: string): boolean => selectedTimeFrames[value];

  const onChangeHandler = (changedTimeFrame: string, checked: boolean) => {
    setSelectedTimeFrames((prevState) => ({
      ...prevState,
      [changedTimeFrame]: checked,
    }));
  };

  const onChangeYearHandler = (year: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = Array.from(Array(12).keys())
      .map((month) => month + 1)
      .map((month) => (month < 10 ? `0${month}` : `${month}`))
      .filter((month) => !isMonthDisabled(`${year}-${month}`))
      .map((month) => `${year}-${month}`)
      .reduce(
        (previousObject, currentObject) => Object.assign(previousObject, { [currentObject]: event.target.checked }),
        {},
      );
    setSelectedTimeFrames((prevState) => ({
      ...Object.assign(prevState, newState),
    }));
  };

  const onSaveHandler = () => {
    onClose();
    onSave(selectedTimeFrames);
  };

  const getCheckedMonthsForYear = (year: number): number =>
    Object.keys(selectedTimeFrames)
      .filter((timeFrame) => timeFrame.startsWith(`${year}-`))
      .filter((timeFrame) => selectedTimeFrames[timeFrame]).length;

  const isYearPartiallyChecked = (year: number): boolean => {
    const interestedYear = Object.keys(selectedTimeFrames).filter((timeFrame) => timeFrame.startsWith(`${year}-`));
    const checkedMonths = interestedYear.filter((item) => selectedTimeFrames[item]);
    return checkedMonths.length > 0 && checkedMonths.length !== interestedYear.length;
  };

  const containerHeight = useMemo(() => {
    if (height) {
      if (height <= BREAK_POINT_HEIGHT) {
        return height - 300;
      }
      return 385;
    }
    return 385;
  }, [height]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h3>Calendar</h3>
        <div className={styles.subtitle}>
          <span>Time range is on a monthly resolution</span>
          {lastUpdated && <span>Last updated: {lastUpdated.format('MMM DD, YYYY')}</span>}
        </div>
      </div>
      <YearIntervalPicker
        reportStartDate={reportStartDate}
        reportEndDate={reportEndDate}
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={setStartDate}
        onChangeEndDate={setEndDate}
        minYear={minYear}
        maxYear={maxYear}
      />
      <div className={`custom-scrollbar ${styles.yearsContainer}`} style={{ height: `${containerHeight}px` }}>
        {getYearsBetweenDates(startDate, endDate).map((year) => (
          <div key={year} className={styles.datesContainer}>
            <div className={`${styles.yearContainer} ${isYearPartiallyChecked(year) ? styles.disabled : ''}`}>
              <label htmlFor={`${year}`}>{year}</label>
              <Checkbox
                onChange={(e) => onChangeYearHandler(year, e)}
                isChecked={getCheckedMonthsForYear(year) > 0}
                name={'select-all'}
              />
            </div>
            {months.map(({ id, label, month }) => (
              <MonthCheckbox
                key={id}
                id={id}
                name={id}
                label={label}
                value={`${year}-${month}`}
                disabled={isMonthDisabled(`${year}-${month}`)}
                onChange={onChangeHandler}
                checked={isChecked(`${year}-${month}`)}
              />
            ))}
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={onClose}>Cancel</Button>
        <Button layout="primary" onClick={onSaveHandler} animation>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Menu;
