import React, { useEffect, useRef, useState } from 'react';
import { Range, getTrackBackground, Direction } from 'react-range';
import { toFixNumber } from '../../lib';
import { useClickOutside } from '../../hooks';
import SliderIcon from '../../icons/controls/slider';
import styles from './style.module.scss';

interface Props {
  onChange: (threshold: number) => void;
  initialValue: number;
  minValue?: number;
  maxValue: number;
  disabled?: boolean;
}

export const Slider: React.FC<Props> = ({ onChange, initialValue, minValue, maxValue, disabled = false }) => {
  const [values, setValues] = useState<number[]>([initialValue]);
  const [inputValue, setInputValue] = useState<number>(initialValue);
  const [isOverInput, setIsOverInput] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);
  const min = minValue || 0;
  const max = maxValue;

  useEffect(() => {
    const value = initialValue;
    setValues([value]);
    setInputValue(toFixNumber(value, 0));
  }, [initialValue]);

  const onConfirmInputChange = () => {
    ref?.current?.blur();
    if (isEdit) {
      let newValue = Number(inputValue.toFixed(0));
      if (Number.isNaN(newValue)) {
        newValue = initialValue;
      } else if (newValue < min) {
        newValue = min;
      } else if (newValue > max) {
        newValue = max;
      }
      setValues([newValue]);
      setInputValue(toFixNumber(newValue, 0));
      onChange(newValue);
    }
    setIsEdit(false);
  };

  useClickOutside(ref, onConfirmInputChange, true);

  const onThumbFinalChangeHandler = (selectedValues: number[]) => {
    if (!isOverInput && !isEdit) {
      onChange(selectedValues[0]);
    }
  };

  const onThumbChangeHandler = (selectedValues: number[]) => {
    if (!isOverInput && !isEdit) {
      setValues(selectedValues);
      setInputValue(toFixNumber(selectedValues[0], 0));
    }
  };

  const onInputClickHandler = () => {
    ref?.current?.focus();
    setIsEdit(true);
  };

  const onInputMouseOverHandler = () => {
    setIsOverInput(true);
  };

  const onInputMouseOutHandler = () => {
    setIsOverInput(false);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(toFixNumber(event.target.valueAsNumber, 0));
  };

  const onInputKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onConfirmInputChange();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.sliderContainer}>
        <span>{min}</span>
        <Range
          step={1}
          min={min}
          max={max}
          values={values}
          onChange={onThumbChangeHandler}
          disabled={disabled}
          onFinalChange={onThumbFinalChangeHandler}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
                minWidth: '100px',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '10px',
                  width: '100%',
                  background: getTrackBackground({
                    values,
                    colors: [disabled ? 'rgba(255, 255, 255, 0.65)' : '#00B2F1', 'rgba(255, 255, 255, 0.15)'],
                    min,
                    max,
                  }),
                  alignSelf: 'center',
                  borderRadius: '15px',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '10px',
                width: '0px',
                backgroundColor: '#00434B',
                position: 'relative',
                content: '',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {!disabled && (
                <span className={styles.sliderIconContainer}>
                  <SliderIcon />
                </span>
              )}
              <input
                ref={ref}
                className={`${styles.sliderValue} ${isEdit ? '' : styles.disabled}`}
                type={isEdit ? 'number' : 'string'}
                maxLength={6}
                max={max}
                min={min}
                size={6}
                value={isEdit ? Number(inputValue.toFixed(0)) : `${parseFloat(`${inputValue.toFixed(0)}`)}`}
                onChange={onInputChange}
                onClick={onInputClickHandler}
                onMouseOver={onInputMouseOverHandler}
                onFocus={onInputMouseOverHandler}
                onBlur={onInputMouseOutHandler}
                onMouseOut={onInputMouseOutHandler}
                onKeyUp={onInputKeyUpHandler}
                disabled={disabled}
              />
            </div>
          )}
        />
        <span>{max}</span>
      </div>
    </div>
  );
};
