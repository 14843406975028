import { RefObject, useEffect } from 'react';

type Callback = (e?: HTMLElement | null) => void;
export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  callback: Callback,
  isActive: boolean,
) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(<Node>e.target) && isActive) {
      callback(e.target as HTMLElement);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
