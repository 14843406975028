import React from 'react';

export const CollapseIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6666 1.2735L10.1399 4.80016L12.3333 7.00016H6.99992V1.66683L9.19325 3.86016L12.7266 0.333496L13.6666 1.2735ZM1.27325 13.6668L4.79992 10.1402L6.99992 12.3335V7.00016H1.66659L3.85992 9.1935L0.333252 12.7268L1.27325 13.6668Z"
      fill="white"
    />
  </svg>
);
