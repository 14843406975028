import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { SwitchToggle } from '../../../../common/controls/toggle';
import {
  ColorSchemeEnum,
  DataLevelEnum,
  LocationTypeEnum,
  OverlayHotspotsEnum,
  ReportItemType,
} from '../../../../common/reportItems/types';
import { MainContext } from '../../../../common/types/mainContext';
import { Button } from '../../../../common/controls/buttons';
import { useClickOutside, useURLParams } from '../../../../common/hooks';
import { usePopupPositions } from '../../../../common/reportItems/hooks';
import { ThresholdFilter } from '../../../../common/controls';
import { trackUserAction, UserActionsEnum } from '../../../../../mixpanel';

interface Props {
  onClose: (value?: boolean) => void;
  onOpenTooltip: VoidFunction;
  isOpenedMenu: boolean;
  maxVisitShare?: number;
  minVisitShare?: number;
  hasHotspots?: boolean;
}
export const Menu = ({
  onClose,
  isOpenedMenu,
  maxVisitShare = 1,
  minVisitShare = 0,
  onOpenTooltip,
  hasHotspots,
}: Props) => {
  const { t } = useTranslation();
  const { reportItemsDetails, updateReportItemsDetailsValue } = useContext(MainContext);
  const [colorScheme, setColorScheme] = useState<ColorSchemeEnum>(reportItemsDetails.colorScheme);
  const [dataLevel, setDataLevel] = useState<DataLevelEnum>(reportItemsDetails.dataLevel);
  const [locationType, setLocationType] = useState<LocationTypeEnum>(reportItemsDetails.locationType);
  const [hotspotOverlay, setHotspotOverlay] = useState<OverlayHotspotsEnum>(reportItemsDetails.hotspotOverlay);
  const [threshold, setThreshold] = useState<{ min: number; max: number }>(reportItemsDetails.movementTreshold);
  const { onChangeParams } = useURLParams();

  useEffect(() => {
    setThreshold(reportItemsDetails.movementTreshold);
  }, [reportItemsDetails.movementTreshold]);

  const onSave = () => {
    if (reportItemsDetails.colorScheme !== colorScheme) {
      trackUserAction(
        'Movement color scheme changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Color scheme: ${colorScheme}`,
      );
      updateReportItemsDetailsValue('colorScheme', colorScheme);
      onChangeParams('colorScheme', colorScheme);
    }
    if (reportItemsDetails.dataLevel !== dataLevel) {
      trackUserAction(
        'Movement data type changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Data Type: ${dataLevel}`,
      );
      updateReportItemsDetailsValue('dataLevel', dataLevel);
      onChangeParams('dataLevel', dataLevel);
    }
    if (reportItemsDetails.locationType !== locationType) {
      trackUserAction(
        'Movement location type changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Location Type: ${locationType}`,
      );
      updateReportItemsDetailsValue('locationType', locationType);
      onChangeParams('locationType', locationType);
    }
    if (
      reportItemsDetails.movementTreshold.min !== threshold.min ||
      reportItemsDetails.movementTreshold.max !== threshold.max
    ) {
      trackUserAction(
        'Movement hotspots threshold changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Min: ${threshold.min}, max: ${threshold.max}`,
      );
      updateReportItemsDetailsValue('movementTreshold', threshold);
      onChangeParams('movementThresholdMin', threshold.min);
      onChangeParams('movementThresholdMax', threshold.max);
    }
    if (reportItemsDetails.hotspotOverlay !== hotspotOverlay) {
      trackUserAction(
        'Movement hotspots overlay changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `New value: ${hotspotOverlay}`,
      );
      updateReportItemsDetailsValue('hotspotOverlay', hotspotOverlay);
      onChangeParams('hotspotOverlay', hotspotOverlay);
    }
    onMenuClose();
  };

  const onMenuClose = () => {
    onClose(false);
  };

  const { ref } = usePopupPositions(`menu-${ReportItemType.MOVEMENT}`, onMenuClose, 230, 24, 450);
  useClickOutside(ref, onMenuClose, isOpenedMenu);
  return (
    <div className={`${styles.menu} ${hasHotspots ? styles.full : styles.part}`} ref={ref}>
      {isOpenedMenu && (
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>{t('reportItem.settings')}</h3>
          </div>
          <div className={styles.rows}>
            {hasHotspots && (
              <div className={styles.item}>
                <div className={styles.row}>
                  <div className={styles.label}>{t('movement.card.hotspotsThreshold')}</div>
                  <div className={styles.content}>
                    <ThresholdFilter
                      initialValue={threshold}
                      maxValue={maxVisitShare}
                      minValue={minVisitShare}
                      onChange={setThreshold}
                      percentage
                      unitSymbol="%"
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>{t('movement.card.dataLevel')}</div>
                  <div className={styles.content}>
                    <SwitchToggle
                      leftLabelSize="61px"
                      leftLabel={t('movement.card.category')}
                      leftValue={DataLevelEnum.CATEGORY}
                      rightLabel={t('movement.card.location')}
                      rightValue={DataLevelEnum.LOCATION}
                      selectedValue={dataLevel}
                      onClick={(value) => setDataLevel(value as DataLevelEnum)}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>{t('movement.card.locationType')}</div>
                  <div className={styles.content}>
                    <SwitchToggle
                      leftLabelSize="61px"
                      leftLabel={t('movement.card.buildings')}
                      leftValue={LocationTypeEnum.BUILDINGS}
                      rightLabel={t('movement.card.pois')}
                      rightValue={LocationTypeEnum.POIS}
                      selectedValue={locationType}
                      onClick={(value) => setLocationType(value as LocationTypeEnum)}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={styles.item}>
              <div className={styles.row}>
                <div className={styles.label}>{t('catchment.card.mapPalette')}</div>
                <div className={styles.content}>
                  <SwitchToggle
                    leftLabelSize="61px"
                    leftLabel={t('catchment.card.color')}
                    leftValue={ColorSchemeEnum.COLORFUL}
                    rightLabel={t('catchment.card.monochrome')}
                    rightValue={ColorSchemeEnum.MONOCHROME}
                    selectedValue={colorScheme}
                    onClick={(value) => setColorScheme(value as ColorSchemeEnum)}
                  />
                </div>
              </div>
              {hasHotspots && (
                <div className={styles.row}>
                  <div className={styles.label}>{t('movement.card.overlayHotspots')}</div>
                  <div className={styles.content}>
                    <SwitchToggle
                      leftLabelSize="61px"
                      leftLabel={t('reportItem.yes')}
                      leftValue={OverlayHotspotsEnum.YES}
                      rightLabel={t('reportItem.no')}
                      rightValue={OverlayHotspotsEnum.NO}
                      selectedValue={hotspotOverlay}
                      onClick={(value) => setHotspotOverlay(value as OverlayHotspotsEnum)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttons}>
            <Button onClick={onMenuClose}>{t('reportItem.cancel')}</Button>
            <Button layout="primary" onClick={onSave} animation>
              {t('reportItem.save')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
