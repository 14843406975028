import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Marker } from 'react-map-gl';
import { MapViewPort } from '../../maps/mapBase/mapBase';
import { EditorHandlers, PolygonEditor } from '../editor';
import { SearchLocation, Place } from '../searchLocation';
import { SimpleMap } from '../../maps/mapBase';
import { getFitBounds } from '../../maps/mapBase/utils';
import { LocationAddedIcon } from '../../icons/map/locationAddedIcon';
import styles from './style.module.scss';
import { useDebounce } from '../../hooks';
import { EditorActions } from '../editorActions';
import {
  addNumbersToPolygons,
  parseCoordinatesToPolygon,
  transformCoordinatesToPolygons,
  validateCoordinates,
} from './utils';
import { Navigation } from '../../maps/navigation/navigation';
import { PolygonData } from '../../manageLocations/createLocation/interfaces';
import { getCenterCoordinateOfGeoPolygon } from '../../maps/utils/getCenterCoordinateOfGeoPolygon';

interface Props {
  onChangePolygon: (data: string) => void;
  coordinates: string;
  editedLocation: PolygonData | null;
}

export const PolygonTool: React.FC<Props> = ({ onChangePolygon, coordinates, editedLocation }) => {
  const [marker, setMarker] = useState<number[]>();
  const [viewport, setViewport] = useState<MapViewPort | null>({ zoom: 0, latitude: 0, longitude: 0 });
  const [polygon, setPolygon] = useState<GeoJSON.Feature<GeoJSON.Polygon>[]>([]);
  const [history, setHistory] = useState<GeoJSON.Feature<GeoJSON.Polygon>[][]>([]);
  const [isFirstRun, setFirstRun] = useState<boolean>(true);
  const saveHistoryRef = useRef(false);
  const debouncedPolygon = useDebounce(polygon, 1000);
  const mapRef = useRef<HTMLDivElement>(null);
  const editorHandlers = useRef<EditorHandlers>({ drawMode: () => {} });
  const isChangePolygonOutsideHandlerAvailable = useRef(false);

  const onSetPolygon = useCallback(
    (polygon: GeoJSON.Feature<GeoJSON.Polygon>[]) => {
      isChangePolygonOutsideHandlerAvailable.current = true;
      setPolygon(polygon);
      saveHistoryRef.current = true;
    },
    [setPolygon],
  );

  useEffect(() => {
    if (!isFirstRun) {
      if (coordinates.length) {
        isChangePolygonOutsideHandlerAvailable.current = false;
        const parsedCoordinates = coordinates.split('\n');
        const isPolygonsValid = validateCoordinates(parsedCoordinates);
        if (!isPolygonsValid) return;
        const polygons = transformCoordinatesToPolygons(parsedCoordinates);
        setPolygon(addNumbersToPolygons(polygons));
      } else {
        setPolygon([]);
      }
    }
  }, [coordinates]);

  useEffect(() => {
    if (editedLocation && editedLocation.polygonString.length) {
      try {
        const parsedCoordinates = editedLocation.polygonString.split('\n');
        const polygons = transformCoordinatesToPolygons(parsedCoordinates);
        const centerPoint = getCenterCoordinateOfGeoPolygon(polygons[0]);
        setViewport({ zoom: 17, longitude: centerPoint[0], latitude: centerPoint[1] });
        setPolygon(addNumbersToPolygons(polygons));
      } catch (error) {
        const polygon = parseCoordinatesToPolygon(
          // @ts-ignore
          editedLocation?.polygon?.points?.map((point) => [point.longitude, point.latitude]).flat(1) || [],
        );
        if (polygon) {
          const centerPoint = getCenterCoordinateOfGeoPolygon(polygon);
          setViewport({ zoom: 17, longitude: centerPoint[0], latitude: centerPoint[1] });
          setPolygon(addNumbersToPolygons([polygon]));
        }
      }
    }
    setFirstRun(false);
  }, [editedLocation]);

  useEffect(() => {
    if (debouncedPolygon && saveHistoryRef.current) setHistory((preHistory) => [debouncedPolygon, ...preHistory]);
    if (debouncedPolygon && debouncedPolygon.length > 0) {
      const polygons = debouncedPolygon.reduce(
        (acc, polygon, index) =>
          `${acc}${index === 0 ? '' : '\n\n'}${polygon.geometry.coordinates[0]
            .map((item) => item.join(',\n'))
            .join(',\n')}`,
        '',
      );
      onChangePolygon(polygons);
    } else onChangePolygon('');
  }, [debouncedPolygon]);

  const onSelectPlace = (data: Place) => {
    const {
      geometry: { viewport, location },
    } = data;
    const { southwest, northeast } = viewport;
    setMarker([location.lng, location.lat]);

    if (mapRef.current) {
      const { offsetWidth, offsetHeight } = mapRef.current;
      const box = getFitBounds(offsetWidth, offsetHeight, southwest.lng, southwest.lat, northeast.lng, northeast.lat);
      setViewport(box);
    }
  };

  const onUndo = () => {
    if (!(polygon.length === 0 && history.length === 0)) {
      saveHistoryRef.current = false;
      const [undo, ...newHistory] = history;
      const result = undo || [];
      setPolygon(addNumbersToPolygons(result) || []);
      setHistory(newHistory);
    }
  };

  const onCreateMode = () => {
    editorHandlers.current.drawMode();
  };

  return (
    <div className={styles.map} ref={mapRef}>
      <div className={styles.search}>
        <SearchLocation onSelectPlace={onSelectPlace} />
      </div>
      <div className={styles.actions}>
        <EditorActions onUndo={onUndo} onCreateMode={onCreateMode} />
      </div>
      <SimpleMap initialViewport={viewport} mapStyle="main">
        <div className={styles.navigation}>
          <Navigation showCompass={false} rounded />
        </div>
        {marker && (
          <Marker longitude={marker[0]} latitude={marker[1]}>
            <LocationAddedIcon />
          </Marker>
        )}
        <PolygonEditor
          polygon={polygon}
          setPolygon={onSetPolygon}
          styles={{ borderRadius: '5px 0 0 5px', border: '1px solid transparent' }}
          ref={editorHandlers}
        />
      </SimpleMap>
    </div>
  );
};
