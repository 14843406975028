export interface Report {
  id?: string;
  date?: string;
  name: string;
  startDay: string;
  endDay: string;
  description: string;
  reportCategory: string;
  visualizationURL?: string;
  locations: string[];
  reportItems: ReportItem[];
  project_id?: string;
  project_name?: string;
  status?: string;
  recurring: boolean;
  last_updated?: string;
  recurrence_frequency?: string;
  recurrentNotificationEmail?: string;
  rerunStatus?: string;
}

export interface ReportItem {
  id?: string;
  status?: ReportItemStatus;
  date?: string;
  name: string;
  description: string;
  reportItemType: string;
  settings: ReportItemSetting[];
  visualizationUrl?: string;
  reportId?: string;
}

export interface ReportItemSetting {
  name: string;
  value: string;
}

export interface PostProcessingSetting{
  name: string;
  description: string;
  value: string;
  analytics_name: string;
  settings_type: string;
  possibleValues?: string[];
  order: number;
  status?: PostProcessingStatus;
}

export type PostProcessingStatus = 'processing' | 'completed' | 'error';

// TODO use those
export type PostProcessingSettingType = 'simpleText' | 'singleChoice' | 'adjustableText' | 'multipleChoice';

export type ReportItemStatus = 'initiated' | 'processing' | 'ready' | 'confirmed' | 'error';

export enum IReportCategory {
  AOI_DEFAULT = 'AOI_DEFAULT',
  POI_DEFAULT = 'POI_DEFAULT',
  OOH = 'OOH',
  FFI = 'FFI',
}


