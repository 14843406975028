import React from 'react';
import { useLocation } from 'react-router-dom';
import { IntroPageTemplate } from '../../common/templates';
import { SuccessEmailSent } from '../../auth';
import { RedirectAuthedUser } from '../../processes';

export const ForgotPasswordEmailSentPage = () => {
  const { state } = useLocation<{ email: string }>();

  return (
    <RedirectAuthedUser>
      <IntroPageTemplate>
        <SuccessEmailSent email={state?.email} />
      </IntroPageTemplate>
    </RedirectAuthedUser>
  );
};
