import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../templates/page/page';
import styles from './accountsPage.module.css';
import Button from '../../atoms/primaryButton/button';
import routes from '../../../router/routes';
import { AppState } from '../../../reducers/reducers';
import { AuthState } from '../../../reducers/authReducer';
import { fetchAccounts } from '../../../actions/actions';
import ErrorMessage from '../../organisms/errorMessage';

const normalizeString = (value: string): string => value || '-';

const AccountsPage = (): ReactElement => {
  const { accounts } = useSelector<AppState, AuthState>((state) => state.auth);
  const history = useHistory();
  const addAccountHandler = () => history.push(routes.accounts.add);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccounts());
  }, []);

  return (
    <Page title="Accounts">
      <ErrorMessage />
      <div className={styles.header}>
        <Button type="submit" onClick={addAccountHandler}>
          Create Account
        </Button>
      </div>
      <div>
        {!!accounts && accounts.length > 0 && (
          <table className={styles.accountsTable}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Account name</th>
                <th>Description</th>
                <th>Type</th>
                <th>Status</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {accounts
                .sort((a, b) => (a.name + a.status).toLowerCase().localeCompare((b.name + b.status).toLowerCase()))
                .map((account) => (
                  <tr key={account.id}>
                    <td>{normalizeString(account.id)}</td>
                    <td>{normalizeString(account.name)}</td>
                    <td>{normalizeString(account.description)}</td>
                    <td>{normalizeString(account.type)}</td>
                    <td>{normalizeString(account.status)}</td>
                    <td>{new Date(account.createdAt).toLocaleDateString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </Page>
  );
};

export default AccountsPage;
