import { decodeUserCredentials, saveUserCredentials } from '../signIn/utils';
import { trackSuccessfulLogin } from '../../../mixpanel';

export const processUserDataAfterLogin = (token: string) => {
  const { userId, companyName, userRole, title, phone, userEmail, userName, accountId, accountType, user } =
    decodeUserCredentials(token);
  trackSuccessfulLogin(userId, userEmail, userName);
  saveUserCredentials(token, accountId, accountType, userRole, userName, userEmail, userId, companyName, title, phone);

  // @ts-ignore
  if (window.pendo) {
    // @ts-ignore
    window.pendo.initialize({
      visitor: {
        id: userId,
        email: userEmail,
      },
    });
  }

  return { user };
};
