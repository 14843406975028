export const getTitlePositionAccordingToCount = (count: number) => {
  switch (count) {
    case 1:
      return 200;
    case 2:
      return 180;
    case 3:
      return 160;
    case 4:
      return 140;
    case 5:
      return 120;
    case 6:
      return 100;
    case 7:
      return 80;
    case 8:
      return 60;
    case 9:
      return 40;
    case 10:
      return 20;
    case 11:
      return 0;
    case 12:
      return -20;
    default:
      return 0;
  }
};
