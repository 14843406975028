import { getInitialBbox } from '../../utils/getInitialBboxFromPoints';

export const getViewportBoxFromGeo = (geo: GeoJSON.Feature<GeoJSON.Geometry>[]) => {
  const initViewport = getInitialBbox(geo);
  const horizontalOffset = (initViewport[2] - initViewport[0]) * 0.2;
  const verticalOffset = (initViewport[3] - initViewport[1]) * 0.2;

  return [
    initViewport[0] - horizontalOffset,
    initViewport[1] - verticalOffset,
    initViewport[2] + horizontalOffset,
    initViewport[3] + verticalOffset,
  ];
};
