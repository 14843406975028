import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import { characteristicAudienceModel } from '../model';
import styles from './style.module.scss';
import { ReportItemVisualizationMode } from '../../../../../common/reportItems/types';

interface Props {
  mode: ReportItemVisualizationMode;
}

export const HeaderTooltip = ({ mode }: Props) => {
  const { t } = useTranslation();
  const filters = useStore(characteristicAudienceModel.$activeFilters);

  const commonPart = (
    <div style={{ marginBottom: '25px' }}>
      {t('catchment.socioDemo.tooltipParagraph1')}
      <br />
      {t('catchment.socioDemo.tooltipParagraph2')}
      <br />
      <br />
      <b>{t('catchment.socioDemo.externalProviders')}</b>
      <br />
      {t('catchment.socioDemo.inDeItChAt')} -{' '}
      <a href="https://www.gfk.com/" target="_blank" rel="noreferrer">
        GfK
      </a>
      <br />
      {t('catchment.socioDemo.inNetherlands')} -{' '}
      <a href="https://www.whooz.nl/" target="_blank" rel="noreferrer">
        Whooz
      </a>
      <br />
      {t('catchment.socioDemo.inIsrael')} -{' '}
      <a href="https://points.co.il/" target="_blank" rel="noreferrer">
        Points
      </a>
    </div>
  );

  const contentDE = (
    <div>
      <b>
        {t('catchment.socioDemo.buildings')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.buildingsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.buildings')} / {t('catchment.socioDemo.type')}:{' '}
      </b>
      {t('catchment.socioDemo.buildingsType')}
      <br />
      <b>
        {t('catchment.socioDemo.families')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.familiesNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.families')} / {t('catchment.socioDemo.size')}:{' '}
      </b>
      {t('catchment.socioDemo.familiesSizeDE')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.head')} / {t('catchment.socioDemo.age')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsHeadDE')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.income')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsIncome')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.purchasingPower')}:{' '}
      </b>
      {t('catchment.socioDemo.householdPurchasingDE')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.age')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsAge')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.gender')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsGender')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.clothingAndFootwear')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingClothingDE')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.food')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingFoodDE')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.furnitureEquipment')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingFurniture')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.healthHygiene')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingHealth')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.miscGoods')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingMiscDE')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.other')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingOther')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingpower')}
    </div>
  );

  const contentAT = (
    <div>
      <b>
        {t('catchment.socioDemo.buildings')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.buildingsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.buildings')} / {t('catchment.socioDemo.type')}:{' '}
      </b>
      {t('catchment.socioDemo.buildingsType')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.size')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsSize')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.age')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsAge')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.gender')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsGender')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsNumber')}
    </div>
  );

  const contentCH = (
    <div>
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.education')} /{' '}
        {t('catchment.socioDemo.highest')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsEducation')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.income')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsIncome')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.size')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsSize')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.age')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsAge')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.employment')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsEmployment')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.gender')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsGender')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.maritalStatus')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsMaritalstatus')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.publicTransport')} /{' '}
        {t('catchment.socioDemo.contractType')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPublictransport')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.vehicles')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsVehicles')}
    </div>
  );

  const contentIT = (
    <div>
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.age')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsAge')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.gender')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsGender')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingpower')}
    </div>
  );

  const contentNL = (
    <div>
      <b>
        {t('catchment.socioDemo.buildings')} / {t('catchment.socioDemo.energy')}:{' '}
      </b>
      {t('catchment.socioDemo.buildingsEnergyNL')}
      <br />
      <b>
        {t('catchment.socioDemo.buildings')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.buildingsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.families')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.familiesNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.families')} / {t('catchment.socioDemo.size')}:{' '}
      </b>
      {t('catchment.socioDemo.familiesSizeNL')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.education')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsEducation')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.head')} / {t('catchment.socioDemo.age')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsHeadNL')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.income')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsIncome')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.income')} / {t('catchment.socioDemo.decile')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsIncomeDecile')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.income')} / {t('catchment.socioDemo.vsAvg')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsIncomeVsAvg')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.decile')}:{' '}
      </b>
      {t('catchment.socioDemo.householdPurchasingNL')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.segment')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsSegment')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.size')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsSize')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.socialClass')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsSocialclass')}
      <br />
      <b>
        {t('catchment.socioDemo.households')} / {t('catchment.socioDemo.vehicles')}:{' '}
      </b>
      {t('catchment.socioDemo.householdsVehicles')}
      <br />
      <b>
        {t('catchment.socioDemo.housingUnits')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.housingunitsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.housingUnits')} / {t('catchment.socioDemo.value')} / {t('catchment.socioDemo.buy')}:{' '}
      </b>
      {t('catchment.socioDemo.housingunitsValueBuy')}
      <br />
      <b>
        {t('catchment.socioDemo.housingUnits')} / {t('catchment.socioDemo.value')} / {t('catchment.socioDemo.rent')}:{' '}
      </b>
      {t('catchment.socioDemo.housingunitsValueRent')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.number')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsNumber')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.beverages')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingBeverages')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.clothingAndFootwear')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingClothingNL')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.eduCulEnt')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingEducation')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.food')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingFoodNL')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.household')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingHousehold')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.miscGoods')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingMiscNL')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')} /{' '}
        {t('catchment.socioDemo.transportComm')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingTransport')}
      <br />
      <b>
        {t('catchment.socioDemo.inhabitants')} / {t('catchment.socioDemo.purchasingPower')}:{' '}
      </b>
      {t('catchment.socioDemo.inhabitantsPurchasingpower')}
    </div>
  );

  const getGraphTooltipText = () => {
    let selectedContent;
    if (filters.country === 'de') {
      selectedContent = contentDE;
    }
    if (filters.country === 'at') {
      selectedContent = contentAT;
    }
    if (filters.country === 'ch') {
      selectedContent = contentCH;
    }
    if (filters.country === 'it') {
      selectedContent = contentIT;
    }
    if (filters.country === 'nl') {
      selectedContent = contentNL;
    }

    return (
      <>
        {commonPart}
        {selectedContent}
      </>
    );
  };

  const getChartTooltipText = () => (
    <div>
      <b>{t('catchment.socioDemo.households')}:</b> {t('catchment.socioDemo.householdsNumber')}
      <br />
      <b>{t('catchment.socioDemo.inhabitants')}:</b> {t('catchment.socioDemo.inhabitantsNumber')}
      <br />
      <b>{t('catchment.socioDemo.gender')}:</b> {t('catchment.socioDemo.inhabitantsGender')}
      <br />
      <b>{t('catchment.socioDemo.families')}:</b> {t('catchment.socioDemo.familiesNumber')}
      <br />
      <b>{t('catchment.socioDemo.age')}:</b> {t('catchment.socioDemo.inhabitantsAge')}
      <br />
      <b>{t('catchment.socioDemo.purchasingPower')}:</b> {t('catchment.socioDemo.inhabitantsPurchasingpower')}
      <br />
    </div>
  );

  return (
    <div className={styles.headerTooltip}>
      {(mode === ReportItemVisualizationMode.Graph ? getGraphTooltipText() : getChartTooltipText()) || null}
    </div>
  );
};
