import React, { useContext, useMemo } from 'react';
import { useStore } from 'effector-react';
import styles from '../locations.module.scss';
import { MainContext } from '../../../../common/types/mainContext';
import { ArrowDownIcon } from '../../../../common/icons/dropdown';
import LocationsList from './locationsList';
import { useToggle } from '../../../../common/hooks';
import { dashboardModel } from '../../../../dasboardLoader';
import { Skeleton } from '../../../../common/skeleton';

const LocationsDropdown = () => {
  const { selectedLocations, primaryLocationId } = useContext(MainContext);
  const [isOpened, toggleOpened] = useToggle(false);
  const isGlobalLoading = useStore(dashboardModel.$isLoading);
  const areAllRIsLoaded = useStore(dashboardModel.$areAllReportItemsLoaded);

  const primaryLocation = useMemo(
    () => selectedLocations.find((loc) => loc.id === primaryLocationId),
    [selectedLocations, primaryLocationId],
  );

  const onDropdownBlur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      toggleOpened(false);
    }
  };

  const onClose = () => {
    toggleOpened(false);
  };

  return (
    <div className={styles.locationsDropdown} onBlur={onDropdownBlur} tabIndex={0}>
      {isGlobalLoading || !areAllRIsLoaded ? (
        <span className={styles.skeleton}>
          <Skeleton className={styles.skeletonField} />
        </span>
      ) : (
        <div className={styles.activeLocations} onClick={() => toggleOpened(!isOpened)}>
          <span className={styles.name}>
            {primaryLocation
              ? `${primaryLocation.name} ${
                  selectedLocations.length - 1 ? `(+${selectedLocations.length - 1} more)` : ''
                }`
              : 'No selected location'}
          </span>
          <button type="button">
            <ArrowDownIcon />
          </button>
        </div>
      )}
      {isOpened && <LocationsList onClose={onClose} />}
    </div>
  );
};

export default LocationsDropdown;
