import React from 'react';

export const PrimaryPinIcon = () => (
  <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6824_13413)">
      <path
        d="M9.30866 12.0488L10.8535 13.6026L16.0063 18.8033L13.945 20.8819L7.31315 14.1909C6.71541 13.659 6.2338 13.0074 5.89881 12.2776C5.56382 11.5477 5.38274 10.7555 5.36717 9.95118C5.36717 8.48202 5.9452 7.07303 6.97402 6.03418C8.00284 4.99533 9.39829 4.41171 10.8534 4.41171C12.3083 4.41171 13.7038 4.99533 14.7327 6.03418C15.7616 7.07303 16.3396 8.48202 16.3396 9.95118C16.324 10.7555 16.1429 11.5478 15.808 12.2777C15.4728 13.0076 14.991 13.6591 14.3931 14.1909L13.9435 14.6444L11.886 12.565L12.3986 12.0488C13.002 11.5139 13.3716 10.76 13.4271 9.95118C13.418 9.26843 13.143 8.61676 12.6615 8.13723C12.1801 7.65769 11.531 7.38882 10.8547 7.38882C10.1786 7.38882 9.5295 7.65769 9.04806 8.13723C8.56662 8.61676 8.2916 9.26843 8.28249 9.95118C8.33783 10.7598 8.70711 11.5136 9.30999 12.0488H9.30866ZM6.31664 15.2614L10.8555 19.84L12.9155 21.9196L10.8545 24L4.32261 17.4044C2.82478 16.0654 1.76597 14.2979 1.28688 12.3367C0.807759 10.3755 0.930953 8.31356 1.64011 6.42477C2.3493 4.53598 3.61072 2.90978 5.25717 1.7622C6.90344 0.61462 8.85671 0 10.8572 0C12.8577 0 14.8108 0.61462 16.4572 1.7622C18.1035 2.90978 19.3651 4.53598 20.0742 6.42477C20.7834 8.31356 20.9065 10.3755 20.4274 12.3367C19.9483 14.2979 18.8894 16.0654 17.3918 17.4044L17.0352 17.764L14.9768 15.6854L15.3953 15.2634C16.1424 14.5979 16.7427 13.7813 17.157 12.8664C17.5714 11.9516 17.7904 10.959 17.8002 9.95308C17.7851 8.10379 17.047 6.33543 15.7465 5.03315C14.4461 3.73088 12.6887 3.0002 10.8572 3.0002C9.02553 3.0002 7.26825 3.73088 5.96773 5.03315C4.66737 6.33543 3.92914 8.10379 3.91407 9.95308C3.92368 10.9584 4.14236 11.9504 4.55604 12.8649C4.96973 13.7794 5.56912 14.5959 6.31531 15.2614H6.31664Z"
        fill="#1F303D"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6824_13413"
        x="0"
        y="0"
        width="21.7144"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.121569 0 0 0 0 0.188235 0 0 0 0 0.239216 0 0 0 0.1 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_6824_13413" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6824_13413" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default PrimaryPinIcon;
