import * as turf from '@turf/turf';
import { Position } from '@turf/helpers/dist/js/lib/geojson';

export const getDistanceBetweenTwoPoints = (point1: Position, point2: Position) => {
  const from = turf.point(point1);
  const to = turf.point(point2);

  const distance = turf.distance(from, to, { units: 'miles' });
  return distance;
};
