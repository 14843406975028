import React, { useEffect, useState } from 'react';
import { SpiderChart } from '../../../../common/chart';
import { AnalyticItemInformation, AnalyticsData } from '../../types';
import { Switcher } from './switcher';
import styles from './style.module.scss';
import { formatDataForColumnDisplaying } from './utils';
import { DataLevelEnum, LocationTypeEnum } from '../../../../common/reportItems/types';
import { LocationInfo } from '../../../../common/types/projectsHierarchy';
import { addImageUrlToLocations } from '../../../../common/locations/utils';

interface Props {
  data?: AnalyticsData | null;
  dataLevel: DataLevelEnum;
  locationType: LocationTypeEnum;
  primaryLocation: LocationInfo | null;
}

export const MovementSpiderChart: React.FC<Props> = ({ data, dataLevel, locationType, primaryLocation }) => {
  const [columnsData, setColumnsData] = useState<{
    before: AnalyticItemInformation<'percentage'>[] | null;
    after: AnalyticItemInformation<'percentage'>[] | null;
  }>({ before: null, after: null });
  const [includeOptions, toggleIncludeOptions] = useState({ home: false, work: false });
  const [primaryLocationImage, setPrimaryLocationImage] = useState('');

  useEffect(() => {
    if (data && data.before && data.after) {
      const { before, after } = data;
      const { home, work } = includeOptions;

      if (home && work) {
        const beforeColumn = formatDataForColumnDisplaying(before, locationType, dataLevel, true, true);
        const afterColumn = formatDataForColumnDisplaying(after, locationType, dataLevel, true, true);

        setColumnsData({ before: beforeColumn, after: afterColumn });
        return;
      }
      if (home) {
        const beforeColumn = formatDataForColumnDisplaying(before, locationType, dataLevel, false, true);
        const afterColumn = formatDataForColumnDisplaying(after, locationType, dataLevel, false, true);

        setColumnsData({ before: beforeColumn, after: afterColumn });
        return;
      }

      if (work) {
        const beforeColumn = formatDataForColumnDisplaying(before, locationType, dataLevel, true, false);
        const afterColumn = formatDataForColumnDisplaying(after, locationType, dataLevel, true, false);

        setColumnsData({ before: beforeColumn, after: afterColumn });
        return;
      }

      const beforeColumn = formatDataForColumnDisplaying(before, locationType, dataLevel, false, false);
      const afterColumn = formatDataForColumnDisplaying(after, locationType, dataLevel, false, false);

      setColumnsData({ before: beforeColumn, after: afterColumn });
    }
  }, [data, includeOptions, dataLevel, locationType]);

  const onChangeOptions = (type: 'work' | 'home') => () => {
    toggleIncludeOptions((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  useEffect(() => {
    if (primaryLocation) {
      const updatedPrimaryLocation = addImageUrlToLocations([primaryLocation], 310, 100);
      const location = updatedPrimaryLocation[0];
      if (location) {
        if (location.image) {
          setPrimaryLocationImage(location.image);
        }
      }
    }
  }, [primaryLocation]);

  const { after, before } = columnsData;
  const height = (29 + 26) * ((after?.length || 1) <= 4 ? 5 : after?.length || 1);
  return (
    <div className={styles.wrapper}>
      {after && before && (
        <div className={styles.display} style={{ height: `${height}px` }}>
          <SpiderChart
            after={after}
            before={before}
            locationImage={primaryLocationImage}
            locationName={primaryLocation?.name}
          />
          <div className={styles.switcher}>
            <Switcher data={includeOptions} onChange={onChangeOptions} />
          </div>
        </div>
      )}
    </div>
  );
};
