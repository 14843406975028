import React, { ChangeEvent } from 'react';
import styles from './style.module.scss';

interface Props {
  value: string;
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  name: string;
  id?: string;
  type?: string;
  isBorder?: boolean;
  maxLength?: number;
}
export const DefaultInput: React.FC<Props> = ({
  value = '',
  onChange,
  placeholder = '',
  isError,
  name,
  id,
  type = 'text',
  isBorder,
  maxLength,
}) => (
  <input
    className={`${styles.input} ${isError ? styles.error : ''} ${isBorder ? styles.border : ''}`}
    type={type}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    name={name}
    id={id}
    maxLength={maxLength}
  />
);
