import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PeriodsEnum } from '../../../../common/reportItems/types';
import styles from './style.module.scss';
import Dropdown from '../../../../common/controls/dropdown';
import { Option } from '../../../../common/controls/dropdown/types';
import { ShowAverageToggle } from '../../../../common/reportItems/reportItemDetail/showAverageToggle/showAverageToggle';
import { ContentVisualizationMode } from '../../../../common/types/projectsHierarchy';

interface Props {
  onChangeTimeType: (value: Option<PeriodsEnum>) => void;
  activeTimeType: string;
  visualizationMode: ContentVisualizationMode;
}

const transformToOptionItem = (value: PeriodsEnum, label: string) => ({
  id: value,
  label,
  item: value,
  disabled: false,
});

export const Header = ({ activeTimeType, onChangeTimeType, visualizationMode }: Props) => {
  const { t } = useTranslation();
  const periodOptions = useMemo(
    () =>
      [PeriodsEnum.MONTHLY, PeriodsEnum.QUARTERLY].map((scope) =>
        transformToOptionItem(scope, t(`visitTrends.enum.${scope}`)),
      ),
    [t],
  );
  return (
    <div className={styles.header}>
      {visualizationMode === ContentVisualizationMode.VISUALIZATION && <ShowAverageToggle />}
      <div className={styles.select}>
        <Dropdown<PeriodsEnum>
          onChange={onChangeTimeType}
          selectedId={activeTimeType}
          options={periodOptions}
          maxLengthToShow={100}
        />
      </div>
    </div>
  );
};
