import { SocialDemographicData, SocialDemographicResponse } from '../interfaces';
import {
  getAge,
  getDistance,
  getDriving,
  getFamily,
  getHousehold,
  getIncome,
  getMarket,
  getPurchase,
  getResident,
  getSex,
} from './subCategories';

export const convertDataToRepresentationFormat = (
  data: SocialDemographicResponse,
  currency: string,
): SocialDemographicData[] => {
  const {
    totalHouseholds,
    totalResidents,
    gender,
    income: incomes,
    age,
    avgDrivingTime,
    avgDistance,
    avgHouseholdsIncome,
    avgPurchasingPower,
    retail,
    households,
    totalHouseholdsContributionPct,
    totalResidentsContributionPct,
  } = data;

  const household = getHousehold(totalHouseholds, totalHouseholdsContributionPct);
  const resident = getResident(totalResidents, totalResidentsContributionPct);
  const sex = getSex(gender);
  const family = getFamily(households);
  const ages = getAge(age);
  const driving = getDriving(avgDrivingTime);
  const distance = getDistance(avgDistance);
  const income = getIncome(incomes, currency);
  const purchase = getPurchase(avgPurchasingPower, currency);
  const market = getMarket(retail, currency);

  return [sex, resident, household, driving, distance, income, purchase, market, ages, family];
};
