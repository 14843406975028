import React from 'react';

export const AgesIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.83333 2.5C8.52093 2.5 9.18037 2.76339 9.66657 3.23223C10.1528 3.70107 10.4259 4.33696 10.4259 5C10.4259 5.66304 10.1528 6.29893 9.66657 6.76777C9.18037 7.23661 8.52093 7.5 7.83333 7.5C7.14574 7.5 6.4863 7.23661 6.00009 6.76777C5.51389 6.29893 5.24074 5.66304 5.24074 5C5.24074 4.33696 5.51389 3.70107 6.00009 3.23223C6.4863 2.76339 7.14574 2.5 7.83333 2.5ZM5.88889 27.5V20H2L5.35741 10.5125C5.68148 9.4875 6.66667 8.75 7.83333 8.75C9 8.75 9.98519 9.4875 10.3093 10.5125L13.6667 20H9.77778V27.5H5.88889Z"
      fill="#DBAA85"
    />
    <path
      d="M29.125 15.3V27.5H27.95V15.3C27.95 15.1125 27.875 15 27.7875 14.8625C27.675 14.75 27.55 14.7 27.4 14.7C27.2125 14.7 27.075 14.75 26.9625 14.8625C26.85 15 26.8 15.125 26.8 15.3V16.45H25.6625V15.625C24.7875 15.4125 24 15.05 23.3125 14.5375C22.625 14.025 22.05 13.425 21.575 12.7375L21.1375 14.2375C21 14.7625 21 15.3 21 15.85V16.25V16.6625L23.3125 19.925V27.5H21V21.675L18.9 18.75L18.6875 22.8125L15.2 27.5L13.35 26.0875L16.3375 22.05V15.85C16.3375 15.1875 16.4 14.5375 16.5125 13.8875L16.9375 11.925L15.2 12.9V17.0375H12.875V11.5375L19.125 8C19.4875 7.825 19.8625 7.725 20.2625 7.725C20.6625 7.725 21.05 7.8375 21.4125 8.05C21.8125 8.275 22.1125 8.6 22.3375 9.0375L23.2625 11C23.625 11.725 24.175 12.3375 24.925 12.825C25.6625 13.3125 26.4875 13.5625 27.4 13.5625C27.875 13.5625 28.275 13.75 28.625 14.05C28.975 14.375 29.125 14.7875 29.125 15.3ZM21.625 2.5C23.0125 2.5 24.125 3.625 24.125 5C24.125 6.3875 23.0125 7.5 21.625 7.5C20.25 7.5 19.125 6.3875 19.125 5C19.125 3.625 20.25 2.5 21.625 2.5Z"
      fill="#EADED5"
    />
  </svg>
);
