import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableDataFormat } from '../types';
import BaseTable, { BaseTableHeaderProps } from '../../../common/baseTable';
import { DurationLocation } from '../../../common/types/projectsHierarchy';
import { useTimePeriod } from '../../../common/hooks/useTimePeriod';

interface Props {
  data: DurationLocation[];
}

const convertDataToTableFormat = (data: DurationLocation[], isExport: boolean, timeFrame: string) =>
  data
    ? data.map((item) => ({
        head: item.location_name,
        options: item.data.map((subitem) => ({
          subhead: { value: `${subitem.category}`, formatter: () => `${subitem.category}` },
          values: isExport
            ? {
                value: subitem.value,
                timeframe: timeFrame,
              }
            : {
                value: subitem.value * 100,
              },
        })),
      }))
    : [];

const DurationDetailTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const timeFrameString = useTimePeriod();

  const tableHeaders: BaseTableHeaderProps[] = [
    { key: 'value', name: t('duration.detail.visitProportion'), unit: '%' },
  ];

  const exportTableHeaders: BaseTableHeaderProps[] = [
    { key: 'value', name: t('duration.detail.visitProportion'), unit: '' },
    { key: 'timeframe', name: t('reportItem.timeFrame'), unit: '' },
  ];

  const tableData: TableDataFormat[] = useMemo(() => convertDataToTableFormat(data, false, timeFrameString), [data]);
  const exportTableData: TableDataFormat[] = useMemo(
    () => convertDataToTableFormat(data, true, timeFrameString),
    [data],
  );

  return (
    <BaseTable<TableDataFormat>
      data={tableData}
      headers={tableHeaders}
      headName={t('location.location')}
      subheadName={t('duration.detail.timeRange')}
      exportHeaders={exportTableHeaders}
      exportData={exportTableData}
    />
  );
};

export default DurationDetailTable;
