import React from 'react';

export const ReportsListIcon = () => (
  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99998 0H1.59998C0.719985 0 -1.52588e-05 0.72 -1.52588e-05 1.6V14.4C-1.52588e-05 15.28 0.719985 16 1.59998 16H11.2C12.08 16 12.8 15.28 12.8 14.4V4.8L7.99998 0ZM11.2 14.4H1.59998V1.6H7.19998V5.6H11.2V14.4ZM3.99998 8.8V13.6H2.39998V8.8H3.99998ZM8.79998 10.4V13.6H10.4V10.4H8.79998ZM5.59998 7.2V13.6H7.19998V7.2H5.59998Z"
      fill="#00B2F1"
    />
  </svg>
);
