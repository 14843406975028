import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Page from '../../templates/page/page';
import { AppState } from '../../../reducers/reducers';
import { loadReports } from '../../../actions/actions';
import { Report } from '../../../types/report';
import styles from './reportItemMonitoringPage.module.css';

interface StateProps {
  reports?: Report[];
}

interface DispatchProps {
  loadReports: (projectId: string) => void;
}

interface State {
  fetchReportsContinuouslyInterval: NodeJS.Timeout;
}

type PathParams = { projectId: string };

class ReportItemMonitoringPage extends Component<StateProps & DispatchProps & RouteComponentProps<PathParams>, State> {
  componentDidMount(): void {
    const { projectId } = this.props.match.params;
    this.props.loadReports(projectId);
    const fetchReportsContinuouslyInterval = setInterval(() => {
      this.props.loadReports(projectId);
    }, 5000);
    this.setState({ fetchReportsContinuouslyInterval });
  }

  componentWillUnmount(): void {
    clearInterval(this.state.fetchReportsContinuouslyInterval);
  }

  render() {
    return <Page title="Report item monitoring">{this.renderItemTable()}</Page>;
  }

  private renderItemTable() {
    if (this.props.reports) {
      return (
        <div className={styles.table}>
          <div className={styles.header}>
            <div>Report ID</div>
            <div>Item ID</div>
            <div>Report name</div>
            <div>Item name</div>
            <div>status</div>
            <div>Go to report</div>
          </div>
          {this.renderReports()}
        </div>
      );
    }
    return null;
  }

  private renderReports() {
    const { projectId } = this.props.match.params;
    if (this.props.reports) {
      const reports = this.props.reports.reverse();
      return reports.map((report) =>
        report.reportItems.map((item) => (
          <div className={styles.row} key={item.id}>
            <div>{report.id}</div>
            <div>{item.id}</div>
            <div>{report.name}</div>
            <div>{item.name}</div>
            <div>{item.status}</div>
            <div>
              <Link to={`/projects/${projectId}/reports/${report.id}`} className={styles.link}>
                Go
              </Link>
            </div>
          </div>
        )),
      );
    }
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  reports: state.reports.entries,
});

const mapDispatchToProps: DispatchProps = {
  loadReports,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportItemMonitoringPage as any));
