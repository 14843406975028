import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import MapGL, { MapRef } from 'react-map-gl';
import mapboxgl, { setRTLTextPlugin, getRTLTextPluginStatus } from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { MapEvent } from 'react-map-gl/src/components/interactive-map';
import { MAP_STYLE, MAP_TOKEN } from './map.config';
import s from './mapBase.module.scss';

import { Navigation } from '../navigation/navigation';
import { LinkButton } from '../../controls/buttons';
import SelectReportItemPanel from '../SelectReportItemPanel';
import { MapHandlerTypesEnum } from './enums';
import { getViewport } from '../../../mainPage/backgroundMap/utils';
import { MapOptions } from './interfaces';
import { useDebounce, useURLParams } from '../../hooks';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { SidebarContext } from '../../sidebarMenu/sidebarContext';
import { SwitchModes } from '../../types/visualizationObjects';
import { useElementScreenshot } from '../../hooks/useElementScreenshot';
import { MapActions } from '../actions';
import { downloadImage } from '../../export';
import { ReportInfo } from '../../types/projectsHierarchy';

const rtlStatus = getRTLTextPluginStatus();
if (rtlStatus === 'unavailable' || rtlStatus === 'error') {
  setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
    () => {
      console.log('===== RightToLeft mapbox plugin error =====');
    },
    true,
  );
}

const supportedLanguages = ['en', 'de', 'fr', 'es', 'it'];
export interface MapViewPort {
  longitude: number;
  latitude: number;
  zoom: number;
}

interface MapBaseProps {
  bottomBar?: ReactNode;
  interactiveLayerIds?: string[] | null;
  onClickByCode?: (props: any | null) => void;
  onClickByPolygon?: (point: [number, number]) => void;
  geo?: GeoJSON.Feature<GeoJSON.Geometry>[];
  bboxPadding?: number;
  onViewportChange?: (zoom: number, longitude: number, latitude: number) => void;
  disabled?: boolean;
  loading?: boolean;
  defaultViewport?: MapViewPort;
  mapOptions: MapOptions;
  isReady?: boolean;
  resetMapViewport?: boolean;
  primaryLocationId?: string | null;
  clickHandlerType?: MapHandlerTypesEnum;
  isMovement?: boolean;
  isCatchment?: boolean;
  catchmentLayer?: SwitchModes;
  onIdle?: VoidFunction;
  isExportMode?: boolean;
  selectedReportItemName?: string;
  hideActions?: boolean;
  hideOptions?: boolean;
  primaryLocationCoordinates: { longitude: number | null; latitude: number | null };
  showPois: boolean;
  togglePois: (value: boolean) => void;
  language: string;
  selectedReport: ReportInfo | null;
}

export const MapBaseViewport = React.createContext({
  latitude: 0,
  longitude: 0,
  zoom: 10,
});
export const MapBaseOptions = React.createContext({ isHidden: false });

const MapBase: React.FC<MapBaseProps> = ({
  children,
  interactiveLayerIds,
  onClickByCode,
  geo,
  onViewportChange,
  disabled = false,
  loading = false,
  bottomBar,
  mapOptions,
  isReady,
  resetMapViewport,
  primaryLocationId,
  clickHandlerType,
  onClickByPolygon,
  isMovement,
  isCatchment,
  catchmentLayer,
  onIdle,
  isExportMode,
  selectedReportItemName,
  hideActions,
  hideOptions,
  primaryLocationCoordinates,
  showPois,
  togglePois,
  language,
  selectedReport,
}) => {
  const { isOpened } = useContext(SidebarContext);
  const { elementRef: rootRef, onMakeScreenshot, base64Image, isScreenshotInProgress } = useElementScreenshot({});
  const mapRef = useRef<MapRef>(null);
  const prevPrimaryLocationId = useRef<null | string>(null);

  const [viewport, setViewport] = useState<MapViewPort>({
    latitude: 0,
    longitude: 0,
    zoom: 10,
  });
  const [initViewport, setInitViewport] = useState<MapViewPort>({
    latitude: 0,
    longitude: 0,
    zoom: 10,
  });

  const { params, isParsingDone } = useURLParams();
  const isURLChecked = useRef(false);
  const debouncedViewport = useDebounce<MapViewPort>(viewport, 400);

  const calculatedViewPort = useMemo(() => {
    if (rootRef.current && geo?.length) {
      const { offsetWidth, offsetHeight } = rootRef.current;
      return getViewport(geo, offsetWidth, offsetHeight, {
        top: 100,
        right: 100,
        bottom: 100,
        left: isOpened ? 400 : 54,
      });
    }

    return null;
  }, [geo]);

  const setViewportFromURL = () => {
    if (!isParsingDone) return;
    if (catchmentLayer === SwitchModes.TRIPS) {
      const { catchmentTripRoutesLatitude, catchmentTripRoutesLongitude, catchmentTripRoutesZoom } = params;
      if (catchmentTripRoutesLatitude && catchmentTripRoutesLongitude && catchmentTripRoutesZoom) {
        isURLChecked.current = true;
        setViewport({
          latitude: Number(catchmentTripRoutesLatitude),
          longitude: Number(catchmentTripRoutesLongitude),
          zoom: Number(catchmentTripRoutesZoom),
        });
      }
      return;
    }
    const { latitude, longitude, zoom } = params;
    if (latitude && longitude && zoom) {
      isURLChecked.current = true;
      setViewport({ latitude: Number(latitude), longitude: Number(longitude), zoom: Number(zoom) });
    }
  };

  const makeMapScreenshot = () => {
    trackUserAction(
      'Map screenshot export',
      UserActionsEnum.REPORT_EXPORT,
      `Report item name: ${selectedReportItemName};`,
    );
    onMakeScreenshot();
  };

  useEffect(() => {
    const initMapPosition = () => {
      if (calculatedViewPort) {
        const { latitude, longitude, zoom } = calculatedViewPort;

        setInitViewport({ latitude, longitude, zoom });

        if (!isCatchment && !isMovement) {
          if (!mapOptions.default.zoom) {
            if (!params.zoom) setViewport({ latitude, longitude, zoom });
            else setViewportFromURL();
            return;
          }
          if (mapOptions.default.zoom) {
            const options = mapOptions.default;
            if (options) {
              setViewport({
                latitude: options.latitude as number,
                longitude: options.longitude as number,
                zoom: options.zoom as number,
              });
            }
          }
        }
      }
    };
    initMapPosition();
    window.addEventListener('resize', initMapPosition);
    return () => window.removeEventListener('resize', initMapPosition);
  }, [calculatedViewPort?.zoom, calculatedViewPort?.longitude, calculatedViewPort?.latitude]);

  useEffect(() => {
    if (isMovement || isCatchment) {
      if (calculatedViewPort) {
        const { latitude, longitude, zoom } = calculatedViewPort;
        const isTripRoutes = catchmentLayer === SwitchModes.TRIPS;

        const mainZoom = isMovement
          ? !mapOptions.movement.zoom
          : isCatchment
          ? isTripRoutes
            ? !mapOptions.catchmentTripRoutes.zoom
            : !mapOptions.catchment.zoom
          : false;

        if (mainZoom) {
          const paramsZoom = isTripRoutes ? params.catchmentTripRoutesZoom : params.zoom;
          if (!paramsZoom) setViewport({ latitude, longitude, zoom });
          else setViewportFromURL();
          if (primaryLocationId) prevPrimaryLocationId.current = primaryLocationId;
          return;
        }

        if (isMovement ? mapOptions.movement.zoom : isCatchment ? mapOptions.catchment.zoom : false) {
          const options = isMovement
            ? mapOptions.movement
            : isCatchment
            ? isTripRoutes
              ? mapOptions.catchmentTripRoutes
              : mapOptions.catchment
            : mapOptions.catchment;

          if (options) {
            if (prevPrimaryLocationId.current !== primaryLocationId) {
              setViewport({
                latitude: latitude as number,
                longitude: longitude as number,
                zoom: options.zoom as number,
              });
            } else {
              setViewport({
                latitude: options.latitude as number,
                longitude: options.longitude as number,
                zoom: options.zoom as number,
              });
            }
          }
        }
      }
    }
  }, [
    calculatedViewPort?.zoom,
    calculatedViewPort?.longitude,
    calculatedViewPort?.latitude,
    primaryLocationId,
    catchmentLayer,
  ]);

  useEffect(() => {
    if (onViewportChange) {
      const { zoom, longitude, latitude } = debouncedViewport;
      onViewportChange(zoom, longitude, latitude);
    }
  }, [debouncedViewport]);

  useEffect(() => {
    if (mapRef.current !== null) {
      const map = mapRef.current.getMap();

      map.loadImage('/img/customerPortal/polygon-bg.png', (err: any, image: any) => {
        if (err) throw err;
        map.addImage('polygonBackground', image);
      });

      const mapboxLanguageControl = new MapboxLanguage();
      map.addControl(mapboxLanguageControl);
      map._languageControl = mapboxLanguageControl;

      const scale = new mapboxgl.ScaleControl({
        maxWidth: 120,
        unit: 'metric',
      });
      map.addControl(scale, 'bottom-right');
      map.addControl(new mapboxgl.AttributionControl(), 'bottom-right');
      if (onIdle) {
        map.on('idle', onIdle);
      }
    }
  }, [mapRef]);

  useEffect(() => {
    if (mapRef.current !== null) {
      const map = mapRef.current.getMap();
      if (map.isStyleLoaded()) {
        map.setLayoutProperty('poi-label-new', 'visibility', showPois ? 'visible' : 'none');
      }
    }
  }, [showPois]);

  useEffect(() => {
    if (mapRef.current !== null) {
      const map = mapRef.current.getMap();
      if (map.isStyleLoaded() && map._languageControl) {
        if (language !== null && supportedLanguages.includes(language)) {
          map.setStyle(map._languageControl.setLanguage(map.getStyle(), language));
        } else {
          map.setStyle(map._languageControl.setLanguage(map.getStyle(), 'en'));
        }
      }
    }
  }, [language]);

  useEffect(() => {
    const { longitude, latitude } = primaryLocationCoordinates;
    if (longitude && latitude && mapRef.current) {
      setViewport((prevState) => ({
        latitude,
        longitude,
        zoom: prevState.zoom,
      }));
    }
  }, [primaryLocationCoordinates]);

  const onClickHandler = (e: MapEvent) => {
    switch (clickHandlerType) {
      case MapHandlerTypesEnum.CODE: {
        if (
          interactiveLayerIds &&
          onClickByCode &&
          e.features &&
          e.features[0] &&
          e.features[0].layer &&
          interactiveLayerIds.includes(e.features[0].layer.id)
        ) {
          onClickByCode(e.features[0].properties);
        } else {
          onClickByCode && onClickByCode(null);
        }
        break;
      }
      case MapHandlerTypesEnum.POLYGON: {
        const point = e.lngLat;
        if (onClickByPolygon) onClickByPolygon(point);
        break;
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isReady && resetMapViewport) {
      setViewport((prevState) => prevState);
    }
  }, [isReady, resetMapViewport]);

  useEffect(() => {
    if (base64Image && selectedReportItemName && !isExportMode) {
      downloadImage(base64Image, selectedReportItemName);
    }
  }, [base64Image]);

  const onResetInitialPositionHandler = useCallback(() => {
    setViewport(initViewport);
    trackUserAction('Map reset button clicked', UserActionsEnum.RESET_MAP);
  }, [initViewport]);

  useEffect(() => {
    if (selectedReport) {
      onResetInitialPositionHandler();
    }
  }, [selectedReport]);

  return (
    <div className={s.root} ref={rootRef}>
      {(disabled || loading) && (
        <div className={s.noDataContainer}>
          <img src="/img/customerPortal/map-no-data.jpg" alt="Project" />
          <div className={s.noDataBody}>
            {loading ? (
              <h3>Loading data...</h3>
            ) : (
              <>
                <h3>There is not enough data to show this report.</h3>
                <h4>Please try extending the timeframe you are analyzing.</h4>
                <p>
                  If that does not help, please contact us and a customer success representative will get back to you
                  shortly
                </p>
                <LinkButton url="https://placesense.ai/contact/" text="Contact us" external />
              </>
            )}
          </div>
        </div>
      )}
      <MapBaseViewport.Provider value={viewport}>
        <MapBaseOptions.Provider value={{ isHidden: isExportMode || isScreenshotInProgress }}>
          <MapGL
            {...viewport}
            width="100%"
            height="100%"
            mapStyle={MAP_STYLE}
            onViewportChange={setViewport}
            mapboxApiAccessToken={MAP_TOKEN}
            interactiveLayerIds={interactiveLayerIds || undefined}
            onClick={onClickHandler}
            attributionControl={false}
            ref={mapRef}
            maxZoom={19}
            preserveDrawingBuffer
          >
            <>
              {!hideActions && (
                <div className={s.navigation}>
                  <Navigation />
                </div>
              )}
            </>
            {children}
          </MapGL>
          {!hideActions && (
            <>
              <SelectReportItemPanel />
              <MapActions
                onReset={onResetInitialPositionHandler}
                onMakeScreenshot={makeMapScreenshot}
                showPois={showPois}
                togglePois={togglePois}
              />
            </>
          )}
          <div className={`${s.bottomBar} ${isOpened ? s.part : s.full}`}>{bottomBar}</div>
        </MapBaseOptions.Provider>
      </MapBaseViewport.Provider>
    </div>
  );
};

export default MapBase;
