import React from 'react';
import { PanelTitle, LinkWithIcon, HorizontalLine } from '../common/page/controlPanel';
import { SignOutIcon, UserProfileIcon, ChangePasswordIcon } from '../common/icons/controlPanel';
import { Routes } from '../routes';

export const MessagesPanel = () => (
  // TODO
  <>
    <PanelTitle title="User info" backButton />
    <LinkWithIcon title="User profile" icon={<UserProfileIcon />} to={Routes.userProfile} />
    <LinkWithIcon title="Change password" icon={<ChangePasswordIcon />} to={Routes.changePassword} />
    <HorizontalLine />
    <LinkWithIcon title="A pretty boring link" icon={<SignOutIcon />} to={Routes.dashboard} />
  </>
);
