import { AppActions } from '../actions/actionTypes';
import {
  ADD_USER_ERROR,
  CHANGE_PASSWORD_ERROR,
  CLEAR_ERROR_MESSAGE,
  CREATE_PROJECT_ERROR,
  CREATE_REPORT_ERROR,
  FETCH_ACCOUNTS_ERROR,
  FETCH_FFI_PLANS_ERROR,
  FETCH_USER_ERROR,
  FETCH_USERS_ERROR,
  UPDATE_PROJECT_ERROR,
  UPDATE_REPORT_ERROR,
  UPDATE_USER_ERROR,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_ERROR,
  CHANGE_REPORT_STATUS_ERROR,
  GENERATE_RI_THUMBNAILS_ERROR,
} from '../actions/actions';

export interface ErrorMessageState {
  message: string | null;
}

const initialState: ErrorMessageState = {
  message: null,
};

export default function errorMessageReducer(state = initialState, action: AppActions): ErrorMessageState {
  switch (action.type) {
    case UPDATE_PROJECT_ERROR:
    case CREATE_PROJECT_ERROR:
    case FETCH_ACCOUNTS_ERROR:
    case UPDATE_REPORT_ERROR:
    case CREATE_REPORT_ERROR:
    case CHANGE_PASSWORD_ERROR:
    case FETCH_USERS_ERROR:
    case ADD_USER_ERROR:
    case FETCH_FFI_PLANS_ERROR:
    case UPDATE_USER_ERROR:
    case FETCH_USER_ERROR:
    case FORGOT_PASSWORD_ERROR:
    case RESET_PASSWORD_ERROR:
    case CHANGE_REPORT_STATUS_ERROR:
    case GENERATE_RI_THUMBNAILS_ERROR:
      return {
        ...state,
        message: action.payload.reason,
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
}
