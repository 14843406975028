import React from 'react';

export const PoisLayerOffIcon = () => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 4.53011L15.74 9.00011L13.85 10.4701L15.28 11.8901L19 9.00011L10 2.00011L7.41 4.02011L8.83 5.44011L10 4.53011ZM19 14.0701L17.37 12.8001L16.7 13.3201L18.13 14.7501L19 14.0701ZM1.41 0.860107L0 2.27011L4.22 6.49011L1 9.00011L10 16.0001L12.1 14.3701L13.52 15.7901L9.99 18.5401L2.62 12.8101L1 14.0701L10 21.0701L14.95 17.2201L18.73 21.0001L20.14 19.5901L1.41 0.860107ZM10 13.4701L4.26 9.00011L5.65 7.92011L10.67 12.9401L10 13.4701Z"
      fill="#FFF"
    />
  </svg>
);
