import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';
import ReportItemDetail from '../../../common/reportItems/reportItemDetail';
import DurationDetailChart from './DurationDetailChart';
import DurationDetailTable from './DurationDetailTable';
import {
  ContentVisualizationMode,
  Duration,
  DurationCategory,
  DurationData,
  DurationLocation,
  ReportItemInfo,
} from '../../../common/types/projectsHierarchy';
import { ReportItemType } from '../../../common/reportItems/types';
import { MainContext } from '../../../common/types/mainContext';
import { useElementScreenshot } from '../../../common/hooks/useElementScreenshot';
import { exportSettingsModel } from '../../../common/export';
import { ShowAverageToggle } from '../../../common/reportItems/reportItemDetail/showAverageToggle/showAverageToggle';
import { convertDataForExcel } from './utils/convertDataForExcel';
import {
  getDurationCategoryByNumber,
  getDurationCategoryNumberByName,
  sortDurationCategoryArray,
} from '../../../common/utils/itemizeDurationCategoryObject';

export const DurationDetail = () => {
  const { t } = useTranslation();

  const {
    selection: { selectedProject, selectedReport },
    selectedLocationsIds,
    contentVisualizationMode,
    reportItemsDetails: { showAverageValuesInChart },
  } = useContext(MainContext);
  const { onMakeScreenshot, base64Image, elementRef, onClearScreenshotData } = useElementScreenshot({
    backgroundColor: `rgba(37, 55, 76, 1)`,
  });
  const exportOptions = useStore(exportSettingsModel.$exportOptions);
  const isChartReady = useRef<boolean>(false);
  const isExportMode = useStore(exportSettingsModel.$isExportMode);
  const [modifiedData, setModifiedData] = useState<DurationLocation[] | null>(null);
  const requiredDurationCategories = getRequiredDurationCategories(modifiedData);

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.DURATION,
  ) as ReportItemInfo<Duration>;

  const onChartReady = () => {
    isChartReady.current = true;
    if (exportOptions.DURATION?.chart && reportItem.data?.data) {
      onMakeScreenshot();
    }
  };

  useEffect(() => {
    if (!reportItem?.data?.data) {
      exportSettingsModel.updateExportData({ [ReportItemType.DURATION]: { chart: '', table: '' } });
    }
  }, [reportItem?.data?.data]);

  useEffect(() => {
    if (base64Image) {
      exportSettingsModel.updateExportData({ [ReportItemType.DURATION]: { chart: base64Image } });
      onClearScreenshotData();
    }
  }, [base64Image]);

  useEffect(() => {
    if (reportItem?.data?.data && exportOptions.DURATION?.table) {
      const data = convertDataForExcel(reportItem.data?.data);
      exportSettingsModel.updateExportData({ [ReportItemType.DURATION]: { table: data } });
    }
  }, [exportOptions.SEASONALITY]);

  useEffect(() => {
    const data = reportItem?.data;
    if (data) {
      let chartData = [...data?.data];
      if (chartData.length > 1 && showAverageValuesInChart) {
        chartData = [
          {
            location_id: 'average',
            location_name: 'Average',
            data: data?.averageData || [],
          },
          ...chartData,
        ];
      }
      setModifiedData(fillMissingDurationValues(getRequiredDurationCategories(chartData), chartData));
    }
  }, [reportItem.data, showAverageValuesInChart]);

  const render = (data: DurationLocation[] | null) => {
    if (!data) return;
    if (contentVisualizationMode === ContentVisualizationMode.VISUALIZATION || isExportMode) {
      return (
        <DurationDetailChart
          data={data}
          projectLocations={selectedProject?.locations || []}
          isScreenshotMode={isExportMode}
          durationCategories={requiredDurationCategories}
          onReady={onChartReady}
        />
      );
    }
    return <DurationDetailTable data={data || []} />;
  };

  const minimizedData = useMemo(
    () => render(modifiedData),
    [modifiedData, contentVisualizationMode, showAverageValuesInChart, isExportMode],
  );

  return (
    <div ref={elementRef} style={{ width: isExportMode ? 'auto' : '100%', height: isExportMode ? 'auto' : '100%' }}>
      <ReportItemDetail
        type={ReportItemType.DURATION}
        title={t('duration.title')}
        subtitle={t('duration.detail.showingAvgTime')}
        noData={!reportItem?.data?.data || selectedLocationsIds.length <= 0}
        onMakeScreenshot={onMakeScreenshot}
      >
        {contentVisualizationMode === ContentVisualizationMode.VISUALIZATION && <ShowAverageToggle />}
        {reportItem?.data && minimizedData}
      </ReportItemDetail>
    </div>
  );
};

// This should really be in the backend or even better write a class for the duration categories instead of an enum.
/**
 * Accumulates all duration categories for which values exist considering all locations used in report.
 * Returns array sorted
 * @param data
 */
function getRequiredDurationCategories(data: DurationLocation[] | null): DurationCategory[] {
  let durationCategories: DurationCategory[] | undefined;
  if (data === null || data === undefined) {
    return [];
  }

  // iterate through all locations -> iterate through all their respective data,
  // check if their duration categories are already used by other locations, if not add
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].data.length; j++) {
      if (!durationCategories?.map((item) => item.valueOf()).includes(data[i].data[j].category.valueOf())) {
        durationCategories?.push(data[i].data[j].category);
      }
    }
  }
  return sortDurationCategoryArray(durationCategories);
}

// shitty workaround really, should be done during data creation, like filling everything with values and then truncating if
// values are 0 for any category for all locations. Preferrably in backend
/**
 * Fills missing values for locations which have no data for any of the duration categories that are being displayed
 * @param durationCategories
 * @param locations
 */
function fillMissingDurationValues(
  durationCategories: DurationCategory[],
  locations: DurationLocation[] | null,
): DurationLocation[] {
  if (locations === null || locations === undefined) {
    return [];
  }
  // iterate through all locations and their data
  for (let i = 0; i < locations.length; i++) {
    if (locations[i] === null || locations[i] === undefined) {
      return [];
    }
    locations[i].data = completeDataBuilder(locations[i].data,durationCategories);
  }
  return locations;
}

/**
 *
 * @param durationDataArr
 * @param durationCategories
 */
function completeDataBuilder(durationDataArr: DurationData[], durationCategories: DurationCategory[]): DurationData[] {
  let newData: DurationData[] = [];

  const presentDurationCategoriesNumArr = durationDataArr
    .map((item) => getDurationCategoryNumberByName(item.category))
    .sort();
  const requiredDurationCategoriesNumArr = durationCategories
    .map((item) => getDurationCategoryNumberByName(item))
    .sort();

  // check only at locations with missing data: if all categories already present, return present data
  if (
    presentDurationCategoriesNumArr.toString() === requiredDurationCategoriesNumArr.toString()
  ) {
    return durationDataArr;
  }

  console.log(durationDataArr.map(item=>item.category));
  // iterate through all required duration categories
  for (
    let k = requiredDurationCategoriesNumArr[0];
    k < requiredDurationCategoriesNumArr.length;
    k++
  ) {
    if (presentDurationCategoriesNumArr.includes(k)) {
      newData[k] = {
        category: getDurationCategoryByNumber(k),
        value:
          durationDataArr[
            durationDataArr
              .map((item) => getDurationCategoryNumberByName(item.category))
              .findIndex((item) => item === k) // find value corresponding to duration category we want to add
          ].value,
      };
    } else {
      newData[k] = { category: getDurationCategoryByNumber(k), value: 0 };
    }
  }
  return newData;
}
