import { ReportItemInfo, Catchment } from '../../common/types/projectsHierarchy';
import { CatchmentData, PolygonData, CatchmentItemData } from './types';
import { Point, CatchmentArea } from '../../common/types/visualizationObjects';
import { mapReportItemType } from '../utils';
import { convertPolygonToGeoData } from '../../common/maps/utils/convertPolygonToGeoData';
import { getCenterCoordinate } from '../../common/maps/utils/getCenterCoordinate';
import { getMinMax } from '../../common/utils/getMinMax';
import { separateThousandsByComma, countries } from '../../common/utils';

export const mapResponse = (catchmentData: CatchmentData, polygonData: PolygonData): ReportItemInfo<Catchment> => {
  const mapPolygons = (catchmentItems: CatchmentItemData[]): CatchmentArea[] =>
    catchmentItems
      .map((item) => {
        const polygon = polygonData.find((poly) => poly.index === item.index)?.polygon;
        const geo = polygon ? convertPolygonToGeoData(polygon) : null;
        const coordinates = polygon ? getCenterCoordinate(polygon) : [];
        const countryCode = item.index.substring(0, 2);
        const country = countries.find((i) => i.code === countryCode);

        return {
          location_id: item.index,
          name: `${item.geo_entity_name ? `${item.geo_entity_name}: ` : ''}${item.geo_entity_code}`,
          centerPointLongitude: coordinates.length > 1 ? coordinates[0] : 0,
          centerPointLatitude: coordinates.length > 1 ? coordinates[1] : 0,
          sourceRank: item.rank_geo_entity_share_of_source,
          destinationRank: item.rank_geo_entity_share_of_target,
          scoreRank: item.rank_geo_entity_share_of_score,
          sourceValue: item.geo_entity_share_of_source,
          destinationValue: item.geo_entity_share_of_target,
          scoreValue: item.geo_entity_catchment_score,
          sourceIsochrone: item.isochrone_share_of_source,
          destinationIsochrone: item.isochrone_share_of_target,
          scoreIsochrone: item.isochrone_catchment_score,
          isochroneAerialDistanceRange: item.isochrone_aerial_distance_range,
          inhabitants: item.inhabitants || 0,
          households: item.households || 0,
          average_income: item.average_income || 0,
          purchasing_power: item.purchasing_power || 0,
          geo,
          popupData: {
            postalCodes: [
              item.geo_entity_share_of_source !== undefined && item.geo_entity_share_of_source !== null
                ? {
                    name: 'catchment.enum.SOURCE',
                    value: `${(Math.floor(item.geo_entity_share_of_source * 10000) / 100.0).toFixed(2)} %`,
                  }
                : null,
              item.geo_entity_share_of_target !== undefined && item.geo_entity_share_of_target !== null
                ? {
                    name: 'catchment.enum.DESTINATION',
                    value: `${(Math.floor(item.geo_entity_share_of_target * 10000) / 100.0).toFixed(2)} %`,
                  }
                : null,
              item.geo_entity_catchment_score !== undefined && item.geo_entity_catchment_score !== null
                ? {
                    name: 'catchment.enum.SCORE',
                    value: `${(Math.floor(item.geo_entity_catchment_score * 10000) / 100.0).toFixed(2)}`,
                  }
                : null,
              { name: 'catchment.popup.aerialDistance', value: `${item.aerial_distance} km` },
              item.inhabitants
                ? { name: 'catchment.detail.inhabitants', value: separateThousandsByComma(item.inhabitants) }
                : null,
              item.households
                ? { name: 'catchment.detail.households', value: separateThousandsByComma(item.households) }
                : null,
              item.average_income
                ? {
                    name: 'catchment.detail.avgIncome',
                    value: `${separateThousandsByComma(item.average_income.toFixed(0))} ${country?.currency || ''}`,
                  }
                : null,
              item.purchasing_power
                ? {
                    name: 'catchment.detail.totalSpending',
                    value: `${separateThousandsByComma(item.purchasing_power.toFixed(0))} ${country?.currency || ''}`,
                  }
                : null,
            ].filter((item) => item),
            isochrones: [
              item.isochrone_share_of_source !== undefined && item.isochrone_share_of_source !== null
                ? {
                    name: 'catchment.enum.SOURCE',
                    value: `${(Math.floor(item.isochrone_share_of_source * 10000) / 100.0).toFixed(2)} %`,
                  }
                : null,
              item.isochrone_share_of_target !== undefined && item.isochrone_share_of_target !== null
                ? {
                    name: 'catchment.enum.DESTINATION',
                    value: `${(Math.floor(item.isochrone_share_of_target * 10000) / 100.0).toFixed(2)} %`,
                  }
                : null,
              item.isochrone_catchment_score !== undefined && item.isochrone_catchment_score !== null
                ? {
                    name: 'catchment.enum.SCORE',
                    value: `${(Math.floor(item.isochrone_catchment_score * 10000) / 100.0).toFixed(2)}`,
                  }
                : null,
            ].filter((item) => item),
          },
        };
      })
      .filter((item) => item.geo !== null) as CatchmentArea[];

  const mapPoints = (catchmentItems: CatchmentItemData[]) =>
    catchmentItems
      .map((item) => {
        const polygon = polygonData.find((poly) => poly.index === item.index)?.polygon;
        const coordinates = polygon ? getCenterCoordinate(polygon) : [];
        return {
          location_id: item.index,
          name: item.geo_entity_name,
          value: item.geo_entity_share_of_target,
          rank: item.rank_geo_entity_share_of_target,
          longitude: coordinates.length > 1 ? coordinates[0] : 0,
          latitude: coordinates.length > 1 ? coordinates[1] : 0,
        };
      })
      .filter((item) => item.longitude !== 0 && item.latitude !== 0)
      .sort((a, b) => b.value - a.value) as Point[];

  const sourceHomeMinMax = getMinMax(catchmentData.values[0].home, 'rank_geo_entity_share_of_source');
  const sourceWorkMinMax = getMinMax(catchmentData.values[0].work, 'rank_geo_entity_share_of_source');
  const destinationHomeMinMax = getMinMax(catchmentData.values[0].home, 'rank_geo_entity_share_of_target');
  const destinationWorkMinMax = getMinMax(catchmentData.values[0].work, 'rank_geo_entity_share_of_target');
  const scoreHomeMinMax = getMinMax(catchmentData.values[0].home, 'rank_geo_entity_share_of_score');
  const scoreWorkMinMax = getMinMax(catchmentData.values[0].work, 'rank_geo_entity_share_of_score');

  const isoSourceHomeMinMax = getMinMax(catchmentData.values[0].home, 'isochrone_share_of_source');
  const isoSourceWorkMinMax = getMinMax(catchmentData.values[0].work, 'isochrone_share_of_source');
  const isoDestinationHomeMinMax = getMinMax(catchmentData.values[0].home, 'isochrone_share_of_target');
  const isoDestinationWorkMinMax = getMinMax(catchmentData.values[0].work, 'isochrone_share_of_target');
  const isoScoreHomeMinMax = getMinMax(catchmentData.values[0].home, 'isochrone_catchment_score');
  const isoScoreWorkMinMax = getMinMax(catchmentData.values[0].work, 'isochrone_catchment_score');

  return {
    id: catchmentData.id,
    name: catchmentData.name,
    type: mapReportItemType(catchmentData.type),
    data: {
      countries: catchmentData.countries,
      reportLocations: catchmentData.reportLocations,
      data: catchmentData.values.map((item) => ({
        ...item,
        geo_entity_type: catchmentData?.values[0]?.geo_entity_type || '', // TODO: TMA fix name depending on country
      })),
    },
    visualization: {
      indexPolygons: [
        {
          location_id: catchmentData.values[0].location_id,
          home: {
            sourceMinRank: sourceHomeMinMax[0],
            sourceMaxRank: sourceHomeMinMax[1],
            destinationMinRank: destinationHomeMinMax[0],
            destinationMaxRank: destinationHomeMinMax[1],
            scoreMinRank: scoreHomeMinMax[0],
            scoreMaxRank: scoreHomeMinMax[1],
            isochroneSourceMinRank: isoSourceHomeMinMax[0] * 100,
            isochroneSourceMaxRank: isoSourceHomeMinMax[1] * 100,
            isochroneDestinationMinRank: isoDestinationHomeMinMax[0] * 100,
            isochroneDestinationMaxRank: isoDestinationHomeMinMax[1] * 100,
            isochroneScoreMinRank: isoScoreHomeMinMax[0] * 100,
            isochroneScoreMaxRank: isoScoreHomeMinMax[1] * 100,
            polygons: mapPolygons(catchmentData.values[0].home),
          },
          work: {
            sourceMinRank: sourceWorkMinMax[0],
            sourceMaxRank: sourceWorkMinMax[1],
            destinationMinRank: destinationWorkMinMax[0],
            destinationMaxRank: destinationWorkMinMax[1],
            scoreMinRank: scoreWorkMinMax[0],
            scoreMaxRank: scoreWorkMinMax[1],
            isochroneSourceMinRank: isoSourceWorkMinMax[0] * 100,
            isochroneSourceMaxRank: isoSourceWorkMinMax[1] * 100,
            isochroneDestinationMinRank: isoDestinationWorkMinMax[0] * 100,
            isochroneDestinationMaxRank: isoDestinationWorkMinMax[1] * 100,
            isochroneScoreMinRank: isoScoreWorkMinMax[0] * 100,
            isochroneScoreMaxRank: isoScoreWorkMinMax[1] * 100,
            polygons: mapPolygons(catchmentData.values[0].work),
          },
        },
      ],
      heatmap: [
        {
          location_id: catchmentData.values[0].location_id,
          home: {
            minValue: sourceHomeMinMax[0],
            maxValue: sourceHomeMinMax[1],
            points: mapPoints(catchmentData.values[0].home),
          },
          work: {
            minValue: sourceWorkMinMax[0],
            maxValue: sourceWorkMinMax[1],
            points: mapPoints(catchmentData.values[0].work),
          },
        },
      ],
    },
  };
};
