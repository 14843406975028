import React from 'react';
import classNames from 'classnames';
import styles from './monthCheckbox.module.scss';
import { Checkbox } from '../../controls/checkbox';

interface Props {
  id: string;
  name: string;
  label: string;
  value: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (value: string, checked: boolean) => void;
}

const MonthCheckbox: React.FC<Props> = ({ id, name, label, value, disabled, checked, onChange }) => {
  const labelClassNames = classNames(disabled ? styles.labelDisabled : '');

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value, e.target.checked);
  };

  return (
    <div className={styles.container}>
      <label className={labelClassNames} htmlFor={id}>
        {label}
      </label>
      <Checkbox onChange={changeHandler} isChecked={checked} disabled={disabled} id={id} name={name} />
    </div>
  );
};

MonthCheckbox.defaultProps = {
  disabled: false,
};

export default MonthCheckbox;
