import { SwitchModes } from '../../../../types/visualizationObjects';
import { CatchmentMapTypesEnum } from '../enums';

const mapModestoTypes = {
  [SwitchModes.POSTAL_CODES]: CatchmentMapTypesEnum.POSTAL_CODES,
  [SwitchModes.HEATMAP]: CatchmentMapTypesEnum.HEAT_MAP,
  [SwitchModes.HOTSPOTS]: CatchmentMapTypesEnum.HEAT_MAP,
  [SwitchModes.TRIPS]: CatchmentMapTypesEnum.TRIPS,
  [SwitchModes.ISOCHRONES]: CatchmentMapTypesEnum.ISOCHRONES,
};

export const getTypeFromMapMode = (item: Partial<SwitchModes>) => mapModestoTypes[item];
