import React, { useEffect, useMemo, useState } from 'react';
import styles from './style.module.scss';
import Button from '../primaryButton/button';
import { CrossIcon } from './cross';

export interface MultiOption {
  id: number | string;
  value: boolean;
  label: string;
  disabled?: boolean;
}

interface Props {
  options: MultiOption[];
  onSave: (data: MultiOption[]) => void;
  maxItems?: number;
}

const ELEMENT_HEIGHT = 38;
export const MultiSelectWithButtons: React.FC<Props> = ({ options, onSave, maxItems = 5 }) => {
  const [selected, setSelected] = useState<MultiOption[]>([]);
  const [isOpen, toggleIsOpen] = useState(false);

  useEffect(() => {
    if (options.length) setSelected(options);
  }, [options]);

  const onChange = (option: MultiOption) => {
    setSelected((prevState) =>
      prevState.map((item) => (item.id === option.id ? { ...item, value: !item.value } : item)),
    );
  };

  const onCancel = () => {
    setSelected(options);
    toggleIsOpen(false);
  };

  const onSubmit = () => {
    onSave(selected);
    toggleIsOpen(false);
  };

  const onToggle = () => {
    toggleIsOpen((prevState) => !prevState);
  };

  const onClear = () => {
    setSelected((prevState) => prevState.map((item) => ({ ...item, value: false })));
  };

  const height = ELEMENT_HEIGHT * maxItems;

  const currentItemsHeight = options.length * ELEMENT_HEIGHT;

  const isMoreOrEqualThenDisplay = options.length >= 5;

  const displaySelectedLabels = useMemo(
    () => selected.filter(({ value }) => value).map(({ label }) => label),
    [selected],
  );
  return (
    <div className={styles.dropdown}>
      <div className={styles.active} onClick={onToggle}>
        <p>{displaySelectedLabels.length > 0 ? displaySelectedLabels.join(',') : 'Select'}</p>
        <span className={styles.icon} onClick={onClear}>
          <CrossIcon className={styles.cross} />
        </span>
      </div>
      {isOpen && (
        <div className={styles.list}>
          <ul
            className={styles.items}
            style={{ height: `${isMoreOrEqualThenDisplay ? height : currentItemsHeight}px` }}
          >
            {selected.map((option) => {
              const { label, id, value } = option;
              return (
                <div
                  key={id}
                  onClick={() => onChange(option)}
                  className={`${styles.item} ${value ? styles.selected : ''}`}
                >
                  <input type="checkbox" checked={Boolean(value)} /> <span>{label}</span>
                </div>
              );
            })}
          </ul>
          <div className={styles.actions}>
            <button onClick={onCancel}>Cancel</button>
            <Button onClick={onSubmit}>Save</Button>
          </div>
        </div>
      )}
    </div>
  );
};
