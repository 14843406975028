import React, {Component, useState} from 'react';
import _ from 'lodash';
import Modal from 'react-modal';
import Dropdown from 'react-dropdown';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {Tooltip, MenuItem} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import {Select, Checkbox, TextField} from '@mui/material';
import styles from './createReportItem.module.css';
import { Location } from '../../../types/location';
import Button from '../../atoms/primaryButton/button';
import { PostProcessingSetting, Report, ReportItem, ReportItemSetting } from '../../../types/report';
import { ReportItemSettingDefinition, SettingDefinitionsForItems } from '../../../types/reportItemSettingDefinition';
import { displayNameForReportItemType } from '../../../reportItemTypes';
import Help from '../../atoms/help/help';
import MultiSelect from '../../atoms/multiselect/multiselect';
import MultiselectForDisplay from '../../atoms/multiselectForDisplay/multiselectForDisplay';
import MultiSelectEditableSearchDisabled
  from "../../molecules/dropdowns/postprocessingMultiSelects/multiSelectEditableSearchDisabled";
import {stat} from "fs";
import InputAdornment from "@material-ui/core/InputAdornment";

interface Props {
  isEnable: boolean;
  projectId: string;
  report: Report;
  settingDefinitions?: SettingDefinitionsForItems;
  reportItemTypes?: string[];
  reportItemsToDuplicate?: ReportItem[];
  createReportItems: (report: Report, projectId: string) => any;
  reloadReportItemsInReportsPage: () => any;
  rerunReportWithPostProcessingSettings: (postProcessingSettings: any, report: Report) => any;
  locations: Location[] | undefined;
}

interface StateProps {
  settingDefinitions?: SettingDefinitionsForItems;
  locations?: Location[];
  reportItemTypes?: string[];
}

interface DispatchProps {}

interface ReportItemState {
  internalId: string;
  reportItemType: string;
  name: string;
  description: string;
  settings: ReportItemSetting[];
}

interface ItemError {
  reportItemDetails: ReportItemDetails;
  errors: string[];
  errorInItemName: boolean;
}

interface ReportItemDetails {
  internalId: string;
  reportItemType: string;
}

interface State {
  isModalOpenMoreRIs: boolean;
  isModalOpenPostProcessing: boolean;
  name: string;
  description: string;
  reportCategory: string;
  startDay: Date;
  endDay: Date;
  selectedReportItemTypes: ReportItemDetails[];
  reportItems: ReportItemState[];
  itemErrors: ItemError[];
  candidateSelectedReportTypeToAdded: string;
  postProcessingSettingState: PostProcessingSetting[];
  isModalOpenConfirmPostProcessing: boolean;
  isModalOpenRerunStatus: boolean;
  prevRerunStatus: string | undefined;
  selectedPOIs: Option[];
  selectedDaysOfTheWeek: Option[];
  selectedPopulationGroups: Option[];
  isCheckedResidents:boolean;
  isCheckedEmployees: boolean;
}

interface postSetting {
  key: string;
  value: string;
}

export default class CreateReportItem extends Component<Props & StateProps & DispatchProps, State> {
  constructor(props: Readonly<Props & StateProps & DispatchProps>) {
    super(props);
    const { report } = this.props;
    const { locations } = this.props;
    const preConfiguredItemsFromDuplicate = this.initReportItems(report);
    this.state = {
      isModalOpenMoreRIs: false,
      isModalOpenPostProcessing: false,
      isModalOpenConfirmPostProcessing: false,
      name: report.name,
      description: report.description,
      reportCategory: report.reportCategory,
      startDay: new Date(report.startDay),
      endDay: new Date(report.endDay),
      selectedReportItemTypes: preConfiguredItemsFromDuplicate,
      reportItems: preConfiguredItemsFromDuplicate,
      itemErrors: [],
      candidateSelectedReportTypeToAdded: '',
      postProcessingSettingState: this.initializePostProcessingSettings(),
      isModalOpenRerunStatus: false,
      prevRerunStatus: undefined,
      selectedPOIs: [],
      selectedDaysOfTheWeek: [],
      selectedPopulationGroups: [],
      isCheckedResidents: false,
      isCheckedEmployees: false,
    };
    this.renderPostProcessingSettingsItemEditable = this.renderPostProcessingSettingsItemEditable.bind(this);
    this.renderPostProcessingSettingsItemNotEditable = this.renderPostProcessingSettingsItemNotEditable.bind(this);
  }

  render() {
    return (
      <>
        {this.renderModalMoreRIs()}
        {this.renderModalPostProcessing()}
        <div className={styles.groupActions}>
          {this.props.reportItemsToDuplicate && this.props.reportItemsToDuplicate.length > 0 ? (
            this.props.isEnable ? (
              <Tooltip title="Duplicate Report Item">
                <FileCopyIcon
                  className={styles.optionIcon}
                  fontSize="inherit"
                  onClick={() => {
                    this.openModalMoreRIs();
                  }}
                />
              </Tooltip>
            ) : (
              <div />
            )
          ) : (
            <div className={styles.sideBySide}>
              <Button
                disabled={
                  !this.props.isEnable ||
                  !(this.props.report.status === 'READY' || this.props.report.status === 'PRESENTABLE')
                }
                onClick={() => {
                  if (this.props.isEnable) {
                    this.openModalMoreRIs();
                  }
                }}
              >
                Create more report items
              </Button>
              <Button
                disabled={
                  !this.props.isEnable ||
                  !(this.props.report.status === 'READY' || this.props.report.status === 'PRESENTABLE')
                }
                onClick={() => {
                  if (this.props.isEnable) {
                    this.openModalPostProcessing();
                  }
                }}
              >
                Data adjustments
              </Button>
            </div>
          )}
        </div>
        {this.renderModalRerunStatus()}
      </>
    );
  }

  private renderModalMoreRIs() {
    return (
      <Modal
        isOpen={this.state.isModalOpenMoreRIs}
        onRequestClose={() => this.closeModalMoreRIs()}
        ariaHideApp={false}
        className={styles.modal}
      >
        <div className={styles.modalContent}>
          <div className={styles.topBar}>
            <div className={styles.createMoreItemsMessage}>
              <span className={styles.emphasized}>Create more report items to add to current report</span>
            </div>
            <div className={styles.modalTopButtons}>
              <Button
                onClick={() => {
                  if (this.createReportItems()) {
                    this.clearForm();
                    this.closeModalMoreRIs();
                    this.reloadReports();
                  }
                }}
              >
                Create
              </Button>
              <Button
                secondary
                onClick={() => {
                  this.closeModalMoreRIs();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>

          {this.renderCreateReportItemsForm()}
          {this.renderClear()}
        </div>
      </Modal>
    );
  }

  private openModalMoreRIs() {
    this.setState({ isModalOpenMoreRIs: true });
  }

  private clearForm() {
    const itemsToDuplicate = this.initReportItems(this.props.report);
    this.setState({
      selectedReportItemTypes: itemsToDuplicate,
      reportItems: itemsToDuplicate,
      itemErrors: [],
      candidateSelectedReportTypeToAdded: '',
    });
  }

  private reloadReports() {
    this.props.reloadReportItemsInReportsPage();
    setTimeout(this.props.reloadReportItemsInReportsPage(), 20);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 80);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 200);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 200);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 300);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 500);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 1000);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 1000);
    setTimeout(this.props.reloadReportItemsInReportsPage(), 10000);
  }

  private closeModalMoreRIs() {
    this.setState({ isModalOpenMoreRIs: false });
  }

  private createReportItems(): boolean {
    if (this.isFormValid() && this.state.selectedReportItemTypes.length > 0) {
      const reportItems: ReportItem[] = this.state.selectedReportItemTypes.map((reportItemType) =>
        this.reportItemState(reportItemType),
      );
      this.props.createReportItems(
        {
          id: this.props.report.id,
          description: '',
          endDay: '',
          locations: [],
          name: '',
          reportCategory: '',
          reportItems,
          startDay: '',
          visualizationURL: '',
          recurring: false,
          recurrentNotificationEmail: '',
        },
        this.props.projectId,
      );
    } else {
      return false;
    }
    return true;
  }

  private renderCreateReportItemsForm() {
    if (this.props.settingDefinitions && this.props.reportItemTypes) {
      return (
        <div>
          <div className={styles.itemSelector}>{this.renderAvailableReportItems()}</div>
          <hr className={styles.delimiter} />
          <div className={styles.formContainer}>
            {this.renderReportItems()}
            {this.renderErrorMessage()}
          </div>
        </div>
      );
    }
    return null;
  }

  private renderClear() {
    if (this.state.selectedReportItemTypes?.length > 0) {
      return (
        <div>
          <hr className={styles.delimiter} />
          <div className={styles.modalBottomButtons}>
            <div className={styles.modalButtons}>
              <Button
                warning
                onClick={() => {
                  this.clearForm();
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  private renderErrorMessage() {
    return (
      // <div className={styles.fieldErrorMessage}>{this.props.errorMessage}</div>
      <div />
    );
  }

  private renderAvailableReportItems() {
    if (this.props.reportItemTypes) {
      const initialTypeMsg = 'Report Item...';
      return (
        <div className={styles.formRow}>
          <div className={styles.addRiContainer}>
            <Dropdown
              className={styles.addRiDropdown}
              options={[initialTypeMsg].concat(
                this.props.reportItemTypes.sort().map((fullName) => fullName.substr(fullName.indexOf('.') + 1)),
              )}
              onChange={(event) => this.onChangeAddReportItemDropdown(`${this.state.reportCategory}.${event.value}`)}
              value={
                this.state.candidateSelectedReportTypeToAdded === ''
                  ? initialTypeMsg
                  : this.state.candidateSelectedReportTypeToAdded.substr(
                      this.state.candidateSelectedReportTypeToAdded.indexOf('.') + 1,
                    )
              }
              placeholder="Select report item to add"
            />
            <div className={styles.addRiButtonContainer}>
              <Button onClick={() => this.onAddReportItemButton()}>Add</Button>
            </div>
          </div>
        </div>
      );
    }
  }

  private renderRemoveReportItem(reportItemInternalId: string) {
    return (
      <div className={styles.removeRiButtonContainer}>
        <Button onClick={() => this.onRemoveReportItemButton(reportItemInternalId)}>Remove</Button>
      </div>
    );
  }

  private renderReportItems() {
    return <>{this.state.selectedReportItemTypes.sort().map((reportItem) => this.renderReportItem(reportItem))}</>;
  }

  private renderReportItem(reportItem: ReportItemDetails) {
    const { reportItemType } = reportItem;
    const reportItemTitle = reportItemType.substr(reportItemType.indexOf('.') + 1);
    const reportItemState = this.reportItemState(reportItem);
    const isReportItemNameInvalid = this.state.itemErrors.find(
      (item) => item.reportItemDetails.internalId === reportItem.internalId,
    )?.errorInItemName;
    return (
      <div className={styles.reportItem} key={reportItem.internalId}>
        <div className={styles.reportItemTitleContainer}>
          <div className={styles.reportItemTitle}>
            <h2>
              {this.state.selectedReportItemTypes.indexOf(reportItem) + 1}.{' '}
              {displayNameForReportItemType(reportItemTitle)}
            </h2>
            {this.renderRemoveReportItem(reportItem.internalId)}
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.fieldName}>Item name</div>
          <div className={styles.valueContainer}>
            <input
              className={styles.input}
              value={reportItemState.name}
              onChange={(event) => {
                const name = event.target.value;
                this.updateReportItemProperty(reportItemState, 'name', name);
              }}
            />
            {isReportItemNameInvalid && (
              <div className={styles.fieldErrorMessage}>Please provide valid report item name</div>
            )}
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.fieldName}>Item description</div>
          <div className={styles.valueContainer}>
            <textarea
              className={styles.textarea}
              value={reportItemState.description}
              onChange={(event) => {
                const description = event.target.value;
                this.updateReportItemProperty(reportItemState, 'description', description);
              }}
            />
          </div>
        </div>
        {this.renderItemSettings(reportItem)}
      </div>
    );
  }

  private updateReportItemProperty(reportItem: ReportItemDetails, propertyName: string, value: string) {
    this.setState((prevState) => {
      const newItemState = _.assign({}, this.reportItemState(reportItem, prevState), {
        [propertyName]: value,
      });
      const reportItems = [
        ...prevState.reportItems.filter((item) => item.internalId !== reportItem.internalId),
        newItemState,
      ];
      return { reportItems };
    });
  }

  /**
   * Generates a new Report Item (State)
   * @param internalIdInput Id of new Report Item
   * @param reportItemTypeInput
   * @param settingsForItem
   * @private
   */
  private generateReportItemState(
    internalIdInput: string,
    reportItemTypeInput: string,
    settingsForItem: { name: string; value: string }[],
  ): ReportItemState {
    return {
      internalId: internalIdInput !== null && internalIdInput && internalIdInput !== '' ? internalIdInput : uuidv4(),
      reportItemType: reportItemTypeInput,
      name: '',
      description: '',
      settings: settingsForItem,
    };
  }

  /**
   * Returns the state of a Report Item
   * @param reportItemInput
   * @param state
   * @private
   */
  private reportItemState(reportItemInput: ReportItemDetails, state: State = this.state): ReportItemState {
    const maybeItemState = state.reportItems.find((reportItem) => reportItem.internalId === reportItemInput.internalId);
    const { reportItemType } = reportItemInput;
    const defaultSettingsForItem = ((this.props.settingDefinitions || {})[reportItemType] || []).map(
      (settingDefinition) => ({
        name: settingDefinition.name,
        value: settingDefinition.defaultValue,
      }),
    );
    return (
      maybeItemState || this.generateReportItemState(reportItemInput.internalId, reportItemType, defaultSettingsForItem)
    );
  }

  private renderItemSettings(reportItem: ReportItemDetails) {
    const { settingDefinitions } = this.props;
    const { reportItemType } = reportItem;
    const reportItemTitle = reportItemType.substr(reportItemType.indexOf('.') + 1);
    if (settingDefinitions && settingDefinitions[reportItemType]) {
      return (
        <div className={styles.reportItemSettings}>
          <h2>{displayNameForReportItemType(reportItemTitle)} settings</h2>
          {settingDefinitions[reportItemType].map((settingDefinition) =>
            this.renderSettingForItem(reportItem, settingDefinition),
          )}
        </div>
      );
    }
    return null;
  }

  private renderSettingForItem(reportItem: ReportItemDetails, settingDefinition: ReportItemSettingDefinition) {
    const isSettingInvalid = this.isSettingInvalid(reportItem, settingDefinition.name);
    if (settingDefinition.settingType === 'simpleText') {
      return (
        <div key={settingDefinition.name} className={styles.formRow}>
          <div className={styles.fieldName}>
            {settingDefinition.name}
            {settingDefinition.isMandatory ? '*' : ''}
            {settingDefinition.description && <Help helpMessage={settingDefinition.description} />}
          </div>
          <div className={styles.valueContainer}>
            <input
              className={classNames(styles.input, { [styles.inputInvalid]: isSettingInvalid })}
              value={this.itemSettingValue(reportItem, settingDefinition.name, settingDefinition.defaultValue)}
              onChange={(event) => {
                const { value } = event.target;
                return this.updateItemSetting(reportItem, settingDefinition, value);
              }}
            />
            {isSettingInvalid && <div className={styles.fieldErrorMessage}>This field is required</div>}
          </div>
        </div>
      );
    }
    if (settingDefinition.settingType === 'singleChoice') {
      return (
        <div key={settingDefinition.name} className={styles.formRow}>
          <div className={styles.fieldName}>
            {settingDefinition.name}
            {settingDefinition.isMandatory ? '*' : ''}
            {settingDefinition.description && <Help helpMessage={settingDefinition.description} />}
          </div>
          <div className={styles.valueContainer}>
            <select
              className={classNames(styles.select, { [styles.inputInvalid]: isSettingInvalid })}
              value={this.itemSettingValue(reportItem, settingDefinition.name, settingDefinition.defaultValue)}
              onChange={(event) => {
                const { value } = event.target;
                return this.updateItemSetting(reportItem, settingDefinition, value);
              }}
            >
              {settingDefinition.possibleValues.map((possibleValue) => (
                <option key={possibleValue}>{possibleValue}</option>
              ))}
            </select>
            {isSettingInvalid && <div className={styles.fieldErrorMessage}>This field is required</div>}
          </div>
        </div>
      );
    }
    return null;
  }

  private updateItemSetting(
    reportItem: ReportItemDetails,
    settingDefinition: ReportItemSettingDefinition,
    value: string,
  ) {
    return this.setState((prevState) => {
      const reportItemState = this.reportItemState(reportItem, prevState);
      const newItemState = _.assign({}, reportItemState, {
        settings: this.updateSettings(reportItemState.settings, settingDefinition, value),
      });
      return {
        reportItems: [
          ...prevState.reportItems.filter((item) => item.internalId !== reportItem.internalId),
          newItemState,
        ],
      };
    });
  }

  private updateSettings(
    settings: ReportItemSetting[],
    settingDefinition: ReportItemSettingDefinition,
    newValue: string,
  ) {
    if (settings.find((s) => s.name === settingDefinition.name)) {
      return settings.map((setting) => {
        if (setting.name === settingDefinition.name) {
          return { ...setting, value: newValue };
        }
        return setting;
      });
    }
    return [
      ...settings,
      {
        name: settingDefinition.name,
        value: newValue,
      },
    ];
  }

  private itemSettingValue(reportItemDetails: ReportItemDetails, settingName: string, defaultValue: string): string {
    const item = this.state.reportItems.find((reportItem) => reportItem.internalId === reportItemDetails.internalId);
    if (!item) {
      return defaultValue;
    }
    const maybeSetting = item.settings.find((setting) => setting.name === settingName);
    return maybeSetting ? maybeSetting.value : defaultValue;
  }

  private onChangeAddReportItemDropdown(value: string) {
    this.setState({ candidateSelectedReportTypeToAdded: value });
  }

  private onAddReportItemButton() {
    const selectedType = this.state.candidateSelectedReportTypeToAdded;
    if (selectedType !== null && selectedType !== '' && this.props.reportItemTypes?.includes(selectedType)) {
      this.setState({
        selectedReportItemTypes: this.state.selectedReportItemTypes.concat(
          this.generateReportItemState('', selectedType, []),
        ),
      });
    }
    this.setState({ candidateSelectedReportTypeToAdded: '' });
  }

  private onRemoveReportItemButton(reportItemInternalId: string) {
    const elementToRemove = this.state.selectedReportItemTypes.find(
      (value) => value.internalId === reportItemInternalId,
    );
    if (elementToRemove) {
      this.setState({
        selectedReportItemTypes: _.without(this.state.selectedReportItemTypes, elementToRemove),
      });
    }
  }

  private isFormValid() {
    const { settingDefinitions } = this.props;
    if (settingDefinitions) {
      let itemErrors: ItemError[] = _.keys(settingDefinitions).map((supportedReportItemType) => {
        const selectedItemErrors: ItemError[] = this.state.selectedReportItemTypes
          .filter((reportItemDetails) => reportItemDetails.reportItemType === supportedReportItemType)
          .map((selectedItemDetails) => {
            const definitions = settingDefinitions[supportedReportItemType] || [];
            const errorsForItem: string[] = definitions
              .map((definition) => {
                const settingValue = this.itemSettingValue(
                  selectedItemDetails,
                  definition.name,
                  definition.defaultValue,
                );
                const isEmpty = !settingValue || !settingValue.length;
                if (definition.isMandatory && isEmpty) {
                  return definition.name;
                }
                return '';
              })
              .filter((error) => !!error);
            return {
              reportItemDetails: selectedItemDetails,
              errors: errorsForItem,
              errorInItemName: this.isReportItemNameInvalid(selectedItemDetails),
            };
          });
        return selectedItemErrors;
      })[0];
      if (!itemErrors) {
        itemErrors = [];
      }
      this.calcErrorReportItemNameWithoutSettingDefinitions(itemErrors);
      this.setState({ itemErrors });
      return (
        itemErrors?.flatMap((itemError) => itemError.errors).length === 0 &&
        !itemErrors.find((itemError) => itemError.errorInItemName == true)
      );
    }
    // In case there are no global definitions at all, for any report type
    const itemErrors: ItemError[] = [];
    this.calcErrorReportItemNameWithoutSettingDefinitions(itemErrors);
    this.setState({ itemErrors });

    return false;
  }

  private isSettingInvalid(reportItemDetails: ReportItemDetails, settingName: string) {
    const item = this.state.itemErrors.find(
      (item) => item.reportItemDetails.internalId === reportItemDetails.internalId,
    ) || { errors: [] };
    return item.errors.find((erroneousSettingName) => erroneousSettingName === settingName);
  }

  private calcErrorReportItemNameWithoutSettingDefinitions(itemErrors: ItemError[]) {
    const errorsInItemsNames = this.state.selectedReportItemTypes.filter((item) => this.isReportItemNameInvalid(item));
    errorsInItemsNames.map((item) => {
      const itemAlreadyHasErrors = itemErrors.find((element) => element.reportItemDetails === item);
      if (itemAlreadyHasErrors) {
        itemAlreadyHasErrors.errorInItemName = true;
      } else {
        itemErrors.push({ reportItemDetails: item, errors: [], errorInItemName: true });
      }
    });
  }

  private isReportItemNameInvalid(reportItemDetails: ReportItemDetails) {
    const reportItemName = this.state.reportItems.find(
      (item) => item.internalId === reportItemDetails.internalId,
    )?.name;
    return !reportItemName || !this.validateReportItemName(reportItemName);
  }

  private validateReportItemName(value: string) {
    return value && value.length > 0;
  }

  private initReportItems(report: Report) {
    const preConfiguredItems = this.props.reportItemsToDuplicate;
    if (!preConfiguredItems || preConfiguredItems.length === 0) {
      return [];
    }
    return preConfiguredItems.map((item) => {
      const reportItemTypePrefix = `${report?.reportCategory}.`;
      return {
        internalId: uuidv4(),
        reportItemType:
          (item.reportItemType.includes(reportItemTypePrefix) ? '' : reportItemTypePrefix) + item.reportItemType,
        name: `${item.name}_COPY`,
        description: item.description,
        settings: item.settings,
      };
    });
  }

  // ===============================================================
  // ======================= Post Processing =======================
  // ===============================================================

  // ======================= change State functions =======================

  private openModalConfirmPostProcessing() {
    this.setState({ isModalOpenConfirmPostProcessing: true });
  }

  private closeModalConfirmPostProcessing() {
    this.setState({ isModalOpenConfirmPostProcessing: false });
  }

  private closeModalSuccessPostProcessing() {
    this.setState({ isModalOpenRerunStatus: false, prevRerunStatus: undefined });
    this.props.report.rerunStatus = undefined;
  }

  private resetPrevRerunStatus() {
    this.setState({ prevRerunStatus: undefined });
    this.props.report.rerunStatus = undefined;
  }

  private closeModalPostProcessing() {
    this.setState({ isModalOpenPostProcessing: false });
  }

  private openModalPostProcessing() {
    this.setState({ isModalOpenPostProcessing: true });
  }

  /**
   * Called if the input of a post processing setting field changes. Saves changes in state object.
   * @param setting
   * @param newValue
   * @private
   */
  private updatePostProcessingSettingItem(settingAnalyticsName: string, newValue: string) {
    this.setState((state) => {
      const postProcessingSettingState = this.postProcessingSettingsState(settingAnalyticsName, newValue, state);
      return { postProcessingSettingState };
    });
  }

  /**
   * Merges a changed field of a post processing settings field with the previous state without changing the pps field of the state directly.
   * Returns an updated array.
   * Identifies Settings field by their name.
   * @param setting The PPSetting that is to be updated
   * @param prevState The previous state (of the PPS field)
   * @private
   */
  private postProcessingSettingsState(
    settingAnalyticsName: string,
    newValue: string,
    prevState: State,
  ): PostProcessingSetting[] {
    const settingToBeChanged = prevState.postProcessingSettingState.find(
      (s) => s.analytics_name === settingAnalyticsName,
    );

    if (settingToBeChanged) {
      const newSetting = {
        name: settingToBeChanged.name,
        description: settingToBeChanged.description,
        analytics_name: settingToBeChanged.analytics_name,
        settings_type: settingToBeChanged.settings_type,
        order: settingToBeChanged.order,
        possibleValues: settingToBeChanged.possibleValues,
        value: newValue,
      };
      const changedPostProcessingSettingsArray = [
        ...prevState.postProcessingSettingState.filter((s) => s.analytics_name !== settingAnalyticsName),
        newSetting,
      ];
      return changedPostProcessingSettingsArray;
    }

    return prevState.postProcessingSettingState;
  }

  private initializePostProcessingSettings(): PostProcessingSetting[] {
    const postProcessingSettingsItem_location = {
      name: 'Location IDs',
      description:
        'Include specific Location IDs, e.g. <location1, location2>. No input equals selection of all locations',
      value: '',
      analytics_name: 'location_id_array',
      settings_type: 'multipleChoice',
      order: 0,
    };
    const postProcessingSettingsItem_durationAbove = {
      name: 'Duration: exclude visits above',
      description: 'Exclude visits above specified duration in minutes, e.g. <120> for any visit above 2 hours.',
      value: '',
      analytics_name: 'above_duration_threshold',
      settings_type: 'numbersTextField',
      order: 1,
    };
    const postProcessingSettingsItem_durationBelow = {
      name: 'Duration: exclude visits below',
      description: 'Exclude visits below specified duration in minutes, e.g. <5> for any visit below 5 minutes.',
      value: '',
      analytics_name: 'below_duration_threshold',
      settings_type: 'numbersTextField',
      order: 2,
    };
    const postProcessingSettingsItem_hoursBelow = {
      name: 'Hours of the day: exclude visits before',
      description: 'Excludes visits before the specified hour of the day, e.g. <8> for any visit before 08:00.',
      value: '',
      analytics_name: 'below_day_hours_to_exclude',
      settings_type: 'numbersTextField',
      order: 3,
    };
    const postProcessingSettingsItem_hoursAbove = {
      name: 'Hours of the day: exclude visits after',
      description: 'Excludes visits after the specified hour of the day, e.g. <20> for any visit after 20:00.',
      value: '',
      analytics_name: 'above_day_hours_to_exclude',
      settings_type: 'numbersTextField',
      order: 4,
    };
    const postProcessingSettingsItem_days = {
      name: 'Days of the week: days to exclude',
      description: 'Excludes visits on specified weekdays.',
      value: '',
      analytics_name: 'days_of_week_to_exclude',
      settings_type: 'multipleChoice',
      possibleValues: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      order: 5,
    };
    const postProcessingSettingsItem_origTableStart = {
      name: 'Start from the original tables',
      description: 'Select <true> to override previous changes, <false> to keep previous changes',
      value: 'false',
      analytics_name: 'use_original_tables',
      settings_type: 'singleChoice',
      possibleValues: ['True', 'False'],
      order: 8,
    };
    const postProcessingSettingsItem_excludeResidents = {
      name: 'Exclude residents',
      description: 'tbd',
      value: '',
      analytics_name: 'residents_to_exclude_buffer',
      settings_type: 'checkboxDropdown',
      order: 6,
    };
    const postProcessingSettingsItem_excludeEmployees = {
      name: 'Exclude employees',
      description: 'tbd',
      value: '',
      analytics_name: 'employees_to_exclude_buffer',
      settings_type: 'checkboxDropdown',
      order: 7,
    };
    return [
      postProcessingSettingsItem_location,
      postProcessingSettingsItem_durationAbove,
      postProcessingSettingsItem_durationBelow,
      postProcessingSettingsItem_hoursBelow,
      postProcessingSettingsItem_hoursAbove,
      postProcessingSettingsItem_days,
      postProcessingSettingsItem_origTableStart,
      postProcessingSettingsItem_excludeResidents,
      postProcessingSettingsItem_excludeEmployees,
    ];
  }

  private clearPostProcessingForm() {
    this.setState({
      postProcessingSettingState: this.initializePostProcessingSettings(),
      selectedDaysOfTheWeek: [],
      selectedPOIs: [],
      selectedPopulationGroups: [],
      isCheckedResidents: false,
      isCheckedEmployees: false,
    });
  }

  // ======================= render functions =======================

  private renderModalConfirmPostProcessingSettingsModal() {
    const rerunRequestInvalid = this.isPostProcessingFormInValid();
    return (
      <Modal
        isOpen={this.state.isModalOpenConfirmPostProcessing}
        onRequestClose={() => this.closeModalConfirmPostProcessing()}
        ariaHideApp={false}
        className={styles.modalConfirm}
      >
        <div className={styles.modalContent}>
          <div className={styles.topBar}>
            <div className={styles.createMoreItemsMessage}>
              <span className={styles.emphasized}>Are you sure you want to change the report tables?</span>
            </div>
            <div className={styles.modalTopButtons}>
                <Button
                    warning
                    onClick={() => {
                    if (this.rerunReportWithPostProcessingSettings()) {
                      this.clearPostProcessingForm();
                      this.closeModalPostProcessing();
                      this.closeModalConfirmPostProcessing();
                      this.reloadReports();
                      this.setState({ isModalOpenRerunStatus: true, selectedPOIs: [] });
                    }
                  }}
                >
                  Rerun
                </Button>
              <Button
                  secondary
                onClick={() => {
                  this.closeModalConfirmPostProcessing();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
          {this.renderPostProcessingSettingsConfirmContentOverview()}
        </div>
      </Modal>
    );
  }

  private renderToolTipRerunButton() {
    const isRerunRequestInvalid = this.isPostProcessingFormInValid();
    if (isRerunRequestInvalid) {
      return 'Not using original tables without any input is not valid';
    }
  }

  private renderPostProcessingSettingsItemNotEditable(item: PostProcessingSetting) {
    const { name } = item;
    const { description } = item;
    const { value } = item;
    const { analytics_name } = item;
    const { settings_type } = item;
    const { possibleValues } = item;
    if (settings_type === 'adjustableText') {
      return (
        <div className={styles.formRowPostProcessing} key={analytics_name}>
          <div className={styles.fieldNamePostProcessing}>
            {name}
            {description && <Help helpMessage={description} />}
          </div>
          <div className={styles.valueContainer}>
            <textarea
              contentEditable={false}
              disabled
              className={styles.textarea}
              onChange={(event) => {
                const newValue = event.target.value;
                return this.updatePostProcessingSettingItem(analytics_name, newValue);
              }}
              value={value}
            />
          </div>
        </div>
      );
    }
    if (settings_type === 'numbersTextField') {
      return (
        <div className={styles.formRowPostProcessing} key={analytics_name}>
          <div className={styles.fieldNamePostProcessing}>
            {name}
            {description && <Help helpMessage={description} />}
          </div>
          <div className={styles.valueContainer}>
            <TextField
                disabled={true}
                type={"number"}
                size={"small"}
                value={value}
            />
          </div>
        </div>
      );
    }
    if (settings_type === 'singleChoice') {
      const { possibleValues } = item;
      return (
        <div className={styles.formRowPostProcessing} key={analytics_name}>
          <div className={styles.fieldNamePostProcessing}>
            {name}
            {description && <Help helpMessage={description} />}
          </div>
          <div className={styles.valueContainer}>
            <Select
              // TODO display the selected values
              disabled
              style={{ height: '40px' }}
              value={value}
            >
              {possibleValues?.map((possibleValue) => (
                  <MenuItem
                      key={possibleValue.toString().toLowerCase()}
                      value={possibleValue.toString().toLowerCase()}
                      disabled={true}
                  >
                    {possibleValue.toString()}
                  </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      );
    }
    if (settings_type === 'multipleChoice') {
      const { possibleValues } = item;
      return (
          <div className={styles.formRowPostProcessing} key={analytics_name}>
            <div className={styles.fieldNamePostProcessing}>
              {name}
              {description && <Help helpMessage={description} />}
            </div>
            {analytics_name === 'location_id_array' && this.renderAssignedPOIsMultiSelectDisabled()}
            {analytics_name === 'days_of_week_to_exclude' &&
                this.renderMultiSelectNotEditableSearchDisabled(this.mapPossibleValuesToOptionsDisabled(possibleValues,this.mapDayOfTheWeekToValue),this.state.selectedDaysOfTheWeek)}
            {analytics_name === 'population_group_to_exclude' &&
                this.renderMultiSelectNotEditableSearchDisabled(this.mapPossibleValuesToOptionsDisabled(possibleValues, this.mapPopulationGroupToValue),this.state.selectedPopulationGroups)}
          </div>
      );
    }
    if(settings_type === 'checkboxDropdown'){
      let stateToBeChanged='';
      if(analytics_name === 'residents_to_exclude_buffer'){
        stateToBeChanged='isCheckedResidents'
      }
      else if(analytics_name === 'employees_to_exclude_buffer'){
        stateToBeChanged='isCheckedEmployees'
      }
      return(
          <div className={styles.formRowPostProcessing} key={analytics_name}>
            <div className={styles.fieldNamePostProcessing}>
              {name}
              {description && <Help helpMessage={description} />}
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox
                  disabled={true}
                  checked={this.state[stateToBeChanged as keyof State] as boolean}
              ></Checkbox>
            </div>
            <div className={styles.sideBySideInputContainer}>
              <TextField
                  disabled={true}
                  value={value}
                  type={"number"}
                  label={"Area around POI"}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                  }}
                  size={"small"}
              />
            </div>
          </div>
      )
    }
    }

  private renderPostProcessingSettingsConfirmContent() {
    return (
      <div>
        {this.state.postProcessingSettingState
          .sort((a, b) => this.comparePPS(a, b))
          .map(this.renderPostProcessingSettingsItemNotEditable)}
      </div>
    );
  }

  private renderPostProcessingSettingsConfirmContentOverview() {
    return (
      <div>
        <hr className={styles.delimiter} />
        <div className={styles.formContainer} />
        {this.renderPostProcessingSettingsConfirmContent()}
      </div>
    );
  }

  private renderPostProcessingSettingsForm() {
    return (
      <div>
        {this.state.postProcessingSettingState
          .sort((a, b) => this.comparePPS(a, b))
          .map(this.renderPostProcessingSettingsItemEditable)}
      </div>
    );
  }

  private renderPostProcessingSettingsItemEditable(item: PostProcessingSetting) {
    const { name } = item;
    const { description } = item;
    const { value } = item;
    const { analytics_name } = item;
    const { settings_type } = item;
    if (settings_type === 'numbersTextField') {
      return (
        <div className={styles.formRowPostProcessing} key={analytics_name}>
          <div className={styles.fieldNamePostProcessing}>
            {name}
            {description && <Help helpMessage={description} />}
          </div>
          <div className={styles.valueContainer}>
            <TextField
                type={"number"}
                size={"small"}
              value={value}
              onChange={(event) => {
                const newValue = event.target.value;
                return this.updatePostProcessingSettingItem(analytics_name, newValue);
              }}
            />
          </div>
        </div>
      );
    }
    if (settings_type === 'singleChoice') {
      const { possibleValues } = item;
      return (
        <div className={styles.formRowPostProcessing} key={analytics_name}>
          <div className={styles.fieldNamePostProcessing}>
            {name}
            {description && <Help helpMessage={description} />}
          </div>
          <div className={styles.valueContainer}>
            <Select
                value={value}
                style={{ height: '40px' }}
                defaultValue={'false'}
              onChange={(event) => {
                this.updatePostProcessingSettingItem(analytics_name, event.target.value as string);
              }}
            >
              {possibleValues?.map((possibleValue) => (
                <MenuItem
                  key={possibleValue.toString().toLowerCase()}
                  value={possibleValue.toString().toLowerCase()}
                >
                  {possibleValue.toString()}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      );
    }
    if (settings_type === 'multipleChoice') {
      const { possibleValues } = item;
      return (
        <div className={styles.formRowPostProcessing} key={analytics_name}>
          <div className={styles.fieldNamePostProcessing}>
            {name}
            {description && <Help helpMessage={description} />}
          </div>
          {analytics_name === 'location_id_array' && this.renderAssignedPOIsMultiSelectEnabled(analytics_name)}
          {analytics_name === 'days_of_week_to_exclude' &&
              <MultiSelectEditableSearchDisabled onChange={(selected: Option[]) => {
                this.setState({ selectedDaysOfTheWeek: selected });
                const newValue = selected.map((item) => item.value).toString();
                this.updatePostProcessingSettingItem(analytics_name,newValue)}}
                options={this.mapPossibleValuesToOptionsEnabled(possibleValues,this.mapDayOfTheWeekToValue)}
                value={this.state.selectedDaysOfTheWeek}/>
          }
          {analytics_name === 'population_group_to_exclude' &&
              <MultiSelectEditableSearchDisabled
                  onChange={(selected: Option[]) => {
                    this.setState({selectedPopulationGroups: selected});
                    const newValue = selected.map((item) => item.value).toString();
                    this.updatePostProcessingSettingItem(analytics_name, newValue)
                  }}
                  options={this.mapPossibleValuesToOptionsEnabled(possibleValues,this.mapPopulationGroupToValue)}
                  value={this.state.selectedPopulationGroups}/>
          }
        </div>
      );
    }
    if(settings_type === 'checkboxDropdown'){
      let stateToBeChanged='';
      if(analytics_name === 'residents_to_exclude_buffer'){
        stateToBeChanged='isCheckedResidents'
      }
      else if(analytics_name === 'employees_to_exclude_buffer'){
        stateToBeChanged='isCheckedEmployees'
      }
      return(
          <div className={styles.formRowPostProcessing} key={analytics_name}>
            <div className={styles.fieldNamePostProcessing}>
              {name}
              {description && <Help helpMessage={description} />}
            </div>
            <div className={styles.checkboxContainer}>
              <Checkbox
                  checked={this.state[stateToBeChanged as keyof State] as boolean}
                  onChange={(event, checked)=>{
                this.setState({...this.state,[stateToBeChanged]:checked})
                if(!checked){
                  this.updatePostProcessingSettingItem(analytics_name,'')
                } else{
                  this.updatePostProcessingSettingItem(analytics_name,'0')
                }
              }}
            ></Checkbox>
            </div>
            <div className={styles.sideBySideInputContainer}>
              <TextField
                  disabled={!this.state[stateToBeChanged as keyof State]}
                  value={value}
                  type={"number"}
                  label={"Area around POI"}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                  }}
                  size={"small"}
                  onChange={(event) => {
                    let newValue = event.target.value;
                    if(event.target.value === ''){
                      newValue='0'
                    }
                    return this.updatePostProcessingSettingItem(analytics_name, newValue);
                  }}
              />
            </div>
          </div>
      )
    }
  }

  private renderAssignedPOIsMultiSelectEnabled(analytics_name: string) {
    return (
      <div className={styles.valueContainer}>
        <MultiSelect
          options={this.mapPOIsToSelectableOptions()}
          value={this.state.selectedPOIs}
          onChange={(selected: Option[]) => {
            this.setState({ selectedPOIs: selected });
            const newValue = selected.map((item) => item.value).toString();
            return this.updatePostProcessingSettingItem(analytics_name, newValue);
          }}
        />
      </div>
    );
  }

  private renderAssignedPOIsMultiSelectDisabled() {
    return (
      <div className={styles.valueContainer}>
        <MultiselectForDisplay options={this.mapPOIsToNonSelectableOptions()} value={this.state.selectedPOIs} />
      </div>
    );
  }

  private renderMultiSelectNotEditableSearchDisabled(options: Option[],value: Option[]){
    return (
        <div className={styles.valueContainer}>
          <MultiselectForDisplay options={options} value={value} disableSearch={true}/>
        </div>
    );
  }

  private renderModalPostProcessing() {
    const tooltipIdentifier = `tooltip-${_.random(0, 1000000)}`;
    const rerunRequestInvalid = this.isPostProcessingFormInValid();
    console.log("==============State use orig tables==============\n",
        this.state.postProcessingSettingState.filter(item=>item.analytics_name==="use_original_tables"))
    return (
      <>
        <Modal
          isOpen={this.state.isModalOpenPostProcessing}
          onRequestClose={() => this.closeModalPostProcessing()}
          ariaHideApp={false}
          className={styles.modal}
        >
          <div className={styles.modalContent}>
            <div className={styles.topBar}>
              <div className={styles.createMoreItemsMessage}>
                {/* {<Help helpMessage={"For a How-To, click " <a href="https://drive.google.com/file/d/10Tt7evUcHMm33NposlY_BKNhjVxJyuhU/view?usp=share_link">here</a>}/>} */}
                <span className={styles.emphasized}>Adjust report data</span>
              </div>
              <div className={styles.modalTopButtons}>
                <ReactTooltip id="rerun-confirm-button">{this.renderToolTipRerunButton()}</ReactTooltip>
                <div data-for="rerun-confirm-button" data-tip="">
                <Button
                    secondary={rerunRequestInvalid}
                    disabled={rerunRequestInvalid}
                  onClick={() => {
                    this.openModalConfirmPostProcessing();
                  }}
                >
                  Rerun
                </Button>
                </div>
                <Button
                    secondary={!rerunRequestInvalid}
                  onClick={() => {
                    this.closeModalPostProcessing();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
            {this.renderDataAdjustmentForm()}
            {this.renderClearPostProcessing()}
          </div>
        </Modal>
        {this.renderModalConfirmPostProcessingSettingsModal()}
      </>
    );
  }

  private renderClearPostProcessing() {
    if (!this.isPostProcessingFormInValid()) {
      return (
        <div>
          <hr className={styles.delimiter} />
          <div className={styles.modalBottomButtons}>
            <div className={styles.modalButtons}>
              <Button
                warning
                onClick={() => {
                  this.clearPostProcessingForm();
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  private renderDataAdjustmentForm() {
    return (
      <div>
        <hr className={styles.delimiter} />
        <div className={styles.formContainer} />
        {this.renderPostProcessingSettingsForm()}
      </div>
    );
  }

  private renderModalRerunStatus() {
    const status = this.props.report.rerunStatus;
    let statusText = '';
    if (status === undefined && this.state.prevRerunStatus === undefined) {
      statusText = 'Waiting for response';
    }
    if (status === '201' && this.state.prevRerunStatus === undefined) {
      statusText = 'Rerun request successful';
      this.setState({ prevRerunStatus: status });
    }
    if (this.state.prevRerunStatus === '201') {
      statusText = 'Rerun request successful';
    }
    if (status !== undefined && status !== '201' && this.state.prevRerunStatus === undefined) {
      statusText = `Rerun request unsuccessful with error code ${this.state.prevRerunStatus}`;
      this.setState({ prevRerunStatus: status });
    }
    if (this.state.prevRerunStatus !== undefined && this.state.prevRerunStatus !== '201') {
      statusText = `Rerun request unsuccessful with error code ${this.state.prevRerunStatus}`;
    }
    return (
      <Modal
        isOpen={this.state.isModalOpenRerunStatus}
        onRequestClose={() => this.closeModalSuccessPostProcessing()}
        ariaHideApp={false}
        className={styles.modal}
      >
        <div className={styles.modalContent}>
          <div className={styles.topBar}>
            <div className={styles.createMoreItemsMessage}>
              <span className={styles.emphasized}>{statusText}</span>
            </div>
            <div className={styles.modalTopButtons}>
              <Button
                onClick={() => {
                  this.closeModalSuccessPostProcessing();
                  this.resetPrevRerunStatus();
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  // ======================= input validation functions =======================

  // only invalid if no input with use_orig_tables=false
  private isPostProcessingFormInValid() {
    return (
      this.state.postProcessingSettingState
        .find((item) => item.analytics_name.includes('use_original_tables'))
        ?.value.includes('false') && this.isPostProcessingFormEmpty()
    );
  }

  private isPostProcessingFormEmpty() {
    return this.state.postProcessingSettingState
      .filter((item) => item.analytics_name !== 'use_original_tables')
      .every((item) => item.value === '');
  }

  // ======================= miscellaneous functions =======================

  private comparePPS(a: PostProcessingSetting, b: PostProcessingSetting) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  }

  private mapPOIsToSelectableOptions():Option[] {
    return (
      this.props.locations?.filter((location) => this.props.report.locations.includes(location.id || '')) || []
    ).map((location) => ({ label: location.name, value: location.id }));
  }

  private mapPOIsToNonSelectableOptions():Option[] {
    return (
      this.props.locations?.filter((location) => this.props.report.locations.includes(location.id || '')) || []
    ).map((location) => ({ label: location.name, value: location.id, disabled: true }));
  }

  // builds Json ready post processing settings to be sent via API
  private buildPostSetting(name1: string, value1: string): postSetting {
    return {
      key: name1,
      value: value1,
    };
  }

  private rerunReportWithPostProcessingSettings(): boolean {
    if (!this.isPostProcessingFormInValid() && typeof this.props.report.id !== 'undefined') {
      const values = this.state.postProcessingSettingState
        .filter((value) => value.value?.length > 0)
        .map((value) => this.buildPostSetting(value.analytics_name, value.value));
      this.props.rerunReportWithPostProcessingSettings(values, this.props.report);
    } else {
      return false;
    }
    return true;
  }

  // ======================= mapping functions =======================

  // Maps String array to selectable Option array. Label is string, value is mapped string.
  private mapPossibleValuesToOptionsEnabled(values: string[] | undefined, mapper?:(item: any)=>any): Option[] {
    if (values === undefined) {
      return [];
    }
    else if(mapper){
      return Array.from(new Set(values)).map((item) => ({ label: item, value: mapper(item) }));
    }
    return Array.from(new Set(values)).map((item) => ({ label: item, value: item.toString().toLowerCase() }));
  }
  
  // Maps String array to non-selectable Option array. Label is string, value is mapped string.
  private mapPossibleValuesToOptionsDisabled(values: string[] | undefined, mapper?:(item: any)=>any): Option[] {
    if (values === undefined) {
      return [];
    }
    else if(mapper){
      return Array.from(new Set(values)).map((item) => ({ label: item, value: mapper(item),disabled:true }));
    }
    return Array.from(new Set(values)).map((item) => ({ label: item, value: item.toString().toLowerCase(), disabled:true}));
  }

  private mapDayOfTheWeekToValue(day: string): number {
    switch (day.toLowerCase()) {
      case 'sunday':
        return 1;
        break;
      case 'monday':
        return 2;
        break;
      case 'tuesday':
        return 3;
        break;
      case 'wednesday':
        return 4;
        break;
      case 'thursday':
        return 5;
        break;
      case 'friday':
        return 6;
        break;
      case 'saturday':
        return 7;
        break;
      default:
        return 1;
        break;
    }
  }
  
  private mapPopulationGroupToValue(group: string) : string{
    switch(group.toLowerCase()){
      case "exclude employees":return "employees";
      case "exclude residents":return "residents";
      default: return "";
    }
    return "";
  }
}
