import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './showAverageToggle.module.scss';
import { MainContext } from '../../../types/mainContext';
import { SwitchToggle } from '../../../controls/toggle';
import { useURLParams } from '../../../hooks';

export const ShowAverageToggle = () => {
  const { t } = useTranslation();
  const { onChangeParams, params, isParsingDone } = useURLParams();
  const { reportItemsDetails, updateReportItemsDetailsValue } = useContext(MainContext);

  useEffect(() => {
    if (isParsingDone) {
      const { showDetailsAverage } = params;

      if (showDetailsAverage !== null) {
        updateReportItemsDetailsValue('showAverageValuesInChart', showDetailsAverage === '1');
      }
    }
  }, [isParsingDone]);

  const onToggleClick = (value: string) => {
    updateReportItemsDetailsValue('showAverageValuesInChart', value === '1');
    onChangeParams('showDetailsAverage', value);
  };

  return (
    <div className={styles.averageToggle}>
      <span>{t('reportItem.showAverage')}</span>
      <SwitchToggle
        leftLabel={t('reportItem.on')}
        leftValue="1"
        rightLabel={t('reportItem.off')}
        rightValue="0"
        selectedValue={reportItemsDetails.showAverageValuesInChart ? '1' : '0'}
        onClick={onToggleClick}
        leftLabelSize="30px"
      />
    </div>
  );
};
