import React, { ChangeEvent } from 'react';
import { DefaultInput } from '../default';
import { IconSidesEnum } from './enums';
import styles from './style.module.scss';

interface Props {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  isError?: boolean;
  name: string;
  id?: string;
  iconSide?: 'left' | 'right';
  icon?: React.ReactNode;
  onIconClick?: VoidFunction;
}
export const WithIconInput: React.FC<Props> = ({ icon, iconSide = 'left', onIconClick, ...inputProps }) => (
  <div className={`${styles.wrapper} ${iconSide === IconSidesEnum.LEFT ? styles.left : styles.right}`}>
    {icon && (
      <span className={`${styles.icon}`} onClick={onIconClick}>
        {icon}
      </span>
    )}
    <DefaultInput {...inputProps} />
  </div>
);
