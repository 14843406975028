import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../buttons';
import { Row } from './row';

import styles from './style.module.scss';
import { ArrowDownIcon } from '../../icons/dropdown';
import { useClickOutside, useToggle } from '../../hooks';
import { Checkbox } from '../checkbox';

export interface CheckboxDropdownItem {
  id: string;
  name: string;
  selected: boolean;
}

interface Props {
  items: CheckboxDropdownItem[];
  onSave: (data: string[]) => void;
  title: string;
  selectAllTitle: string;
}

export const CheckboxDropdown: React.FC<Props> = ({ items, onSave, title, selectAllTitle }) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [isOpen, toggleIsOpen] = useToggle(false);
  const initialSelectedIds = useRef<string[]>([]);
  const allLSelectedIds = useRef<string[]>([]);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (items.length) {
      const selected = items.filter(({ selected }) => selected).map(({ id }) => id);
      initialSelectedIds.current = selected;
      setSelected(selected);
    }
  }, [items]);

  useEffect(() => {
    if (items.length) {
      const selected = items.map(({ id }) => id);
      allLSelectedIds.current = selected;
    }
  }, []);

  const isCheckedAll = useMemo(() => items.length === selected.length, [selected]);

  const onPreselectionChange = (id: string) => {
    setSelected((prevData) => {
      if (prevData.includes(id)) {
        return prevData.filter((item) => item !== id);
      }
      return [...prevData, id];
    });
  };

  const onSelectAllClick = () => {
    if (isCheckedAll) {
      setSelected([]);
      return;
    }
    setSelected(allLSelectedIds.current);
  };

  const onSubmit = () => {
    onSave(selected);
    onClose();
  };

  const onClose = () => {
    toggleIsOpen(false);
    setSelected(initialSelectedIds.current);
  };

  const isDisabledSave = useMemo(() => selected.length === 0, [selected]);

  const isNoItems = items.length === 0;

  const data = useMemo(
    () => items.map((item) => ({ ...item, selected: selected.includes(item.id) })),
    [selected, items],
  );

  const selectedValues = useMemo(() => {
    return items.filter((item) => selected.includes(item.id)).map(({ name }) => name);
  }, [selected, items]);

  useClickOutside(elementRef, onClose, isOpen);
  return (
    <div className={styles.dropdown} ref={elementRef}>
      <div className={styles.title} onClick={() => toggleIsOpen()}>
        <p>
          {selectedValues.length === 0 ? title : <span className={styles.values}>{selectedValues.join(', ')}</span>}
        </p>
        <span>
          <ArrowDownIcon />
        </span>
      </div>
      <div className={`${styles.wrapper} ${isOpen ? styles.open : styles.close}`}>
        <div className={`${styles.list} custom-scrollbar`}>
          <div className={styles.selectAll}>
            <span>{selectAllTitle}</span>
            <Checkbox isChecked={isCheckedAll} disabled={isNoItems} onChange={onSelectAllClick} name={'select-all'} />
          </div>
          {data.map(({ id, name, selected }) => (
            <Row id={id} name={name} selected={selected} onClick={onPreselectionChange} />
          ))}
        </div>
        <div className={styles.buttons}>
          {/* <div onClick={onClose}>Cancel</div> */}
          <Button onClick={onClose}>Cancel</Button>
          <Button layout="primary" onClick={onSubmit} disabled={isDisabledSave} animation>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
