import { convertPolygonToGeoData } from '../../../../common/maps/utils/convertPolygonToGeoData';
import { Point } from '../../../../common/types/visualizationObjects';

export const getPointsFromPolygon = (polygonString: string | undefined): Point[] => {
  if (polygonString && polygonString.length > 0) {
    const polygon = convertPolygonToGeoData(polygonString);
    if (polygon) {
      if (polygon.geometry.type === 'Polygon') {
        return polygon.geometry.coordinates[0].map((item, index) => ({
          location_id: `coordinate_${index}`,
          name: `coordinate_${index}`,
          longitude: item[0],
          latitude: item[1],
          value: 0,
          color: 'white',
        }));
      }
      if (polygon.geometry.type === 'MultiPolygon') {
        return polygon.geometry.coordinates[0][0].map((item, index) => ({
          location_id: `coordinate_${index}`,
          name: `coordinate_${index}`,
          longitude: item[0],
          latitude: item[1],
          value: 0,
          color: 'white',
        }));
      }
    }
  }
  return [];
};
