import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './style.module.scss';
import { Routes } from '../../../../routes';
import { Button } from '../../../../common/controls/buttons';

interface Props {
  onSubmit: VoidFunction;
}
export const Information = ({ onSubmit }: Props) => (
  <div className={styles.information}>
    <div className={styles.left}>
      <span>Already have an account?</span>
      <NavLink to={Routes.signIn}>Log in</NavLink>
    </div>
    <Button onClick={onSubmit} layout="primary">
      Sign up
    </Button>
  </div>
);
