import React, { ReactNode, RefObject } from 'react';
import styles from './reportItemCard.module.scss';
import ReportItemCardHeader from './reportItemCardHeader';
import ReportItemCardValueList from './reportItemCardValueList';
import { ReportItemType } from '../types';
import { ReportItemCardValue } from './reportItemCardValueList/types';
import { ReportItemInfo, ReportItems } from '../../types/projectsHierarchy';

interface Props {
  reportItemType: ReportItemType;
  values?: ReportItemCardValue[];
  children?: ReactNode;
  additionalInformation?: ReactNode;
  reportItem: ReportItemInfo<ReportItems> | null;
  onHeaderMenuClick?: VoidFunction;
  onOpenTooltip?: VoidFunction;
  isLoading?: boolean;
  noData?: boolean;
  alwaysShowAdditionalInformation?: boolean;
  isHeaderMenuOpened?: boolean;
  isGlobalLoading?: boolean;
  tooltipRef?: RefObject<HTMLDivElement>;
}

const ReportItemCard: React.FC<Props> = ({
  reportItem,
  children,
  reportItemType,
  values = [],
  additionalInformation,
  onHeaderMenuClick,
  isLoading,
  noData,
  alwaysShowAdditionalInformation,
  isHeaderMenuOpened,
  onOpenTooltip,
  tooltipRef,
}) => (
  <div className={styles.container} id={`report-item-card-${reportItemType}`}>
    <ReportItemCardHeader
      reportItemType={reportItemType}
      reportItem={reportItem}
      onHeaderMenuClick={onHeaderMenuClick}
      onOpenTooltip={onOpenTooltip}
      disabled={noData}
      isHeaderMenuOpened={isHeaderMenuOpened}
      isGlobalLoading={Boolean(isLoading)}
      tooltipRef={tooltipRef}
    />
    {alwaysShowAdditionalInformation && additionalInformation}
    <div className={styles.contentContainer}>
      {!alwaysShowAdditionalInformation && additionalInformation}
      {(isLoading || noData) && (
        <div className={styles.contentContainerOverlay}>
          {/* {isLoading && <span>Loading data...</span>} */}
          {noData && !isLoading && <span>There's not enough data to show this report</span>}
        </div>
      )}
      {values.length && !noData ? (
        <ReportItemCardValueList values={values} isGlobalLoading={Boolean(isLoading)} />
      ) : null}
      {!noData && children}
    </div>
  </div>
);

export default ReportItemCard;
