import React from 'react';
import { PlaceSenseIcon } from '../../../../common/icons/controlPanel';
import styles from './style.module.scss';
import { Checkbox } from '../../checkbox';

type TProps = {
  id: string;
  name: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (id: string) => void;
};

export const Row = ({ onClick, id, selected, disabled, name }: TProps) => {
  const onCheckboxClick = () => {
    if (onClick) {
      onClick(id);
    }
  };
  return (
    <div className={styles.row}>
      <span className={styles.line}>
        <PlaceSenseIcon /> <p className={styles.name}>{name}</p>
      </span>
      <Checkbox isChecked={Boolean(selected)} disabled={disabled} onChange={onCheckboxClick} name={'select-all'} />
    </div>
  );
};
