import React from 'react';

export const SeasonalityIcon: React.FC = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 6.33301H2.66667V7.66634H4V6.33301ZM6.66667 6.33301H5.33333V7.66634H6.66667V6.33301ZM9.33333 6.33301H8V7.66634H9.33333V6.33301ZM10.6667 1.66634H10V0.333008H8.66667V1.66634H3.33333V0.333008H2V1.66634H1.33333C0.593333 1.66634 0.00666666 2.26634 0.00666666 2.99967L0 12.333C0 13.0663 0.593333 13.6663 1.33333 13.6663H10.6667C11.4 13.6663 12 13.0663 12 12.333V2.99967C12 2.26634 11.4 1.66634 10.6667 1.66634ZM10.6667 12.333H1.33333V4.99967H10.6667V12.333Z"
      fill="white"
    />
  </svg>
);

export default SeasonalityIcon;
