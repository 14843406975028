import React from 'react';

interface Props {}
export const FamilyStatusIcon: React.FC<Props> = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8375 2V12.4272H27.2647C27.2647 6.66619 22.5985 2 16.8375 2ZM25.075 20.1043C26.4436 18.3447 27.2647 16.1289 27.2647 13.7306H8.28712L7.04888 11.1238H2.5V13.7306H5.39356C5.39356 13.7306 7.857 19.0355 8.15678 19.4917C9.83769 22.1786 10.7549 20.8028 10.7549 21.5993C12.0583 21.9856 11.1894 21.5993 12.4928 21.9856C13.5065 23.5545 13.2913 24.1579 14.8302 24.1579H17.5413C22.2451 24.1579 19 23 21.6166 21.5993C24.1322 21.5993 22.3989 21.213 23.2877 21.5993C23.9877 21.213 22.936 22.8545 25.075 20.1043Z"
      fill="#DBAA85"
    />
    <path
      d="M14 25C14 27.2091 12.2091 29 10 29C7.79086 29 6 27.2091 6 25C6 22.7909 7.79086 21 10 21C12.2091 21 14 22.7909 14 25ZM7.6 25C7.6 26.3255 8.67452 27.4 10 27.4C11.3255 27.4 12.4 26.3255 12.4 25C12.4 23.6745 11.3255 22.6 10 22.6C8.67452 22.6 7.6 23.6745 7.6 25Z"
      fill="#EADED5"
    />
    <path
      d="M27 25C27 27.2091 25.2091 29 23 29C20.7909 29 19 27.2091 19 25C19 22.7909 20.7909 21 23 21C25.2091 21 27 22.7909 27 25ZM20.6 25C20.6 26.3255 21.6745 27.4 23 27.4C24.3255 27.4 25.4 26.3255 25.4 25C25.4 23.6745 24.3255 22.6 23 22.6C21.6745 22.6 20.6 23.6745 20.6 25Z"
      fill="#EADED5"
    />
  </svg>
);
