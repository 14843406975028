import { request } from '../request';
import { API_URLS } from '../../routes';
import { ReportInfo, ReportItemInfo, ReportItems } from '../../common/types/projectsHierarchy';

interface Response extends ReportInfo {
  reportItems: ReportItemInfo<ReportItems>[];
}
export const getReport = async (data: ReportInfo) => {
  const url = `${API_URLS.REPORT}/${data.id}`;
  const { report } = await request<{ report: Response }>({ url, method: 'GET' });

  return {
    ...data,
    visualization_url: report.visualization_url,
    status: report.status,
    report_category: report.report_category,
    creator: report.creator,
    numberOfSignals: report.numberOfSignals,
    report_items: data.report_items.map((item) => ({
      ...item,
      settings: report.report_items[item.type.toLowerCase()]
        ? report.report_items[item.type.toLowerCase()][0]?.settings?.filter(
            (value, index, self) => index === self.findIndex((t) => t.name === value.name),
          )
        : [],
    })),
  };
};
