import React, { ReactNode } from 'react';
import { ButtonTooltip } from '../../tooltip/buttonTooltip';
import styles from './circleButton.module.scss';

interface TProps {
  onClick?: () => void;
  active?: boolean;
  className?: string;
  icon?: ReactNode;
  activeIcon?: ReactNode;
  title?: string;
  hasShadow?: boolean;
  disabled?: boolean;
}

export const CircleButton = ({
  onClick,
  active = false,
  className,
  icon,
  activeIcon,
  title = '',
  hasShadow,
  disabled,
}: TProps) => (
  <ButtonTooltip title={title}>
    <button
      type="button"
      onClick={onClick}
      className={`${styles.circleButton} ${className} ${active ? styles.active : ''} ${
        disabled ? styles.disabled : ''
      } ${hasShadow ? styles.shadow : ''} `}
      disabled={disabled}
    >
      {active && activeIcon ? activeIcon : icon}
    </button>
  </ButtonTooltip>
);
