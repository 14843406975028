export enum ValueBreakZonesEnum {
  LIGHT = '0-15',
  GREEN = '16-25',
  YELLOW = '26-40',
  ORANGE = '41-50',
  RED = '50+',
}

export enum RatioBreakZonesEnum {
  LIGHT = '100 : 0-5',
  GREEN = '100 : 5-10',
  YELLOW = '100 : 10-25',
  ORANGE = '100 : 25-50',
  RED = '100 : 50+',
}
