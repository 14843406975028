import React from 'react';

export const DistanceIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.92707 13.2313C8.89173 13.1162 6.73142 12.9786 5.66235 13.0028V13.4275C5.91314 13.4275 6.70428 13.4398 7.47594 13.5283C9.233 13.73 11.0288 13.8527 11.0288 14.7025C11.0288 15.0525 9.26111 15.4956 8.13896 15.9773C7.0168 16.459 6.16757 16.737 5.24963 17.677C3.93402 19.0243 2.99818 21.0765 5.96243 23.5751C7.54428 24.9085 15 28.5 23.5 30C22.6399 28.5427 21.3296 28.031 19.2847 25.3258C18.4551 25.0868 16.5 21.5 16.5 21.5C16.5 21.5 9.08815 19.3092 8.96483 18.5269C8.82014 17.609 10.9639 16.9569 12.3626 16.2323C13.7612 15.5077 13.9059 15.0488 13.7853 14.614C13.6648 14.1792 12.5314 13.521 9.92707 13.2313Z"
      fill="#F0CECF"
    />
    <path
      d="M22.3218 20.5386L23.4973 21.7687L27.4178 25.886L25.8494 27.5315L20.8035 22.2344C20.3487 21.8134 19.9822 21.2976 19.7274 20.7197C19.4725 20.1419 19.3347 19.5147 19.3228 18.878C19.3228 17.7149 19.7627 16.5995 20.5455 15.7771C21.3282 14.9546 22.39 14.4926 23.4971 14.4926C24.6041 14.4926 25.6659 14.9546 26.4488 15.7771C27.2316 16.5995 27.6714 17.7149 27.6714 18.878C27.6596 19.5148 27.5218 20.142 27.2669 20.7198C27.0119 21.2977 26.6453 21.8135 26.1904 22.2344L25.8483 22.5935L24.2828 20.9473L24.6728 20.5386C25.1319 20.1152 25.4132 19.5183 25.4554 18.878C25.4485 18.3375 25.2392 17.8216 24.8729 17.442C24.5066 17.0623 24.0127 16.8495 23.4981 16.8495C22.9837 16.8495 22.4898 17.0623 22.1235 17.442C21.7572 17.8216 21.548 18.3375 21.541 18.878C21.5831 19.5182 21.8641 20.1149 22.3228 20.5386H22.3218ZM20.0453 23.082L23.4988 26.7067L25.0661 28.353L23.498 30L18.5281 24.7785C17.3884 23.7185 16.5828 22.3191 16.2183 20.7665C15.8537 19.2139 15.9475 17.5816 16.487 16.0863C17.0266 14.591 17.9864 13.3036 19.2391 12.3951C20.4918 11.4866 21.9779 11 23.5 11C25.0221 11 26.5082 11.4866 27.7609 12.3951C29.0135 13.3036 29.9734 14.591 30.513 16.0863C31.0526 17.5816 31.1462 19.2139 30.7817 20.7665C30.4171 22.3191 29.6115 23.7185 28.472 24.7785L28.2007 25.0631L26.6345 23.4176L26.9529 23.0835C27.5214 22.5567 27.9781 21.9102 28.2934 21.1859C28.6086 20.4617 28.7753 19.6759 28.7827 18.8795C28.7713 17.4155 28.2097 16.0155 27.2202 14.9846C26.2308 13.9536 24.8936 13.3752 23.5 13.3752C22.1064 13.3752 20.7693 13.9536 19.7798 14.9846C18.7904 16.0155 18.2287 17.4155 18.2172 18.8795C18.2245 19.6754 18.3909 20.4608 18.7057 21.1847C19.0204 21.9087 19.4765 22.5551 20.0443 23.082H20.0453Z"
      fill="#DD7BAA"
    />
    <path
      d="M6 2C3.78857 2 2 3.7215 2 5.85C2 8.7375 6 13 6 13C6 13 10 8.7375 10 5.85C10 3.7215 8.21143 2 6 2ZM6 7.225C5.21143 7.225 4.57143 6.609 4.57143 5.85C4.57143 5.091 5.21143 4.475 6 4.475C6.78857 4.475 7.42857 5.091 7.42857 5.85C7.42857 6.609 6.78857 7.225 6 7.225Z"
      fill="#DD7BAA"
    />
  </svg>
);
