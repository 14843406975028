import React from 'react';

export const DrivingIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M24.9956 7.45188C24.7067 6.60375 23.8978 6 22.9444 6H7.05556C6.10222 6 5.30778 6.60375 5.00444 7.45188L2 16.0625V27.5625C2 28.3531 2.65 29 3.44444 29H4.88889C5.68333 29 6.33333 28.3531 6.33333 27.5625V26.125H23.6667V27.5625C23.6667 28.3531 24.3167 29 25.1111 29H26.5556C27.35 29 28 28.3531 28 27.5625V16.0625L24.9956 7.45188ZM7.05556 21.8125C5.85667 21.8125 4.88889 20.8494 4.88889 19.6562C4.88889 18.4631 5.85667 17.5 7.05556 17.5C8.25444 17.5 9.22222 18.4631 9.22222 19.6562C9.22222 20.8494 8.25444 21.8125 7.05556 21.8125ZM22.9444 21.8125C21.7456 21.8125 20.7778 20.8494 20.7778 19.6562C20.7778 18.4631 21.7456 17.5 22.9444 17.5C24.1433 17.5 25.1111 18.4631 25.1111 19.6562C25.1111 20.8494 24.1433 21.8125 22.9444 21.8125ZM4.88889 14.625L7.05556 8.15625H22.9444L25.1111 14.625H4.88889Z"
        fill="#DD7BAA"
      />
    </g>
    <mask id="path-3-inside-1_7879_61127" fill="white">
      <path d="M30.8662 8.5C30.8662 12.6421 27.5083 16 23.3662 16C19.2241 16 15.8662 12.6421 15.8662 8.5C15.8662 4.35786 19.2241 1 23.3662 1C27.5083 1 30.8662 4.35786 30.8662 8.5Z" />
    </mask>
    <path
      d="M28.8662 8.5C28.8662 11.5376 26.4038 14 23.3662 14V18C28.6129 18 32.8662 13.7467 32.8662 8.5H28.8662ZM23.3662 14C20.3286 14 17.8662 11.5376 17.8662 8.5H13.8662C13.8662 13.7467 18.1195 18 23.3662 18V14ZM17.8662 8.5C17.8662 5.46243 20.3286 3 23.3662 3V-1C18.1195 -1 13.8662 3.25329 13.8662 8.5H17.8662ZM23.3662 3C26.4038 3 28.8662 5.46243 28.8662 8.5H32.8662C32.8662 3.25329 28.6129 -1 23.3662 -1V3Z"
      fill="#F0DAE5"
      mask="url(#path-3-inside-1_7879_61127)"
    />
    <path
      d="M23.4286 5.71429C23.4286 5.3198 23.1088 5 22.7143 5C22.3198 5 22 5.3198 22 5.71429V8.08515C22 8.40823 22.1561 8.71141 22.4191 8.8991L25.0557 10.781C25.38 11.0124 25.8305 10.9372 26.0621 10.613C26.3008 10.2788 26.2129 9.81303 25.8687 9.58891L23.8829 8.29584C23.5995 8.1113 23.4286 7.79603 23.4286 7.45784V5.71429Z"
      fill="#E4D0EC"
    />
  </svg>
);
