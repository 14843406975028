import { Absorption, LocationInfo, ReportItemInfo } from '../../common/types/projectsHierarchy';
import { AbsorptionResponse } from './types';
import { ReportItemType } from '../../common/reportItems/types';
import alphabeticalSort from './lib/alphabeticalSort';
import { generateMapPoints } from '../utils/mappers/generateMapPoints';
import { generateMapPoiPolygons } from '../utils/mappers/generateMapPoiPolygons';
import { getCorrectPointsIfCoordinatesAreWrong } from '../../common/maps/utils';

export default (item: AbsorptionResponse, projectLocations: LocationInfo[]): ReportItemInfo<Absorption> => {
  const locationPoints: {
    location_id: string;
    location_name: string;
    group_id: string;
    average: string;
  }[] = [];

  const aoiPoints: {
    location_id: string;
    location_name: string;
    group_id: string;
  }[] = [];

  for (let i = 0; i < item.values.length; i += 1) {
    const aoiPoint = {
      location_id: item.values[i].source_location_id,
      location_name: item.values[i].source_location_name,
      group_id: item.values[i].source_location_id,
    };
    aoiPoints.push(aoiPoint);
    for (let j = 0; j < item.values[i].data.length; j += 1) {
      const point = {
        location_id: item.values[i].data[j].target_location_id,
        location_name: item.values[i].data[j].target_location_name,
        group_id: item.values[i].source_location_id,
        average: `${(item.values[i].data[j].avg_weighted_absorption * 100).toFixed(2)} %`,
      };
      locationPoints.push(point);
    }
  }

  const points = generateMapPoints(locationPoints, projectLocations);
  const poiPolygons = generateMapPoiPolygons(locationPoints, projectLocations);

  return {
    id: item.id,
    name: item.name,
    type: ReportItemType.ABSORPTION,
    data: {
      data: item.values
        .map((source) => ({
          location_id: source.source_location_id,
          location_name: source.source_location_name,
          average:
            source.data
              .map(({ avg_weighted_absorption }) => avg_weighted_absorption)
              .reduce((prevValue, nextValue) => prevValue + nextValue, 0) / source.data.length,
          data: source.data
            .map((target) => ({
              locationId: target.target_location_id,
              locationName: target.target_location_name,
              avgWeightedAbsorption: target.avg_weighted_absorption,
              dateChanges: target.dateChanges.map((dateChange) => ({
                timestamp: dateChange.timestamp,
                absorption: dateChange.visits_in_target / dateChange.visits_in_source,
              })),
            }))
            .sort((targetA, targetB) => alphabeticalSort(targetA.locationName, targetB.locationName)),
        }))
        .sort((sourceA, sourceB) => alphabeticalSort(sourceA.location_name, sourceB.location_name)),
    },
    visualization: {
      points: getCorrectPointsIfCoordinatesAreWrong(points, poiPolygons),
      poiPolygons,
      aoiPolygons: generateMapPoiPolygons(aoiPoints, projectLocations),
    },
  };
};
