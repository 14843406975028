import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { CreateNewPassword } from '../../auth';
import { IntroPageTemplate } from '../../common/templates';
import { Routes } from '../../routes';
import { RedirectAuthedUser } from '../../processes/RedirectAuthedUser';

export const CreateNewPasswordPage = () => {
  const { token } = useParams<{ token: string }>();

  if (!token) return <Redirect to={Routes.signIn} />;

  return (
    <RedirectAuthedUser>
      <IntroPageTemplate>
        <CreateNewPassword token={token} />
      </IntroPageTemplate>
    </RedirectAuthedUser>
  );
};
