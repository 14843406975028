import React from 'react';

export const PlaceSenseLogoIcon = () => (
  <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3287 16.0651L13.4351 18.1367L20.4607 25.0711L17.6502 27.8425L8.60784 18.9212C7.79283 18.212 7.13617 17.3432 6.67942 16.3701C6.22267 15.3969 5.97578 14.3406 5.95454 13.2682C5.95454 11.3094 6.74267 9.4307 8.14545 8.04557C9.54822 6.66044 11.4509 5.88228 13.4349 5.88228C15.4186 5.88228 17.3213 6.66044 18.7243 8.04557C20.1271 9.4307 20.9152 11.3094 20.9152 13.2682C20.894 14.3407 20.6471 15.397 20.1903 16.3702C19.7333 17.3434 19.0765 18.2121 18.2612 18.9212L17.6482 19.5259L14.8428 16.7533L15.5417 16.0651C16.3644 15.3519 16.8684 14.3466 16.9441 13.2682C16.9316 12.3579 16.5567 11.489 15.9002 10.8496C15.2438 10.2102 14.3588 9.85176 13.4367 9.85176C12.5148 9.85176 11.6298 10.2102 10.9734 10.8496C10.3169 11.489 9.94195 12.3579 9.92952 13.2682C10.005 14.3464 10.5085 15.3515 11.3305 16.0651H11.3287ZM7.24912 20.3486L13.4378 26.4533L16.2465 29.2261L13.4365 32L4.53031 23.2059C2.48804 21.4205 1.04438 19.0638 0.391157 16.4489C-0.262116 13.834 -0.0941438 11.0847 0.872776 8.56636C1.83974 6.04797 3.55966 3.87971 5.80455 2.3496C8.04922 0.819494 10.7125 0 13.4401 0C16.1677 0 18.8307 0.819494 21.0756 2.3496C23.3202 3.87971 25.0404 6.04797 26.0072 8.56636C26.9742 11.0847 27.1421 13.834 26.4888 16.4489C25.8355 19.0638 24.3919 21.4205 22.3498 23.2059L21.8637 23.6853L19.057 20.9138L19.6276 20.3512C20.6464 19.4639 21.4648 18.375 22.0297 17.1552C22.5947 15.9354 22.8933 14.612 22.9066 13.2708C22.8861 10.805 21.8798 8.44724 20.1065 6.71086C18.3335 4.97451 15.9373 4.00026 13.4401 4.00026C10.9426 4.00026 8.54663 4.97451 6.77339 6.71086C5.00039 8.44724 3.99382 10.805 3.97327 13.2708C3.98637 14.6112 4.28454 15.9339 4.84859 17.1532C5.41264 18.3725 6.2299 19.4612 7.24731 20.3486H7.24912Z"
      fill="#00B2F1"
    />
  </svg>
);
