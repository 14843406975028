import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../../api';
import { commonRequestHeaders } from '../commonRequestHeaders';
import forbiddenHandler from '../forbiddenHandler';

export const request = <T>(config: AxiosRequestConfig): Promise<T> => {
  const instance = axios.create({ baseURL: `${API_URL}/api`, headers: commonRequestHeaders() });

  instance.interceptors.response.use(
    (res) => res,
    async (error) => {
      await forbiddenHandler(error.response);

      return Promise.reject(error);
    },
  );
  return instance(config).then((res) => res.data);
};
