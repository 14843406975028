import React, { useMemo } from 'react';
import { EChartsOption } from 'echarts';
import { Chart as EChart } from '../../../common/chart/chart';
import { TEXT_STYLE } from '../../../common/chart/configuration';
import { toFixNumber } from '../../../common/lib';
import { setTooltipPosition } from '../../../common/chart/utils';
import { PieItem } from '../../../common/chart/pie';
import { Skeleton } from '../../../common/skeleton';
import styles from './style.module.scss';

const getChartOptions = (data: PieItem[]): EChartsOption => ({
  tooltip: {
    trigger: 'item',
    position(pos) {
      return setTooltipPosition(pos);
    },
  },
  legend: {
    top: 'center',
    orient: 'vertical',
    icon: 'circle',
    itemHeight: 10,
    itemWidth: 10,
    left: 95,
    textStyle: {
      ...TEXT_STYLE,
      fontWeight: 'normal',
      color: 'white',
    },
    formatter(name) {
      const currentValue = data.find((item) => item.name === name);
      return `${name} ${toFixNumber(Number(currentValue?.value) * 100, 2)}%`;
    },
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['40%', '70%'],
      width: 100,
      tooltip: {
        formatter({ name, value }: any) {
          return `<div>${name}: ${toFixNumber(value * 100, 2)}%</div>`;
        },
      },
      label: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
        },
      },
      labelLine: {
        show: false,
      },
      data: data.map(({ value, name }, index) => ({
        value: Number(value),
        name,
        itemStyle: {
          color: index === 0 ? 'rgba(255, 255, 255, 0.6)' : '#00B2F1',
        },
      })),
    },
  ],
});

interface Props {
  data: PieItem[];
  isLoading: boolean;
}

export const Chart: React.FC<Props> = ({ data, isLoading }) => {
  const options = useMemo(() => getChartOptions(data), [data]);

  return (
    <div className="chart-container" style={{ height: '130px' }}>
      {isLoading ? <Skeleton className={styles.skeleton} /> : <EChart option={{ ...options }} />}
    </div>
  );
};
