import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportItemType } from '../../../common/reportItems/types';
import { MainContext } from '../../../common/types/mainContext';
import { ReportItemInfo, Retention } from '../../../common/types/projectsHierarchy';
import ReportItemCard from '../../../common/reportItems/reportItemCard';
import { fetchRetentionData } from '../service';
import { useToggle } from '../../../common/hooks';
import { Chart } from './Chart';
import { toFixNumber } from '../../../common/lib';
import { RetentionMainTooltip } from './tooltips';
import { dashboardModel } from '../../../dasboardLoader';
import { exportSettingsModel } from '../../../common/export';
import { useTooltipStartPosition } from '../../../common/reportItems/hooks';
import { reportItemDetailsModel } from '../../model';
import { REQUEST_DELAY } from '../../types';

export const RetentionCard = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReport, selectedReportItem },
    selectedLocationsIds,
    selectedLocations,
    selectedTimeFrames,
    updateReportItemOfSelectedReport,
    updateReportItemsDetailsItems,
    reportItemsDetails: { retentionPeriod },
    primaryLocationId,
  } = useContext(MainContext);

  const [isLoading, setIsLoading] = useToggle(false);
  const [fetched, setFetched] = useToggle(true);
  const [isTooltipOpen, toggleTooltipOpen] = useToggle(false);
  const { tooltipIconRef, position } = useTooltipStartPosition();

  const reportItem = useMemo(
    () => selectedReport?.report_items?.find((i) => i.type === ReportItemType.RETENTION) as ReportItemInfo<Retention>,
    [selectedReport],
  );

  useEffect(() => {
    if (reportItem && !isLoading) {
      (async function () {
        setIsLoading(true);
        setFetched(false);
        dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.RETENTION, value: false });
        if (selectedReportItem?.type !== ReportItemType.RETENTION) {
          await new Promise((resolve) => setTimeout(resolve, REQUEST_DELAY));
        }
        const response = await fetchRetentionData(
          reportItem.id,
          selectedLocationsIds,
          retentionPeriod,
          selectedLocations,
          selectedTimeFrames,
        );
        if (response) {
          updateReportItemOfSelectedReport(response);
          updateReportItemsDetailsItems('retention', [response]);
        }
        setFetched(true);
        setIsLoading(false);
        dashboardModel.toggleReportItemsLoadStatus({ type: ReportItemType.RETENTION, value: true });
      })();
    }
  }, [reportItem, selectedLocations, selectedTimeFrames, selectedLocationsIds]);

  const noData = useMemo(
    () => fetched && (!reportItem || !reportItem.data),
    [reportItem, selectedTimeFrames, fetched, isLoading, selectedLocationsIds],
  );

  const locationReturningAverage = useMemo(
    () => reportItem?.data?.averageInfo.returningVisitsLocationsAverage.get(primaryLocationId || ''),
    [reportItem, primaryLocationId],
  );

  const data = useMemo(() => {
    const returningAverage = locationReturningAverage ? toFixNumber(Number(locationReturningAverage) * 100, 2) : 0;
    return [
      {
        value: (100 - returningAverage) / 100,
        name: t('retention.card.newVisits'),
      },
      { value: returningAverage / 100, name: t('retention.card.returningVisits') },
    ];
  }, [locationReturningAverage, t]);

  useEffect(() => {
    if (locationReturningAverage) {
      exportSettingsModel.updateReportItemsInformation({ retention: { returningAverage: locationReturningAverage } });
    }
  }, [locationReturningAverage]);

  useEffect(() => {
    if (locationReturningAverage)
      reportItemDetailsModel.reportItemsBriefDataChanged({
        [ReportItemType.RETENTION]: { chartData: { data }, locationReturningAverage },
      });
  }, [data, locationReturningAverage]);

  const onTooltipClose = () => {
    toggleTooltipOpen(false);
  };

  return reportItem ? (
    <ReportItemCard
      reportItemType={ReportItemType.RETENTION}
      values={[
        {
          name: t('retention.card.avgRate'),
          value: `${locationReturningAverage ? toFixNumber(locationReturningAverage * 100, 2) : 0}%`,
        },
      ]}
      reportItem={reportItem}
      isLoading={isLoading}
      noData={noData}
      onOpenTooltip={toggleTooltipOpen}
      tooltipRef={tooltipIconRef}
    >
      <>
        <RetentionMainTooltip isOpen={isTooltipOpen} onClose={onTooltipClose} position={position} />
        <Chart data={data} isLoading={isLoading} />
      </>
    </ReportItemCard>
  ) : null;
};
