import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContext } from '../../../common/types/mainContext';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { CatchmentMetricTypeEnum, CatchmentTypeEnum } from '../../../common/reportItems/types';
import MultiBarPin from '../../../common/maps/chartPin/multiBarPin';
import { IndexMultiArea } from '../../../common/types/visualizationObjects';
import { getSocioDemoFieldName, getTopLocationsFieldName } from './utils/getFieldName';
import { getChartsNumberByZoom } from './utils/getChartsNumberByZoom';

const WinnersBarChartPoints = () => {
  const { t } = useTranslation();
  const {
    selection: { selectedReportItem },
    reportItemsDetails: { catchmentType, catchmentTreshold, catchmentMetricType, catchmentSocioDemoMetric },
    mapOptions,
  } = useContext(MainContext);

  const topLocationsFieldName = getTopLocationsFieldName(catchmentMetricType);
  const socioDemoFieldName = getSocioDemoFieldName(catchmentSocioDemoMetric);

  const postalCodePolygons = useMemo(() => {
    let locationPolygons: IndexMultiArea | null = selectedReportItem?.visualization?.multiLocationPolygons || null;
    const data = catchmentType === CatchmentTypeEnum.HOME ? locationPolygons?.home : locationPolygons?.work;
    const filteredData = data
      ? {
          ...data,
          polygons: data.polygons
            .filter(
              (item) =>
                (item[socioDemoFieldName] || 0) >= catchmentTreshold.min &&
                (item[socioDemoFieldName] || 0) <= catchmentTreshold.max &&
                (item[topLocationsFieldName]?.length || 0) > 0 &&
                item.centerPointLongitude &&
                item.centerPointLatitude,
            )
            .sort((a, b) => (b[topLocationsFieldName][0].value || 0) - (a[topLocationsFieldName][0].value || 0)),
        }
      : null;
    return filteredData;
  }, [catchmentType, catchmentTreshold, selectedReportItem, topLocationsFieldName, socioDemoFieldName]);

  const chartsNumber = getChartsNumberByZoom(mapOptions.catchment.zoom || 9);
  return (
    <>
      {postalCodePolygons?.polygons.slice(0, chartsNumber).map((point) => (
        <MultiBarPin
          maxValue={postalCodePolygons.polygons[0][topLocationsFieldName][0].value * 100}
          key={point.location_id}
          longitude={point.centerPointLongitude}
          latitude={point.centerPointLatitude}
          name={point.name}
          unitLabel={catchmentMetricType === CatchmentMetricTypeEnum.SCORE ? '' : '%'}
          chartValues={point[topLocationsFieldName].map((item) => ({
            name: item.locationName,
            value: item.value,
            color: item.color,
          }))}
        />
      ))}
    </>
  );
};

export default WinnersBarChartPoints;
