import { createEvent } from 'effector';
import { PolygonData } from '../createLocation/interfaces';

export const deleteLocationOnFrontend = createEvent<string>();

export const removeLocationFromReport = createEvent<string>();

export const saveLocations = createEvent<PolygonData[]>();

export const updateLocation = createEvent<PolygonData>();

export const resetLocations = createEvent();

export const saveAndClearLocations = createEvent<PolygonData[]>();
