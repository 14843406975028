import React from 'react';
import { Line } from './line';
import styles from './styles.module.scss';
import { ItemInformation } from '../ItemInformation';
import { getYPosition } from '../utils';

interface Props {
  items: ItemInformation[];
  side: 'left' | 'right';
}
export const Lines: React.FC<Props> = ({ items, side }) => {
  const size = 400;
  const topHeight = size * 0.2;
  const bottomHeight = size * 0.8;
  const halfSize = size * 0.5;
  const radius = topHeight * 0.5;

  return (
    <div className={`${styles.lines} ${side === 'right' ? styles.right : styles.left}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
        {items.map(({ name, value }, index) => {
          const position = getYPosition(index, size, items.length);

          return (
            <Line
              coordinates={{
                radius,
                bottomHeight,
                halfSize,
                center: halfSize,
                yPosition: position,
                topHeight,
                translateY: 50,
              }}
              index={index}
              side={side}
            />
          );
        })}
      </svg>
    </div>
  );
};
