import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EChartsOption } from 'echarts';
import EChart from '../../../common/chart';
import { dateFormatter } from '../utils';
import styles from './style.module.scss';
import { TrendValue } from '../types';
import { ChartEvents } from '../../../common/chart/interfaces';
import { PeriodsEnum } from '../../../common/reportItems/types';
import { generateTooltip } from './tooltip';
import { useToggle } from '../../../common/hooks';
import { ChartEventsEnum } from '../../../common/chart/enums';
import { LocationInfo } from '../../../common/types/projectsHierarchy';
import { useGraphHeight } from '../../../common/reportItems/hooks';
import { formatNumberToReadableFormat } from '../../../common/utils';
import { DefaultLegend } from '../../../common/chart/legends';
import { useDoubleClick } from '../../../common/chart/hooks';

interface Props {
  data: TrendValue[];
  period: PeriodsEnum;
  isPercentage: boolean;
  locationsWithMissingAbsolute: string[];
  projectLocations: LocationInfo[];
  onChartReady?: VoidFunction;
  isScreenMode?: boolean;
  events?: ChartEvents;
  useFixedScreenshotSize?: boolean;
}

const dateOptions = {
  format: {
    monthly: 'MMM, YY',
    weekly: 'MMM DD, YY',
  },
};
function getChartOptions(
  data: TrendValue[],
  period: PeriodsEnum,
  isPercentage: boolean,
  locationsWithMissingAbsolute: string[],
  filteredLocations: Record<string, boolean>,
  projectLocations: LocationInfo[],
  t: (value: string) => string,
  isScreenMode?: boolean,
): EChartsOption {
  return {
    grid: {
      top: 20,
      left: 65,
      right: 0,
      bottom: period === PeriodsEnum.WEEKLY ? 120 : 100,
      height: 'auto',
    },
    xAxis: {
      type: 'category',
      data: data?.[0]?.data.map((item) => item.date),
      axisLabel: {
        formatter: (value: number) => dateFormatter(String(value), period, dateOptions),
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        rotate: 90,
        opacity: 0.6,
        margin: 8,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: 'rgba(255, 255, 255, .1)',
        },
      },
    },
    yAxis: {
      type: 'value',
      name: isPercentage
        ? `${t('visitTrends.detail.comparedToThe')} ${
            period === PeriodsEnum.MONTHLY ? t('reportItem.month').toLowerCase() : t('reportItem.week').toLowerCase()
          }`
        : t('visitTrends.detail.visits'),
      nameLocation: 'middle',
      axisLabel: {
        formatter: (value: number) =>
          !isPercentage
            ? `${value === 0 ? value : `${formatNumberToReadableFormat(value)}`}`
            : `${value === 0 ? value : `${(value * 100).toLocaleString()}%`}`,
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
        opacity: 0.6,
      },
      nameTextStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.07)',
        },
      },
      nameGap: 52,
    },
    // @ts-ignore
    legend: {
      show: !isScreenMode,
      align: 'auto',
      itemGap: 25,
      bottom: 0,
      icon: 'circle',
      type: 'scroll',
      selected: filteredLocations,
      textStyle: {
        fontFamily: 'Open Sans, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        color: '#FFFFFF',
      },
      pageTextStyle: {
        color: 'white',
      },
      pageIconColor: 'rgba(255, 255, 255, .25)',
      data: data?.map((item) => ({
        name: item.location_name,
        textStyle: {
          color: !isPercentage && locationsWithMissingAbsolute.includes(item.location_name) ? '#CCCCCC' : 'white',
        },
        itemStyle: {
          color:
            !isPercentage && locationsWithMissingAbsolute.includes(item.location_name)
              ? '#CCCCCC'
              : projectLocations.find((loc) => loc.id === item.location_id)?.color,
        },
      })),
    },
    tooltip: {
      trigger: 'axis',
      triggerOn: 'click',
      borderColor: 'rgba(255, 255, 255, 0.3)',
      backgroundColor: 'transparent',
      shadowColor: 'transparent',
      className: styles.tooltip,
      formatter(params: any) {
        return generateTooltip(params, isPercentage, period, t);
      },
    },
    series: data?.map((item, i) => ({
      animation: !isScreenMode,
      id: item.location_id,
      name: item.location_name,
      type: 'line',
      smooth: true,
      color: projectLocations.find((loc) => loc.id === item.location_id)?.color || '#FFF',
      data: item.data.map((el) => el.value),
      triggerLineEvent: false,
      lineStyle:
        item.location_id === 'average'
          ? {
              type: 'dotted',
              width: 4,
            }
          : undefined,
    })),
  };
}

export const Chart: React.FC<Props> = ({
  data,
  period,
  isPercentage,
  locationsWithMissingAbsolute,
  projectLocations,
  events,
  isScreenMode,
  useFixedScreenshotSize,
}) => {
  const { t } = useTranslation();
  const [filteredLocations, setFilteredLocations] = useState<Record<string, boolean>>({});
  const [locations, _] = useState<Record<string, boolean>>({});
  const [isPrevNumber, toggleIsPrevNumber] = useToggle(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { graphHeight } = useGraphHeight(wrapperRef, period, projectLocations.length);
  const { chartRef, onChange, selectedLocations } = useDoubleClick();

  const options = useMemo(
    () =>
      getChartOptions(
        data,
        period,
        isPercentage,
        locationsWithMissingAbsolute,
        filteredLocations,
        projectLocations,
        t,
        isScreenMode,
      ),
    [data, isPercentage, locationsWithMissingAbsolute, filteredLocations, isScreenMode, t],
  );

  useEffect(() => {
    const updatedLocations = { ...locations };
    if (!isPercentage) {
      locationsWithMissingAbsolute.forEach((location) => (updatedLocations[location] = false));
      toggleIsPrevNumber(true);
    } else if (isPercentage) {
      if (isPrevNumber) {
        locationsWithMissingAbsolute.forEach((location) => (updatedLocations[location] = true));
        toggleIsPrevNumber(false);
      }
    }
    setFilteredLocations(updatedLocations);
  }, [isPercentage, locationsWithMissingAbsolute, locations, isPrevNumber]);

  useEffect(() => {
    setFilteredLocations(selectedLocations);
  }, [selectedLocations]);

  const onLegendClick: ChartEvents[ChartEventsEnum.LEGEND_SELECT_CHANGED] = useCallback(
    (e) => {
      if (!isPercentage && locationsWithMissingAbsolute.includes(e.name)) {
        return;
      }
      onChange(e);
    },
    [locationsWithMissingAbsolute, isPercentage],
  );

  const legendItems = useMemo(() => {
    const hasFilteredLocations = Object.keys(filteredLocations).length > 0;
    if (hasFilteredLocations) {
      return data.map(({ location_id, location_name }) => {
        const fullLocationInformation = projectLocations.find(({ id }) => id === location_id);
        const isActive = filteredLocations[location_name];
        return {
          id: location_id,
          label: location_name,
          color: isActive ? fullLocationInformation?.color || '' : 'grey',
        };
      });
    }

    return data.map(({ location_id, location_name }) => {
      const fullLocationInformation = projectLocations.find(({ id }) => id === location_id);
      return {
        id: location_id,
        label: location_name,
        color: fullLocationInformation ? fullLocationInformation?.color || '' : 'grey',
      };
    });
  }, [data, projectLocations]);

  return (
    <div>
      <div style={{ height: useFixedScreenshotSize ? '300px' : graphHeight }} ref={wrapperRef}>
        <EChart option={{ ...options }} events={{ legendselectchanged: onLegendClick, ...events }} ref={chartRef} />
      </div>
      {isScreenMode && (
        <div className={styles.legend}>
          <DefaultLegend data={legendItems} />
        </div>
      )}
    </div>
  );
};
