import { ExcelExport, alignment } from '../lib';
import { generateFileName } from './generateFileName';
import { ExportData } from '../../model/interfaces';
import { ReportItemsInformation } from '../../model/interfaces/ReportItemsInformation.interface';

const defaultColumnStyles = {
  alignment: { vertical: 'middle', horizontal: 'center' },
};

const LeftColumnStyles = {
  alignment: { vertical: 'middle', horizontal: 'left' },
};

const generateVisitTrends = (reportItemName: string) => ({
  footfall_title: {
    headerDefinition: [{ name: reportItemName, key: 'title' }],
  },
  visitTrends: {
    headerDefinition: [
      {
        name: 'Location',
        key: 'location',
        headerStyle: LeftColumnStyles,
        style: {
          alignment: alignment.middleLeft,
        },
      },
      {
        name: 'Time range',
        key: 'time_range',
        headerStyle: defaultColumnStyles,
      },
      {
        name: 'Footfall index',
        key: 'footfall_index',
        headerStyle: defaultColumnStyles,
      },
    ],
  },
});

const generateCatchment = (reportItemName: string, header: string) => ({
  catchment_title: {
    headerDefinition: [{ name: reportItemName, key: 'title' }],
  },
  catchment: {
    headerDefinition: [
      {
        name: 'Primary location',
        key: 'location',
        headerStyle: LeftColumnStyles,
        style: {
          alignment: alignment.middleLeft,
        },
      },
      {
        name: 'Postal code',
        key: 'postal_code',
        headerStyle: defaultColumnStyles,
      },
      {
        name: 'Aerial distance',
        key: 'aerial_distance',
        headerStyle: defaultColumnStyles,
      },
      {
        name: header,
        key: 'visits_share',
        headerStyle: defaultColumnStyles,
      },
    ],
  },
});

const generateSeasonality = (reportItemName: string) => ({
  seasonality_title: {
    headerDefinition: [{ name: reportItemName, key: 'title' }],
  },
  seasonality: {
    headerDefinition: [
      {
        name: 'Location',
        key: 'location',
        headerStyle: LeftColumnStyles,
        style: {
          alignment: alignment.middleLeft,
        },
      },
      {
        name: 'Day of week',
        key: 'week_day',
        headerStyle: defaultColumnStyles,
      },
      {
        name: 'Hour of day',
        key: 'hour_day',
        headerStyle: defaultColumnStyles,
      },
      {
        name: 'Share of visits',
        key: 'visits_share',
        headerStyle: defaultColumnStyles,
      },
    ],
  },
});

const generateDuration = (reportItemName: string) => ({
  duration_title: {
    headerDefinition: [{ name: reportItemName, key: 'title' }],
  },
  duration: {
    headerDefinition: [
      {
        name: 'Location',
        key: 'location',
        headerStyle: LeftColumnStyles,
        style: {
          alignment: alignment.middleLeft,
        },
      },
      {
        name: 'Time range',
        key: 'time_range',
        headerStyle: defaultColumnStyles,
      },
      {
        name: 'Visit proportion',
        key: 'visit_proportion',
        headerStyle: defaultColumnStyles,
      },
    ],
  },
});

const generateRetention = (reportItemName: string) => ({
  retention_title: {
    headerDefinition: [{ name: reportItemName, key: 'title' }],
  },
  retention: {
    headerDefinition: [
      {
        name: 'Location',
        key: 'location',
        headerStyle: LeftColumnStyles,
        style: {
          alignment: alignment.middleLeft,
        },
      },
      {
        name: 'Date',
        key: 'date',
        headerStyle: defaultColumnStyles,
      },
      {
        name: 'Retention rate',
        key: 'retention_rate',
        headerStyle: defaultColumnStyles,
      },
    ],
  },
});

const generateCrossVisitation = (reportItemName: string) => ({
  crossVisitation_title: {
    headerDefinition: [{ name: reportItemName, key: 'title' }],
  },
  crossVisitation: {
    headerDefinition: [
      {
        name: 'Area name',
        key: 'area_name',
        headerStyle: LeftColumnStyles,
        style: {
          alignment: alignment.middleLeft,
        },
      },
      {
        name: 'Aerial distance',
        key: 'aerial_distance',
        headerStyle: defaultColumnStyles,
      },
      {
        name: 'Share of visitors',
        key: 'visitors_loyalty',
        headerStyle: defaultColumnStyles,
      },
      {
        name: 'Visits ratio',
        key: 'ratio',
        headerStyle: defaultColumnStyles,
      },
    ],
  },
});

const generateAbsorptionAttraction = (
  data: Record<string, string | Record<string, string>[]>[],
  title: string,
  reportItemName: string,
) => {
  const additionalColumns = (
    (data as Record<string, Record<string, string>[]>[])?.[0] as unknown as {
      data: {
        timestamp: string;
        value: string;
        date: string;
      }[];
    }
  ).data.map(({ date, timestamp }) => ({ name: date, key: timestamp, headerStyle: defaultColumnStyles }));

  return {
    title: {
      headerDefinition: [{ name: reportItemName, key: 'title' }],
    },
    [title.toLowerCase()]: {
      headerDefinition: [
        {
          name: 'Location name',
          key: 'location',
          headerStyle: LeftColumnStyles,
          style: {
            alignment: alignment.middleLeft,
          },
        },
        {
          name: 'Source',
          key: 'source',
          headerStyle: defaultColumnStyles,
        },
        ...additionalColumns,
      ],
    },
  };
};

const parseAbsorptionAttractionData = (data: Record<string, string | Record<string, string>[]>[]) =>
  data.map(({ location, source, data }) => ({
    location,
    source,
    ...(data as Record<string, string>[]).reduce((acc, { timestamp, value }) => ({ ...acc, [timestamp]: value }), {}),
  }));

export const generateExcel = (exportData: ExportData, reportItemsInformation: ReportItemsInformation, name: string) => {
  const fileName = generateFileName(name, 'pdf');
  const workSheets = [];
  const data = [];

  if (exportData.FOOTFALL?.table) {
    workSheets.push({
      sheetName: 'Visit trends',
      startingRowNumber: 1,
      gapBetweenTwoTables: 1,
      tableSettings: {
        ...generateVisitTrends(name),
      },
    });

    data.push({
      footfall_title: [],
      visitTrends: exportData.FOOTFALL?.table,
    });
  }

  if (exportData.CATCHMENT?.table) {
    workSheets.push({
      sheetName: 'Catchment',
      startingRowNumber: 1,
      gapBetweenTwoTables: 1,
      tableSettings: {
        ...generateCatchment(name, reportItemsInformation.catchment.header),
      },
    });

    data.push({
      catchment_title: [],
      catchment: exportData.CATCHMENT?.table,
    });
  }

  if (exportData.SEASONALITY?.table) {
    workSheets.push({
      sheetName: 'Popular day & hour',
      startingRowNumber: 1,
      gapBetweenTwoTables: 1,
      tableSettings: {
        ...generateSeasonality(name),
      },
    });

    data.push({
      seasonality_title: [],
      seasonality: exportData.SEASONALITY?.table,
    });
  }

  if (exportData.DURATION?.table) {
    workSheets.push({
      sheetName: 'Duration',
      startingRowNumber: 1,
      gapBetweenTwoTables: 1,
      tableSettings: {
        ...generateDuration(name),
      },
    });

    data.push({
      duration_title: [],
      duration: exportData.DURATION?.table,
    });
  }

  if (exportData.RETENTION?.table) {
    workSheets.push({
      sheetName: 'Retention',
      startingRowNumber: 1,
      gapBetweenTwoTables: 1,
      tableSettings: {
        ...generateRetention(name),
      },
    });

    data.push({
      retention_title: [],
      retention: exportData.RETENTION?.table,
    });
  }

  if (exportData.LOYALTY?.table) {
    workSheets.push({
      sheetName: 'Cross visitation',
      startingRowNumber: 1,
      gapBetweenTwoTables: 1,
      tableSettings: {
        ...generateCrossVisitation(name),
      },
    });

    data.push({
      crossVisitation_title: [],
      crossVisitation: exportData.LOYALTY?.table,
    });
  }

  if (exportData.ABSORPTION?.table) {
    workSheets.push({
      sheetName: 'Absorption',
      startingRowNumber: 1,
      gapBetweenTwoTables: 1,
      tableSettings: {
        // absorption_title: [],
        ...generateAbsorptionAttraction(exportData.ABSORPTION?.table, 'Absorption', name),
      },
    });

    data.push({
      title: [],
      absorption: parseAbsorptionAttractionData(exportData.ABSORPTION?.table),
    });
  }

  if (exportData.ATTRACTION?.table) {
    workSheets.push({
      sheetName: 'Attraction',
      startingRowNumber: 1,
      gapBetweenTwoTables: 1,
      tableSettings: {
        ...generateAbsorptionAttraction(exportData.ATTRACTION?.table, 'Attraction', name),
      },
    });

    data.push({
      title: [],
      attraction: parseAbsorptionAttractionData(exportData.ATTRACTION?.table),
    });
  }

  const options = {
    fileName,
    workSheets,
  };

  const excelExport = new ExcelExport();
  excelExport.downloadExcel(options, data);
};
