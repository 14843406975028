import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { getRandomNumber } from '../../utils';
import { useWindowSize } from '../../hooks';

const options = [
  { coefficient: 1.2 },
  { coefficient: 1.2 },
  { coefficient: 1 },
  { coefficient: 0 },
  { coefficient: 1.6 },
  { coefficient: 1.4 },
  { coefficient: 0 },
  { coefficient: 1 },
  { coefficient: 1 },
];
export const CloudsLoader = () => {
  const [cloudPosition, setCloudPosition] = useState<number[]>([]);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width) {
      const data = options.map(({ coefficient }) => getRandomNumber(0, width / coefficient));
      setCloudPosition(data);
    }
  }, [width]);

  return (
    <div className={`${styles.wrapper}`}>
      {options.map((_, index) => {
        const isOdd = index % 2 === 0;
        return (
          <img
            key={index}
            src={`/img/v2/clouds-${isOdd ? 1 : 2}.png`}
            alt="clouds"
            style={{ left: `${cloudPosition[index]}px` }}
          />
        );
      })}
    </div>
  );
};
