import { toFixNumber } from '../../../../../common/lib';
import { AnalyticItemInformation, Category, ColumnData } from '../../../types';
import { DataLevelEnum, LocationTypeEnum } from '../../../../../common/reportItems/types';

type dataType = 'categories' | 'locations';
type locationType = 'pois' | 'buildings';

const getMainData = (data: Category, work: boolean, home: boolean) => {
  if (work && home) {
    return data.mainWithWorkAndHomeData;
  }

  if (work) return data.mainWithWorkData;
  if (home) return data.mainWithHomeData;

  return data.main;
};

const getHomeData = (data: Category, work: boolean, home: boolean) => {
  const { additional } = data;

  if (work && home) return additional.homeDataWithWorkAndHome;
  if (work) return additional.homeDataWithWork;
  if (home) return additional.homeDataWithHome;

  return additional.home;
};

const getWorkData = (data: Category, work: boolean, home: boolean) => {
  const { additional } = data;

  if (work && home) return additional.workDataWithWorkAndHome;
  if (work) return additional.workDataWithWork;
  if (home) return additional.workDataWithHome;

  return additional.home;
};

export const formatDataForColumnDisplaying = (
  data: ColumnData,
  locationType: LocationTypeEnum,
  dataType: DataLevelEnum,
  workDataType: boolean,
  homeDataType: boolean,
) => {
  const dataAccordingToSelectedLocationType =
    data[dataType.toLowerCase() as dataType][locationType.toLowerCase() as locationType];

  const home = getHomeData(dataAccordingToSelectedLocationType, workDataType, homeDataType);
  const work = getWorkData(dataAccordingToSelectedLocationType, workDataType, homeDataType);

  const mainData = getMainData(dataAccordingToSelectedLocationType, workDataType, homeDataType);

  const homeAndWorkData = [
    {
      name: 'Home',
      address: 'Home',
      category: 'Home',
      subcategory: 'Home',
      value: homeDataType ? home : null,
    },
    {
      name: 'Work',
      address: 'Work',
      category: 'Work',
      subcategory: 'Work',
      value: workDataType ? work : null,
    },
  ];

  const sortedData = mainData.sort((a, b) => b.value - a.value);

  const fullData = [...homeAndWorkData, ...sortedData];

  const filteredData = fullData.filter(({ value }) => value) as AnalyticItemInformation[];

  const main = filteredData.slice(0, 30);

  return main.map(({ name, address, category, subcategory, value }) => {
    let title = '';
    if (dataType === DataLevelEnum.CATEGORY) {
      title = subcategory || category || 'Unknown category';
    } else if (name?.length > 0) {
      title = name;
    } else {
      title = locationType === LocationTypeEnum.POIS ? 'POI' : 'Building';
      if (subcategory?.length > 0) {
        title += ` (${subcategory})`;
      } else if (category?.length > 0) {
        title += ` (${category})`;
      } else if (address.length > 0) {
        title += ` (${address})`;
      }
    }
    return { name: title, address, category, subcategory, value: `${toFixNumber(value * 100, 2)}%` };
  }, []);
};
