import React from 'react';
import { MenuItem } from '../../../common/page/menuItem/menuItem';
import { PointerIcon } from '../../../common/icons/menuItems';
import { MenuItemsEnum } from './menuItemsEnum';

interface TProps {
  onClick: (item: MenuItemsEnum) => void;
  selected?: boolean;
}

export const ProjectsMenuItem = (props: TProps) => (
  <MenuItem
    icon={<PointerIcon />}
    id={MenuItemsEnum.PROJECTS}
    onClick={props.onClick}
    selected={!!props.selected}
    title="My projects"
  />
);
