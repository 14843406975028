import { splitCoordinatesArrayToPairs } from './parseCoordinatesToPolygon';

export const convertPolygonsToPoints = (data: string[]) =>
  data.map((polygon) => {
    const coordinates = splitCoordinatesArrayToPairs(polygon.split(','));
    return coordinates.map(([longitude, latitude]) => ({
      longitude: String(longitude),
      latitude: String(latitude),
    }));
  });
