import { RetentionLocation } from '../../../../common/types/projectsHierarchy';
import { PeriodsEnum } from '../../../../common/reportItems/types';
import { dateFormatter } from '../../utils';
import { toFixNumber } from '../../../../common/lib';

export const convertDataForExcel = (data: RetentionLocation[], period: PeriodsEnum) => {
  const entities = data.map(({ data, location_name }) => {
    return data.map(({ value, date }) => {
      return {
        location: location_name,
        date: dateFormatter(period, String(date)),
        retention_rate: `${toFixNumber(Number(value) * 100, 2)}%`,
      };
    });
  });
  return entities.reduce((acc, curr) => [...acc, ...curr], []);
};
