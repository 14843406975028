import React from 'react';
import styles from './style.module.scss';
import { SwitcherItem } from './interfaces';
import { Skeleton } from '../../skeleton';

interface Props {
  items: SwitcherItem[];
  activeItem: SwitcherItem;
  onChange: (item: SwitcherItem) => void;
  isLoading?: boolean;
  disabled?: boolean;
}
export const DefaultSwitcher: React.FC<Props> = ({ items, activeItem, onChange, isLoading, disabled }) =>
  isLoading ? (
    <Skeleton className={styles.skeleton} />
  ) : (
    <div className={styles.switcher}>
      {items.map(({ value, id }) => (
        <div
          key={`${id}_${value}`}
          className={`${styles.item} ${value === activeItem.value ? styles.active : ''}`}
          onClick={() => {
            if (!disabled) onChange({ value, id });
          }}
        >
          {value}
        </div>
      ))}
    </div>
  );
