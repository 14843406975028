import { getData, getFilters } from './effects';
import { $data, $filterOptions, $activeFilters, $initialFilterOptions, $metricType, $yAxisLabel } from './stores';
import { changeFilters, updateFiltersOptions } from './events';

export const characteristicAudienceModel = {
  getData,
  getFilters,
  changeFilters,
  updateFiltersOptions,
  $data,
  $filterOptions,
  $activeFilters,
  $initialFilterOptions,
  $metricType,
  $yAxisLabel,
};
