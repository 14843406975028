import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EChartsOption } from 'echarts';
import { TEXT_STYLE } from '../../../common/chart/configuration';
import { Chart } from '../../../common/chart/chart';
import { dateFormatter } from '../utils';
import { generateChartTooltip } from './tooltips';
import { splitArrayIntoChunksOfLen } from '../../../common/utils';
import { Skeleton } from '../../../common/skeleton';
import styles from './style.module.scss';

const displayDates = (dates: number[] = []) => {
  const total = dates.length;
  if (total <= 6) return dates.map((date) => String(date));
  const chunksCount = 5;

  const result: (string | number)[] = [];
  const chunks = splitArrayIntoChunksOfLen(dates, Math.ceil(total / chunksCount));

  chunks.forEach((chunk, index) => {
    const chunkTotal = chunk.length;
    if (index === 0) {
      const [first, ...rest] = chunk;
      result.push(String(first));
      rest.forEach(() => result.push(''));
    }

    if (index === chunks.length - 1) {
      if (chunkTotal === 1) {
        result.push(chunk[0]);
      } else {
        const last = chunk[chunkTotal - 1];
        const rest = chunk.slice(0, chunkTotal - 1);
        rest.forEach(() => result.push(''));
        result.push(String(last));
      }
    }

    if (index !== 0 && index !== chunks.length - 1) {
      if (chunkTotal === 1) {
        result.push(chunk[0]);
      } else {
        const middleIndex = Math.floor(chunkTotal / 2);
        const beforeMiddle = chunk.slice(0, middleIndex);
        const afterMiddle = chunk.slice(middleIndex + 1, chunkTotal);
        const middle = chunk[middleIndex];
        beforeMiddle.forEach(() => result.push(''));
        result.push(String(middle));
        afterMiddle.forEach(() => result.push(''));
      }
    }
  });

  return result;
};

const symbolBase64 =
  'image://data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF81NDUyXzI0ODM5KSI+CjxjaXJjbGUgY3g9IjkuMzU2NTIiIGN5PSI3IiByPSI0IiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2RfNTQ1Ml8yNDgzOSIgeD0iMC4zNTY1MjIiIHk9IjAiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4KPGZlT2Zmc2V0IGR5PSIyIi8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIuNSIvPgo8ZmVDb21wb3NpdGUgaW4yPSJoYXJkQWxwaGEiIG9wZXJhdG9yPSJvdXQiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMyAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93XzU0NTJfMjQ4MzkiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3dfNTQ1Ml8yNDgzOSIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K';
const getChartOptions = (
  data: number[],
  isPercentage: boolean,
  displayedDates: (string | number)[],
  isAbsoluteNumbersDisabled: boolean,
  t: (value: string) => string,
  dates?: number[],
): EChartsOption => ({
  grid: {
    top: 0,
    left: dates && dates.length > 8 ? 21 : 0,
    right: dates && dates.length > 8 ? 21 : 0,
    bottom: 20,
  },
  xAxis: {
    type: 'category',
    data: dates,
    axisLabel: {
      color: '#FFFFFF',
      interval: 0,
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      opacity: 0.6,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed',
        dashOffset: 10,
        color: 'rgba(255, 255, 255, .1)',
      },
    },
  },
  yAxis: {
    type: 'value',
    nameLocation: 'middle',
    splitNumber: 2,
    axisLabel: {
      show: false,
    },
    nameTextStyle: {
      ...TEXT_STYLE,
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.07)',
      },
    },
  },
  axisLabel: {
    formatter: (value: string) => (displayedDates.includes(value) ? dateFormatter(value) : ''),
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line',
      axis: 'x',
      lineStyle: {
        color: '#D4D4D4',
        shadowColor: 'white',
      },
    },
    formatter(params: any) {
      return generateChartTooltip(params[0], isAbsoluteNumbersDisabled, [t('reportItem.date'), t('reportItem.value')]);
    },
  },
  series: {
    data,
    type: 'line',
    smooth: true,
    color: '#00B2F1',
    symbol: symbolBase64,
    showSymbol: false,
    symbolSize: 15,
  },
});

interface Props {
  data: number[];
  dates?: number[];
  isPercentage: boolean;
  isLoading: boolean;
  isAbsoluteNumbersDisabled: boolean;
  chartSizeType?: 'small' | 'default';
}

const getHeight = (type: 'small' | 'default') => {
  switch (type) {
    case 'small':
      return '65px';
    case 'default':
      return '135';
    default:
      return '135';
  }
};
export const TrendsChart: React.FC<Props> = ({
  data,
  dates,
  isPercentage,
  isLoading,
  isAbsoluteNumbersDisabled,
  chartSizeType = 'default',
}) => {
  const { t } = useTranslation();
  const displayedDates = useMemo(() => displayDates(dates), [dates]);

  const options = useMemo(
    () => getChartOptions(data, isPercentage, displayedDates, isAbsoluteNumbersDisabled, t, dates),
    [data, dates, isPercentage, displayedDates, isAbsoluteNumbersDisabled],
  );

  return (
    <div className="chart-container" style={{ height: getHeight(chartSizeType) }}>
      {isLoading ? <Skeleton className={styles.chartSkeleton} /> : <Chart option={{ ...options }} />}
    </div>
  );
};
