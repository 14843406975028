import React, { ReactElement } from 'react';
import Page from '../../templates/page/page';
import styles from './addAccountPage.module.css';
import ErrorMessage from '../../organisms/errorMessage';
import AccountForm from '../../organisms/AccountForm/AccountForm';

const AddAccountPage = (): ReactElement => (
  <Page title="Add account">
    <div className={styles.formContainer}>
      <ErrorMessage />
      <AccountForm />
    </div>
  </Page>
);

export default AddAccountPage;
