import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { SidebarMenu } from '../../sidebarMenu/sidebarMenu';

export const DefaultPageTemplate: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const accountType = localStorage.getItem('accountType');
  const isDemo = accountType === 'SELF_REGISTRATION' || accountType === 'FREEMIUM';

  return (
    <>
      {isDemo && (
        <div className={styles.demoBar}>
          {t('demo.thisIsDemo')}{' '}
          <a href="https://placesense.ai/contact/" target="_blank" rel="noreferrer">
            {t('demo.contactUs')}
          </a>{' '}
          {t('demo.toGetAccess')}
        </div>
      )}
      <div
        className={`${styles.page} ${isDemo ? styles.withDemoBar : ''}`}
        style={{ backgroundImage: 'url(/img/v2/intro-bg-big.jpg)' }}
      >
        <div className={styles.sidebar}>
          <SidebarMenu />
        </div>
        <div className={`${styles.basic}`} style={{ width: '100%' }}>
          {children}
        </div>
      </div>
    </>
  );
};
