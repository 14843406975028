import React, { RefObject, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './reportItemCardHeader.module.scss';
import { InformationIcon, MenuIcon } from '../../../icons/reportItems';
import { ReportItemType } from '../../types';
import { reportItemCardConfiguration } from '../../configuration';
import { MainContext } from '../../../types/mainContext';
import { ReportItemInfo, ReportItems } from '../../../types/projectsHierarchy';
import { CircleButton } from '../../../controls/buttons/circleButton/circleButton';
import { trackUserAction, UserActionsEnum } from '../../../../../mixpanel';
import { Skeleton } from '../../../skeleton';
import { useURLParams } from '../../../hooks';

interface Props {
  reportItemType: ReportItemType;
  reportItem: ReportItemInfo<ReportItems> | null;
  onHeaderMenuClick?: VoidFunction;
  onOpenTooltip?: VoidFunction;
  disabled?: boolean;
  isHeaderMenuOpened?: boolean;
  isGlobalLoading: boolean;
  tooltipRef?: RefObject<HTMLDivElement>;
}

const ReportItemCardHeader: React.FC<Props> = ({
  reportItemType,
  reportItem,
  onHeaderMenuClick,
  disabled,
  isHeaderMenuOpened,
  onOpenTooltip,
  isGlobalLoading,
  tooltipRef,
}) => {
  const { t } = useTranslation();
  const {
    updateSelectedReportItem,
    selection: { selectedReportItem },
  } = useContext(MainContext);
  const { onChangeParams } = useURLParams();

  const configuration = useMemo(
    () => (reportItemCardConfiguration.has(reportItemType) ? reportItemCardConfiguration.get(reportItemType) : null),
    [reportItemType],
  );

  if (!configuration) {
    return <></>;
  }

  const onClickHandler = () => {
    if (reportItem && !disabled) {
      updateSelectedReportItem(reportItem);
      trackUserAction('Report item clicked', UserActionsEnum.OPEN_REPORT_ITEM, reportItem.type);
      onChangeParams('reportItemType', reportItem.type);
    }
  };

  const onTooltipIconClick = () => {
    if (onOpenTooltip) {
      trackUserAction(`Tooltip icon clicked in ${reportItemType}`, UserActionsEnum.TOOLTIP_ICON_CLICK);
      onOpenTooltip();
    }
  };

  return (
    <div className={styles.container}>
      {isGlobalLoading ? (
        <span className={styles.skeleton}>
          <Skeleton className={styles.skeletonButton} />
        </span>
      ) : (
        <div className={styles.title} onClick={onClickHandler}>
          <CircleButton
            icon={<configuration.icon />}
            active={selectedReportItem?.type === reportItemType}
            className={selectedReportItem?.type !== reportItemType ? styles.iconContainer : ''}
          />
          <h2>{t(`reportItem.enum.${reportItemType}`)}</h2>
        </div>
      )}
      <div className={styles.actions}>
        <span className={styles.tooltipIcon} id={`header-report-item-tooltip-${reportItemType}`} ref={tooltipRef}>
          {isGlobalLoading ? (
            <Skeleton className={styles.skeletonIcon} />
          ) : (
            <span className={styles.icon} onClick={onTooltipIconClick} title={t('reportItem.howItWorks')}>
              <InformationIcon />
            </span>
          )}
        </span>
        {onHeaderMenuClick && !disabled ? (
          <span className={styles.settings} id={`header-report-item-menu-${reportItemType}`}>
            {isGlobalLoading ? (
              <Skeleton className={styles.dotsSkeleton} />
            ) : (
              <button
                type="button"
                className={`${styles.menuContainer} ${isHeaderMenuOpened ? styles.menuActive : ''}`}
                onClick={onHeaderMenuClick}
                title="Settings"
              >
                <MenuIcon />
              </button>
            )}
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ReportItemCardHeader;
