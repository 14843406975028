import React, { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import { SearchIcon } from '../../icons/controls';
import { SmallLogoIcon } from '../../icons/chart';
import { GOOGLE_KEY } from '../../maps/mapBase/map.config';
import { Place } from './interfaces';
import styles from './style.module.scss';

Geocode.setApiKey(GOOGLE_KEY);

interface Props {
  onSelectPlace: (data: Place) => void;
}
export const SearchLocation: React.FC<Props> = ({ onSelectPlace }) => {
  const [value, setValue] = useState('');

  const onChange = (value: string) => {
    setValue(value);
  };

  const onSelect = (place: string) => {
    setValue(place);
    Geocode.fromAddress(place)
      .then((response) => {
        onSelectPlace(response.results[0]);
      })
      .catch(() => {
        setValue('');
      });
  };

  return (
    <PlacesAutocomplete value={value} onChange={onChange} onSelect={onSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <label className={styles.wrapper}>
          <div className={styles.field}>
            <SmallLogoIcon />
            <input id="polygonToolSearchFieldAutoComplete" {...getInputProps()} className={styles.input} />
            <SearchIcon />
          </div>
          {suggestions.length > 0 && (
            <div className={styles.suggestions}>
              {suggestions.map((suggestion) => (
                <div {...getSuggestionItemProps(suggestion)} key={suggestion.id} className={styles.suggestion}>
                  {suggestion.description}
                </div>
              ))}
            </div>
          )}
        </label>
      )}
    </PlacesAutocomplete>
  );
};
