import React, { useState } from 'react';
import styles from './yearMonthRangePicker.module.scss';
import Menu from './Menu';
import YearMonthLabel from './Menu/YearMonthLabel';

interface TProps {
  start: Date;
  end: Date;
  min?: Date;
  max?: Date;
  onChange: (start: Date, end: Date) => void;
  disabledFrom?: boolean;
  disabledTo?: boolean;
}

const YearMonthRangePicker = ({ start, end, onChange, min, max, disabledFrom, disabledTo }: TProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  const saveHandler = (startDate: Date, endDate: Date) => {
    setIsMenuOpened(false);
    onChange(startDate, endDate);
  };

  const toggleMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsMenuOpened((prevState) => !prevState);
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setIsMenuOpened(false);
    }
  };

  return (
    <div onBlur={onBlurHandler} className={styles.container} tabIndex={0}>
      <YearMonthLabel startDate={start} endDate={end} isMenuOpened={isMenuOpened} onToggleMenu={toggleMenu} />

      {isMenuOpened && (
        <Menu
          startDate={start}
          endDate={end}
          minDate={min || null}
          maxDate={max || null}
          onClose={() => setIsMenuOpened(false)}
          onSave={saveHandler}
          disabledFrom={disabledFrom}
          disabledTo={disabledTo}
        />
      )}
    </div>
  );
};

export default YearMonthRangePicker;
