import React from 'react';
import styles from './locations.module.scss';
import { PlaceSenseIcon } from '../icons/controlPanel';
import { Checkbox } from '../controls/checkbox';

type TProps = {
  id: string;
  name: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (id: string) => void;
};

const LocationRow = (props: TProps) => {
  const onCheckboxClick = () => {
    if (props.onClick) {
      props.onClick(props.id);
    }
  };

  return (
    <div className={styles.locationRow}>
      <span className={styles.icon}>
        <PlaceSenseIcon /> {props.name}
      </span>
      <Checkbox
        id={props.id}
        isChecked={Boolean(props.selected)}
        disabled={props.disabled}
        onChange={onCheckboxClick}
        name={props.name}
      />
    </div>
  );
};

export default LocationRow;
