import { Area } from '../../../types/visualizationObjects';
import { isPointInPolygon } from '../../utils';

type Point = [number, number];

export const getClickedPolygon = (point: Point, data: Area[]) => {
  const checkIsInPolygon = isPointInPolygon(point);

  const locations = data.filter((area) => checkIsInPolygon(area));

  return locations[0];
};
