import {DurationCategory} from "../types/projectsHierarchy";

export function getDurationCategoryNumberByName(
    category: DurationCategory
): number {
    switch (category) {
        case DurationCategory['0-5']:
            return 0;
        case DurationCategory['5-15']:
            return 1;
        case DurationCategory['15-30']:
            return 2;
        case DurationCategory["30-60"]:
            return 3;
        case DurationCategory["60-120"]:
            return 4;
        case DurationCategory["120+"]:
            return 5;
        default:
            return 0;
    }
}

export function getDurationCategoryByNumber(num: number):DurationCategory{
    switch (num) {
        case 0:
            return DurationCategory['0-5'];
        case 1:
            return DurationCategory['5-15'];
        case 2:
            return DurationCategory['15-30'];
        case 3:
            return DurationCategory["30-60"];
        case 4:
            return DurationCategory["60-120"];
        case 5:
            return DurationCategory["120+"];
        default:
            return DurationCategory['0-5'];
    }
}

export function sortDurationCategoryArray(arr:DurationCategory[]|undefined):DurationCategory[]{
    if(arr === null || arr === undefined){
        return[0,1,2,3,4,5].map(item=>getDurationCategoryByNumber(item));
    }
    return arr.map(item=>getDurationCategoryNumberByName(item)).sort().map(item=>getDurationCategoryByNumber(item));
}