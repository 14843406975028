import { replaceValueToNA } from '../replaceValueToNA';
import { ResidentIcon } from '../../../../../../../common/icons/charts';
import { StakeType } from '../../../../../../../common/chart/demographic/interfaces';

export const getResident = (value: number | null, contribution: number | null) => ({
  id: 1,
  icon: ResidentIcon,
  type: 'information' as StakeType,
  data: [
    {
      id: 1,
      color: '#DBAA85',
      title: 'inhabitants',
      value: replaceValueToNA(value),
      stake: 100,
      contribution: contribution ? `${contribution}%` : null,
    },
  ],
});
