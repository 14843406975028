import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './basicPopup.module.css';
import MapPopup from '../../../../common/maps/popup/MapPopup';
import { MapPopupData } from '../../../../common/types/visualizationObjects';

interface Props {
  data: MapPopupData;
  closePopup: () => void;
}
export const CommonPopup = forwardRef<HTMLDivElement, Props>(({ data, closePopup }, ref) => {
  const currentData = data.data as any;
  const { t } = useTranslation();
  return data.coordinates.length > 0 ? (
    <MapPopup coordinate={data.coordinates} onClose={closePopup}>
      <div className={styles.root} ref={ref}>
        <h3 className={styles.title}>{data.title}</h3>
        {currentData && currentData.length > 0 && (
          <table className={styles.features}>
            <tbody>
              {currentData.map((item: any, index: number) => (
                <tr key={index}>
                  <th>{`${t(item.name)}:`}</th>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </MapPopup>
  ) : null;
});
