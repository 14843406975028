import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageCircle } from './imageCircle';
import { Information } from './information';
import styles from './style.module.scss';
import { calculateHeightOfInformation } from './utils';
import { ParticleData, Sides, StakeStyles, StakeType } from '../interfaces';
import { useClickOutside, useToggle } from '../../../hooks';
import { DetailInformationPopup } from './detailInformationPopup';
import { Retail } from '../../../../reportItems/catchment/detail/Chart/model';

interface Props {
  data: ParticleData[];
  icon: React.ElementType;
  side?: Sides;
  contentSide?: Sides;
  contentType?: StakeType;
  customStyles?: StakeStyles;
  retails: Retail[];
}

const INFORMATION_ROW_ITEM_COUNT = 3;
export const InformationParticle: React.FC<Props> = ({
  data,
  icon,
  customStyles,
  side = 'right',
  contentSide,
  contentType,
  retails,
}) => {
  const { t } = useTranslation();
  const [isOpenMore, toggleIsOpenMore] = useToggle(false);
  const ref = useRef<HTMLDivElement>(null);
  const { length } = data;
  const informationRows = Math.ceil(length / INFORMATION_ROW_ITEM_COUNT);

  const onOpenMore = () => {
    toggleIsOpenMore(true);
  };

  const onCloseMore = () => {
    toggleIsOpenMore(false);
  };

  useClickOutside(ref, onCloseMore, isOpenMore);
  return (
    <div className={styles.particle}>
      {isOpenMore && (
        <div className={styles.popup} ref={ref}>
          <DetailInformationPopup data={retails} title="Retail categories" onClose={onCloseMore} />
        </div>
      )}
      <ImageCircle data={data} Icon={icon} />
      <div
        className={`${styles.information} ${
          length === 1 ? styles.single : length === 2 ? styles.multiple : styles.triple
        } ${side === 'right' ? styles.right : styles.left}`}
        style={{
          top: `${calculateHeightOfInformation(
            informationRows,
            customStyles?.item?.top ? (customStyles?.item?.top as number) : 55,
          )}px`,
          ...customStyles?.item,
        }}
      >
        {data
          .sort((a, b) => a.id - b.id)
          .map(({ color, title, value, styles, options, contribution }, index) => (
            <Information
              key={index}
              color={color}
              label={
                t(`catchment.detail.${title}`).startsWith('catchment.detail') ? title : t(`catchment.detail.${title}`)
              }
              value={value}
              contentSide={contentSide}
              contentType={contentType}
              customStyles={styles}
              options={options}
              onOpenMore={onOpenMore}
              contribution={contribution}
            />
          ))}
      </div>
    </div>
  );
};
