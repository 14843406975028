import React from 'react';
import { IconProps } from '../types';

export const BarIcon: React.FC<IconProps> = ({ active }) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 4.43301H3.3V13.4163H0.5V4.43301ZM6.1 0.583008H8.9V13.4163H6.1V0.583008ZM11.7 8.28301H14.5V13.4163H11.7V8.28301Z"
      fill={active ? '#323232' : 'white'}
    />
  </svg>
);

export default BarIcon;
