import React from 'react';
import styles from './buttonsContainer.module.scss';

interface TProps {
  children?: React.ReactNode;
  className?: string;
}

const ButtonsContainer: React.FC<TProps> = ({ className, children }) => (
  <div className={`${styles.buttonsContainer} ${className || ''}`}>{children}</div>
);

export default ButtonsContainer;
