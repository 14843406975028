import styles from './style.module.scss';
import { dateFormatter } from '../../../utils';
import { PeriodsEnum } from '../../../../../common/reportItems/types';

export const generateTooltip = (params: any[], title: string, period: PeriodsEnum) => {
  const date = dateFormatter(period, params[0].axisValueLabel);
  let string = `
              <div class="${styles.header}">
                <span class="${styles.title}">${title}</span><br />
                <span class="${styles.date}">${date}</span>
              </div>`;
  params.forEach((item) => {
    string += `<div class="${styles.item}">
                  <span class="${styles.location}"><span style="${{ width: '100%' }}">${item.marker}</span> ${
      item.seriesName
    }</span>
                  <span class="${styles.value}">${(item.value * 100).toFixed(2)}%</span>
                </div>`;
  });

  return `<div class="${styles.tooltip}">
                ${string}
              </div>`;
};
