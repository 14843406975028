import React from 'react';
import styles from './input.module.scss';
import { EnvelopeIcon } from '../icon/envelope';

interface TProps {
  value: string;
  onChange: (event: any) => void;
  error?: boolean;
}

export const EmailInput = (props: TProps) => (
  <div className={props.error ? styles.errorInputContainer : styles.inputContainer}>
    <EnvelopeIcon className={styles.inputIcon} />
    <input
      className={styles.input}
      autoFocus
      type="text"
      value={props.value}
      onChange={props.onChange}
      placeholder="Email"
    />
  </div>
);
