import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { dashboardModel } from '../../../../dasboardLoader';
import styles from './style.module.scss';
import { MainContext } from '../../../types/mainContext';
import { useURLParams, useWindowSize } from '../../../hooks';
import { useSlider } from '../../hooks';
import { CollapseOptions } from '../CollapseOptions';
import { Switcher } from '../../switcher';
import { Dot } from '../../dot';
import { Card } from '../Card';
import { SlidingBarViewOptions } from '../interfaces';
import { trackUserAction, UserActionsEnum } from '../../../../../mixpanel';

const TOP_OFFSET = 140;
const DOT_WIDTH = 10;
const SPACE_BETWEEN_DOTS = 5;
const CARD_HEIGHT = 164;
const SPACE_BETWEEN_ROWS = 5;
const CARD_WIDTH = 230;

interface Item {
  id: string;
  image?: string;
  name: string;
}
interface Props {
  items: Item[];
  shareBlock?: React.ReactNode;
  isExpanded?: boolean;
  isCollapsed?: boolean;
  onChangeViewOptions?: (data: Partial<SlidingBarViewOptions>) => void;
}

export const LocationsSlidingBar: React.FC<Props> = ({
  items,
  shareBlock,
  isExpanded = true,
  isCollapsed = false,
  onChangeViewOptions,
}) => {
  const { primaryLocationId, setPrimaryLocationId } = useContext(MainContext);
  const [displayItemsCount, setDisplayItemsCount] = useState(1);
  const { height, width } = useWindowSize();
  const { onChangeParams } = useURLParams();
  const itemsRef = useRef<HTMLDivElement>(null);
  const sliderControlRef = useRef<HTMLDivElement>(null);

  const areAllRIsLoaded = useStore(dashboardModel.$areAllReportItemsLoaded);

  useEffect(() => {
    if (!isCollapsed) {
      const element = itemsRef.current;
      if (element) {
        element.scrollTo(0, 0);
      }
    }
  }, [isCollapsed]);

  useEffect(() => {
    setDisplayItemsCount(displayedItemsCount());
  }, [width]);

  const onCollapse = () => {
    trackUserAction(
      'Gallery button clicked',
      UserActionsEnum.GALLERY_CLICK,
      `Maximize button, ${isCollapsed ? 'maximized' : 'back to regular size'}`,
    );
    onChangeViewOptions?.({ collapsed: !isCollapsed, expanded: isExpanded || !isExpanded });
  };

  const onExpand = () => {
    trackUserAction(
      'Gallery button clicked',
      UserActionsEnum.GALLERY_CLICK,
      `Minimize button, ${isExpanded ? 'minimized' : 'back to regular size'}`,
    );
    onChangeViewOptions?.({ expanded: !isExpanded });
  };

  const onClickByLocation = (id: string) => {
    if (areAllRIsLoaded) {
      trackUserAction(
        'Primary location has been changed',
        UserActionsEnum.PRIMARY_LOCATION_CHANGE,
        `Set by click on the image in Catchment/Movement gallery. Location ID: ${id}`,
      );
      setPrimaryLocationId(id);
      onChangeParams('primaryLocation', id);
    }
  };

  const maxDotsCount = useMemo(() => {
    const SPACE_BETWEEN_SLIDER_CONTROL = 1025; // depends on screen size
    const INIT_DOTS_COUNT = 10;
    const INIT_DOTS_COUNT_FOR_SMALL = 5;
    const newWidth = width || 1;
    const result = (newWidth - SPACE_BETWEEN_SLIDER_CONTROL) / DOT_WIDTH + SPACE_BETWEEN_DOTS;
    if (result > 0) return result < INIT_DOTS_COUNT ? INIT_DOTS_COUNT : result;
    return INIT_DOTS_COUNT_FOR_SMALL;
  }, [width]);

  const displayedItemsCount = () => {
    const offsetWidth = itemsRef.current?.offsetWidth;
    if (offsetWidth) {
      return Math.floor(offsetWidth / CARD_WIDTH);
    }
    return 1;
  };

  const PORTION_SIZE = displayItemsCount;
  const SCROLL_SIZE = displayItemsCount;

  const { isLast, isFirst, onChangeCurrent, current, portionsCount, currentItemsPortion, onPrevSlide, onNextSlide } =
    useSlider<Item>(items, items.length, PORTION_SIZE, SCROLL_SIZE, maxDotsCount);

  const maxHeight = useMemo(() => (height ? height - TOP_OFFSET : 0), [height]);

  const currentHeight = useMemo(
    () => (portionsCount?.length || 0) * CARD_HEIGHT + SPACE_BETWEEN_ROWS * (portionsCount?.length || 0),
    [height, items],
  );

  const selectedLocationName = items.find(({ id }) => primaryLocationId === id)?.name;

  const showSliderControl = !isCollapsed && (portionsCount?.length || 0) > 1;
  return (
    <div className={`${styles.wrapper}`}>
      <div className={styles.options}>
        <CollapseOptions
          disabledCollapseButton={(portionsCount?.length || 0) <= 1}
          onCollapse={onCollapse}
          onExpand={onExpand}
          isExpanded={isExpanded}
          isCollapsed={isCollapsed}
          hideCollapse={isLast && isFirst}
        />
      </div>
      <div className={styles.information}>
        <div className={`${styles.header} ${isCollapsed || !showSliderControl ? styles.collapsed : ''}`}>
          <h4 className={styles.title}>
            <span className={styles.location}>{selectedLocationName}</span>{' '}
            {items?.length > 1 ? `(+${items.length - 1} more)` : ''}
          </h4>
          {showSliderControl && (
            <div className={styles.sliderControl} ref={sliderControlRef}>
              <Switcher
                isActiveLeftArrow={!isFirst}
                isActiveRightArrow={!isLast}
                onPrev={onPrevSlide}
                onNext={onNextSlide}
              />
              <div className={styles.dots}>
                {portionsCount?.map((value, index) => (
                  <Dot
                    key={`miniSlider-dot-${index}`}
                    isActive={value === current}
                    onClick={onChangeCurrent}
                    value={value}
                  />
                ))}
              </div>
            </div>
          )}
          <div className={styles.share}>{shareBlock}</div>
        </div>
        <div
          className={`${styles.items} ${isExpanded ? styles.expanded : styles.hidden} ${
            isCollapsed ? `custom-scrollbar ${styles.collapsed}` : ''
          }`}
          style={{
            gridTemplateColumns: `repeat(${PORTION_SIZE}, 1fr)`,
            ...(isCollapsed ? { height: `${currentHeight > maxHeight ? maxHeight : currentHeight}px` } : {}),
          }}
          ref={itemsRef}
        >
          {(!isCollapsed ? currentItemsPortion : items).map(({ image, name, id }) => (
            <Card
              key={id}
              id={id}
              img={image}
              name={name}
              isActive={id === primaryLocationId}
              onClick={onClickByLocation}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
