import React, { forwardRef } from 'react';
import { MapPopupData } from '../../../../common/types/visualizationObjects';
import MapPopup from '../../../../common/maps/popup/MapPopup';
import styles from './style.module.scss';
import { CrossIcon } from '../../../../common/icons/controls';

interface Props {
  data: MapPopupData;
  closePopup: VoidFunction;
  locationName: string;
  previewImage: string;
  children: React.ReactNode;
}

export const LocationPopup = forwardRef<HTMLDivElement, Props>(
  ({ data, closePopup, children, locationName, previewImage }, ref) => {
    return (
      <MapPopup coordinate={data.coordinates} onClose={closePopup}>
        <div className={styles.popup} ref={ref}>
          <div className={styles.cross} onClick={closePopup}>
            <CrossIcon />
          </div>
          <div className={styles.information}>
            <h4>{locationName}</h4>
          </div>
          <img className={styles.preview} src={previewImage} />
          <div>{children}</div>
        </div>
      </MapPopup>
    );
  },
);
