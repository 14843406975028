import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MainContext } from '../../../common/types/mainContext';
import { useClickOutside } from '../../../common/hooks';
import { trackUserAction, UserActionsEnum } from '../../../../mixpanel';
import { ReportItemType } from '../../../common/reportItems/types';
import { CommonPopup } from './commonPopup/basicPopup';
import { LocationPopup } from './locationPopup';
import { VisitTrendsMapTooltip } from '../../../reportItems/trends/card/tooltips';
import { addImageUrlToLocations } from '../../../common/locations/utils';
import { SeasonalityMapTooltip } from '../../../reportItems/seasonality/card';
import { DurationMapTooltip } from '../../../reportItems/duration/card/tooltips';
import { RetentionMapTooltip } from '../../../reportItems/retention/card/tooltips';
import { CrossVisitationMapTooltip } from '../../../reportItems/crossVisitation/card/tooltips';
import { AbsorptionMapTooltip } from '../../../reportItems/absorption/card/tooltips';
import { AttractionMapTooltip } from '../../../reportItems/attraction/card/tooltips';

interface Props {}
export const BasicPopup: React.FC<Props> = () => {
  const {
    popup,
    selection: { selectedReportItem },
    setPopup,
    primaryLocationId,
    selectedLocations,
  } = useContext(MainContext);
  const [primaryLocationPreview, setPrimaryLocationPreview] = useState('');

  const currentData = selectedReportItem ? popup?.[selectedReportItem.type] : null;

  const primaryLocation = useMemo(
    () => selectedLocations?.find(({ id }) => id === primaryLocationId),
    [primaryLocationId],
  );

  const checkIsBasicDisplay = () => {
    switch (selectedReportItem?.type) {
      case ReportItemType.CATCHMENT:
        return true;
      case ReportItemType.MOVEMENT:
        return true;
      default:
        return false;
    }
  };
  const isBasicDisplay = checkIsBasicDisplay();

  useEffect(() => {
    if (currentData && popup) {
      trackUserAction(
        `Popup displayed by click on map point / polygon`,
        UserActionsEnum.POLYGON_OR_POINT_CLICK,
        `Title: ${currentData.title}`,
      );
    }
  }, [popup]);

  useEffect(() => {
    if (primaryLocation && !isBasicDisplay) {
      const updatedPrimaryLocation = addImageUrlToLocations([primaryLocation], 310, 100);
      const location = updatedPrimaryLocation[0];
      if (location.image) setPrimaryLocationPreview(location.image);
    }
  }, [primaryLocation, isBasicDisplay]);

  const closePopup = (e?: HTMLElement | null) => {
    const dataset = e?.dataset;
    if (dataset?.popup) return;

    if (e) {
      const { dataset } = e;

      if ('identifier' in (dataset || {})) {
        // Note: If we clicked by pin there is going to be data-identifier to identify the pin.
        // We don't have to close it because we are going to open new one using specific method in Points component
      } else {
        setPopup(null);
      }
    }
  };

  const rootRef = useRef<HTMLDivElement>(null);

  useClickOutside(rootRef, closePopup, popup !== null);

  const tooltipComponent = useMemo(() => {
    if (!selectedReportItem?.type || !currentData) return <></>;

    return {
      [ReportItemType.FOOTFALL]: <VisitTrendsMapTooltip data={currentData} />,
      [ReportItemType.CATCHMENT]: <></>,
      [ReportItemType.SEASONALITY]: <SeasonalityMapTooltip data={currentData} />,
      [ReportItemType.MOVEMENT]: <></>,
      [ReportItemType.DURATION]: <DurationMapTooltip data={currentData} />,
      [ReportItemType.RETENTION]: <RetentionMapTooltip data={currentData} />,
      [ReportItemType.CROSS_VISITATION]: <CrossVisitationMapTooltip data={currentData} />,
      [ReportItemType.ABSORPTION]: <AbsorptionMapTooltip data={currentData} />,
      [ReportItemType.ATTRACTION]: <AttractionMapTooltip data={currentData} />,
    }[selectedReportItem.type];
  }, [selectedReportItem?.type, currentData]);

  if (!currentData?.data || !primaryLocation) return <></>;

  return (
    currentData.data &&
    (isBasicDisplay ? (
      <CommonPopup data={currentData} closePopup={closePopup} ref={rootRef} />
    ) : (
      <LocationPopup
        data={currentData}
        closePopup={closePopup}
        locationName={primaryLocation.name}
        previewImage={primaryLocationPreview}
        ref={rootRef}
      >
        {tooltipComponent}
      </LocationPopup>
    ))
  );
};
