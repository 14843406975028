import { Income } from '../../interfaces';
import { IncomeIcon } from '../../../../../../../common/icons/charts';
import { separateThousandsByComma } from '../../../../../../../common/utils';

const calculateLeftOffset = (length: number) => {
  switch (length) {
    case 1:
      return -115;
    case 2:
      return -250;
    case 3:
      return -410;
    default:
      return -410;
  }
};

const colors = ['#DFD2EF', '#A67BDD', '#c1a3e7', '#d3bdee', '#e4d7f5', '#A6ADB6'];

const data = [
  {
    id: 1,
    color: colors[1],
    title: 'Income',
    value: `N/A`,
    stake: 100,
    styles: {
      item: {
        width: '80px',
      },
      label: {
        width: '65px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        justifyContent: 'start',
      },
    },
  },
];
export const getIncome = (value: Income[] | null, currency: string) => ({
  id: 6,
  icon: IncomeIcon,
  styles: { item: { left: `${calculateLeftOffset(value?.length || data.length)}px` } },
  data: value
    ? value?.map(({ value, category }, index) => ({
        id: index + 1,
        color: colors[index],
        title: `${category.replace('<CURRENCY>', currency || '')}`,
        value: `${separateThousandsByComma(value)}%`,
        stake: value as number,
        styles: {
          item: {
            width: '130px',
          },
          label: {
            width: '116px',
            justifyContent: 'start',
          },
        },
      }))
    : data,
});
