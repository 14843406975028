import { PeriodsEnum } from '../../../common/reportItems/types';
import { RetentionLocation } from '../../../common/types/projectsHierarchy';
import {
  getAllMonthsBetweenPeriod,
  getAllQuartersBetweenPeriod,
  getAllWeeksBetweenPeriod,
  getMinMaxMonth,
  getMinMaxWeek,
} from '../../../common/utils';

export const populateWithMissingTimeFrames = (
  timeFrames: string[] | null,
  period: PeriodsEnum,
  data: RetentionLocation[],
) => {
  if (timeFrames === null) {
    return data;
  }
  return data.map((location) => {
    let dates: number[] = [];
    if (PeriodsEnum.MONTHLY === period) {
      const [minDate, maxDate] = getMinMaxMonth(timeFrames);
      dates = getAllMonthsBetweenPeriod(minDate, maxDate);
    } else if (PeriodsEnum.QUARTERLY === period) {
      const [minDate, maxDate] = getMinMaxMonth(timeFrames);
      dates = getAllQuartersBetweenPeriod(minDate, maxDate);
    } else if (PeriodsEnum.WEEKLY === period) {
      const [minDate, maxDate] = getMinMaxWeek(timeFrames);
      dates = getAllWeeksBetweenPeriod(minDate, maxDate);
    }

    const locationData = dates.map((date) => {
      const item = (location.data || []).find((item) => Number(item.date) === date);
      const updatedItem = {
        date,
        value: item ? item.value : '-',
        confidence_level: item && item.confidence_level ? item.confidence_level : '-',
        is_relative_shown: item && item.is_relative_shown ? item.is_relative_shown : '-',
      };
      return updatedItem;
    });

    return { ...location, data: locationData };
  });
};
