import { AbsorptionData } from '../../../../common/types/projectsHierarchy';
import { dateFormatter } from '../../lib/dateFormatter';

export const convertDataForExcel = (data: AbsorptionData) => {
  return data.data.map(({ locationName, dateChanges }) => ({
    location: locationName,
    source: data.location_name,
    data: dateChanges.map(({ timestamp, absorption }) => ({
      timestamp: String(timestamp),
      date: dateFormatter(`${timestamp}`, 'MMM YYYY'),
      value: `${(absorption * 100).toFixed(2)}%`,
    })),
  }));
};
