import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './changePasswordForm.module.css';
import Button from '../../atoms/primaryButton/button';
import { changePasswordForUser } from '../../../actions/actions';

interface Props {
  email: string;
  onSave: () => void;
}

const ChangePasswordForEditUserModalForm = ({ email, onSave }: Props): ReactElement => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    newPassword: '',
  });

  const updateInputFieldHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  const changePasswordHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(changePasswordForUser(email || '', state.newPassword, onSave));
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={changePasswordHandler}>
          <div className={styles.formRow}>
            <label htmlFor="otherUser_userName" className={styles.label}>
              Login
              <input
                className={styles.input}
                id="otherUser_userName"
                name="userName"
                value={email}
                onChange={updateInputFieldHandler}
                autoComplete="new-password"
                disabled
              />
            </label>
          </div>
          <div className={styles.formRow}>
            <label htmlFor="otherUser_newPassword" className={styles.label}>
              New password
              <input
                className={styles.input}
                type="password"
                id="otherUser_newPassword"
                name="newPassword"
                value={state.newPassword}
                onChange={updateInputFieldHandler}
                autoComplete="new-password"
              />
            </label>
          </div>
          <div className={styles.buttonContainer}>
            <Button type="submit" disabled={!email}>
              Change password
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordForEditUserModalForm;
